import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import clsx from 'clsx';
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { IFilter, IMenuItem, Indexable, InvoiceFilter, InvoicesSettings, LSwapFunc } from "types";
import { FilterList, ChipMenu, SearchInput, IconMenu } from "components";
import { useBoolState, useDialogs, useLocalization, useSearchStringFilter, useSliceSettings } from "utils/hooks";
import { generalStyles } from "utils/styles";
import { useMediaQuery } from "@material-ui/core";
import { exportInvoicesToCsv } from "utils/export-helper";
import { selectFilteredInvoices } from "../infra/invoice-selectors";
import { getRoute, routes } from "features/app";
import { useHistory } from "react-router-dom";

const buildStyles   = makeStyles(theme => ({
  ...generalStyles(theme),
  root  : {
    // flexWrap    : "nowrap",
    minHeight: 42,
  },
  title: {
    fontSize: 22,
    fontWeight: 500,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    }
  }, 
  menuBar: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: "center",
    }
  },
  firstCol: {
    padding: `${theme.spacing(0)}px ${theme.spacing(1)}px`, //`
    borderRight: `1px solid ${theme.palette.grey[300]}`, //`
    display: "inline-flex",
    [theme.breakpoints.down('xs')]: {
      borderRight: 'none',
      marginBottom: theme.spacing(1),
      alignItems: 'center',
    }
  },
  lastCol: {
    padding: `${theme.spacing(0)}px ${theme.spacing(1)}px`, //`
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1),
      alignItems: 'center',
    }
  },
  chip: {
    height: 28,
    minWidth: 50,
  },
  chipIcon: {
    height: 28,
    width: 28,
    border: "1px solid rgba(0,0,0,0.23)",
    "& .MuiIcon": {
      fontSize: 22,
    }
  },
  searchContainer: {
    width: "65%",
    [theme.breakpoints.down('xs')]: {
      width: "100%",
    }
  },
  iconButton: {
    padding: 10,
  },
}));

export const invoiceFilterLabels: Indexable = {
  thismonth: "This Month",
  active: "Active",
  inprogress: "Not Delivered",
  uninvoiced: "Not Invoiced",
  unpaid: "Unpaid",
  all: "All",
  paid: "Paid",
  lastmonth: "Last Month",
  thisquarter: "This Quarter",
  thisyear: "This Year",
  archived: "Archived",
}

const filterListItems = (LSwap: LSwapFunc): IFilter[] => {
  return [
    { id: 0, label: "Unpaid", filter: "unpaid", sort: "dueDate", sortDir: "desc", title: LSwap("Invoices that have not been paid", "invoices") },
    { id: 40, label: "All", filter: "all", sort: "invoiceDate", sortDir: "desc", title: LSwap("All invoices", "invoices") },
  ];
}

const filterMenuItems: IMenuItem[] = [
  { id: 100, label: "Overdue", action: "overdue", props: { sort: "dueDate", sortDir: "desc" } },
  { id: 200, label: "Due soon", action: "due-soon", props: { sort: "dueDate", sortDir: "desc" } },
  { id: 300, label: "Paid", action: "paid", props: { sort: "paidDate", sortDir: "desc" } },  
  { id: 999, isDivider: true},
  { id: 400, label: "Invoiced this year", action: "this-year", props: { sort: "invoiceDate", sortDir: "desc" } },  
  { id: 500, label: "Paid this year", action: "paid-this-year", props: { sort: "paidDate", sortDir: "desc" } },  
  { id: 600, label: "Invoiced last year", action: "last-year", props: { sort: "invoiceDate", sortDir: "desc" } },  
  { id: 700, label: "Paid last year", action: "paid-last-year", props: { sort: "paidDate", sortDir: "desc" } },  
];

const InvoiceListFilter = () => {
  const classes   = buildStyles();
  const history = useHistory();
  const {L, LSwap} = useLocalization();
  const invoices = useSelector(selectFilteredInvoices);
  const [settings, changeSettings] = useSliceSettings<InvoicesSettings>("invoices");
  const [isSearch, toggleSearch] = useBoolState(false);
  const isXs = useMediaQuery((theme: any) => theme.breakpoints.down('xs'));
  const { openDialog, onOpenDialog } = useDialogs();
  const filters = useMemo(() => filterListItems(LSwap), []);
  const menuItems = useMemo(() => filterMenuItems, []);
  const selectedItem = useMemo(() => { return filterMenuItems.find(f => f.action === settings.filter) ?? null; }, [settings.filter]);

  const configMenu: IMenuItem[] = useMemo(() => [
    { id: 50, label: LSwap("Import Invoices", "invoices"), action: "import" },
    { id: 60, label: LSwap("Export Invoices", "invoices"), action: "export" },
  ], []);

  //-- Clear the search term when the search closes
  useEffect(() => {
    if(!isSearch && settings.search !== null){
      changeSettings({...settings, search: null});
    }
  }, [isSearch]);

  //Check to see if there's a filter in the query paramters, and if so, deal with it
  useSearchStringFilter(routes.invoices, changeSettings);

  const menuLabel = useMemo<string>(() => { 
    return isXs ? "" : selectedItem?.label ?? "Filter";
  }, [selectedItem, isXs])

  const onFilterClicked = (filter: IFilter) => {
    changeSettings({ filter: filter.filter as InvoiceFilter, sort: filter.sort, sortDir: filter.sortDir });
  }

  const onMenuItemClicked = (menuItem: IMenuItem) => {
    switch (menuItem.action) {
      case "import": openDialog("import", "invoices"); break;
      case "export": exportInvoicesToCsv(invoices); break;
      default:
        changeSettings({ filter: menuItem.action as InvoiceFilter, sort: menuItem.props.sort, sortDir: menuItem.props.sortDir });
        break;
    }
  }
  
  const filterMenuComparer = (menuItem: IMenuItem) => {
    return menuItem.action === settings.filter && menuItem.props.sort === settings.sort;
  }

  const doSearch = (searchVal: string) => {
    changeSettings({...settings, search: searchVal});
  }

  const onCreateInvoice = () => {
    history.push(routes.invoiceNew);
  }
  
  return (
    <Grid container alignItems="center" className={classes.root}>
      <Grid item sm={3} xs={12}>
        <Typography variant="h5" color="primary" className={classes.title}>{L("invoices", true)}</Typography>
      </Grid>
      <Grid item sm={9} xs={12} id="invoice-list-filter" container justify="flex-start" alignItems="center">
        {isSearch && 
          <Grid container alignItems="center">
            <SearchInput autoFocus={true} placeholder="Search invoices" onClose={toggleSearch} onSearch={doSearch} classes={{container: classes.searchContainer}}/>
          </Grid>
        }
        {!isSearch && 
          <Grid container alignItems="center" className={classes.menuBar}>
            <Grid item className={classes.firstCol}>
              <FilterList filters={filters} currentFilter={settings.filter} onClick={onFilterClicked} color="secondary" classes={{container: classes.marginRight}}/>
              <ChipMenu label={menuLabel} icon={<Icon fontSize="small">filter_list</Icon>} size="small" items={menuItems} title="More filters" color={!!selectedItem ? "secondary" : "default"} variant={!!selectedItem ? "default" : "outlined"}  isSelectable={true} onClick={onMenuItemClicked} isSelected={filterMenuComparer}/>
            </Grid>
            <Grid item className={classes.lastCol}>
              <Grid container alignItems="center">
                <Tooltip title="Search invoices">
                  <IconButton size="small" className={clsx(classes.chipIcon)} onClick={toggleSearch}><Icon fontSize="small">search</Icon></IconButton>
                </Tooltip>
                <Tooltip title="New invoice">
                  <IconButton size="small" className={clsx(classes.chipIcon, classes.marginLeft)} onClick={onCreateInvoice}><Icon fontSize="small">add</Icon></IconButton>
                </Tooltip>
                <IconMenu icon={<Icon>more_vert</Icon>} items={configMenu} title="More actions" onClick={onMenuItemClicked} classes={{button: clsx(classes.chipIcon, classes.marginLeft)}} />
              </Grid>
            </Grid>
          </Grid>
        }
      </Grid>
    </Grid>
  );
}

export default InvoiceListFilter;
