import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import HoursFilterBar from "features/timesheet/ui/hours-filter-bar";
import { generalStyles } from "utils/styles";
import { generateSampleClient, generateSampleHours, generateSampleProject } from "utils/sample-data/sample-writer";

const buildStyles   = makeStyles(theme => ({
  ...generalStyles(theme),
  root  : {
    paddingTop: theme.spacing(5),
  },
  title   : {
    fontSize    : 22,
    fontWeight  : 500,
    textAlign   : "center",
    marginBottom: theme.spacing(1),
  },
  subTitle  : {
    fontSize  : 17,
    fontWeight: 500,
    textAlign : "center",
    marginBottom  : theme.spacing(3),
    color     : theme.palette.grey[800],
  },
}));

const UITester = () => {
  const classes   = buildStyles();
  const [filter, setFilter] = useState("");
  const [cliText, setCliText] = useState("");
  const [prjText, setPrjText] = useState("");

  const onChange = (filter: any) => {
    if(!filter){
      console.log("no filter applied");
    }
    else{
      console.log("filter applied");
      setFilter(JSON.stringify(filter));
    }
  }

  const onGenerate = () => {
    const client = generateSampleClient();
    const project = generateSampleProject(client);
    const timers = generateSampleHours(project);
    setCliText(JSON.stringify(client));
    setPrjText(JSON.stringify(project));

    console.log(client);
    console.log(project);
    console.log(timers);
  }

  return (
    <Grid id="data-view" container justify="center" className={classes.root}>
      <Grid item xs={10} style={{marginTop: 25}}>        
        <HoursFilterBar onFilterChange={onChange}/>
      </Grid>
      <Grid item xs={10} container style={{marginTop: 25}} justify="center" alignContent="center">
        <Typography>filter: {filter}</Typography>
      </Grid>

      <Grid container style={{marginTop: 25}} justify="center" alignItems="center">
          <Button onClick={onGenerate}>Generate</Button>
          <Grid item md={6} container direction="column" spacing={1}>
            <Grid item>
              <TextField multiline rows={10} value={cliText} fullWidth variant="outlined"/>
            </Grid>
            <Grid item>
              <TextField multiline rows={10} value={prjText} fullWidth variant="outlined"/>
            </Grid>
          </Grid>
      </Grid>

    </Grid>
  );
}

export default UITester;
