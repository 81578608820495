import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "types";
import { loadNotes } from "./infra/note-actions";
import { selectFilteredNotes } from "./infra/note-selectors";
import NotesListHeader from "./notes-list-header";
import { LoadingIndicator } from "components";
import NoteEditCard from "./note-edit-card";
import NotesList from "./notes-list";

const NotesContent = () => {
  const dispatch = useDispatch();
  const notes = useSelector(selectFilteredNotes);
  const { status, isInitialized } = useSelector((state: RootState) => state.notes);

  useEffect(() => {
    if(notes === null ){
      dispatch(loadNotes());
    }
  }, []);

  if(!isInitialized){
    return (<LoadingIndicator isVisible={!isInitialized || status.isWorking} message="Loading..." />);
  }

  return (
    <Grid container direction="column">
      <NotesListHeader />
      <NoteEditCard />
      <NotesList items={notes} />      
    </Grid>
  );
}

export default NotesContent;
