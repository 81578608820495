import _ from "lodash";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGoal, IStatus } from "types";

interface InitialState{
  items: Array<IGoal> | null;
  current: Partial<IGoal> | null;
  isInitialized: boolean;
  isEmpty?: boolean | null;
  settings: any;
  status: IStatus;
}

export const initialState: InitialState = {
  items: null,
  current: null,
  isInitialized: false,
  isEmpty: null,
  settings: null,
  status: {
    isWorking: false,
    error: null,
  }
};

export const slice = createSlice({
  name: "goals",
  initialState,
  reducers: {
    statusChanged: (state, action: PayloadAction<Partial<IStatus>>) => {
      state.status = {
        ...state.status,
        ...action.payload,
      };
    },
    clearStore: (state) => {
      state.items = initialState.items;
      state.current = initialState.current;
      state.isInitialized = initialState.isInitialized;
      state.isEmpty = initialState.isEmpty;
      state.status = {...initialState.status};
    },
    goalsLoaded: (state, action: PayloadAction<IGoal[]>) => {
      state.items = action.payload;
      state.isEmpty = Boolean(!action.payload || action.payload.length === 0);
      state.isInitialized = true;
      state.status = {isWorking: false, error: null};
    },
    goalAdded: (state, action: PayloadAction<IGoal>) => {
      if (state.items === null) state.items = [];
      state.items.push(action.payload);
      state.isEmpty = false;
      state.status = {isWorking: false, error: null};
    },
    goalUpdated: (state, action: PayloadAction<{id: string; changes: Record<string, unknown>}>) => {
      const existing = state.items?.find(gl => gl.id === action.payload.id) as any;
      if (!!existing) {
        _.keys(action.payload.changes).forEach(key => {
          existing[key] = action.payload.changes[key];
        });
        state.status = {isWorking: false, error: null};
      }
    },
    goalDeleted: (state, action: PayloadAction<string>) => {
      if(!state.items) return;
      const updates = state.items.filter(i => i.id !== action.payload);
      state.items = updates;
      state.isEmpty = updates.length === 0;
      state.status = {isWorking: false, error: null};
    }
  }
});

export const {
  statusChanged,
  clearStore,
  goalsLoaded,
  goalAdded,
  goalUpdated,
  goalDeleted,
} = slice.actions;

export default slice.reducer;