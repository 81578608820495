import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import { shallowEqual } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { Invoice } from "types";
import { cardStyles, formStyles, generalStyles } from "utils/styles";
import { useClients, useProjects, useInputs } from "utils/hooks";
import { DateInput } from "components";
import { invoiceParsers } from "../infra/invoice-helpers";
import { InvoiceContext } from "../ui/invoice-container";

const buildStyles   = makeStyles(theme => ({
  ...formStyles(theme),
  ...cardStyles(theme),
  ...generalStyles(theme),  
}));

interface ControlProps{
  isWorking: boolean;
}

//TODO: Deal with invoices that aren't real invoices

const InvoiceEditCard = ({isWorking}: ControlProps) => {
  const classes   = buildStyles();
  const { isNew, invoice, invoiceId, clientId, projectId, setCurrentValues, hoursTotal } = useContext(InvoiceContext);
  const [lastTotal, setLastTotal] = useState(0); //hoursTotal);
  const clients = useClients("name");
  const allProjects = useProjects();
  const [values, display, errors, binding, setValues] = useInputs<Invoice>(invoice, invoiceParsers);
  const [lastValues, setLastValues] = useState(values);
  const today = React.useMemo(() => new Date(), []);
  const [isReady, setReady] = React.useState(false);

  //When there are changes, need to update the edit context
  useEffect(() => {
    if(!shallowEqual(lastValues, values)){
      setCurrentValues(values);
      setLastValues(values);
    }
  }, [values]);
  
  //-- initialize the card based on invoiceId that is provided
  React.useEffect(() => {
    if(!isNew && invoice){
      setValues(invoice);
    }
    else {
      //New invoice
      if(!values.projectId && projectId){
        const prj = allProjects?.find(p => p.id === projectId);
        if(prj){
          setValues({...values, clientId: prj.clientId, projectId: projectId as string});
        }
      }
      else if(!values.clientId && clientId){
        setValues({...values, clientId: clientId as string});
      }
    }

    setReady(true);
  }, [invoiceId, clientId, projectId]);

  //-- List of projects available based on current values
  const availableProjects = React.useMemo(() => {
    if(!values.clientId || !allProjects) return [];
    const prjs = allProjects.filter(p => p.clientId === values.clientId);
    return prjs;
  }, [values.clientId]);

  // -- switch out the current project when the client changes
  useEffect(() => {
    if(!isReady) return;
    //If there's only one project (for the current client)
    if(availableProjects.length === 1 && availableProjects[0].clientId === values.clientId){
      setValues({...values, projectId: availableProjects[0].id});
    }
    else if(values.clientId){
      //Otherwise, clear out the project id
      setValues({...values, projectId: ""});
    }
  }, [values.clientId]);

  useEffect(() => {
    if((!values.amount || values.amount === lastTotal) && (values.amount !== hoursTotal) ){
      setValues({...values, amount: hoursTotal});
      setLastTotal(hoursTotal);
    }
  }, [hoursTotal]);

  return (
      <Grid id="invoice-edit-view" container>        
        <Paper className={clsx([classes.card, classes.mbl])} variant="outlined">
          <Grid container className={classes.cardBody} spacing={1}>
            <Grid id="invoice-editor" container>
              <Grid item xs={12} container spacing={2}>
                  <Grid item md={2} sm={5} xs={6} className={classes.inputGrid}>
                    <TextField id="number" label="Invoice Number" autoFocus={true} fullWidth value={display?.number} {...binding.input} {...errors["number"]} autoComplete="off" disabled={isWorking}/>
                  </Grid>
                  
                  <Grid item md={5} sm={7} xs={6} className={classes.inputGrid}>
                    <FormControl className={classes.formControl} fullWidth {...binding.selectContainer("clientId", clients)} disabled={Boolean(isWorking || !isNew)}>
                      <InputLabel id="client-label" className={classes.selectLabel}>Client</InputLabel>
                      <Select id="clientId" labelId="client-label" value={values?.clientId} {...binding.select("clientId")} fullWidth className={classes.selectContainer} classes={{ select: classes.select }}>
                        <MenuItem value="-1" disabled className={classes.option}>Select Client</MenuItem>
                        {clients?.map(option => <MenuItem key={option.id} value={option.id} className={classes.option}>{option.name}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item md={5} sm={6} xs={12} className={classes.inputGrid}>
                    <FormControl className={classes.formControl} fullWidth {...binding.selectContainer("projectId", allProjects)} disabled={Boolean(isWorking || !isNew || !values.clientId)}>
                      <InputLabel id="project-label" className={classes.selectLabel}>Project</InputLabel>
                      <Select id="projectId" labelId="project-label" value={values?.projectId} {...binding.select("projectId")} fullWidth className={clsx(classes.selectContainer, {[classes.secondaryColor]: values?.projectId === "multiple"})} classes={{ select: classes.select }}>
                        <MenuItem value="-1" disabled className={classes.option}>Select project</MenuItem>
                        {availableProjects?.length > 1 && <MenuItem value="multiple" className={classes.optionSecondary}>- Multiple -</MenuItem>}
                        {availableProjects?.map(option => <MenuItem key={option.id} value={option.id} className={classes.option}>{option.name}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid item xs={12} container spacing={2}>
                  <Grid item sm={6} xs={12} container spacing={2} alignContent="flex-start">
                    <Grid item xs={6} className={classes.inputGrid}>
                      <TextField id="amount" label="Amount" fullWidth value={display?.amount} {...binding.input} {...errors["amount"]} autoComplete="off" disabled={isWorking}/>
                    </Grid>
                    <Grid item xs={6} className={classes.inputGrid}>
                      <DateInput id="invoiceDate" label="Invoice Date" value={values.invoiceDate} {...binding.dateInput("invoiceDate")} fullWidth maxDate={today} disabled={isWorking}/>
                    </Grid>
                    <Grid item xs={6} className={classes.inputGrid}>
                      <DateInput id="dueDate" label="Due Date" value={values.dueDate} {...binding.dateInput("dueDate")} fullWidth minDate={values.invoiceDate || today} disabled={isWorking}/>
                    </Grid>
                    <Grid item xs={6} className={classes.inputGrid}>
                      <DateInput id="paidDate" label="Paid Date" value={values.paidDate} {...binding.dateInput("paidDate")} fullWidth minDate={values.invoiceDate || today} maxDate={today} disabled={isWorking}/>
                    </Grid>
                  </Grid>
                  <Grid item sm={6} xs={12} container spacing={2}>
                    <Grid item xs={12} className={classes.inputGrid}>
                      <TextField id="notes" label="Notes" fullWidth variant="outlined" value={display?.notes} {...binding.input} {...errors["notes"]} multiline rows={5} disabled={isWorking}/>
                    </Grid>
                  </Grid>
                </Grid>
            </Grid>
          </Grid>
        </Paper>

      </Grid> 
  );
}

export default InvoiceEditCard;
