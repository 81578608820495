import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { IProject, ITimer } from "types";
import { useActiveTimer, useSnackbar } from "utils/hooks";
import { useDispatch } from "react-redux";
import { Icons } from "utils/common-classes";
import { startTimer } from "features/timesheet";

const buildStyles   = makeStyles(theme => ({
  forceSecondary: {
    color: `${theme.palette.secondary.main} !important`,
  }
}));

interface ButtonProps{
  project: IProject;
  size?: "small" | "medium";
}

const ProjectTimerButton = ({project, size}: ButtonProps) => {
  const classes = buildStyles();
  const activeTimer = useActiveTimer();
  const dispatch = useDispatch();
  const notify = useSnackbar();
  const iconSize = (size === "medium") ? "default" : size;
  
  const onStartTimer = async () => {
    const model: Partial<ITimer> = {
      startTime: new Date(),
      projectId: project.id,
      clientId: project.clientId,
      rate: project.type === "perHour" ? project.fee : project.client.defaultRate,
      roundType: project.roundType,
    };

    await dispatch(startTimer(model));
    notify(`Timer started for ${project.name}`, "info");
  }

  if(activeTimer){
    const isMine = (activeTimer.projectId === project.id);
    const tip =  isMine ? "A timer is running for this project" : "A timer is running for a different project";
    const color = isMine ? classes.forceSecondary : undefined;

    return (
      <Tooltip title={tip}>
        <span>
          <IconButton disabled className={color} size={size || "small"} onClick={onStartTimer}>{Icons.timer(iconSize)}</IconButton>
        </span>
      </Tooltip>
    );
  }
  else{
    return (
      <Tooltip title="Start a timer for this project">
        <IconButton size={size || "small"} onClick={onStartTimer}>{Icons.timer(iconSize)}</IconButton>
      </Tooltip>
    );
  }
}

export default ProjectTimerButton;
