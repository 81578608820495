import { IGoal, RootState } from "types";
import { createSelector } from "@reduxjs/toolkit";
import { goalDefinitions } from "./goals-config";
import { selectAllClients, selectAllProjects } from "features/app/infra/common-selectors";
import { getGoalData } from "./goal-helpers";

// const _getId = (state: RootState, id: string) => id;
const _getGoals = (state: RootState) => state.goals.items;

export const selectGoals = createSelector(
  _getGoals,
  (goals) => {
    if(!goals) return [];
    const definitions = goalDefinitions;

    const items = goals.map(g => {
      const def = definitions.find(d => d.id === g.definitionId);
      return {
        ...g,
        definition: def,
      } as IGoal;
    });

    return items;
  }
);

//TODO: Change this so it's a getter, and there's a selector that gets
// data for all the goals (since this doesn't use re-re-select)
export const selectGoalsWithData = createSelector(
  selectGoals, selectAllClients, selectAllProjects,
  (goals, clients, projects) => {
    
    const goalsWithData = goals.map(g => {
      return {
        ...g, 
        data: getGoalData(g, clients, projects),
      } as IGoal;
    });
    
    return goalsWithData;
  }
);

