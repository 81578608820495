import _ from "lodash";
import moment, { Moment } from "moment";
import { shallowEqual } from "react-redux";
import { isSameMinute } from "date-fns";
import { Indexable } from "types";
import { REDUX_DATE_FORMAT, DISPLAY_DATE_FORMAT } from "./date-helpers";

export function tryParseInt(value: any, fallback: any) {
  try {
    const val = parseInt(value);
    return isNaN(val) ? fallback : val;
  } catch (ex) {
    return fallback === undefined ? -1 : fallback;
  }
}

export function tryParseFloat(value: any, fallback: any) {
  try {
    const val = parseFloat(value);
    return isNaN(val) ? fallback : val;
  } catch (ex) {
    return fallback === undefined ? -1 : fallback;
  }
}

export function isStringNumeric(val: string) {
  const chars = val.split("");
  const notNumeric = chars.find(c => c !== "." && c !== "-" && tryParseInt(c, null) === null);
  return !notNumeric;
}

export function updateUrlSearch(key: string, value: any) {
  const url = new URL(window.location.href);
  if (url.searchParams.has(key) && !value)
    url.searchParams.delete(key);
  else
    url.searchParams.set(key, value);

  const relative = url.href.replace(window.location.origin, ""); //need to remove the origin for sake of the history
  return decodeURIComponent(relative);  //don't want the encoding at this point
}


type ArrayCompareFunc = (v1: any, v2: any) => number;
export function areArraysEqual(value1: any[] | null | undefined, value2: any[] | null | undefined, compareFn: ArrayCompareFunc | undefined = undefined){
  if(!value1 || !value2) return false;  //if either OR BOTH are not arrays, return false
  if(value1.length != value2.length) return false;  //need to be the same size
  return _.isEqual(value1.slice().sort(compareFn), value2.slice().sort(compareFn));
}

//-- Checks to see if a value has changed, handling object values (shallow) and array values (simple compare)
export function hasValueChanged(propName: string, original: any, changes: any): boolean{
  if(_.isDate(original[propName])){
    return !isSameMinute(original[propName], changes[propName]);
  }
  else if(_.isObject(original[propName])){
    return !shallowEqual(changes[propName], original[propName]);
  }
  else if(_.isArray(original[propName])){
    return areArraysEqual(original[propName], changes[propName]);
  }
  else{
    return changes[propName] !== original[propName];
  }
  // (_.isObject(original[propName] && !shallowEqual(changes[propName], original[propName])) || changes[propName] !== original[propName])
}

export function getChanges(original: any, changes: any): Record<string, unknown> | null {
  const origKeys = _.keys(original);
  const changeKeys = _.keys(changes);

  //Get any fields that have changed between the two
  const changedValues = origKeys.reduce((result, propName) => {
    const hasProp = changes[propName] !== undefined;
    // const valueHasChanged = (_.isObject(original[propName] && !shallowEqual(changes[propName], original[propName])) || changes[propName] !== original[propName]);    
    if ( hasProp && hasValueChanged(propName, original, changes)){
      return { ...result, [propName]: changes[propName] };
    }
    return result;
  }, {});

  //Don't get missing fields because the changes can be a partial...

  //Now, check for new keys on the changes that weren't on the original
  const newKeys = changeKeys.filter(k => !origKeys.includes(k));
  const newValues = newKeys.reduce((result, propName) => {
    return { ...result, [propName]: changes[propName] };
  }, changedValues);

  const keyCount = _.keys(newValues).length;
  return keyCount <= 0 ? null : newValues;
}

export function parseDate(value: any): Date | null {
  if (!value) return null;
  if (value instanceof Date) return value;
  else {
    try {
      return value.toDate();
    }
    catch {
      try {
        return new Date(value);
      }
      catch {
        return null;
      }
    }
  }
}

//-- Does a group by, but returns the results as an array, rather than as an object (like _.groupBy)
export function groupBy<IObj>(items: any[], key: any): { key: any; items: IObj[] }[] {
  return items.reduce(function (rv, x) {
    const v = key instanceof Function ? key(x) : x[key];
    const el = rv.find((r: { key: any }) => r && r.key === v);
    if (el) { el.items.push(x); }
    else {
      rv.push({ key: v, items: [x] });
    }
    return rv;
  }, []);
}

export function getMonth(date: Moment | null) {
  if (!date) return null;
  else{
    const newWay = date.startOf("month").format(REDUX_DATE_FORMAT); //DATE_FORMAT_LONG); 
    return newWay;
  }
}

function isDateField(key: string) {
  const lKey = key.toLowerCase();
  return (lKey === "date" || lKey.endsWith("date") || lKey.endsWith("at") || lKey.endsWith("time"));
}

export function formatDateString(value: string, format: string = DISPLAY_DATE_FORMAT) {
  if (!!value && value.length > 0) {
    return moment(value).format(format)
  }
  return "";
}

//----
// takes all date fields and formats them a specific way
export function formatDateStrings(item: any, format: string = DISPLAY_DATE_FORMAT) {
  const keys = _.keys(item);
  const preped = _.reduce(keys, (accum, key) => {
    if (isDateField(key) && !!item[key]) {
      return {
        ...accum,
        [key]: moment(item[key]).format(format),
      };
    }
    return accum;
  }, item);

  return preped;
}

const tryDateFormat = (value: any, format?: string): moment.Moment | null => {
  try {
    if (format === "firestore") {
      const val = value.toDate();   //try to get the date from a firestore date
      return moment(val);
    }
    else {
      const mmt = moment(value, format);
      return mmt.isValid() ? mmt : null;
    }
  }
  catch {
    return null;
  }
}

//---
// Converts a date values to a common string date format.
export function dateToString(value: any, format = REDUX_DATE_FORMAT): string | null {
  try {
    if (_.isDate(value)) {
      return moment(value).format(format);
    }
    else if (value) {
      const mmt = tryDateFormat(value, "firestore") || tryDateFormat(value);
      return mmt ? mmt.format(format) : null;
    }
    return null;
  }
  catch {
    try {
      const mmt = moment(value.toDate());
      // const mmt = moment(value);
      return mmt.format(format);
    }
    catch {
      return value;
    }
  }
}

//---
// Converts all date property values in an object to a common string date format.
export function datesToStrings(item: Indexable, format = REDUX_DATE_FORMAT) {
  const keys = _.keys(item);
  const preped: Indexable = _.reduce(keys, (accum, key) => {
    if (isDateField(key) && !!item[key]) {
      return {
        ...accum,
        [key]: dateToString(item[key], format),
      };
    }
    else if(_.isArray(item[key])){
      if(item[key].length > 0 && _.isObject(item[key][0])){
        const updated = item[key].map((i: any) => datesToStrings(i, format));
        return {
          ...accum,
          [key]: updated,
        };
      }
    }
    // else if(item[key] instanceof firebase.firestore.Timestamp)
    else if(_.isObject(item[key])){
      return {
        ...accum,
        [key]: datesToStrings(item[key], format),
      }
    }
    return accum;
  }, item);

  return preped;
}

//---
// Converts a string date value to a date object.
export function stringToDate(value: string | Date | null): Date | null {
  try {
    if (_.isDate(value)) return value;
    else if (_.isString(value)) {
      return new Date(value);
    }
    return null;
  }
  catch {
    return null;
  }
}

export function stringsToDates<T extends Indexable>(item: T) {
  const keys = _.keys(item);
  const preped: T = _.reduce(keys, (accum, key) => {
    // const lKey = key.toLowerCase();
    
    if (isDateField(key) && !!item[key]) {
      return {
        ...accum,
        [key]: stringToDate(item[key]),
      };
    }
    else if(_.isArray(item[key]) && item[key].length > 0 && _.isObject(item[key][0])){
      const updated = item[key].map((i: any) => stringsToDates(i));
      return {
        ...accum,
        [key]: updated,
      };
    }
    else if(_.isObject(item[key])){
      return {
        ...accum,
        [key]: stringsToDates(item[key]),
      };
    }

    return accum;
  }, item);

  return preped;
}

//---
// Converts all string property values for an object to lowercase.
export function allLowerCase(item: any) {
  const keys = _.keys(item);
  const preped = _.reduce(keys, (accum, key) => {
    if (!!item[key] && _.isString(item[key])) {
      return {
        ...accum,
        [key]: item[key].toLowerCase(),
      };
    }
    return accum;
  }, item);

  return preped;
}

export function replaceAt<T>(array: T[], index: number, value: T) {
  const ret = array.slice(0);
  ret[index] = value;
  return ret;
}

export function replaceItem<T>(array: T[], item: T, value: T) {
  const index = _.findIndex(array, item);
  const ret = array.slice(0);
  ret[index] = value;
  return ret;
}

export function removeItem<T>(array: T[], itemOrPredicate: T | any) {
  const isFunc = _.isFunction(itemOrPredicate);
  const index = isFunc ? _.findIndex(array, itemOrPredicate) : _.indexOf(array, itemOrPredicate);
  if (index >= 0) {
    return array.length === 1 ? [] : [...array.slice(0, index), ...array.slice(index + 1)];
  }
  else {
    return array;
  }
}

//---
// Swaps out any null / undefined values with blank strings for use in an input
export function prepareForInputs<T>(item: T, keys: keyof T | (keyof T)[] | undefined = undefined): T {
  if(!item) return item;
  if (!keys) keys = _.keys(item).map(k => k as (keyof T));
  else if (!_.isArray(keys)) keys = [keys];

  const output = keys.reduce((result: T, key: keyof T) => {
    const curr = item[key];
    if (curr !== null && curr !== undefined) return result;
    return { ...result, [key]: "" };
  }, item);

  // console.log(output);
  return output;
}

export function getSeverityBorder(severity: string, palette: any) {
  if (severity) return palette[severity].main;
  else return null;
}

//---
// Removes all the undefined fields from the object
export function removeUndefined<T extends Indexable>(obj: T): T {
  const keys = _.keys(obj);
  const result = obj;
  keys.forEach(key => {
    if (_.isUndefined(obj[key])) {
      delete obj[key];
    }
  });

  return result as T;
}

//---
// Removes unwanted properties from an object
export function removeProps<T>(obj: Indexable, props: string[] | null): T {
  if(!props || !props.length) return obj as T;
  return _.omit(obj, props) as T;
}

export function whitelistProps<T>(obj: Indexable, props: string[] | null) : T {
  if(!props || !props.length) return obj as T;
  return _.pick(obj, props) as T;
}

//----
// Ensures that an object has certain property fields.  If not, will assign default values.
// if no defaults are provided, it will use null as the default value
export function ensureProps<T>(obj: Indexable, props: string[], fallbacks: any = undefined): T {
  const keys = _.keys(obj);
  const result = obj;

  props.forEach((p, i) => {
    if(keys.indexOf(p) < 0){
      //if not an array, use the value or null (if undefined).  If an array, use the specific index, or null if the array isn't big enough
      const fallback = !_.isArray(fallbacks) ? (_.isUndefined(fallbacks) ? null : fallbacks) : fallbacks.length > i ? fallbacks[i] : null;
      result[p] = fallback;
    }
  });

  return result as T;
}

//---
// Does the steps necessary to prepare an object for storage in the DB
export function prepareForDb<T extends Indexable>(
  obj: T, 
  badProps: string[] | null = null, 
  reqProps: string[] | null = null, 
  whitelist: string[] | null = null): T 
{
  const start = removeProps<T>(obj, badProps);
  const whitelisted = whitelistProps<T>(start, whitelist);
  let prepared = removeUndefined<T>(whitelisted);
  if(!!reqProps) prepared = ensureProps<T>(prepared, reqProps);
  prepared = stringsToDates<T>(prepared);
  return prepared;
}

export function twoChars(value: number): string {
  return value < 10 ? `0${value}` : value.toString()
}

export function toHours(minutes: number): string {
  return (minutes / 60).toFixed(2)
}

export function roundTo(value: number, places = 2){
  return Number(Math.round(Number(value + "e" + places)) + "e-" + places);
}

export function choose<T, V>(item: T, keys: T[], values: V[], fallback?: V): V {
  if(keys.length !== values.length) throw new Error("arrays must be of the same size");

  const index = keys.indexOf(item);
  if(index < 0){
    if(fallback) return fallback;
    else throw new Error("Out of range");
  }

  return values[index];
}