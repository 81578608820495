import React, { memo } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from '@material-ui/core/Divider';
import { fontStyles } from "utils/styles";

const buildStyles   = makeStyles(theme => ({
  ...fontStyles(theme),
  centered: {
    textAlign: "center",
  }
}));

interface ControlProps{
  label: string;
  className?: string;
}

const SeparatorLine = ({label, className}: ControlProps) => {
  const classes   = buildStyles();

  return (
    <Grid container className={className}>
      <Grid item sm={3}></Grid>
      <Grid item sm={6} container alignItems="center">
        <Grid item md><Divider /></Grid>
        <Grid item md container justify="center" alignItems="center"><Typography className={clsx(classes.infoText, classes.centered)}>{label}</Typography></Grid>
        <Grid item md><Divider /></Grid>
      </Grid>
    </Grid>
  );
}

export default memo(SeparatorLine);
