import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { IStatus, IProject, ProjectSettings, ProjectTracking } from "types";

interface InitialState {
  items: Array<IProject> | null;
  isEmpty: boolean | null;
  isInitialized: boolean;
  settings: ProjectSettings;
  status: IStatus;
  trackedHours: ProjectTracking[] | null;
}

export const initialState: InitialState = {
  items: null,
  isEmpty: null,
  isInitialized: false,
  settings: {
    filter: "active",
    sort: "dueDate",
    sortDir: "asc",
    search: null,
    showDates: false,
    listView: "table",
  },
  status: {
    isWorking: false,
    error: null,
  },
  trackedHours: null,
};

export const slice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    statusChanged: (state, action) => {
      state.status = {
        ...state.status,
        ...action.payload,
      };
    },
    updateProjectSettings: (state, action) => {
      const updates = { ...state.settings, ...action.payload };
      state.settings = updates;
    },
    clearStore: (state) => {
      state.items = initialState.items;
      state.isEmpty = initialState.isEmpty;
      state.isInitialized = initialState.isInitialized;
      state.settings = {...initialState.settings};
      state.status = {...initialState.status};
      state.trackedHours = initialState.trackedHours;
    },
    projectsLoaded: (state, action) => {
      state.items = action.payload;
      state.isEmpty = Boolean(!action.payload || action.payload.length === 0);
      state.isInitialized = true;
      state.status = {isWorking: false, error: null};
    },
    projectAdded: (state, action) => {
      if (state.items === null) state.items = [];
      state.items.push(action.payload);
      state.isEmpty = false;
      state.status = {isWorking: false, error: null};
    },
    projectsAdded: (state, action: PayloadAction<IProject[]>) => {
      if (state.items === null) state.items = [];
      state.items.push(...action.payload);
      state.isEmpty = false;
      state.status = {isWorking: false, error: null};
    },
    projectUpdated: (state, action) => {
      const existing = state.items?.find(prj => prj.id === action.payload.id) as any;
      if (!!existing) {
        _.keys(action.payload.changes).forEach(key => {
          existing[key] = action.payload.changes[key];
        });
      }
      state.status = {isWorking: false, error: null};
    },
    projectTrackedMinutesUpdated: (state, action) => {
      if(state.items && action.payload){
        const { totals, tracking } = action.payload;
        
        if(totals){
          totals.forEach((ttl: any) => {
            const prj = state.items?.find(p => p.id === ttl.projectId);
            if(prj) prj.trackedMinutes = ttl.trackedMinutes;
          });
        }
        
        //update the trackedHours collect
        if(tracking){
          tracking.forEach((trk: any) => {
            let prjHours = trk ? state.trackedHours?.find(ph => ph.projectId === trk.projectId) : null;
            if(!prjHours){
              prjHours = {
                projectId: trk.projectId,
                days: {
                  [trk.key]: trk.minutes,
                }
              };
              state.trackedHours = [...(state.trackedHours || []), prjHours];
            }
            else{
              prjHours.days[trk.key] = trk.minutes;
            }
          });
        }
      
        state.status = {isWorking: false, error: null};
      }
    },
    projectHoursLoaded: (state, action) => {
      state.trackedHours = action.payload;
    },
    clearItems: (state) => {
      state.items = initialState.items;
      state.isEmpty = initialState.isEmpty;
      state.trackedHours = initialState.trackedHours;
    },
    
  },
});

export const { statusChanged, clearStore, clearItems, projectsLoaded, projectAdded, projectsAdded, projectUpdated, updateProjectSettings, projectTrackedMinutesUpdated, projectHoursLoaded } = slice.actions;
export default slice.reducer;

//====== ACTIONS ======





