import React, { useCallback, useRef, useState } from "react";
import _ from "lodash";
import { INotify } from "types";
import { AppContext, IAppContext } from "features/app/app-context";
import { trackClick } from "utils/mixpanel";

export type AnchorPosition = "bottomRight" | "bottomLeft" | "bottomCenter" | undefined;

export const AnchorPositions: { [key: string]: any } = {
  bottomRight: {
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "right",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "right",
    },
  },
  bottomLeft: {
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
  },
  bottomCenter: {
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
  },
};

//==================
// Anchor Hook
// export interface IAnchorHandler {
//   anchor  : object,
//   isOpen  : boolean,
//   onOpen  : React.MouseEventHandler<HTMLElement>,
//   onClose : (any) => void,
//   positionProps   : "bottomRight" | "bottomLeft" | "bottomCenter" | undefined,
// }

export function useAnchor(
  trackingId: string | null = null,
  position: AnchorPosition = "bottomRight"
): [
    Element | null,
    boolean,
    React.MouseEventHandler<HTMLElement>,
    (ignored?: any) => void,
    any
  ] {
  const [anchor, setAnchor] = React.useState<Element | null>(null);

  const onOpen = (e: React.MouseEvent<HTMLElement>) => {
    if(trackingId){
      trackClick(trackingId);
    }
    setAnchor(e.currentTarget);
  };

  const onClose = () => {
    setAnchor(null);
  };
  const positionProps = AnchorPositions[position]; //positions[position] || positions["bottomRight"];

  return [anchor, Boolean(anchor), onOpen, onClose, positionProps];
}


//==================
// Snackbar Notifications
// add a notification and wire up the hook to a snackbar and you're good to go
type SnackSeverity = "error" | "info" | "success" | "warning" | undefined;
interface SnackbarBindings {
  snackbar: {
    open: boolean;
    autoHideDuration: number;
    onClose: () => void;
  };
  alert: {
    severity: SnackSeverity;
    onClose: () => void;
  };
}
type CreateNotification = (notification: INotify | string, severity?: SnackSeverity) => void;

export function useSnackbarNotify(duration = 5000): [INotify | null, CreateNotification, SnackbarBindings] {
  const [notification, setNotification] = React.useState<INotify | null>(null);

  const create = (notification: INotify | string, severity: SnackSeverity = undefined) => {
    if (_.isString(notification)) {
      setNotification({ message: notification, severity: severity });
    }
    else {
      setNotification(notification);
    }
  }

  const close = () => {
    setNotification(null);
  }

  const bindings: SnackbarBindings = {
    snackbar: {
      open: !!notification,
      autoHideDuration: duration,
      onClose: close,
    },
    alert: {
      severity: notification?.severity,
      onClose: close,
    }
  }

  // const result = [notification, bindings];
  return [
    notification,
    create,
    // close,
    bindings
  ];
}

export function useSnackbar() {
  const ctx = React.useContext<IAppContext>(AppContext);
  return ctx.notify;
}

export const useHover = <T extends HTMLElement>(): [
  (node?: T | null) => void,
  boolean,
] => {
  const [value, setValue] = useState(false)

  // Wrap in useCallback so we can use in dependencies below
  const handleMouseEnter = useCallback(() => setValue(true), [])
  const handleMouseLeave = useCallback(() => setValue(false), [])

  // Keep track of the last node passed to callbackRef
  // so we can remove its event listeners.
  const ref = useRef<T>()

  // Use a callback ref instead of useEffect so that event listeners
  // get changed in the case that the returned ref gets added to
  // a different element later. With useEffect, changes to ref.current
  // wouldn't cause a rerender and thus the effect would run again.
  const callbackRef = useCallback<(node?: null | T) => void>(
    node => {
      if (ref.current) {
        ref.current.removeEventListener(
          'mouseenter', //'mouseover',
          handleMouseEnter,
        )
        ref.current.removeEventListener(
          'mouseleave', //'mouseout',
          handleMouseLeave,
        )
      }

      ref.current = node || undefined

      if (ref.current) {
        ref.current.addEventListener(
          'mouseenter', //'mouseover',
          handleMouseEnter,
        )
        // ref.current.addEventListener('mouseout', handleMouseOut)
        ref.current.addEventListener('mouseleave', handleMouseLeave)
      }
    },
    [handleMouseEnter, handleMouseLeave],
  )

  return [callbackRef, value]
}

//============
// Tabs
// a hook to simplify management of tabs that connect to the location
// export const useTabNavigation = (location: Location<unknown>, tabs: IMenuItem) => {

//   //Effect to keep the location in sync with the tabs
//   useEffect(() => {
//     const path = loc.pathname;
//     const index = tabMap.findIndex(t => t === path);
//     if(tabIndex !== index) setTabIndex(index);
//     if(tabItem.id !== index) setTabItem(tabMenuItems[index]);
//   }, [loc.pathname, tabIndex]);

// }