import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

interface IErrorProps {
  message: string | null | undefined;
  onClose?: () => void | null | undefined;
}

const buildStyles = makeStyles(theme => ({
  errorCard: {
    margin: `${theme.spacing(2)}px ${theme.spacing(3)}px`, //`
    marginTop: theme.spacing(1),
    background: theme.palette.error.light,
    borderColor: theme.palette.error.dark,
  },
  errorGrid: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  errorMessage: {
    fontSize: 16,
    fontWeight: 400,
    marginBottom: theme.spacing(1),
    color: theme.palette.error.main,
  },
}));

const AtkErrorDisplay = (props: IErrorProps) => {
  const classes = buildStyles();

  if (!props.message) return null;

  return (
    <Card id="error-card" variant="outlined" className={classes.errorCard}>
      <Grid container alignItems="center" className={classes.errorGrid}>
        <Typography variant="h5" color="primary" className={classes.errorMessage}>{props.message}</Typography>
        {/* <Typography variant="h6" color="secondary" className={classes.subTitle}>This is my component</Typography> */}
      </Grid>
    </Card>
  );
}

export default AtkErrorDisplay;
