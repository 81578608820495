import React, { useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { generalStyles } from 'utils/styles/common-styles';
import OnboardingProfile from "./onboarding-profile";
import OnboardingClients from "./onboarding-clients";
import OnboardingProjects from "./onboarding-projects";
import OnboardingChoice from "./onboarding-choice";
import OnboardingGenerate from "./onboarding-generate";
import { useBoolState } from "utils/hooks";
import { useSelector } from "react-redux";
import { RootState } from "types";
import { trackDisplayType } from "utils/mixpanel";
import { useRouteMatch } from "react-router-dom";
import { routes } from "features/app";
// import OnboardingHeader from "./onboarding-header";
import OnboardingRemove from "./onboarding-remove";

const buildStyles = makeStyles(theme => ({
  ...generalStyles(theme),
  card: {
    marginTop: theme.spacing(5),
    marginLeft: "auto",
    marginRight: "auto",
    width: "67%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    }
  },
  grid: {
    padding: theme.spacing(1),
  },
  nextContainer: {
    height: 250,
  },
  nextGrid: {
    minHeight: 375,
  }
}));



export enum OnboardingScreens {
  profile = 0,
  choice = 10,
  generate = 20,
  samplesReady = 30,
  clients = 40,
  projects = 50,
  removeSamples = 60,
}
export interface INavState {
  canPrevious: boolean;
  hidePrevious?: boolean;
  canNext: boolean;
  hideNext?: boolean;
  previousTitle: string;
  nextTitle: string;
  nextOverride: null | OnboardingScreens; //((change: number) => number);
  prevOverride: null | OnboardingScreens;
}

export interface IStepProps {
  onNavStateChange: (props: Partial<INavState>) => void;
}

const OnboardingView = () => {
  const classes = buildStyles();
  const isSamplesRoute = useRouteMatch(routes.samples);
  // const isOnboarding = useSelector((state: RootState) => state.app.isOnboarding);
  // const onboarding = useSelector((state: RootState) => state.app.org.onboarding);
  // const wasSample = Boolean(onboarding && onboarding.sampleEndDate);
  const [step, setStep] = useState<OnboardingScreens>(OnboardingScreens.profile);
  const [prevStep, setPrevStep] = useState<OnboardingScreens>(OnboardingScreens.profile);
  const [navState, setNavState] = useState<INavState>({canPrevious: false, canNext: true, previousTitle: "Previous", nextTitle: "Next", nextOverride: null, prevOverride: null});
  // const [isSample, toggleSample] = useBoolState(!wasSample);

  console.log("samples page:", isSamplesRoute);
  // const nextLabel = isSample ? "Use My Data" : "Hmm... did you say sample data?";
  
  // const toggleDisplay = () => {
  //   trackDisplayType("onboarding", isSample ? "use-mine" : "sample");
  //   toggleSample();
  // }

  const onNavStateChange = useCallback((props: Partial<INavState>) => {
    const newVal = {...navState, ...props};
    setNavState(newVal);
  }, [navState]);

  const onChangeStep = (change: number) => () => {
    //Allow the current child to override the next step...
    const isForward = Boolean(change > 0);
    // const isOverride = isForward ? Boolean(navState.nextOverride !== null) : Boolean(navState.prevOverride !== 0);
    let next = 0;
    if(isForward){
      next = navState.nextOverride !== null ? navState.nextOverride : step + change;
    }
    else if(!isForward){
      next = navState.prevOverride !== null ? navState.prevOverride : step + change;
    }

    if(next >= OnboardingScreens.profile && next <= OnboardingScreens.removeSamples){
      //reset nav state before switching the step
      onNavStateChange({canNext: false, canPrevious: next >= OnboardingScreens.profile, nextOverride: null, prevOverride: null, hideNext: false, hidePrevious: false, previousTitle: "Previous", nextTitle: "Next"});
      setPrevStep(step);
      setStep(next);
    }
  }

  return (
    <Card className={classes.card}>
        <Grid container justify="center" className={classes.grid}>
          {isSamplesRoute &&
            <OnboardingRemove />
          }
          {!isSamplesRoute &&
            <>
              {step === OnboardingScreens.profile && 
                <Slide direction={prevStep >= OnboardingScreens.profile ? "right" : "left"} in={step === OnboardingScreens.profile}>
                  <Grid container className={classes.nextGrid}>
                    <OnboardingProfile onNavStateChange={onNavStateChange} />
                  </Grid>
                </Slide>
              }
              {step === OnboardingScreens.choice && 
                <Slide direction={prevStep >= OnboardingScreens.choice ? "right" : "left"} in={step === OnboardingScreens.choice}>
                  <Grid container className={classes.nextGrid}>
                    <OnboardingChoice onNavStateChange={onNavStateChange} />
                  </Grid>
                </Slide>
              }
              {step === OnboardingScreens.generate && 
                <Slide direction={prevStep >= OnboardingScreens.generate ? "right" : "left"} in={step === OnboardingScreens.generate}>
                  <Grid container className={classes.nextGrid}>
                    <OnboardingGenerate onNavStateChange={onNavStateChange} />
                  </Grid>
                </Slide>
              }
              {step === OnboardingScreens.clients && 
                <Slide direction={prevStep >=  OnboardingScreens.clients ? "right" : "left"} in={step === OnboardingScreens.clients}>
                  <Grid container className={classes.nextGrid}>
                    <OnboardingClients onNavStateChange={onNavStateChange}/>
                  </Grid>
                </Slide>
              }
              {step === OnboardingScreens.projects && 
                <Slide direction="left" in={step === OnboardingScreens.projects}>
                  <Grid container className={classes.nextGrid}>
                    <OnboardingProjects onNavStateChange={onNavStateChange} />
                  </Grid>
                </Slide>
              }

              <Grid item md={7} container justify="space-between">
                {navState.hidePrevious !== true && <Button onClick={onChangeStep(-10)} startIcon={<ArrowBackIcon />} disabled={!navState.canPrevious || step < OnboardingScreens.choice}>{navState.previousTitle || "Previous"}</Button>}
                {navState.hideNext !== true && <Button onClick={onChangeStep(10)} endIcon={<ArrowForwardIcon />} disabled={!navState.canNext || step >= OnboardingScreens.removeSamples}>{navState.nextTitle || "Next"}</Button>}
              </Grid>

              {/* <Grid item container>
                <Grid container className={classes.nextContainer}>
                  {isSample && 
                    <Slide direction="right" in={isSample}>
                      <Grid container alignItems="center" className={classes.nextGrid}>
                        <OnboardingSamples/>
                      </Grid>
                    </Slide>
                  }
                  {!isSample && 
                    <Slide direction="left" in={!isSample}>
                      <Grid container alignItems="center" className={classes.nextGrid}>
                        <OnboardingClients/>
                        <OnboardingProjects />
                      </Grid>
                    </Slide>
                  }
                </Grid>

                <Grid container justify="center">
                  {!wasSample && <Button onClick={toggleDisplay} disabled={isOnboarding}>{nextLabel}</Button>}
                </Grid>
              </Grid> */}
            </>
          }
        </Grid>
    </Card>
  );
}

export default OnboardingView;
