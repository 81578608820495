import _, { compact, groupBy, isString, keys, sumBy } from "lodash";
import moment from "moment";
import { IRange, ITimer, ITimerGroupKey, Range, RoundingTypes } from "types";
import { parseDate, twoChars } from "utils/general-helpers";
import { seriesColorByKey } from "utils/chart-helpers";
import { dayLabels, formatDate } from "utils/date-helpers";
import { compareAsc, differenceInMinutes } from "date-fns";

export const parseGroupKey = (key: string): ITimerGroupKey => {
  const parts = key.split("~");
  return {
    grouping: parts[0],
    date: parseDate(parts[1]) as Date,
    projectId: parts[2],
    category: parts[3]
  };
};

export const groupHours = (grouping: string, hours: ITimer[]) => {
  const sorted = [...hours].sort((a,b) => compareAsc(a.startTime as Date, b.startTime as Date));
  const grouped = groupBy(sorted, h => {
    return [
      grouping,
      formatDate(h.startTime),
      h.projectId,
      h.category
    ].join("~");
  });
  const asArray = keys(grouped).map(id => {
    const items = grouped[id];
    //default to the minutes property, but calculate if that's not present
    const minutes = sumBy(items, tmr => tmr.minutes || differenceInMinutes(tmr.stopTime as Date, tmr.startTime as Date));
    const creditMinutes = sumBy(items, tmr => tmr.creditMinutes || 0);
    const notes = compact(items.map(t => t.notes)).join(';');
    return {id, items, minutes, creditMinutes, notes};
  });
  // console.log(asArray);
  return asArray;
}

export function getDuration(from: moment.Moment | Date, to: moment.Moment | Date | undefined = undefined): { hours: number; minutes: number } {
  const end = to ? (moment.isMoment(to) ? to.toDate() : to) : new Date();
  const begin = (moment.isMoment(from) ? from.toDate() : from);
  const diff = differenceInMinutes(end, begin);
  const mins = parseInt((diff % 60).toString());
  const hrs = parseInt((diff / 60).toString());
  return { hours: hrs, minutes: mins };
}

export function getDurationFromMinutes(minutes: number): { hours: number; minutes: number } {
  const mins = parseInt((minutes % 60).toString());
  const hrs = parseInt((minutes / 60).toString());
  return { hours: hrs, minutes: mins };
}

export function formatDuration(duration: {hours: number; minutes: number}, asFraction = false){
  if(asFraction === true){
    return `${(((duration.hours * 60) + duration.minutes) / 60).toFixed(2)}`;
  }
  else{
    return `${duration.hours}:${twoChars(duration.minutes)}`;
  }
}

export function formatMinutesDuration(minutes: number, asFraction = false){
  if(asFraction === true){
    return `${(minutes / 60).toFixed(2)}`;
  }
  else{
    const duration = getDurationFromMinutes(minutes);
    return `${duration.hours}:${twoChars(duration.minutes)}`;
  }
}

export function calcMinutes(from: Date | string, to: Date | string): number {
  const fromDate = _.isDate(from) ? from : parseDate(from);
  const toDate = _.isDate(to) ? to : parseDate(to);

  if (!fromDate || !toDate) throw new Error("Invalid inputs");

  const secs = (toDate.getTime() - fromDate.getTime()) / 1000;
  const mins = secs / 60;
  return mins;
}

export function roundMinutes(minutes: number, roundType: RoundingTypes | null | undefined) {
  let result = Math.round(minutes); //round off seconds

  if (!roundType || roundType === "none") return result; //still round the minutes to get rid of seconds...
  const hours = Math.floor(result / 60);
  const extra = Math.round(result % 60);
  if (extra === 0) return result;   //No remainder, it's an even hour amount

  if (roundType.startsWith("round")) {
    const toNum = parseInt(roundType.substr(5));
    const baseMin = Math.floor(extra / toNum) * toNum;
    const extraMin = Math.floor(extra % toNum);
    const line = (toNum / 2);
    result = (hours * 60) + baseMin + (extraMin > line ? toNum : 0);
  }
  else if (roundType.startsWith("push")) {
    const toNum = parseInt(roundType.substr(4));
    const baseMin = Math.floor(extra / toNum) * toNum;
    const extraMin = Math.floor(extra % toNum);
    result = (hours * 60) + baseMin + (extraMin > 0 ? toNum : 0);
  }

  return result;
}

export const hoursLabelFormatter = (v: any) => v ? parseFloat(v).toFixed(2) : "";
export const labelFormatter = (key: string) => (v: any) => { 
  if(key === "week"){ //v.indexOf("/") > 0){
    return `week ending ${moment(v).format("M/D")}`;
  }
  else if(key === "months"){ //(v.length === 1){
    return `${v} ${moment().format("yyyy")}`;
  }
  else if(key === "day"){
    const dayIndex = dayLabels.indexOf(v);
    const mmt = moment().startOf("weeks").add(dayIndex, "days");
    return mmt.format("dddd M/D");
  }
  else{ //unknown type
    return v;
  }
};
export const axisLabelFormater = (key: string) => (v: any) => {
  if(key === "months" && isString(v)){
    return v.slice(0, 1);
  }
  else if(key === "months"){
    return formatDate(new Date(v), "M/D");
  }
  else return v;
}

export type ChartOptions = {
  colorKey?: string;
  hideXAxis?: boolean;  
}

export const summaryBarOptions = (id: string, labelsKey: string, xAxisCategories: string[], colorKey: string | null = null, options: ChartOptions | null = null) => ({
  chart: {
    id: id,
    type: 'bar',
    toolbar: {
      show: false,
    }
  },
  grid: {
    show: false,
  },
  stroke: {
    width: 1
  },
  dataLabels: {
    enabled: false,    
  },
  xaxis: {
    categories: xAxisCategories,
    show: !options?.hideXAxis, //true,
    labels: {
      show: !options?.hideXAxis, //true,
      formatter: axisLabelFormater(labelsKey),
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    }
  },
  yaxis: {
    show: false,
  },
  tooltip: {
    x: {
      formatter: labelFormatter(labelsKey),
    },
    y: {
      formatter: hoursLabelFormatter, //(v: any) => v ? formatCurrency(v) : "",
    }
  },
  ...seriesColorByKey(colorKey),
});

export const summaryAreaOptions = (id: string, labelsKey: string, xAxisCategories: string[], colorKey: string | null = null) => {
  const result = {
    chart: {
      id: id,
      type: 'area',
      toolbar: {
        show: false,
      }
    },
    grid: {
      show: false,
    },
    stroke: {
      width: 2
    },
    dataLabels: {
      enabled: false,    
    },
    xaxis: {
      type: "datetime",
      show: false, //options?.hideXAxis, //true,
      labels: {
        show: false, //!options?.hideXAxis, //true,
        // formatter: axisLabelFormater(labelsKey),
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      tooltip: {
        enabled: false,
      }
    },
    yaxis: {
      show: false,
    },
    tooltip: {
      // show: true,
      x: {
        formatter: axisLabelFormater(labelsKey), //"M/D",
      },
      y: {
        formatter: hoursLabelFormatter,
      }
    },
    ...seriesColorByKey(colorKey),  
  };
 
  return result;
};

const now = moment();
export const Ranges: IRange[] = [
  { id: Range.thisMonth, label: "This Month", start: now.clone().startOf("month").toDate(), end: now.clone().endOf("month").toDate(), },
  { id: Range.thisWeek, label: "This Week", start: now.clone().startOf("week").toDate(), end: now.clone().endOf("week").toDate(), },
  { id: Range.last7, label: "Last 7 days", start: now.clone().subtract(1, "week").toDate(), end: now.clone().endOf("day").toDate(), },
  { id: Range.last30, label: "Last 30 days", start: now.clone().subtract(30, "days").toDate(), end: now.clone().endOf("day").toDate(), },
  { id: Range.lastMonth, label: "Last Month", start: now.clone().subtract(1, "month").startOf("month").toDate(), end: now.clone().subtract(1, "month").endOf("month").toDate(), },
  { id: Range.lastWeek, label: "Last Week", start: now.clone().subtract(1, "week").startOf("week").toDate(), end: now.clone().subtract(1, "week").endOf("week").toDate(), },
  { id: Range.thisQuarter, label: "This Quarter", start: now.clone().startOf("quarter").toDate(), end: now.clone().endOf("quarter").toDate(), },
  { id: Range.lastQuarter, label: "Last Quarter", start: now.clone().subtract(1, "quarter").startOf("quarter").toDate(), end: now.clone().subtract(1, "quarter").endOf("quarter").toDate(), },
  { id: Range.thisYear, label: "This Year", start: now.clone().startOf("year").toDate(), end: now.clone().endOf("year").toDate(), },
  { id: Range.lastYear, label: "Last Year", start: now.clone().subtract(1, "year").startOf("year").toDate(), end: now.clone().subtract(1, "year").endOf("year").toDate(), },
];

export const getRange = (id: Range | undefined | null, fallback = Range.last7) => {
  const found = id ? Ranges.find(r => r.id === id) : null;
  return found ?? Ranges.find(r => r.id === fallback) ?? Ranges[2];
}