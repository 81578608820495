import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import LinearProgress from '@material-ui/core/LinearProgress';
import { useAuthWithActions } from "utils/hooks";

const buildStyles   = makeStyles(theme => ({
  root  : {
    padding     : theme.spacing(1),
    paddingTop: theme.spacing(3),
    height: "100%",
  },
  title   : {
    fontSize    : 22,
    fontWeight  : 500,
    textAlign   : "center",
    marginBottom: theme.spacing(1),
  },
  bar: {
    minWidth: "125px",
    width: "33%",
  },
}));

const AppLogout = () => {
  const classes   = buildStyles();
  const history = useHistory();
  const auth = useAuthWithActions();
  
  React.useEffect(() => {
    async function logUserOut(){
      await auth.logout();
      history.push("/");
    }
    logUserOut();
  }, []);

  return (
    <Grid id="logout-view" container direction="column" alignContent="center" className={classes.root}>
      <Grid item xs={12}>        
        <Typography variant="h5" color="primary" className={classes.title}>Please wait while we log you out...</Typography>
        <LinearProgress className={classes.bar} />
      </Grid>
    </Grid>
  );
}

export default AppLogout;
