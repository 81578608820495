import React, { useMemo } from "react";
import clsx from "clsx";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from '@material-ui/core/Tooltip';
import ChartPanel from "features/dashboard/chart-panel";
import { fontStyles } from "utils/styles";
import { getDate, getDayOfYear, getDaysInMonth } from "date-fns";
import { formatCurrencyK } from "utils/number-helpers";

const buildStyles   = makeStyles(theme => ({
  ...fontStyles(theme),
  root  : {
    padding     : theme.spacing(1),
  },
  chartContainer: {
    height: 40,
    overflowY: "hidden",
    marginBottom: theme.spacing(0.5),
  },
  chartCaption: {
    // marginTop: theme.spacing(-4),
    whiteSpace: "nowrap",
  }
}));

interface GoalProps {
  label: string;
  value: number | null;
  goal: number | null;
  period: "month" | "year";
}

const goalDonutOptions = (startColor: string, labelColor: string, props: any, goal: number | null) =>  ({
  chart: {
    type: "radialBar",    
  },
  stroke: {
    lineCap: "round",
  },
  labels: ["Progress"],
  colors: [startColor],
  plotOptions: {
    radialBar: {
      startAngle: -90,
      endAngle: 90,
      hollow: {
        margin: 0,
        size: "60%",
        background: `${startColor}33`,
      },
      track: {
        dropShadow: {
          enabled: true,
          top: 2,
          left: 0,
          blur: 2,
          opacity: 0.15
        }
      },
      dataLabels: {
        name: {
          offsetY: goal === 0 ? -4 : -7,
          show: (goal === 0),
        },
        value: {
          color: labelColor, //"#fff",
          offsetY: -3, //props.offsetY, //10,
          fontSize: "16px", //props.fontSize, //"30px",
          show: true,          
        },
        total: {
          color: `${labelColor}77`,
          fontSize: "10px",
          show: true,
          label: goal === 0 ? "$0" : "",
          formatter: (val: any) => { 
            return goal === 0 ? "" : `${val.config.series[0]}%`; 
          },
        }
      },    
    },  
  },
  grid: {
    padding: {
      top: props.padding, //-20,
      bottom: props.padding, //-20,
      left: props.padding, //-20,
      right: props.padding, //-20,
    },
  },
});

const chooseColors = (value: number | null, theme: Theme) => {
  if(value === null){
    return {
      startColor: theme.palette.grey[500], 
      labelColor: theme.palette.grey[800],
      tip: "You don't have a goal set",
    };
  }
  else if(value < 70){
    return {
      startColor: theme.palette.error.main, 
      labelColor: theme.palette.error.contrastText,
      tip: "You are behind schedule on this goal",
    };
  }
  else if(value < 90){
    return {
      startColor: theme.palette.warning.dark, 
      labelColor: theme.palette.warning.contrastText,
      tip: "You are slightly behind schedule on this goal",
    };
  }
  else if(value < 110){
    return {
      startColor: theme.palette.info.dark, 
      labelColor: theme.palette.info.contrastText,
      tip: "You are on schedule for this goal",
    };
  }
  else{
    return {
      startColor: theme.palette.success.main, 
      labelColor: theme.palette.success.contrastText,
      tip: "You are ahead of schedule for this goal",
    };
  }
}

const ClientGoal = ({label, value, goal, period}: GoalProps) => {
  const classes = buildStyles();
  const theme = useTheme();
  const chartProps = {fontSize: "16px", chartHeight: 75, padding: -7, offsetY: -30,}; //getChartProps("m");
  const percent = useMemo(() => value && goal ? parseInt(((value / goal) * 100).toString()) : 0, [goal, value]);
  const chartData = [percent];

  const expected = useMemo(() => {
    if(!goal) return null;
    const today = new Date();
    const part = period === "year" ? getDayOfYear(today) : getDate(today);
    const whole = period === "year" ? 365 : getDaysInMonth(today);
    const ratio = (part / whole);
    const shouldBeAt = goal * ratio;
    const progress = parseInt((((value || 0) / shouldBeAt) * 100).toString());
    return progress;
  }, [period, value, goal]);

  const colors = chooseColors(expected, theme);
  const chartOptions = goalDonutOptions(colors.startColor, colors.labelColor, chartProps, goal);

  return (
    <Tooltip title={colors.tip}>
      <Grid container direction="column" justify="center" alignItems="center" wrap="nowrap">
        <div className={classes.chartContainer}>
          <ChartPanel options={chartOptions} series={chartData} type="radialBar" height={chartProps.chartHeight} width="100%"/>      
        </div>
        {goal !== 0 && <Typography className={clsx(classes.caption, classes.chartCaption)}>{`${formatCurrencyK(value || 0)} / ${formatCurrencyK(goal || 0)}`}</Typography>}
        <Typography className={clsx(classes.caption)}>{label}</Typography>
      </Grid>
    </Tooltip>
  );
}

export default ClientGoal;
