import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { INote, IStatus } from "types";

interface INotesFilter {
  clientId?: string;
  projectId?: string;
  status?: null | "favorite";
}

interface INotesSettings {
  filter: INotesFilter | null,
  search: string | null;
  view: "cards" | "table";
}

interface InitialState {
  editingId: string | null;
  items: Array<INote> | null;
  isEmpty: boolean | null;
  isInitialized: boolean;
  settings: INotesSettings;
  status: IStatus;  
}

export const initialState: InitialState = {
  editingId: null,
  items: null,
  isEmpty: null,
  isInitialized: false,
  settings: {
    filter: null,
    search: null,
    view: "cards",
  },
  status: {
    isWorking: false,
    error: null,
  },
};

export const slice = createSlice({
  name: "notes",
  initialState,
  reducers: {
    statusChanged: (state, action: PayloadAction<Partial<IStatus>>) => {
      state.status = {
        ...state.status,
        ...action.payload,
      };
    },
    updateNotesSettings: (state, action: PayloadAction<Partial<INotesSettings>>) => {
      const updates = { ...state.settings, ...action.payload };
      state.settings = updates;
    },
    clearStore: (state) => {
      state.editingId = initialState.editingId;
      state.items = initialState.items;
      state.isEmpty = initialState.isEmpty;
      state.isInitialized = initialState.isInitialized;
      state.settings = {...initialState.settings};
      state.status = {...initialState.status};
    },
    itemsLoaded: (state, action: PayloadAction<INote[]>) => {
      state.items = action.payload;
      state.isEmpty = Boolean(!action.payload || action.payload.length === 0);
      state.isInitialized = true;
      state.status = { isWorking: false, error: null };
    },
    toggleEditNote: (state, action: PayloadAction<string>) => {
      const existing = state.items?.find(note => note.id === action.payload) as any;
      if (existing) {
        if(state.editingId === action.payload) state.editingId = null;
        else state.editingId = action.payload;
      }
    },
    itemCreated: (state, action: PayloadAction<INote>) => {
      if(state.isInitialized && state.items !== null){
        state.items.unshift(action.payload);
      }      
      state.isEmpty = false;
      state.status = { isWorking: false, error: null };
    },
    itemUpdated: (state, action: PayloadAction<{id: string, changes: Record<string, unknown>}>) => {
      const existing = state.items?.find(note => note.id === action.payload.id) as any;
      if (!!existing) {
        _.keys(action.payload.changes).forEach(key => {
          existing[key] = action.payload.changes[key];
        });
      }
      if(state.editingId === action.payload.id) state.editingId = null;  //kick it out of edit mode
      state.status = {isWorking: false, error: null};
    }, 
    itemDeleted: (state, action: PayloadAction<{id: string}>) => {
      const deletedId = action.payload.id;
      const existing = state.items?.find(item => item.id === deletedId);
      if (!!existing && state.items) {
        state.items = state.items?.filter(i => i.id !== existing.id);
        state.isEmpty = (state.items.length === 0);
      }      
      state.status = { isWorking: false, error: null };
    },
    
  }
});

export const {
  statusChanged,
  updateNotesSettings,
  clearStore,
  itemsLoaded,
  itemCreated,
  toggleEditNote,
  itemUpdated,
  itemDeleted,
} = slice.actions;

export default slice.reducer;