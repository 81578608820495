import React, { useEffect, useMemo } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import { useBoolState, useDialogs, useLocalization, useSearchStringFilter, useSliceSettings } from "utils/hooks";
import { ClientSettings, IFilter, IMenuItem, LSwapFunc, ClientFilter, Indexable } from "types";
import { ChipMenu, FilterList, SearchInput } from "components";
import { capitalize } from "lodash";
import { generalStyles } from "utils/styles";
import ClientListMenu from "./client-list-menu";
import { useSelector } from "react-redux";
import { selectClientCategories } from "../infra/client-selectors";
import { routes } from "features/app";

const buildStyles   = makeStyles(theme => ({
  ...generalStyles(theme),
  root  : {
    // flexWrap    : "nowrap",
    minHeight: 42,
  },
  title: {
    fontSize: 22,
    fontWeight: 500,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      // marginBottom: theme.spacing(0.5),
    }
  },
  menuBar: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: "center",
    }
  },
  firstCol: {
    padding: `${theme.spacing(0)}px ${theme.spacing(1)}px`, //`
    borderRight: `1px solid ${theme.palette.grey[300]}`, //`
    display: "inline-flex",
    [theme.breakpoints.down('xs')]: {
      borderRight: 'none',
      marginBottom: theme.spacing(1),
      alignItems: 'center',
    }
  },
  lastCol: {
    padding: `${theme.spacing(0)}px ${theme.spacing(1)}px`, //`
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1),
      alignItems: 'center',
    }
  },
  chip: {
    height: 28,
    minWidth: 50,
  },
  chipIcon: {
    height: 28,
    width: 28,
    border: "1px solid rgba(0,0,0,0.23)",
    "& .MuiIcon": {
      fontSize: 22,
    }
  },
  iconButton: {
    padding: 10,
  }, 
  searchContainer: {
    width: "65%",
    [theme.breakpoints.down('xs')]: {
      width: "100%",
    }
  } 
}));

export const filterLabels: Indexable = {
  active: "Active",
  all: "All",
  archived: "Archived",
}

const filterListItems = (LSwap: LSwapFunc): IFilter[] => {
  return [
    { id: 0, label: "Active", filter: "active", sort: "name", sortDir: "asc", title: LSwap("Clients with projects in the last 6 months", "clients") },
    { id: 40, label: "All", filter: "all", sort: "name", sortDir: "asc", title: LSwap("All clients", "clients") },
  ];
};

//Should this be by category?
const filterMenuItems = (categories: {id: string; label: string}[]): IMenuItem[] => {
  let index = 0;
  const categoryItems = categories.map(cat => ({id: index++, label: capitalize(cat.label), action: `category:${cat.id}`, props: {sort: "name", sortDir: "desc"}}));

  return [    
    ...categoryItems,
    { id: 991, isDivider: true },
    { id: 800, label: "Archived", action: "archived", props: { sort: "startDate", sortDir: "desc" } },
  ];
}

const ClientListHeader = () => {
  const classes   = buildStyles();
  const {L, LSwap} = useLocalization();
  const [settings, changeSettings] = useSliceSettings<ClientSettings>("clients");
  const [isSearch, toggleSearch] = useBoolState(false);
  const { onOpenDialog } = useDialogs();
  const filters = useMemo(() => filterListItems(LSwap), []);
  const categories = useSelector(selectClientCategories);
  const menuItems = useMemo(() => filterMenuItems(categories), [categories]);
  const selectedItem = useMemo(() => { return filterMenuItems(categories).find(f => (f.action === settings.filter || f.action === `category:${settings.categoryFilter}`)) ?? null; }, [settings.filter, settings.categoryFilter]);

  //-- Clear the search term when the search closes
  useEffect(() => {
    if(!isSearch && settings.search !== null){
      changeSettings({...settings, search: null});
    }
  }, [isSearch]);

   //Check to see if there's a filter in the query paramters, and if so, deal with it
   useSearchStringFilter(routes.clients, changeSettings);

  const menuLabel = useMemo<string>(() => { 
    return selectedItem?.label ?? "Category";
  }, [selectedItem]);

  const filterLabel = useMemo(() => { 
    const label = settings.search ? "Search results" : filterLabels[settings.filter];
    return label ? `(${label})` : null; 
  }, [settings.search, settings.filter, filterLabels]);

  const onFilterClicked = (filter: IFilter) => {
    changeSettings({ filter: filter.filter as ClientFilter, categoryFilter: null, sort: filter.sort, sortDir: filter.sortDir });
  }

  const onMenuItemClicked = (menuItem: IMenuItem) => {
    if(menuItem.action.indexOf("category:") >= 0){
      const category = menuItem.action.replace("category:", "");
      changeSettings({ filter: null, categoryFilter: category, sort: menuItem.props.sort, sortDir: menuItem.props.sortDir });
    }
    else{
      changeSettings({ filter: menuItem.action as ClientFilter, categoryFilter: null, sort: menuItem.props.sort, sortDir: menuItem.props.sortDir });
    }
  }
  
  const filterMenuComparer = (menuItem: IMenuItem) => {
    return menuItem.action === settings.filter && menuItem.props.sort === settings.sort;
  }

  const doSearch = (searchVal: string) => {
    changeSettings({...settings, search: searchVal});
  }
  
  return (
    <Grid id="client-list-header" container justify="flex-start" alignItems="flex-start" className={classes.root}>
      <Grid item sm={4} xs={12}>
        <Typography variant="h5" color="primary" className={classes.title}>{L("clients", true)} {filterLabel}</Typography>
      </Grid>
      <Grid item sm={8} xs={12} container justify="center" alignItems="center">
        {isSearch && 
          <Grid container alignItems="center">
            <SearchInput autoFocus={true} placeholder="Search clients" onClose={toggleSearch} onSearch={doSearch} classes={{container: classes.searchContainer}}/>
          </Grid>
        }
        {!isSearch && 
          <Grid container alignItems="center" className={classes.menuBar}>
            <Grid item className={classes.firstCol}>
              <FilterList filters={filters} currentFilter={settings.filter} onClick={onFilterClicked} color="secondary" classes={{container: classes.marginRight}} noWrap/>
              <ChipMenu label={menuLabel} icon={<Icon fontSize="small">filter_list</Icon>} size="small" items={menuItems} title="Filter by Category" color={!!selectedItem ? "secondary" : "default"} variant={!!selectedItem ? "default" : "outlined"}  isSelectable={true} onClick={onMenuItemClicked} isSelected={filterMenuComparer} />
            </Grid>
            <Grid item className={classes.lastCol}>
              <Grid container alignItems="center">
                <Tooltip title="Search clients">
                  <IconButton size="small" className={clsx(classes.chipIcon, classes.marginLeft)} onClick={toggleSearch}><Icon fontSize="small">search</Icon></IconButton>
                </Tooltip>
                <Tooltip title="New Client">
                  <IconButton size="small" className={clsx(classes.chipIcon, classes.marginLeft)} onClick={onOpenDialog("client", -1)}><Icon fontSize="small">add</Icon></IconButton>
                </Tooltip>
                <ClientListMenu />
              </Grid>
            </Grid>
          </Grid>
        }
      </Grid>
    </Grid>
  );
}

export default ClientListHeader;
