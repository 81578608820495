import React from "react";
import { Link, Route } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useLocalization, useReadyState } from "utils/hooks";
import { LoadingIndicator } from "components";
import { routes } from "features/app/app-routes";
import InvoiceView from "./invoice-view";
import InvoiceListView from "./invoice-list-view";
import { Helmet } from "react-helmet-async";
import { selectIsOnboarded } from "features/app";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: theme.spacing(1),
  },
  title: {
    fontSize: 22,
    fontWeight: 500,
    marginBottom: theme.spacing(1),
  },
  subTitle: {
    fontSize: 17,
    fontWeight: 500,
    // textAlign: "center",
    marginBottom: theme.spacing(3),
    color: theme.palette.grey[800],
  },
}));

const InvoicesView = () => {
  const classes = useStyles();
  const isReady = useReadyState();
  const {L, LSwap} = useLocalization();
  const isOnboarded = useSelector(selectIsOnboarded);
  
  return (
    <>
      {!isReady &&
        <LoadingIndicator isVisible={true} message="Loading..." />
      }
      {isReady &&
        <Fade in={true} timeout={250}>
          <Grid id="invoices-view" container className={classes.root}>
            <Helmet>
              <title>{LSwap("Gigops - Invoices", "invoices")}</title>
            </Helmet>
            {isOnboarded && 
              <>
                <Route path={[routes.invoiceNew, routes.invoiceEdit, routes.invoice]} exact component={InvoiceView} />
                <Route path={routes.invoices} exact component={InvoiceListView} />
              </>
            }
            {!isOnboarded && 
              <>
                <Typography className={classes.title}>{LSwap("You need Clients and Projects before you can create invoices.", ["clients", "projects"])}</Typography>
                <Typography className={classes.subTitle}>
                  Visit the <Link to="/">{L("dashboard", true)}</Link> or click the <AddCircleIcon fontSize="small" color="secondary"/> button in the header to begin.
                </Typography>
              </>
            }
          </Grid>
        </Fade>
      }
    </>
  );
}

export default InvoicesView;
