import { differenceInBusinessDays, isSameMonth, startOfDay, } from "date-fns";
import { metricChartOptions, metricDonutOptions, metricScatterOptions,  } from "./metric-chart-helpers";
import { calcFee } from "features/projects";
import { isNumber, orderBy, sum, sumBy, uniq } from "lodash";
import { Invoice, IProject, DateRange, IClient, IProjectSummary } from "types";
import { isInRange } from "utils/date-helpers";
import { formatCurrency } from "utils/number-helpers";
import { defaultRateMonth, getActiveClients, getActiveProjects, UNCATEGORIZED } from "./metric-calcs-common";

export const RevenueMetrics = [
  {
    id: "revenue-booked",
    category: "revenue",
    period: ["year", "month"],
    label: "Booked",
    description: "Revenue for all projects scheduled to start ~period~",
    why: "",
    isExpandable: true,
  },
  {
    id: "revenue-earned",
    category: "revenue",
    period: ["year", "month"],
    label: "Earned",
    description: "Revenue for all projects delivered ~period~",
    why: "",
  },
  {
    id: "revenue-invoiced",
    category: "revenue",
    period: ["year", "month"],
    label: "Invoiced",
    description: "Revenue for all invoices sent ~period~",
    why: "",
  },
  {
    id: "revenue-paid",
    category: "revenue",
    period: ["year", "month"],
    label: "Paid",
    description: "Revenue for all paid invoices ~period~",
    why: "",
  },
  {
    id: "revenue-rate",
    category: "revenue",
    period: ["year", "month"],
    label: "Hourly Rate",
    description: "Your hourly rate across all time that you have track in ~period~",
    why: "",
  },
  {
    id: "revenue-wait",
    category: "revenue",
    period: ["year"],
    label: "Days to pay",
    description: "The average days it has taken your clients to pay your invoices ~period~",
    why: ""
  },
  {
    id: "revenue-perword",
    category: "revenue",
    period: ["year"],
    label: "Per-word Rate",
    description: "Your average per-word rate across all projects ~period~",
    why: "",
    hideWhenNa: true,
  },
  {
    id: "revenue-bycategory-project",
    category: "revenue",
    period: ["year"],
    label: "Best Project Category",
    description: "Your best project category, by earned revenue, ~period~",
    why: "",
  },
  {
    id: "revenue-bycategory-client",
    category: "revenue",
    period: ["year"],
    label: "Best Client Category",
    description: "Your best client category, by earned revenue, ~period~",
    why: "",
  },
  {
    id: "revenue-bywordcount-rate",
    category: "revenue",
    period: ["year"],
    label: "Rate by Wordcount",
    description: "This measures your average hourly rate by project word count.  So it tells you what size project is your most profitable ~period~",
    why: "",
    hideWhenNa: true,
  }
];

export const calcRevenueBooked = (range: DateRange, projects: IProject[], monthEnds: Date[]) => {
  const matches = projects.filter(p => isInRange(p.startDate, range));
  const revenue = matches.reduce((total, prj) => { return total + calcFee(prj, true); }, 0);
  const value = revenue;
  const displayValue = formatCurrency(revenue);

  const chartData = monthEnds.map(endDate => {
    const monthProjects = projects.filter(p => isSameMonth(p.startDate as Date, endDate));
    const total = sumBy(monthProjects, prj => calcFee(prj, true));
    return [startOfDay(endDate), total];
  });

  return {
    value,
    displayValue,
    chartData: [{
      name: "booked",
      type: "bar",
      data: chartData,
    }],
    chartOptions: metricChartOptions("currency", "revenue"),
    chartType: "bar",
  }
}

export const calcRevenueEarned = (range: DateRange, projects: IProject[], monthEnds: Date[]) => {
  const matches = projects.filter(p => isInRange(p.deliveredDate, range));
  const revenue = matches.reduce((total, prj) => { return total + calcFee(prj); }, 0);
  const value = revenue;
  const displayValue = formatCurrency(revenue);

  const chartData = monthEnds.map(endDate => {
    const monthProjects = projects.filter(p => isSameMonth(p.deliveredDate as Date, endDate));
    const total = sumBy(monthProjects, prj => calcFee(prj, true));
    return [startOfDay(endDate), total];
  });

  return {
    value,
    displayValue,
    chartData: [{
      name: "earned",
      type: "bar",
      data: chartData,
    }],
    chartOptions: metricChartOptions("currency", "revenue"),
    chartType: "bar",
  }
}

export const calcRevenueInvoiced = (range: DateRange, invoices: Invoice[], monthEnds: Date[]) => {
  const matches = invoices.filter(i => isInRange(i.invoiceDate, range));
  const revenue = sumBy(matches, m => m.amount);
  const value = revenue;
  const displayValue = formatCurrency(revenue);

  const chartData = monthEnds.map(endDate => {
    const monthInvoices = invoices.filter(p => isSameMonth(p.invoiceDate as Date, endDate));
    const total = sumBy(monthInvoices, inv => inv.amount);
    return [startOfDay(endDate), total];
  });

  return {
    value,
    displayValue,
    chartData: [{
      name: "invoiced",
      type: "bar",
      data: chartData,
    }],
    chartOptions: metricChartOptions("currency", "revenue"),
    chartType: "bar",
  }
}

export const calcRevenuePaid = (range: DateRange, invoices: Invoice[], monthEnds: Date[]) => {
  const matches = invoices.filter(i => isInRange(i.paidDate, range));
  const revenue = sumBy(matches, m => m.amount);
  const value = revenue;
  const displayValue = formatCurrency(revenue);
  
  const chartData = monthEnds.map(endDate => {
    const monthInvoices = invoices.filter(p => isSameMonth(p.paidDate as Date, endDate));
    const total = sumBy(monthInvoices, inv => inv.amount);
    return [startOfDay(endDate), total];
  });

  return {
    value,
    displayValue,
    chartData: [{
      name: "paid",
      type: "bar",
      data: chartData,
    }],
    chartOptions: metricChartOptions("currency", "revenue"),
    chartType: "bar",
  }
}

export const calcOverallRate = (range: DateRange, projects: IProject[], monthEnds: Date[]) => {
  //overall rate = all fees / all minutes
  //all projects started or delivered or overlapping this period
  let totalFee = 0;
  let totalMinutes = 0;
  
  const months: {date: Date; minutes: number; amount: number}[] = [];
  const hourlyProjects = projects.filter(prj => prj.trackedMinutes && prj.deliveredDate);
  const matches = hourlyProjects.filter(prj => isInRange(prj.startDate, range) || isInRange(prj.deliveredDate, range));
  for(let i = 0; i < matches.length; i++){
    const prj = matches[i];
    const fee = calcFee(prj);
    totalFee += fee;
    totalMinutes += prj.trackedMinutes || 0;
    //For chart...
    const ddate = prj.deliveredDate as Date;
    let month = months.find(m => isSameMonth(m.date, ddate)) || null;
    if(!month){
      month = {...defaultRateMonth(ddate)};
      months.push(month);
    }
    month.minutes += prj.trackedMinutes || 0;
    month.amount += fee;
  }

  const chartData = monthEnds.map(endDate => {
    const month = months.find(m => isSameMonth(m.date, endDate)) || {...defaultRateMonth(endDate)};
    const average = month.minutes === 0 ? 0 : month.amount / (month.minutes / 60);
    return [startOfDay(endDate), average];
  });

  const value = totalFee / ((totalMinutes || 1) / 60);
  const chartOptions = metricChartOptions("currency", "revenue", "area");
  return {
    value,
    displayValue: `${formatCurrency(value)} / hr`,
    chartData: [{
      name: "hourly rate",
      type: "area",
      data: chartData,
    }],
    chartOptions,
    chartType: "area",
  }  
}

//"revenue-wait"
export const calcRevenueWait = (range: DateRange, invoices: Invoice[]) => {
  const matches = invoices.filter(i => i.paidDate && isInRange(i.paidDate, range) || isInRange(i.invoiceDate, range));
  const daysToPay = matches
    .map(i => differenceInBusinessDays(i.paidDate as Date, i.invoiceDate as Date))
    .filter(v => isNumber(v))
    .sort((a,b) => a-b);
  const totalDays = sum(daysToPay);
  const value = totalDays / (daysToPay.length || 1);
  const displayValue = `${Math.floor(value).toString()} days`;
  
  const min = daysToPay.find(f => f !== 0) || 0;  //first non-zero value
  const max = daysToPay[daysToPay.length - 1];
  const mean = daysToPay[Math.floor(daysToPay.length / 2)]
  
  return {
    value,
    displayValue,
    stats: [
      {id: "min", label: "Min", value: min, displayValue: `${Math.floor(min).toString()}d` },
      {id: "max", label: "Max", value: min, displayValue: `${Math.floor(max).toString()}d` },
      {id: "mean", label: "Mean", value: min, displayValue: `${Math.floor(mean).toString()}d` },      
    ],
  };
}

//"revenue-perword"
export const calcWordRate = (range: DateRange, projects: IProject[]) => {
  const matches = getActiveProjects<IProject>(range, projects).filter(p => p.wordCount || (p.type === "perWord" && !!p.units));
  if(matches.length === 0){
    return { value: -999, displayValue: "n/a" }; //not applicable, no wordcount projects
  }
  const totalFee = sumBy(matches, p => calcFee(p));
  const totalWords = sumBy(matches, p => p.units as number);
 
  const value = totalFee / ((totalWords || 1));

  const chartData = matches.map(project => {
    if(project.type === "perWord" && project.units){
      return [project.fee as number, project.units as number] as [number,number];
    }
    else{
      const fee = calcFee(project);
      const words = project.wordCount || project.units || 1;
      const rate = parseFloat((fee / words).toFixed(2));
      return [rate, words] as [number,number];
    }
  });

  const result = {
    value,
    displayValue: `${formatCurrency(value, false)} / word`,
    chartData: [{
      name: "Wordcount",
      data: chartData,
    }],
    chartOptions: metricScatterOptions("wordRate", "count", "earned"),
    chartType: "scatter",
  };

  return result;
};

//TODO: Chart with hourly rate by wordcount.  e.g. avg $100/hr on 500 word-count, vs $300/hr on 2000 word-count
//"revenue-bywordcount-rate"
export const calcWordCountRate = (range: DateRange, projects: IProject[], hours: IProjectSummary[]) => {
  let matches = getActiveProjects<IProject>(range, projects).filter(p => p.wordCount || (p.type === "perWord" && !!p.units));
  matches = matches.filter(m => m.deliveredDate && hours.find(h => h.id === m.id));  //filter down to delivered projects with tracked hours
  if(matches.length === 0){
    return { value: -999, displayValue: "n/a" }; //not applicable, no wordcount projects
  }

  //For each project, calculate the hourly rate
  // item format: [wordCount, hourlyRate]
  let chartData = matches.map(prj => {
    const fee = calcFee(prj);
    const minutes = hours.find(h => h.id === prj.id)?.minutes;
    const words = prj.wordCount || prj.units || 0;
    const rate = parseFloat((fee / ((minutes || 1) / 60)).toFixed(2));
    return [words, rate];
  });

  chartData = orderBy(chartData, [d => d[1]], ["desc"]);
  
  //item format: [count, [rate1, rate2, ...]] so I can get the hourly rate by wordcount
  const grouped = chartData.reduce((accum: [number, number[]][], d) => {
    const wordCount = d[0];
    const rate = d[1];
    const index = accum.findIndex(i => i[0] === wordCount);
    if(index >= 0) accum[index][1].push(rate);
    else accum.push([wordCount, [rate]]);
    return accum;
  }, []);

  //item format: [wordCount, averageHourlyRate]
  const averages = orderBy(grouped.map(g => {
    const avg = (sum(g[1]) / g[1].length);
    return [g[0], avg];
  }), [i => i[1]], ["desc"]);

  const value = averages[0][0]; //chartData[0][0];  //the max value

  return {
    value,
    displayValue: `${value.toLocaleString()} words`,
    subValue: averages[0][1],
    displaySubValue: `${formatCurrency(averages[0][1])} / hr`,
    chartData: [{
      name: "Wordcount",
      data: chartData,
    }],
    chartOptions: metricScatterOptions("count", "rate", "earned"),
    chartType: "scatter",
  }
}

//"revenue-bycategory-client"
export const calcRevenueByClientCategory = (range: DateRange, clients: IClient[], projects: IProject[]) => {
  const activeClients = getActiveClients(range, clients, projects).filter(c => c.category);
  const activeProjects = getActiveProjects<IProject>(range, projects);
  const categories = uniq(activeClients.map(a => a.category?.toLowerCase() || UNCATEGORIZED));
  if(!categories || categories.length === 0){
    return {
      value: -998,
      displayValue: "no clients to evaluate",
    }
  }

  const grouped = categories.map(category => {
    const catClients = category === UNCATEGORIZED ? activeClients.filter(a => !a.category) : activeClients.filter(a => a.category?.toLowerCase() === category);
    const catRevenue = catClients.reduce((total, client) => {
      const cliProjects = activeProjects.filter(p => p.clientId === client.id);
      const sum = sumBy(cliProjects, p => calcFee(p));
      return total + sum;
    }, 0);
    return [category, catRevenue];
  }) as [string, number][];

  grouped.sort((a,b) => a[1] - b[1]);
  const best = grouped[grouped.length - 1];
  const chartData = grouped.map(g => g[1]);
  const chartLabels = grouped.map(g => g[0]);

  return {
    value: best[1],
    displayValue: formatCurrency(best[1]),
    subValue: best[0],
    displaySubValue: best[0],
    chartData,
    chartOptions: metricDonutOptions(chartLabels, "currency"),
    chartType: "donut",
  };
}

//"revenue-bycategory-project"
export const calcRevenueByProjectCategory = (range: DateRange, projects: IProject[]) => {
  const activeProjects = getActiveProjects<IProject>(range, projects).filter(p => p.category);
  const categories = uniq(activeProjects.map(a => a.category?.toLowerCase() || UNCATEGORIZED));
  if(!categories || categories.length === 0){
    return {
      value: -998,
      displayValue: "no projects to evaluate",
    }
  }

  const grouped = categories.map(category => {
    const catProjects = category === UNCATEGORIZED ? activeProjects.filter(a => !a.category) : activeProjects.filter(a => a.category?.toLowerCase() === category);
    const sum = sumBy(catProjects, calcFee);
    return [category, sum];
  }) as [string, number][];

  grouped.sort((a,b) => a[1] - b[1]);
  const best = grouped[grouped.length - 1];
  const chartData = grouped.map(g => g[1]);
  const chartLabels = grouped.map(g => g[0]);

  return {
    value: best[1],
    displayValue: formatCurrency(best[1]),
    subValue: best[0],
    displaySubValue: best[0],
    chartData,
    chartOptions: metricDonutOptions(chartLabels, "currency"),
    chartType: "donut",
  };
}