import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@material-ui/core/Divider';
import AddIcon from '@material-ui/icons/Add';
import Icon from '@material-ui/core/Icon';
import { goalCategories, goalDefinitions } from "../infra/goals-config";
import { IGoal, IGoalCategory, IGoalDefinition } from "../infra/goal-types";
import { cardStyles, generalStyles } from "utils/styles";
import { useLocalization } from "utils/hooks";
import { compact } from "lodash";

const buildStyles   = makeStyles(theme => ({
  ...cardStyles(theme),
  ...generalStyles(theme),
  root  : {
    padding     : theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  cardTop: {
    padding: theme.spacing(2),
    background: `linear-gradient(90deg,${theme.palette.primary.main},${theme.palette.primary.light})`,
    borderRadius: "5px 5px 0 0",
    position: "relative",
  },
  title   : {
    fontSize    : 22,
    fontWeight  : 500,
    color: theme.palette.primary.contrastText,
  },
  subTitle  : {
    fontSize  : 15,
    fontWeight: 400,
    color     : theme.palette.grey[800],
  },
  divider: {
    width: "50%",
    margin: `${theme.spacing(2)}px ${theme.spacing(0)}px`, //`
    alignSelf: "center",
  },
  defGrid: {
    marginBottom: theme.spacing(2),
    // borderBottom: `1px solid ${theme.palette.grey[300]}`, //`
  },
  label: {
    fontSize: 18,
    fontWeight: 500,
    color: theme.palette.secondary.main, //.grey[900],
  },
  subLabel: {
    fontSize: 15,
    fontWeight: 400,
    color: theme.palette.grey[800],
  },
  icon: {
    position: "absolute",
    fontSize: "4em",
    right: theme.spacing(1),
    transformOrigin: "top",
    // transform: "rotate(-33deg)",
    top: theme.spacing(1),
    opacity: 0.35,
    color: theme.palette.secondary.main,
  }
}));

interface AvailableGoalProps{
  category: IGoalCategory;
  items?: IGoalDefinition[];
  onAdd: (item: IGoalDefinition) => void;
}

const AvailableGoal = ({category, items, onAdd}: AvailableGoalProps) => {
  const classes = buildStyles();
  const {LSwap} = useLocalization();

  return (
    <Paper className={classes.card}>
      <Grid container direction="column" justify="center" alignItems="center" className={classes.cardTop} >
        <Typography className={classes.title}>{LSwap(category.title)}</Typography>
        <Icon className={classes.icon} color="action" fontSize="large">{category.icon}</Icon>
      </Grid>
      <Grid container direction="column" className={classes.cardBody}>
        <Typography className={classes.subTitle}>{LSwap(category.subTitle)}</Typography>
        <Divider className={classes.divider} />
        {(!items || items.length === 0) &&
          <Grid container direction="column" alignItems="center">
            <Typography className={classes.label}>Great job!</Typography>
            <Typography className={classes.subLabel}>You&apos;ve set all your {LSwap(category.title)} goals.</Typography>
          </Grid>
        }
        {(items && items.length > 0) && 
          items.map(item => {
            return (
              <Grid key={item.id} container className={classes.defGrid}>
                <Grid item md={10}>
                  <Typography className={classes.label}>{item.title}</Typography>
                  <Typography className={classes.subLabel}>{item.tip}</Typography>
                </Grid>
                <Grid item md={2} container justify="flex-end" alignItems="center">
                  <Tooltip title="Set this goal">
                    <IconButton size="small" onClick={() => onAdd(item)}><AddIcon /></IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            );
          })
        }
      </Grid>
    </Paper>
  )
}

interface AvailableGoalsProps{
  onAdd: (item: IGoalDefinition) => void;
  existing: IGoal[];
  adding: IGoalDefinition[];
}

const AvailableGoals = ({onAdd, existing, adding}: AvailableGoalsProps) => {
  const classes   = buildStyles();
  //Get the definitions for the existing goals
  const existingDefs = compact(existing?.map(e => goalDefinitions.find(g => g.id === e.definitionId)));
  //Get a list of Ids of the goals that have already been set, or are in the process of being set (ignoring allowMulti goals)
  const ids = [...existingDefs?.filter(e => !e.allowMulti).map(e => e.id), ...adding?.filter(a => !a.allowMulti).map(a => a.id)];

  return (
    <Grid id="data-view" container className={classes.root} spacing={2}>
      {goalCategories.map(category => {
        const items: IGoalDefinition[] = goalDefinitions.filter(g => g.category === category.id);
        const filtered = items.filter(i => ids.indexOf(i.id) < 0);        
        return (
          <Grid key={category.id} item md={4}>
            <AvailableGoal category={category} items={filtered} onAdd={onAdd}/>
          </Grid>
        )
      })}
    </Grid>
  );
}

export default AvailableGoals;
