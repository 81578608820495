import { clientsAdded, statusChanged as clientStatusChanged } from "features/clients/clients-slice";
import { projectsAdded, statusChanged as projectStatusChanged } from "features/projects";
import { itemsCreated as invoicesAdded, statusChanged as invoiceStatusChanged } from "features/invoices";
import { AppThunk, IClient, Invoice, IProject } from "types";
import { STATUSES, useFirestore } from "./firebase";
import { datesToStrings, prepareForDb, removeUndefined } from "./general-helpers";

export const importClients = (items: IClient[]): AppThunk => async (dispatch, getState) => {
  const [api, uid] = useFirestore();

  if (!uid) throw new Error("Must be logged in to import clients.");
  const app = getState().app;

  if (app.isInitialized) {
    await dispatch(clientStatusChanged({ isWorking: true, error: null }));
    const results: IClient[] = [];
    const errors = [];

    for(let i = 0; i < items.length; i++){
      
      const prepared = removeUndefined({ ...items[i], createdDate: new Date() });
      const result = await api.createClient(app.org.id, prepared);
      
      if (result.statusCode === STATUSES.ok) {
        const storeClient = {
          ...datesToStrings(prepared),
          id: result.key
        } as IClient;

        results.push(storeClient);
      }
      else{
        errors.push(result);
      }
    }

    //Update the client slice
    await dispatch(clientsAdded(results));
    return results.length;
  }
}

export const importProjects = (items: IProject[]): AppThunk => async (dispatch, getState) => {
  const [api, uid] = useFirestore();

  if (!uid) throw new Error("User is not authorized.");
  const app = getState().app;

  if (app.isInitialized) {
    await dispatch(projectStatusChanged({ isWorking: true, error: null }));
    const results: IProject[] = [];
    const errors = [];
    for(let i = 0; i < items.length; i++){
      const prepared = {...prepareForDb(items[i]), createdDate: new Date() };
      const result = await api.createProject(app.org.id, prepared);

      if (result.statusCode === STATUSES.ok) {
        const toStore = {
          ...datesToStrings(prepared),
          id: result.key
        } as IProject;

        results.push(toStore);
      }
      else{
        errors.push(result);
      }
    }

    dispatch(projectsAdded(results));
    return results.length;
  }
}

export const importInvoices = (items: Invoice[]): AppThunk => async (dispatch, getState) => {
  const [api, uid] = useFirestore();

  if (!uid) throw new Error("User is not authorized.");
  const app = getState().app;

  if (app.isInitialized) {
    await dispatch(invoiceStatusChanged({ isWorking: true, error: null }));
    const results: Invoice[] = [];
    const errors = [];
    for(let i = 0; i < items.length; i++){
      const prepared = { ...items[i], userId: uid } as Invoice;  //Add the User Id to the invoice
      const result = await api.createInvoice(app.org.id, prepared);

      if (result.statusCode === STATUSES.ok) {
        const toStore = {id: result.key, ...datesToStrings(result.data)} as Invoice;
        results.push(toStore);
      }
      else{
        errors.push(result);
      }
    }

    dispatch(invoicesAdded(results));
    return results.length;
  }
}