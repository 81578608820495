export interface IDashboardMetric{
  id: string;
  label: string;
  isDisplayed: boolean;
}

export const heroMetrics: IDashboardMetric[] = [
  {id: "earned", label: "Earned", isDisplayed: false},
  {id: "hours", label: "Hours", isDisplayed: false },
  {id: "delivered", label: "Delivered", isDisplayed: true },
  {id: "invoiced", label: "Invoiced", isDisplayed: false },
  {id: "paid", label: "Paid", isDisplayed: true },
  {id: "assigned", label: "Assigned", isDisplayed: true },
];

export const heroAreas: IDashboardMetric[] = [
  {id: "earned", label: "Earned", isDisplayed: false},
  {id: "delivered", label: "Delivered", isDisplayed: true },
  {id: "invoiced", label: "Invoiced", isDisplayed: false },
  {id: "paid", label: "Paid", isDisplayed: false },
  {id: "assigned", label: "Assigned", isDisplayed: true },
]