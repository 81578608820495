import moment from "moment";
import { IProject, ProjectType, IClient, emptyClient, ITimer, Indexable, Invoice, Exportable } from "types";
import { tryParseInt, dateToString, tryParseFloat, } from "utils/general-helpers";
import { parseTime, validateTime, parseDate, validateDate } from "utils/date-helpers";
import { newProject } from "features/projects/infra/project-helper";
import { defaultTimer, calcMinutes, roundMinutes } from "features/timesheet"
import { newInvoice } from "features/invoices/infra/invoice-helpers";

const altComma = "|";
// const rx = /("[\w\d\s\$]+,)/g;
const rxReplace = /"[^"]+"/g; ///"[$\w\d\s\,]+"/g;

//Interface definition for an import mapping object
interface ImportMap{
  id: number;
  name: string;
  matches: string[];
  isRequired?: boolean;
  tooltip?: string;
}

export interface ParsedCsv{
  items: Record<string, unknown>[];
  errors: {row: number; error: string}[];
}

//Checks to see if a column map has the required fields defined in the importMap for the type
const hasRequiredColumns = (colMap: any, nameMap: ImportMap[]) => {
  const required = nameMap.filter(m => m.isRequired);
  for(let i = 0; i < required.length; i++){
    if(!colMap.hasOwnProperty(required[i].name)) return false;
  }
  return true;
}

function prepareString(str: string, fromComma = true, asNumber = false) {
  if (!str || str.length === 0) return str;
  if (fromComma) {
    let nextMatch = rxReplace.exec(str);
    if (!nextMatch) return str;

    let replaced = str;
    while (nextMatch !== null) {
      const matchText = nextMatch[0];
      const matchReplace = matchText.replaceAll('\"', "").trim().replaceAll(",", altComma);
      replaced = replaced.replace(matchText, matchReplace);
      nextMatch = rxReplace.exec(str);
    }

    return replaced;
  }
  else if (asNumber) {
    const swapped = str.replaceAll(altComma, "").replace("$", "");
    return swapped;
  }
  else {
    const swapped = str.replaceAll(altComma, ",");
    return swapped;
  }
}

function swapLfs(array: string[]){
  const swapped = array.map(itm => {
    return itm.indexOf("<lf>") >= 0 ? itm.replaceAll("<lf>", '\n') : itm;
  });
  return swapped;
}

const getMappedField = (nameMap: any[], fieldName: string, index: number): any => {
  const item = nameMap.find(nm => nm.matches.indexOf(fieldName.trim().toLowerCase()) >= 0);
  if (item) {
    return { name: [item.name], column: index}; //item.id };
  }
  return null;
}

const mapFields = (nameMap: any[], header: string) => {
  const items = header.split(",");
  const map: Indexable = {};
  for(let i = 0; i < items.length; i++){
    const item = items[i];
    const mapItem = getMappedField(nameMap, item, i);
    if(mapItem){
      map[mapItem.name] = mapItem.column;
    }
  }
  return map;
}

function checkForHeader(line: string, map: any){
  const cols = line.split(",");
  const cellValue = cols[0];
  const headerCol = getMappedField(map, cellValue, 0);
  return !!headerCol;
}

//#region Projects

const projectMap = {
  started: 0,
  due: 1,
  client: 2,
  name: 3,
  delivered: 4,
  fee: 5,
  type: 6,
  hours: 7,
  invoiced: 8,
  paid: 9,
  notes: 10
};
const projectNameMap: ImportMap[] = [
  { id: 0, name: "started", matches: ["started", "start", "assigned", "startdate", "assigneddate", "starteddate"], isRequired: true},
  { id: 1, name: "due", matches: ["due", "deadline", "duedate", "deadlinedate"], },
  { id: 2, name: "client", matches: ["client", "customer"], },
  { id: 3, name: "name", matches: ["name", "project", "assignment"], isRequired: true, },
  { id: 4, name: "delivered", matches: ["delivered", "delivereddate", "finished", "finish", "finisheddate", "finishdate"], },
  { id: 5, name: "fee", matches: ["fee", "amount", "payment"], },
  { id: 6, name: "type", matches: ["type", "fee type", "assignment type", "project type"], },
  { id: 13, name: "units", matches: ["units", "count", "items", "itemCount", "item count"], },
  { id: 7, name: "hours", matches: ["hours", "time"], },
  { id: 8, name: "invoiced", matches: ["invoiced", "invoiceddate", "invoice sent", "invoice", "billed", "billeddate"], },
  { id: 9, name: "paid", matches: ["paid", "paiddate", "payment"], },
  { id: 10, name: "notes", matches: ["notes", "comments"], },
  { id: 11, name: "id", matches: ["id", "projectid", "project id"]},
  { id: 12, name: "clientId", matches: ["clientid", "client id"]},
  { id: 13, name: "category", matches: ["category", "project category", "projectcategory"]},
];


//Assigned,Due,Client,Name,Delivered,Fee,Type,Hours,Invoiced,Paid,Notes
export function parseCSVtoProjects(text: string, clients: IClient[], fieldMap: any = null): ParsedCsv {
  const projects: IProject[] = [];
  const lines = text.split('\n');
  const errors: {row: number; error: string}[] = [];

  if(checkForHeader(lines[0], projectNameMap)){
    const colMap = mapFields(projectNameMap, lines[0]);
    if(!colMap.started || !colMap.name){
      return {items: [], errors: [{row: 0, error: "Invalid File, one or more required columns are missing."}]}
    }
    const data = lines.slice(1).join('\n');
    const results = parseCSVtoProjects(data, clients, colMap);
    return results;
  }
  else{
    for(let i = 0; i < lines.length; i++){
      const line = lines[i].replace(/[\n\r]+/g, '');
      if(line === "") continue;
      //Look for commas inside of a column
      const working = prepareString(line, true);
      const row = swapLfs(working.split(","));
      const fields = fieldMap || projectMap;

      if(row[fields.started] === "" || row[fields.name] === ""){
        errors.push({row: i + 1, error: "Invalid Row: Name or started is missing or blank"});
        continue;
      }
      else{
        const startDate = moment(row[fields.started]);  //make sure the start date is a date
        if(!startDate.isValid()){
          errors.push({row: i + 1, error: "Invalid Row: Start date is invalid"});
          continue;
        }
        else{
          const clientId = (fields.clientId && row[fields.clientId]) ? row[fields.clientId] : null;
          const cName = row[fields.client].trim().toLowerCase();
          const client = clients.find(c => c.id === clientId || c.name.toLowerCase() === cName);

          const base = { ...newProject };
          
          const typeString = row[fields.type];
          const pType = typeString as ProjectType || ProjectType.fixed;
          const units = tryParseFloat(row[fields.units], 1);

          const project = {
            ...base,
            name: prepareString(row[fields.name], false),
            clientId: client ? client.id : "",
            type: pType,
            units: units,
            fee: tryParseFloat(prepareString(row[fields.fee], false, true), 0),
            startDate: new Date(row[fields.started]),
            dueDate: row[fields.due] ? new Date(row[fields.due]) : null,
            deliveredDate: row[fields.delivered] ? new Date(row[fields.delivered]) : null,
            invoicedDate: row[fields.invoiced] ? new Date(row[fields.invoiced]) : null,
            paidDate: row[fields.paid] ? new Date(row[fields.paid]) : null,
            notes: prepareString(row[fields.notes], false),
            hours: tryParseFloat(row[fields.hours], 0),
            category: row[fields.category] ? prepareString(row[fields]) : undefined,
          };
          
          if (fields.hasOwnProperty("id")) project.id = row[fields.id]; //Add the Id if there is one...
          projects.push(project);
        }
      }
    }

    return {items: projects, errors};
  }
}

//#endregion

//#region Clients
const clientMap = {
  name: 0,
  contactName: 1,
  contactEmail: 2,
  contactPhone: 3,
  defaultRate: 4,
  notes: 5,
};
const clientNameMap: ImportMap[] = [
  { id: 0, name: "name", matches: ["name", "client", "customer"], isRequired: true },
  { id: 1, name: "contactName", matches: ["contact", "contactName", "primaryContact", "contact name"], },
  { id: 2, name: "contactEmail", matches: ["email", "clientEmail", "contactEmail", "primaryEmail", "contact email"], },
  { id: 3, name: "contactPhone", matches: ["phone", "clientPhone", "contactPhone", "primaryPhone", "contact phone"], },
  { id: 4, name: "defaultRate", matches: ["rate", "defaultRate", "fee", "defaultFee", "default rate"], },
  { id: 5, name: "notes", matches: ["notes", "comments"], },
  { id: 6, name: "id", matches: ["id", "clientid", "client id"], },  
  { id: 7, name: "defaultType", matches: ["type", "defaulttype", "default type", "projecttype", "project type", "defaultprojecttype"], },
  { id: 8, name: "daysToPay", matches: ["daystopay"] ,},
  { id: 9, name: "category", matches: ["category", "client category", "clientcategory"]},
];

export function parseCSVtoClient(text: string, fieldMap: any = null): ParsedCsv {
  const clients: IClient[] = [];
  const lines = text.split('\n');
  const errors: {row: number; error: string}[] = [];

  if(checkForHeader(lines[0], clientNameMap)){
    const colMap = mapFields(clientNameMap, lines[0]);
    const data = lines.slice(1).join('\n');
    const results = parseCSVtoClient(data, colMap);
    return results;
  }
  else{
    for(let index = 0; index < lines.length; index++){
      const line = lines[index].replace(/[\n\r]+/g, '');
      if(line === "") continue; //ignore blank rows
      //Look for comas inside of a column
      const working = prepareString(line, true);
      const row = swapLfs(working.split(","));
      const fields = fieldMap || clientMap;

      if(row[fields.name] === ""){
        errors.push({row: index + 1, error: "Invalid Row: Name is missing or blank"});
      }
      else{
        const client = {
          ...emptyClient,
          name: prepareString(row[fields.name], false),
          createdDate: dateToString(new Date()) as string,
        }
        
        if(fields.hasOwnProperty("id")) client.id = row[fields.id];
        
        if (row[fields.contactName]) client.contactName = prepareString(row[fields.contactName], false);
        if (row[fields.contactEmail]) client.contactEmail = prepareString(row[fields.contactEmail], false);
        if (row[fields.contactPhone]) client.contactPhone = prepareString(row[fields.contactPhone], false);
        client.defaultRate = tryParseFloat(prepareString(row[fields.defaultRate], true), 0);
        if (row[fields.notes]) client.notes = prepareString(row[fields.notes], false);
        if(row[fields.defaultType]){
          const pType = row[fields.defaultType];
          const prjType = ProjectType[pType as ProjectType] || ProjectType.fixed;
          client.defaultProjectType = prjType;
        } 
        if(row[fields.daysToPay]){
          client.daysToPay = tryParseInt(row[fields.daysToPay], 30);
        }
        if(row[fields.category]){
          client.category = prepareString(row[fields.category]);
        }

        clients.push(client);
      }
    }

    return {items: clients, errors: errors};
  }
}

//#endregion

//#region Hours

const hoursMap = {
  date: 0,  
  start: 1,
  stop: 2,
  projectId: 3,
  category: 4,
  notes: 5,
  invoiceDate: 6,
  paymentDate: 7,
  roundType: 8,
  creditMinutes: 9,
};
//"Date,Start Time,Stop Time,Time,Category,Invoice Date,Payment Date,Notes,Minutes,CreditMinutes,Rounding,Project Id
const hoursNameMap: ImportMap[] = [
  { id: 0, name: "date", matches: ["date"], isRequired: true, },
  { id: 1, name: "start", matches: ["start", "starttime", "start time", "begin", "begintime", "begin time"], isRequired: true, },
  { id: 2, name: "stop", matches: ["stop", "stoptime", "stop time", "end", "endtime", "end time", "finish", "finishtime"], isRequired: true, },
  { id: 3, name: "projectId", matches: ["project", "projectId", "project id"], isRequired: true, },
  { id: 4, name: "category", matches: ["category", "task"], },
  { id: 5, name: "notes", matches: ["notes", "comments", "details"], },
  { id: 6, name: "invoiceDate", matches: ["invoiced", "invoiceddate", "invoiced date", "invoicedate", "invoice date"], },
  { id: 7, name: "paymentDate", matches: ["payment", "paid", "paymentdate", "paiddate", "payment date", "paid date"], },
  { id: 8, name: "roundType", matches: ["rounding", "roundto", "roundtype", "round type", "round to"], },
  { id: 9, name: "credit", matches: ["credit", "creditminutes", "credit minutes"]},
];
export function parseCSVtoHours(text: string, projects: IProject[], fieldMap: any = null): ParsedCsv {
  const hours: Partial<ITimer>[] = [];
  const invalidRows: {row: number; error: string}[] = [];
  const lines = text.split('\n');

  if(checkForHeader(lines[0], hoursNameMap)){
    const colMap = mapFields(hoursNameMap, lines[0]);
    if(!hasRequiredColumns(colMap, hoursNameMap)) return {items: [], errors: [{row: 0, error: "File is missing required columns"}]}
    const data = lines.slice(1).join('\n');
    const results = parseCSVtoHours(data, projects, colMap);
    return results;
  }
  else{
    for(let index = 0; index < lines.length; index++){
      const line = lines[index].replace(/[\n\r]+/g, '');
      if(line === "") continue;
      //Look for comas inside of a column
      const working = prepareString(line, true);
      const row = swapLfs(working.split(","));
      const fields = fieldMap || hoursMap;

      const dateStr = row[fields.date];
      const startStr = row[fields.start];  //make sure there is a start time and a stop time
      const stopStr = row[fields.stop];
      const projectId = row[fields.projectId];
      
      if(!dateStr || !startStr || !stopStr || !projectId){
        invalidRows.push({row: index + 1, error: "One or more required fields are missing."});
        continue;
      }
      
      if(dateStr && startStr && stopStr && projectId){
        //Try to parse the date and time
        const isDateValid = validateDate(dateStr);
        const isStartValid = validateTime(startStr);
        const isStopValid = validateTime(stopStr);
        
        if(!isDateValid || !isStartValid || !isStopValid){
          //flag this row as invalid, and keep going
          invalidRows.push({row: index + 1, error: "Unable to parse date, start or stop time"});
          continue;
        }

        const project = projects.find(prj => prj.id === projectId);
        if(!project){
          //flag this row as invalid, and keep going
          invalidRows.push({row: index + 1, error: "Project ID is not recognized"});
          continue;
        }
        
        //figure out the start and stop times
        const date = moment(parseDate(dateStr));
        const start = parseTime(startStr);
        const stop = parseTime(stopStr);
        start.set({"year": date.year(), "month": date.month(), "date": date.date()});
        stop.set({"year": date.year(), "month": date.month(), "date": date.date()});

        //calculate the minutes based on rounding
        const rounding = (row[fields.roundType] as "none" | "round15" | "round30" | "round60" | "push15" | "push30" | "push60") || "none";
        const minutes = calcMinutes(start.toDate(), stop.toDate());
        const rounded = roundMinutes(minutes, rounding || "none");

        const timer = {
          ...defaultTimer,
          startTime: start.format(),
          stopTime: stop.format(),
          minutes: rounded,
          creditMinutes: tryParseInt(row[fields.creditMinutes], 0),
          roundType: rounding,
          clientId: project.clientId,
          projectId: project.id,
          category: prepareString(row[fields.category]),
          notes: prepareString(row[fields.notes]),
          invoiceId: "",
          invoiceDate: parseDate(row[fields.invoiceDate]),
          paymentId: "",
          paymentDate: parseDate(row[fields.paymentDate]),
          rate: project.fee || undefined,
          dayEndDate: date.clone().endOf("day").toDate(),
          monthEndDate: date.clone().endOf("month").toDate(),
          year: date.year(),
          month: date.month(),  //note: month is 0-based
          dayOfMonth: date.date(),
          dayOfWeek: date.day(),
        }

        hours.push(timer);         
      }
      
    }
  }
  return { items: hours, errors: invalidRows};
}

//#endregion

//#region Invoices
const invoiceMap = {
  number: 0,
  clientId: 1,
  projectId: 2,
  invoiceDate: 3,
  dueDate: 4,
  paidDate: 5,
  amount: 6,
  notes: 7,
};
const invoiceNameMap: ImportMap[] = [
  { id: 0, name: "id", matches: ["id", "invoiceId", "invoice id"]},
  { id: 1, name: "number", matches: ["number", "invoiceNumber", "invoice number"], isRequired: true},
  { id: 2, name: "clientId", matches: ["clientId", "client id"], isRequired: true, },
  { id: 4, name: "projectId", matches: ["projectId", "project id"], isRequired: true, },
  { id: 5, name: "invoiceDate", matches: ["date", "invoiceDate", "invoice date", "invoicedDate", "invoiced date"], isRequired: true},
  { id: 6, name: "dueDate", matches: ["due", "duedate", "due date"], isRequired: true, },
  { id: 7, name: "paidDate", matches: ["paid", "paiddate", "paid date"], },
  { id: 8, name: "amount", matches: ["amount", "total"], isRequired: true, },
  { id: 9, name: "notes", matches: ["notes", "comments"], },
];

export function parseCSVtoInvoice(text: string, projects: IProject[], clients: IClient[], fieldMap: any = null): ParsedCsv {
  const invoices: Invoice[] = [];
  const lines = text.split('\n');
  const invalidRows: {row: number; error: string}[] = [];
  
  if(checkForHeader(lines[0], invoiceNameMap)){
    const colMap = mapFields(invoiceNameMap, lines[0]);
    if(!hasRequiredColumns(colMap, invoiceNameMap)) return {items: [], errors: [{row: 0, error: "File is missing required columns"}]}
    const data = lines.slice(1).join('\n');
    const results = parseCSVtoInvoice(data, projects, clients, colMap);
    return results;
  }
  else{
    for(let index = 0; index < lines.length; index++){
      //Look for comas inside of a column
      const line = lines[index].replace(/[\n\r]+/g, '');
      if(line === "") continue;   //skip any blank rows
      const working = prepareString(line, true);
      const row = swapLfs(working.split(","));
      const fields = fieldMap || invoiceMap;

      if(!row[fields.number] || !row[fields.invoiceDate] || !row[fields.dueDate] || !row[fields.amount] || (!row[fields.clientId] && !row[fields.projectId])){
        invalidRows.push({row: index + 1, error: "One or more required fields are missing"});
        continue;
      }

      const invDate = row[fields.invoiceDate];  //make sure there is a name
      const projectId = fields.hasOwnProperty("projectId") ? row[fields.projectId] : null;
      const clientId = fields.hasOwnProperty("clientId") ? row[fields.clientId] : null;
      const amount = row[fields.amount];

      const invoice: Invoice = {
        ...newInvoice,
        invoiceDate: parseDate(invDate) as Date,
        amount: parseFloat(amount),
      }
      
      if(fields.hasOwnProperty("id")) invoice.id = row[fields.id];
    
      if(projectId){
        const project = projects.find(prj => prj.id === projectId);
        if(project){
          invoice.projectId = project.id;
          invoice.clientId = project.clientId;
        }
      } 

      if(!invoice.clientId && clientId){
        const client = clients.find(cli => cli.id === clientId);
        if(client){
          invoice.clientId = clientId;
        }
        else{
          invalidRows.push({row: index + 1, error: "ProjectID or Client ID is missing or not recognized"});
          continue;
        }
      } 
      
      if (row[fields.number]) invoice.number = row[fields.number];
      if (row[fields.dueDate]) invoice.dueDate = parseDate(row[fields.dueDate]) as Date;
      if (row[fields.paidDate]) invoice.paidDate = parseDate(row[fields.paidDate]);
      if (row[fields.notes]) invoice.notes = prepareString(row[fields.notes], false);

      invoices.push(invoice);
    }

    return {items: invoices, errors: invalidRows };
  }
}
//#endregion

export function parse(itemType: Exportable, content: string, clients?: IClient[], projects?: IProject[]): ParsedCsv {
  switch(itemType){
    case "clients": return parseCSVtoClient(content);
    case "projects": return parseCSVtoProjects(content, clients as IClient[]);
    case "invoices": return parseCSVtoInvoice(content, projects as IProject[], clients as IClient[]);
    case "hours": return parseCSVtoHours(content, projects as IProject[]);
    default: return {items: [], errors: [{row: 0, error: "Unsupported itemType"}]};
  }
}
