import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import TimesheetHeader from "./timesheet-header";
import HoursListView from "./hours-list-view";
import { Helmet } from "react-helmet-async";
import { useLocalization } from "utils/hooks";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectIsOnboarded } from "features/app";

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: theme.spacing(1),
  },
  title: {
    fontSize: 22,
    fontWeight: 500,
    marginBottom: theme.spacing(1),
  },
  subTitle: {
    fontSize: 17,
    fontWeight: 500,
    textAlign: "center",
    marginBottom: theme.spacing(3),
    color: theme.palette.grey[800],
  },
}));

const TimesheetView = () => {
  const classes = useStyles();
  const isOnboarded = useSelector(selectIsOnboarded);
  const { L, LSwap } = useLocalization();
  
  return (
    <Grid id="timesheet-view" container className={classes.root}>
      <Helmet>
        <title>Gigops - Timesheet</title>
      </Helmet>
     {isOnboarded && 
        <>
          <TimesheetHeader />
          <HoursListView />
        </>
      }
      {!isOnboarded && 
        <>
          <Typography className={classes.title}>{LSwap("You need Clients and Projects before you can begin tracking time.", ["clients", "projects"])}</Typography>
          <Typography className={classes.subTitle}>
            Visit the <Link to="/">{L("dashboard", true)}</Link> or click the <AddCircleIcon fontSize="small" color="secondary"/> button in the header to begin.
          </Typography>
        </>
      }
    </Grid>
  );
};

export default TimesheetView;
