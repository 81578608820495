import { createSelector } from "@reduxjs/toolkit";
import { selectAllClients } from "features/app/infra/common-selectors";
import { selectAllProjects } from "features/projects";
import { orderBy } from "lodash";
import { INote, RootState } from "types";

const _getInitialized = (state: RootState) => state.notes.isInitialized;
const _getNotes = (state: RootState) => state.notes.items;
const _getNoteSettings = (state: RootState) => state.notes.settings;
const _getInputs = (state: RootState, inp1: string, inp2: string) => ({inp1, inp2});

//===
// Gets all the notes prepared with client, project and a search string.
export const selectNotes = createSelector(
  _getNotes, selectAllClients, selectAllProjects,
  (notes, clients, projects) => {
    if(!notes) return [];
    
    const preped = notes.map(note => {
      const client = note.clientId ? clients.find(c => c.id === note.clientId) : null;
      const project = note.projectId ? projects.find(p => p.id === note.projectId) : null;
      const withProps = {
        ...note,
        client,
        project,
        favSort: note.isFavorite === true ? 1 : 0,
      } as INote;

      //create a search string with relevant fields for each note
      const searchString = [note.content, note.project?.name || "", note.client?.name || ""].join(" ").toLowerCase();
      return {
        ...withProps,
        searchString,
      };

    });

    return preped;
  }
);

//===
// Gets the notes filtered based on the search / filter props of the settings in the Notes Slice.
export const selectFilteredNotes = createSelector(
  selectNotes, _getNoteSettings, _getInitialized,
  (notes, settings, isInitialized) => {
    if(!isInitialized) return null;
    let result = notes;

    if(settings.search){
      const searchStr = settings.search.toLowerCase();
      result = notes.filter(n => n.searchString.indexOf(searchStr) >= 0);
    }
    else if(settings.filter?.projectId){
      result = settings.filter.projectId === "none" ? notes.filter(n => !n.projectId) : notes.filter(n => n.projectId === settings.filter?.projectId);
    }
    else if(settings.filter?.clientId){
      result = settings.filter.clientId === "none" ? notes.filter(n => !n.clientId) : notes.filter(n => n.clientId === settings.filter?.clientId);
    }

    if(settings.filter?.status === "favorite"){
      result = result.filter(tsk => tsk.isFavorite);
    }
    //else: all = all of them


    const ordered = orderBy(result, ["favSort", "noteDate"], ["desc", "desc"]);
    return ordered;
  }
);

//===
// Gets the notes for a specific client or project
export const selectNotesFor = createSelector(
  selectNotes, _getInputs, _getInitialized,
  (notes, inputs, isInitialized) => {
    if(!isInitialized) return null;
    let result = notes;

    if(inputs.inp1 === "project" && inputs.inp2){
      result = inputs.inp2 === "none" ? notes.filter(n => !n.projectId) : notes.filter(n => n.projectId === inputs.inp2);
    }
    else if(inputs.inp1 === "client" && inputs.inp2){
      result = inputs.inp2 === "none" ? notes.filter(n => !n.clientId) : notes.filter(n => n.clientId === inputs.inp2);
    }    

    const ordered = orderBy(result, ["favSort", "noteDate"], ["desc", "desc"]);
    return ordered;
  }
);