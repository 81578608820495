import { addDays, addMinutes, addMonths, differenceInDays, differenceInMinutes, endOfDay, startOfDay, startOfYear, subDays, subMonths, subYears } from "date-fns";
import faker from "faker";
import { roundMinutes } from "features/timesheet";
import { IClient, IProject, ITimer, ProjectType } from "types";

const companyCategories = ["Magazine", "Brand", "Agency"];
const projectCategories = ["Editing", "Print", "Digital", "Content Marketing"];
const timerCategories = ["Writing", "Editing", "Reporting", "Researching", "Admin"];
const tmrRoundType = ["none", "round15", "push15"];

const projectTypes = ["perWord", "perHour", "perArticle","fixed"];
const rateRanges: Record<string, any> = {
  "perWord": {min: 0, max: 2},
  "perHour": {min: 60, max: 130},
  "perArticle": {min: 150, max: 800},
  "fixed": {min: 400, max: 4000},
};
const timerRanges: Record<string, any> = {
  "Writing": {min: 35, max: 240 },
  "Editing": {min: 20, max: 300 },
  "Reporting": {min: 15, max: 60 },
  "Researching": {min: 30, max: 360 },
  "Admin": {min: 15, max: 60 },
};

const wordCountRange = {min: 500, max: 5000 };
const articleCountRange = {min: 1, max: 5};

const generateProjectUnits = (projectType: string): number | null => {
  switch(projectType){
    case "perWord": return faker.random.number(wordCountRange);
    case "perArticle": return faker.random.number(articleCountRange);
    
    case "perHour":
    case "fixed" :
    default:
      return null;
  }
}
const generateRate = (type: string) => {
  if(type === "perWord") {
    //faker.random.float doesn't seem to work...
    const whole = faker.random.number(rateRanges[type]);
    const decimal = faker.random.number({min: whole === 0 ? 5 : 0, max: 9});
    const rate = whole + (decimal / 10);
    return rate;
  }
  else return faker.random.number(rateRanges[type]);
}

const generateProjectLength = (projectType: string, rate: number, units: number): number => {
  
  switch(projectType){
    case "perWord":
      if(units < 750) return faker.random.number({min: 15, max: 30 });
      if(units < 2500) return faker.random.number({min: 15, max: 45});
      else return faker.random.number({min: 30, max: 75});
          
    case "perHour":
      if(rate > 100) return faker.random.number({min: 15, max: 30});
      if(rate > 75) return faker.random.number({min: 20, max: 45});
      else return faker.random.number({min: 20, max: 90});

    case "perArticle":
      if(units <= 2) return faker.random.number({min: 15, max: 30});
      if(units <= 4) return faker.random.number({min: 20, max: 45});
      else return faker.random.number({min: 20, max: 90});
    
    case "fixed":
      if(rate < 1000) return faker.random.number({min: 10, max: 30 });
      if(rate < 2500) return faker.random.number({min: 15, max: 45});
      if(rate < 4000) return faker.random.number({min: 20, max: 45});
      else return faker.random.number({min: 25, max: 60});
  }

  return 0;
}


const generateProjectDates = (projectLength: number) => {
  const now = startOfDay(new Date());
  const beforeNow = subDays(now, 2);
  const lastMonth = subMonths(now, 1);
  const start = subYears(startOfYear(now), 1); //start of last year
  // const end = addMonths(now, 2);

  const num = faker.random.number({min: 0, max: 100});
  if(num > 50){ //delivered
    const startDate = faker.date.between(start, beforeNow);
    const dueDate = endOfDay(addDays(startDate, projectLength));
    const deliveredDate = startOfDay(faker.date.between(subDays(dueDate, 2), addDays(dueDate, 2)));
    const invoicedDate = differenceInDays(now, deliveredDate) > 5 ? startOfDay(faker.date.between(deliveredDate, now)) : undefined;
    const paidDate = invoicedDate && differenceInDays(now, invoicedDate) > 15 ? startOfDay(faker.date.between(addDays(invoicedDate, 10), now)) : undefined;
    
    const dates: any = {
      startDate,
      dueDate,
      deliveredDate,
    };
    if(invoicedDate) dates.invoicedDate = invoicedDate;
    if(paidDate) dates.paidDate = paidDate;
    return dates;
  }
  else{ //not delivered
    const startDate = faker.date.between(lastMonth, now);
    const dueDate = endOfDay(addDays(startDate, projectLength));
    return {
      startDate,
      dueDate,
    };    
  }
}


export const generateSampleClient = (): IClient => {
  const defType = faker.random.arrayElement(projectTypes);
  const defRate = generateRate(defType);

  return {
    id: `SAMPLE-C-${faker.finance.account(5)}`,
    name: faker.company.companyName(),
    contactName: `${faker.name.firstName()} ${faker.name.lastName()}`,
    contactEmail: faker.internet.exampleEmail(),
    contactPhone: faker.phone.phoneNumber(),
    address: faker.fake("{{address.streetAddress}}\n{{address.secondaryAddress}}\n{{address.city}}, {{address.stateAbbr}} {{address.zipCode}}"),
    category: faker.random.arrayElement(companyCategories),
    defaultProjectType: defType as ProjectType,
    defaultRate: defRate,
  };
}

export const generateSampleProject = (client: IClient): IProject => {
  const prjType = faker.random.arrayElement(projectTypes);
  const prjFee = prjType === client.defaultProjectType ? client.defaultRate : generateRate(prjType);
  const prjUnits = generateProjectUnits(prjType);
  const prjLength = generateProjectLength(prjType, prjFee as number, prjUnits || 0);
  const dates = generateProjectDates(prjLength);

  const project: IProject = {
    id: `SAMPLE-P-${faker.finance.account(5)}`,
    name: faker.company.catchPhrase(),
    clientId: client.id,
    category: faker.random.arrayElement(projectCategories),
    roundType: faker.random.arrayElement(tmrRoundType),
    ...dates,
    fee: prjFee,
    type: prjType as ProjectType,
  };

  if(prjUnits) project.units = prjUnits;
  return project;
}

export const generateSampleHours = (project: IProject): Partial<ITimer>[] => {
  const now = new Date();
  const prjStart = project.startDate as Date;
  let prjEnd = project.dueDate as Date;
  if(prjEnd > now) prjEnd = startOfDay(now);
  const days = differenceInDays(prjEnd, prjStart);
  const count = Math.round(days / 3);
  const timers = [];

  let timerFloor = prjStart;

  for(let i = 0; i < count; i++){
    const category = faker.random.arrayElement(timerCategories);
    const tmrStart = faker.date.between(timerFloor, prjEnd);
    const range = timerRanges[category];
    const mins = faker.random.number(range);
    const tmrStop = addMinutes(tmrStart, mins);
    const roundedMins = roundMinutes(mins, project.roundType);

    const timer: Partial<ITimer> = {
      startTime: tmrStart,
      stopTime: tmrStop,
      clientId: project.clientId,
      projectId: project.id,
      category: category,
      notes: faker.company.catchPhrase(),
      minutes: roundedMins,
      roundType: project.roundType,
    };

    timers.push(timer);
    timerFloor = tmrStop;
  }

  return timers;
}

export const generateSampleData = (clientCount: number) => {

  const output = {
    clients: [] as IClient[],
    projects: [] as IProject[],
    hours: [] as Partial<ITimer>[],
  };

  for(let i = 0; i < clientCount; i++){
    const client = generateSampleClient();
    output.clients.push(client);

    const projectCount = faker.random.number({min: 1, max: 8});
    for(let p = 0; p < projectCount; p++){
      const project = generateSampleProject(client);
      output.projects.push(project);

      const hours = generateSampleHours(project);
      output.hours = [...output.hours, ...hours];
    }
  }

  return output;
}