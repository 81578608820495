import _ from "lodash";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IStatus, IClient, ClientSettings } from "types";
import { useFirestore, STATUSES } from "utils/firebase";
import { AppThunk } from "store/root-reducer";
import { getChanges, stringsToDates, datesToStrings, removeUndefined } from "utils/general-helpers";


interface InitialState {
  items: Array<IClient> | null;
  isInitialized: boolean;
  isEmpty?: boolean | null;
  settings: ClientSettings;
  status: IStatus;
}

export const initialState: InitialState = {
  items: null,
  isEmpty: null,
  isInitialized: false,
  settings: {
    filter: "active",
    sort: "name",
    sortDir: "asc",
  },
  status: {
    isWorking: false,
    error: null,
  }
};

export const slice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    statusChanged: (state, action) => {
      state.status = {
        ...state.status,
        ...action.payload,
      };
    },
    updateClientSettings: (state, action) => {
      const updates = { ...state.settings, ...action.payload };
      state.settings = updates;
    },
    clearStore: (state) => {
      state.items = initialState.items;
      state.isEmpty = initialState.isEmpty;
      state.isInitialized = initialState.isInitialized;
      state.settings = {...initialState.settings};
      state.status = {...initialState.status};
    },
    clientsLoaded: (state, action) => {
      state.items = action.payload;
      state.isEmpty = Boolean(!action.payload || action.payload.length === 0);
      state.isInitialized = true;
      state.status = {isWorking: false, error: null};
    },
    clientAdded: (state, action) => {
      if (state.items === null) state.items = [];
      state.items.push(action.payload);
      state.isEmpty = false;
      state.status = {isWorking: false, error: null};
    },
    clientsAdded: (state, action: PayloadAction<IClient[]>) => {
      if (state.items === null) state.items = [];
      state.items.push(...action.payload);
      state.isEmpty = false;
      state.status = {isWorking: false, error: null};
    },
    clientUpdated: (state, action) => {
      const existing = state.items?.find(cli => cli.id === action.payload.id) as any;
      if (!!existing) {
        _.keys(action.payload.changes).forEach(key => {
          existing[key] = action.payload.changes[key];
        });
        state.status = {isWorking: false, error: null};
      }
    },
    clearItems: (state) => {
      state.items = initialState.items;
      state.isEmpty = initialState.isEmpty;
    },
    
  },
});

export const { statusChanged, clearStore, clearItems, clientsLoaded, clientAdded, clientsAdded, updateClientSettings, clientUpdated } = slice.actions;
export default slice.reducer;

//====== ACTIONS ======

//-- load clients
export const loadClients = (): AppThunk => async (dispatch, getState) => {
  const [api, uid] = useFirestore();

  if (!uid) throw new Error("Must be logged in to load clients.");
  const app = getState().app;

  if (app.isInitialized) {
    dispatch(statusChanged({ isWorking: true, error: null }));
    const result = await api.getClients(app.org.id);
    if (result.statusCode === STATUSES.ok || result.statusCode === STATUSES.empty) {
      const list = result.items?.map(i => datesToStrings(i));
      dispatch(clientsLoaded(list || []));
    }
    else{
      dispatch(statusChanged({ isWorking: false, error: result.error }));
    }
  }
}

//-- create a new Client
export const createClient = (item: IClient): AppThunk => async (dispatch, getState) => {
  const [api, uid] = useFirestore();

  if (!uid) throw new Error("Must be logged in to create a client.");
  const app = getState().app;

  if (app.isInitialized) {
    await dispatch(statusChanged({ isWorking: true, error: null }));
    const prepared = removeUndefined({ ...item, createdDate: new Date() });
    const result = await api.createClient(app.org.id, prepared);
    if (result.statusCode === STATUSES.ok) {
      //Update the local store
      const storeClient = {
        ...datesToStrings(prepared),
        id: result.key
      };

      await dispatch(clientAdded(storeClient));

      return storeClient;
    }
    else{
      await dispatch(statusChanged({ isWorking: false, error: result.error }));
      return null;
    }
  }
}

//-- upload a list of clients
export const uploadClients = (items: IClient[]): AppThunk => async (dispatch, getState) => {
  const [api, uid] = useFirestore();

  if (!uid) throw new Error("Must be logged in to create a client.");
  const app = getState().app;

  if (app.isInitialized) {
    await dispatch(statusChanged({ isWorking: true, error: null }));
    const prepared = items.map(item => removeUndefined({ ...item, createdDate: new Date() }));
    const result = await api.createClients(app.org.id, prepared);
    
    if (result.statusCode === STATUSES.ok) {
      //Update the local store
      const ids = (result.key as string).split("~");
      let i = 0;
      const toStore = prepared.map(cli => ({...datesToStrings(cli), id: ids[i++]} as IClient));
      await dispatch(clientsAdded(toStore));
      return toStore;
    }
    else{
      await dispatch(statusChanged({ isWorking: false, error: result.error }));
      return null;
    }
  }
}

export const updateClient = (clientId: string, changes: Partial<IClient>): AppThunk => async (dispatch, getState) => {
  const [api, uid] = useFirestore();

  if (!uid) throw new Error("User is not authorized.");
  const app = getState().app;

  if (app.isInitialized) {
    //get the actual changes
    const client = getState().clients.items?.find(cli => cli.id === clientId);
    if (client) {
      const actualChanges = getChanges(client as any, changes as any);

      if (actualChanges !== null) {
        dispatch(statusChanged({ isWorking: true, error: null }));
        const prepared = stringsToDates(actualChanges);
        const result = await api.updateClient(app.org.id, clientId, prepared);

        if (result.statusCode === STATUSES.ok) {
          //Update the local store
          // const toStore = prepareDates(actualChanges);
          dispatch(clientUpdated({ id: clientId, changes: datesToStrings(prepared) }));
          return clientId;
        }
        else{
          dispatch(statusChanged({ isWorking: false, error: result.error }));
        }
      }
    }
  }

  return null;
}