import React, { useEffect, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from '@material-ui/core/TextField';
import Typography from "@material-ui/core/Typography";
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { feeAdornStyle, formStyles } from "utils/styles";
import { selectCustomizations } from "features/app";
import { emptyClient, IClient, ProjectTypeItems, RootState } from "types";
import { useSelector } from "react-redux";
import { IParserCollection, Parsers, useInputs } from "utils/hooks";
import { Icons } from "utils/common-classes";

const parsers: IParserCollection = {
  defaultRate: Parsers.currency,
};

const buildStyles   = makeStyles(theme => ({
  ...formStyles(theme),
  ...feeAdornStyle(theme),
}));

interface ControlProps {
  onChange: (values: Partial<IClient>) => void;
  onRemove: () => void;
  noRemove?: boolean;
}

const NewClientRow = ({ onChange, onRemove, noRemove } : ControlProps) => {
  const classes = buildStyles() as any;
  const defaults = useSelector((state: RootState) => selectCustomizations(state, ["defaultProjectType", "defaultHourlyRate"]));
  
  const initialClient = useMemo(() => ({
    ...emptyClient, 
    defaultRate: defaults.defaultHourlyRate, 
    defaultProjectType: defaults.defaultProjectType
  }), []);

  const [values, display, errors, binding] = useInputs<Partial<IClient>>(initialClient, parsers);

  useEffect(() => {
    onChange(values);
  }, [values]);

  return (
    <Grid container spacing={1}>
        <Grid item md={4} className={classes.inputGrid}>
          <TextField id="name" label="Name*" fullWidth value={display?.name} {...binding.input} {...errors["name"]} autoComplete="off" autoFocus={noRemove} />
        </Grid>
        <Grid item md={2} className={classes.inputGrid}>
          <TextField id="contactName" label="Contact" fullWidth value={display?.contactName} {...binding.input} {...errors["contactName"]} autoComplete="off" />
        </Grid>
        <Grid item md={3} className={classes.inputGrid}>
          <TextField id="contactEmail" label="Contact Email" fullWidth value={display?.contactEmail} {...binding.input} {...errors["contactEmail"]} autoComplete="off" />
        </Grid>
        <Grid item md={2} className={classes.inputGrid} container>
          <TextField id="defaultRate" label="Default Fee / Rate" className={classes.rateInput} value={display?.defaultRate} {...binding.input} {...errors["defaultRate"]} autoComplete="off"
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <Grid container className={classes.adornGrid}>
                  <Typography className={classes.adorn}>/</Typography>
                  <TextField select value={display?.defaultProjectType} {...binding.select("defaultProjectType")} className={classes.adornSelect}>
                    <MenuItem value={-1} disabled>[choose]</MenuItem>
                    {ProjectTypeItems.map(opt => <MenuItem key={opt.id} value={opt.id} className={classes.option}>{opt.unitLabel}</MenuItem>)}
                  </TextField>
                </Grid>
              </InputAdornment>

            }}
          />
        </Grid>
        <Grid item md={1} className={classes.inputGrid} container justify="center" alignContent="flex-end">
          {!noRemove && <IconButton onClick={onRemove} size="small">{Icons.delete("small")}</IconButton>}
        </Grid>
      </Grid>
  );
}

export default NewClientRow;
