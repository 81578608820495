import React, { useMemo, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';
import { fontStyles, generalStyles } from "utils/styles";
import { useSelector } from "react-redux";
import { selectClientRates, selectClientViewStats } from "../infra/client-selectors";
import { IMenuItem, RootState } from "types";
import { useParams } from "react-router-dom";
import { formatCurrency } from "utils/number-helpers";
import { ChipMenu } from "components";
import { capitalize } from "lodash";

const buildStyles   = makeStyles(theme => ({
  ...generalStyles(theme),
  ...fontStyles(theme),
  root  : {
    padding     : theme.spacing(1),
    background: theme.palette.common.white,
    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap !important",
    }
  },
  menuRow: {
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
      marginBottom: theme.spacing(1),
    }
  },
  statCell: {
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(1),
    }
  },
  emphasisDiv: {
    width: "15%",
    height: 3,
  },
  ltvColor: {
    background: theme.palette.success.main,
  },
  yearColor: {
    background: theme.palette.secondary.main,
  },
  monthColor: {
    background: theme.palette.info.main,
  },
  rateColor: {
    background: theme.palette.primary.main,
  },
  chipIcon: {
    height: 28,
    width: 28,
    border: "1px solid rgba(0,0,0,0.23)",
    "& .MuiIcon": {
      fontSize: 22,
    }
  },
}));

// interface StatStruct extends Record<string, unknown> {
//   month: number;
//   year: number;
//   allTime: number;
// }

const viewMenu: IMenuItem[] = [
  // { id: 10, label: "Earned", action: "earned" },
  { id: 20, label: "Invoiced", action: "invoiced" },
  { id: 30, label: "Paid", action: "paid" },
];


const ClientStats = () => {
  const classes   = buildStyles();
  const { clientId } = useParams<{ clientId: string }>();
  const data = useSelector((state: RootState) => selectClientViewStats(state, clientId));
  const clientRates = useSelector(selectClientRates);
  const [view, setView] = useState<"paid" | "invoiced">("invoiced");
  const rate = useMemo(() => {
    const myData = clientRates ? clientRates.find(d => d.clientId === clientId) : null;
    return myData;
  }, [data]);
  const stats = useMemo<Record<string, number>>(() => data ? data[view] : {}, [view, data]);

  const onViewChange = (item: IMenuItem) => {
    setView(item.action);
  }

  return (
    <Grid id="client-stats" container wrap="nowrap" alignItems="center" className={classes.root}>
      <Grid item sm={2} xs={12} container className={classes.menuRow}>
        <ChipMenu 
          // disabled
          label={capitalize(view)} 
          icon={<Icon fontSize="small">arrow_drop_down</Icon>} 
          size="small" 
          items={viewMenu} 
          title="Toggle the display values" 
          variant="outlined" 
          onClick={onViewChange} 
          isSelected={(item: IMenuItem) => item.action === view}
        />
      </Grid>

      <Grid item md={2} sm={3} xs={6} container direction="column" className={classes.statCell}>
        <Typography className={clsx(classes.caption, classes.bold)}>Month</Typography>
        <Grid item container>
          <Tooltip title="This month">
            <Typography className={classes.title}>{formatCurrency(stats.month)}</Typography>
          </Tooltip>
          {/* {data && data.monthChange.diff !== 0 ? <ChangeArrow change={data.monthChange} title="{change} from last month" variant="trending" /> : null} */}
        </Grid>
        <div className={clsx(classes.emphasisDiv, classes.monthColor)}/>
      </Grid>

      <Grid item md={2} sm={3} xs={6} container direction="column" className={classes.statCell}>
        <Typography className={clsx(classes.caption, classes.bold)}>Year</Typography>
        <Grid item container>
          <Tooltip title="This year">
            <Typography className={classes.title}>{formatCurrency(stats.year)}</Typography>
          </Tooltip>
          {/* {data && data.yearChange.diff !== 0 ? <ChangeArrow change={data.yearChange} title="{change} from last year" variant="trending" /> : null} */}
        </Grid>
        <div className={clsx(classes.emphasisDiv, classes.yearColor)}/>
      </Grid>

      <Grid item md={2} sm={3} xs={6} container direction="column" className={classes.statCell}>
        <Typography className={clsx(classes.caption, classes.bold)}>LTV</Typography>
        <Tooltip title="Life Time Value of this client">
          <Typography className={classes.title}>{formatCurrency(stats.allTime)}</Typography>
        </Tooltip>
        <div className={clsx(classes.emphasisDiv, classes.ltvColor)}/>
      </Grid>

      {rate && 
        <Grid item md={2} sm={3} xs={6} container direction="column" className={classes.statCell}>
          <Typography className={clsx(classes.caption, classes.bold)}>Avg Rate</Typography>
          <Grid item container>
            <Tooltip title="Your average hourly rate for this client">
              <Typography className={classes.title}>{formatCurrency(rate.average)} / hr</Typography>
            </Tooltip>
            {/* {data && data.monthChange.diff !== 0 ? <ChangeArrow change={data.monthChange} title="{change} from last month" variant="trending" /> : null} */}
          </Grid>
          <div className={clsx(classes.emphasisDiv, classes.rateColor)}/>
        </Grid>
      }

      <Grid item md sm container justify="flex-end" alignItems="center">
        
        {/* <Tooltip title="Set goals for this client">
          <Button color="primary" size="small">Goals</Button>
        </Tooltip> */}
      </Grid>
    </Grid>
  );
}

export default ClientStats;
