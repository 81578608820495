import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import Grid from "@material-ui/core/Grid";
import IconButton from '@material-ui/core/IconButton';
import Typography from "@material-ui/core/Typography";
import Tooltip from '@material-ui/core/Tooltip';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import { cardStyles, fontStyles, generalStyles, statusStyles } from "utils/styles";
import { ProjectWithInvoices } from "types";
import { Link } from "react-router-dom";
import { getRoute } from "features/app";
import { Icons } from "utils/common-classes";
import { SubItem } from "components";
import { useDialogs, useHover } from "utils/hooks";
import ProjectTimerButton from "../project-timer-button";
import ProjectDateBox from "../project-date-box";
import ProjectNextAction from "./project-next-action";
import ProjectCardStats from "./project-card-stats";

const buildStyles   = makeStyles(theme => ({
  ...cardStyles(theme),
  ...generalStyles(theme),
  ...fontStyles(theme),
  ...statusStyles(theme),
  root  : {
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`, //`
    height: theme.spacing(30),
  },
  projectCard: {
    height: "100%",
  },
  cardHover: {
    boxShadow: "5px 5px 15px 5px rgba(94,94,94,0.3)",
  },
  headerGrid: {
    maxWidth: "85%"
  },
  cardTitle: {
    fontSize: "1.2em",
    fontWeight: 600,
    color: theme.palette.common.black,
  },
  notesGrid: {
    maxHeight: theme.spacing(5),
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  avatar: {
    backgroundColor: `${theme.palette.primary.main}66`, //` theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  avatarHover: {
    backgroundColor: theme.palette.primary.main,
    // color: theme.palette.primary.contrastText,
  },
  noWrap: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    // width: "95%",
    overflow: "hidden",
  },
}));

interface CardProps{
  item: ProjectWithInvoices;
}

const ProjectCard = ({item: project}: CardProps) => {
  const classes   = buildStyles();
  const [hoverRef, isHovered] = useHover();
  const { onOpenDialog} = useDialogs();
  
  return (
    <Grid item xl={3} lg={4} md={6} sm={12} className={classes.root}>
      <Paper ref={hoverRef} variant="outlined" className={clsx([classes.card, classes.mbl, classes.fullHeight, {[classes.cardHover]: isHovered}])}>
        <Grid container className={clsx([classes.cardBody, classes.fullHeight])}>
          <Grid container direction="column" justify="space-between" wrap="nowrap" className={classes.fullHeight}>
            <Grid container>

              <Grid container alignItems="flex-start" wrap="nowrap" className={classes.mbl}>
                <Avatar className={clsx(classes.marginRightLg, classes.avatar, {[classes.avatarHover]: isHovered})}>
                  {Icons.project()}
                </Avatar>
                <Grid item className={classes.headerGrid}>
                  <Link to={getRoute("project", project.id)} className={classes.link}>
                    <Tooltip title={project.name}>
                      <Typography className={clsx([classes.cardTitle, classes.noWrap])}>{project.name}</Typography>
                    </Tooltip>
                  </Link>
                <Grid container alignItems="center">
                    <Link to={getRoute("client", project.clientId)} className={classes.link}>
                      <Typography color="primary" className={classes.infoText}>{project.client.name}</Typography>
                    </Link>              
                    <SubItem icon="person_outlined" label={project.client.contactName} tooltip={`Contact: ${project.client.contactName}`} hideLabel/>
                    <SubItem icon="mail_outlined" label={project.client.contactEmail} link={`mailto:${project.client.contactEmail}`} tooltip={`Email: ${project.client.contactEmail}`} hideLabel/>
                    <SubItem icon="phone_outlined" label={project.client.contactPhone} tooltip={`Phone: ${project.client.contactPhone}`} hideLabel/>
                  </Grid>
                </Grid>
              </Grid>
              
              <Grid container direction="column" alignItems="flex-start">
                <Grid container alignItems="center" className={classes.mbl}>
                  <ProjectDateBox project={project} dateType="delivered" className={classes.marginRight} />
                  <ProjectDateBox project={project} dateType="paid" className={classes.marginRight} />
                  <ProjectNextAction project={project} />
                </Grid>
                <ProjectCardStats project={project} />
              </Grid>
            </Grid>

            {isHovered &&
              <Grid container direction="column" alignItems="center">
                <Divider className={classes.divider}/>
                <Grid container justify="space-evenly" style={{width: "50%"}}>
                  <Tooltip title="Edit this project">
                    <IconButton size="small" component={Link} to={getRoute("projectEdit", project.id)}>{Icons.edit("small")}</IconButton>
                  </Tooltip>
                  <ProjectTimerButton project={project} />
                  <Tooltip title="Create an invoice for this project">
                    <IconButton size="small" onClick={onOpenDialog("invoice", null, {clientId: project.clientId, projectId: project.id})}>{Icons.invoice("small")}</IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            }
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}

export default ProjectCard;
