import firebase from "firebase/app";
import "firebase/firestore";
import _ from "lodash";
import { COLS, STATUSES, IFirestoreResult, asyncForEach, getSnapshotDoc, collectionRef } from "utils/firebase";
import { IProject, ProjectTracking } from "types";
import { prepareForDb } from "utils/general-helpers";

export const projectCollections = {
  PROJECT: "projects",
  // PROJECT_TRACKED_DAYS: "trackedDays",
  PROJECT_HOURS: "project-hours",
};

//Properties that shound not be saved to the DB (but may get added somewhere along the way)
const WHITELIST = [
  "name",
  "clientId",
  "startDate",
  "dueDate",
  "deliveredDate",
  "type",
  "fee",
  "units",
  "actualUnits",
  "invoicedDate",
  "paidDate",
  "notes",
  "isArchived",
  "createdDate",
  "roundType",
  "trackedMinutes",
  "trackedDays",
  "estimatedHours",
  "category",
  "wordCount",
]
// const BAD_PROPS = ["id", "uid", "client", "clientName", "invoices", "lastInvoiceDate", "lastPaymentDate", "lastInvoice", "totalInvoiced", "totalPaid", "uninvoiced", "unpaid"];

export const projectsApi = (db: firebase.firestore.Firestore) => ({

  //---
  // Creates a new client for the specified user
  createProject: async (orgId: string, project: IProject): Promise<IFirestoreResult> => {
    const id = project.id;
    const safeProject = prepareForDb(project, null, null, WHITELIST);
    // const fsProject = _.omit(project, BAD_PROPS);  //remove the id property, if present
    const coll = collectionRef(db, orgId, COLS.PROJECT);
    let projectId = "";

    if(id){
      const doc = coll.doc(id);
      await doc.set(safeProject);
      projectId = id;
    }
    else{
      const result = await coll.add(safeProject);
      projectId = result.id;
    }
    // const result = await db.collection(COLS.ORG).doc(orgId).collection(COLS.PROJECT).add(fsProject);

    return {
      ok: true,
      statusCode: STATUSES.ok,
      key: projectId
    };
  },

  //---
  // Creates a new client for the specified user
  createProjects: async (orgId: string, projects: IProject[]): Promise<IFirestoreResult> => {
    
    const items = projects.map(prj => ({id: prj.id, project: prepareForDb(prj, null, null, WHITELIST)}));
    const batch = db.batch();
    const coll = collectionRef(db, orgId, COLS.PROJECT);
    const keys = [] as string[];

    await asyncForEach(items, async item => {
      if(item.id){
        const doc = coll.doc(item.id);
        await doc.set(item.project);
        keys.push(item.id);
      }
      else{
        const result = await coll.add(item.project);
        keys.push(result.id);
      }
    });

    await batch.commit();

    return {
      ok: true,
      statusCode: STATUSES.ok,
      key: keys.join("~"),
    };
  },


  //---
  // Updates an existing project
  updateProject: async (orgId: string, projectId: string, changes: Partial<IProject>): Promise<IFirestoreResult> => {
    const safeChanges = prepareForDb(changes, null, null, WHITELIST);
    // const safeChanges = _.omit(changes, BAD_PROPS);  //remove the id property, if present
    const project = db.collection(COLS.ORG).doc(orgId).collection(COLS.PROJECT).doc(projectId);
    await project.update(safeChanges);

    return {
      ok: true,
      statusCode: STATUSES.ok,
      key: projectId
    };
  },

  //---
  // Gets the list of projects for the current user
  getProjects: async (orgId: string): Promise<IFirestoreResult> => {
    const items: IProject[] = [];

    const projects = await collectionRef(db, orgId, COLS.PROJECT).get();
    if(!projects.empty){
      await asyncForEach(projects.docs, async doc => {
        const item = getSnapshotDoc<IProject>(doc); 
        items.push(item);
      });
    }

    if (items.length > 0) {
      return {
        ok: true,
        statusCode: STATUSES.ok,
        key: orgId,
        items: items,
      };
    }
    else {
      return {
        ok: true,
        statusCode: STATUSES.empty,
        key: orgId,
      };
    }
  },

  //---
  // Gets the project tracking information
  getProjectHours: async (orgId: string): Promise<IFirestoreResult> => {
    const items: ProjectTracking[] = [];
    const snapshot = await collectionRef(db, orgId, COLS.PROJECT_HOURS).get();
    await asyncForEach(snapshot.docs, async doc => {
      const item = await getSnapshotDoc<ProjectTracking>(doc);
      items.push(item);
    });

    return {
      ok: true,
      statusCode: snapshot.empty ? STATUSES.empty : STATUSES.ok,
      key: orgId,
      data: items,
    };
  }
});