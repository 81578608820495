import { AppThunk, ExternalItemType, Integration } from "types";
import { STATUSES, useFirestore } from "utils/firebase";
import { datesToStrings, removeUndefined } from "utils/general-helpers";
import { itemUpdated, itemsLoaded, statusChanged } from "./integrations-slice";

//-- Load the current integrations for this user
export const loadIntegrations = (): AppThunk => async (dispatch, getState) => {
  const [api, uid] = useFirestore();
  if (!uid) throw new Error("Must be logged in to load notes.");
  const app = getState().app;

  if (app.isInitialized) {
    //Check to see if we've already loaded them... since we get all of them at once
    const state = getState().integrations;
    if(state.isInitialized) return;

    await dispatch(statusChanged({isWorking: true, error: null}));

    const result = await api.getIntegrations(app.org.id);
    if(result.statusCode === STATUSES.ok || result.statusCode === STATUSES.empty){
      const list = result.items?.map(i => datesToStrings(i)) as Integration[];
      dispatch(itemsLoaded(list || []));
    }
    else{
      dispatch(statusChanged({ isWorking: false, error: result.error }));
    }
  }
}

export const addIntegration = (item: Integration): AppThunk => async (dispatch, getState) => {
  const [api, uid] = useFirestore();
  if (!uid) throw new Error("Not logged in.");
  const app = getState().app;

  if (app.isInitialized) {

    await dispatch(statusChanged({ isWorking: true, error: null }));
    const prepared = removeUndefined({ ...item, lastUpdatedDate: new Date() });
    const result = await api.createIntegration(app.org.id, prepared);

    if (result.statusCode === STATUSES.ok) {
      //Update the local store
      const toStore = {
        ...datesToStrings(prepared),
        id: result.key
      } as Integration;

      await dispatch(itemUpdated({id: toStore.id, changes: toStore}));

      return toStore;
    }
    else{
      await dispatch(statusChanged({ isWorking: false, error: result.error }));
      return null;
    }
  }
}

export const fetchExternalData = (item: Integration, itemType: ExternalItemType, itemId: string | null = null): AppThunk => async (dispatch, getState) => {
  const [api, uid] = useFirestore();
  if (!uid) throw new Error("Not logged in.");
  const app = getState().app;

  if (app.isInitialized) {

    await dispatch(statusChanged({ isWorking: true, error: null }));
    const result = await api.fetchExternalData(item, itemType, itemId);

    if(result.ok){
      await dispatch(itemUpdated({id: item.id, changes: {data: {[itemType]: result.data}}}));
      return result.data;
    }
    else{
      await dispatch(statusChanged({ isWorking: false, error: result.error }));
      return null;
    }
  }

}