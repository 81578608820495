import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from '@material-ui/core/Tooltip';
import { Steps } from "components";
import { DISPLAY_TIME_FORMAT, formatDate } from "utils/date-helpers";
import { formatDuration } from "../infra/timesheet-helpers";

const buildStyles   = makeStyles(theme => ({
  durationTime: {
    fontSize: 30,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
  },
  labelTime: {
    fontSize: 18,
    fontWeight: 300,
    color: `${theme.palette.primary.contrastText}6`, //`,
  },
  stepGrid: {
    paddingTop: theme.spacing(1),
    "& .MuiIcon-root": {
      color: `${theme.palette.common.white}3`, //`
      fontSize: "0.8rem",
      margin: `${theme.spacing(0)}px ${theme.spacing(0.25)}px`, //`
    }
  }
}));

interface ControlProps{
  startTime: Date;
  duration: { hours: number, minutes: number };
  onCycle: () => void;
}

const DisplayFraction = ({startTime, duration, onCycle}: ControlProps) => {
  const classes   = buildStyles();
  const startDisplay = React.useMemo(() => { return formatDate(startTime, DISPLAY_TIME_FORMAT); }, [startTime]);
  
  return (
    <Tooltip title={`started at ${startDisplay}`}>
      <Grid container onClick={onCycle}>
        <Grid container>
          <Grid container>
            <Grid item xs={12} container justify="center" alignItems="center">
              <Typography className={classes.durationTime}>{formatDuration(duration, true)}</Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} container justify="center" alignItems="center">
              <Typography className={classes.labelTime}>hours</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Steps steps={3} current={1} classes={classes.stepGrid} />
      </Grid>
    </Tooltip>
  );
}

export default DisplayFraction;
