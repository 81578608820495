import React from "react";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from '@material-ui/core/Paper';
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import { RootState, IClient } from "types";
import { useLocalization, useDialogs } from "utils/hooks";
import { selectClientProjects } from "features/projects";
import AtkTable from "components/atk-table";
import ClientProjectsListRow, { rowHeader, getSortValue } from "./client-projects-list-row";
import { cardStyles } from "utils/styles";

const buildStyles = makeStyles(theme => ({
  ...cardStyles(theme),
  root: {
    marginTop: 0,
  },
  tableGrid: {
    margin: 0,
  },
}));

type Props = {
  client: IClient;
  isWorking?: boolean;
  classes?: Record<string, string>;
}

const ClientProjectsList = (props: Props) => {
  const classes = buildStyles();
  const otherClasses = props.classes || {};
  const {L, LSwap} = useLocalization();
  const { client } = props;
  const projects = useSelector((state: RootState) => selectClientProjects(state, client.id));
  const rows = React.useMemo(() => { return projects.map(prj => { return { ...prj, client: client }; }) }, [projects]);
  const { onOpenDialog } = useDialogs();

  return (
    <Grid id="client-projects-list" container justify="center" className={clsx(classes.root, otherClasses.container)}>
      <Paper className={clsx([classes.card, classes.root, otherClasses.paper])}>
        <Grid container>

          <Grid container className={clsx(classes.cardHeader, otherClasses.toolbar)} justify="space-between" alignItems="center">
            <Grid item xs={8} container justify="flex-start" alignItems="center">
              <Typography className={clsx(classes.cardHeaderText, otherClasses.title)}>{L("projects", true)}</Typography>
            </Grid>
            <Grid item xs={4} container justify="flex-end" alignItems="center">
              <Button size="small" color="secondary" onClick={onOpenDialog("project", "-1", {clientId: client.id})}>New</Button>
            </Grid>
          </Grid>

          <Grid container className={clsx(classes.cardBody, otherClasses.body)} spacing={1}>
            <Grid item xs={12} container className={classes.tableGrid}>
              <AtkTable
                rowId="id"
                header={rowHeader()}
                rows={rows}
                RowComponent={ClientProjectsListRow}
                dense={true}
                emptyMessage={LSwap("This client has no projects", ["client", "projects"])}
                settingKey="cli-prj-list"
                getSortValue={getSortValue}
                extra={{
                  classes: classes,
                  LSwap: LSwap,
                }} />
            </Grid>
          </Grid>

        </Grid>
      </Paper>      
    </Grid>
  );
}

export default ClientProjectsList;
