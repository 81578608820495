import React, { memo } from "react";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from '@material-ui/core/Tooltip';
import { formatCurrency } from "utils/number-helpers";
import { selectListStats } from "../infra/projects-selectors";
import { generalStyles, fontStyles } from "utils/styles";
import ChartPanel from "features/dashboard/chart-panel";
import { metricChartOptions } from "features/metrics/infra/metric-chart-helpers";
import { StatChartCard } from "components";

const buildStyles = makeStyles(theme => ({
  ...generalStyles(theme),
  ...fontStyles(theme),
  root: {
  },
  barContent: {
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`, //`
  },
  statCard: {
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1.5)}px`, //`
    height: theme.spacing(4.5),
  },
  statCardWithChart: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`, //`
    height: theme.spacing(10),
  },
  valueGrid: {
    height: theme.spacing(8),
    maxWidth: "25%",
  },
  chartGrid: {
    paddingLeft: theme.spacing(2),
    maxWidth: "75%",
  },
  dangerColor: {
    color: theme.palette.error.main,
  },
  clickable: {
    cursor: "pointer",
  }
}));

interface StatBarProps{
  onClick: (action: string) => void;
}

const ProjectsStatBar = ({onClick}: StatBarProps) => {
  const classes = buildStyles();
  const summary = useSelector(selectListStats);
  
  if(!summary) return null;

  return (
    <Grid id="project-stats-bar" container className={classes.root} spacing={3}>
      <Grid item md={3} sm={6} xs={12}>
        <StatChartCard stat={summary.assigned} onClick={onClick}/>
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <StatChartCard stat={summary.delivered} onClick={onClick}/>
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <StatChartCard stat={summary.paid} onClick={onClick}/>
      </Grid>
      
      <Grid item md={3} sm={6} xs={12} container direction="column" justify="space-between">
        <SimpleStatCard stat={summary.uninvoiced} classes={classes}  onClick={onClick}/>
        <SimpleStatCard stat={summary.unpaid} classes={classes}  onClick={onClick}/>
      </Grid>

    </Grid>
  );
}

export default memo(ProjectsStatBar)
ProjectsStatBar.displayName = "ProjectStatBar";

interface SimpleStat{
  label: string; 
  amount: number; 
  toolTip?: string, 
  color?: string
  action?: string;
}

interface StatWithChart extends SimpleStat{
  chartData?: any, 
  chartFormatter?: "currency" | "hours" | "count";
  chartType?: "bar" | "area";
}
interface StatWithChartProps {
  stat: StatWithChart | null;
  classes: ReturnType<typeof buildStyles>;
  onClick?: (action: string) => void;
}

const StatWithChartCard = (props: StatWithChartProps) => {
  const { stat, classes } = props;
  if (!stat) return null;
  const chartOptions = metricChartOptions(stat.chartFormatter || "currency", stat.color || "blue", stat.chartType || "area", undefined, undefined, {top: -20, bottom: 0, left: 0, right: 0});
  const pointerClass = stat.action ? classes.clickable : null;

  const statClick = () => {
    if(props.onClick && stat.action) props.onClick(stat.action);
  }

  return (
    <Paper  className={clsx(classes.statCardWithChart)} variant="outlined">
      <Grid container>
        <Tooltip title={stat.toolTip || stat.label}>
          <Grid item md container direction="column" className={clsx(classes.valueGrid, pointerClass)} justify="space-evenly" onClick={statClick}>
            <Typography className={classes.caption}>{stat.label}</Typography>
            <Typography className={clsx(classes.subTitle, classes.semiBold)} >{formatCurrency(stat.amount)}</Typography>
          </Grid>
        </Tooltip>
        <Grid item md container className={classes.chartGrid}>
          {stat.chartData && 
            <ChartPanel options={chartOptions} series={stat.chartData} type="area" height={75} minWidth={125}/>  
          }
        </Grid>
      </Grid>
    </Paper>
  )
};

memo(StatWithChartCard);
StatWithChartCard.displayName = "StatWithChartCard";


interface SimpleStatProps {
  stat: SimpleStat | null;
  classes: ReturnType<typeof buildStyles>;
  onClick?: (action: string) => void;
}

const SimpleStatCard = (props: SimpleStatProps) => {
  const { stat, classes } = props;
  if (!stat) return null;
  const colorClass = stat.color === "danger" ? classes.dangerColor : null;
  const pointerClass = stat.action ? classes.clickable : null;

  const statClick = () => {
    if(props.onClick && stat.action) props.onClick(stat.action);
  }

  return (
    <Tooltip title={stat.toolTip || stat.label}>
      <Paper  className={clsx(classes.statCard, pointerClass)} variant="outlined" onClick={statClick}>
        <Grid container justify="space-between" alignItems="center">
          <Typography className={classes.caption}>{stat.label}</Typography>
          <Typography className={clsx(classes.subTitle, classes.semiBold, colorClass)}>{formatCurrency(stat.amount)}</Typography>
        </Grid>
      </Paper>
    </Tooltip>
  )
};

memo(SimpleStatCard);
SimpleStatCard.displayName = "SimpleStatCard";