import React, { useMemo } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Icon from '@material-ui/core/Icon';
import { IconMenu } from "components";
import { IMenuItem, ProjectSettings } from "types";
import { useDialogs, useLocalization, useSliceSettings } from "utils/hooks";
import { generalStyles } from "utils/styles";
import { exportProjectsToCsv } from "utils/export-helper";
import { selectFilteredProjects } from "../infra/projects-selectors";
import { useSelector } from "react-redux";
import { Icons } from "utils/common-classes";

const buildStyles   = makeStyles(theme => ({
  ...generalStyles(theme),
  chipIcon: {
    height: 28,
    width: 28,
    // border: "1px solid rgba(0,0,0,0.23)",
    "& .MuiIcon": {
      fontSize: 22,
    }
  },
}));

const ProjectListMenu = () => {
  const classes   = buildStyles();
  const { LSwap } = useLocalization();
  const [settings, changeSettings] = useSliceSettings<ProjectSettings>("projects");
  const {openDialog} = useDialogs();
  const itemRows = useSelector(selectFilteredProjects);
  
  const configMenu: IMenuItem[] = useMemo(() => [
    { id: 10, label: "New Client", action: "new-client", icon: Icons.client("small") },
    { id: 20, label: "New Invoice", action: "new-invoice", icon: Icons.invoice("small") },
    { id: 23, label: "Add Multiple Projects", action: "new-projects", icon: Icons.project("small") },
    { id: 25, isDivider: true },
    { id: 0, label: "Toggle Dates / Checks", action: "toggle-dates", icon: Icons.toggle("small") },
    { id: 50, label: LSwap("Import Projects", "projects"), action: "import", icon: Icons.import("small") },
    { id: 60, label: LSwap("Export Projects", "projects"), action: "export", icon: Icons.export("small") },
  ], []);
  
  const onMenuItemClicked = (menuItem: IMenuItem) => {
    switch (menuItem.action) {
      case "toggle-dates": changeSettings({ showDates: !settings.showDates }); break;
      case "import": openDialog("import", "projects"); break;
      case "new-invoice": openDialog("invoice"); break;
      case "new-client": openDialog("client"); break;
      case "new-projects": openDialog("projectMulti"); break;
      case "export": exportProjectsToCsv(itemRows); break;
    }
  };

  return (
    <>
      <IconMenu icon={<Icon>more_vert</Icon>} items={configMenu} title="More actions" onClick={onMenuItemClicked} classes={{button: clsx(classes.chipIcon, classes.marginLeft)}} />
    </>
  );
}

export default ProjectListMenu;
