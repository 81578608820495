import { differenceInCalendarDays, isBefore, isSameMonth, isWithinInterval, startOfDay, startOfMonth } from "date-fns";
import { metricChartOptions, metricDonutOptions } from "./metric-chart-helpers";
import { calcFee } from "features/projects";
import { compact, groupBy, keys, orderBy, sumBy, uniq } from "lodash";
import { IProject, DateRange, IProjectSummary } from "types";
import { isInRange } from "utils/date-helpers";
import { formatCurrency } from "utils/number-helpers";
import { defaultRateMonth, getActiveProjects, getProjectsWithHourlyRate, UNCATEGORIZED } from "./metric-calcs-common";

export const ProjectMetrics = [
  {
    id: "projects-count",
    category: "projects",
    period: ["year"],
    label: "Total",
    description: "The total number of projects you've worked on",
    why: "",
  },
  {
    id: "projects-active",
    category: "projects",
    period: ["year", "month"],
    label: "Active",
    description: "The total number of projects you've worked with ~period~",
    why: "",
  },
  {
    id: "projects-avg-length",
    category: "projects",
    period: ["year"],
    label: "Average Length",
    description: "The average length (in days) of projects you've worked on ~period~",
    why: "",
  },
  {
    id: "projects-avg-revenue",
    category: "projects",
    period: ["year"],
    label: "Average Value",
    description: "The average value of projects you've worked on ~period~",
    why: "",
  },
  {
    id: "projects-avg-rate",
    category: "projects",
    period: ["year"],
    label: "Average Hourly Rate",
    description: "The average rate across projects with tracked time ~period~.  This does not take into account the length of each project, so will probably be different from the overall rate in the Revenue category.",
    why: "",
  },
  {
    id: "projects-mean-rate",
    category: "projects",
    period: ["year"],
    label: "Mean Hourly Rate",
    description: "The mean rate across projects with tracked time ~period~.  Mean is the mid-point value, if this number differs drastically from the average, it is likely because the individual values are skewed one direction or the other.",
    why: "",
  },
  {
    id: "projects-category-count",
    category: "projects",
    period: ["year"],
    label: "Project Categories",
    description: "The number of different categories, or types of projects you worked on ~period~",
    why: "",
  },
  {
    id: "projects-word-count",
    category: "projects",
    period: ["year", "quarter", "month", "week"],
    label: "Total Words",
    description: "The total number of (assigned) words you've delivered ~period~",
    why: "",
  },
  {
    id: "projects-bycategory-rate",
    category: "projects",
    period: ["year"],
    label: "Rate by Project Category",
    description: "Your hourly rate by project category ~period~",
    why: "",
  }
];

export const calcProjectCount = (projects: IProject[], monthEnds: Date[]) => {
  const value = projects.length;

  let total = 0;
  const chartData = monthEnds.map(endDate => {
    const start = startOfMonth(endDate);
    const monthProjects = getActiveProjects<IProject>({start: start, end: endDate}, projects);
    total += monthProjects.length;
    return [startOfDay(endDate), total];
  });

  return {
    value,
    displayValue: value.toString(),
    chartData: [{
      name: "Total Projects",
      type: "area",
      data: chartData,
    }],
    chartOptions: metricChartOptions("count", "default"),
    chartType: "area",
  }  
}

export const calcActiveProjects = (range: DateRange, projects: IProject[], monthEnds: Date[]) => {
  const matches = getActiveProjects<IProject>(range, projects);
  const value = matches.length;

  const chartData = monthEnds.map(endDate => {
    const start = startOfMonth(endDate);
    const prjMatches = getActiveProjects<IProject>({start: start, end: endDate}, projects);
    return [startOfDay(endDate), prjMatches.length];
  });

  return {
    value,
    displayValue: value.toString(),
    chartData: [{
      name: "Active Projects",
      type: "area",
      data: chartData,
    }],
    chartOptions: metricChartOptions("count", "default"),
    chartType: "area",
  }
}

export const calcProjectLength = (range: DateRange, projects: IProject[]) => {
  //all projects delivered in this period
  const matches = projects.filter(prj => prj.deliveredDate && (isInRange(prj.startDate, range) || isInRange(prj.deliveredDate, range)));
  const lengths = matches.map(prj => differenceInCalendarDays(prj.deliveredDate as Date, prj.startDate as Date));
  const value = sumBy(lengths, m => m) / (lengths.length || 1);

  lengths.sort((a,b) => a - b);
  const min = lengths[0] || 0;
  const max = lengths[lengths.length - 1] || 0;
  const mean = lengths[Math.floor(lengths.length / 2)] || 0;

  return {
    value,
    displayValue: `${value.toFixed(1)} days`,
    stats: [
      {id: "min", label: "Min", value: min, displayValue: `${min.toString()}d` },
      {id: "max", label: "Max", value: min, displayValue: `${max.toString()}d` },
      {id: "mean", label: "Mean", value: min, displayValue: `${mean.toString()}d` },      
    ],
  }  
}

export const calcProjectRevenue = (range: DateRange, projects: IProject[]) => {
  //all projects started or delivered or overlapping this period
  const matches = projects.filter(prj => isInRange(prj.startDate, range) || isInRange(prj.deliveredDate, range) || (isBefore(prj.startDate as Date, range.start) && !prj.deliveredDate));
  const fees = matches.map(prj => calcFee(prj));
  const value = sumBy(fees, m => m) / (fees.length || 1);

  fees.sort((a,b) => a - b);
  const min = fees.find(f => f !== 0);  //first non-zero value
  const max = fees[fees.length - 1];
  const mean = fees[Math.floor(fees.length / 2)]

  return {
    value,
    displayValue: formatCurrency(value),
    stats: [
      {id: "min", label: "Min", value: min, displayValue: formatCurrency(min) },
      {id: "max", label: "Max", value: min, displayValue: formatCurrency(max) },
      {id: "mean", label: "Mean", value: min, displayValue: formatCurrency(mean) },      
    ],
  }  
}

export const calcProjectAvgRate = (range: DateRange, projects: IProject[], monthEnds: Date[]) => {
  //all delivered projects projects that are active within the range
  const hourlyProjects = projects.filter(prj => prj.trackedMinutes && prj.deliveredDate);
  const matches = getActiveProjects<IProject>(range, hourlyProjects);
  const rates = matches.map(prj => calcFee(prj) / ((prj.trackedMinutes || 1) / 60));
  const value = sumBy(rates, m => m) / (rates.length || 1);

  const months: {date: Date; minutes: number; amount: number}[] = [];
  for(let i = 0; i < matches.length; i++){
    const prj = matches[i];
    const fee = calcFee(prj);
    //For chart...
    const ddate = prj.deliveredDate as Date;
    let month = months.find(m => isSameMonth(m.date, ddate)) || null;
    if(!month){
      month = {...defaultRateMonth(ddate)};
      months.push(month);
    }
    month.minutes += prj.trackedMinutes || 0;
    month.amount += fee;
  }

  const chartData = monthEnds.map(endDate => {
    const month = months.find(m => isSameMonth(m.date, endDate)) || {...defaultRateMonth(endDate)};
    const average = month.minutes === 0 ? 0 : month.amount / (month.minutes / 60);
    return [startOfDay(endDate), average];
  });

  const chartOptions = metricChartOptions("currency", "delivered", "area");
  return {
    value,
    displayValue: `${formatCurrency(value)} / hr`,
    chartData: [{
      name: "hourly rate",
      type: "area",
      data: chartData,
    }],
    chartOptions,
    chartType: "area",
  }  
}

export const calcProjectMeanRate = (range: DateRange, projects: IProject[]) => {
  //all projects started or delivered or overlapping this period
  const hourlyProjects = projects.filter(prj => prj.trackedMinutes && prj.deliveredDate);
  const matches = hourlyProjects.filter(prj => isInRange(prj.startDate, range) || isInRange(prj.deliveredDate, range));
  //only projects that are completed
  const rates = matches.map(prj => calcFee(prj) / ((prj.trackedMinutes || 1) / 60));
  rates.sort((a,b) => a - b);
  const value = rates[Math.floor(rates.length / 2)];

  const min = rates.find(r => r !== 0);  //first non-zero value
  const max = rates[rates.length - 1];

  return {
    value,
    displayValue: `${formatCurrency(value)} / hr`,
    stats: [
      {id: "min", label: "Min", value: min, displayValue: `${formatCurrency(min)} / hr` },
      {id: "max", label: "Max", value: min, displayValue: `${formatCurrency(max)} / hr` },
    ],
  }  
}

//TODO: switch chart to a line graph with cumulative lines showing the # of projects.
// this will track trends in assignments by category.
//"projects-category-count"
export const calcProjectCategories = (range: DateRange, projects: IProject[]) => {
  const activeProjects = getActiveProjects<IProject>(range, projects);
  let uncategorized = activeProjects.length;
  const matches = uniq(compact(activeProjects.map(c => c.category?.toLowerCase())));
  const chartData = matches.map(category => {
    const cliMatches = activeProjects.filter(c => c.category?.toLowerCase() === category);
    const count = cliMatches.length;
    uncategorized -= count;
    return count;
  });
  matches.push("Uncategorized");
  chartData.push(uncategorized);

  const value = matches.length;
  const result = {
    value,
    displayValue: value.toString(),
    chartData: chartData,
    chartOptions: metricDonutOptions(matches),
    chartType: "donut",
  };

  return result;
};

//"projects-word-count"
export const calcTotalWords = (range: DateRange, projects: IProject[], monthEnds: Date[]) => {
  const matches = getActiveProjects<IProject>(range, projects).filter(p => p.deliveredDate).filter(p => p.wordCount || (p.type === "perWord" && p.units));
  const value = sumBy(matches, m => m.type === "perWord" ? m.units as number : m.wordCount as number);

  const chartData = monthEnds.map(endDate => {
    const start = startOfMonth(endDate);
    const monthlyMatches = matches.filter(m => isWithinInterval(m.deliveredDate as Date, {start: start, end: endDate}));
    const monthlyTotal = sumBy(monthlyMatches, m => m.type === "perWord" ? m.units as number : m.wordCount as number);
    return [startOfDay(endDate), monthlyTotal];
  });

  const result = {
    value,
    displayValue: value.toLocaleString(),    
    chartData: [{
      name: "Total Words",
      type: "bar",
      data: chartData,
    }],
    chartOptions: metricChartOptions("count", "default"),
    chartType: "bar",
  };

  return result;
}

//"projects-bycategory-rate"
export const calcRateByProjectCategory = (range: DateRange, projects: IProject[], hours: IProjectSummary[]) => {
  if(!hours || hours.length === 0) return { value: -999, displayValue: "n/a" };
  //all delivered projects projects that are active within the range
  const hourlyProjects = projects.filter(prj => prj.trackedMinutes && prj.deliveredDate);  
  const matches = getActiveProjects<IProject>(range, hourlyProjects);
  if(!matches || matches.length === 0){
    return {
      value: -998,
      displayValue: "No projects to evaluate",
    };
  }
  //Group the projects by category
  const projectCategories = groupBy(matches, m => m.category || UNCATEGORIZED);
  //for each group, calculate the average rate
  const categoryRates = orderBy(keys(projectCategories).map(category => {
    //get the projects for that category
    const catProjects = projectCategories[category];
    //Calculate the rate for each project...
    const rates = getProjectsWithHourlyRate(catProjects, hours);
    // const rates = compact(catProjects.map(prj => {
    //   const minutes = hours.find(h => h.id === prj.id)?.minutes || 0;
    //   if(!minutes) return null;
    //   const fee = calcFee(prj);
    //   const rate = parseFloat((fee / (minutes / 60)).toFixed(2));
    //   return rate;
    // }));
    const avg = rates.length ? sumBy(rates, r => r[1]) / rates.length : 0;
    return [category, avg] as [string, number];
  }), [pc => pc[1]], ["desc"]);

  const value = categoryRates[0][1];
  const sValue = categoryRates[0][0];

  const categories = categoryRates.map(cr => cr[0]);
  const rates = categoryRates.map(cr => cr[1]);

  const result = {
    value,
    displayValue: `${formatCurrency(value)} / hr`,
    subValue: sValue,
    displaySubValue: sValue,
    chartData: [{
      name: "Rate",
      type: "bar",
      data: rates,
    }],
    chartOptions: metricChartOptions("currency", "default", "bar", undefined, categories),
    chartType: "bar",
  };

  return result;
}