 import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Button from '@material-ui/core/Button';
// import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
// import { RootState } from "types";
import { fontStyles, formStyles, generalStyles } from "utils/styles";
// import { useDispatch, useSelector } from "react-redux";
// import { LoadingIndicator } from "components";
// import { EVENTS, trackEvent } from "utils/mixpanel";
// import { createSampleData } from "./infra/onboarding-actions";
import { IStepProps, OnboardingScreens } from "./onboarding-view";
// import { useHistory } from "react-router-dom";
// import { getRoute, routes } from "features/app";

const buildStyles   = makeStyles(theme => ({
  ...generalStyles(theme),
  ...formStyles(theme),
  ...fontStyles(theme),
  root  : {
    // padding     : theme.spacing(1),
    marginBottom: theme.spacing(2),
    height: "100%",
  },
  emphasis: {
    fontSize: 18,
    fontWeight: 500,
    textAlign: "center",
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(1),
  },
  body: {
    fontSize: 16,
    fontWeight: 400,
    textAlign: "center",
  },
  cardBody: {
    height: `calc(100% - ${theme.spacing(13)}px)`,
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    }
  },
  leftCol: {
    margin: `${theme.spacing(2)}px ${theme.spacing(0)}px`, //`
    height: `calc(100% - ${theme.spacing(4)}px)`,
    borderRight: `1px solid ${theme.palette.grey[300]}`, //`
    [theme.breakpoints.down("xs")]: {
      height: "auto",
      borderRight: "none",
      borderBottom: `1px solid ${theme.palette.grey[300]}`, //`
    }
  },
  rightCol: {
    margin: `${theme.spacing(2)}px ${theme.spacing(0)}px`, //`
    height: `calc(100% - ${theme.spacing(4)}px)`,
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    }
  },
  content: {
    padding: `${theme.spacing(3)}px ${theme.spacing(1)}px`, //`
  },
  radioButton: {
    paddingRight: theme.spacing(1.5),
    border: `1px solid ${theme.palette.primary.main}`, //`
    borderRadius: 5,
    minWidth: "50%",
    "& span": {
      textTransform: "uppercase",
      color: theme.palette.primary.main,
      fontWeight: 600,
    }
  }
}));


const OnboardingChoice = ({onNavStateChange}: IStepProps) => {
  const classes   = buildStyles();
  // const dispatch = useDispatch();
  const [opt, setOpt] = useState(0);
  // const isOnboarding = useSelector((state: RootState) => state.app.isOnboarding);
  // const [message, setMessage] = useState("Generating data...");
  // const [isFinished, setIsFinished] = useState(false);

  useEffect(() => {
    onNavStateChange({canPrevious: true, canNext: false, previousTitle: "Previous", nextTitle: "Next", nextOverride: null, prevOverride: OnboardingScreens.profile, hideNext: false, hidePrevious: false});
  }, []);

  const onChangeOpt = (opt: number) => () => {
    setOpt(opt);
    onNavStateChange({canPrevious: true, canNext: true, previousTitle: "Previous", nextTitle: "Next", nextOverride: opt === 2 ? OnboardingScreens.clients : OnboardingScreens.generate});
  }

  return (
    <Grid item container direction="column" className={classes.root}>
      <Grid container direction="column" alignItems="center" className={clsx(classes.mbl, classes.mtl)}>
        <Typography color="primary" className={classes.pageHead}>Dip a toe, or dive right in</Typography>
        <Typography color="secondary" className={classes.subTitle}>How would you like to get started?</Typography>
      </Grid>

      <Grid container className={classes.cardBody}>        
        <Grid item sm={6} xs={12} container className={classes.leftCol}>
          <Grid container direction="column" justify="space-between" alignItems="center" className={classes.content}>
            <Typography variant="h3" className={classes.emphasis}>Dip a toe</Typography>
            <Typography variant="h3" className={clsx(classes.body, classes.mtl, classes.mbl, classes.marginX)}>{"Use sample data to try out GigOps with one click, and no risk"}</Typography>
            <FormControlLabel name="sample-type" color="primary" value={1} control={<Radio checked={opt === 1} />} onChange={onChangeOpt(1)} label="Use Sample Data" className={classes.radioButton} />
          </Grid>
        </Grid>
        
        <Grid item sm={6} xs={12} container className={classes.rightCol}>
          <Grid container direction="column" justify="space-between" alignItems="center" className={classes.content}>
            <Typography variant="h3" className={classes.emphasis}>Dive in</Typography>
            <Typography variant="h3" className={clsx(classes.body, classes.mtl, classes.mbl, classes.marginX)}>{"Add your own data to get started right away"}</Typography>
            <FormControlLabel name="sample-type" color="primary" value={2} control={<Radio  checked={opt === 2}/>} onChange={onChangeOpt(2)} label="Use My Data" className={classes.radioButton} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default OnboardingChoice;
