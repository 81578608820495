import React from "react";
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';

interface CellProps{
  isSelected?: boolean;
  labelId: string;
  disabled?: boolean;
}

const TableCheckCell = ({isSelected, disabled, labelId}: CellProps) => {
  return (
    <TableCell padding="checkbox">
      <Checkbox checked={isSelected} inputProps={{ 'aria-labelledby': labelId }} disabled={disabled} />
    </TableCell>
  );
}

export default TableCheckCell;
