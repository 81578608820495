// import { IParser } from '../index';

export interface IInputDisplayValues {
  [key: string]: string | boolean;
}

export interface IInputError {
  error: boolean;
  helperText?: string;
}

export interface IInputErrors {
  [key: string]: IInputError;
}

export interface IInputsBinding {
  onChange: React.ChangeEventHandler<any>;
  onBlur: React.FocusEventHandler<any>;
}

export interface IParser<T> {
  name?: string;
  errorMessage?: string;
  parse: (v: string, isFinal?: boolean | undefined) => T;
  format: (v: T) => string;
}

export interface IParserCollection {
  [key: string]: IParser<any>;
}

export enum UpdateValueEvent {
  onBlur = "onBlur",    //update the value when the input loses focus
  onChange = "onChange"   //update the value when the input is fired (immediately)
}

export type UpdateValueEventStrings = keyof typeof UpdateValueEvent;

export interface IInputOptions {
  updateOn?: UpdateValueEventStrings | UpdateValueEvent;    //indicates the value should be updated with each change
  triggers?: Array<{ [key: string]: UpdateValueEvent }>;    //a field-by-field determination of triggers...
}