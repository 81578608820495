import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { IGoal, IGoalVariable } from "../infra/goal-types";
import { useClients, useInputs } from "utils/hooks";
import { isUndefined } from "lodash";
import { selectClientCategories } from "features/clients/infra/client-selectors";
import { selectProjectCategories } from "features/projects";

const buildStyles   = makeStyles(theme => ({
  variableRoot  : {
    paddinsg     : theme.spacing(1),
  },
  title   : {
    fontSize    : 22,
    fontWeight  : 500,
    textAlign   : "center",
    marginBottom: theme.spacing(1),
  },
  label: {
    fontSize: 18,
    fontWeight: 400,
  },
  goalAmount: {
    "& input": {
      fontSize: 22,
      fontWeight: 600,
      textAlign: "center",
    },
    "& .MuiSelect-select": {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '1.6em',
    }
  },
  amountGrid: {
    position: "relative",
    padding: `${theme.spacing(0)}px ${theme.spacing(1)}px`, //`
    [theme.breakpoints.down("sm")]: {
      padding: `${theme.spacing(0)}px ${theme.spacing(3)}px`, //`
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(1),
    }
  },
  filterGrid: {
    position: "relative",
    // padding: `${theme.spacing(0)}px ${theme.spacing(1)}px`, //`
    [theme.breakpoints.down("sm")]: {
      padding: `${theme.spacing(0)}px ${theme.spacing(1)}px`, //`
    },
    [theme.breakpoints.down("xs")]: {
      padding: `${theme.spacing(0)}px ${theme.spacing(3)}px`, //`
      marginBottom: theme.spacing(1),
    }
  },
  adjustBtnLeft: {
    backgroundColor: theme.palette.common.white,
    zIndex: 2,
    position: "absolute",
    top: "30%",  
    left: "-10px",
    [theme.breakpoints.down("sm")]: {
      left: "10px",
    }  
  },
  adjustBtnRight: {
    backgroundColor: theme.palette.common.white,
    zIndex: 2,
    position: "absolute",
    top: "30%",  
    right: "-10px",  
    [theme.breakpoints.down("sm")]: {
      right: "10px",
    }  
  }
}));

interface ControlProps{
  item: IGoalVariable;
  goal?: IGoal | null;
  onChange: (value: any, display?: string) => void;
}

interface IValue{
  amount?: number;
  filter?: string;
}

const GoalVariable = (props: ControlProps) => {
  switch(props.item.id){
    case "amount":
    case "count":
      return <NumericGoalVariable {...props} />;
    
    case "filter":
      if(!!props.item.operation) return null;   //this isn't a user-provided value
      else return <FilterGoalVariable {...props} />;

    default: return null;
  }
}

export default GoalVariable;

// Control for a numeric variable
const NumericGoalVariable = ({item, goal, onChange}: ControlProps) => {
  const classes   = buildStyles();
  const prepared = useMemo(() => {
    const result = goal ? 
      {amount: goal[item.id] } :
      {amount: item.defaultValue || 0}
    return result;
  }, [item, goal]);
  const [values, display, errors, binding, setValues] = useInputs<IValue>(prepared, {amount: item.parser});

  useEffect(() => {
    onChange(values.amount);
  }, [values])

  const onInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.select();
  }

  const adjust = (amount: number) => () => {
    const next = (values.amount || 0) + amount;
    const actualMin = isUndefined(item.min) ? 0 : item.min; //default to a min of 0
    const okNext = (next < actualMin) ? actualMin : ((!isUndefined(item.max) && next > item.max) ? item.max :  next);
    setValues({...values, amount: okNext});
  }

  return (
    <Grid item md={5} sm={6} xs={12} className={classes.variableRoot}>      
      <Grid container direction="column" alignItems="center" spacing={1}>
        <Grid container alignItems="center" justify="center">
          <Typography className={classes.label}>{item.label}</Typography>
        </Grid>
        <Grid container className={classes.amountGrid} alignItems="center">
          <IconButton size="small" onClick={adjust((item.increment || 1) * -1)} className={classes.adjustBtnLeft}><RemoveCircleOutlineIcon /></IconButton>
          <TextField name="amount" value={display?.amount} variant="outlined" margin="dense" fullWidth className={classes.goalAmount} onFocus={onInputFocus} {...binding.input} />
          <IconButton size="small" onClick={adjust(item.increment || 1)} className={classes.adjustBtnRight}><AddCircleOutlineIcon /></IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
}

const FilterGoalVariable = ({item, goal, onChange}: ControlProps) => {
  const classes   = buildStyles();
  const clients = useClients();
  const clientCategories = useSelector(selectClientCategories);
  const projectCategories = useSelector(selectProjectCategories);

  // const prepared = useMemo(() => { return {filter: ""}}, [item]);
  const prepared = useMemo(() => {
    const result = goal ? 
      {filter: goal.filter } :
      {filter: ""}
    return result;
  }, [item, goal]);
  const [values, display, errors, binding] = useInputs<IValue>(prepared, {amount: item.parser});

  const filterItems = useMemo(() => {
    switch(item.type){
      case "client":
        if(item.prop === "name") return clients.map(c => { return {id: c.id, label: c.name}; }); //TODO: client names
        else if(item.prop === "category") return clientCategories;
        else return [];   //TODO: all clients
      case "project":
        if(item.prop === "name") return [];   //TODO: project names?
        else if(item.prop === "category") return projectCategories;
        else return [];   //TODO: all projects
      default: return [];
    }
  }, [item.type]);

  useEffect(() => {
    const item = filterItems.find(i => i.id === values.filter);
    onChange(item?.id || "", item?.label || "");
  }, [values]);

  return (
    <Grid item md={5} sm={6} xs={12}  className={classes.variableRoot}>      
      <Grid container direction="column" alignItems="center" spacing={1}>
        <Grid container alignItems="center" justify="center">
          <Typography className={classes.label}>{item.label}</Typography>
        </Grid>
        <Grid container className={classes.filterGrid} alignItems="center">
          <TextField name="filter" value={values?.filter} variant="outlined" className={classes.goalAmount} select {...binding.selectContainer("filter", filterItems, "label")} {...binding.select("filter")} fullWidth margin="dense">
            {filterItems.map(item => <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>)}
          </TextField>
        </Grid>
      </Grid>      
    </Grid>
  );
}

