import { IClient, IProject, IAlert, ClientFilter, IClientWithProjects } from "types";
import { IParserCollection, Parsers } from "utils/hooks";
import { subMonths } from "date-fns";

export const clientParsers: IParserCollection = {
  defaultRate: Parsers.currency,
  daysToPay: Parsers.int,
}

export const ClientRatingsParsers : IParserCollection = {
  joy: Parsers.int,
  communication: Parsers.int,
  network: Parsers.int,
  growth: Parsers.int,
  quality: Parsers.int,
};

export const ClientGoalsParsers : IParserCollection = {
  monthlyRevenue: Parsers.currencyInt,
  annualRevenue: Parsers.currencyInt,
}

export function getClientAlerts(client: IClient, projects: IProject[]): IAlert[] {
  console.log("TODO: implement client alerts", client, projects.length);
  const items: IAlert[] = [];

  return items;
}

const ACTIVE_MONTHS = 6;  //Threshold for when a client is considered active / inactive - if they have a project within the last x months

export const hasActiveProject = (projects: IProject[], threshold?: Date) => {
  const startDate = threshold || subMonths(new Date(), ACTIVE_MONTHS);
  const first = projects.find(prj => prj.startDate >= startDate || (prj.dueDate && prj.dueDate >= startDate) || (prj.deliveredDate && prj.deliveredDate >= startDate));
  return !!first;
}

//Filteres clients by the client filter options
export const filterClients = (clients: IClientWithProjects[], filter: ClientFilter) => {
  if(filter === "active"){
    const threshold = subMonths(new Date(), ACTIVE_MONTHS);
    const filtered = clients.filter(cli => {
      return cli.projects && hasActiveProject(cli.projects, threshold);
    });
    return filtered;
  }
  else if(filter === "archived"){
    return clients.filter(cli => cli.isArchived === true);
  }

  //Fall-through
  return clients.filter(cli => !cli.isArchived);
}