import React from "react";
import { useParams, Link, Route } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';
import ProjectEditor from "./project-edit-card";
import { LoadingIndicator } from "components";
import { useProjectSafe } from "utils/hooks";
import { IconNames } from "utils/common-classes";
// import { InvoicesListCard } from "features/invoices";
import { getRoute, routes } from "features/app";
import { Helmet } from "react-helmet-async";
import { generalStyles } from "utils/styles/common-styles";

const buildStyles = makeStyles(theme => ({
  ...generalStyles(theme),
  root: {
    padding: theme.spacing(1),
  },
  title: {
    fontSize: 22,
    fontWeight: 500,
    textAlign: "center",
    marginRight: theme.spacing(1),
  },
  titleIcon: {
    marginRight: theme.spacing(1),
  },
  backIcon: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0.5),
  },
  clientTitle: {
    fontSize: 20,
    fontWeight: 400,
    textAlign: "center",
  },
  clientLink: {
    textDecoration: "none",
    color: theme.palette.action.disabled
  }
}));

const ProjectEditView = () => {
  const classes = buildStyles();
  const { projectId } = useParams<{ projectId: string }>();
  const project = useProjectSafe(projectId);

  return (
    <Grid id="project-edit-view" container justify="center" alignItems="center" className={classes.root}>
      <Helmet>
        <title>Gigops - {project.name} (edit)</title>
      </Helmet>
      
      <Route path={routes.projectEdit} exact>
        <Grid container justify="space-between" alignItems="center">
          <Grid item xs={12} container alignItems="center">
            <Tooltip title="back to Projects">
              <Link to={getRoute("project", projectId)} replace={true}><Icon className={classes.backIcon} color="primary">keyboard_backspace</Icon></Link>
            </Tooltip>  
            <Tooltip title="Go to this client">
              <Link to={getRoute("client", project?.clientId)} className={classes.clientLink}>
                <Grid container>
                  <Icon color="disabled" className={classes.titleIcon}>{IconNames.client}</Icon>
                  <Typography color="textSecondary" className={classes.clientTitle}>{project?.client?.name}</Typography>
                </Grid>
              </Link>
            </Tooltip>        
            <Icon color="disabled" className={classes.titleIcon}>chevron_right</Icon>
            <Icon color="primary" className={classes.titleIcon}>{IconNames.project}</Icon>
            <Typography variant="h5" color="primary" className={classes.title}>{project?.name}</Typography>
          </Grid>
        </Grid>
      </Route>

      <Grid container spacing={2}>
        <LoadingIndicator isVisible={!project} />

        <Grid item xs={12}>
          {project && <ProjectEditor />}
        </Grid>

        {/* <Grid item md={8} sm={12}>
          <InvoicesListCard projectId={project?.id} queryKey={`prj-${project?.id}`}/>
        </Grid> */}
        
      </Grid>
    </Grid>
  );
}

export default ProjectEditView;