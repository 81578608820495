import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
// import CircularProgress from '@material-ui/core/CircularProgress';

interface IProps {
  isVisible?: boolean;
  message?: string;
  size?: string;
  variant?: "default" | "circular";
  className?: string;
  otherProps?: any;
}

//-------------------------
// Styles for the Loading Indicator
const buildStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: "1 1 0%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  message: {
    fontSize: "16px",
    marginBottom: theme.spacing(2),
  },
  bar: {
    minWidth: "125px",
  },
  circle: {

  }
}));

const LoadingIndicator = (props: IProps) => {
  const classes = buildStyles();
  const { isVisible, message, size, className, ...otherProps } = props;

  if (!isVisible) return null;
  const barClasses = size ? size : "w-1/2";
  // const progress      = (variant === "circular") ? <CircularProgress className={classes.circle}/> : <LinearProgress className={clsx(classes.bar, barClasses)} />;

  const loadingMessage = message || "Loading...";

  return (
    <div className={clsx(classes?.root, className)} {...otherProps}>
      <span className={classes?.message}>{loadingMessage}</span>
      {/* {progress} */}
      <LinearProgress className={clsx(classes.bar, barClasses)} />
    </div>
  );
}


export default LoadingIndicator;