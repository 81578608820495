import _, { compact, orderBy, pick, sumBy, values } from "lodash";
import { IClientWithProjects, RootState } from "types";
import { selectAllClients, selectAllInvoices, selectAllProjects, selectProjectsWithAllInvoices } from 'features/app/infra/common-selectors'
import { createSelector } from "@reduxjs/toolkit";
import { calcProjectRate, selectProjectsByClient, sumProjects } from "features/projects";
import { filterClients } from "../client-helpers";
import { isSameMonth, isSameYear } from "date-fns";
import { formatCurrency } from "utils/number-helpers";

const _getSettings = (state: RootState) => state.clients.settings;
const _getId = (state: RootState, id: string) => id;
const _getInput = (state: RootState, input: any) => input;
// const _getFilter = (state: RootState) => state.clients.settings.filter;
// const _getSearch = (state: RootState) => state.clients.settings.search;

//==
// Selects each client with a string representation, used for searching
const selectClientStrings = createSelector(
  selectAllClients,
  (clients) => {
    if(!clients) return [];
    const searchStrings = clients.map(client => {
      const cliString = values(pick(client, ["name", "category", "notes", "contactName", "contactEmail", "contactPhone"])).join(" ");
      return [client.id, cliString.toLowerCase()];      
    });

    return searchStrings;
  }
);

//==
// Select all clients with their projects included
export const selectClientsWithProjects = createSelector(
  selectAllClients, selectAllProjects,
  (clients, projects): IClientWithProjects[] => {
    if(!clients) return [];
    // if(!projects) return clients;

    const withProjects = clients.map(client => {
      const cliProjects = orderBy(projects.filter(prj => prj.clientId === client.id), [prj => prj.dueDate || prj.startDate], ["desc"]);
      return {
        ...client,
        projects: cliProjects,
      } as IClientWithProjects;
    });

    return withProjects;
  }
);

//==
// Select all clients based on the filter / search / categoryFilter options in the client slice settings
export const selectFilteredClients = createSelector(
  selectClientsWithProjects, selectClientStrings, _getSettings,
  (clients, clientStrings, settings) => {
    if (!clients) return [];
    
    if(settings.search){
      const searchTerm = settings.search.toLowerCase();
      const matches = clientStrings.filter(cs => cs[1].indexOf(searchTerm) >= 0);
      const searched = compact(matches.map(m => clients.find(c => c.id === m[0]))); // clients.filter(p => p.name.toLowerCase().indexOf(searchTerm) >= 0 || p.clientName?.toLowerCase().indexOf(searchTerm) >= 0 || (p.notes ?? "").toLowerCase().indexOf(searchTerm) >= 0);
      return searched.filter(s => !s.isArchived);
    }
    else if(settings.categoryFilter){
      const filtered = clients.filter(c => c.category === settings.categoryFilter);
      return filtered.filter(s => !s.isArchived);
    }
    else if(settings.filter){
      const filtered = filterClients(clients, settings.filter);
      return filtered;
    }
    else{
      return clients;
    }
  }
);

//Calculates the average project rate for all clients, as well as the best and worst projects
// from a rate perspective.
export const selectClientRates = createSelector(
  selectAllClients, selectAllProjects,
  (clients, projects) => {
    if(!clients || !projects) return [];

    const clientRates = clients.map(client => {
      const cliProjects = projects.filter(prj => prj.clientId === client.id);
      const projectRates = _.orderBy(cliProjects.map(prj => {
        return {
          id: prj.id,
          name: prj.name,
          rate: calcProjectRate(prj),
          isDelivered: Boolean(prj.deliveredDate),
        };
      })
      .filter(pr => pr.rate !== 0 && pr.isDelivered === true), //only include projects with rates that are delivered
      ["rate"], ["desc"]);

      if(projectRates.length > 0){
        const avg = _.sumBy(projectRates, prj => prj.rate) / projectRates.length;
        return {
          clientId: client.id,
          clientName: client.name,
          average: avg,
          count: projectRates.length,
          top: projectRates[0],
          bottom: projectRates[projectRates.length - 1],
        };
      }
      else{
        return {
          clientId: client.id,
          clientName: client.name,
          average: 0,
          count: 0,
          top: null,
          bottom: null,
        };
      }
    });

    const ordered = _.orderBy(clientRates.filter(cr => cr.average !== 0), ["average"], ["desc"]);
    let rank = 1;
    const ranked = ordered.map(cr => ({...cr, rank: rank++}));
    return ranked;
  }
);

//Gets the list of client categories, derived from the clients.
export const selectClientCategories = createSelector(
  selectAllClients,
  (clients) => {
    if(!clients) return [];
    const categories = clients.map(c => c.category);
    const unique = _.uniq(categories);
    const sorted = _.compact(unique).sort();
    return sorted.length > 0 ? sorted.map(s => {return {id: s, label: s}; }) : [];
  }
);

//Gets the Life Time Value (LTV) of a client based on Paid Invoices
export const selectClientLtv = createSelector(
  _getId, selectAllInvoices,
  (id, invoices) => {
    if(!invoices || invoices.length === 0) return 0;
    const clientInvoices = invoices.filter(inv => inv.clientId === id && inv.paidDate);
    const total = sumBy(clientInvoices, ci => ci.amount);
    return total;
  }
);

//Gets the current month data for a client based on Paid Invoices
export const selectClientMonth = createSelector(
  _getId, selectAllProjects, selectAllInvoices,
  (id, projects, invoices) => {
    if(!invoices || invoices.length === 0 || !projects || projects.length === 0) return 0;

    const today = new Date();
    const cliInvoices = invoices.filter(inv => inv.clientId === id && isSameMonth(inv.invoiceDate as Date, today));
    // const cliProjects = projects.filter(prj => prj.clientId === id);

    // const 

    const total = sumBy(cliInvoices, ci => ci.amount);
    return total;
  }
);

export const selectClientViewStats = createSelector(
  _getInput, selectAllInvoices,
  (clientId, invoices) => {
    if (!clientId || !invoices) return null;
    
    const today = new Date();
    const invoiced = invoices.filter(inv => inv.clientId === clientId);
    const paid = invoiced.filter(inv => Boolean(inv.paidDate));
    // const cliProjects = projects.filter(prj => prj.clientId === clientId && (prj.deliveredDate || prj.type === "perHour"));

    const result = {
      paid: {
        allTime: sumBy(paid, pi => pi.amount),
        month: sumBy(paid.filter(p => isSameMonth(p.paidDate as Date, today)), inv => inv.amount),
        year: sumBy(paid.filter(p => isSameYear(p.paidDate as Date, today)), inv => inv.amount),
      },
      invoiced: {
        allTime: sumBy(invoiced, inv => inv.amount),
        month: sumBy(invoiced.filter(i => isSameMonth(i.invoiceDate as Date, today)), inv => inv.amount),
        year: sumBy(invoiced.filter(i => isSameYear(i.invoiceDate as Date, today)), inv => inv.amount),
      },
      // earned: {
      //   allTime: sumProjects(cliProjects),
      //   month: 0, // sumProjects(invoiced.filter(i => isSameMonth(i.paidDate as Date, today)), inv => inv.amount),
      //   year: 0, // sumBy(invoiced.filter(i => isSameYear(i.paidDate as Date, today)), inv => inv.amount),
      // }
    };

    return result;

  }
);

export const selectClientScore = createSelector(
  _getInput, selectClientsWithProjects,
  (clientId, clients) => {
    if(!clientId || !clients) return null;

    const client = clients.find(c => c.id === clientId);
    if(!client || !client.ratings) return null;
    
    const r = client.ratings;
    const total = r.communication + r.growth + r.joy + r.network + r.quality;
    const score = (total / 5) * 10;
    return parseInt(score.toString());
  }
)