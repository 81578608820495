import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from '@material-ui/core/Tooltip';
import Slider from '@material-ui/core/Slider';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { fontStyles, generalStyles } from "utils/styles";

const buildStyles   = makeStyles(theme => ({
  ...generalStyles(theme),
  ...fontStyles(theme),
  root  : {
    padding     : theme.spacing(1),
  },
  
}));

interface ControlProps {
  id: string;
  label: string;
  tip: string;
  value: number;
  binding: any;
}

const ClientRating = ({id, label, tip, value, binding}: ControlProps) => {
  const classes   = buildStyles();

  return (
    <Grid container className={classes.mb}>
      <Grid item sm={3} container alignItems="center" justify="space-between">        
        <Typography className={classes.bodyText}>{label}</Typography>
        <Tooltip title={tip}>
          <InfoIcon fontSize="small" color="disabled" className={clsx(classes.marginLeft, classes.marginRight)}/>
        </Tooltip>
      </Grid>
      <Grid item sm={4} container alignItems="center">
        <Slider
          value={value}
          aria-labelledby="discrete-slider-custom"
          step={0.5}
          min={1}
          max={10}
          valueLabelDisplay="auto"
          {...binding.slider(id)}
          // marks={marks.joy}
        />
      </Grid>
    </Grid>

  );
}

export default ClientRating;
