import { Invoice } from "features/invoices/infra/invoice-types";
import { IClient } from "./client-types";
import { Indexable, IHierarchicalEntity, ISort, RoundingTypes } from "./common-types";

export type ProjectFilter = "all" | "archived" | "unarchived" | "active" | "paid" | "unpaid" | "delivered" | "undelivered" | "inprogress" | "earned" | "uninvoiced" | "overdue" | "invoiced" | "thismonth" | "thisquarter" | "thisyear" | "lastmonth" | "lastquarter" | "lastyear" | null;

export interface ProjectSettings extends ISort {
  filter?: ProjectFilter | null;
  showDates?: boolean;
  search?: string | null;
  listView?: "table" | "cards" | "board";
}

export enum ProjectType {
  fixed = "fixed",
  perHour = "perHour",
  // perDay = "perDay",
  // perWeek = "perWeek",
  // perMonth = "perMonthly",
  perWord = "perWord",
  perArticle = "perArticle",
  perPost = "perPost",
}

export const ProjectTypeItems = [
  { id: "fixed", label: "fixed", unitLabel: "fixed", },
  { id: "perHour", label: "hourly", unitLabel: "hour", },
  // { id: "perDay", label: "day", unitLabel: "day" },
  // { id: "perWeek", label: "week", unitLabel: "week" },
  // { id: "perMonth", label: "month", unitLabel: "month" },
  { id: "perWord", label: "word", unitLabel: "word" },
  { id: "perArticle", label: "article", unitLabel: "article", },
  { id: "perPost", label: "post", unitLabel: "post", },
];

export interface TrackingDay extends Indexable {
  id: string;
  dayEndDate?: Date;
  weekEndDate?: Date;
  monthEndDate?: Date;
  yearEndDate?: Date;
  minutes: number;
  dayOfWeek?: number;   //the following properties would be nice additions...
  dayOfMonth?: number;
  month?: number;
  year?: number;
}

export interface IProject extends IHierarchicalEntity {
  name: string;
  clientId: string;
  startDate: Date | string;
  dueDate?: Date | string | null;
  deliveredDate?: Date | string | null;
  type: ProjectType;
  fee?: number | null;
  units?: number | null;    //the number of project type units... e.g if project type is "words", this will be the wordcount. if it's "articles", this will be how many articles.
  actualUnits?: number | null;  //in case the final unit count is difference (e.g. with est hours and actual hours)
  invoicedDate?: Date | string | null;
  paidDate?: Date | string | null;
  notes?: string;
  isArchived?: boolean;
  hours?: number;
  createdDate?: Date | string;
  roundType?: RoundingTypes;
  trackedMinutes?: number;
  trackedDays?: TrackingDay[];
  estimatedHours?: number;
  hasInvoices?: boolean;
  lastInvoiceDate?: Date | string | null;
  lastPaymentDate?: Date | string | null;
  category?: string;
  wordCount?: number;   //For if this isn't a per-word article, but still want to track wordcount
}

export interface ProjectTracking extends Indexable{
  // type: string;
  projectId: string;
  days: Indexable;  
}

export interface IProjectDates {
  id: string;
  start: moment.Moment | null;
  due: moment.Moment | null;
  delivered: moment.Moment | null;
  invoiced: moment.Moment | null;
  paid: moment.Moment | null;
  today: moment.Moment;
  startMonth?: number | null;
  startYear?: number | null;
  dueMonth?: number | null;
  dueYear?: number | null;
  deliveredMonth?: number | null;
  deliveredYear?: number | null;
  invoicedMonth?: number | null;
  invoicedYear?: number | null;
  paidMonth?: number | null;
  paidYear?: number | null;
}

export interface ProjectWithDates extends IProject {
  dates: IProjectDates;
}

export interface ProjectWithClient extends IProject {
  client: IClient;
  clientName: string;
}

export type ProjectDateGroup = {
  key: string | null;
  items: ProjectWithDates[];
}

export interface ProjectGroups extends Indexable{
  all: IProject[];
  byStart: ProjectDateGroup[];
  byDue: ProjectDateGroup[];
  byDelivered: ProjectDateGroup[];
  byInvoiced: ProjectDateGroup[];
  byPaid: ProjectDateGroup[];
}

export interface ProjectWithInvoices extends ProjectWithClient {
  invoices: Invoice[] | null;
  lastInvoice: Invoice | null;
  lastInvoiceDate: Date | null;
  lastPaymentDate: Date | null;
  totalInvoiced: number;
  totalPaid: number;
}

//NOTE: There are DaySummary and ProjectSummary types in project-selectors which
// are used locally, but these are more generic, used by metrics (at the moment)
export interface IDaySummary{
  date: Date;
  minutes: number;
}

//NOTE: There are DaySummary and ProjectSummary types in project-selectors which
// are used locally, but these are more generic, used by metrics (at the moment)
export interface IProjectSummary{
  id: string;
  name: string;
  minutes: number;
  startDate: Date;
  deliveredDate?: Date;
  days: IDaySummary[];
}
