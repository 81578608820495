import React, { useEffect, useMemo } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import { useClients, useDialogs, useLocalization, useProjects } from "utils/hooks";
import { fontStyles, formStyles, generalStyles } from "utils/styles";
import { IStepProps } from "./onboarding-view";

const buildStyles   = makeStyles(theme => ({
  ...generalStyles(theme),
  ...formStyles(theme),
  ...fontStyles(theme),
  actionsGrid: {
    marginBottom: theme.spacing(4),
  },
  actionButton: {
    margin: `${theme.spacing(1)}px ${theme.spacing(0)}px`, //`
    minWidth: 250,
  },  
  body: {
    fontSize: 16,
    fontWeight: 400,
    textAlign: "center",
  },
  cardBody: {
    // height: `calc(100% - ${theme.spacing(13)}px)`,
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    }
  },
  leftCol: {
    margin: `${theme.spacing(2)}px ${theme.spacing(0)}px`, //`
    // height: `calc(100% - ${theme.spacing(4)}px)`,
    borderRight: `1px solid ${theme.palette.grey[300]}`, //`
    [theme.breakpoints.down("xs")]: {
      height: "auto",
      borderRight: "none",
      borderBottom: `1px solid ${theme.palette.grey[300]}`, //`
    }
  },
  rightCol: {
    margin: `${theme.spacing(2)}px ${theme.spacing(0)}px`, //`
    // height: `calc(100% - ${theme.spacing(4)}px)`,
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    }
  },
  content: {
    padding: `${theme.spacing(3)}px ${theme.spacing(1)}px`, //`
  },
}));

const OnboardingProjects = ({onNavStateChange}: IStepProps) => {
  const classes   = buildStyles();
  const {L, LSwap} = useLocalization();
  const { onOpenDialog } = useDialogs();
  const clients = useClients();
  const projects = useProjects();
  const hasClients = useMemo(() => { return Boolean(clients && clients.length > 0); }, [clients]);
  
  useEffect(() => {
    const hasProjects = Boolean(projects && projects.length > 0);
    onNavStateChange({canPrevious: false, canNext: hasProjects, hideNext: false, hidePrevious: false, previousTitle: "Previous", nextTitle: "Next", nextOverride: null});
  }, [clients]);

  return (
    <Grid item container justify="center" alignItems="flex-start" className={classes.actionsGrid}>
      <Grid item xs={12} container direction="column" className={clsx(classes.mtl, classes.mb)}>
        <Typography color="primary" className={clsx(classes.pageHead, classes.textCenter, classes.mb)}>{L("projects", true)}</Typography>
        <Typography color="secondary" className={clsx(classes.subTitle, classes.textCenter)}>{`Finally, you'll need some ${L("projects")}`}</Typography>
      </Grid>
      <Grid container className={classes.cardBody}>        
          <Grid item sm={6} xs={12} container className={classes.leftCol}>
            <Grid item container direction="column" justify="space-between" alignItems="center" className={classes.content}>
              <Typography variant="h3" className={clsx(classes.body, classes.mtl, classes.mbl, classes.marginX)}>{"Create your projects manually"}</Typography>
              <Button variant="outlined" color="secondary" disabled={!hasClients} className={clsx([classes.actionButton, classes.marginX])} onClick={onOpenDialog("projectMulti", "")}>{LSwap("Create projects", "projects")}</Button>
            </Grid>
          </Grid>
          
          <Grid item sm={6} xs={12} container className={classes.rightCol}>
            <Grid item container direction="column" justify="space-between" alignItems="center" className={classes.content}>
              <Typography variant="h3" className={clsx(classes.body, classes.mtl, classes.mbl, classes.marginX)}>{"Import your projects from a spreadsheet or .csv file"}</Typography>
              <Button onClick={onOpenDialog("import", "projects")} variant="outlined" color="secondary" disabled={!hasClients} className={clsx([classes.actionButton, classes.marginX])}>{LSwap("Import projects", "projects")}</Button>
            </Grid>
          </Grid>
        </Grid>
    </Grid>
  );
}

export default OnboardingProjects;
