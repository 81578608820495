import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IDialogProps, Invoice, RootState } from "types";
import { IconNames } from "utils/common-classes";
import { useSnackbar } from "utils/hooks";
import { selectInvoice } from "../infra/invoice-selectors";
import { AtkDialog, cancelAction, saveAction } from "components";
import { roundTo, areArraysEqual } from "utils/general-helpers";
import { newInvoice } from "../infra/invoice-helpers";
import { createInvoice, updateInvoice } from "../infra/invoice-actions";
import { trackCreate } from "utils/mixpanel";
import InvoiceContainer from "./invoice-container";

//TODO: All new / edit invoices should be switched to the invoice-edit-view, so
// should be able to get rid of this.

interface InvoiceDialogProps extends IDialogProps {
  invoiceId?: string | null;
  clientId?: string | null;
  projectId?: string | null;
}

const InvoiceDialog2 = (props: InvoiceDialogProps) => {
  const dispatch = useDispatch();
  const invoice = useSelector((state: RootState) => selectInvoice(state, props.invoiceId));
  const [values, setValues] = useState<Partial<Invoice>>(invoice || newInvoice);
  // const isMultiple = React.useMemo(() => {return values.projectId === "multiple"}, [values.projectId]);
  const [selectedHours, setSelectedHours] = React.useState<string[]>([]);
  const notify = useSnackbar();
  const [isWorking, setWorking] = useState<boolean>(false);

  //-- Invoice is being saved
  async function onSave(){
    //TODO: Validate we have enough information
    if(invoice){
      //this is an update
      //check to see if there are any changes to the hours...
      const dollarAmount = roundTo(values.amount || 0, 2); //don't send through the amount with a large fraction
      const model = {...values, amount: dollarAmount} as Invoice;
      if(!areArraysEqual(invoice.hours, selectedHours)){
        model.hours = selectedHours;
      }
      setWorking(true);
      const updateResult = await dispatch(updateInvoice(invoice.id, model)) as any;
      setWorking(false);
      if(!!updateResult){
        notify("Invoice successfully updated", "success");
        props.onClose(true);
      }
    }
    else{
      const dollarAmount = roundTo(values.amount || 0, 2); //don't send through the amount with a large fraction
      const model = {
        ...values,
        amount: dollarAmount,
        hours: selectedHours, //.map(h => h.id),
      } as Invoice;

      setWorking(true);
      const result = await dispatch(createInvoice(model));
      setWorking(false);
      if(!!result){
        trackCreate("invoice");
        notify("Invoice successfully created", "success");
        props.onClose(true);
      }
    }
  }

  const onInvoiceChange = (values: Partial<Invoice>, hours: string[]) => {
    setValues(values);
    setSelectedHours(hours);
  }

  const dialogActions = [cancelAction(() => props.onClose()), saveAction(onSave, false, "Save & Close")];

  return (
    <AtkDialog maxWidth="md" isOpen={Boolean(props.isOpen)} onClose={props.onClose} title="Invoice" icon={IconNames.invoice} actions={dialogActions} isWorking={isWorking}>
      <InvoiceContainer invoiceId={props.invoiceId || ""} hideHeader={true} onChange={onInvoiceChange} isWorking={isWorking}/> 
    </AtkDialog>
  );
}

export default InvoiceDialog2;
