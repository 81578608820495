import { IGoalDefinition } from "./goal-types";
import { Parsers } from "utils/hooks";
// import { Icons } from "utils/common-classes";

export const goalTokens: [string,string,any][] = [
  ["~amount~", "amount", "$0"], 
  ["~count~", "count", 0], 
  ["~filter~", "filter", "[___]"]
];

export const goalCategories = [
  {
    id: "revenue",
    title: "Revenue",
    subTitle: "Revenue goals help you measure and grow your business",
    icon: "monetization_on_outlined",
  },
  {
    id: "clients",
    title: "Clients",
    subTitle: "Client goals encourage you to grow, diversify and build your business for the long-term",
    icon: "business",
  },
  {
    id: "projects",
    title: "Projects",
    subTitle: "Project goals help you expand your business into new verticals, and track your growth",
    icon: "assignment",
  },
  {
    id: "time",
    title: "Time",
    subTitle: "Manage your work-life with time-based goals",
    icon: "alarm",
  }
];

export const goalDefinitions: IGoalDefinition[] = [
  {
    id: "revenue-annual",
    category: "revenue",
    title: "Annual Revenue",
    tip: "Set a goal for annual revenue",
    description: "Book revenue of ~amount~",
    parser: Parsers.currencyInt,
    increment: 5000,
    min: 0,
    showOnDash: true,
    variables: [
      {
        id: "amount", 
        label: "Revenue Target",
        type: "revenue", 
        period: "annual", 
        increment: 5000, 
        defaultValue: 10000,
        min: 0, 
        parser: Parsers.currencyInt 
      }
    ]
  },
  {
    id: "revenue-monthly",
    category: "revenue",
    title: "Monthly Revenue",
    tip: "Set a monthly goal for revenue",
    description: "Book revenue of ~amount~ each month",
    parser: Parsers.currencyInt,
    increment: 2500,
    min: 0,
    variables: [
      {
        id: "amount", 
        label: "Revenue Target",
        type: "revenue", 
        period: "monthly", 
        increment: 5000, 
        min: 0, 
        parser: Parsers.currencyInt 
      }
    ]
  },
  {
    id: "revenue-size",
    category: "revenue",
    title: "Project Size",
    tip: "Strive to take on more projects of a certain value or size",
    description: "Book ~count~ projects worth more than ~amount~",
    parser: Parsers.currencyInt,
    increment: 250,
    min: 0,
    variables: [
      {
        id: "count",
        label: "How many?",
        type: "projects",
        period: "annual",
        parser: Parsers.int,
      },
      {
        id: "amount", 
        label: "Value",
        type: "fee", 
        increment: 500, 
        parser: Parsers.currencyInt 
      }
    ]
  },
  {
    id: "clients-new",
    category: "clients",
    title: "New Clients",
    tip: "Set a goal to expand your business",
    description: "Add ~count~ new clients",
    parser: Parsers.int,
    increment: 1,
    min: 0,
    variables: [
      {
        id: "count",
        label: "How many?",
        type: "clients",
        period: "annual",
        parser: Parsers.int,
      }
    ]
  },
  {
    id: "projects-new",
    category: "projects",
    title: "New Projects",
    tip: "Set a goal to grow your business",
    description: "Book ~count~ new projects",
    parser: Parsers.int,
    increment: 1,
    min: 0,
    variables: [
      {
        id: "count",
        label: "How many?",
        type: "projects",
        period: "annual",
        parser: Parsers.int,
      }
    ]
  },
  {
    id: "clients-diversity",
    category: "clients",
    title: "Client Diversity",
    tip: "Build a recession-proof business by spreading your eggs across multiple baskets",
    description: "Work for ~count~ different client categories",
    parser: Parsers.int,
    increment: 1,
    min: 0,
    variables: [
      {
        id: "count",
        label: "How many?",
        type: "projects",
        filter: "category",
        period: "annual",
        parser: Parsers.int,
      },
      {
        id: "filter",        
        type: "project-category",
        operation: "groupBy"        
      }
    ],
  },
  {
    id: "clients-type",
    category: "clients",
    title: "Client Type",
    tip: "Strengthen your business by expanding into new markets or verticals",
    description: "Book work for ~count~ ~filter~ clients",
    parser: Parsers.int,
    increment: 1,
    min: 0,
    filter: "client-category",
    allowMulti: true,
    variables: [
      {
        id: "count",
        label: "How many?",
        type: "clients",
        period: "annual",
        parser: Parsers.int,
      },
      {
        id: "filter",
        label: "Client Type",
        type: "client-category",                
      }
    ]
  },
  {
    id: "projects-type",
    category: "projects",
    title: "Project Type",
    tip: "Grow your skills, and expand your opportunities",
    description: "Book ~count~ ~filter~ projects",
    parser: Parsers.int,
    increment: 1,
    min: 0,
    filter: "project-category",
    allowMulti: true,
    variables: [
      {
        id: "count",
        label: "How many?",
        type: "projects",
        period: "annual",
        parser: Parsers.int,
        defaultValue: 2,
      },
      {
        id: "filter",
        label: "Project Type",
        type: "project",                
        prop: "category"
      }
    ],
  },
  {
    id: "projects-expand",  //TODO: Can't really calculate this because we don't know the team for a project
    category: "projects",
    title: "Expand within a Client",
    tip: "Grow your reach within a client by working with new people or teams",
    description: "Book ~count~ project(s) with a new team at ~filter~",
    parser: Parsers.int,
    increment: 1,
    min: 0,
    allowMulti: true,
    variables: [
      {
        id: "count",
        label: "How many?",
        type: "projects",
        period: "annual",
        parser: Parsers.int,
        defaultValue: 1,
      },
      {
        id: "filter",
        label: "Which client?",
        type: "client",
        prop: "name",
      }
    ],
  },
  {
    id: "client-revenue-annual",
    category: "clients",
    title: "Client revenue goal",
    tip: "Set a client-specific revenue goals",
    description: "Book ~amount~ in revenue with ~filter~",
    parser: Parsers.int,
    increment: 1,
    min: 0,
    allowMulti: true,
    variables: [
      {
        id: "amount",
        label: "Revenue Target?",
        type: "revenue",
        period: "annual",
        parser: Parsers.currencyInt,
        defaultValue: 2500,
        increment: 2500,
        min: 0
      },
      {
        id: "filter",
        label: "Which client?",
        type: "client",
        prop: "name",
      }
    ],
  }
];