import _ from "lodash";
import moment from "moment";
// import { subDays, startOfDay, isBefore, isSameDay, isAfter } from "date-fns";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store/root-reducer";
import { ProjectTracking, ITimer, IProject, ChartFormatter } from "types";
import { REDUX_DATE_FORMAT, DISPLAY_MONTH_FORMAT, formatDate } from "utils/date-helpers";
import { ensureProps, stringsToDates } from "utils/general-helpers";
import { selectAllProjects } from "features/app/infra/common-selectors";
import { addDays, addWeeks, endOfDay, endOfWeek, isSameDay, isSameMonth, isSameWeek, startOfDay, startOfMonth, subDays, subWeeks } from "date-fns";
import { isSameYear, parseISO } from "date-fns/esm";

// const _getRootState = (state: RootState) => state;
const _getProjectHours = (state: RootState) => state.projects.trackedHours;
const _getTimer = (state: RootState) => state.timesheet.current;
const _getHours = (state: RootState) => state.timesheet.items;
const _getInput = (state: RootState, input: any) => input;
const _getItemQuery = (state: RootState, key: string) => state.timesheet.itemQueries[key];

type DaySummary = { 
  day: string;
  minutes: number;
  hours: number;
  eow: string;
  eom: string;
  monthKey: string;
  month: number;
  year: number;
  date: number;
  moment: moment.Moment;
};

type ProjectSummary = {
  id: string;
  project: IProject;
  days: DaySummary[];
}

export const selectCurrentTimer = createSelector(
  selectAllProjects, _getTimer,
  (projects, timer) => {
    if (!projects || !timer) return null;

    const project = timer.projectId ? projects.find(p => p.id === timer.projectId) : null;
    const client = project ? project.client : null;
    return {
      ...timer,
      client: client,
      project: project,
    } as ITimer;
  }
);

export const selectTimer = createSelector(
  selectAllProjects, _getHours, _getInput,
  (projects, hours, id) => {
    if (!projects || !hours || !id) return null;

    const item = hours.find(h => h.id === id.toString());
    if (!item) return null;
    const project = item.projectId ? projects.find(p => p.id === item.projectId) : null;
    const client = project ? project.client : null;
    return {
      ...item,
      client: client,
      project: project,
    };
  }
)

const selectDates = () => {
  const days = 5; //parseInt(weekDays) || 5;
  const weeks = 4;
  const now = moment();

  const dates = {
    weekDays:  days,
    eoy: now.clone().endOf("year"),
    eom: now.clone().endOf("month"),
    eow: now.clone().endOf("week"),
    eod: now.clone().endOf("day"),
    daysAgo: now.clone().subtract(days, "days").startOf("day"),
    weeksAgo: now.clone().subtract(weeks, "weeks").startOf("day"),
    year: now.year(),
    month: now.month(),
  };
  const keys = {
    eoyK: dates.eoy.format(REDUX_DATE_FORMAT),
    eomK: dates.eom.format(REDUX_DATE_FORMAT),
    eowK: dates.eow.format(REDUX_DATE_FORMAT),
    eodK: dates.eod.format(REDUX_DATE_FORMAT),
    daysAgoK: dates.daysAgo.format(REDUX_DATE_FORMAT),
    weeksAgoK: dates.weeksAgo.format(REDUX_DATE_FORMAT),
  };

  return {...dates, ...keys};
}

interface IDay{
  id: string;
  mmt: moment.Moment;
  date: Date,
  minutes: number;
}

const selectDays = createSelector(
  _getProjectHours,
  (hours) => {
    if(!hours) return null;

    //TODO: Look at metrics-time.ts, calcWorkdays function for a simplier way to 
    // gather all the days and minutes
    const allDays = _.reduce<ProjectTracking, IDay[]>(hours, (accum, projectHours) => {
      if(projectHours.days){
        _.forOwn(projectHours.days, (minutes, dayKey) => {
          let day = accum.find(d => d.id === dayKey);
          if(!day){
            day = {
              id: dayKey,
              mmt: moment(dayKey).endOf("day"),
              date: endOfDay(parseISO(dayKey)),
              minutes: 0,
            } as IDay;
            accum.push(day);
          }
          day.minutes = day.minutes + minutes;
        });        
        return accum;
      }
      else return accum;
    }, []);

    return allDays;
  }
)

export const selectWeekHours = createSelector(
  selectDays,
  (days) => {
    if(!days) return null;
    const eod = endOfDay(new Date());
    let currDate = subDays(eod, 5); //start 5 days ago
    const thisWeek = days.filter((d) => isSameWeek(d.date, eod, {weekStartsOn: 6}));
    const thisWeekHours = (_.sumBy(thisWeek, d => d.minutes) / 60); //.toFixed(2);
    
    const lastFiveDays = days.filter((d) => d.mmt.isSameOrAfter(currDate, "days") && d.mmt.isSameOrBefore(eod, "days")).slice();
    const weekApexData = [];
    const categories = [];
    while(currDate <= eod){
      const items = lastFiveDays.filter(i => isSameDay(i.date, currDate));
      const hours = (_.sumBy(items, d => d.minutes) / 60).toFixed(2);
      weekApexData.push(hours);
      categories.push(formatDate(currDate, "ddd"));
      currDate = addDays(currDate, 1);
    }

    return {
      week: {
        label: "Hours", 
        amount: thisWeekHours, 
        toolTip: "Hours this week", 
        chartData: [{name: "Hours", type: "bar", data: weekApexData }],
        chartProps: { chartType: "bar", categories }, 
        chartFormatter: "hours" as ChartFormatter,
        color: "time", 
        action: "hours"
      },
    };
  }
);

export const selectMonthHours = createSelector(
  selectDays,
  (days) => {
    if(!days) return null;
    const now = new Date();
    const eow = endOfWeek(now, {weekStartsOn: 6});
    const thisMonth = days.filter((d) => isSameMonth(d.date, now));
    const thisMonthHours = (_.sumBy(thisMonth, d => d.minutes) / 60);

    const thisYear = days.filter((d) => isSameYear(d.date, now));
    let counter = endOfWeek(subWeeks(eow, 4), {weekStartsOn: 6});
    // let counter = endOfWeek(startOfMonth(eow), {weekStartsOn: 6});
    const monthApexData = [];
    const weekEnds = [];
    const categories = [];
    while(counter <= eow){
      weekEnds.push(formatDate(counter, DISPLAY_MONTH_FORMAT));
      const items = thisYear.filter(i => isSameWeek(i.date, counter, {weekStartsOn: 6}));
      const hours = (_.sumBy(items, d => d.minutes) / 60).toFixed(2);
      monthApexData.push(hours);
      categories.push(formatDate(counter, "M/D"));
      counter = addWeeks(counter, 1); //.add(1, "week");
    }

    return {
      month: {
        label: "Hours", 
        amount: thisMonthHours, 
        toolTip: "Hours this month", 
        chartData: [{name: "Hours", type: "bar", data: monthApexData }],
        chartProps: { chartType: "bar", categories }, 
        chartFormatter: "hours" as ChartFormatter,
        color: "hours", 
        action: "hours"
      },
    };
  }
);

//TODO: add a selectAnnualHours, then convert the below to use the three selectors above.

export const selectHoursSummary = createSelector(
  _getProjectHours,
  (hours) => {
    if(!hours) return null;
    
    //TODO: Look at metrics-time.ts, calcWorkdays function for a simplier way to 
    // gather all the days and minutes
    const now = moment();
    const allDays = _.reduce<ProjectTracking, IDay[]>(hours, (accum, projectHours) => {
      if(projectHours.days){
        _.forOwn(projectHours.days, (minutes, dayKey) => {
          let day = accum.find(d => d.id === dayKey);
          if(!day){
            day = {
              id: dayKey,
              mmt: moment(dayKey).endOf("day"),
              minutes: 0,
            } as IDay;
            accum.push(day);
          }
          day.minutes = day.minutes + minutes;
        });        
        return accum;
      }
      else return accum;
    }, []);

    const weekDays = 5;
    const eow = now.clone().endOf("week");
    const sow = now.clone().subtract(weekDays, "days").endOf("day");
    
    const thisYear = allDays.filter((d) => d.mmt.isSame(now, "year"));
    const thisMonth = allDays.filter((d) => d.mmt.isSame(now, "month"));
    const thisWeek = allDays.filter((d) => d.mmt.isSame(now, "week"));
    const lastFiveDays = allDays.filter((d) => d.mmt.isSameOrAfter(sow, "days") && d.mmt.isSameOrBefore(eow, "days")); // d.dayEndDate >= sow.format(REDUX_DATE_FORMAT) && d.dayEndDate <= eow.format(REDUX_DATE_FORMAT));

    const thisYearHours = (_.sumBy(thisYear, d => d.minutes) / 60).toFixed(2);
    const thisMonthHours = (_.sumBy(thisMonth, d => d.minutes) / 60).toFixed(2);
    const thisWeekHours = (_.sumBy(thisWeek, d => d.minutes) / 60).toFixed(2);

    const eod = now.clone().endOf("day");
    const weekCounter = sow.clone();
    const weekApexData = [];
    while(weekCounter <= eod){
      const items = lastFiveDays.filter(i => i.mmt.isSame(weekCounter, "day"));
      const hours = (_.sumBy(items, d => d.minutes) / 60).toFixed(2);
      weekApexData.push(hours);
      weekCounter.add(1, "day");
    }

    const monthCounter = now.clone().startOf("month").endOf("week");
    const monthApexData = [];
    const weekEnds = [];
    while(monthCounter <= eow){
      weekEnds.push(monthCounter.format(DISPLAY_MONTH_FORMAT));
      const items = thisYear.filter(i => i.mmt.isSame(monthCounter, "week"));
      const hours = (_.sumBy(items, d => d.minutes) / 60).toFixed(2);
      monthApexData.push(hours);
      monthCounter.add(1, "week");
    }

    const eom = now.clone().endOf("month");
    const yearCounter = now.clone().startOf("year").endOf("month");
    const yearApexData = [];
    while(yearCounter <= eom){
      const items = thisYear.filter(i => i.mmt.isSame(yearCounter, "month"));
      const hours = (_.sumBy(items, d => d.minutes) / 60).toFixed(2);
      yearApexData.push(hours);
      yearCounter.add(1, "month").endOf("month");
    }

    return {
      yearHours: thisYearHours,
      monthHours: thisMonthHours,
      weekHours: thisWeekHours,

      weekApexChart: [{name: "Hours", type: "bar", data: weekApexData }],
      monthApexChart: [{name: "Hours", type: "bar", data: monthApexData }],
      yearApexChart: [{name: "Hours", type: "bar", data: yearApexData }],

      weekEnds: weekEnds,
    }
  }
)

export const selectProjectHours = createSelector(
  _getProjectHours, selectAllProjects,
  (projectHours, projects) => {
    if(!projects || !projectHours) return null;

    const projectsWithDays = _.reduce(projectHours, (accum: ProjectSummary[], prjHours) => {
      const days = _.reduce(prjHours.days, (daysArray: DaySummary[], value, key) => {
        const dayDate = moment(key);
        const weekEnd = dayDate.clone().endOf("week");
        const monthEnd = dayDate.clone().endOf("month");
        const daySummary: DaySummary = { 
          day: key, 
          minutes: value, 
          hours: (value / 60), 
          eow: weekEnd.format(REDUX_DATE_FORMAT), 
          eom: monthEnd.format(REDUX_DATE_FORMAT),
          monthKey: monthEnd.format(DISPLAY_MONTH_FORMAT),
          month: dayDate.month(),
          year: dayDate.year(),
          date: dayDate.date(),
          moment: dayDate,
        }
        daysArray.push(daySummary);
        return daysArray;
      }, []);
      const project = projects.find(prj => prj.id === prjHours.projectId);
      const projectSummary: ProjectSummary = {id: prjHours.projectId, project: project as IProject, days: days};
      accum.push(projectSummary);

      return accum;
    }, []);

    return projectsWithDays;
  }
);

//Used by the hours-summary-week which shows the project-level summaries in the timesheet view
export const selectProjectsSummary = createSelector(
  selectProjectHours, selectDates,
  (projectHours, dates) => {
    if(!projectHours) return null;

    const projectSummaries = projectHours.map(proj => {
    const days = proj.days.filter((d: DaySummary) => d.moment.isSameOrAfter(dates.daysAgo));
    const weeksStart = dates.eod.clone().subtract(3, "months");
    const weeks = proj.days.filter((d: DaySummary) => d.month >= weeksStart.month());
  
      const dayCounter = dates.daysAgo.clone();
      const weekApexData = [];
      let lastFiveTotal = 0;
      while(dayCounter <= dates.eod){
        const item = days.find(i => i.date === dayCounter.date());
        const hours = parseFloat(item?.hours.toFixed(2) || "0") ?? 0;
        weekApexData.push(hours);
        lastFiveTotal += hours;
        dayCounter.add(1, "day");
      }

      const weekCounter = weeksStart.clone().endOf("week"); //dates.eod.clone().startOf("year").endOf("week"); //dates.weeksAgo.endOf("week").clone();
      const monthApexData = [];
      const weekEnds = [];
      while(weekCounter <= dates.eow){
        const label = weekCounter.format(REDUX_DATE_FORMAT);
        weekEnds.push(weekCounter.format(DISPLAY_MONTH_FORMAT));
        const items = weeks.filter(i => i.eow === label);  //use thisYear because some of the items in the first week may be from the previous month
        const hours = parseFloat(_.sumBy(items, d => d.hours).toFixed(2));
        monthApexData.push([weekCounter.toDate(), hours]);
        weekCounter.add(1, "week");
      }

      return {
        ...proj,
        priority: lastFiveTotal,
        weekEnds: weekEnds,
        weekApexChart: [{name: "Hours", type: "bar", data: weekApexData }],
        monthApexChart: [{name: "Hours", type: "area", data: monthApexData }],
      };
    });
  
    const ordered = _.orderBy(projectSummaries, ["priority"], ["desc"]);
    return ordered;
  }
);

export const selectHours = createSelector(
  _getHours, selectAllProjects,
  (items, projects) => {
    if(!items) return [];
    if(!projects) return items;

    const expanded = items.map((item: ITimer) => { 
      const project = projects?.find(p => p.id === item.projectId);
      const client = project?.client ?? null;

      return {
        ...stringsToDates(item), 
        projectName: project?.name || "Loading...",
        clientName: client?.name || "Loading...",
      }; 
    });

    return expanded;
  }
)

export const selectHoursQuery = createSelector(
  _getItemQuery, selectAllProjects,
  (items, projects): ITimer[] => {
    if(!items) return [];
    if(!projects) return items;

    const withProject = items.map((item: ITimer) => { 
      return {
        ...stringsToDates(item), 
        project: projects.find(p => p.id === item.projectId)
      }; 
    });

    return withProject;
  }
);