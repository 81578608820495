import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Icon from '@material-ui/core/Icon';
import ExpandLessIcon from '@material-ui/icons/ArrowUpward';
import ExpandMoreIcon from '@material-ui/icons/ArrowDownward';
import { IProject, ProjectTypeItems, RootState, roundingTypeItems, emptyClient, IClient } from 'types';
import { useLocalization, useInputs, useClients, useProject, useSnackbar } from 'utils/hooks';
import { AtkDialog, IDialogAction, DateInput, SeparatorLine } from 'components';
import { selectCustomizations } from "features/app";
import { createProject, updateProject } from '../infra/project-actions';
import { createClient } from "features/clients/clients-slice";
import { newProject, projectParsers, projectTypeLabel } from "../infra/project-helper";
import { feeAdornStyle, fontStyles, formStyles, generalStyles, iconStyles } from "utils/styles";
import { capitalize, omit } from 'lodash';
import { trackCreate, trackEdit } from 'utils/mixpanel';

interface DialogProps {
  isOpen: any;
  id: number | string | boolean | undefined;
  onClose: (id?: number | boolean) => void;
  isExpanded?: boolean;
  focusField?: string | null;
  clientId?: string;
}

const buildStyles = makeStyles(theme => ({
  ...generalStyles(theme),
  ...formStyles(theme),
  ...feeAdornStyle(theme),
  ...iconStyles(theme),
  ...fontStyles(theme),
  clientNameAdjust: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 21,
    }
  }
}));

const ProjectDialog = (props: DialogProps) => {
  const classes = buildStyles();
  const dispatch = useDispatch();
  const { L } = useLocalization();
  const defaults = useSelector((state: RootState) => selectCustomizations(state, ["defaultRounding", "defaultProjectType", "defaultHourlyRate", "projectCategory", "wordCount"]));
  const clients = useClients("name");
  const project = useProject(props.id?.toString()) || newProject;
  const [isExpanded, setExpanded] = React.useState(Boolean(props.isExpanded));
  const [focusOverride, setFocusOverride] = React.useState<string | null>(null);
  const [values, display, errors, binding, setValues] = useInputs<IProject>(project, projectParsers);
  const isNew = React.useMemo(() => { return (props.id === -1 || props.id === "-1" || props.id === "" || props.id === true); }, [values.id])
  const today = React.useMemo(() => new Date(), []);
  const [clientName, setClientName] = React.useState("");
  const notify = useSnackbar();
  
  React.useEffect(() => {
    if (!!props.clientId && !project.clientId) {
      setValues({ ...project, clientId: props.clientId });
    }
  }, []);
  
  React.useEffect(() => {
    if(isNew && !props.clientId){
      const withDefaults = {
        ...values,
        fee: defaults.defaultHourlyRate,
        type: defaults.defaultProjectType,
      };
      if(defaults.defaultProjectType === "perHour"){
        withDefaults.roundType = defaults.defaultRounding;
      }

      return setValues(withDefaults);
    }
  }, [props.id]);

  React.useEffect(() => {
    if (isNew && values.clientId && values.clientId !== "new") {
      const cli = clients?.find(c => c.id === values.clientId);
      if (cli && (cli.defaultRate || cli?.defaultProjectType)) {
        setValues(
          { 
            ...values, 
            fee: cli.defaultRate, 
            type: (cli.defaultProjectType || values.type),
            roundType: defaults.defaultRounding,
          }
        );
      }
    }
  }, [isNew, values.clientId]);

  React.useEffect(() => {
    setExpanded(Boolean(props.isExpanded));
  }, [props.isExpanded]);

  const onSave = async () => {
    if(!values.name || !values.clientId) return;
    let newClientId = null;

    if(values.clientId === "new"){
      if(clientName.trim().length === 0) return;  //TODO: validation
      const newClient: IClient = {
        ...emptyClient,
        name: clientName,
        defaultRate: values.fee || 0,
        defaultProjectType: values.type,
      };
      
      const clientResult = await dispatch(createClient(newClient));
      if(!!clientResult){
        //TODO: assign the client id to the project...
        newClientId = (clientResult as any).id;
      }
    }

    let updates = values;
    if(values.clientId === "new"){
      if(!newClientId){
        console.log("could not get new client id...");
        return;
      } 

      updates = {...values, clientId: newClientId};
    }

    let action = "created";
    if (isNew){ //(props.id === -1 || props.id === "-1" || props.id === "") {
      updates = omit(updates, ["id"]) as IProject;  //don't pass in an id
      await dispatch(createProject(updates));
      trackCreate("project");
    }
    else if (!!project && !!project.id) {
      action = "updated";
      await dispatch(updateProject(project.id, updates));
      trackEdit("project");
    }

    notify(`${L("project", true)} ${action} successfully`);
    setValues({ ...newProject });    //reset the values
    props.onClose(true);
  }

  const dialogActions: IDialogAction[] = [
    {
      label: "Cancel",
      onClick: () => { props.onClose(); },
      color: "default",
    },
    {
      label: "Save & Close",
      onClick: onSave,
      color: "primary",
    },
    {
      label: isExpanded ? "Fewer Dates" : "More Dates",
      onClick: () => { if (!isExpanded) { setFocusOverride("deliveredDate"); } setExpanded(!isExpanded) },
      color: "secondary",
      align: "left",
      rightIcon: isExpanded ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" />,
    }
  ];

  return (
    <AtkDialog isOpen={Boolean(props.isOpen)} onClose={() => props.onClose()} maxWidth="md" title={L("project", true)} icon="assignment" actions={dialogActions}>
      <Grid container spacing={2}>

        <Grid item md={7} sm={12} className={classes.inputGrid} container alignItems="flex-end">
          <TextField 
            id="name" 
            label="Name*" 
            autoFocus={!props.focusField || props.focusField === "name"} 
            fullWidth value={display?.name} 
            {...binding.input} 
            {...errors["name"]} 
            autoComplete="off" />
        </Grid>

        <Grid item md={5} sm={6} xs={12} container className={classes.inputGrid} alignItems="flex-end">
          <FormControl className={classes.formControl} fullWidth {...binding.selectContainer("clientId", clients)}>
            <InputLabel id="client-label" className={classes.selectLabel}>Client*</InputLabel>
            <Select id="clientId" labelId="client-label" label="Client" value={values?.clientId} {...binding.select("clientId")} className={classes.selectContainer} fullWidth classes={{ select: classes.select }}>
              <MenuItem value="-1" disabled className={classes.option}>Select Client</MenuItem>
              <MenuItem value="new" className={classes.option}>
                <Grid container alignItems="flex-end">
                  <Icon fontSize="small" color="secondary" className={classes.iconLeft}>add</Icon>
                  <Typography color="secondary">New Client...</Typography>
                </Grid>
              </MenuItem>
              {clients?.map(option => <MenuItem key={option.id} value={option.id} className={classes.option}>{option.name}</MenuItem>)}
            </Select>
          </FormControl>
        </Grid>

        {values.clientId === "new" && 
          <Grid item md={6} sm={6} className={classes.inputGrid} container>
            <TextField id="newClientName" label="New Client Name*" autoFocus={values.clientId === "new"} value={clientName} onChange={(e) => setClientName(e.target.value)} fullWidth autoComplete="off" className={classes.clientNameAdjust}/>
          </Grid>
        }

        <Grid item md={2} sm={3} container alignItems="flex-start"  className={classes.inputGrid}>
          <DateInput id="startDate" label="Assigned*" value={values.startDate} {...binding.dateInput("startDate")} autoFocus={props.focusField === "startDate"}  fullWidth/>
        </Grid>
        <Grid item md={2} sm={3} container alignItems="flex-start" className={classes.inputGrid}>
          <DateInput id="dueDate" label="Due" value={values.dueDate} {...binding.dateInput("dueDate")} autoFocus={props.focusField === "dueDate"} fullWidth minDate={values.startDate || today}/>
        </Grid>
        <Grid item md={3} sm={4} xs={7} className={classes.inputGrid} container alignItems="flex-end">
          <TextField id="fee" label="Fee / Rate" className={classes.rateInput} value={display?.fee} {...binding.input} {...errors["fee"]} fullWidth autoComplete="off"
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <Grid container className={classes.adornGrid}>
                  <Typography className={classes.adorn}>/</Typography>
                  <TextField select value={display?.type} {...binding.select("type")} className={classes.adornSelect}>
                    <MenuItem value={-1} disabled>[choose]</MenuItem>
                    {ProjectTypeItems.map(opt => <MenuItem key={opt.id} value={opt.id} className={classes.option}>{opt.unitLabel}</MenuItem>)}
                  </TextField>
                </Grid>
              </InputAdornment>
            }}
          />
        </Grid>
        
        {(values.type !== "fixed" && values.type !== "perHour") && 
          <Grid item sm={2} xs={4} container className={classes.inputGrid} alignItems="flex-end">
            <TextField id="units" label={`${capitalize(projectTypeLabel(values.type))}s`} fullWidth value={display?.units} {...binding.input} {...errors["units"]} autoComplete="off" className={classes.textCenter} />
          </Grid>
        }
        {(values.type !== "perHour" && values.type !== "perWord") && 
          <Grid item sm={2} xs={4} className={classes.inputGridDense}>
              <TextField id="wordCount" label="Words" fullWidth value={display?.wordCount} {...binding.input} {...errors["wordCount"]} autoComplete="off" className={classes.textCenter} />
          </Grid>
        }

        {(values.type === "perHour") && 
          <Grid item md={3} sm={6} xs={5} className={classes.inputGrid} container alignItems="flex-end">
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel id="rounding-label" htmlFor="roundType">Rounding</InputLabel>
              <Select id="roundType" labelId="rounding-label" margin="dense" value={values?.roundType || defaults.defaultRounding} {...binding.select("roundType")} fullWidth>
                {roundingTypeItems?.map((option: any) => <MenuItem key={option.id} value={option.id}>{option.label}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>
        }

        <SeparatorLine label="Details" className={classes.mtl} />
        
        <Grid item md={9} className={classes.inputGridDense}>
          <TextField id="projectCategory" label="Project Category" value={display?.category || ""} {...binding.inputWithKey("category")} {...errors["category"]} fullWidth margin="dense" autoComplete="on" />              
        </Grid>
        <Grid item md={3} className={classes.inputGridDense}>
          <TextField id="estimatedHours" label="Est Hours" className={classes.textRight} value={display?.estimatedHours || ""} {...binding.input} {...errors["estimatedHours"]} fullWidth margin="dense" autoComplete="off" />
        </Grid>

        <Grid item xs={12} className={classes.inputGrid}>
          <TextField id="notes" label="Notes" fullWidth value={display?.notes} {...binding.input} {...errors["notes"]} multiline rowsMax={3} />
        </Grid>

        
        {isExpanded &&
          <Grid item xs={12} container spacing={2}>
            <Grid item md={2} sm={4} xs={6} className={classes.inputGrid}>
              <DateInput id="deliveredDate" label="Delivered" value={values.deliveredDate} {...binding.dateInput("deliveredDate")}  autoFocus={props.focusField === "deliveredDate" || focusOverride === "deliveredDate"} fullWidth minDate={values.startDate || today}/>
            </Grid>
            {project.hasInvoices && 
              <Grid item md={3} sm={4} xs={6} container className={classes.inputGrid} alignItems="flex-end">
                <Typography className={classes.infoText}>[This project uses invoices]</Typography>
              </Grid>
            }
            {!project.hasInvoices && 
              <>
                <Grid item md={2} sm={4} xs={6} className={classes.inputGrid}>
                  <DateInput id="invoicedDate" label="Invoiced" value={values.invoicedDate} {...binding.dateInput("invoicedDate")} autoFocus={props.focusField === "invoicedDate"} fullWidth minDate={values.startDate || today}/>
                </Grid>
                <Grid item md={2} sm={4} xs={6} className={classes.inputGrid}>
                  <DateInput id="paidDate" label="Paid" value={values.paidDate} {...binding.dateInput("paidDate")} autoFocus={props.focusField === "paidDate"} fullWidth minDate={values.startDate || today}/>
                </Grid>
              </>
            }
          </Grid>
        }

      </Grid>
    </AtkDialog>
  );
}

export default ProjectDialog;