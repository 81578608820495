import React, { useMemo } from "react";
import clsx from "clsx";
import { Link, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from "@material-ui/core/Typography";
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import LinearProgress from '@material-ui/core/LinearProgress';
import { IProject } from "types";
import { fontStyles, generalStyles, interactionStyles } from "utils/styles";
import { formatDate } from "utils/date-helpers";
import { SubItem } from "components";
import { useActiveTimer, useDialogs, useHover } from "utils/hooks";
import { getRoute } from "features/app";
import { Icons } from "utils/common-classes";
import { formatDistanceToNowStrict, isSameDay } from "date-fns/esm";
import { differenceInDays, endOfDay, startOfDay } from "date-fns";
import ProjectTimerButton from "features/projects/ui/project-timer-button";
import { formatPercent } from "utils/number-helpers";

const buildStyles   = makeStyles(theme => ({
  ...generalStyles(theme),
  ...fontStyles(theme),
  ...interactionStyles(theme),
  root  : {
    padding     : theme.spacing(1),
  },
  projectAvatar: {
    height: theme.spacing(3),
    width: theme.spacing(3),
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.grey[200],
    border: `0.25px solid ${theme.palette.grey[300]}`, //`
    color: theme.palette.grey[500],
    fontSize: "0.95rem",
  },
  projectName: {
    textDecoration: "none",
  },
  dateButton: {
    textTransform: "none",
    width: theme.spacing(14),
    height: 36,
    "& span": {
      fontSize: "0.8rem",
    }
  },
  dueButtonWarning: {
    backgroundColor: theme.palette.warning.light,
  },
  dueButtonDanger: {
    backgroundColor: theme.palette.error.light,
  },
  active: {
    border: `1px solid ${theme.palette.secondary.main}`, //`
    background: `linear-gradient(90deg,${theme.palette.common.white},${theme.palette.secondary.light}44)`,
  },
  projectProgress : {
    marginLeft: theme.spacing(2),
    minWidth: 50,
    width: 100,
  }
}));

interface ItemProps{
  project: IProject;
}

const ProjectDashItem = ({project}: ItemProps) => {
  const classes   = buildStyles();
  const history = useHistory();
  const [hoverRef, isHovered] = useHover();
  const { openDialog } = useDialogs();
  const activeTimer = useActiveTimer();
  const isTiming = useMemo(() => activeTimer && activeTimer.projectId === project.id, [activeTimer, project]);
  const pctComplete = useMemo(() => project.estimatedHours && project.trackedMinutes ? ((project.trackedMinutes / 60) / project.estimatedHours) * 100 : 0, [project.estimatedHours, project.trackedMinutes]);

  const daysToDue = useMemo(() => {
    if(!project.dueDate) return 999;
    const due = project.dueDate as Date;
    const today = new Date();
    const isToday = isSameDay(due, today);  //differenceInDays will return 0 for times within 24 hours (including yesterday)
    if(isToday) return 0;
    const daysToDue = differenceInDays(startOfDay(due), startOfDay(new Date()));
    return daysToDue === 0 ? 1 : daysToDue;
    
  }, [project]);

  const dueLabel = useMemo(() => { 
    const due = endOfDay(project.dueDate as Date);
    if(daysToDue === 0){
      return {mod: "", suffix: "", value: "today"};
    }
    else if(daysToDue === 999){
      return {mod: "", suffix: "", value: "set due date"};
    }
    else if(daysToDue < 0){
      return {mod: "", suffix: "ago", value: formatDistanceToNowStrict(due, {addSuffix: true, unit: "day"})};
    }
    else if(daysToDue > 5){
      return {mod: "on", suffix: "", value: formatDate(due)};
    }
    else {
      return {mod: "in", suffix: "", value: formatDistanceToNowStrict(due, {addSuffix: true, unit: "day"})};
    }
  }, [daysToDue]);

  const dueButtonClass = useMemo(() => {
    if(daysToDue < 0) return classes.dueButtonDanger;
    else if(daysToDue < 5) return classes.dueButtonWarning;
    else return null; //classes.dueButtonNormal;
  }, [daysToDue]);

  const onActionClick = (item: string) => () => {
    console.log("menu item clicked");
    switch(item){
      case "invoice": 
        openDialog("invoice", null, {clientId: project.clientId, projectId: project.id});
        break;

      case "timer":
        openDialog("timer", null, {clientId: project.clientId, projectId: project.id});
        break;

      case "edit":
        history.push(`/projects/${project.id}/edit`);
        break;
    }
  }

  const onDueClick = () => {
    if(!project.dueDate){
      //no due date, so focus on that, rather than delivery
      openDialog("project", project.id, {isExpanded: true, focusField: "dueDate"});
    }
    else if(project.hasInvoices){
      //Need to open the invoice dialog, not the project dialog
      openDialog("invoice", null, {clientId: project.clientId, projectId: project.id});
    }
    else{
      openDialog("project", project.id, {isExpanded: true, focusField: "deliveredDate"});
    }
  }

  return (
    <Paper ref={hoverRef} variant="outlined" className={clsx(classes.root, classes.fullWidth, {[classes.hoverShadowPrimary]: isHovered, [classes.active]: isTiming})}>
      <Grid container justify="space-between">
        <Grid item sm={7} container wrap="nowrap">
          <Grid item sm={12} container direction="column">
            <Grid container wrap="nowrap" alignItems="center" className={classes.mbd}>
              <Avatar variant="rounded" className={classes.projectAvatar}>{project.clientName[0]}</Avatar>
              <Tooltip title={project.name}>
                <Grid item className={classes.overflowContainer}>
                  <Typography color="primary" className={clsx(classes.bodyTextSmall, classes.semiBold, classes.projectName)} component={Link} to={`/projects/${project.id}/view`}>{project.name}</Typography>
                </Grid>
              </Tooltip>
            </Grid>
            
            <Grid container alignItems="center">
              <Link to={getRoute("client", project.clientId)} className={classes.link}>
                <Typography color="primary" className={classes.infoText}>{project.client.name}</Typography>
              </Link>              
              <SubItem icon="person_outlined" label={project.client.contactName} tooltip={`Contact: ${project.client.contactName}`} hideLabel/>
              <SubItem icon="mail_outlined" label={project.client.contactEmail} link={`mailto:${project.client.contactEmail}`} tooltip={`Email: ${project.client.contactEmail}`} hideLabel/>
              <SubItem icon="phone_outlined" label={project.client.contactPhone} tooltip={`Phone: ${project.client.contactPhone}`} hideLabel/>
              {pctComplete && 
                <Tooltip title={`${formatPercent(pctComplete/100, 0)} Complete, Estimated: ${project.estimatedHours}hrs, Tracked: ${((project.trackedMinutes || 0) / 60).toLocaleString()}hrs`}>
                  <LinearProgress variant="determinate" color="primary" value={pctComplete} className={classes.projectProgress} />
                </Tooltip>
              }
            </Grid>
          </Grid>
        </Grid>
        
        <Grid item sm={5} container justify="flex-end" alignItems="center">
          <Grid item className={classes.marginLeft}>
            {isHovered && 
              <>
                <IconButton onClick={onActionClick("edit")} size="medium">{Icons.edit("small")}</IconButton>
                {project.trackedMinutes && <IconButton onClick={onActionClick("invoice")} size="medium">{Icons.invoice("small")}</IconButton>}
              </>
            }
            {(isTiming || isHovered) && <ProjectTimerButton project={project} size="medium"/>}
          </Grid>
          <Tooltip title={project.dueDate ? `Due ${formatDate(project.dueDate)}. Click to edit.` : "Set a due date"}>
            <Button onClick={onDueClick} size="small" className={clsx(classes.bodyTextSmall, classes.dateButton, dueButtonClass)}>
              {dueLabel.value}
            </Button>
          </Tooltip>
        </Grid>

      </Grid>
    </Paper>
  );
}

export default ProjectDashItem;
