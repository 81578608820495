import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import moment from "moment";
import { ITimer, TimesheetSettings, IStatus, Indexable, ItemUpdate } from "types";

interface InitialState {
  current: Partial<ITimer> | null;
  items: Array<ITimer> | null;
  isEmpty: boolean | null;
  isInitialized: boolean;
  lastCheckForTimer: string | null;
  settings: TimesheetSettings;
  status: IStatus;
  itemQueries: Indexable;  
}

export const initialState: InitialState = {
  current: null,
  items: null,
  isEmpty: null,
  isInitialized: false,
  lastCheckForTimer: null,
  settings: {
    range: null,  //"this-month",
    filter: null, //a json string of the filter applied,
    sort: "stopTime",
    sortDir: "desc",
    showDates: false,
    tab: "summary",
  },
  status: {
    isWorking: false,
    error: null,
  },
  itemQueries: {}
};

export const slice = createSlice({
  name: "timesheet",
  initialState,
  reducers: {
    statusChanged: (state, action) => {
      state.status = {
        ...state.status,
        ...action.payload,
      };
    },
    updateTimesheetSettings: (state, action) => {
      const updates = { ...state.settings, ...action.payload };
      state.settings = updates;
    },
    clearStore: (state) => {
      state.current = initialState.current;
      state.items = initialState.items;
      state.isEmpty = initialState.isEmpty;
      state.isInitialized = initialState.isInitialized;
      state.lastCheckForTimer = initialState.lastCheckForTimer;
      state.settings = {...initialState.settings};
      state.status = {...initialState.status};
      state.itemQueries = initialState.itemQueries;
    },
    hoursLoaded: (state, action) => {
      state.items = action.payload;
      state.isEmpty = Boolean(!action.payload || action.payload.length === 0);
      state.isInitialized = true;
      state.settings = { ...state.settings, ...action.payload.settings };
      state.status = { isWorking: false, error: null };
    },
    itemQueryLoaded: (state, action) => {
      const { key, items } = action.payload;
      state.itemQueries[key] = items;
      state.status = { isWorking: false, error: null };
    },
    clearQuery: (state, action) => {
      state.itemQueries[action.payload] = null;
    },
    checkedForTimer: (state) => {
      state.lastCheckForTimer = moment().unix().toString();
    },
    timerStarted: (state, action) => {
      state.current = action.payload; //might be null if there's no running timer
      state.lastCheckForTimer = moment().unix().toString();
    },
    timerUpdated: (state, action) => {
      const item = { ...state.current, ...action.payload };
      if (!item.stopDate) {
        state.current = item;
        state.lastCheckForTimer = moment().unix().toString();
      }
      else {
        state.current = null;
        if (!state.items) state.items = [item];
        else state.items.push(item);
        state.isEmpty = false;
      }
    },
    timerStopped: (state, action) => {
      const item = { ...state.current, ...action.payload };
      state.current = null;
      if (!!action.payload) {   //will be null if they're just canceling the timer
        if (!state.items) state.items = [item];
        else state.items.push(item);
      }
      state.isEmpty = false;
    },
    itemCreated: (state, action) => {
      const item = action.payload;
      if (!state.items) state.items = [item];
      else state.items.push(item);
      state.isEmpty = false;
    },
    itemsCreated: (state, action: PayloadAction<ITimer[]>) => {
      if (!state.items) state.items = action.payload;
      else state.items.push(...action.payload);
      state.isEmpty = false;
      state.status.isWorking = false;
      state.status.error = null;
    },
    itemUpdated: (state, action) => {
      const existing = state.items?.find(item => item.id === action.payload.id) as any;
      if (!!existing) {
        _.keys(action.payload.changes).forEach(key => {
          existing[key] = action.payload.changes[key];
        });
      }
    },
    itemDeleted: (state, action) => {
      const existing = state.items?.find(item => item.id === action.payload.id);
      if (!!existing && state.items) {
        state.items = state.items?.filter(i => i.id !== existing.id);
        state.isEmpty = (state.items.length === 0);
      }
    },
    itemsUpdated: (state, action) => {
      if(state.items && action.payload && action.payload.length){
        action.payload.forEach((upd: ItemUpdate<ITimer>) => {
          const existing = state.items?.find(item => item.id === upd.id) as any;
          if (!!existing) {
            _.keys(upd.updates).forEach(key => {
              existing[key] = upd.updates[key];
            });
          }
        });
      }
    },
    recalibrated: (state, action) => {
      state.status = {isWorking: false, error: action.payload?.error};
    },
    imported: (state, action) => {
      state.status = {isWorking: false, error: action.payload?.error || null};
    },
    clearItems: (state) => {
      state.current = initialState.current;
      state.items = initialState.items;
      state.isEmpty = initialState.isEmpty;
      state.lastCheckForTimer = initialState.lastCheckForTimer;
      state.itemQueries = initialState.itemQueries;
    },
    
  },
});

export const { 
  statusChanged, 
  updateTimesheetSettings, 
  clearStore, 
  clearItems,
  hoursLoaded, 
  checkedForTimer,
  timerStarted, 
  timerUpdated, 
  timerStopped, 
  itemCreated,
  itemsCreated,
  itemUpdated, 
  itemDeleted, 
  itemsUpdated,
  recalibrated, 
  itemQueryLoaded, 
  clearQuery,
  imported,
} = slice.actions;

export default slice.reducer;

//====== ACTIONS ======