import React from "react";
import { Link, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import PersonalizeIcon from '@material-ui/icons/AssignmentIndOutlined';
import { useProjects, useProfile, useDialogs } from "utils/hooks";
import { IconMenu } from "components";
import { IconNames } from "utils/common-classes";
import HeroWidget from "./hero-widget";
import AllRevenueWidget from "./all-revenue-widget";
import ProjectsWidget from "./projects-widget";
import TopClientsWidget from "./top-clients-widget";
import { generalStyles } from "utils/styles";
import GoalsWidget from "./ui/goals-widget";

const buildStyles = makeStyles((theme) => ({
  ...generalStyles(theme),
  root: {
  },
  title: {
    fontSize: 22,
    fontWeight: 500,
    textAlign: "center",
  },
  titleIcon: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
  }
}));

const AnalyticsView = () => {
  const classes = buildStyles();
  const history = useHistory();
  const {openDialog} = useDialogs();
  const profile = useProfile();
  const projects = useProjects();
  
  const menuClicked = (action: string) => async () => {
    switch(action){
      case "dash-settings": openDialog("dashSettings"); break;
      case "app-settings": history.push("/profile/settings"); break;
      default: console.log("no handler for action:", action); break;
    }
  }

  const settingMenuItems = React.useMemo(() => {
    const items = [
      { id: 10, label: "Application Settings", icon: <Icon style={{marginLeft: 8}}>{IconNames.settings}</Icon>, action: menuClicked("app-settings")},
      { id: 20, label: "Dashboard Settings", icon: <Icon style={{marginLeft: 8}}>{IconNames.dashboard}</Icon>, action: menuClicked("dash-settings")},
    ];
    return items;
  }, []);

  return (
    <Grid id="dashboard-view" container justify="center" className={classes.root}>
      <Grid container>
        <Grid item xs={10} container justify="flex-start" alignItems="center" className={classes.mbl}>
          <Typography color="primary" className={classes.title}>Welcome{profile?.displayName ? `, ${profile.displayName}` : " to GigOps"}</Typography>
          {!profile?.displayName && 
            <Tooltip title="Personalize GigOps by adding your name" placement="top">
              <Link to="/profile" className={classes.titleIcon}><PersonalizeIcon fontSize="small" color="disabled"/></Link>
            </Tooltip>
          }
        </Grid>
        <Grid item xs={2} container justify="flex-end" alignItems="center" className={classes.mbl}>
          <Tooltip title="settings" placement="top-start">
            <span>
              <IconMenu size="small" icon={<Icon color="disabled" fontSize="small">settings</Icon>} items={settingMenuItems} />
            </span>
          </Tooltip>
          </Grid>

        {!!projects &&
          <Grid container spacing={2}>
            <Grid item xs={12} container>
              <HeroWidget />
            </Grid>
            <GoalsWidget />                
            <Grid item lg={4} md={6} sm={6} xs={12}>
              <AllRevenueWidget />
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={12}>
              <TopClientsWidget />
            </Grid>
            <Grid item lg={4} md={6} sm={8} xs={12}>
              <ProjectsWidget />
            </Grid>
          </Grid>
        }
      </Grid>
    </Grid>
  );
};

export default AnalyticsView;
