import React, { useMemo, useState } from "react";
import clsx from "clsx";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Icon from '@material-ui/core/Icon';
import { generalStyles } from "utils/styles";
import { useClient, useIsMobile, useLocalization } from "utils/hooks";
import ClientProjectsList from "../client-projects-list";
import { InvoicesListCard } from "features/invoices";
import { HoursListCard } from "features/timesheet";
import ClientStats from "./client-stats";
import ClientGoalsView from "./client-goals-view";
import ItemNotesList from "../../notes/item-notes-list";
import { IMenuItem, LFunc } from "types";
import { ChipMenu } from "components";


const buildStyles   = makeStyles(theme => ({
  ...generalStyles(theme),
  root  : {
    marginTop: theme.spacing(-0.5),
    marginRight: theme.spacing(-4),
    width: `calc(100% + ${theme.spacing(1)}px)`,   
  },
  tabs: {
    marginBottom: theme.spacing(1),
    "& .MuiTab-root": {
      minWidth: "unset",
    }
  },
  headerRow: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`, //`
    height: theme.spacing(9),
    [theme.breakpoints.down("xs")]: {
      margin: 0,
      width: "100%",
      height: "unset",
    }
  },
  childPaper: {
    border: "unset",
    background: "transparent",
    boxShadow: "none",
  },
  childToolbar: {
    background: theme.palette.common.white,    
  },
  childBody: {
  },
  hidden: {
    display: "none",
  }
}));

const createTabs = (L: LFunc): IMenuItem[] => {
   return [
    {id: 0, label: "Notes & Tasks", action: 0 },
    {id: 10, label: L("projects", true), action: 1 },
    {id: 20, label: L("hours", true), action: 2 },
    {id: 30, label: L("invoices", true), action: 3 },
    {id: 40, label: "Goals & Rating", action: 4 },  
  ];
}

const ClientContent = () => {
  const classes   = buildStyles();
  const { clientId } = useParams<{ clientId: string }>();
  const client = useClient(clientId);
  const isMobile = useIsMobile();
  const { L } = useLocalization();
  const tabs = useMemo(() => createTabs(L), []);
  const [tab, setTab] = useState(0);
  
  const onViewChange = (item: IMenuItem) => {
    setTab(item.action as number);    
  }

  const childClasses = {
    paper: classes.childPaper, 
    toolbar: classes.childToolbar, 
    title: classes.hidden, 
    body: classes.childBody
  };

  return (
    <Grid container className={classes.root}>

      <Grid container className={classes.headerRow}>
        <ClientStats />
      </Grid>
      
      {isMobile && 
        <Grid container justify="center" className={clsx(classes.mb, classes.mt)}>
          <ChipMenu 
            label={tabs[tab].label || ""} 
            icon={<Icon fontSize="small">arrow_drop_down</Icon>} 
            size="small" 
            items={tabs} 
            title="Project Views" 
            variant="outlined" 
            onClick={onViewChange} 
            isSelected={(item: IMenuItem) => item.action === tab}
          />
        </Grid>
      }
      {!isMobile && 
        <Grid container className={clsx(classes.padX, classes.borderBottom)}>
          <Tabs scrollButtons="on" value={tab} onChange={(e, i) => setTab(i)} className={classes.tabs}>
            {tabs.map(tab => <Tab key={tab.id} label={tab.label} />)}
          </Tabs>
        </Grid>
        }
      
      <Grid container>
        {(tab === 0 && client) && 
          <ItemNotesList itemType="client" itemId={client.id} isWorking={false} classes={childClasses} />
        }
        {(tab === 1 && client) && 
          <ClientProjectsList client={client} isWorking={false} classes={childClasses} />
        }
        {(tab === 2 && client) && 
          <HoursListCard clientId={client.id} classes={childClasses} />
        }
        {(tab === 3 && client) &&
          <InvoicesListCard queryKey="client-invoices" clientId={client.id} isWorking={false} classes={childClasses} />
        }
        {(tab === 4 && client) &&
          <ClientGoalsView client={client} isWorking={false} />
        }
      </Grid>
    </Grid>
  );
}

export default ClientContent;
