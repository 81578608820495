import React, { useState } from "react";
import clsx from "clsx";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { generalStyles } from "utils/styles";
import { useProject, useLocalization } from "utils/hooks";

import { InvoicesListCard } from "features/invoices";
import { HoursListCard } from "features/timesheet";
import ItemNotesList from "features/notes/item-notes-list";
import ProjectStats from "./project-stats";


const buildStyles   = makeStyles(theme => ({
  ...generalStyles(theme),
  root  : {
    // padding     : theme.spacing(1),
  },
  headerRow: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`, //`
    height: theme.spacing(9),
    [theme.breakpoints.down("xs")]: {
      margin: 0,
      width: "100%",
      height: "unset",
    }
  },  
  tabs: {
    marginBottom: theme.spacing(1),
    "& .MuiTab-root": {
      minWidth: "unset",
    }
  },
  childPaper: {
    border: "unset",
    background: "transparent",
    boxShadow: "none",
  },
  childToolbar: {
    background: theme.palette.common.white,    
  },
  childBody: {
  },
  hidden: {
    display: "none",
  }
}));

const ProjectContent = () => {
  const classes   = buildStyles();
  const { projectId } = useParams<{ projectId: string }>();
  const project = useProject(projectId);

  const [tab, setTab] = useState(0);
  const { L } = useLocalization();

  if(!project) return null;
    
  const childClasses = {
    paper: classes.childPaper, 
    toolbar: classes.childToolbar, 
    title: classes.hidden, 
    body: classes.childBody
  };

  return (
    <Grid container className={classes.root}>
      <Grid container className={classes.headerRow}>
        <ProjectStats />
      </Grid>
      <Grid container className={clsx(classes.padX, classes.borderBottom)}>
        <Tabs value={tab} onChange={(e, i) => setTab(i)} className={classes.tabs}>
          <Tab label="Notes &amp; Tasks" />
          <Tab label="Hours"/>
          <Tab label="Invoices" />
        </Tabs>
      </Grid>
      <Grid container>
        {tab === 0 && <ItemNotesList itemType="project" itemId={project.id} isWorking={false} classes={childClasses} />}
        {tab === 1 && <HoursListCard projectId={project.id} classes={childClasses} />}
        {tab === 2 && <InvoicesListCard projectId={project.id} queryKey={`prj-${project?.id}`} classes={childClasses} />}
      </Grid>
        
    </Grid>
  );
}

export default ProjectContent;
