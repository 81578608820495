import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from "@material-ui/core/Typography";
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import { cardStyles, fontStyles, generalStyles } from "utils/styles";
import { ITask } from "./infra/task-types";
import { formatDate } from "utils/date-helpers";
import { SeparatorDot } from "components";
import { IconNames, Icons } from "utils/common-classes";
import { useBoolState, useHover, useSnackbar } from "utils/hooks";
import { useDispatch } from "react-redux";
import { deleteTask, updateTask } from "./infra/task-actions";
import { toggleEditTask } from "./infra/tasks-slice";

const buildStyles   = makeStyles(theme => ({
  ...generalStyles(theme),
  ...cardStyles(theme),
  ...fontStyles(theme),
  task: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`, //`
    width: "100%",
  },
  taskCard: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`, //`
  },
  contentCol: {
    marginLeft: theme.spacing(-2),
  },
  primaryRow: {
    height: 45,
  },
  propsRow: {
    marginLeft: theme.spacing(3),
  },
  deleteStyle: {
    background: theme.palette.error.light,
    borderColor: theme.palette.error.main,
  },
  deleteConfirm: {
    // background: theme.palette.error.main,
    color: theme.palette.error.dark,
    borderRadius: 4,
  },
  hoverStyle: {
    background: `${theme.palette.secondary.light}11`, //`
  }
}));

interface ControlProps {
  task: ITask;
}

const TaskViewCard = ({task}: ControlProps) => {
  const classes   = buildStyles();
  const dispatch = useDispatch();
  const notify = useSnackbar();
  const [hoverRef, isHovered] = useHover();
  const [isDeleting, toggleDelete] = useBoolState(false);

  const onDelete = async () => {
    await dispatch(deleteTask(task.id));
    notify("Task successfully deleted", "info");
  }

  const onEdit = async () => {
    await dispatch(toggleEditTask(task.id));
  }

  const onFinished = async () => {
    await dispatch(updateTask(task.id, {isFinished: !Boolean(task.isFinished)}));
  }

  return (
    <Grid ref={hoverRef} container>
      <Grid className={clsx(classes.task, {[classes.deleteStyle]: isDeleting, [classes.hoverStyle]: (isHovered && !isDeleting)})}>
        <Grid container justify="flex-start" className={classes.taskCard}>

          <Grid item md={isDeleting ? 8 : 9} container alignItems="center" justify="flex-start" wrap="nowrap" className={classes.contentCol}>
            <Checkbox checked={task.isFinished} onChange={onFinished} disabled={isDeleting} inputProps={{ 'aria-label': 'primary checkbox' }} />              
            <Typography className={clsx(classes.bodyText, classes.preWrap)}>{task.content}</Typography>
          </Grid>
          {!isDeleting &&
            <>
              <Grid item md={1} container alignItems="center" justify="flex-end">
                <Tooltip title="Due Date">
                  <Typography className={clsx(classes.bodyTextSmall, classes.boldish)}>{formatDate(task.dueDate)}</Typography>
                </Tooltip>
              </Grid>

              <Grid item md={2} container alignItems="center" justify="flex-end" className={classes.primaryRow}>
                {isHovered &&
                  <>
                    <IconButton onClick={onEdit} title="Edit Task">{Icons.edit()}</IconButton>
                    <IconButton onClick={toggleDelete} title="Delete Task">{Icons.delete()}</IconButton>
                  </>
                }
              </Grid>
            </>
          }
          {isDeleting && 
            <Grid item md={4} container alignItems="center" justify="flex-end" className={classes.primaryRow}>
              <Grid container alignItems="center" justify="flex-end" spacing={1} className={classes.deleteConfirm}>
                <Typography className={clsx(classes.bodyTextSmall, classes.bold)}>Delete this task?</Typography>
                <Button onClick={onDelete} size="small" className={classes.marginLeft}>Yes</Button>
                <Button onClick={toggleDelete} size="small" className={classes.marginLeft}>No</Button>
              </Grid>
            </Grid>
          }

          <Grid item md={12} container alignItems="center" justify="space-between" className={classes.propsRow}>
            <Grid item md={8} container alignItems="center">
              <Typography className={classes.caption}>
                {task.clientId && <Tooltip title="Client"><Icon fontSize="small" className={classes.marginRightDense}>{IconNames.client}</Icon></Tooltip>}{task.client?.name}
              </Typography>
              {task.projectId && <SeparatorDot />}
              <Typography className={classes.caption}>
                {task.projectId && <Tooltip title="Project"><Icon fontSize="small" className={classes.marginRightDense}>{IconNames.project}</Icon></Tooltip>}{task.project?.name}
              </Typography>

            </Grid>

            
          </Grid>
          
        </Grid>
      </Grid>
    </Grid>
  );
}

export default TaskViewCard;
