import React, { useEffect, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from '@material-ui/core/TextField';
import Typography from "@material-ui/core/Typography";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "types";
import { useInputs, useOrg } from "utils/hooks";
import { formStyles } from "utils/styles";
import { updateOrg, updateProfile } from "features/app/app-slice";
import OnboardingHeader from "./onboarding-header";
import { IStepProps } from "./onboarding-view";

const buildStyles   = makeStyles(theme => ({
  ...formStyles(theme),
  root  : {
    padding     : theme.spacing(1),
    paddingBottom: theme.spacing(2),
    // borderBottom: `1px solid ${theme.palette.grey[300]}`, //`
    marginBottom: theme.spacing(2),
  },
  emphasis: {
    fontSize: 18,
    fontWeight: 500,
    textAlign: "center",
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(0.5),
  },
  
}));

interface IOnbProfile{
  usrName: string;
  coName: string;
}

const OnboardingProfile = ({onNavStateChange}: IStepProps) => {
  const classes   = buildStyles();
  const dispatch = useDispatch();
  const isOnboarding = useSelector((state: RootState) => state.app.isOnboarding);
  const profile = useSelector((s: RootState) => s.app.profile);
  const org = useOrg(true);
  const defaultValues = useMemo(() => { return {usrName: profile?.displayName || "", coName: org.name || ""}; }, [profile, org]);
  const [values, display, errors, binding] = useInputs<IOnbProfile>(defaultValues);

  useEffect(() => {
    onNavStateChange({canPrevious: false, canNext: true, nextTitle: "Next", previousTitle: "Previous", nextOverride: null});
  }, []);

  useEffect(() => {
    //Should only be triggered on blur
    if(profile && values.usrName !== profile.displayName){
      const changes = {displayName: values.usrName };
      dispatch(updateProfile(changes));
    }
    else if(values.coName !== org.name){
      const changes = {name: values.coName };
      dispatch(updateOrg(changes));
    }
  }, [values, profile, org]);

  return (
    <Grid id="onboarding-profile" container justify="center" className={classes.root}>
      <OnboardingHeader />
      <Typography variant="h3" className={classes.emphasis}>Allow us to custmoize your GigOps experience:</Typography>

      <Grid item xs={12} sm={7} className={classes.inputGridDense} container>
        <TextField id="usrName" value={display?.usrName} label="Your Name" disabled={isOnboarding} variant="outlined" fullWidth margin="dense" {...binding.input} {...errors["usrName"]} autoComplete="off" />
      </Grid>
      <Grid item xs={12} sm={7} className={classes.inputGridDense} container>
        <TextField id="coName" value={display?.coName} label="Your Business Name" disabled={isOnboarding} variant="outlined" fullWidth margin="dense" {...binding.input} {...errors["coName"]} autoComplete="off" />
      </Grid>
      <Grid item xs={12} sm={7} className={classes.inputGridDense} container>
        <TextField id="address" label="Your Business Address" variant="outlined" fullWidth margin="dense" value={display?.address} {...binding.input} {...errors["address"]} multiline rows={4} rowsMax={6} />
      </Grid>
    </Grid>
  );
}

export default OnboardingProfile;
