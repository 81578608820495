// import AuthService from '../components/api-authorization/AuthorizeService';
import _ from 'lodash';

//========================================
// let _authToken      = null;
const getHeaders    = async (withBody = false) => {
  // if(withAuth && !authToken){
  //     _authToken  = await AuthService.getAccessToken();
  // }

  const headers: Record<string, unknown> = {};
  // if(withAuth && authToken) headers["Authorization"] = `Bearer ${authToken}`;
  if(withBody){
    headers["Accept"] = "application/json";
    headers["Content-Type"] = "application/json";
  }

  return headers;
}

const handleResponse = async (response: any) => {
  try {
    // const result  = await fetch(url, { method: method, body: body });
    if(response.status >= 200 && response.status < 300){
      if(response.json){
        const json    = await response.json();

        if (!response.ok) {
          return {
            isError   : true,
            status    : response.status,
            message   : response.statusText,
            ...json
          };
        }
      
        return json;  //all good, just return the result
      }
      else if(response.ok === true){
        //Nothing to return, but all is good
        return response;
      }
    }
    else{
      return {
        isError   : true,
        status    : response.status,
        message   : "Failed to fetch.  Our technical team has been notified.",
      };
    }
  }
  catch(err){
    let errMsg  = "Unhandled exception handling fetch response. Our technical team has been notified.";
    if(err.text) errMsg    = await err.text();

    return {
      isError   : true,
      message   : errMsg
    };
  } 
};

export const doFetch = async (url: string, verb = "GET", fetchHeaders: Record<string, unknown> | null, body: Record<string, unknown> | null = null) => {
  const bodyHeaders = await getHeaders(Boolean(body));
  const headers = {...fetchHeaders, ...bodyHeaders};
  const options: Record<string, unknown> = {
    method: verb,
    headers: headers,
    // credentials : 'include',        
  };
  if (body) {
      options.body = JSON.stringify(body);
  }

  try{
    const request = new Request(url, options);
    const response = await fetch(request);
    return await handleResponse(response);
  }
  catch(err){
    let errMsg  = "Unknown error fetching.";
    if(err.text) errMsg    = await err.text();

    return {
      isError   : true,
      message   : errMsg
    };
  } 
}

// export const doUnsecureFetch = async (url: string, verb = "GET", body: Record<string, unknown> | null = null) => {
//   const options: Record<string, unknown> = {
//       method: verb,
//       headers: getHeaders(false, Boolean(body)),
//       // credentials : 'include',        
//   };
//   if (body) {
//       options.body = JSON.stringify(body);
//   }

//   try{
//     const request = new Request(url, options);
//     const response = await fetch(request);
//     return await handleResponse(response);
//   }
//   catch(err){
//     let errMsg  = "Unknown error fetching.";
//     if(err.text) errMsg    = await err.text();

//     return {
//       isError   : true,
//       message   : errMsg
//     };
//   } 
// }