import React, { useCallback, useMemo, useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import LinearProgress from '@material-ui/core/LinearProgress';
import { IProject } from "types";
import { useInterval } from "utils/hooks";
import { getDuration } from "../infra/timesheet-helpers";
import { getRoute } from "features/app";
import { DisplayTime, DisplayFraction, DisplayStart } from "../timer-card";
import { formatDate } from "utils/date-helpers";

const buildStyles = makeStyles(theme => ({
  activeGrid: {
    // background: theme.palette.primary.dark,
  },
  dateText: {
    fontSize: 16,
    fontWeight: 200,
    color: `${theme.palette.primary.contrastText}8`, //`,
    marginTop: theme.spacing(-2),
  },
  projectGrid: {
    marginBottom: theme.spacing(2),
    "& p": {
      fontSize: 20,
      fontWeight: 300,
      color: `${theme.palette.primary.contrastText}8`, //`,
    }
  },
  innerGrid: {
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(3),
  },
  iconButton: {
    color: `${theme.palette.primary.contrastText}c`,
    border: `2px solid ${theme.palette.primary.contrastText}6`, //`
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
    "&:hover": {
      border: `2px solid ${theme.palette.primary.contrastText}`, //`
      color: theme.palette.primary.contrastText,
    }
  },
  smallIconButton: {
    color: `${theme.palette.primary.contrastText}c`,
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
    "&:hover": {
      color: theme.palette.primary.contrastText,
    }
  },
  progressGrid: {

  },
  progress: {
    width: "100%",
    height: 5,
  },
  link: {
    fontSize: 20,
    fontWeight: 300,
    color: `${theme.palette.primary.contrastText}8`, //`,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
    "& p": {
      textAlign: "center",
    },
  },
  stepGrid: {
    paddingTop: theme.spacing(1),
    "& .MuiIcon-root": {
      color: `${theme.palette.common.white}3`, //`
      fontSize: "0.8rem",
      margin: `${theme.spacing(0)}px ${theme.spacing(0.25)}px`, //`
    }
  }
}));

type TimerEditProps = {
  project: IProject;
  startTime: Date | string;
  onStop: (stopDate: Date) => void;
  onCancel: () => void;
  onEdit?: () => void;
}

const ExistingTimer = ({ startTime, project, onStop, onCancel, onEdit }: TimerEditProps) => {
  const classes = buildStyles();
  const [displayType, setDisplayType] = useState("time");
  const [duration, setDuration] = useState({ hours: 0, minutes: 0 });
  const startMoment = useMemo(() => { return moment(startTime); }, [startTime]);
  const [isStopping, setStopping] = useState(false);
  const urls = useMemo(() => { return { 
    client: project?.clientId ? getRoute("client", project.clientId) : "",
    project: project ? getRoute("project", project.id) : "",
  };}, [project]);

  const updateTime = useCallback(() => {
    const diff = getDuration(startMoment);
    setDuration(diff);    
  }, [startTime]);

  useInterval(updateTime, 30000, true);  

  async function stopTimer() {
    setStopping(true);
    const stopTime = new Date();
    await onStop(stopTime);
    setStopping(false);
  }

  const cycleDisplay = () => {
    switch(displayType){
      case "time": setDisplayType("fraction"); break;
      case "fraction": setDisplayType("start"); break;
      case "start": setDisplayType("time"); break;
    }
  }

  return (
    <Grid container justify="center">
      <Grid container className={classes.progressGrid}>
        <LinearProgress color="secondary" className={classes.progress} />
      </Grid>
      <Grid container className={classes.innerGrid} alignItems="flex-start" justify="center">
        
        <Grid item xs={12} container justify="center" alignItems="center">
          <Typography className={classes.dateText}>{formatDate(startTime as Date, "MMM D, YYYY")}</Typography>
        </Grid>
        
        <Grid container className={classes.projectGrid}>
          <Grid item xs={12} container justify="center" alignItems="center">
            <Link to={urls.client} className={classes.link}><Typography>{project?.client?.name}</Typography></Link>
          </Grid>
          <Grid item xs={12} container justify="center" alignItems="center">
            <Link to={urls.project} className={classes.link}><Typography>{project?.name}</Typography></Link>
          </Grid>
        </Grid>
        {(displayType === "time") && <DisplayTime startTime={startTime as Date} duration={duration} onCycle={cycleDisplay} /> }
        {(displayType === "fraction") && <DisplayFraction startTime={startTime as Date} duration={duration} onCycle={cycleDisplay} /> }
        {(displayType === "start") && <DisplayStart startTime={startTime as Date} duration={duration} onCycle={cycleDisplay} /> }
      </Grid>

      <Grid container alignItems="center">
        <Grid item xs={4} container justify="center">
          <Tooltip title="Cancel this timer">
            <IconButton onClick={onCancel} className={classes.smallIconButton} disabled={isStopping}>
              <Icon fontSize="small">cancel</Icon>
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={4} container justify="center">
          <Tooltip title="Stop this timer">
            <IconButton onClick={stopTimer} className={classes.iconButton} disabled={isStopping}>
              <Icon fontSize="large">stop</Icon>
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={4} container justify="center">
          <Tooltip title="Edit this timer">
            <IconButton onClick={onEdit} className={classes.smallIconButton} disabled={isStopping}>
              <Icon fontSize="small">edit</Icon>
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>

    </Grid>
  );
}

export default ExistingTimer;
