import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import Grid from "@material-ui/core/Grid";
import Alert from '@material-ui/lab/Alert';
import Typography from "@material-ui/core/Typography";
import { useDispatch, useSelector } from "react-redux";
import { INote, RootState } from "types";
import { loadNotes } from "./infra/note-actions";
import { cardStyles, fontStyles, generalStyles } from "utils/styles";
import NoteViewCard from "./note-view-card";
import { selectFilteredNotes } from "./infra/note-selectors";
import NotesListHeader from "./notes-list-header";
import { LoadingIndicator } from "components";
import NoteEditCard from "./note-edit-card";

const buildStyles   = makeStyles(theme => ({
  ...fontStyles(theme),
  ...generalStyles(theme),
  ...cardStyles(theme),
  root  : {
    // padding     : theme.spacing(1),
  },  
}));

interface ListProps {
  items: INote[] | null;
}

const NotesList = ({ items }: ListProps) => {
  const classes   = buildStyles();
  const { status, isInitialized, isEmpty, editingId } = useSelector((state: RootState) => state.notes);

  if(!isInitialized){
    return (<LoadingIndicator isVisible={!isInitialized || status.isWorking} message="Loading..." />);
  }

  return (
    <Grid container direction="column" className={classes.root}>

      {isEmpty && <Alert severity="info" style={{width: "100%"}}>There are no notes to display</Alert> }
      {(items?.length === 0 && !isEmpty) &&
        <Alert severity="info" style={{width: "100%"}}>No notes match your filter</Alert>
      }

      {items && !isEmpty && 
        <Grid container>
          {items?.map(note => 
            <Grid key={note.id} item xs={12}>
              {note.id === editingId ? <NoteEditCard note={note}/> : <NoteViewCard note={note} />}
            </Grid>
          )}
        </Grid>
      }      
    </Grid>
  );
}

export default NotesList;
