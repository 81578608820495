import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const buildStyles   = makeStyles(theme => ({
  wrapper: {
    margin      : theme.spacing(1),
    position    : "relative",
    display     : "inline-block",
  },
  buttonProgress: {
    color       : theme.palette.primary.main,
    position    : "absolute",
    top         : "50%",
    left        : "50%",
    marginTop   : -12,
    marginLeft  : -12,
  },
}));

function WaitButton({children, isWaiting, color = null, disabled = false, ...otherProps}){
  const classes   = buildStyles();
  color           =  color || "primary";

  return (
    <div className={classes.wrapper}>
      <Button
          {...otherProps}
          color={color || "primary"}
          disabled={isWaiting || disabled}
        >
          {children}
        </Button>
        {isWaiting && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
  )
}

export default WaitButton;

WaitButton.propTypes  = {
  isWaiting   : PropTypes.bool, 
  children    : PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  color       : PropTypes.string,
  disabled    : PropTypes.bool, 
};
