import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import IconButton from '@material-ui/core/IconButton';
import Typography from "@material-ui/core/Typography";
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import InvalidIcon from '@material-ui/icons/Warning';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import { useDropzone } from 'react-dropzone';
import { generalStyles } from 'utils/styles';
import { parse, ParsedCsv } from "utils/import-helper";
import { Exportable } from "types";
import WaitButton from "components/wait-button";
import { useClients, useProjects } from "utils/hooks";

const buildStyles   = makeStyles(theme => ({
  ...generalStyles(theme),
  root  : {
    padding: theme.spacing(2),
  },
  instructions: {
    fontSize: 18,
    fontWeight: 400,
    marginBottom: theme.spacing(1),
  },
  emphasis: {
    fontSize: 18,
    fontWeight: 400,
    color: theme.palette.secondary.main,
    marginBottom: theme.spacing(1),
  },
  dropRow: {
    minHeight: theme.spacing(8),
    margin: `${theme.spacing(2)}px ${theme.spacing(0)}px`, //`
  },
  dropZone: {
    minHeight: '9em',
    height: '100%',
    margin: `${theme.spacing(0)}px ${theme.spacing(2)}px`, //`
    border: `2px dashed ${theme.palette.primary.main}`, //`
    background: `${theme.palette.primary.light}33`, //`,
    borderRadius: 10,
    "& p": {
      fontSize: 18,
      fontWeight: 400,
    },
    "&:focus": {
      outlineColor: "transparent",
    }
  },
  dropContent: {
    height: "100%",
    width: "100%",
  },
  fileRow: {
    padding: theme.spacing(1),
    margin: `${theme.spacing(2)}px ${theme.spacing(2)}px`, //`
    border: `1px solid ${theme.palette.grey[300]}`, //`
    background: `${theme.palette.grey[300]}33`, //`
    borderRadius: 5,
  },
  fileText: {
    color: theme.palette.secondary.main,
    fontSize: 16,
    fontWeight: 300,
  },
  validChip: {
    color: theme.palette.common.white,
    border: theme.palette.success.dark,
    background: theme.palette.success.main,
    "& svg": {
      color: theme.palette.common.white,
    },
    marginRight: theme.spacing(1),    
  },
  invalidChip: {
    color: theme.palette.common.white,
    border: theme.palette.error.dark,
    background: theme.palette.error.main,
    "& svg": {
      color: theme.palette.common.white,
    },
    marginRight: theme.spacing(1),  
  }
}));

interface ImportProps {
  itemType: Exportable;
  isWorking?: boolean;
  onImport: (parsed: ParsedCsv) => void;
}

const ImportForm = ({itemType, isWorking, onImport}: ImportProps) => {
  const classes = buildStyles();
  const clients = useClients();
  const projects = useProjects();
  const [file, setFile] = useState<any>(null);
  const [fileContent, setFileContent] = useState("");
  const [isValid, setValid] = useState<boolean | null>(null);
  const [parsed, setParsed] = useState<ParsedCsv | null>(null);

  useEffect(() => {
    async function validate(content: string){
      const results = parse(itemType, content, clients, projects);
      const isValid = (!results.errors || results.errors.length === 0);
      setValid(isValid);
      setParsed(results);
    }
    if(fileContent){
      validate(fileContent);
    }
  }, [fileContent]);

  const onDrop = (files: any) => {
    const file = files[0];
    if(file){
      setFile(file) //Show the filename
      
      const reader = new FileReader();
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        const fileText = reader.result as string;
        setFileContent(fileText);
      }
      reader.readAsText(file)    
    }
  }

  const onClear = () => {
    setFile(null);
    setFileContent("");
    setValid(null);
    setParsed(null);
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({onDrop, accept: '.csv'});
  const chipStyle = isValid ? classes.validChip : classes.invalidChip;

  const doImport = async () => {
    if(parsed === null) return;
    await onImport(parsed);    
  }

  const dropZoneProps = {
    container: true, 
    alignContent:"center", 
    justify: "space-evenly", 
    direction: "column", 
    alignItems: "center", 
    className: classes.dropZone
  };

  return (
    <Grid id="import-options" container className={classes.dropRow}>
      <Grid {...getRootProps(dropZoneProps)}>
        <input {...getInputProps()} />
        {isDragActive ?
          <p>Drop the file here...</p> :
          <p>{"Drag 'n' drop a csv file here, or click to select files"}</p>
        }
        {!file ? (isDragActive ? <CloudUploadIcon fontSize="large" /> : <CloudUploadOutlinedIcon fontSize="large"/> ) : <CloudDoneIcon fontSize="large" color="primary" />}
      </Grid> 
      <Grid container className={classes.fileRow} wrap="nowrap">
        <Grid item xs={6} container alignItems="center">
          <InsertDriveFileIcon color="primary" className={classes.marginRight}/>
          <Typography className={classes.fileText}>{file ? file.name : "<no file selected>"}</Typography>
        </Grid>
        <Grid item xs={6} container spacing={2} alignItems="center" justify="flex-end">
          {isValid !== null && 
            <Tooltip title={isValid ? "This file is valid and can be imported" : "This file is not valid"}>
              <Chip className={chipStyle} variant="outlined" icon={isValid ? <CheckCircleIcon /> : <InvalidIcon fontSize="small"/>} label={isValid ? "Valid" : "Invalid"}/>
            </Tooltip>
          }
          {file && <IconButton color="default" size="small" onClick={onClear} disabled={!file || isWorking} className={classes.marginRight} title="remove this file and start over"><CloseIcon /></IconButton> }
          <WaitButton variant="outlined" color="secondary" size="small" onClick={doImport} isWaiting={isWorking} disabled={isValid !== true} title="Import the items in this file">Import</WaitButton>
        </Grid>
      </Grid>      
    </Grid>
  );
}

export default ImportForm;
