import { createSelector } from "@reduxjs/toolkit";
import { selectAllClients } from "features/app/infra/common-selectors";
import { selectAllProjects } from "features/projects";
import { orderBy } from "lodash";
import { ITask, RootState } from "types";

const _getInitialized = (state: RootState) => state.tasks.isInitialized;
const _getTasks = (state: RootState) => state.tasks.items;
const _getTaskSettings = (state: RootState) => state.tasks.settings;
const _getInputs = (state: RootState, inp1: string, inp2: string) => ({inp1, inp2});

//===
// Gets all the notes prepared with client, project and a search string.
export const selectTasks = createSelector(
  _getTasks, selectAllClients, selectAllProjects,
  (tasks, clients, projects) => {
    if(!tasks) return [];
    
    const preped = tasks.map(task => {
      const client = task.clientId ? clients.find(c => c.id === task.clientId) : null;
      const project = task.projectId ? projects.find(p => p.id === task.projectId) : null;
      const withProps = {
        ...task,
        client,
        project,
      } as ITask;

      //create a search string with relevant fields for each note
      const searchString = [task.content, task.project?.name || "", task.client?.name || ""].join(" ").toLowerCase();
      return {
        ...withProps,
        searchString,
      };

    });

    return preped;
  }
);

//===
// Gets the notes filtered based on the search / filter props of the settings in the Notes Slice.
export const selectFilteredTasks = createSelector(
  selectTasks, _getTaskSettings, _getInitialized,
  (tasks, settings, isInitialized) => {
    if(!isInitialized) return null;
    let result = tasks;

    if(settings.search){
      const searchStr = settings.search.toLowerCase();
      result = tasks.filter(tsk => tsk.searchString.indexOf(searchStr) >= 0);
    }
    else if(settings.filter?.projectId){
      result = settings.filter.projectId === "none" ? tasks.filter(tsk => !tsk.projectId) : tasks.filter(tsk => tsk.projectId === settings.filter?.projectId);
    }
    else if(settings.filter?.clientId){
      result = settings.filter.clientId === "none" ? tasks.filter(tsk => !tsk.clientId) : tasks.filter(tsk => tsk.clientId === settings.filter?.clientId);
    }

    if(!settings.filter?.status){
      result = result.filter(tsk => !tsk.isFinished);
    }
    else if(settings.filter?.status === "finished"){
      result = result.filter(tsk => tsk.isFinished === true);
    }
    //else: all = all of them

    const ordered = orderBy(result, ["dueDate", "createdDate"], ["asc", "asc"]);
    return ordered;
  }
);

//===
// Gets the notes for a specific client or project
export const selectTasksFor = createSelector(
  selectTasks, _getInputs, _getInitialized,
  (tasks, inputs, isInitialized) => {
    if(!isInitialized) return null;
    let result = tasks;

    if(inputs.inp1 === "project" && inputs.inp2){
      result = inputs.inp2 === "none" ? tasks.filter(tsk => !tsk.projectId) : tasks.filter(tsk => tsk.projectId === inputs.inp2);
    }
    else if(inputs.inp1 === "client" && inputs.inp2){
      result = inputs.inp2 === "none" ? tasks.filter(tsk => !tsk.clientId) : tasks.filter(tsk => tsk.clientId === inputs.inp2);
    }    

    const ordered = orderBy(result, ["dueDate", "createdDate"], ["desc", "asc"]);
    return ordered;
  }
);