import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from '@material-ui/core/Divider';

const buildStyles   = makeStyles(theme => ({
  root  : {
    // padding     : theme.spacing(1),
    // flexWrap: "none",
    height: "100%",
  },
  statItem: {
    flexDirection: "column",
    justifyItems: "center",
    alignItems: "center",
    alignContent: "center",
    marginRight: theme.spacing(1),
  },
  value   : {
    fontSize    : 14,
    fontWeight  : 500,
    textAlign   : "center",
    // marginBottom: theme.spacing(0.5),
  },
  label  : {
    fontSize  : 12,
    fontWeight: 400,
    textAlign : "center",
    color     : theme.palette.grey[600],
  },
  divider: {
    // height: "50%",
    margin: `${theme.spacing(1)}px ${theme.spacing(1)}px`, //`
  }
}));

interface ControlProps{
  data: any;
}

const MetricStatPanel = ({data}: ControlProps) => {
  const classes   = buildStyles();

  return (
    <Grid container justify="center" alignItems="center" className={classes.root}>
      {data.map((stat: any) => {
          return (
            <div key={stat.id}>
              <Grid item className={classes.statItem}>
                <Typography className={classes.value} color="primary">{stat.displayValue}</Typography>
                <Typography className={classes.label}>{stat.label}</Typography>
              </Grid>
              <Divider orientation="vertical" flexItem className={classes.divider}/>
            </div>
          );
        })
      }
    </Grid>
  );
}

export default MetricStatPanel;
