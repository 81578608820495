import React, { useEffect } from 'react';
import clsx from "clsx";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableHeader, { HeadCell } from "./table-header";
import { ISort, Order } from 'types';
import { stableSort, getComparator, areArraysEqual } from "./table-helpers";
import { useLocalSetting } from "utils/hooks";
import TableCheckCell from './table-check-cell';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableRoot: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
      border: `1px solid ${theme.palette.grey[300]}`, //`
    },
    table: {
      // minWidth: 750,
    },
    emptyTableRow: {
      "& td": {
        textAlign: "center",
        padding: `${theme.spacing(3)}px ${theme.spacing(0)}px`, //`
        borderBottomWidth: 0,
        // fontStyle: "italic",
        fontSize: 16,
        color: theme.palette.grey[500],
      }
    },
    bodyStyle: {

    },
    denseBodyStyle: {
      "& .MuiTableCell-body, .MuiTableCell-head" : {
        padding: `${theme.spacing(0.75)}px ${theme.spacing(1.5)}px`, //`
      },
      "& th.MuiTableCell-paddingCheckbox" : {
        padding: `${theme.spacing(0)}px ${theme.spacing(1)}px !important`, //`
      },
    }
  }),
);

interface ITableProps<T> {
  header: HeadCell<T>[];
  rows: any[];
  rowId?: string;
  RowComponent: any;
  selectable?: boolean;
  dense?: boolean;
  onSelected?: (selectedItems: string[]) => void;
  extra?: any;
  order?: ISort;
  onSort?: (order: ISort) => void;
  emptyMessage?: string;
  settingKey?: string;
  noPaging?: boolean;
  noHeader?: boolean;
  classes?: any;
  selected?: string[];
  isWorking?: boolean;
  caption?: string;
  rowsPerPageOptions?: number[];
  getSortValue?: (row: T, col: string) => any;
}

export interface RowProps<T> {
  rowIndex: number;
  item: T;
  cols: HeadCell<T>[];
  isSelected: boolean;
  onSelected: (event: React.MouseEvent<unknown>) => void;
  CheckboxCell: any;
  labelId: string;
  isWorking?: boolean;
  extra?: any;
}

export default function AtkTable<T>(tableProps: ITableProps<T>) {
  const classes = useStyles();
  const { header, rows, classes: classOverrides, isWorking, dense } = tableProps;
  const [settings, saveSettings] = useLocalSetting(tableProps.settingKey || "atk-table", { rowCount: 10 });
  const defSortCol = header.find(h => !!h.defaultSortDir) || header[0];
  const [sortDir, setOrder] = React.useState<Order>(defSortCol?.defaultSortDir || 'asc');
  const [sort, setOrderBy] = React.useState<keyof T>(defSortCol.id);
  const [mySelected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const rowsPerPageOptions = tableProps.rowsPerPageOptions ?? [5, 10, 25, 50];
  const [rowsPerPage, setRowsPerPage] = React.useState(settings.rowCount || 10);

  //Called when a user clicks the column header to initiate a sort
  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof T) => {
    if (tableProps.onSort && tableProps.order) {
      const isAsc = tableProps.order.sort === property && tableProps.order.sortDir === 'asc';
      const sortDir = isAsc ? 'desc' : 'asc';
      tableProps.onSort({ sort: property.toString(), sortDir: sortDir });
    }
    else {
      const isAsc = sort === property && sortDir === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    }
  };

  //Effect to deal with changes to the data, and reset the page to 0
  useEffect(() => { setPage(0); }, [rows]);

  //Effect to sync the sort from the props to the table sort
  useEffect(() => {
    if(!tableProps.order) return;  //no order to sync with
    setOrder(tableProps.order?.sortDir || "asc");
    const sortCol = tableProps.header.find(h => h.id === tableProps.order?.sort);
    setOrderBy(sortCol?.id || defSortCol.id);
  }, [tableProps.order]);

  //Effect to sync the selected items that are provided by the consumer
  useEffect(() => {
    if(!tableProps.selectable) return;  // table doesn't support selection
    if(areArraysEqual(tableProps.selected, mySelected)) return; //arrays are equal

    if(tableProps.selected && tableProps.selected.length > 0){
      setSelected(tableProps.selected);    
      if (tableProps.onSelected) tableProps.onSelected(tableProps.selected);
    }
    else if(mySelected && mySelected.length > 0){
      setSelected([]);
      if (tableProps.onSelected) tableProps.onSelected([]);
    }
  }, [tableProps.selected]);

  //Handles the click of the select all option with the selectable table
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(!!isWorking) return;

    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      if (tableProps.selectable && tableProps.onSelected) tableProps.onSelected(newSelecteds);
      return;
    }

    setSelected([]);
    if (tableProps.selectable && tableProps.onSelected) tableProps.onSelected([]);
  };

  //Handles the click on a row, deals with selectability
  const handleClick = (event: React.MouseEvent<unknown>, name: keyof T) => {
    if(!!isWorking) return;    
    if (!tableProps.selectable) return;

    const selectedIndex = mySelected.indexOf(name.toString());
    let newSelected: string[] = [];

    if (selectedIndex === -1) { newSelected = newSelected.concat(mySelected, name.toString()); }
    else if (selectedIndex === 0) { newSelected = newSelected.concat(mySelected.slice(1)); }
    else if (selectedIndex === mySelected.length - 1) { newSelected = newSelected.concat(mySelected.slice(0, -1)); }
    else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        mySelected.slice(0, selectedIndex),
        mySelected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
    if (tableProps.selectable && tableProps.onSelected) tableProps.onSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    if(!!isWorking) return;
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(!!isWorking) return;
    const newVal = parseInt(event.target.value, 10);
    setRowsPerPage(newVal);
    setPage(0);
    if (tableProps.settingKey) saveSettings({ rowCount: newVal });
    // if (config?.updateRowCount) config.updateRowCount(newVal)
  };

  //Function to check if a row is selected or not
  const isSelected = (name: string) => mySelected.indexOf(name) !== -1;
  const isEmpty = rows.length === 0;
  const RowComponent = tableProps.RowComponent;
  const rowKeyProp = tableProps.rowId || "id";

  //Creates the list of rows that are visible based on sort, page and page size
  const tableRows = React.useMemo(() => {
    let visibleRows = stableSort(rows, getComparator(sortDir, sort, tableProps.getSortValue));
    if(!tableProps.noPaging) visibleRows = visibleRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    // const visibleRows = stableSort(rows, getComparator(sortDir, sort, tableProps.getSortValue))
    //   .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    return visibleRows;
  }, [rows, sort, sortDir, page, rowsPerPage]);

  return (
    <div className={classes.tableRoot}>
      <Paper className={clsx(classes.paper, classOverrides?.paper)} elevation={0}>
        <TableContainer>
          
          <Table className={clsx(classes.table, classOverrides?.table)} aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'} aria-label="enhanced table">
            {tableProps.caption && <caption>{tableProps.caption}</caption>}
            {!tableProps.noHeader &&
              <TableHeader
                numSelected={mySelected.length}
                order={sortDir}
                orderBy={sort.toString()}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                headCells={header}
                selectable={tableProps.selectable}
                dense={dense}
                isWorking={isWorking}
              />
            }
            <TableBody className={dense ? classes.denseBodyStyle : classes.bodyStyle}>
              {!isEmpty && tableRows.map((row, index) => {
                const rowKey = (row as any)[rowKeyProp];
                const isItemSelected = isSelected(rowKey.toString());
                const labelId = `row-checkbox-${index}`;  //`

                const checkboxCell = tableProps.selectable ? <TableCheckCell isSelected={isItemSelected} labelId={labelId} disabled={!!isWorking} /> : null;

                return <RowComponent
                  key={rowKey}
                  rowIndex={index}
                  item={row}
                  cols={header}
                  isSelected={isItemSelected}
                  onSelected={(event: React.MouseEvent<unknown>) => handleClick(event, rowKey)}
                  CheckboxCell={checkboxCell}
                  labelId={labelId}
                  className={classOverrides?.row}
                  isWorking={isWorking}
                  extra={tableProps.extra}
                />;

              })}
              {isEmpty &&
                <TableRow className={classes.emptyTableRow}>
                  <TableCell colSpan={header.length}>
                    {tableProps.emptyMessage || "There are no items"}
                  </TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>

        {(!tableProps.noPaging && !isEmpty) &&
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={(rows.length < rowsPerPage) ? 0 : page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}            
          />
        }
      </Paper>
    </div>
  );
}
