import React from "react";
import ReactResizeDetector from "react-resize-detector";
import Chart from "react-apexcharts";

type chartType = "area" | "line" | "bar" | "pie" | "donut" | "radialBar";

type ChartPanelProps = {
  options: any;
  series: any;
  type: chartType;
  width?: number | string;
  height?: number | string;
  minWidth?: number | string;
  minHeight?: number | string;
  maxWidth?: number | string;
  maxHeight?: number | string;
  style?: Record<string, unknown>;
} 

type dimensions = {
  width: number;
  height: number;
  targetRef: any;
}

const ChartPanel = ({ options, series, type, width, height, minWidth, minHeight, maxWidth, maxHeight, style }: ChartPanelProps) => {
  // const classes = buildStyles();
  // const divRef = React.useRef(null);
  const handlers = React.useMemo(() => { return { width: !width, height: !height }; }, [width, height]);
  const panelStyle = React.useMemo(() => {
    return {
      width: width || "100%",
      height: height || "100%",
      minWidth: minWidth || undefined, //"100%",
      minHeight: minHeight || undefined, // "100%",
      maxWidth: maxWidth || undefined, // "100%",
      maxHeight: maxHeight || undefined, // "100%",
      ...style,
    };
  }, [width, height, style]);

  return (
    <div style={panelStyle}>
      <ReactResizeDetector handleWidth={handlers.width} handleHeight={handlers.height}>
        {(dims: dimensions) =>
          <div ref={dims.targetRef}>
            <Chart ref={dims.targetRef} options={options} series={series} type={type} width={width || dims.width || "100%"} height={height || dims.height || "100%"} />
          </div>
        }
      </ReactResizeDetector>
    </div>
  );
}

export default ChartPanel;

// function ChartContainer(props: ChartPanelProps) {
//   if (!props.height && !props.width) return <div />;
//   return (
//     <Chart {...props} />
//   )
// }
