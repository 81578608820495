import React from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { IMenuItem, ProjectTypeItems, AppCustomizations, roundingTypeItems } from "types";
import { getChanges } from "utils/general-helpers";
import { useSnackbar, useInputs, useTrackChanges } from "utils/hooks";
import { IconMenu } from "components";
import { cardStyles, formStyles, generalStyles } from "utils/styles";
import { updateCustomizations, customizationParsers } from "features/app/app-slice";
import { selectAllCustomizations } from "features/app";

const buildStyles = makeStyles(theme => ({
  ...formStyles(theme),
  ...cardStyles(theme),
  ...generalStyles(theme),
  instructions: {
    fontSize: 16,
    fontWeight: 300,
    marginBottom: theme.spacing(2),
  },
  sectionGrid: {
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey[300]}`, //`
  },
  sectionHeader: {
    marginBottom: theme.spacing(2),
    color: theme.palette.secondary.main,
    fontSize: 16,
    fontWeight: 400,
  },
}));

const validateCustomizations = (changes: Partial<AppCustomizations>) => {
  const empty = _.keys(changes).find((k: string) => {
    const val: any = changes[k as keyof AppCustomizations];
    return (!val || val === "");
  });

  if (empty) {
    return "All labels must have a value.";
  }
  else {
    return null;
  }
}

const ProfileSettings = () => {
  const classes = buildStyles();
  // const {L, LSwap} = useLocalization();
  const customizations = useSelector(selectAllCustomizations);
  const dispatch = useDispatch();
  const notify = useSnackbar();
  const menuItems: IMenuItem[] = [];
  const [values, display, errors, binding] = useInputs<AppCustomizations>(customizations, customizationParsers);
  const [isChanged, resetChanges] = useTrackChanges(customizations, values);

  async function saveChanges() {
    if (isChanged) {
      const diff = getChanges(customizations, values) as Partial<AppCustomizations>;
      const errors = validateCustomizations(diff);
      if (errors) {
        notify(errors, "error");
      }
      else {
        await dispatch(updateCustomizations(diff));
        resetChanges(values);
        notify("Settings saved successfully", "success");
      }
    }
  }

  return (
    <Grid container>

      <Paper className={classes.card}>
        <Grid container>

          <Grid container className={classes.cardHeader} justify="space-between" alignItems="center">
            <Grid item xs={6} container justify="flex-start" alignItems="center">
              <Typography className={classes.cardHeaderText}>Settings</Typography>
            </Grid>
            <Grid item xs={6} container justify="flex-end" alignItems="center">
              <Button color="primary" variant={isChanged ? "contained" : undefined} size="small" disabled={!isChanged} onClick={saveChanges} className={classes.actionButton}>Save</Button>
              <IconMenu icon={<MoreVertIcon />} items={menuItems} />
            </Grid>
          </Grid>

          <Grid container className={classes.cardBody} spacing={1}>

            <Grid container>
              <Typography className={classes.instructions} color="primary">
                Customize your experience by setting default values, and changing the labels that are displayed throughout the application
              </Typography>
            </Grid>

            <Grid container className={classes.sectionGrid}>
              <Grid container>
                <Typography className={classes.sectionHeader}>Defaults</Typography>
              </Grid>
              <Grid item lg={8} md={10} sm={12} className={classes.inputGrid} container>
                <Grid item sm={2} xs={4} container alignItems="center">
                  <InputLabel>Project Type</InputLabel>
                </Grid>
                <Grid item md={4} sm={5} xs={8}>
                  <TextField select value={display?.defaultProjectType} label="Standard Project Type" variant="outlined" margin="dense" fullWidth {...binding.select("defaultProjectType")}>
                    <MenuItem value={-1}>[choose]</MenuItem>
                    {ProjectTypeItems.map(opt => <MenuItem key={opt.id} value={opt.id} className={classes.option}>{opt.label}</MenuItem>)}
                  </TextField>
                </Grid>
              </Grid>
              <Grid item lg={8} md={10} sm={12} className={classes.inputGrid} container spacing={1}>
                <Grid item sm={2} xs={4} container alignItems="center">
                  <InputLabel>Time Tracking</InputLabel>
                </Grid>
                <Grid item md={4} sm={5} xs={8}>
                  <TextField id="defaultHourlyRate" variant="outlined" label="Standard Hourly Rate" margin="dense" fullWidth className={classes.textRight} value={display?.defaultHourlyRate} {...binding.input} {...errors["defaultHourlyRate"]} autoComplete="off" />
                </Grid>
                <Grid item md={4} sm={5} xs={12}>
                  <FormControl fullWidth variant="outlined" margin="dense">
                    <InputLabel id="rounding-label" htmlFor="roundType">Rounding</InputLabel>
                    <Select id="defaultRounding" labelId="rounding-label" margin="dense" label="Rounding" value={values?.defaultRounding || "none"} {...binding.select("defaultRounding")} variant="outlined" fullWidth>
                      {roundingTypeItems?.map((option: any) => <MenuItem key={option.id} value={option.id}>{option.label}</MenuItem>)}
                    </Select>
                  </FormControl>                  
                </Grid>
              </Grid>
            </Grid>

            <Grid container className={classes.sectionGrid}>
              <Grid container>
                <Typography className={classes.sectionHeader}>Labels</Typography>
              </Grid>
              <Grid item xs={12} className={classes.inputGridDense} container spacing={1}>
                <Grid item xs={2} container alignItems="center">
                  <InputLabel className={classes.inputLabel}>Dashboard</InputLabel>
                </Grid>
                <Grid item xs={5}>
                  <TextField id="dashboard" variant="outlined" margin="dense" fullWidth value={display?.dashboard} {...binding.input} {...errors["dashboard"]} autoComplete="off" />
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.inputGridDense} container spacing={1}>
                <Grid item xs={2} container alignItems="center">
                  <InputLabel className={classes.inputLabel}>Client</InputLabel>
                </Grid>
                <Grid item xs={5}>
                  <TextField id="client" label="Singular" variant="outlined" margin="dense" fullWidth value={display?.client} {...binding.input} {...errors["client"]} autoComplete="off" />
                </Grid>
                <Grid item xs={5}>
                  <TextField id="clients" label="Plural" variant="outlined" margin="dense" fullWidth value={display?.clients} {...binding.input} {...errors["clients"]} autoComplete="off" />
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.inputGridDense} container spacing={1}>
                <Grid item xs={2} container alignItems="center">
                  <InputLabel className={classes.inputLabel}>Project</InputLabel>
                </Grid>
                <Grid item xs={5}>
                  <TextField id="project" label="Singular" variant="outlined" margin="dense" fullWidth value={display?.project} {...binding.input} {...errors["project"]} autoComplete="off" />
                </Grid>
                <Grid item xs={5}>
                  <TextField id="projects" label="Plural" variant="outlined" margin="dense" fullWidth value={display?.projects} {...binding.input} {...errors["projects"]} autoComplete="off" />
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.inputGridDense} container spacing={1}>
                <Grid item xs={2} container alignItems="center">
                  <InputLabel className={classes.inputLabel}>Time Tracking</InputLabel>
                </Grid>
                <Grid item xs={5}>
                  <TextField id="timesheet" label="Timesheet" variant="outlined" margin="dense" fullWidth value={display?.timesheet} {...binding.input} {...errors["timesheet"]} autoComplete="off" />
                </Grid>
                <Grid item xs={5}>
                  <TextField id="hours" label="Hours" variant="outlined" margin="dense" fullWidth value={display?.hours} {...binding.input} {...errors["hours"]} autoComplete="off" />
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.inputGridDense} container spacing={1}>
                <Grid item xs={2} container alignItems="center">
                  <InputLabel className={classes.inputLabel}>Notes</InputLabel>
                </Grid>
                <Grid item xs={5}>
                  <TextField id="note" label="Singular" variant="outlined" margin="dense" fullWidth value={display?.note} {...binding.input} {...errors["note"]} autoComplete="off" />
                </Grid>
                <Grid item xs={5}>
                  <TextField id="notes" label="Plural" variant="outlined" margin="dense" fullWidth value={display?.notes} {...binding.input} {...errors["notes"]} autoComplete="off" />
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.inputGridDense} container spacing={1}>
                <Grid item xs={2} container alignItems="center">
                  <InputLabel className={classes.inputLabel}>Tasks</InputLabel>
                </Grid>
                <Grid item xs={5}>
                  <TextField id="task" label="Singular" variant="outlined" margin="dense" fullWidth value={display?.task} {...binding.input} {...errors["task"]} autoComplete="off" />
                </Grid>
                <Grid item xs={5}>
                  <TextField id="tasks" label="Plural" variant="outlined" margin="dense" fullWidth value={display?.tasks} {...binding.input} {...errors["tasks"]} autoComplete="off" />
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.inputGridDense} container spacing={1}>
                <Grid item xs={2} container alignItems="center">
                  <InputLabel className={classes.inputLabel}>Reports</InputLabel>
                </Grid>
                <Grid item xs={5}>
                  <TextField id="report" label="Singular" variant="outlined" margin="dense" fullWidth value={display?.report} {...binding.input} {...errors["report"]} autoComplete="off" />
                </Grid>
                <Grid item xs={5}>
                  <TextField id="reports" label="Plural" variant="outlined" margin="dense" fullWidth value={display?.reports} {...binding.input} {...errors["reports"]} autoComplete="off" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </Paper>

    </Grid>
  );
}

export default ProfileSettings;
