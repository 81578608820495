import firebase from "firebase/app";
import "firebase/firestore";
import _ from "lodash";
import { Invoice, QueryFilter } from "types";
import { COLS, STATUSES, getTimestamp, IFirestoreResult, asyncForEach, getSnapshotDoc, collectionRef, arrayResponse } from "utils/firebase";
import { prepareForDb, ensureProps } from "utils/general-helpers";

export const invoiceCollections = {
  INVOICES: "invoices",
}

export const BAD_PROPS = ["id", "uid", "client", "project", "clientName", "projectName"];
const REQ_PROPS = ["invoiceDate", "dueDate", "paidDate"];

export const invoicesApi = (db: firebase.firestore.Firestore) => ({

  // Gets the list of clients for the specified user
  getInvoices: async (orgId: string, startDate: Date | null, endDate: Date | null = null, filter: QueryFilter | QueryFilter[] | null = null): Promise<IFirestoreResult> => {
    const items: Invoice[] = [];
    const coll =  collectionRef(db, orgId, COLS.INVOICES);
    let snapshot: firebase.firestore.QuerySnapshot | null = null;

    //add the start and end dates to the filter collection if necessary
    let allFilters = filter ? (_.isArray(filter) ? filter : [filter]) : [];
    if(startDate || endDate){
      if(endDate)  allFilters = [{field: "invoiceDate", operator: "<", value: endDate}, ...allFilters];
      if(startDate) allFilters = [{field: "invoiceDate", operator: ">=", value: startDate}, ...allFilters];
    }

    if(allFilters.length){
      const f1 = allFilters[0];
      let query = coll.where(f1.field, f1.operator, f1.value)
      if(allFilters.length > 1){
        for(let i = 1; i < allFilters.length; i++){
          const f = allFilters[i];
          query = coll.where(f.field, f.operator, f.value);
        }
      }
      snapshot = await query.get();
    }
    else{
      snapshot = await coll.get();
    }    

    // const snapshot = await (!!query ? query.get() : coll.get());
    //enumerate the snapshot and assign the necessary values
    await asyncForEach(snapshot.docs, async doc => {
      const data = getSnapshotDoc(doc) as Invoice;
      items.push(data);
    });

    return arrayResponse(items, orgId);
  },

  getInvoice: async (orgId: string, invoiceId: string): Promise<IFirestoreResult> => {
    const invoiceDoc = collectionRef(db, orgId, COLS.INVOICES).doc(invoiceId);
    const invoiceRef = await invoiceDoc.get();
    const invoice = invoiceRef.data() as Invoice;
    
    return {
      ok: true,
      statusCode: STATUSES.ok,
      key: invoiceId,
      data: invoice
    };
  },

  createInvoice: async (orgId: string, model: Invoice): Promise<IFirestoreResult> => {    
    const id = (model.id === "-1" ? null : model.id) || `${getTimestamp()}-inv`; //generate an id based on the timestamp so they are ordered in the db
    let prepared = prepareForDb(model, BAD_PROPS);  //remove unnecessary props
    prepared = ensureProps(prepared, REQ_PROPS);    //ensure necessary props
    
    const invoiceDoc = db.collection(COLS.ORG).doc(orgId).collection(COLS.INVOICES).doc(id);
    await invoiceDoc.set(prepared);
    
    return {
      ok: true,
      statusCode: STATUSES.ok,
      key: id,
      data: prepared, 
    };
  },

  //---
  // Updates an existing project
  updateInvoice: async (orgId: string, invoiceId: string, changes: Partial<Invoice>): Promise<IFirestoreResult> => {
    const safeChanges = _.omit(changes, BAD_PROPS);  //remove the id property, if present
    const invoice = db.collection(COLS.ORG).doc(orgId).collection(COLS.INVOICES).doc(invoiceId);
    await invoice.update(safeChanges);

    return {
      ok: true,
      statusCode: STATUSES.ok,
      key: invoiceId
    };
  },

  deleteInvoice: async (orgId: string, invoiceId: string): Promise<IFirestoreResult> => {
    const invoiceDoc = collectionRef(db, orgId, COLS.INVOICES).doc(invoiceId);
    await invoiceDoc.delete();

    return {
      ok: true,
      statusCode: STATUSES.ok,
      key: invoiceId,      
    };
  }

  
});