import React, { useState, useMemo } from 'react';
import _ from 'lodash';
import { IInputDisplayValues, IInputError, IInputErrors, IParserCollection, UpdateValueEvent, IInputOptions } from './useInputs-types';
import { Parsers } from './useInputs-parsers';
import { Indexable, InputField } from 'types';
import { isEqual } from "date-fns";

function valMap(targetType: string): string {
  switch (targetType) {
    case "checkbox": return "checked";
    case "radio": return "checked";
    default: return "value";
  }
}

function getParser(type: string): any {
  switch (type) {
    case "number": return Parsers.int;
    case "string": return Parsers.string;
    case "boolean": return Parsers.boolean;
    default: return Parsers.ignore;    //if we don't recognize the type, ignore it
  }
}

function getInputChangeProps(e: React.ChangeEvent<any>): [string, any] {
  const key: string = e.target.id || e.currentTarget.id || e.target.name;
  const inputType = e.target.type || e.currentTarget.type;
  const valKey = valMap(inputType);
  const val: any = (e.target as any)[valKey];
  return [key, val];
}

function isSelect(e: React.ChangeEvent<any>): boolean {
  return !e.target.id && !!e.target.name;
}

//-----------
//IInputsHandler<T>
export function useInputs<T>(
  initValues: T,
  parsers?: IParserCollection | undefined,
  options: IInputOptions | undefined = { updateOn: "onBlur" }
): [T, any, IInputErrors, any, (val: T) => void, any] {

  const [errors, setErrors] = useState<IInputErrors>({});
  const [values, setValues] = useState<T>(initValues);
  const [displayValues, setDisplayValues] = useState<IInputDisplayValues | null>(null);
  const _updateOnVal = typeof (options?.updateOn) === "string" ? UpdateValueEvent[options?.updateOn || "onBlur"] : options?.updateOn || UpdateValueEvent.onBlur;

  //#region Helper Functions
  function addError(key: string, err: IInputError): void {
    const updates = {
      ...errors,
      [key]: err
    };
    setErrors(updates);
  }

  function clearError(key: string): void {
    if (errors !== null && errors[key]) {
      const updates = _.omit(errors, [key]);
      setErrors(updates);
    }
  }

  function updateDisplayValue(key: string, value: any): void {
    const updates = {
      ...displayValues,
      [key]: value
    };
    setDisplayValues(updates);
  }

  function updateValue(key: string, value: any): void {
    const updates = {
      ...values,
      [key]: value
    };
    setValues(updates);
  }

  function createDisplayValues(values: any, theParsers?: any): any {
    const propNames = Object.keys(values);
    //eslint-disable-next-line
    theParsers = theParsers || myParsers;

    const dValues = propNames.reduce((obj, key) => {
      const dispValue = theParsers[key] ? theParsers[key].format(values[key]) : values[key];
      return { ...obj, [key]: dispValue };
    }, {});

    return dValues;
    // setDisplayValues(dValues);
  }

  //#endregion

  const myParsers = useMemo<IParserCollection>((): IParserCollection => {
    //Get the parsers for each field in the values
    const propNames = initValues ? Object.keys(initValues) : [];
    const propParsers = propNames.map((pn) => {
      if (parsers && parsers[pn]) return parsers[pn];
      else {
        const pType = typeof ((initValues as any)[pn]);
        return getParser(pType);
      }
    });

    //find any parsers that aren't associated with an existing / initial field, and add them to the collection...
    if (!!parsers) {
      const parserKeys = _.keys(parsers);
      const extraParsers = parserKeys.filter(pk => propNames.indexOf(pk) < 0);
      if (extraParsers.length > 0) {
        extraParsers.forEach(extra => {
          propNames.push(extra);
          propParsers.push(parsers[extra]);
        });
      }
    }

    //Combine the names of the properties with the parsers into an object
    const theParsers = _.zipObject(propNames, propParsers);
    //Use the parsers to establish the initial displayValues
    const dValues = createDisplayValues(initValues, theParsers);
    setDisplayValues(dValues);

    return theParsers;
  }, [initValues]);

  //---
  // Handle changes to the inputs
  const onInputChange = (e: React.ChangeEvent<any>): void => {
    const [key, val] = getInputChangeProps(e);
    const parser = myParsers[key];

    try {
      const tVal = parser.parse(val);  //parse to the T version
      if (_updateOnVal === UpdateValueEvent.onChange || isSelect(e)) updateValue(key, tVal);

      //Clear out any existing error on this field if it's not currently a problem
      if (!Number.isNaN(tVal)) {
        clearError(key);
      }
    }
    catch (ex) {
      //invalid format, but leave the display value until they exit the input
    }

    const newVal: IInputDisplayValues = { ...displayValues, [key]: val };
    setDisplayValues(newVal);   //leave the display value as the entered value
  }

  const onInputChangeWithKey = (key: string) => (e: React.ChangeEvent<any>): void => {
    const [noop, val] = getInputChangeProps(e);
    const parser = myParsers[key];

    try {
      const tVal = parser.parse(val);  //parse to the T version
      if (_updateOnVal === UpdateValueEvent.onChange || isSelect(e)) updateValue(key, tVal);

      //Clear out any existing error on this field if it's not currently a problem
      if (!Number.isNaN(tVal)) {
        if(errors[key]) clearError(key);
      }
    }
    catch (ex) {
      //invalid format, but leave the display value until they exit the input
    }

    const newVal: IInputDisplayValues = { ...displayValues, [key]: val };
    setDisplayValues(newVal);   //leave the display value as the entered value
  }

  //---
  // Handle changes to an Indeterminiate checkbox
  // value of Null will map to indeterminate
  const onIndeterminateCheckChange = (key: string) => (e: React.ChangeEvent<any>): void => {
    const val = e.target.checked;
    let nextVal = val;
    const aValues = values as Indexable;
    if(aValues[key] === false){
      nextVal = null;
    }
    else if(aValues[key] === null){
      nextVal = true;
    }

    try {
      updateValue(key, nextVal);
      clearError(key);
    }
    catch (ex) {
      //invalid format, but leave the display value until they exit the input
    }

    const newVal: IInputDisplayValues = { ...displayValues, [key]: nextVal };
    setDisplayValues(newVal);   //leave the display value as the entered value
  }

  const onBlur = (e: React.FocusEvent<any>): void => {
    const [key] = getInputChangeProps(e);
    const parser = myParsers[key];       //parser for this key
    const sVal = (values as any)[key]; //current actual value in the state
    let tVal = sVal;                 //working copy
    let dVal: string = (displayValues as any)[key]; //current display value in the state

    if(!parser) console.error(`UseInputHook: property ${key} was not initialized (no parser)`);

    if (parser && (Number.isNaN(tVal) || _updateOnVal === UpdateValueEvent.onBlur)) {
      //If we're delaying updates, or it's currently NaN, need to parse the value now
      tVal = parser.parse(dVal);
    }

    //If it's different, update the state
    if (tVal !== sVal) updateValue(key, tVal);

    //If it's still NaN, need to add an error
    if (Number.isNaN(tVal)) {
      //Still NaN, so need to flag as an error
      addError(key, { error: true, helperText: parser.errorMessage || "Invalid value" });
      return;
    }

    dVal = parser?.format(tVal) || tVal; //format back to the string version, to make sure we're consistent
    updateDisplayValue(key, dVal);
  }

  const onBlurWithKey = (key: string) => (): void => {
    // const [key] = getInputChangeProps(e);
    const parser = myParsers[key];       //parser for this key
    const sVal = (values as any)[key]; //current actual value in the state
    let tVal = sVal;                 //working copy
    let dVal: string = (displayValues as any)[key]; //current display value in the state

    if(!parser) console.error(`UseInputHook: property ${key} was not initialized (no parser)`);

    if (parser && (Number.isNaN(tVal) || _updateOnVal === UpdateValueEvent.onBlur)) {
      //If we're delaying updates, or it's currently NaN, need to parse the value now
      tVal = parser.parse(dVal);
    }

    //If it's different, update the state
    if (tVal !== sVal) updateValue(key, tVal);

    //If it's still NaN, need to add an error
    if (Number.isNaN(tVal)) {
      //Still NaN, so need to flag as an error
      addError(key, { error: true, helperText: parser.errorMessage || "Invalid value" });
      return;
    }

    dVal = parser?.format(tVal) || tVal; //format back to the string version, to make sure we're consistent
    updateDisplayValue(key, dVal);
  }

  const onSelectChange = (key: string) => async (e: React.ChangeEvent<any>) => {
    const value = e.target.value || e.currentTarget.value;
    const updated = { ...values, [key]: value };
    setValues(updated);

    const newVal: IInputDisplayValues = { ...displayValues, [key]: value };
    setDisplayValues(newVal);   //leave the display value as the entered value
  }

  const onAutoCompleteChange = (key: string, idProp = "id") => async (e: React.ChangeEvent<any>, newValue: any) => {
    const value = newValue ? (idProp === null ? newValue : newValue[idProp]) : "";
    const updated = { ...values, [key]: value };
    setValues(updated);

    const newVal: IInputDisplayValues = { ...displayValues, [key]: value };
    setDisplayValues(newVal);   //leave the display value as the entered value
  }

  const onSelectKeyDown = (key: string, items: any[] | null, field: string | null) => async (e: React.KeyboardEvent<any>) => {
    if(!items) return;
    if(e.key >= "a" && e.key <= "z"){
      const nameField = field || "name";
      const char = e.key;
      const first = items.find(p => p[nameField].toLowerCase().startsWith(char));
      if(first){
        setValues({...values, [key]: first.id});
      }
    } 
  }

  const onDateInputChange = (key: string) => async (newValue: Date | null, isError: boolean, field: Partial<InputField<Date>>) => {
    if(isError){
      if(!errors[key]) addError(key, { error: true, helperText: field.bindings?.helperText || "invalid value"});
      return;
    }
    else{
      if(errors[key]) clearError(key);
      const nextValue = (field.isEmpty ? null : newValue) as Date;
      const currValue = (values as Indexable)[key] as Date;
      if(!newValue && !currValue) return;
      else if(!isEqual(nextValue, currValue)){
        const updated = {...values, [key]: nextValue || ""};
        setValues(updated);
      }
    }
  }

  const onSliderChange = (key: string) => async (event: any, newValue: number | number[]) => {
    const value = newValue;
    const updated = { ...values, [key]: value };
    setValues(updated);

    const newDisplay: IInputDisplayValues = { ...displayValues, [key]: value.toString() };
    setDisplayValues(newDisplay);   //leave the display value as the entered value
  }

  //Override the setValues function so we can update display values too...
  function setValuesOverride(toSet: T): void {
    //Pick out any of the newly assigned values that have changed
    const oToSet = (toSet as unknown) as Record<string, unknown>;
    const changes = _.pickBy(oToSet, (val: any, key: string) => {
      const aV = values as any;
      return !aV[key] || val !== aV[key];
    });

    //create an object of their display values
    const dValues = createDisplayValues(changes);

    //Now update the state
    setValues(toSet);
    setDisplayValues({ ...values, ...displayValues, ...dValues });
  }

  const bindings = {
    input: {
      onChange: onInputChange,
      onBlur: onBlur,
    },
    inputWithKey: (key: string) => ({
      onChange: onInputChangeWithKey(key),
      onBlur: onBlurWithKey(key),
    }),
    autoComplete: (key: string, idProp?: string) => ({
      onChange: onAutoCompleteChange(key, idProp),
    }),
    select: (key: string) => ({
      onChange: onSelectChange(key),
    }),
    selectContainer: (key: string, items: any[] | null = null, nameField: string | null = null) => ({
      onKeyDown: onSelectKeyDown(key, items, nameField)
    }),
    indeterminateCheck: (key: string) => ({
      onChange: onIndeterminateCheckChange(key),
    }),
    dateInput: (key: string) => ({
      onDateChange: onDateInputChange(key),
    }),
    slider: (key: string) => ({
      onChange: onSliderChange(key),
    })
  };

  const handlers = {
    selectHandler: onSelectChange,
    inputHandler: onInputChange,
    dateInputHandler: onDateInputChange,
    indeterminateCheckHandler: onIndeterminateCheckChange,
  };

  //--- Return for the hook
  return [
    values,
    displayValues,
    errors,
    bindings,
    setValuesOverride,
    handlers,
  ];
}
