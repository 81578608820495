import React, { useMemo } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useDialogs, useProjects, useLocalization } from "utils/hooks";
import { isProject } from "features/projects/infra/project-helper";
import ProjectDashItem from "./project-dash-item";
import { cardStyles, fontStyles, generalStyles, layoutStyles } from "utils/styles";
import beachImage from "./beach.jpg";

const buildStyles   = makeStyles(theme => ({
  ...generalStyles(theme),
  ...fontStyles(theme),
  ...cardStyles(theme),
  ...layoutStyles(theme),
  root  : {
    padding     : 0,
  },
  beach: {
    width: 225,
    height: 225,
  },
  smallCard: {
    // maxWidth: 350,
  }
}));



const ProjectDashList = () => {
  const classes   = buildStyles();
  const { LSwap } = useLocalization();
  const { onOpenDialog } = useDialogs();
  const projects = useProjects("dueDate", "asc");
  const activeProjects = useMemo(() => projects.filter(p => isProject.active(p)), [projects]);

  return (
    <Grid id="project-dash-list" container direction="column" justify="flex-start" className={classes.root}>
      {activeProjects.map(prj => {
        return (
          <Grid key={prj.id} container className={clsx(classes.mbl, classes.fullWidth)}>
            <ProjectDashItem project={prj} />
          </Grid>
        )        
      })}
      {activeProjects.length === 0 && 
        <Grid container>
          <Paper variant="outlined" className={clsx(classes.card, classes.smallCard)}>
            <Grid container direction="column" justify="center" alignItems="center" className={classes.cardBody}>
              <Typography color="primary" className={clsx(classes.title, classes.mb)}>{LSwap("No active projects", "projects")}</Typography>
              <Button size="small" color="secondary" onClick={onOpenDialog("project", -1)} className={classes.mbl}>{LSwap("Add a project", "project")}</Button>
              <Typography className={clsx(classes.bodyText, classes.mb)}>Or, better yet...</Typography>
              <div className={clsx(classes.beach, classes.fadingCircle)}>
                <img src={beachImage} className={classes.beach} />
              </div>
              <Typography component="a" href="https://www.google.com/travel/explore" target="_blank" className={clsx(classes.bodyText, classes.mt, classes.link)}>Last Minute Flights?</Typography>
            </Grid>
          </Paper>
        </Grid>
      }
    </Grid>
  );
}

export default ProjectDashList;
