import React from 'react';
import firebase from "firebase/app";
import { IFirebaseContext, IFirebaseAuth } from "./firebase-types";
import { PROVIDERS } from "./firebase-config";
import { firebaseContext } from "./firebase-provider";

//----
//Get the Firebase info from the context
export const useFirebase = (): IFirebaseContext => {
  const ctx = React.useContext<IFirebaseContext>(firebaseContext);
  return ctx;
}

export const useAuth = (): IFirebaseContext => {
  const ctx = React.useContext<IFirebaseContext>(firebaseContext);
  return ctx;
}

//----
//Get the firebase auth info, plus the actions
export const useAuthWithActions = (): IFirebaseAuth => {
  const ctx = React.useContext<IFirebaseContext>(firebaseContext);

  function doLogin(providerName: string | null = null): void {
    console.log("TODO: add additional providers support: ", providerName);
    if (ctx.isInitialized && !ctx.isAuthenticated) {
      // const provider = PROVIDERS[providerName || "google"]();
      firebase.auth().signInWithPopup(PROVIDERS.google());
    }
  }

  function doLogout(): Promise<void> {
    if (ctx.isAuthenticated) {
      return firebase.auth().signOut();
    }
    return Promise.resolve();
  }

  async function createAccount(username: string, password: string): Promise<any> {
    if (ctx.isInitialized && !ctx.isAuthenticated) {
      try {
        const result = await firebase.auth().createUserWithEmailAndPassword(username, password);
        return result;
      }
      catch (error) {
        // console.error("Failed to create account.", error);
        return error;
      }
    }
  }

  async function doEmailLogin(username: string, password: string): Promise<any> {
    if (ctx.isInitialized && !ctx.isAuthenticated) {
      try {
        const result = await firebase.auth().signInWithEmailAndPassword(username, password);
        return result;
      }
      catch (error) {
        // console.error("Failed to login with email.", error);
        return error;
      }
    }
  }

  return {
    ...ctx,
    login: doLogin,
    logout: doLogout,
    loginWithEmail: doEmailLogin,
    createAccount: createAccount,
  };
};

