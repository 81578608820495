import { IGoal } from "types";
import { useFirestore, STATUSES } from "utils/firebase";
import { AppThunk } from "store/root-reducer";
import { getChanges, stringsToDates, datesToStrings, removeUndefined } from "utils/general-helpers";
import { statusChanged, goalsLoaded, goalAdded, goalUpdated, goalDeleted } from "./goals-slice";

//-- load goals
export const loadGoals = (): AppThunk => async (dispatch, getState) => {
  const [api, uid] = useFirestore();

  if (!uid) throw new Error("Must be logged in to load goals.");
  const app = getState().app;

  if (app.isInitialized) {
    dispatch(statusChanged({ isWorking: true, error: null }));
    const result = await api.getGoals(app.org.id);
    if (result.statusCode === STATUSES.ok || result.statusCode === STATUSES.empty) {
      const list = result.items?.map(i => datesToStrings(i)) as IGoal[];
      dispatch(goalsLoaded(list || []));
    }
    else{
      dispatch(statusChanged({ isWorking: false, error: result.error }));
    }
  }
}

//-- create a new Goal
export const createGoal = (item: IGoal): AppThunk => async (dispatch, getState) => {
  const [api, uid] = useFirestore();

  if (!uid) throw new Error("Not logged in.");
  const app = getState().app;

  if (app.isInitialized) {
    await dispatch(statusChanged({ isWorking: true, error: null }));
    const prepared = removeUndefined({ ...item, createdDate: new Date() });
    const result = await api.createGoal(app.org.id, prepared);
    if (result.statusCode === STATUSES.ok) {
      //Update the local store
      const storeGoal = {
        ...datesToStrings(prepared),
        id: result.key
      } as IGoal;

      await dispatch(goalAdded(storeGoal));

      return storeGoal;
    }
    else{
      await dispatch(statusChanged({ isWorking: false, error: result.error }));
      return null;
    }
  }
}

//-- update a goal
export const updateGoal = (goalId: string, changes: Partial<IGoal>): AppThunk => async (dispatch, getState) => {
  const [api, uid] = useFirestore();

  if (!uid) throw new Error("Not logged in.");
  const app = getState().app;

  if (app.isInitialized) {
    //get the actual changes
    const goal = getState().goals.items?.find(gl => gl.id === goalId);
    if (goal) {
      const actualChanges = getChanges(goal as any, changes as any);

      if (actualChanges !== null) {
        dispatch(statusChanged({ isWorking: true, error: null }));
        const prepared = {...stringsToDates(actualChanges), lastUpdatedDate: new Date()};
        const result = await api.updateGoal(app.org.id, goalId, prepared);

        if (result.statusCode === STATUSES.ok) {
          //Update the local store
          const toStore = datesToStrings(prepared);
          dispatch(goalUpdated({ id: goalId, changes: toStore }));
          return goalId;
        }
        else{
          dispatch(statusChanged({ isWorking: false, error: result.error }));
        }
      }
    }
  }

  return null;
}

//-- delete a goal
export const deleteGoal = (goalId: string): AppThunk => async (dispatch, getState) => {
  const [api, uid] = useFirestore();

  if (!uid) throw new Error("Not logged in.");
  const app = getState().app;

  if (app.isInitialized) {
    //get the actual changes
    const goal = getState().goals.items?.find(gl => gl.id === goalId);
    if (goal) {
      dispatch(statusChanged({ isWorking: true, error: null }));
      const result = await api.deleteGoal(app.org.id, goalId);

      if (result.statusCode === STATUSES.ok) {
        //Update the local store
        dispatch(goalDeleted(goalId));
        return goalId;
      }
      else{
        dispatch(statusChanged({ isWorking: false, error: result.error }));
      }
    }
  }

  return null;
}