import React, { useMemo } from 'react';
import _ from "lodash";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import { useDispatch, useSelector } from "react-redux";
import { IFirebaseContext, IFirebaseProfile, IFirestoreService } from "./firebase-types";
import { firebaseConfig, PROFILE_PROPS } from "./firebase-config";
import FirestoreService from "./firestore-api";
import { authChanged } from "features/app/app-slice";
import { clearStore as clearClientStore } from "features/clients/clients-slice";
import { clearStore as clearProjectStore } from "features/projects";
import { clearStore as clearInvoiceStore } from "features/invoices";
import { clearStore as clearTimesheetStore } from "features/timesheet";
import { clearStore as clearGoalsStore } from "features/goals";
import { clearStore as clearTasksStore } from "features/tasks";
import { clearStore as clearNotesStore } from "features/notes";
import { Dispatch } from 'redux';
import { datesToStrings, removeProps } from 'utils/general-helpers';
import { IProfileInfo, RootState } from 'types';
import { identifyUser, startTracking, stopTracking } from 'utils/mixpanel';


let _initialized = false;
let _db: firebase.firestore.Firestore;
let _auth: firebase.auth.Auth;
let _api: IFirestoreService;

//----
// Initializes the Firebase app
export function initializeFirebase() {
  if (!_initialized) {
    if(!firebase.apps.length){
      firebase.initializeApp(firebaseConfig);
    }
    else{
      console.warn("firebase re-initialized");
    }
    _db = firebase.firestore();
    _auth = firebase.auth();
    _api = FirestoreService(_db);
  _initialized = true;
  }
}

//----
// A react context to hold and share the state of firebase
export const firebaseContext = React.createContext<IFirebaseContext>({
  isInitialized: false,
  isAuthenticated: null,
  user: null,
  profile: null,
});

//---
// function to save the profile to the db if necessary
async function initializeProfile(profile: IFirebaseProfile): Promise<any> {

  //Don't track logins during development... is there a better place?
  if(window.location.hostname !== "localhost"){
    _api.logActivity(profile.uid, "logged-in");
  }

  //get or create the profile in the db
  let profileInfo = profile;
  const existing = await _api.getProfile(profile.uid);
  if (!existing || !existing.data) {
    const forDb = removeProps<IFirebaseProfile>(profile, ["providerData"]);
    const result = await _api.createProfile(forDb);
    profileInfo = result.data;
    _api.logActivity(profile.uid, "profile-created", { id: profile.uid });
    
    //TODO: toggle to this so we capture mixpanel profile at time of creation
    identifyUser(profile as IProfileInfo, null);
  }
  else profileInfo = existing.data;

  //get or create the org in the db
  let orgInfo = null;
  const existingOrg = await _api.getOrg(profile.uid);
  if (!existingOrg || !existingOrg.data) {
    const orgResult = await _api.createOrg(profile.uid, profile.email as string);
    orgInfo = orgResult.data;
    _api.logActivity(profile.uid, "org-created", { id: orgInfo.id });
  }
  else orgInfo = existingOrg.data;

  
  return { 
    profile: datesToStrings(profileInfo), 
    org: datesToStrings(orgInfo) 
  };
  // return existing?.data || profile;
  //TODO: Log the activity (logging in)
}

//---
// clear out the the store if the user logs out
async function clearStore(dispatch: Dispatch<any>): Promise<void> {
  dispatch(clearClientStore());
  dispatch(clearProjectStore());
  dispatch(clearInvoiceStore());
  dispatch(clearTimesheetStore());
  dispatch(clearGoalsStore());
  dispatch(clearTasksStore());
  dispatch(clearNotesStore());
}


//----
//A hook to setup the firebase watcher.  Should only be used once at the top level.
export const useFirebaseWatcher = (): IFirebaseContext => {
  const dispatch = useDispatch();

  if (_auth === null) {
    throw new Error("Firebase must be initialized before watching for auth changes.");
  }

  const [state, setState] = React.useState<IFirebaseContext>(() => {
    const user = _auth.currentUser;
    return {
      isInitialized: true,
      isAuthenticated: !!user || null,
      user
    };
  });

  async function onAuthChanged(user: firebase.User | null): Promise<void> {
    const profile = !!user ? _.pick(user, PROFILE_PROPS) as IFirebaseProfile : null;
    if(profile) profile.providerData = user?.providerData.map(pd => _.pick(pd, ["uid", "displayName", "photoUrl", "email", "phoneNumber", "providerId"]));
    
    setState({
      isInitialized: true,
      isAuthenticated: !!user,
      user,
      profile,
    });

    let payload: {profile: any; org: any} = { profile: null, org: null };

    //Check to see if we need to save this profile to the db...
    if (!!profile) payload = await initializeProfile(profile);

    //Update the redux store as well...
    const storeProfile = profile ? {...payload.profile, providerData: profile?.providerData} : null;
    const forStore = {...payload, profile: storeProfile};
    dispatch(authChanged(forStore));

    if (!profile){
      stopTracking(); //stop the tracking in Mixpanel
      clearStore(dispatch); //clear out the redux store
    } 
    else{
      startTracking(profile.uid);
      //TODO: Move this to the place where profile is created
      // so that we don't call it every time
      // (just have it here so we can capture existing users to start)
      identifyUser(storeProfile as IProfileInfo, payload.org);
    }
    
  }

  React.useEffect(() => {
    const unsub = _auth.onAuthStateChanged(onAuthChanged);
    return () => unsub();
  }, []);

  return state;
}

//----
//A hook to get the firestore api service
export const useFirestore = (): [IFirestoreService, string | null] => {
  // if(!_api || !_auth || !_auth.currentUser) throw new Error("Must be authenticated to use firestore.");
  const uid = _auth?.currentUser?.uid || null;
  return [_api, uid];
}

export const usePasswordChange = () => {
  const provider = useSelector((state: RootState) => state.app.profile?.providerData ? state.app.profile?.providerData[0] : null)
  const canChange = useMemo(() => { return provider && provider.providerId === "password"}, [provider]);

  const changePassword = async (currentPassword: string, newPassword: string) => {
    if(!canChange || (!_auth || !_auth.currentUser)) return Promise.resolve(false);
    const user = _auth.currentUser;
    let stage = "";

    try{
      //first, need to re-authenticate
      stage = "Invalid current password";
      const creds = firebase.auth.EmailAuthProvider.credential(user.email as string, currentPassword);
      const reauthed = await _auth.currentUser.reauthenticateWithCredential(creds)
      console.log("reauthenticated: ", reauthed);
      stage = "Password change failed";
      await _auth.currentUser.updatePassword(newPassword);
      return Promise.resolve(true);
    }
    catch(ex){
      console.log("error changing password during stage: ", stage, ex);
      return Promise.reject(ex)
    }
  }

  return { canChange, changePassword, providerName: provider?.providerId };
}

export const forgotPassword = async (email: string) => {
  if(!_auth) return "Not initialized.";
  else if(email){
    try{
      await _auth.sendPasswordResetEmail(email);
      return true;
    }
    catch(ex){
      console.log("forgot password:", ex);
      return ex.message || ex.toString();
    }
  }
  else{
    return "Email address missing";
  }
}