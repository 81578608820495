import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { RootState } from 'types';
import { useSelector } from 'react-redux';
import { selectGoals } from '../infra/goal-selectors';

const buildStyles   = makeStyles(theme => ({
  root  : {
    padding     : theme.spacing(1),
  },
  title   : {
    fontSize    : 22,
    fontWeight  : 500,
    textAlign   : "center",
    marginBottom: theme.spacing(1),
  },
  subTitle  : {
    fontSize  : 17,
    fontWeight: 400,
    textAlign : "center",
    marginBottom  : theme.spacing(3),
    color     : theme.palette.grey[800],
  },
}));

const startMessage = `It doesn't look like you've set any goals started.  
Congratulations for taking the first step to grow and strengthen your business.
To begin your goal-setting journey, click the button below.`;

const GoalsWelcome = () => {
  const classes   = buildStyles();
  const isInitialized = useSelector((state: RootState) => state.goals.isInitialized);
  const goals = useSelector(selectGoals);
  
  if(!isInitialized || goals.length > 0) return null;

  return (
    <Grid id="data-view" container justify="center" className={classes.root}>
      <Grid item xs={12}>        
        <Typography variant="h5" color="primary" className={classes.title}>Getting Started!</Typography>
        <Typography variant="h6" color="secondary" className={classes.subTitle}>{startMessage}</Typography>
      </Grid>
    </Grid>
  );
}

export default GoalsWelcome;