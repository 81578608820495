import { IEntity, Indexable, ISort, RoundingTypes } from "types/common-types";

export const roundingTypeItems = [
  { id: "none", label: "No rounding", },
  { id: "round15", label: "Round to 15 min", },
  { id: "round30", label: "Round to 30 min", },
  { id: "round60", label: "Round to 1 hour", },
  { id: "push15", label: "Round up to 15 min", },
  { id: "push30", label: "Round up to 30 min", },
  { id: "push60", label: "Round up to 1 hour", },
];

export interface ITimer extends IEntity {
  userId: string;
  startTime: Date | string;
  stopTime?: Date | string;
  clientId: string;
  projectId: string;
  category?: string;
  notes?: string;
  minutes?: number;
  creditMinutes?: number;
  invoiceId?: string | null;
  invoiceDate?: Date | string | null;
  paymentId?: string;
  paymentDate?: Date | string | null;
  rate?: number;
  amount?: number;
  dayEndDate?: Date | string;
  monthEndDate?: Date | string;
  dayOfWeek?: number;
  dayOfMonth?: number;
  month?: number;
  year?: number;
  roundType?: RoundingTypes; //string | null;
}

export interface ITimerGroup{
  id: string;
  minutes: number;
  creditMinutes?: number;
  notes?: string;
  items: ITimer[];  
}

export interface ITimerGroupKey{
  grouping: string;
  date: Date;
  projectId: string;
  category: string;
}

export interface TimesheetSettings extends ISort {
  range?: string | null;
  filter?: string | null;
  showDates?: boolean;
  tab?: string;
}

export interface Timing {
  value: string;
  error: string | null;
  date: moment.Moment;
  isDirty: boolean;
}
export interface Times extends Indexable {
  startTime: Timing;
  stopTime: Timing;
}

//TODO: Consider a better structure for the Timer
// that will handle indexing and querying better.  Map fields
// may simplify the query requirements
// interface TimerTest extends IEntity{
//   userId: string;
//   startTime: Date;
//   stopTime: Date;
//   for: {
//     clientId: string;
//     projectId: string;
//     category?: string;
//     rate?: number;
//     roundType?: RoundingTypes;
//   };
//   status: {
//     invoiceId?: string;
//     invoiceDate?: Date | string | null;
//     paymentId?: string;
//     paymentDate?: Date | string | null;    
//   }
// }

export enum Range {
  "thisMonth",
  "thisWeek",
  "last7",
  "last30",
  "lastMonth",
  "lastWeek",
  "thisQuarter",
  "lastQuarter",
  "thisYear",
  "lastYear",
}

export interface IRange extends Indexable {
  id: string | Range;
  label?: string;
  start: Date | null;
  end: Date | null;
}