import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import { getRoute } from "features/app";
import { cardStyles, generalStyles } from "utils/styles";
import { IGoal, IGoalDefinition } from "../infra/goal-types";
import ChartPanel from "features/dashboard/chart-panel";
import { getGoalDescription } from "../infra/goal-helpers";
import { choose } from "utils/general-helpers";
import { useBoolState, useClients, useHover, useProjects, useSnackbar } from "utils/hooks";
import { CellSize } from "types";
import { deleteGoal } from "../infra/goal-actions";

const buildStyles   = makeStyles(theme => ({
  ...cardStyles(theme),
  ...generalStyles(theme),
  root  : {
    padding     : `${theme.spacing(0.5)}px ${theme.spacing(1)}px`, //`,
  },
  title   : {
    fontSize    : 22,
    fontWeight  : 500,
    textAlign   : "center",
    marginBottom: theme.spacing(1),
  },
  subTitle  : {
    fontSize  : 17,
    fontWeight: 500,
    textAlign : "center",
    marginBottom  : theme.spacing(3),
    color     : theme.palette.grey[800],
  },
  value: {
    fontSize: "1.6em",
    fontWeight: 500,
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  description: {
    fontSize: "1.2em",
    fontWeight: 400,
    color: theme.palette.grey[700],
    textAlign: "center",
  },
  editButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  deleteButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(4.5),
  },
  deleteGrid: {
    height: 234,
    background: `${theme.palette.error.main}33`, //`
    border: `1px solid ${theme.palette.error.main}`, //`
    borderRadius: 5,
    padding: theme.spacing(2),
  },
  deleteText: {

  }
}));

type chartSize = "s" | "m" | "l";

const donutChart = (startColor: string, endColor: string, labelColor: string, props: any) =>  ({
  chart: {
    type: "radialBar",    
  },
  stroke: {
    lineCap: "round",
  },
  labels: ["Progress"],
  color: [startColor],
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: "60%",
        // background: "#293450"
      },
      track: {
        dropShadow: {
          enabled: true,
          top: 2,
          left: 0,
          blur: 4,
          opacity: 0.25
        }
      },
      dataLabels: {
        name: {
          show: false,
          // offsetY: -20,
          // color: "#fff",
          // fontSize: "14px"
        },
        value: {
          color: labelColor, //"#fff",
          offsetY: props.offsetY, //10,
          fontSize: props.fontSize, //"30px",
          show: true
        }
      },    
    },  
  },
  grid: {
    padding: {
      top: props.padding, //-20,
      bottom: props.padding, //-20,
      left: props.padding, //-20,
      right: props.padding, //-20,
    },
  },
  fill: {
    type: "gradient",
    gradient: {
      shade: "dark",
      type: "horizontal",
      gradientToColors: [endColor], //["#87D4F9"],
      stops: [0, 100],
      inverseColors: true,
    }
  },
});

export const getGoalCardSize = (size?: string): {lg: CellSize; md: CellSize; sm: CellSize; xs: CellSize} => {
  switch(size){
    case "s": return {lg: 2, md: 3, sm: 6, xs: 12};
    case "l": return {lg: 4, md: 6, sm: 12, xs: 12};
    case "m":
    default:
      return {lg: 3, md: 4, sm: 6, xs: 12};
  }
}

const getChartProps = (size?: chartSize) => {
  let val: any = {fontSize: "30px", chartHeight: 160, padding: -20, offsetY: 10,};
  switch(size){
    case "s": 
      val = {fontSize: "22px", chartHeight: 80, padding: -10, offsetY: 10}; 
      break;
    case "l": 
      val = {fontSize: "36px", chartHeight: 240, padding: -40, offsetY: 20}; 
      break;
    case "m":
    default: 
      break;
  }
  return val;
}

interface GoalProps{
  goal: IGoal;
  size?: chartSize;
  showTitle?: boolean;
}

export const GoalContent = ({goal, size, showTitle}: GoalProps) => {
  const classes   = buildStyles();
  const theme = useTheme();
  const data = goal.data;
  const chartProps = getChartProps(size);
  const valueSize = choose(size, ["s", "m", "l"], [18, 22, 26], 22);
  // const clients = useClients();
  // const projects = useProjects();
  
  // const filterVal = useMemo(() => {
  //   if(!goal || !goal.definition || !goal.definition.variables) return "";
  //   const filterVariable = goal.definition.variables.find(v => v.id === "filter" && (v.type === "project" || v.type === "client"));
  //   if(filterVariable.type === "client"){
  //     const cli = clients.find(c => c.id === goal.data.)
  //   }
  //   else if(filterVariable.type === "project"){

  //   }
  //   return "";
  // }, [goal.definition, clients, projects]);

  // const [filterVal, setFilterVal] = useState("");

  const chartData = useMemo(() => { 
    if(!data) return [0];
    else{
      const val = parseFloat((data.percent * 100).toFixed(0));  //deal with precision issues
      return [val];
    } 
  }, [data]);

  const chartOptions = donutChart(theme.palette.primary.light, theme.palette.primary.dark, theme.palette.secondary.main, chartProps);
  
  const description = getGoalDescription(goal.definition as IGoalDefinition, goal, "");

  return (
    <Grid container justify="center" alignItems="center">
      {showTitle && <Typography color="primary" className={classes.title} style={{fontSize: valueSize}}>{goal.definition?.title}</Typography>}
      <ChartPanel options={chartOptions} series={chartData} type="radialBar" height={chartProps.chartHeight} width="100%"/>
      <Grid item container direction="column" alignItems="center" justify="center" className={classes.mt}>
        {size !== "s" && <Typography className={classes.description}>{description}</Typography> }
        <Typography className={classes.value} style={{fontSize: valueSize}}>{data?.displayValue}</Typography>
      </Grid>      
    </Grid>
  );
}

const GoalCard = ({goal, size}: GoalProps) => {
  const classes   = buildStyles();
  const dispatch = useDispatch();
  const notify = useSnackbar();
  const titleSize = choose(size, ["s", "m", "l"], [18, 22, 26], 22);
  const [isDelete, toggleDelete] = useBoolState(false);
  const [hoverRef, isHovered] = useHover();

  const onDeleteGoal = async () => {
    if(isDelete){
      await dispatch(deleteGoal(goal.id));
      notify("Goal removed successfully", "success");
    }
    else toggleDelete(null);
  }

  return (
    <Paper className={classes.card}>
      <Grid container className={classes.cardHeader} justify="center" alignItems="center">
        <Typography variant="h5" color="primary" className={classes.title} style={{fontSize: titleSize}}>{goal.definition?.title}</Typography>
      </Grid>
      <Grid ref={hoverRef} container className={classes.cardBody} style={{position: "relative"}}>
        {isDelete && 
          <Grid container direction="column" alignItems="center" justify="center" className={classes.deleteGrid}>
            <Typography className={classes.description}>Are you sure you wish to delete this goal?</Typography>
            <Grid container spacing={2} justify="center" className={classes.mtl}>
              <Grid item>
                <Button color="default" onClick={toggleDelete}>Cancel</Button>
              </Grid>
              <Grid item>
                <Button color="primary" onClick={onDeleteGoal}>Delete</Button>
              </Grid>
            </Grid>
          </Grid>
        }
        {!isDelete && 
          <>
            <GoalContent goal={goal} size={size} />
            {isHovered && 
              <>
                <Tooltip title="Edit Goal">
                  <IconButton size="small" className={classes.editButton} component={Link} to={getRoute("goalsEdit", goal.id)}>
                    <Icon fontSize="small" color="disabled">edit</Icon>
                  </IconButton>          
                </Tooltip>
                <Tooltip title="Delete Goal">
                  <IconButton size="small" className={classes.deleteButton} onClick={onDeleteGoal}>
                    <Icon fontSize="small" color="disabled">delete</Icon>
                  </IconButton>          
                </Tooltip>
              </>
            }
          </>
        }
      </Grid>
    </Paper>
  );
}

export default GoalCard;
