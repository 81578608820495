import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core/styles";
import green from "@material-ui/core/colors/green";
import purple from "@material-ui/core/colors/purple";
import blue from "@material-ui/core/colors/blue";
import red from "@material-ui/core/colors/red";
import yellow from "@material-ui/core/colors/yellow";
import indigo from "@material-ui/core/colors/indigo";
import grey from "@material-ui/core/colors/grey";

const fontList = ["Muli", "Roboto", '"Helvetica"', "Arial", "sans-serif"];

//Customizations for the default Material UI Theme go here
const themeCustomizations = {
  //Add a custom node for the status colors
  palette: {
    // type     : 'light',
    primary: {
      light: indigo[200],
      main: indigo[500],
      dark: indigo[800],
      // contrastText: "#fff",
    },
    secondary: {
      light: purple[200],
      main: purple[500],
      dark: purple[700],
    },
    error: {
      light: red[50],
      main: red[300], //"#ed5565", //"#7f231c",
      dark: red[500], //"#fccac7",
      contrastText: grey[700],
    },
    warning: {
      light: yellow[100],
      main: yellow[600], //"#ed5565", //"#7f231c",
      dark: yellow[800], //"#fccac7",
      contrastText: grey[700],
    },
    info: {
      light: blue[50],
      main: blue[500], //"#ed5565", //"#7f231c",
      dark: blue[800], //"#fccac7",
      contrastText: grey[700],
    },
    success: {
      light: green[50],
      main: green[500], //"#ed5565", //"#7f231c",
      dark: green[800], //"#fccac7",
      contrastText: grey[700],
    },
  },
  typography: {
    //Override the default fonts and font weights
    fontFamily: fontList.join(","),
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    useNextVariants: true,
    suppressDeprecationWarnings: true,
    htmlFontSize: 16,
    fontSize: 14,
  },
  overrides: {
    MuiIconButton: {
      root: {
        "&:focus": {
          outline: 0,
        },
      },
    },
    MuiButton: {
      label: {
        fontSize: "1rem",
      },
      root: {
        "&:focus": {
          outline: 0,
        },
      },
      outlined: {
        "&:focus": {
          outline: 0,
        },
      },
    },
    MuiTabs: {
      root: {
        "& button:focus": {
          outline: 0,
        },
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: "unset",
      },
      input: {
        paddingTop: "10px",
        // background  : "#eee",
        background: "#0096881A !important", //#2979ff33 !important",
        borderRadius: "5px 5px 0 0 !important",
        "&$disabled": {
          background: "#fafafa !important",
        },
        // "&:focused"  : {
        //     background  : "#2979ff33",
        // }
      },
    },
    MuiTableCell: {
      body: {
        "& a": {
          textDecoration: "none",
        }
      }
    },
    MuiFormHelperText: {
      marginDense: {
        marginBottom: "-24px",
      }
    }
  },
};

//-----------------
// Creates and returns the custom theme for the Portal
export const createTheme = () => {
  return createMuiTheme(themeCustomizations);
};
