import { DialogContext, IDialogContext } from "components/dialogs/dialog-provider";
import { useContext, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { parseDialogString } from "./dialog-core";

// function parseDialogString(value: string) {
//   if (!value) return null;

//   const arr = value.split(",");
//   const items = arr.reduce((result, item) => {
//     const parts = item.split(":");
//     const val = parts.length > 0 ? tryParseInt(parts[1], parts[1]) : true;
//     return {
//       ...result,
//       [parts[0]]: val,
//     };
//   }, {});

//   return items;
// }

//Will manage the visible dialog boxes based on the URL QueryString
// query string should be in the format:
// ?dialogs={key}:{id},{key2}:{id2}...
// the dialogs structure will return it in the format:
// {key: id, key2: id2}
export function useDialogsWithUrl(defaults = null) {
  const location = useLocation();
  const urlp = new URLSearchParams(location.search);
  const dialogString = urlp.get("dialogs");
  const history = useHistory();
  const [dialogs, setDialogs] = useState<any>(
    defaults || null
  );

  function evaluateDialogs() {
    if (!dialogString) {
      if (dialogs !== null) setDialogs(null);
    } else {
      const items = parseDialogString(dialogString);
      setDialogs(items);
    }
  }

  useEffect(() => {
    evaluateDialogs();
  }, [dialogString]);

  //Will remove the specified dialog key from the query string, effectively closing the dialog
  function closeDialog(key: string) {
    if (dialogs && dialogs[key]) {
      const items = dialogString?.split(",") || [];
      const after = items.filter((i: string) => i.indexOf(key) !== 0);
      const newSearch = new URLSearchParams(location.search);
      if (after.length === 0) {
        newSearch.delete("dialogs");
        history.replace({
          pathname: location.pathname,
          search: newSearch.toString(),
        });
      } else {
        const result = after.join(",");
        newSearch.set("dialogs", result);
        history.replace({
          pathname: location.pathname,
          search: newSearch.toString(),
        });
      }
    }
  }

  //Will remove the specified dialog key from the query string, effectively closing the dialog
  function openDialog(key: string, id: any) {
    if (!dialogs || !dialogs[key]) {
      const items = (dialogString || "").split(",");
      const clean = items.filter((i) => i !== "");
      const after = [...clean, `${key}:${id}`]; //`
      const result = after.join(",");
      const newSearch = new URLSearchParams(location.search);
      newSearch.set("dialogs", result);
      history.replace({
        pathname: location.pathname,
        search: newSearch.toString(),
      });
    }
  }

  return [dialogs, openDialog, closeDialog];
}

//Making use of the dialog context provider...
export function useDialogs() {
  const ctx = useContext(DialogContext);
  if(!ctx) return {} as IDialogContext;

  return {
    dialogs: ctx.dialogs,
    setProps: ctx.setProps,
    openDialog: ctx.openDialog,
    closeDialog: ctx.closeDialog,
    onOpenDialog: ctx.onOpenDialog,
    onCloseDialog: ctx.onCloseDialog,
  };
}