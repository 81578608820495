import { tipFormatters, gradientBarFill, seriesColorByKey, TipFormats } from "utils/chart-helpers";
import { featureColors } from "utils/common-classes";

const metricXAxis = (categories: string[] | null = null) => {
  if(!categories){
    return {
      xaxis: {
        type: "datetime",
        show: false,
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tooltip: {
          enabled: false,
        }
      },
    }
  }
  else if(categories !== null) {
    return {
      xaxis: {
        categories: categories,
        show: false,
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tooltip: {
          enabled: false,
        }
      },
    }
  }
  else return {};
}

export const metricOptions = (yFormatter: any, type: "bar" | "area") => {
  const result = {
    chart: {
      id: 'metric-chart',
      type: type,
      toolbar: {
        show: false,
      },
    },
    grid: {
      show: false,
    },
    stroke: {
      width: 1,
      curve: "smooth",
    },
    dataLabels: {
      enabled: false
    },
    yaxis: {
      show: false,
    },
    tooltip: {
      x: {
        format: 'MMM yyyy'
      },
      y: {
        formatter: yFormatter, //(v: any) => v ? formatCurrency(v) : "",
      }
    },
    fill: {
      ...gradientBarFill,
    },
    legend: {
      show: false,
    }
  };

  return result;
};

export const metricChartOptions = (
  tipFormatter: "currency" | "hours" | "count" | null = null, 
  color: string | null | string[] = null, 
  type: "bar" | "area" = "bar",
  labels?: string[],
  xAxisCategories?: string[],
  padding?: any,
) => {
  const formatter = tipFormatter ? tipFormatters[tipFormatter] : null;
  const colorProp = seriesColorByKey(color); 
  const options = metricOptions(formatter, type);
  const xAxis = metricXAxis(xAxisCategories);
  const gridPadding = padding ? padding : {
    top: -20,
    bottom: -15,
    left: 0,
    right: 20,
  };

  const final = {
    ...options,
    ...xAxis,
    ...colorProp,
    grid: {
      show: false,
      padding: gridPadding
    },    
  };
  if(labels) (final as any).labels = labels;

  return final;
}

export const metricPieOptions = (labels: string[]) => {
  return {
    chart: {
      id: 'metric-chart',
      type: "pie",
    },
    legend: {
      show: false,
    },
    labels: labels,
    dataLabels: { enabled: false },
    tooltip: {
      x: {
        format: 'MMM yyyy'
      },
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 90,
        offsetY: 10
      }
    },
    grid: {
      show: false,
      padding: {
        top: -8,
        bottom: -10,
        left: 0,
        right: 0,
      }
    },
    fill: {
      type: "gradient",
    },
  };
};

export const metricDonutOptions = (labels: string[], formatter: any = undefined) => {
  return {
    chart: {
      id: 'metric-chart',
      type: "donut",
    },
    legend: {
      show: false,
    },
    labels: labels,
    dataLabels: { 
      enabled: false,
    },
    tooltip: {
      y: {
        formatter: tipFormatters[formatter],
      }
    },
    theme: {
      monochrome: {
        enabled: true,
      }
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        startAngle: -90,
        endAngle: 90,
        offsetY: 10,
        donut: {
          size: "40%",
        }
      }
    },
    grid: {
      show: false,
      padding: { top: -15, bottom: -60, left: -60, right: -60 },
    },
    fill: {
      type: "gradient",
    },
  };
};

export const metricTreemapOptions = () => {
  return {
    chart: {
      id: 'metric-chart',
      type: "treemap",
      toolbar: {
        show: false,
      },
    },
    legend: {
      show: false,
    },
    tooltip: {
    },
    grid: {
      show: false,
      padding: {
        top: -20,
        bottom: -20,
        left: -10,
        right: 20,
      }
    }
  };
};

export const metricHeatmapOptions = (color: string) => {
  const opts = {
    chart: {
      id: 'metric-heatmap',
      type: "heatmap",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      heatmap: {
        shadeIntensity: 0.75,
        useFillColorAsStroke: true,
        colorScale: {
          min: 0,
          max: 12,
        }
        
      }
    },
    colors: [featureColors[color]],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 0.5,
    },
    legend: {
      show: false,
    },
    yaxis: {
      show: false,
    },
    xaxis: {
      show: false,
      axisBorder: { show: false, },
      axisTicks: { show: false },
      tooltip: { enabled: false },
    },
    grid: {
      show: false,
      padding: {
        top: -20,
        bottom: -30,
        left: 0,
        right: 20,
      }
    },
    tooltip: {
      y: {
        formatter: (v: number) => {
          return `${v.toFixed(2)} hours`;
        },
        title: {
          formatter: (seriesName: string, props: any) => {
            return `${seriesName} ${props.dataPointIndex + 1}: `;
          },
        }
      }
    }
  };  
  return opts;
}

export const metricScatterOptions = (tipXFormatter: TipFormats = null, tipYFormatter: TipFormats = null, color: string) => {

  const xFormatter = tipXFormatter ? tipFormatters[tipXFormatter] : null;
  const yFormatter = tipYFormatter ? tipFormatters[tipYFormatter] : null;
  // const colorProp = seriesColorByKey(color); 

  const options = {
    chart: {
      type: "scatter",
      toolbar: {
        show: false,
      }
    },
    colors: [featureColors[color]],
    xaxis: {
      show: false,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      tooltip: {
        enabled: false,
      }
    },
    yaxis: {
      show: false,     
    },
    tooltip: {
      x: {
        formatter: xFormatter,
      },
      y: {
        formatter: yFormatter,
      }
    },
    grid: {
      padding: {
        top: -20,
        bottom: 0,
        right: 20,
      }
    },
  };

  return options;
}
