import React, { PropsWithChildren } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useIsMobile, useIsSidebarExpanded } from "utils/hooks";

const buildStyles   = makeStyles(theme => ({
  page  : {
    margin: theme.spacing(-2),
    marginLeft: theme.spacing(-3),
    width: `calc(100% + ${theme.spacing(5)}px)`,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      width: `calc(100% + ${theme.spacing(6)}px)`,
    }
  },
  sidebarShift: {
    left: "191px !important",
    transition: theme.transitions.create("left", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  pageSidebar: {
    zIndex: 1,
    position: "fixed",
    top: 68,
    left: 64,
    width: 260,
    height: "100%",
    borderRight: `1px solid ${theme.palette.grey[300]}`, //`
    transition: theme.transitions.create("left", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down("xs")]: {
      position: "relative",
      left: 0,
      top: 0,
      width: "100%",
      // width: `calc(100% + ${theme.spacing(0.5)}px)`,
      height: "unset",
      borderRight: "unset",
      borderBottom: `1px solid ${theme.palette.grey[300]}`, //`
    }
  },
  pageContent: {
    zIndex: 0,
    marginLeft: 260,
    width: `calc(100% - 276px)`,
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
      width: "100%",
      // width: `calc(100% + ${theme.spacing(0.5)}px)`,
      minWidth: "unset !important",
    }
  }
}));

export const PageLayout = ({children}: any) => {
  const classes   = buildStyles();

  return (
    <Grid container wrap="nowrap" className={classes.page}>
      {children}
    </Grid>
  );
}

// export default PageLayout;

interface PageSidebarProps {
  backgroundColor?: string;
}

export const PageSidebar = ({backgroundColor, children}: PropsWithChildren<PageSidebarProps>) => {
  const classes   = buildStyles();
  const theme = useTheme();
  // const isMobile = useIsMobile();
  const isSidebarExpanded = useIsSidebarExpanded();
  const style = backgroundColor ? {backgroundColor} : {backgroundColor: theme.palette.common.white};

  return (
    <Grid style={{...style}} className={clsx(classes.pageSidebar, {[classes.sidebarShift]: isSidebarExpanded})}>
      {children}
    </Grid>
  );
}

interface PageContentProps {
  minWidth?: number;
}

export const PageContent = ({minWidth, children}: PropsWithChildren<any>) => {
  const classes   = buildStyles();
  const isMobile = useIsMobile();
  const isSidebarExpanded = useIsSidebarExpanded();

  const size = minWidth ? {minWidth} : null;

  return (
    <Grid className={classes.pageContent} style={{...size}}>
      {children}
    </Grid>
  );
}