import React, { FunctionComponent, useMemo } from "react";
import Grid from '@material-ui/core/Grid';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { RowProps, HeadCell } from "components/atk-table";
import { ITimer, IProject, IClient } from "types";
import { DISPLAY_TIME_FORMAT, DISPLAY_MONTH_FORMAT, formatDate, formatTime, DISPLAY_DATE_FORMAT } from "utils/date-helpers";
import { getDuration, getDurationFromMinutes, formatDuration } from "features/timesheet";

export interface InvoicableRow extends ITimer {
  client?: IClient;
  project?: IProject;
  startMoment?: string;
  stopMoment?: string;
  duration?: string;
}

export const rowHeader = (isMultiple: boolean): HeadCell<InvoicableRow>[] => {
  const cols: HeadCell<InvoicableRow>[] = [
    { id: "id", label: "Id", isHidden: true },
    { id: "startTime", label: 'Start', padding: "default", width: 170, },
    { id: "minutes", label: "Duration", align: "right", padding: "default" },
    { id: "category", label: "Category", padding: "default" },
  ];

  if(isMultiple){
    cols.push({ id: "project", label: "Project", padding: "default" },)
  }
  cols.push({ id: "notes", label: "Notes", padding: "default" });

  return cols;
};

const InvoicableRow: FunctionComponent<RowProps<ITimer>> = ({ item, isSelected, onSelected, CheckboxCell, labelId, extra }) => {
  const myExtra = extra || {};

  const duration = React.useMemo(() => {
    let dur = null;
    if (item.minutes) {
      dur = getDurationFromMinutes(item.minutes);
    }
    else {
      dur = getDuration(item.startTime as Date, item.stopTime as Date);
    }
    return formatDuration(dur, myExtra.isTimeFractional); //`${dur.hours}:${twoChars(dur.minutes)}`;
  }, [item.startTime, item.stopTime, item.minutes, myExtra.isTimeFractional]);

  const hasCredit = Boolean(item.creditMinutes && item.creditMinutes > 0);
  const tt = item.creditMinutes ? `This item has a credit of ${item.creditMinutes} minutes`: "";

  return (
    <TableRow hover role="checkbox" aria-checked={isSelected} tabIndex={-1} selected={isSelected} onClick={onSelected}>
      {CheckboxCell}
      <TableCell component="th" id={labelId} scope="row" padding="default">
        <Grid container justify="flex-start">
          <Grid item md={6}>
            {formatDate(item.startTime)}
          </Grid>
          <Grid item md={6}>
            {formatTime(item.startTime, DISPLAY_TIME_FORMAT)}
          </Grid>
        </Grid>
      </TableCell>
      <TableCell align="right" padding="default" title={tt}>{duration}{hasCredit && "*"}</TableCell>
      <TableCell padding="default">{item.category}</TableCell>
      {extra.isMultiple && <TableCell padding="default">{item.project?.name || item.projectId}</TableCell>}
      <TableCell padding="default">{item.notes}</TableCell>      
    </TableRow>
  );
}

export default InvoicableRow;
