import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Link, useLocation, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
// import { Icons } from "utils/common-classes";
import { fontStyles, generalStyles, tabStyles } from "utils/styles";
import { getRoute, routes } from "features/app";
// import ConnectIcon from '@material-ui/icons/DeviceHub';
import { Integration, RootState } from "types";
import { fetchExternalData, loadIntegrations } from "./integration-actions";
import { LoadingIndicator } from "components";
import IntegrationItem from "./integration-item";

const buildStyles   = makeStyles(theme => ({
  ...generalStyles(theme),
  ...fontStyles(theme),
  ...tabStyles(theme),
  root  : {
    padding     : theme.spacing(1),
  },
  title   : {
    fontSize    : 22,
    fontWeight  : 500,
    marginBottom: theme.spacing(2),
  },
  section: {
    marginBottom: theme.spacing(2),
  },
}));

//https://id.getharvest.com/oauth2/authorize?client_id={CLIENT_ID}&response_type=token
const HARVEST_HOST = "https://id.getharvest.com/oauth2/authorize"
const HARVEST_CLIENT_ID = "XCd5LxM8rDRfijXNY67REtGd";
const HARVEST_STATE = `harvest`;
// const REDIRECT_URL = "http://localhost:3000/integrations/oauth_redirect";
const HARVEST_URL = `${HARVEST_HOST}?client_id=${HARVEST_CLIENT_ID}&state=${HARVEST_STATE}&response_type=token`;

const IntegrationsView = () => {
  const classes   = buildStyles();
  const dispatch = useDispatch();
  // const history = useHistory();
  const intState = useSelector((state: RootState) => state.integrations);   
  const { status, items } = intState; 
  // const loc = useLocation();
  // const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    if(intState && !intState.isInitialized){
      dispatch(loadIntegrations());
    }
  }, []);

  return (
    <Grid id="integrations-view" container className={classes.root}>
      <Grid container direction="column" alignItems="center" className={classes.mbl}>
        <Typography className={classes.pageHead} color="primary">3rd Party Integrations</Typography> 
        
        {status.isWorking && <LoadingIndicator isVisible={true} message="Working..." />}
        
        {!status.isWorking &&
          <Grid container direction="column">
            {!items || items.length === 0 && 
              <>
                <a href={HARVEST_URL}>Connect to Harvest</a>
                <Typography className={classes.infoText}>No Integrations</Typography>
              </>
            }
            {items && items.map(item => <IntegrationItem key={item.id} item={item} isWorking={status.isWorking}/>)}            
          </Grid>
        }
        
      </Grid>
      
    </Grid>
  );
}

export default IntegrationsView;