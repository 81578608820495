import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
// import Icon from '@material-ui/core/Icon';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';


const buildStyles   = makeStyles(theme => ({
  sepIcon: {
    fontSize: "0.7em",
    // margin: `${theme.spacing(0)}px ${theme.spacing(2)}px`, //`
    color: theme.palette.grey[400]
  },
  sm: {
    margin: `${theme.spacing(0)}px ${theme.spacing(0.5)}px`, //`
  },
  md: {
    margin: `${theme.spacing(0)}px ${theme.spacing(1)}px`, //`
  },
  lg: {
    margin: `${theme.spacing(0)}px ${theme.spacing(2)}px`, //`
  }
}));

interface ControlProps{
  spacing?: "sm" | "md" | "lg";
}

const SeparatorDot = ({spacing}: ControlProps) => {
  const classes   = buildStyles();

  return (
    <FiberManualRecordIcon color="disabled" fontSize="small" className={clsx([classes.sepIcon, classes[spacing || "md"]])} />
    // <Icon color="disabled" fontSize="small" className={classes.sepIcon}>fiber_manual_record</Icon>
  );
}

export default React.memo(SeparatorDot);
