import React from "react";
import { Link , useHistory } from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import AddIcon from '@material-ui/icons/AddOutlined';
import ProjectDashList from "../ui/parts/project-dash-list";
import { fontStyles, generalStyles } from "utils/styles";
import TimerCard from "features/timesheet/ui/timer-card";
import { useDialogs, useIsMobile, useLocalization, useSliceSettings, useSnackbar } from "utils/hooks";
import { ITimer, ProjectFilter, ProjectSettings } from "types";
import ProjectDashAlerts from "../ui/parts/project-dash-alerts";
import ProjectDashboardStats from "./project-dashboard-stats";
import TopClientsWidget from "../top-clients-widget";
import { formatDate } from "utils/date-helpers";

const buildStyles   = makeStyles(theme => ({
  ...generalStyles(theme),
  ...fontStyles(theme),
  root  : {
    padding     : theme.spacing(1),
  },
  statsBar: {
    margin: `${theme.spacing(1)}px ${theme.spacing(0)}px`, //`
    marginBottom: theme.spacing(2),
  },
  sideColGrid: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing(1),
      marginBottom: 0,
    }
  }
}));

const ProjectDashboard = () => {
  const classes   = buildStyles();
  const history = useHistory();
  const { LSwap } = useLocalization();
  const notify = useSnackbar();
  const { onOpenDialog } = useDialogs();
  const [, changeSettings] = useSliceSettings<ProjectSettings>("projects");
  const isMobile = useIsMobile();

  const onStatClick = (action: string) => {
    changeSettings({ filter: action as ProjectFilter });
    history.push("/projects");
  }

  const onTimerStopped = (item: ITimer) => {
    if(item){
      notify(LSwap("Your timer has been stopped, and the hours recorded.", "timer"), "success");
    }
    else{
      notify(LSwap("Timer canceled", "timer"), "info");
    }
  }

  
  return (
    <Grid id="project-dashboard" container className={classes.root} spacing={1}>
      
      <Grid item xs={12} container justify="space-between"> 
        <Typography color="secondary" className={clsx(classes.title, classes.upcase)}>Welcome to GigOps</Typography>
        {!isMobile && <Typography color="secondary" className={classes.title}>{formatDate(new Date(), "MMM D, yyyy")}</Typography>}
      </Grid>

      <Grid container className={classes.statsBar}>
        <ProjectDashboardStats onClick={onStatClick}/>
      </Grid>

      <Grid item xs={12} className={clsx(classes.mb)} container alignItems="center">        
          <Typography color="secondary" className={clsx(classes.title, classes.upcase)}>{LSwap("Active Projects", "projects")}</Typography>
          <Tooltip title={LSwap("Add a new project", "project")}>
            <IconButton onClick={onOpenDialog("project")} size="small" color="default" className={classes.marginLeft}><AddIcon fontSize="small" /></IconButton>
          </Tooltip>
          <Tooltip title={LSwap("Go to your projects", "projects")}>
            <IconButton component={Link} to="/projects" size="small" color="default" className={classes.marginLeft}><ArrowForwardIcon fontSize="small" /></IconButton>
          </Tooltip>
      </Grid>

      <Grid item md={8} sm={12} xs={12}>
        <ProjectDashList/>
      </Grid>

      <Grid item md={4} sm={12} container alignContent="flex-start" spacing={1}>
        <Grid item md={12} sm={6} xs={12} container direction="column">
          <TimerCard noFocus={true} onStopped={onTimerStopped}/>
        </Grid>
        <Grid item md={12} sm={6} xs={12}>
          <ProjectDashAlerts />
        </Grid>
        <Grid item md={12} sm={6} xs={12}>
          <TopClientsWidget variant="outlined"/>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ProjectDashboard;
