import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import MetricCard from "./metric-card";
import { useDispatch, useSelector } from "react-redux";
import { selectMetrics } from "../infra/metrics-selectors";
import { RootState } from "types";
import { updateCustomizations } from "features/app/app-slice";
import { selectAllCustomizations } from "features/app/infra/app-selectors";

const buildStyles   = makeStyles(theme => ({
  root  : {
    padding     : theme.spacing(1),
  },
  title   : {
    fontSize    : 22,
    fontWeight  : 500,
    textAlign   : "center",
    marginBottom: theme.spacing(1),
  },
  subTitle  : {
    fontSize  : 17,
    fontWeight: 500,
    textAlign : "center",
    marginBottom  : theme.spacing(3),
    color     : theme.palette.grey[800],
  },
}));

interface ControlProps{
  range: string;
  period: string;
}

const MetricsList = ({range, period}: ControlProps) => {
  const classes   = buildStyles();
  const dispatch = useDispatch();
  const { category } = useParams<{category: string}>();
  const metrics = useSelector((state: RootState) => selectMetrics(state, category || "favorites", range));
  const customizations = useSelector(selectAllCustomizations);
  const favorites = useMemo(() => customizations?.favoriteMetrics || [], [customizations]);

  const toggleFavorite = async (id: string) => {
    let updated = favorites ? [...favorites] : [];
    if(favorites?.indexOf(id) >= 0){
      updated = favorites.filter(f => f !== id);
    }
    else{
      updated.push(id);
    }

    await dispatch(updateCustomizations({favoriteMetrics: updated}));
  }

  return (
    <Grid id="metrics-list" container direction="column" className={classes.root}>
      {        
        metrics.map(m => <MetricCard key={m.id} metric={m} isFavorite={favorites?.indexOf(m.id) >= 0} category={category} onToggleFavorite={toggleFavorite} period={period}/>)
      }
    </Grid>
  );
}

export default MetricsList;
