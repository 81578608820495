import React, { useMemo, useState } from "react";
import clsx from "clsx";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import StopIcon from '@material-ui/icons/Stop';
import BackIcon from '@material-ui/icons/ArrowBack';
import { ITimer, roundingTypeItems, Timing } from "types";
import { timerParsers } from "../infra/timesheet-config";
import { DISPLAY_TIME_FORMAT, formatTime, parseTime } from "utils/date-helpers";
import { useDebouncedValue, useInputs, useProjects, useTrackChanges } from "utils/hooks";
import { fontStyles, formStyles, generalStyles } from 'utils/styles/common-styles';
import { prepareForInputs } from "utils/general-helpers";
import { selectCurrentTimer } from "../infra/timesheet-selectors";
import { useDispatch, useSelector } from "react-redux";
import { updateTimer } from "../infra/timesheet-actions";

const buildStyles = makeStyles(theme => ({
  ...generalStyles(theme),
  ...formStyles(theme),
  ...fontStyles(theme),
  rootGrid: {
    // padding: theme.spacing(2),
    height: "100%",
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  card: {
    background: theme.palette.common.white,
    width: "100%",
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.grey[300]}`, //`
  },
  inputGrid: {
    marginBottom: theme.spacing(0.5),
    // marginRight: theme.spacing(1),
  },
  noTopMargin: {
    marginTop: 0,
  },
  autoSave: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.grey[400],
  },
  notesGrid: {
    marginBottom: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),    
  },
  notesSmall: {
    "& .MuiOutlinedInput-root": {
      padding: `${theme.spacing(1.5)}px ${theme.spacing(1)}px`, //`
    },
    "& textarea": {
      fontSize: "0.8em",
      fontWeight: 300,
    }
  }
}));

interface ControlProps{
  size?: "small" | "default";
  onClose?: (andClose: boolean) => void;
}

const TimerEditor = ({size, onClose}: ControlProps) => {
  const classes = buildStyles();
  const dispatch = useDispatch();
  const projects = useProjects({sort: "name", sortDir: "asc"}); 
  const timer = useSelector(selectCurrentTimer);
  const prepedValues = useMemo(() => prepareForInputs(timer as ITimer, ["category", "notes"]), [timer]);
  const [values, display, errors, binding, setValues] = useInputs<ITimer>(prepedValues, timerParsers, { updateOn: "onChange" });
  const [isChanged, resetChanges] = useTrackChanges(timer, values);
  const [autoSaved, setAutoSaved] = useState("");
  const [times, setTimes] = useState<Timing>({
    value: formatTime(timer ? timer.startTime : new Date()),
    error: null,
    date: parseTime((timer ? timer.startTime : new Date()) as string, timer?.date),
    isDirty: false,
  });
  const isSmall = Boolean(size === "small");

  const notesDebounced = useDebouncedValue(values.notes, 3000);
  
  async function doAutoSave(newValues: Partial<ITimer> | null = null){
    if (timer && (isChanged || newValues)) {
      await dispatch(updateTimer(timer.id, values));
      setAutoSaved(`Auto-saved at ${moment().format(DISPLAY_TIME_FORMAT)}`); //`
    }
  }

  React.useEffect(() => {
    resetChanges(timer);
  }, [timer]);

  //Triggered when values.notes has changed, after a 500ms wait.
  React.useEffect(() => {
    doAutoSave();
  }, [notesDebounced]);

  async function onBlur() {
    await doAutoSave();
  }

  const onSelectChanged = (key: string) => async (e: React.ChangeEvent<any>) => {
    const value = e.target.value || e.currentTarget.value;
    
    //If they changed project id, update other associated values
    let other = null;
    if(key === "projectId"){
      const prj = projects.find(p => p.id === value);
      if(prj?.roundType){
        other = {roundType: prj.roundType};
      }
    }

    const updated = { ...values, [key]: value, ...other };
    setValues(updated);

    await doAutoSave(updated); 
  }

  const onTimeChange = (e: React.ChangeEvent<any>) => {
    const val = e.target.value as string;
    if (val !== times.value) {

      let error = null;
      if (!val || val.trim().length === 0) {
        error = `start time is required.`;  //`
        //create an invalid moment to use as the date
        setTimes({ value: val, error: error, date: moment("x"), isDirty: true } );
      }
      else{
        const parsed = parseTime(val, new Date());
        setTimes({ value: val, error: error, date: parsed, isDirty: true });
      }
    }
  }

  const onTimeBlur = async () => {
    //On blur, update the field with the parsed time value
    if (times.isDirty) {
      if (times.value !== "") {
        const parsed = parseTime(times.value, new Date());
        if(!parsed.isValid()){
          const error = `start time is invalid.`;  //`
          setTimes({ ...times, value: times.value, error: error, date: parsed });
        }
        else{
          const formatted = parsed.isValid() ? parsed.format(DISPLAY_TIME_FORMAT) : "";
          setTimes({ ...times, value: formatted, error: times.error, date: parsed });

          const updated = { ...values, startTime: parsed.toDate() };
          setValues(updated);
          await doAutoSave(updated); 
        }
      }
    }
  }

  const onSave = async (andClose = false) => {
    await doAutoSave();
    if(onClose) onClose(andClose);
  }

  return (
    <Grid id="timer-editor" container justify="center" className={classes.rootGrid}>
      <Grid item xs={12} container className={classes.inputGrid} spacing={1}>
        <Grid item xs={isSmall ? 5 : 3}>
          <TextField id="start" label="Start" fullWidth variant="outlined" margin="dense" className={clsx(classes.textCenter, classes.noTopMargin)} value={times.value} onChange={onTimeChange} error={!!times.error} helperText={times.error} onBlur={onTimeBlur} />
        </Grid>
        <Grid item xs={isSmall ? 7 : 5}>
          <FormControl fullWidth variant="outlined" margin="dense" className={classes.noTopMargin} {...binding.selectContainer("projectId", projects)}>
            <InputLabel id="project-label" htmlFor="projectId">Project</InputLabel>
            <Select id="projectId" labelId="project-label" label="Project" value={values?.projectId} onChange={onSelectChanged("projectId")} fullWidth>
              {projects.map(option => <MenuItem key={option.id} value={option.id}>{option.name} ({option.client?.name})</MenuItem>)}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={isSmall ? 12 : 4}>
          <TextField id="category" label="Category" fullWidth variant="outlined" margin="dense" className={classes.noTopMargin} value={display?.category} {...binding.input} {...errors["category"]} onBlur={onBlur} />
        </Grid>        
      </Grid>

      <Grid container className={classes.notesGrid}>
        <TextField id="notes" label="Notes" value={display?.notes} {...binding.input} {...errors["notes"]} multiline rows={isSmall ? 3 : 6} onBlur={onBlur} fullWidth variant="outlined" className={size === "small" ? classes.notesSmall : undefined} />
      </Grid>

      <Grid item xs={12} container alignItems="center" className={classes.inputGrid} spacing={1}>
        <Grid item xs={isSmall ? 6 : 4} container alignItems="center">
          <FormControl fullWidth variant="outlined" margin="dense">
            <InputLabel id="rounding-label" htmlFor="roundType">Rounding</InputLabel>
            <Select id="roundType" labelId="rounding-label" margin="dense" label="Rounding" value={values?.roundType || "none"} onChange={onSelectChanged("roundType")} variant="outlined" fullWidth>
              {roundingTypeItems?.map((option: any) => <MenuItem key={option.id} value={option.id}>{option.label}</MenuItem>)}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={isSmall ? 6 : 8} container justify="flex-end" alignItems="center">
          {!isSmall && <Typography className={classes.autoSave}>{autoSaved}</Typography>}
          {/* {isSmall && <Button onClick={onSave} size="small" variant="outlined" color="primary" className={classes.mtd}>Close</Button>} */}
          {isSmall && 
            <Grid container justify="flex-end">
              <Tooltip title="Stop timer and save changes">
                <IconButton onClick={() => onSave(true)} color="primary">
                  <StopIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Close edit view, keep timer running">
                <IconButton onClick={() => onSave(false)} color="primary">
                  <BackIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          }
        </Grid>
      </Grid>
    </Grid>
  );
}

export default TimerEditor;
