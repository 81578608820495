import _ from "lodash";
import { LabelType, LFunc, LSwapFunc } from "./localization-types";

export const defaultLabels: LabelType = {
  home: "home",
  dashboard: "dashboard",
  analytics: "analytics",
  client: "client",
  clients: "clients",
  project: "project",
  projects: "projects",
  projectStart: "started",
  projectDue: "due",
  projectDelivered: "delivered",
  projectInvoiced: "invoiced",
  projectPaid: "paid",
  timesheet: "timesheet",
  hours: "hours",
  timer: "timer",
  invoices: "invoices",
  invoice: "invoice",
  notes: "notes",
  note: "note",
  tasks: "tasks",
  task: "task",
  report: "report",
  reports: "reports",
  goal: "goal",
  goals: "goals",
  metric: "metric",
  metrics: "metrics",
  integrations: "integrations",
  integration: "integration",
}

//---
// Capitalizes the first character of a string
export function capitalize(str: string | undefined) {
  return str ? str?.charAt(0).toUpperCase() + str?.slice(1) : undefined;
}



//--
// Function to create the function that converts a value into the appropriate label
export const LFactory = (labels: LabelType): LFunc => (key: keyof LabelType, isCapitalized = false): string => {
  let val = labels[key];
  if (isCapitalized) val = capitalize(val);

  return val || "<missing>";
}

//--
// Function to create the function that converts values within a string to the appropriate label
export const LSwapFactory = (labels: LabelType): LSwapFunc => (str: string, keys?: (keyof LabelType) | (keyof LabelType)[]): string => {
  if(!keys) keys = Object.keys(defaultLabels) as (keyof LabelType)[];
  if (!_.isArray(keys)) keys = [keys];
  const result = keys.reduce((result: string, key: keyof LabelType) => {
    const replacement = labels[key];
    if (replacement !== undefined) {
      return result.replaceAll(key, replacement).replaceAll(capitalize(key) as string, capitalize(replacement) as string);
    }
    else {
      return result;
    }
  }, str);

  return result;
}