import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { IAlert } from "types";
import { generalStyles, fontStyles, statusStyles } from "utils/styles";
import { getIcon } from "utils/common-classes";
import { useHistory } from "react-router-dom";

const buildStyles   = makeStyles(theme => ({
  ...generalStyles(theme),
  ...fontStyles(theme),
  ...statusStyles(theme),
  alertRoot  : {
    padding     : theme.spacing(1),
    border: `1px solid ${theme.palette.grey[300]}`, //`
    borderLeftWidth: 4,
    borderRadius: 3,
    background: theme.palette.common.white,
    color: theme.palette.grey[700],
  },  
  navButton: {
    marginLeft: theme.spacing(0.5),
    marginTop: theme.spacing(-0.5),
    "& svg": {
      marginTop: theme.spacing(0.25),
      fontSize: "1rem",
      color: `${theme.palette.primary.main}99`, //` theme.palette.grey[500],
    }
  }
}));

interface AlertProps{
  alert: IAlert;
}

const ProjectAlertItem = ({alert}: AlertProps) => {
  const classes   = buildStyles();
  const history = useHistory();
  const icon = alert.icon ? getIcon(alert.icon, "small") : null;
  const hasAction = Boolean(alert.path || alert.onClick);

  const onClick = () => {
    if(alert.path){
      history.push(alert.path);
    }
    else if(alert.onClick){
      alert.onClick(alert);
    }
  }

  return (
    <Grid id="data-view" container justify="center" className={clsx(classes.alertRoot, classes[alert.severity || "info"])}>
      <Grid container direction="column">        
        {alert.label && 
          <Grid container justify="space-between" wrap="nowrap">
            <Grid item container alignItems="flex-start">
              <Typography className={clsx(classes.bodyTextXSmall, classes.semiBold)}>{alert.label}</Typography>
              {hasAction && 
                <Tooltip title="Click to address this alert">
                  <IconButton size="small" onClick={onClick} className={classes.navButton}><ArrowForwardIcon fontSize="small"/></IconButton>
                </Tooltip>
              }
            </Grid>
            {icon}
          </Grid>
        }
        <Typography className={clsx(classes.bodyTextXSmall)}>{alert.message}</Typography>
      </Grid>
    </Grid>
  );
}

export default React.memo(ProjectAlertItem);
