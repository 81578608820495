import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { IProject, RootState } from 'types';
import { useLocalization, useSnackbar } from 'utils/hooks';
import { AtkDialog, IDialogAction } from 'components';
import { selectCustomizations } from "features/app";
import { uploadProjects } from '../infra/project-actions';
import { newProject } from "../infra/project-helper";
import { trackCreate } from 'utils/mixpanel';
import NewProjectRow from './new-project-row';
import { removeItem, replaceItem } from 'utils/general-helpers';
import { startOfDay } from 'date-fns';

interface DialogProps {
  isOpen: any;
  onClose: (id?: number | boolean) => void;
}

const ProjectDialogMulti = (props: DialogProps) => {
  const dispatch = useDispatch();
  const { L, LSwap } = useLocalization();
  const defaults = useSelector((state: RootState) => selectCustomizations(state, ["defaultRounding", "defaultProjectType", "defaultHourlyRate", "projectCategory", "wordCount"]));
  const notify = useSnackbar();
  const today = useMemo(() => startOfDay(new Date()), []);
  const newItem = useMemo(() => ({
    ...newProject, 
    startDate: today,
    fee: defaults.defaultHourlyRate,
    type: defaults.defaultProjectType,
    roundType: defaults.defaultProjectType === "perHour" ? defaults.defaultRounding : undefined,
  }), [defaults]);

  const initRows = useMemo(() => [newItem, newItem, newItem], []);
  const [rows, setRows] = useState<Partial<IProject>[]>(initRows);

  useEffect(() => {
    console.log("re-rendered multi-project-dialog");
  }, []);
 

  const onSave = async () => {
    const items = rows.filter(r => Boolean(r.name && r.name.trim().length > 0 && r.clientId ));
    if(items.length > 0){
      await dispatch(uploadProjects(items as IProject[]));
    }
    
    notify(`${items.length} ${L("projects", true)} created successfully`);

    trackCreate("projects");
    props.onClose(true);
  }

  const dialogActions: IDialogAction[] = [
    {
      label: "Cancel",
      onClick: () => { props.onClose(); },
      color: "default",
    },
    {
      label: "Save & Close",
      onClick: onSave,
      color: "primary",
    },    
  ];

  const onAddRow = () => {
    const newRows = [...rows, {...newItem}];
    setRows(newRows);
  }

  const onRowChange = (index: number) => (values: Partial<IProject>) => {
    console.log("row values changed", index, values);
    const newRows = replaceItem(rows, rows[index], values);
    setRows(newRows);
  };

  const onRemoveRow = (index: number) => () => {
    const toRemove = rows[index];
    let newRows = removeItem(rows, toRemove);
    setRows(newRows); 
    if(newRows.length === 0) {
      newRows = [{...newItem}];
      setRows(newRows); 
    }  
  };

  return (
    <AtkDialog isOpen={Boolean(props.isOpen)} onClose={() => props.onClose()} maxWidth="lg" title={LSwap("Add multiple projects", "projects")} icon="assignment" actions={dialogActions}>
      {rows.map((r, index) => <NewProjectRow key={index} onChange={onRowChange(index)} onRemove={onRemoveRow(index)} noRemove={index === 0}/>)}  
      <Button size="small" onClick={onAddRow}>Add Row</Button>    
    </AtkDialog>
  );
}

export default ProjectDialogMulti;