import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Redirect, useLocation } from "react-router-dom";
import { fontStyles, generalStyles } from "utils/styles";
import { useDispatch, useSelector } from "react-redux";
import { Integration, RootState } from "types";
// import { updateIntegration } from "./integrations-slice";
import { addSeconds, getUnixTime } from "date-fns";
// import { dateToString } from "utils/general-helpers";
// import { REDUX_DATE_TIME_FORMAT } from "utils/date-helpers";
import { addIntegration } from "./integration-actions";
import { statusChanged } from "./integrations-slice";
import { AtkErrorDisplay, LoadingIndicator } from "components";
import { useReadyState } from "utils/hooks";
import { parseIntegration } from "./integration-helpers";

const useStyles   = makeStyles(theme => ({
  ...generalStyles(theme),
  ...fontStyles(theme),
  root  : {
    marginBottom: theme.spacing(3),
  },  
}));

const OauthRedirect = () => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const { status, isInitialized}  = useSelector((state: RootState) => state.integrations);
  const isReady = useReadyState();
  const [isParsed, setIsParsed] = useState(false);
  // const myState = useSelector((state: RootState) => state.integrations);
  // const harvestState = myState.items ? myState.items.find(i => i.key === "harvest") : null;

  console.log("Harvest Oauth Querystring: ", location.search);
      
  useEffect(() => {
    async function parseRedirect(){
      const item = parseIntegration(location.search);
      
      if(!item){
        await dispatch(statusChanged({isWorking: false, error: "Failed to parse redirect search string"}));
      }
      else{
        await dispatch(addIntegration(item));
        setIsParsed(true);      
      }
    }

    if(isReady && isInitialized && location.search){
      parseRedirect();
    }
  }, [isReady, isInitialized, location.search]);

  return (
    <Grid id="redirect-view" container justify="center" className={classes.root}>
      <LoadingIndicator isVisible={!Boolean(status.error)} message="Connecting to 3rd party..." variant="circular" />
      <AtkErrorDisplay message={status.error} />
      {isParsed && <Redirect to="/integrations" push={false} />}      
    </Grid>
  );
}

export default OauthRedirect;
