import React from "react";
import { makeStyles } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';
import { formatDate } from "utils/date-helpers";
import { fontStyles } from "utils/styles";

const buildStyles   = makeStyles(theme => ({
  ...fontStyles(theme),
}));

function DateOrAddButton(props: {value: string | Date | null | undefined; onEdit: () => void}){
  const classes   = buildStyles();
  return (
    <>
      {props.value && 
        <Typography className={classes.bodyText}>{formatDate(props.value)}</Typography>
      }
      {!props.value && 
        <Tooltip title="click to add">
          <IconButton size="small" onClick={props.onEdit}>
            <Icon fontSize="small" color="disabled">info_outlined</Icon>
          </IconButton>
        </Tooltip>
      }
    </>
  )
}

export default DateOrAddButton;