import React, { useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import Divider from '@material-ui/core/Divider';
import CloseIcon from '@material-ui/icons/Close';
import NotesIcon from '@material-ui/icons/Notes';
import Tooltip from '@material-ui/core/Tooltip';
import { cardStyles, formStyles, generalStyles } from "utils/styles";
import { IParserCollection, Parsers, useBoolState, useInputs, useLocalization, useTrackChanges } from "utils/hooks";
import { IGoal, IGoalDefinition, LabelType } from "types";
import GoalVariable from "./goal-variable";
import { getGoalDescription, getGoalDescriptionTokenized } from "../infra/goal-helpers";

const buildStyles   = makeStyles(theme => ({
  ...cardStyles(theme),
  ...formStyles(theme),
  ...generalStyles(theme),  
  goalHeader: {
    padding: "1em",
    paddingBottom: 0,
  },
  goalTitle: {
    fontSize: "1.5em",
    fontWeight: 500,
    color: theme.palette.secondary.main,
  },
  goalSubTitle: {
    fontSize: "1.2em",
    fontWeight: 300,
    marginBottom: theme.spacing(1),
    color: theme.palette.grey[700],
  },
  goalSubTitleVariable: {
    fontSize: "1.2em",
    fontWeight: 700,
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
    padding: `${theme.spacing(0)}px ${theme.spacing(0.5)}px`, //`
  },
  goalAmount: {
    "& input": {
      fontSize: 22,
      fontWeight: 600,
      textAlign: "center",
    }
  },
}));

const defaultGoal: IGoal = {
  id: "",
  definitionId: "",
  amount: 0,
  count: 0,
  filter: "",
  description: "",
  notes: "",
  showOnDash: false,
  endDate: "",
  startDate: "",
}

interface GoalProps {
  definition: IGoalDefinition;
  goal?: IGoal | null;
  showOnDash?: boolean;
  onSave: (item: IGoal) => void;
  onCancel: () => void;
}

const swapTerms: (keyof LabelType)[]  = ["client", "project", "clients", "projects"];

const SetGoal = ({definition, goal, onSave, onCancel}: GoalProps) => {
  const classes   = buildStyles();
  const {title, variables} = definition;
  const { LSwap } = useLocalization();
  const [isNotes, toggleNotes] = useBoolState(false);

  const goalParsers: IParserCollection = {
    startDate: Parsers.dateString,
    endDate: Parsers.dateString,
  }

  const myVars = useMemo(() => variables?.reduce((vOut, variable) => { return {...vOut, [variable.id]: variable.defaultValue }; }, {}), [variables]);
  const myGoal = useMemo(() => goal || {...defaultGoal, ...myVars, showOnDash: !!definition.showOnDash}, [goal]);

  const [filterDisplay, setFilterDisplay] = useState("");
  const [values, display, errors, binding, setValues] = useInputs<IGoal>(myGoal, goalParsers);
  const [isChanged] = useTrackChanges(defaultGoal, values);

  const defaultDescription = useMemo(() => {
    const defaultDesc = getGoalDescription(definition, display, filterDisplay);
    if(!defaultDesc) return "";
    return LSwap(defaultDesc, swapTerms);
  }, [display]);

  const defaultDescriptionObject = useMemo(() => {
    const [defaultDesc, swaps] = getGoalDescriptionTokenized(definition, display, filterDisplay);
    if(!defaultDesc) return <Grid container />;

    let keyCounter = 0;
    const parts: string[] = defaultDesc.split('|');
    const partsMapped = parts.map(p => {
      if(p.startsWith("~~")){
        const index = parseInt(p.substring(2));
        return <Typography key={keyCounter++} className={classes.goalSubTitleVariable}>{swaps[index]}</Typography>
      }
      else{
        return <Typography key={keyCounter++} className={classes.goalSubTitle}>{LSwap(p, swapTerms)}</Typography>
      }
    });

    return <Grid container>{partsMapped}</Grid>;
  }, [display]);

  const onClickSave = async () => {
    const model = {...values, definitionId: definition.id};
    onSave(model);
  }

  const onVariableChange = (id: string) => (value: any, display?: string) => {
    setValues({...values, [id]: value});
    if(display && id === "filter") setFilterDisplay(display);
  }

  return (
    <Paper id="add-goal" className={classes.card}>
      <Grid className={classes.goalHeader} container justify="center" direction="column">
        <Grid container justify="space-between">
          <Typography className={classes.goalTitle}>{LSwap(title, swapTerms)}</Typography>
          <Tooltip title="Cancel adding setting this goal">
            <IconButton size="small" onClick={onCancel}><CloseIcon fontSize="small"/></IconButton>
          </Tooltip>
        </Grid>
        {defaultDescriptionObject}
      </Grid>
      <form>
        <Grid container alignItems="center" justify="center" className={classes.cardBody}>
          <Grid container justify="space-around">
            {variables?.map(v => {
              return (
                <GoalVariable key={v.id} item={v} goal={goal} onChange={onVariableChange(v.id)}/>
              )
            })}
          </Grid>
          <Divider className={classes.dividerTall} />

          <Grid container alignItems="center" justify="center">
            <Grid container spacing={1}>
              <Grid item md={4} container justify="flex-end">
                  <Switch name="showOnDash" checked={values?.showOnDash} {...binding.input}/>
              </Grid>
              <Grid item md={8} container justify="flex-start">
                <Typography>Show on Dashboard</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item md={4} container justify="flex-end">
                  <IconButton size="small" onClick={toggleNotes} color={values.notes ? "primary" : "default"}><NotesIcon /></IconButton>
              </Grid>
              <Grid item md={8} container justify="flex-start">
                <Typography>{isNotes ? "Hide Details" : "Show Details"}</Typography>
              </Grid>
            </Grid>
          </Grid>

          <Divider className={classes.dividerTall} />

          {isNotes && 
            <>
              <Grid container alignItems="center" justify="center">
                <TextField name="description" value={display?.description || defaultDescription} {...binding.input} fullWidth variant="outlined" label="Description" margin="dense" className={classes.mbd}/>
                <TextField name="notes" value={display?.notes} {...binding.input} multiline rows={4} fullWidth variant="outlined" label="Notes" margin="dense"/>
              </Grid>
              <Divider className={classes.dividerTall} />
            </>
          }

          <Grid container alignItems="center" justify="center">
            <Button color="primary" variant="contained" size="small" onClick={onClickSave} disabled={!isChanged}>Save Goal</Button>
          </Grid>
        </Grid>
        
      </form>
    </Paper>
  );
}

export default SetGoal;
