import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';

interface StatProps{
  label: string;
  icon: string;
  value: string;
  elevation?: number;
  variant?: "outlined" | "elevation";
  backgroundColor?: string;
  foregroundColor?: string;
  toolTip?: string;
}

const buildStyles   = makeStyles(theme => createStyles({
  statContainer: (props: StatProps) => ({
    backgroundColor: props.backgroundColor || "white",
  }),
  statGrid  : {
    padding     : `${theme.spacing(2)}px ${theme.spacing(1)}px`, //`,
    flexWrap: "nowrap",
  },
  statIconGrid: {
    height: "100%",
  },
  statIcon : (props: StatProps) => ({
    fontSize: 48,
    fontWeight: 300,
    color: props.foregroundColor || theme.palette.primary.contrastText,
    marginRight: theme.spacing(2),
  }),
  statValue   : (props: StatProps) => ({
    fontSize    : 20,
    fontWeight  : 900,
    marginBottom: theme.spacing(0.25),
    color: props.foregroundColor || theme.palette.primary.contrastText,
  }),
  statLabel  : (props: StatProps) => ({
    fontSize  : 17,
    fontWeight: 300,
    color: `${props.foregroundColor || theme.palette.primary.contrastText}`,
    opacity: 0.8,
  }),
}));

const StatCard = (props: StatProps) => {
  const { label, icon, value } = props;
  const classes   = buildStyles(props);
  
  return (
    <Tooltip title={props.toolTip ?? props.label}>
      <Paper elevation={props.elevation} variant={props.variant} className={classes.statContainer}>
        <Grid id="stat-grid" container direction="row" className={classes.statGrid} alignItems="center">
          <Grid item container className={classes.statIconGrid} justify="center">
            {icon && <Icon className={classes.statIcon}>{icon}</Icon>}
          </Grid>
          <Grid item container direction="column" style={{flexGrow: 1}}>
            <Typography variant="h5" color="primary" className={classes.statValue}>{value}</Typography>
            <Typography variant="h6" color="secondary" className={classes.statLabel}>{label}</Typography>
          </Grid>
        </Grid>
      </Paper>
    </Tooltip>
  );
}

export default StatCard;
