import React, { memo } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { metricChartOptions } from "features/metrics/infra/metric-chart-helpers";
import ChartPanel from "features/dashboard/chart-panel";
import { formatCurrency } from "utils/number-helpers";
import { StatWithChart } from "types";
import { fontStyles } from "utils/styles";

const useStyles = makeStyles(theme => ({
  // ...generalStyles(theme),
  ...fontStyles(theme),
  root: {
  },
  statCardWithChart: {
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`, //`
    height: theme.spacing(10),    
  },
  noWrap: {
    flexWrap: "nowrap",
  },
  valueGrid: {
    height: theme.spacing(8),
    // maxWidth: "25%",
  },
  chartGrid: {
    paddingLeft: theme.spacing(2),
    flexGrow: 4,
    // maxWidth: "75%",
  },
  dangerColor: {
    color: theme.palette.error.main,
  },
  clickable: {
    cursor: "pointer",
  },
  transparentCard: {
    background: "transparent",
    borderColor: "transparent",
  }
}));

type StatFormat = "currency" | "number" | "float";

const formatStat = (amount: number, format?: StatFormat) => {
  switch(format){
    case "number": return amount;
    case "float": return amount.toFixed(2);
    default: return formatCurrency(amount, true, "$0");
  }
}

interface StatWithChartProps {
  stat: StatWithChart | null;
  onClick?: (action: string) => void;
  variant?: "default" | "transparent";
  format?: StatFormat;
}

const StatChartCard = (props: StatWithChartProps) => {
  const { stat, variant, format } = props;
  const classes = useStyles();
  if (!stat) return null;
  const chartOptions = metricChartOptions(stat.chartFormatter || "currency", stat.color || "blue", stat.chartProps?.chartType || "area", undefined, stat.chartProps?.categories, {top: -20, bottom: 0, left: 0, right: 0});
  const pointerClass = stat.action ? classes.clickable : null;

  const statClick = () => {
    if(props.onClick && stat.action) props.onClick(stat.action);
  }

  const amount = formatStat(stat.amount, format);

  return (
    <Paper  className={clsx(classes.statCardWithChart, { [classes.transparentCard]: (variant === "transparent") })} variant="outlined">
      <Grid container className={classes.noWrap}>
        <Tooltip title={stat.toolTip || stat.label}>
          <Grid item md container direction="column" className={clsx(classes.valueGrid, pointerClass)} justify="space-evenly" onClick={statClick}>
            <Typography className={classes.caption}>{stat.label}</Typography>
            <Typography className={clsx(classes.subTitle, classes.semiBold)} >{amount}</Typography>
          </Grid>
        </Tooltip>
        <Grid item md container className={classes.chartGrid}>
          {stat.chartData && 
            <ChartPanel options={chartOptions} series={stat.chartData} type={stat.chartProps?.chartType || "area"} height={75} minWidth={125}/>  
          }
        </Grid>
      </Grid>
    </Paper>
  )
};

export default memo(StatChartCard);
StatChartCard.displayName = "StatChartCard";
