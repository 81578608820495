import React, { useEffect, useMemo } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from '@material-ui/core/Paper';
import Typography from "@material-ui/core/Typography";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { cardStyles, generalStyles, fontStyles } from "utils/styles";
import { rowHeaderForProject, rowHeaderForClient } from "./hours-list-row";
import HoursList from "./hours-list";
import { useDispatch, useSelector } from "react-redux";
import { IRange, ITimer, QueryFilter, Range, RootState } from "types";
import { selectHoursQuery } from "../infra/timesheet-selectors";
import { loadHours } from "../infra/timesheet-actions";
import { formatDuration, getDurationFromMinutes, getRange, Ranges } from "../infra/timesheet-helpers";
import { useLocalization } from "utils/hooks";

const buildStyles = makeStyles(theme => ({
  ...cardStyles(theme),
  ...generalStyles(theme),
  ...fontStyles(theme),
  hoursCard: {
    marginTop: 0,
  },
  tableGrid: {
    margin: 0,
  },
}));

type CardProps = {
  defaultRange?: Range; //"7-days" | "30-days" | "week" | "last-week" | "month" | "last-month";
  clientId?: string;
  projectId?: string;
  isWorking?: boolean; 
  classes?: Record<string, string>; 
}

const InvoicesListCard = (props: CardProps) => {
  const classes = buildStyles();
  const otherClasses = props.classes || {};
  const dispatch = useDispatch();
  const { L } = useLocalization();
  const {defaultRange, clientId, projectId, isWorking} = props;
  const [range, setRange] = React.useState<IRange>(getRange(defaultRange));
  const queryKey = clientId ? `cli-${clientId}` : projectId ? `prj-${projectId}` : "none";
  const hours = useSelector<RootState, ITimer[] | null>(state => selectHoursQuery(state, queryKey));
  
  //-- Get the row header appropriate for the view
  const rowHeader = useMemo(() => {
    return clientId ? rowHeaderForClient() : rowHeaderForProject();
  }, [clientId, projectId]);

  //-- Refresh the hours when something changes
  useEffect(() => {
    if(clientId || projectId){
      const filter: QueryFilter[] = [];
      if(clientId) filter.push({field: "clientId", operator: "==", value: clientId});
      if(projectId) filter.push({field: "projectId", operator: "==", value: projectId});
      dispatch(loadHours(range.start, range.end, filter, queryKey));
    }
  }, [clientId, projectId, range]);

  //-- Calculates the total hours worked during the selected time range
  const totalHours = React.useMemo(() => {
    if(!hours) return 0;
    const mins = hours.reduce((total: number, item: ITimer) => {return total + (item.minutes || 0)}, 0);
    const duration = formatDuration(getDurationFromMinutes(mins));
    return duration;
  }, [hours]);

  async function onRangeChange(e: any) {
    const key = e.target.value;
    const item = Ranges.find(r => r.id === key);
    if (item) {
      setRange(item);
    }
  }

  return (
    <Grid id="hours-list-card" container justify="center">
      <Paper className={clsx([classes.card, classes.hoursCard, otherClasses.paper])} variant="outlined">
        <Grid container>

          <Grid container className={clsx(classes.cardHeader, otherClasses.toolbar)} justify="space-between" alignItems="center">
            <Grid item xs={3} container justify="flex-start" alignItems="center">
              <Typography className={clsx(classes.cardHeaderText, otherClasses.title)}>Hours</Typography>
            </Grid>
            <Grid item xs={9} container justify="flex-end" alignItems="center">
              <Typography className={clsx([classes.infoText, classes.marginRightLg])}>{totalHours} {L("hours")}</Typography>
              <Select id="timeframe" label="Timeframe" value={range.id} onChange={onRangeChange} disabled={isWorking}>
                {Ranges.map(option => <MenuItem key={option.id} value={option.id}>{option.label}</MenuItem>)}
              </Select>        
            </Grid>
          </Grid>

          <Grid container className={clsx(classes.cardBody, otherClasses.body)} spacing={1}>
            <HoursList hours={hours} header={rowHeader} rowsPerPageOptions={[10]}/>
          </Grid>

        </Grid>
      </Paper>
    </Grid>
  );
}

export default InvoicesListCard;
