import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { generalStyles, fontStyles } from "utils/styles";
import ChartPanel from "features/dashboard/chart-panel";
import { capitalize, lowerCase } from "lodash";
import MetricStatPanel from "./metric-stat-panel";

const buildStyles   = makeStyles(theme => ({
  ...generalStyles(theme),
  ...fontStyles(theme),
  rootContent: {
    paddingLeft: theme.spacing(1),
  },
  headerContent  : {
    margin: 0,
  },
  fullHeight: {
    height: `calc(100% - 20px)`,
  },
  value   : {
    fontSize    : 22,
    fontWeight  : 500,
    textAlign   : "center",
  },
  subValue: {
    fontSize    : 18,
    fontWeight  : 400,
    textAlign   : "center",
    color: theme.palette.grey[700],
  },
  label: {
    fontSize    : 22,
    fontWeight  : 500,
    textAlign   : "center",
    marginBottom: theme.spacing(1),
  },
  favoriteButton: {
    marginBottom: theme.spacing(-1),
  },  
}));

interface ControlProps{
  metric: any;
  category: string;
  period: string;
}

const MetricContent = ({metric, category, period}: ControlProps) => {
  const classes   = buildStyles();
  const label = (!category || category === "favorites") ? `${capitalize(metric.category)}: ${metric.label}` : metric.label;
  const description = metric.description.replace("~period~", lowerCase(period));

  return (
    <Grid container>
      <Grid container className={clsx([classes.fullHeight, classes.padY])} alignItems="center">
        <Grid item md={3} container alignItems="flex-start" direction="column">
          <Typography className={classes.value}>{metric.data.displayValue}</Typography>
          {metric.data.displaySubValue && <Typography className={classes.subValue}>{metric.data.displaySubValue}</Typography>}
        </Grid>
        
        <Grid item md={3}>
          {metric.data.chartData && 
            <ChartPanel options={metric.data.chartOptions} series={metric.data.chartData} type={metric.data.chartType} height={75} minWidth={160} />  
          }
          {metric.data.stats && 
            <MetricStatPanel data={metric.data.stats} />
          }
        </Grid>

        <Grid item md={6} container direction="column" alignItems="flex-start" justify="flex-start">
          <Typography color="primary" className={classes.subTitle}>{label}</Typography>
          <Typography className={classes.bodyText}>{description}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default MetricContent;
