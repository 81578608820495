import React from "react";
import Grid from "@material-ui/core/Grid";
import ClientDialog from "features/clients/client-dialog";
import ClientDialogMulti from "features/clients/ui/client-dialog-multi";
import { ProjectDialog } from "features/projects";
import ProjectDialogMulti from "features/projects/ui/project-dialog-multi";
import InvoiceDialog2 from "features/invoices/ui/invoice-dialog-2";
import { HoursDialog } from "features/timesheet";
import ImportDialog from "components/import/import-dialog";
import DashboardSettingsDialog from "features/dashboard/ui/dashboard-settings-dialog";

type ControlProps = {
  dialogs: any;
  onClose: (key?: any) => (value?: any) => void;
  dialogProps?: Record<string, Record<string, unknown>> | any;
}

const DialogContainer = ({dialogs, onClose, dialogProps }: ControlProps) => {
  //Check to see if there are any dialogs to display...
  if(JSON.stringify(dialogs) === "{}") return null;
  
  const importProps = dialogProps ? dialogProps["import"] : null as any;

  return (
    <Grid id="dialog-container" container justify="center" style={{position: "absolute", bottom: 0, right: 0}}>
      {Boolean(dialogs.client) &&
        <ClientDialog isOpen={Boolean(dialogs.client)} id={dialogs.client} onClose={onClose("client")} {...dialogProps["client"]}/>
      }
      {Boolean(dialogs.clientMulti) &&
        <ClientDialogMulti isOpen={Boolean(dialogs.clientMulti)} onClose={onClose("clientMulti")} {...dialogProps["clientMulti"]}/>
      }
      {Boolean(dialogs.project) &&
        <ProjectDialog isOpen={Boolean(dialogs.project)} id={dialogs.project} onClose={onClose("project")} {...dialogProps["project"]}/>
      }
      {Boolean(dialogs.projectMulti) &&
        <ProjectDialogMulti isOpen={Boolean(dialogs.projectMulti)} onClose={onClose("projectMulti")} {...dialogProps["projectMulti"]} />
      }
      {Boolean(dialogs.invoice) && 
        <InvoiceDialog2 isOpen={Boolean(dialogs.invoice)} invoiceId={dialogs.invoice} onClose={onClose("invoice")} {...dialogProps["invoice"]} /> 
      }    
      {Boolean(dialogs.timer) && 
        <HoursDialog isOpen={Boolean(dialogs.timer)} onClose={onClose("timer")} id={dialogs.timer} {...dialogProps["timer"]} />
      }
      {
        Boolean(dialogs.import) &&
        <ImportDialog isOpen={Boolean(dialogs.import)} onClose={onClose("import")} onImported={importProps?.onImported} itemType={dialogs.import || importProps?.itemType} {...importProps} />
      }
      {
        Boolean(dialogs.dashSettings) && 
        <DashboardSettingsDialog isOpen={dialogs.dashSettings} onClose={onClose("dashSettings")} {...dialogProps["dashSettings"]}/>
      }
    </Grid>
  );
}

export default DialogContainer;
