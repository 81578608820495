import { CSSProperties } from "react";
import { Theme } from "@material-ui/core";

export interface StylesDictionary{
  [key: string]: CSSProperties;
}

export const formStyles = (theme: any) => ({
  formGrid: {
    paddingRight: theme.spacing(4),
  },
  formRow: {
    marginBottom: theme.spacing(2),
  },
  field: {
    minWidth: 150,
  },
  formSelectRow: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(2),
  },
  formCompactRow: {
    marginBottom: theme.spacing(1.5),
  },
  textRight: {
    "& input": {
      textAlign: "right",
    }
  },
  inputGrid: {
    marginBottom: theme.spacing(2),
  },
  inputGridDense: {
    marginBottom: theme.spacing(0.5),
  },
  inputLabel: {

  },
  valueLabel: {
    fontWeight: 500,
    fontSize: "1rem",
    padding: `${theme.spacing(0)}px ${theme.spacing(1)}px`, //`
  },
  formControl: {

  },
  selectContainer: {

  },
  select: {

  },
  selectLabel: {

  },
  option: {

  },  
  optionSecondary: {
    color: theme.palette.secondary.main,
  },
  textCenter: {
    textAlign: "center" as const,
    "& input": {
      textAlign: "center" as const,
    }
  },
  actionButton: {
    minWidth: 75,
  }
});

export const generalStyles = (theme: any) => ({
  marginRight: {
    marginRight: theme.spacing(1),
  },
  marginRightDense: {
    marginRight: theme.spacing(0.5),
  },
  marginRightLg: {
    marginRight: theme.spacing(2),
  },
  marginLeft: {
    marginLeft: theme.spacing(1),    
  },
  marginLeftDense: {
    marginLeft: theme.spacing(0.5),
  },
  marginLeftLg: {
    marginLeft: theme.spacing(2),
  },
  marginX: {
    margin: `${theme.spacing(0)}px ${theme.spacing(1)}px`, //`
  },
  marginY: {
    margin: `${theme.spacing(1)}px ${theme.spacing(0)}px`, //`
  },
  marginYLg: {
    margin: `${theme.spacing(2)}px ${theme.spacing(0)}px`, //`
  },
  marginYXl: {
    margin: `${theme.spacing(4)}px ${theme.spacing(0)}px`, //`
  },
  mbl: {
    marginBottom: theme.spacing(2),
  },
  mb: {
    marginBottom: theme.spacing(1),
  },
  mbd: {
    marginBottom: theme.spacing(0.5),
  },
  mtl: {
    marginTop: theme.spacing(2),
  },
  mtxl: {
    marginTop: theme.spacing(4),
  },
  mt: {
    marginTop: theme.spacing(1),
  },
  mtd: {
    marginTop: theme.spacing(0.5),
  },
  pad: {
    padding: theme.spacing(1),
  },
  padLg : {
    padding: theme.spacing(2),
  },
  padDense : {
    padding: theme.spacing(0.5),
  },
  padX: {
    padding: `${theme.spacing(0)}px ${theme.spacing(1)}px`, //`
  },
  padY: {
    padding: `${theme.spacing(1)}px ${theme.spacing(0)}px`, //`
  },
  noTopMargin: {
    marginTop: 0,
  },
  fullHeight: {
    height: "100%",
  },  
  fullWidth: {
    width: "100%",
  },
  divider: {
    width: "50%",
    margin: `${theme.spacing(1)}px ${theme.spacing(0)}px`, //`
  },  
  dividerTall: {
    width: "50%",
    margin: `${theme.spacing(2)}px ${theme.spacing(0)}px`, //`
  },
  semiTransparent: {
    opacity: "75%",
  },
  clickable: {
    cursor: "pointer",
  }, 
  secondaryColor: {
    color: theme.palette.secondary.main,
  },
  preWrap: {    //used to recognize whitespace in a field, so it will wrap with carriage returns, etc.
    whiteSpace: "pre-wrap" as const,
  },
  borderBottom: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`, //`
  }
});

export const cardStyles = (theme: any) => ({
  card: {
    width: "100%",
    // position: "relative",
    // margin: `${theme.spacing(4)}px ${theme.spacing(2)}px 0 0`, //`
  },
  topCard: {
    width: "100%",
    margin: `0 ${theme.spacing(2)}px 0 0`, //`
  },
  cardHeader: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`, //`,
    borderBottom: `1px solid ${theme.palette.grey[300]}`, //`
    marginBottom: theme.spacing(1),
  },
  cardHeaderText: {
    fontSize: 16,
    fontWeight: 400,
  },
  cardBody: {
    padding: theme.spacing(2),
    "& fieldset": {
      borderColor: theme.palette.grey[300],
    },
  }, 
  smCard: {
    maxWidth: 447,
  },
  mdCard: {
    maxWidth: 807,
  },
  lgCard: {
    maxWidth: 1127,
  },  
});

export const feeAdornStyle = (theme: any) => ({
  rateInput: {
    "& input": {
      textAlign: "right",
    }
  },
  adorn: {
    fontWeight: 600,
    fontSize: 20,
  },
  adornGrid: {
    minWidth: 100,
  },
  adornSelect: {
    marginLeft: theme.spacing(1),
    width: 80,
  }
});

export const iconStyles = (theme: any) => ({
  iconLeft: {
    marginRight: theme.spacing(1),
  },
  iconRight: {
    marginLeft: theme.spacing(1),
  },
  iconAdjust: {
    marginBottom: theme.spacing(-0.5),
  },
  squarishAvatar: {
    height: theme.spacing(3),
    width: theme.spacing(3),
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.grey[200],
    border: `0.25px solid ${theme.palette.grey[300]}`, //`
    color: theme.palette.grey[500],
    fontSize: "0.95rem",
  },
});

export const tabStyles = (theme: any) => ({
  tabs: {
    // marginBottom: theme.spacing(2),
    "&.MuiTabs-root": {
      minHeight: theme.spacing(5),
    },
  },
  tab: {
    "&.MuiTab-root": {
      minHeight: theme.spacing(4),
      padding: `${theme.spacing(0)}px ${theme.spacing(1)}px`, //`
      [theme.breakpoints.up("sm")]: {
        minWidth: theme.spacing(15),
      }
    },
    "& .MuiTab-wrapper": {
      flexDirection: "row",
      alignItems: "center",
      "& svg, .material-icons": {
        fontSize: 20,
        marginRight: theme.spacing(1),
      }
    },    
    "& .MuiTab-labelContainer": {
      padding: 0,
      [theme.breakpoints.up("md")]: {
        padding: 0,
        paddingLeft: 0,
        paddingRight: 0
      }
    },
  }
});

export const widgetStyles = (theme: any) => ({
  widget: {
    padding: theme.spacing(2),
  },
  widgetHeader: {
    marginBottom: theme.spacing(1),    
  },
  widgetBody: {

  },
  widgetHeaderText: {
    fontSize: 18,
    fontWeight: 400,
    // textTransform: "uppercase",
    // textTransform: "uppercase",
    color: theme.palette.grey[500],
    // marginBottom: theme.spacing(1),
  },
  headerNavButton: {
    padding: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
  },
});

export const fontStyles = (theme: Theme) => ({
  pageHead: {
    fontSize: "1.4rem",
    fontWeight: 500,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    }
  },
  title: {
    fontSize    : "1.3em",
    fontWeight  : 500,
  },
  subTitle: {
    fontSize  : "1.2em",
    fontWeight: 500,
  },
  bodyText: {
    fontSize: "1.1em",
    fontWeight: 400,
  },
  bodyTextSmall: {
    fontSize: "1em",
    fontWeight: 500,
  },
  bodyTextXSmall: {
    fontSize  : "0.9em",
    fontWeight: 300,
  },
  infoText: {
    fontSize: "1em",
    fontWeight: 300,
    color: theme.palette.grey[600],
  },
  caption: {
    fontSize  : "0.9em",
    fontWeight: 300,
    color     : theme.palette.grey[500],
    "& svg, .MuiIcon-root": {
      marginBottom: theme.spacing(-0.5),
    }
  },
  link: {
    textDecoration: "none",
  },
  bold: {
    fontWeight: 700,
  },
  semiBold: {
    fontWeight: 600,
  },
  boldish: {
    fontWeight: 500,
  }, 
  overflowContainer: {
    maxWidth: "100%",
    overflow: "hidden",
    whiteSpace: "nowrap" as const,
    textOverflow: "ellipsis",
  },
  upcase: {
    textTransform: "uppercase" as const,
  },
  primary: {
    color: theme.palette.primary.main,
  },
  secondary: {
    color: theme.palette.secondary.main,
  },
  centerText: {
    textAlign: "center" as const,
  },
  grey: {
    color: theme.palette.grey[600],
  },
  lightGrey: {
    color: theme.palette.grey[500],
  },
  darkGrey: {
    color: theme.palette.grey[700],
  }
});

export const overflowStyles = {
  
}

export const statusStyles = (theme: Theme) => ({
  info: {
    borderColor: `${theme.palette.info.main} !important`,
    backgroundColor: theme.palette.info.light,
    color: theme.palette.info.dark, //.contrastText,
  },
  success: {
    borderColor: `${theme.palette.success.main} !important`,
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.dark, //.contrastText,
  },
  warning: {
    borderColor: `${theme.palette.warning.main} !important`,
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.dark, //.contrastText,
  },
  danger: {
    borderColor: `${theme.palette.error.main} !important`,
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.dark, //.contrastText,
  },
  error: {
    borderColor: `${theme.palette.error.main} !important`,
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.dark, //.contrastText,
  },
  infoHover: {
    "&:hover": {
      backgroundColor: theme.palette.info.main,
    }
  },
  successHover: {
    "&:hover": {
      backgroundColor: theme.palette.success.main,
    }
  },
  warningHover: {
    "&:hover": {
      backgroundColor: theme.palette.warning.main,
    }
  },
  dangerHover: {
    "&:hover": {
      backgroundColor: theme.palette.error.main,
    }
  }
});

export const interactionStyles = (theme: Theme) => ({
  hoverShadow: {
    boxShadow: "5px 5px 15px 5px rgba(94,94,94,0.3)",
  },
  hoverShadowPrimary: {
    boxShadow: "3px 3px 10px 3px #3f51b522", //rgba(94,94,94,0.3)",
  },
  hoverBorder: {
    border: `1px solid ${theme.palette.primary.main}33`, //`
  }
});

export const layoutStyles = (theme: Theme) => ({
  // sidebarColumn: (props: any) => ({
  //   background: theme.palette.common.white,
  //   borderRight: `1px solid ${theme.palette.grey[300]}`, //`
  //   position: "fixed" as const,
  //   left: props.isSidebarExpanded ? 191 : 64,
  //   top: 68,
  //   height: "100%",
  //   width: 260,
  //   [theme.breakpoints.down("xs")]: {
  //     display: "none",
  //   }
  // }),
  // contentColumn: (props: any) => ({
  //   marginLeft: props.isSidebarExpanded ? 425 : 236,
  //   marginTop: -20,
  //   [theme.breakpoints.down("xs")]: {
  //     marginLeft: 0,
  //     marginTop: 0,
  //   }
  // }),
  hideXs: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },    
  },
  showXs: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "unset !important",
    },    
  },
  centerXs: {
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },    
  },
  fadingCircle: {
    borderRadius: "50%",
    display: "inline-block",
    position: "relative" as const,
    "& img": {
      borderRadius: "50%",
      display: "block",
      border: "1px solid #fff",
    },
    "&:after": {
      content: "",
      display: "block",
      width: "100%",
      height: "100%",
      background: "radial-gradient(ellipse at center, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 70%,rgba(255,255,255,1) 100%)",
      borderRadius: "50%",
      position: "absolute" as const,
      top: 0,
      left: 0,
  }
  }
});