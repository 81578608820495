import firebase from "firebase/app";
import "firebase/firestore";
import moment from "moment";
import _ from "lodash";
import { COLS, STATUSES, getTimestamp, IFirestoreResult, asyncForEach, getSnapshotDoc, getDoc, getDocF, collectionRef, arrayResponse } from "utils/firebase";
import { Indexable, ITimer, IProject, ProjectTracking, TrackingDay, QueryFilter, Invoice, ItemUpdate } from "types";
import { prepareForDb, removeProps } from "utils/general-helpers";
import { parseDate, formatDate } from "utils/date-helpers";
import { startOfDay, startOfYear } from "date-fns";
import { endOfDay } from "date-fns/esm";

type ProjectTrackingGroup = {
  projectId: string;
  totalMinutes?: number;
  trackedHours: TrackingDay[];
};

export const timesheetCollections = {
  HOURS: "hours",
  TIMERS: "timers",
};

//NOTE: this is here because this format can be different from the date used in the store.
const TRACKED_DAYS_KEY_FORMAT = "YYYY-MM-DD";
const BAD_PROPS = ["id", "uid", "client", "project", "clientName", "projectName", "tracking"];
const REQ_PROPS = ["invoiceId", "invoiceDate", "paymentDate"];

//-- gets whethere or not a value changed
function isChanged<T>(a: T | null | undefined | number, b: T | undefined){
  return Boolean((a || a === 0) && a !== b);
}

//-- Gets the key used in the tracking days map for each day
function getDayKey(value: string | Date | moment.Moment | firebase.firestore.Timestamp | undefined){
  let dateValue = null;
  if(_.isDate(value)) dateValue = value;
  else if(moment.isMoment(value)) dateValue = value.toDate();
  else if(value instanceof firebase.firestore.Timestamp) dateValue = value.toDate();
  else dateValue = value ? parseDate(value) : new Date();
  if(!dateValue) throw new Error(`failed to parse value '${value}' for day key`);
  return formatDate(dateValue, TRACKED_DAYS_KEY_FORMAT);
}

function getRangeFromFilter(filter: QueryFilter | QueryFilter[] | null){
  if(!filter) return null;
  
  const filters = _.isArray(filter) ? filter : [filter];
  const start = filters.find(f => f.field === "startTime");
  const end = filters.find(f => f.field === "endTime");

  return {start: start?.value, end: end?.value};
}

//====
// Helper class to deal with side effects and other api stuff
const apiHelper = {

  getTrackedDates: (day: moment.Moment) => {
    const utcDay = moment.utc(day.format(TRACKED_DAYS_KEY_FORMAT));
    const utcDates ={
      // dayEndDate: utcDay.clone().endOf("day").toDate(),
      // weekEndDate: utcDay.clone().endOf("week").toDate(),
      // monthEndDate: utcDay.clone().endOf("month").toDate(),
      // yearEndDate: utcDay.clone().endOf("year").toDate(),
      dayOfWeek: utcDay.day(),
      dayOfMonth: utcDay.date(),
      month: utcDay.month(),
      year: utcDay.year(),
    };
    return utcDates;
  },

  adjustTrackingDay: async (
    trackingDoc: firebase.firestore.DocumentReference,
    dayKey: string, 
    minutes: number, 
    isReplace = false) => {
      if(minutes === 0 && !isReplace) return null;

      //Get the map of tracked minutes for this project
      const trackingRef = await trackingDoc.get();      
      const tracking = trackingRef.exists ? trackingRef.data() as ProjectTracking : { projectId: trackingDoc.id, days: {}}; 
      if(!tracking.days.hasOwnProperty(dayKey) && minutes < 0) return null; //no minutes to remove

      let model = null;
      const currMin = tracking.days[dayKey] || 0;
      const newMinutes = isReplace ? minutes : currMin + minutes;
      if(newMinutes <= 0){
        //remove this element from the map
        const updates = {...tracking};
        delete updates.days[dayKey];
        await trackingDoc.set(updates, {merge: false}); //replace it, so we remove the unused day
      }
      else{
        const updates: Partial<ProjectTracking> = {days: {}};
        if(!trackingRef.exists){  //this is a new doc, so need to add the necessary fields
          updates.projectId = trackingDoc.id;
        } 
        (updates.days as Indexable)[dayKey] = newMinutes;
        await trackingDoc.set(updates, { merge: true});
        model = {projectId: trackingDoc.id, key: dayKey, minutes: newMinutes};
      }

      return model;
  },

  adjustProjectTotalMinutes: async (
    projectDoc: firebase.firestore.DocumentReference,  
    project: IProject | null,   
    diff: number) => {
      const theProject = project ? project : await getDoc<IProject>(projectDoc); //(await projectDoc.get()).data() as any 
      const dbTotal = theProject.trackedMinutes || 0;
      let newTotal = dbTotal + diff;
      if(newTotal <= 0 ) newTotal = 0;
      await projectDoc.update({ trackedMinutes: newTotal, });

      return { projectId: projectDoc.id, trackedMinutes: newTotal };
  },

  addProjectHours: async (
    db: firebase.firestore.Firestore, 
    orgId: string, 
    timer: ITimer) => {
      if(!timer.minutes || timer.minutes <= 0) return false;

      const projectId = timer.projectId;
      const projectDoc = collectionRef(db, orgId, COLS.PROJECT).doc(projectId);
      const projectHoursDoc = collectionRef(db, orgId, COLS.PROJECT_HOURS).doc(projectId);
      const project = await getDoc<IProject>(projectDoc); // (await projectDoc.get()).data() as IProject;
      const dayKey = getDayKey(timer.startTime);

      const tracking = await apiHelper.adjustTrackingDay(projectHoursDoc, dayKey, timer.minutes);
      const totals = await apiHelper.adjustProjectTotalMinutes(projectDoc, project, timer.minutes);

      return {
        totals: [totals],
        tracking: [tracking],
      };
  },

  updateProjectHours: async(
    db: firebase.firestore.Firestore,
    orgId: string, 
    timerId: string, 
    changes: Partial<ITimer>
    ) => {
      if(changes.projectId || changes.minutes || changes.startTime){
        const timerDoc = collectionRef(db, orgId, COLS.HOURS).doc(timerId);
        const existingTimer = await getDoc<ITimer>(timerDoc); //(await timerDoc.get()).data() as any;
        const existingMinutes = existingTimer.minutes || 0;
        const existingDayKey = getDayKey(existingTimer.startTime);
        const newMinutes = changes.minutes || 0;

        const projectId = existingTimer.projectId;
        const projectDoc = collectionRef(db, orgId, COLS.PROJECT).doc(projectId);
        const project = await getDoc<IProject>(projectDoc); //(await projectDoc.get()).data() as IProject;
        const projectHoursDoc = collectionRef(db, orgId, COLS.PROJECT_HOURS).doc(projectId);
        const trackedDays = [];
        const totals = [];

        const projectChanged = isChanged(changes.projectId, existingTimer.projectId);
        const startDateChanged = isChanged(formatDate(changes.startTime), formatDate(existingTimer.startTime));
        const minutesChanged = isChanged(newMinutes, existingMinutes);

        if(projectChanged){
          //Remove from the old project
          const existingDayKey = getDayKey(existingTimer.startTime);
          const rmTracked = await apiHelper.adjustTrackingDay(projectHoursDoc, existingDayKey, (existingMinutes * -1));
          if(rmTracked){  //If there's no data to remove from the days, don't adjust the totals
            trackedDays.push(rmTracked);
            const rmTotal = await apiHelper.adjustProjectTotalMinutes(projectDoc, project, (existingMinutes * -1));
            totals.push(rmTotal);
          }

          //Add it to the new / current project's tracked dates and totals
          const newDayKey = getDayKey(changes.startTime ? changes.startTime as Date : existingTimer.startTime);
          const newProjectDoc = collectionRef(db, orgId, COLS.PROJECT).doc(changes.projectId);
          const newProject = await getDoc<IProject>(newProjectDoc); //(await newProjectDoc.get()).data() as IProject;
          const newProjectHoursDoc = collectionRef(db, orgId, COLS.PROJECT_HOURS).doc(changes.projectId);
          const addTracked = await apiHelper.adjustTrackingDay(newProjectHoursDoc, newDayKey, newMinutes);
          const addTotal = await apiHelper.adjustProjectTotalMinutes(newProjectDoc, newProject, newMinutes);
          trackedDays.push(addTracked);
          totals.push(addTotal);
        }
        else if(startDateChanged){
          //Adjust the total hours for the project if necessary
          if(minutesChanged){
            const diff = newMinutes - existingMinutes;
            const addTotal = await apiHelper.adjustProjectTotalMinutes(projectDoc, project, diff);
            totals.push(addTotal);
          }
          //Move the minutes from one day to the other
          const newDayKey = getDayKey(changes.startTime);

          const rmTracked = await apiHelper.adjustTrackingDay(projectHoursDoc, existingDayKey, (existingMinutes * -1));
          const addTracked = await apiHelper.adjustTrackingDay(projectHoursDoc, newDayKey, newMinutes);
          trackedDays.push(rmTracked);
          trackedDays.push(addTracked);
        }
        else if(minutesChanged){
          //Only change is to minutes, so it's a simpler change
          const diff = newMinutes - (existingTimer.minutes || 0);
          const addTracked = await apiHelper.adjustTrackingDay(projectHoursDoc, existingDayKey, diff);
          const addTotal = await apiHelper.adjustProjectTotalMinutes(projectDoc, project, diff);
          trackedDays.push(addTracked);
          totals.push(addTotal);
        }
        
        return {
          totals: totals,
          tracking: trackedDays,
        };
      } 
  },

  removeProjectHours: async(
    db: firebase.firestore.Firestore,
    orgId: string, 
    timerId: string) => {
      const timerDoc = collectionRef(db, orgId, COLS.HOURS).doc(timerId);
      const timer = await getDoc<ITimer>(timerDoc); //(await timerDoc.get()).data() as any;
      if(!timer.minutes || timer.minutes <= 0) return false;

      const projectId = timer.projectId;
      const projectHoursDoc = collectionRef(db, orgId, COLS.PROJECT_HOURS).doc(projectId);
      const projectDoc = collectionRef(db, orgId, COLS.PROJECT).doc(projectId);
      const project = await getDoc<IProject>(projectDoc); //(await projectDoc.get()).data() as IProject;
      const totals = [];
      const tracking = [];

      const dayKey = getDayKey(timer.startTime);
      const trackingDay = await apiHelper.adjustTrackingDay(projectHoursDoc, dayKey, (timer.minutes * -1));

      if(trackingDay){
        tracking.push(trackingDay);
        const totalVal = await apiHelper.adjustProjectTotalMinutes(projectDoc, project, (timer.minutes * -1));
        totals.push(totalVal);
      }
      
      return {
        totals: totals,
        tracking: tracking,
      };
  },

}

export const timesheetApi = (db: firebase.firestore.Firestore) => ({
//---
  // Gets the list of clients for the specified user
  getHours: async (orgId: string, startDate: Date | null, endDate: Date | null = null, filter: QueryFilter | QueryFilter[] | null = null): Promise<IFirestoreResult> => {
    const items: ITimer[] = [];

    const filterRange = getRangeFromFilter(filter);
    const startTime = startOfDay(startDate || filterRange?.start || startOfYear(new Date()));
    const coll = collectionRef(db, orgId, COLS.HOURS);
    let query = coll.where("startTime", ">=", startTime);
    if (endDate || filterRange?.end){
      const endTime = endOfDay(endDate || filterRange?.end);
      query = query.where("startTime", "<=", endTime);
    }

    if(filter){
      if(!_.isArray(filter)) filter = [filter];
      filter.forEach(f => {
        //start / end date were handled above
        if(f.field !== "startTime" && f.field !== "endTime"){
          query = query.where(f.field, f.operator, f.value);
        }
      });
    }

    const snapshot = await query.get();
    //enumerate the snapshot and assign the necessary values
    await asyncForEach(snapshot.docs, async doc => {
      const data = getSnapshotDoc<ITimer>(doc);
      items.push(data);
    });

    return arrayResponse(items, orgId);
  },

  //---
  // Creates a new timer in this org
  addHours: async (orgId: string, timer: Partial<ITimer>): Promise<IFirestoreResult> => {
    const startTime = moment(timer.startTime);
    const id = startTime.unix().toString(); //id is the unix timestamp
    let prepared = removeProps(timer, BAD_PROPS) as Partial<ITimer>;
    //Add the year/month/day/etc. fields
    const trackingDates = apiHelper.getTrackedDates(startTime);
    prepared = {
      ...prepared,
      ...trackingDates,
    }

    const timerDoc = collectionRef(db, orgId, COLS.HOURS).doc(id);
    await timerDoc.set(prepared);

    //Update the project trackedHours...
    const justAdded = {...prepared, id: id} as ITimer;
    const projectResult = await apiHelper.addProjectHours(db, orgId, justAdded);

    return {
      ok: true,
      statusCode: STATUSES.ok,
      key: id, //result.id,
      data: projectResult,
    };
  },

  //---
  // Updates an existing timer for the specified user
  updateHours: async (orgId: string, timerId: string, changes: Partial<ITimer>): Promise<IFirestoreResult> => {
    const safeChanges = _.omit(changes, BAD_PROPS);  //remove the id property, if present
    const timerDoc = collectionRef(db, orgId, COLS.HOURS).doc(timerId);

    //Look for changes that need to be written to the project's trackedHours field
    const projectResult = await apiHelper.updateProjectHours(db, orgId, timerId, safeChanges);  

    //This needs to happen after the project changes above so that we can access the previous version of the timer.
    await timerDoc.update(safeChanges);

    return {
      ok: true,
      statusCode: STATUSES.ok,
      key: timerId,
      data: projectResult,
    };
  },

  //---
  // Creates a new timer for the specified user
  deleteHours: async (orgId: string, id: string): Promise<IFirestoreResult> => {
    const timerDoc = collectionRef(db, orgId, COLS.HOURS).doc(id);
    const projectResult = await apiHelper.removeProjectHours(db, orgId, id);

    //Need to deal with project first, so this goes last
    await timerDoc.delete();

    return {
      ok: true,
      statusCode: STATUSES.ok,
      key: id,
      data: projectResult,
    };
  },

  //---
  // Gets the list of running timers in this org
  getTimers: async (orgId: string): Promise<IFirestoreResult> => {
    const items: ITimer[] = [];

    //TODO: change to async foreach
    await collectionRef(db, orgId, COLS.TIMERS).get()
      .then(qs => {
        if (!qs.empty) {
          qs.forEach(doc => {

            const item = {
              id: doc.id,
              ...doc.data(),
            } as ITimer;

            items.push(item);
          });
        }
      });

    if (items.length > 0) {
      return {
        ok: true,
        statusCode: STATUSES.ok,
        key: orgId,
        items: items,
      };
    }
    else {
      return {
        ok: true,
        statusCode: STATUSES.empty,
        key: orgId,
      };
    }
  },

  //---
  // Creates a new timer in this org
  addTimer: async (orgId: string, timer: Partial<ITimer>): Promise<IFirestoreResult> => {

    const fsTimer = _.omit(timer, BAD_PROPS);  //remove the id property, if present
    const id = getTimestamp(); 
    await collectionRef(db, orgId, COLS.TIMERS).doc(id).set(fsTimer); //.add(fsTimer);

    return {
      ok: true,
      statusCode: STATUSES.ok,
      key: id, //result.id
    };
  },

  //---
  // Updates an existing timer for the specified user
  updateTimer: async (orgId: string, timerId: string, changes: Partial<ITimer>): Promise<IFirestoreResult> => {
    const safeChanges = _.omit(changes, BAD_PROPS);  //remove the id property, if present
    const timer = collectionRef(db, orgId, COLS.TIMERS).doc(timerId);
    await timer.update(safeChanges);

    return {
      ok: true,
      statusCode: STATUSES.ok,
      key: timerId
    };
  },

  //---
  // Deletes the specific timer
  deleteTimer: async (orgId: string, timerId: string): Promise<IFirestoreResult> => {
    const timer = collectionRef(db, orgId, COLS.TIMERS).doc(timerId);
    await timer.delete();

    return {
      ok: true,
      statusCode: STATUSES.ok,
      key: timerId
    };
  },

  //---
  // Batch upload of hours
  uploadHours: async (orgId: string, hours: Partial<ITimer>[]): Promise<IFirestoreResult> => {
    const projects: ProjectTrackingGroup[] = [];
    const existingIds: number[] = [];

    const batch = db.batch(); //batch for the hours collection

    //add all the hours to the db
    for(let i = 0; i < hours.length; i++){
      const timer = hours[i];
      
      //capture information to update the project with this timer
      let project = projects.find(p => p.projectId === timer.projectId);
      if(!project){
        project = {projectId: timer.projectId as string, totalMinutes: 0, trackedHours: []};
        projects.push(project);
      }
      const dayKey = getDayKey(timer.startTime);
      let day = project.trackedHours.find((d: any) => d.id === dayKey);
      if(!day){
        day = {id: dayKey, minutes: 0}; 
        project.trackedHours.push(day);
      }
      const minutes = (timer.minutes || 0) - (timer.creditMinutes || 0);
      day.minutes += minutes;
      project.totalMinutes = (project.totalMinutes || 0) + minutes;

      //prepare the item and add it to the db
      let id = moment(timer.startTime).unix();
      while(existingIds.indexOf(id) >= 0) id++;  //find a valid, unused id.  increment by seconds until we don't collide

      //add to the db
      const prepared = prepareForDb(timer, BAD_PROPS, REQ_PROPS) as Partial<ITimer>;
      await collectionRef(db, orgId, COLS.HOURS).doc(id.toString()).set(prepared);
      existingIds.push(id);
    }

    await batch.commit(); //save the hours to the db

    const batch2 = db.batch();    //new batch for the project stuff

    //update the projects
    await asyncForEach(projects, async (prj: ProjectTrackingGroup) => {
      
      const projectDoc = collectionRef(db, orgId, COLS.PROJECT).doc(prj.projectId);
      const project = await getDoc<IProject>(projectDoc) as IProject; //(await projectDoc.get()).data() as IProject;
      const projectHoursDoc = collectionRef(db, orgId, COLS.PROJECT_HOURS).doc(prj.projectId);
      // const projectHoursRef = await projectHoursDoc.get();
      const existingHours = await getDocF<ProjectTracking>(projectHoursDoc, {projectId: prj.projectId, days: {}}, true) as ProjectTracking; // projectHoursRef.exists ? projectHoursRef.data() as ProjectTracking : {projectId: prj.projectId, days: {}};
      const updatedHours = {...existingHours};
      let totalMin = project.trackedMinutes || 0;
      
      //enumerate the days and create the hours map
      prj.trackedHours.forEach((day: TrackingDay) => {  
        const existingMinutes = existingHours.days[day.id] || 0;
        updatedHours.days[day.id] = existingMinutes + day.minutes;
        totalMin += day.minutes;
      });
      
      await projectHoursDoc.set(updatedHours);    //replace the current project hours doc with the new data
      await projectDoc.update({ trackedMinutes: totalMin, });  //update the total tracked minutes on the project
    });

    await batch2.commit();
    
    return {
      ok: true,
      statusCode: STATUSES.ok,
    };
  },

  //---
  // Will reset all the tracked days in all the projects based on all the hours
  recalibrateHours: async (orgId: string): Promise<IFirestoreResult> => {
    
    const query = collectionRef(db, orgId, COLS.HOURS).orderBy("startTime", "desc");
    const snapshot = await query.get();
    
    if(snapshot.empty){
      return {
        ok: true,
        statusCode: STATUSES.empty,
        key: orgId,
        data: null,
      };
    }

    const totals: ProjectTrackingGroup[] = [];
    await asyncForEach(snapshot.docs, async doc => {
      const item  = getSnapshotDoc<ITimer>(doc);
      let projectGroup = totals.find(t => t.projectId === item.projectId);
      
      //Add the project group if necessary
      if(!projectGroup){
        projectGroup = {
          projectId: item.projectId,
          trackedHours: [],
        };
        totals.push(projectGroup);
      }

      //Find the day item
      const dayKey = getDayKey(item.startTime);
      let day = projectGroup.trackedHours.find(td => td.id === dayKey);
      if(!day){
        day = { id: dayKey, minutes: item.minutes || 0, };
        projectGroup.trackedHours.push(day);
      }
      else{
        day.minutes = day.minutes + (item.minutes || 0);        
      }
    });

    //Write the data to the db in a batch
    const batch = db.batch();
    await asyncForEach(totals, async (prj: ProjectTrackingGroup) => {
      
      let totalMin = 0;
      const projectDoc = collectionRef(db, orgId, COLS.PROJECT).doc(prj.projectId);
      const projectHoursDoc = collectionRef(db, orgId, COLS.PROJECT_HOURS).doc(prj.projectId);
      const projectHours = {projectId: prj.projectId, days: {}} as ProjectTracking;

      //enumerate the days and create the hours map
      prj.trackedHours.forEach((day: TrackingDay) => {  
        projectHours.days[day.id] = day.minutes;
        totalMin += day.minutes;
      });
      
      await projectHoursDoc.set(projectHours);    //replace the current project hours doc with the new data
      await projectDoc.update({ trackedMinutes: totalMin, });  //update the total tracked minutes on the project
    });

    await batch.commit();

    return {
      ok: true,
      statusCode: STATUSES.empty,
      key: orgId,
      data: totals,
    };
  },

  assignInvoiceToHours: async (orgId: string, invoiceId: string, invoice: Invoice): Promise<IFirestoreResult> => {
    //Is there anything for me to do here?
    if(invoice && invoice.hours && invoice.hours.length && (invoice.invoiceDate || invoice.paidDate)){
      const updates: ItemUpdate<ITimer>[] = [];

      //Get the hours to update.  Note, Firestore can only use the "in" operator on 10 items at a time, so deal with that
      for(let i = 0; i < invoice.hours.length; i = i + 10){
        const ids = invoice.hours.slice(i, i + 10);
        const collection = collectionRef(db, orgId, COLS.HOURS);
        const query = collection.where(firebase.firestore.FieldPath.documentId(), "in", ids);
        const snapshot = await query.get();
        //enumerate the snapshot and assign the necessary values
        await asyncForEach(snapshot.docs, async doc => {
          const updated: ItemUpdate<ITimer> = {
            id: doc.id,
            updates: {
              invoiceId: invoiceId,
              invoiceDate: invoice.invoiceDate || null,
              paymentDate: invoice.paidDate || null,
            }
          };
          updates.push(updated);
        });
      }

      //Now, send the updates in a batch to the server
      const batch = db.batch();
      await asyncForEach(updates, async (item: ItemUpdate<ITimer>) => {
        await collectionRef(db, orgId, COLS.HOURS).doc(item.id).update(item.updates);
      });

      await batch.commit();

      return {
        ok: true,
        statusCode: STATUSES.ok,
        key: invoiceId,
        data: updates,
      };
    }

    //nothing to do, so return all good with empty data
    return {
      ok: true,
      statusCode: STATUSES.empty,
      key: invoiceId,
      data: [],
    };
  },

  removeInvoiceFromHours: async (orgId: string, ids: string[]): Promise<IFirestoreResult> => {
    //Is there anything for me to do here?
    if(ids && ids.length){
      const updates: ItemUpdate<ITimer>[] = [];

      //Get the hours to update.  Note, Firestore can only use the "in" operator on 10 items at a time, so deal with that
      for(let i = 0; i < ids.length; i = i + 10){
        const someIds = ids.slice(i, i + 9);
        const collection = collectionRef(db, orgId, COLS.HOURS);
        const query = collection.where(firebase.firestore.FieldPath.documentId(), "in", someIds);
        const snapshot = await query.get();
        //enumerate the snapshot and assign the necessary values
        await asyncForEach(snapshot.docs, async doc => {
          const updated: ItemUpdate<ITimer> = {
            id: doc.id,
            updates: {
              invoiceId: null,
              invoiceDate: null,
              paymentDate: null,
            }
          };
          updates.push(updated);
        });
      }

      //Now, send the updates in a batch to the server
      const batch = db.batch();
      await asyncForEach(updates, async (item: ItemUpdate<ITimer>) => {
        await collectionRef(db, orgId, COLS.HOURS).doc(item.id).update(item.updates);
      });

      await batch.commit();

      return {
        ok: true,
        statusCode: STATUSES.ok,
        data: updates,
      };
    }

    //nothing to do, so return all good with empty data
    return {
      ok: true,
      statusCode: STATUSES.empty,
      data: [],
    };

  }
});