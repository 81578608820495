import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useSelector } from "react-redux";
import { RootState } from "types";

const buildStyles   = makeStyles(theme => ({
  title   : {
    fontSize    : 22,
    fontWeight  : 500,
    textAlign   : "center",
    marginBottom: theme.spacing(1),
  },
  subTitle  : {
    fontSize: 18,
    fontWeight: 400,
    textAlign: "center",
    color: theme.palette.secondary.main,
    marginBottom: theme.spacing(2),
  },
}));

const OnboardingHeader = () => {
  const classes   = buildStyles();
  const onboarding = useSelector((state: RootState) => state.app.org.onboarding);
  const wasSample = Boolean(onboarding && onboarding.sampleEndDate);

  return (
    <Grid id="onboarding-header" container justify="center">
      <Grid item xs={12}>
        <Typography variant="h5" color="primary" className={classes.title}>{wasSample ? "Welcome back!" : "Welcome!"}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3" className={classes.subTitle}>{wasSample ? "Let's get started with your data." : "It looks like this is your first time here"}</Typography>
      </Grid>
    </Grid>
  );
}

export default React.memo(OnboardingHeader);
OnboardingHeader.displayName = "OnboardingHeader";
