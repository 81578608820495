import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import { useActiveTimer } from "utils/hooks";

const buildStyles = makeStyles(theme => ({
  button: {
    margin: `${theme.spacing(0)}px ${theme.spacing(1)}px`, //`
  },
  icon: {
    color: theme.palette.secondary.light,
  },
  iconOff: {
    color: theme.palette.grey[500],
  }
}));

const AppHeaderTimer = () => {
  const classes = buildStyles();
  const history = useHistory();
  const timer = useActiveTimer();

  const onClick = React.useCallback(() => {
    history.push("/timesheet");
  }, []);

  const props = React.useMemo(() => {
    return timer ? {
      tt: "Click here to view your running timer",
      icon: "alarm_on",
      cn: classes.icon,
    } : 
    {
      tt: "Click here to start tracking your time",
      icon: "alarm_add",
      cn: classes.iconOff,      
    };
  }, [timer]);


  return (
    <Tooltip title={props.tt}>
      <IconButton size="small" className={classes.button} onClick={onClick}>
        <Icon color="action" className={props.cn}>{props.icon}</Icon>
      </IconButton>
    </Tooltip>
  );
}

export default AppHeaderTimer;
