import _, { sumBy } from "lodash";
import moment from "moment";
import { IProject, IAlert, IProjectDates, ProjectType, IFilter, IMenuItem, IClient, ProjectFilter, Invoice, ProjectWithInvoices } from "types";
import { IParserCollection, Parsers } from "utils/hooks";
import { dateToString } from "utils/general-helpers";
import { formatCurrency } from "utils/number-helpers";
import { parseMoment } from "utils/date-helpers";
import { differenceInDays, endOfDay, isBefore, isSameMonth, isSameYear, subMonths, subYears } from "date-fns";
import { isSameQuarter } from "date-fns/esm";

export const newProject: IProject = {
  id: "",
  name: "",
  type: ProjectType.fixed,
  clientId: "",
  startDate: dateToString(new Date()) as string,
  dueDate: null,
  fee: 0,
  notes: "",
  deliveredDate: null,
  invoicedDate: null,
  paidDate: null,
  hours: 0,
  units: 1,
  roundType: "none",
  estimatedHours: undefined,
  wordCount: 0,
  category: "",
}

export const projectParsers: IParserCollection = {
  // id: Parsers.int,
  // clientId: Parsers.int,
  fee: Parsers.currencyInt,
  startDate: Parsers.dateString,
  dueDate: Parsers.dateString,
  deliveredDate: Parsers.dateString,
  invoicedDate: Parsers.dateString,
  paidDate: Parsers.dateString,
  units: Parsers.float(2),
  estimatedHours: Parsers.intOptional,
}

export function calcProjectDates(project: IProject): IProjectDates {
  const sDate = parseMoment(project.startDate); // project.startDate ? moment(new Date(project.startDate)) : null;
  const dDate = parseMoment(project.dueDate ?? null); // ? moment(project.dueDate) : null;
  const vDate = parseMoment(project.deliveredDate ?? null); // ? moment(project.deliveredDate) : null;
  const iDate = parseMoment(project.invoicedDate ?? null); // ? moment(project.invoicedDate) : null;
  const pDate = parseMoment(project.paidDate ?? null); // ? moment(project.paidDate) : null;
  const today = moment().endOf("day");

  return {
    id: project.id,
    start: sDate,
    due: dDate,
    delivered: vDate,
    invoiced: iDate,
    paid: pDate,
    startMonth: sDate ? sDate.month() + 1 : null,
    startYear: sDate ? sDate.year() : null,
    dueMonth: dDate ? dDate.month() + 1 : null,
    dueYear: dDate ? dDate.year() : null,
    deliveredMonth: vDate ? vDate.month() + 1 : null,
    deliveredYear: vDate ? vDate.year() : null,
    invoicedMonth: iDate ? iDate.month() + 1 : null,
    invoicedYear: iDate ? iDate.year() : null,
    paidMonth: pDate ? pDate.month() + 1 : null,
    paidYear: pDate ? pDate.year() : null,
    today: today,
  };
}

export const clientName = (clients: IClient[] | null, project: IProject): string => {
  if (!clients) return "<no client>";
  const client = clients.find(c => c.id === project.clientId);
  return !!client ? client.name : "<unknown client>";
}

export function getProjectAlerts(project: IProject, client: IClient | null = null): IAlert[] {
  const items: IAlert[] = [];
  if (!project) return items;
  const dates = calcProjectDates(project);
  const myClient = client || (project as any).client; //try to get the client,

  if (dates.paid) {
    items.push({ id: "paid", severity: "success", message: "This project has been paid." });
    return items;
  }

  //Project is near or overdue
  if(dates.due && !dates.delivered){
    const dueIn = dates.due.diff(dates.today, "d");
    if (dueIn < 0) {
      items.push({ id: "overdue", severity: "error", message: `This project is ${dueIn * -1} days overdue.` });
    }
    else if (dueIn === 0) {
      items.push({ id: "due-soon", severity: "warning", message: "This project is due today." });
    }
    else if (dueIn <= 5) {
      const days = dates.due.diff(dates.today, "d");
      items.push({ id: "due-soon", severity: "warning", message: `This project is due in ${days} day(s).` });
    }
  }
  //Needs to be invoiced
  if (dates.delivered && (!dates.invoiced && !project.lastInvoiceDate)) {
    items.push({ id: "to-invoice", severity: "info", message: "This project has been delivered but not invoiced." });
  }
  //Warnings about overdue invoices
  else if((dates.invoiced || project.lastInvoiceDate) && (!dates.paid && !project.lastPaymentDate)){
    const invDate = dates.invoiced || parseMoment(project.lastInvoiceDate ?? null);
    const daysOverdue = invDate ? dates.today.diff(invDate, "d") : 0;
    if (myClient?.daysToPay && daysOverdue > myClient.daysToPay) {
      items.push({ id: "not-paid", severity: "error", message: "This payment is overdue." });
    }
    else if (!myClient?.daysToPay && daysOverdue > 45) {
      items.push({ id: "not-paid", severity: "error", message: "You sent an invoice for this project more than 45 days ago." });
    }
    else if (!myClient?.daysToPay && daysOverdue > 30) {
      items.push({ id: "not-paid", severity: "warning", message: "You sent an invoice for this project more than 30 days ago." });
    }
  }


  return items;
}

export const hasProjectAlert = (alerts: IAlert[], alertId: string) => {
  return !!alerts.find(a => a.id === alertId);
}

const sameMonth = (reference: Date, date?: Date, orBefore = false) => {
  if(!date) return false;
  return isSameMonth(reference, date) || (orBefore && isBefore(date, reference));
}

export const isProject = {
  active: (project: IProject) => {
    return (!project.deliveredDate);
  },
}

export function filterProjects(projects: IProject[], filter: ProjectFilter) {
  //Start off by removing archived items
  const today = new Date();
  projects = (filter === "archived" || filter === "all") ? projects : projects.filter(p => !p.isArchived);
  if (filter === null || filter === "all") return projects;
  else if (filter === "archived") return projects.filter(p => p.isArchived);
  else if (filter === "paid"){
    return projects.filter(p => {
     return !!p.paidDate || (!!p.lastPaymentDate && !!p.lastInvoiceDate && p.lastPaymentDate >= p.lastInvoiceDate);
    });
  }
  else if (filter === "overdue") return projects.filter(p => p.dueDate && !p.deliveredDate && differenceInDays(endOfDay(p.dueDate as Date), endOfDay(today)) < 0)
  else if (filter === "uninvoiced") return projects.filter(p => p.uninvoiced > 0); // return projects.filter(p => !!p.deliveredDate && (!p.invoicedDate && !p.lastInvoiceDate));
  else if (filter === "unpaid"){ return projects.filter(p => p.unpaid > 0); } 
  else if (filter === "undelivered") return projects.filter(p => !p.deliveredDate);
  else if (filter === "inprogress") return projects.filter(p => !!p.startDate && parseMoment(p.startDate)?.isSameOrBefore(moment()) && !p.deliveredDate);
  else if (filter === "earned") return projects.filter(p => !!p.deliveredDate);
  else if (filter === "invoiced") return projects.filter(p => (!!p.invoicedDate || !!p.lastInvoiceDate) && (!p.paidDate && !p.lastPaymentDate));
  else if (filter === "active" || filter.indexOf("this") >= 0 || filter.indexOf("last") >= 0) {
    let matches: IProject[] = [];
    if (filter === "active"){
      //started or due this month, or in progress and not delivered
      matches = projects.filter(p => {
        if(!p.deliveredDate) return sameMonth(today, p.startDate as Date, true); //filter out projects that haven't started yet
        else return false;
      });
    }
    else if (filter === "thismonth") {
      matches = projects.filter(p => sameMonth(today, p.startDate as Date) || !p.dueDate || sameMonth(today, p.dueDate as Date) || sameMonth(today, p.deliveredDate as Date));
    }
    else if (filter === "lastmonth") {
      const lastMonth = subMonths(today, 1);
      matches = projects.filter(p => sameMonth(lastMonth, p.startDate as Date) || sameMonth(lastMonth, p.dueDate as Date) || sameMonth(lastMonth, p.deliveredDate as Date));
    }
    else if (filter === "thisquarter") {
      matches = projects.filter(p => (p.startDate && isSameQuarter(today, p.startDate as Date) || !p.dueDate || (p.dueDate && isSameQuarter(today, p.dueDate as Date)) || !p.deliveredDate || (p.deliveredDate && isSameQuarter(today, p.deliveredDate as Date))));
    }
    else if (filter === "thisyear") {
      matches = projects.filter(p => (p.startDate && isSameYear(today, p.startDate as Date) || !p.dueDate || (p.dueDate && isSameYear(today, p.dueDate as Date)) || !p.deliveredDate || (p.deliveredDate && isSameYear(today, p.deliveredDate as Date))));
    }
    else if (filter === "lastyear") {
      const lastYear = subYears(today, 1);
      matches = projects.filter(p => (p.startDate && isSameYear(lastYear, p.startDate as Date) || (p.dueDate && isSameYear(lastYear, p.dueDate as Date)) || (p.deliveredDate && isSameYear(lastYear, p.deliveredDate as Date))));
    }

    return matches || [];
  }
  else return projects;
}

type ProjectDateFunc = (project: IProject) => Date | string | null | undefined;
type DatePeriod = "week" | "month" | "quarter" | "year" | "12-months" | "all-time" | "last-month" | "last-year" | "last-30" | "prev-30" | null;

export function filterProjectsByDate(
  projects: IProject[], 
  comparator: ProjectDateFunc, 
  period: DatePeriod = null, 
  since: Date | string | null = null, 
  to: Date | string | null = null,
  includeNoDate = false) {
  //Do we even need to filter?
  if (!projects || projects.length == 0 || (!since && !period) || (!since && period === "all-time")) return projects;

  //Figure out the date for which we're filtering
  let filterDate: any = null;
  let filterEndDate: any = null;
  const today = moment();

  if (!!period) {
    switch (period) {
      case "week":
      case "month":
      case "quarter":
      case "year":
        filterDate = today.clone().startOf(period); //.subtract(1, period);
        filterEndDate = today.clone().endOf(period);
        break;
      case "12-months":
        filterDate = today.clone().subtract(12, "months");
        break;
      case "last-month":
        filterDate = today.clone().subtract(1, "months").startOf("month");
        filterEndDate = today.clone().startOf("month");
        break;
      case "last-year":
        filterDate = today.clone().subtract(1, "years").startOf("year");
        filterEndDate = today.clone().startOf("year");
        break;
      case "last-30":
        filterDate = today.clone().subtract(30, "days"); //.subtract(1, period);
        filterEndDate = today.clone().endOf("day");
        break;
      case "prev-30":
        filterDate = today.clone().subtract(61, "days"); //.subtract(1, period);
        filterEndDate = today.clone().subtract(30, "days");
        break;
    }
  }
  else if (!!since) {
    filterDate = parseMoment(since);
    if (!!to) filterEndDate = parseMoment(to);
  }

  //Now, do the filtering
  const filtered = projects.filter(prj => {
    const pDate = comparator(prj);
    if (!!pDate) {
      const mDate = parseMoment(pDate);
      const isMatch = mDate && mDate.isValid() && Boolean(mDate >= filterDate && (!filterEndDate || mDate < filterEndDate));
      return isMatch;
    }
    else if(includeNoDate && !pDate){
      return true;
    }
    return false;
  });

  return filtered;
}

export function calcFee(item: IProject, useEstimatedHours = false): number {
  if (item.fee) {
    if (item.type === ProjectType.fixed) {
      return item.fee;
    }
    else if(item.type === ProjectType.perHour && (!useEstimatedHours || !item.estimatedHours) && item.trackedMinutes){
      return item.fee * (item.trackedMinutes / 60);
    }
    else if(item.type === ProjectType.perHour && item.estimatedHours && useEstimatedHours){
      return item.fee * item.estimatedHours;
    }
    else { //if (item.type === ProjectType.perArticle || item.type === ProjectType.perWord) {
      return item.fee * (item.units || 1);
    }
  }

  return 0;
}

export const calcProjectRate = (item: IProject, fallbackToEstimatedHours = false) => {
  if(item.fee && item.fee > 0){
    if(item.type === ProjectType.perHour) return item.fee;
    else if(item.trackedMinutes || (item.estimatedHours && fallbackToEstimatedHours)){
      const hours = ((item.trackedMinutes as number)  / 60) || (item.estimatedHours as number);
      if(item.type === ProjectType.fixed){
        return item.fee / hours;
      }
      else{ //if(item.type === ProjectType.perArticle || item.type === ProjectType.perWord || item.type === ProjectType.perPost){
        const totalFee = calcFee(item);
        return totalFee / hours;
      }
    }
  }

  return 0;
}


export function sumProjects(projects: IProject[], andFormat = false, useEstimatedHours = false): string | number {
  // const total = projects.reduce((sum, p) => sum + (p.type === "fixed" ? p.fee || 0 : 0), 0);
  const total = projects.reduce((sum, p) => sum + calcFee(p, useEstimatedHours), 0);
  return andFormat ? formatCurrency(total) : total;
}

export function sumProjectsForMonth(projects: ProjectWithInvoices[], month: Date, andFormat = false): string | number {
  const total = projects.reduce((sum, prj) => {
    if(prj.hasInvoices && prj.invoices){
      //Get all the invoices paid in this month
      const invoices = prj.invoices.filter(inv => isSameMonth(inv.paidDate as Date, month));
      return sum + sumBy(invoices, inv => inv.amount);
    }
    else 
      return sum + calcFee(prj);
  }, 0);
  
  return andFormat ? formatCurrency(total) : total;
}

export function findFilterLabel(filter: string | null | undefined, filters: IFilter[], menuItems: IMenuItem[]) {
  if (!filter) return null;
  const filterItem = filters.find(f => f.filter === filter);
  if (filterItem) return filterItem.label;
  const menuItem = menuItems.find(f => f.action === filter);
  if (menuItem) return menuItem.label;
  return null;
}

export const projectTypeLabel = (type: string): string => { return type === "hourly" ? "hour" : (type === "fixed" ? "hour" : type.replace("per", "").toLowerCase()) ; };

const perFeeFormat = (fee: number, type: string) => { return `${formatCurrency(fee)} / ${projectTypeLabel(type)}`; } //`

export function formatFee(item: IProject, altDisplay = false) {
  if (item.fee && item.fee > 0) {
    if (item.type === ProjectType.fixed) {
      if(altDisplay && item.trackedMinutes){
        return `${formatCurrency((item.fee || 0) / (item.trackedMinutes / 60))} / hr`;
      }
      else{
        return formatCurrency(item.fee);
      }
    }
    else if (item.units && (item.type === ProjectType.perArticle || item.type === ProjectType.perWord || item.type === ProjectType.perPost)) {
      if(altDisplay && item.trackedMinutes){
        return `${formatCurrency(((item.fee || 0) * (item.units || 1)) / (item.trackedMinutes / 60))} / hr`;
      }
      else{
        return formatCurrency(item.fee * item.units);
      }
    }
    else if(altDisplay && item.type === ProjectType.perHour){
      if(item.trackedMinutes){
        return formatCurrency( (item.trackedMinutes / 60) * (item.fee || 0));
      }
      else{
        return "<no tracking>";
      }
    }
    else {
      return perFeeFormat(item.fee, item.type);
    }
  }

  return "";
}

export const projectToInvoice = (project: IProject, daysToPay = 30): Invoice | null => {
  if(!project.invoicedDate) return null;

  const virtualInvoice: Invoice = {
    id: project.id, 
    isProject: true, 
    clientId: project.clientId, 
    projectId: project.id, 
    invoiceDate: project.invoicedDate, 
    dueDate: moment(project.invoicedDate).add(daysToPay, "days").toDate(),
    paidDate: project.paidDate,
    amount: calcFee(project),
    notes: "",
    hours: []
  };

  return virtualInvoice;
}