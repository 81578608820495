import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Divider from '@material-ui/core/Divider';
import SetGoal from "./set-goal";
import AvailableGoals from "./available-goals";
import { IGoal, IGoalDefinition } from "../infra/goal-types";
import { selectGoals } from "../infra/goal-selectors";
import { useDispatch, useSelector } from "react-redux";
import { createGoal, updateGoal } from "../infra/goal-actions";
import { useSnackbar } from "utils/hooks";
import { removeProps } from "utils/general-helpers";

const buildStyles   = makeStyles(theme => ({
  root  : {
    // padding     : theme.spacing(1),
  },
  section: {
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    width: "25%",
    marginBottom: theme.spacing(3),
  }
}));

const SetGoalsView = () => {
  const classes   = buildStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { goalId } = useParams<{goalId: string}>();
  const [goal, setGoal] = useState<IGoal | null>(null);
  const [definition, setDefinition] = useState<IGoalDefinition | null>(null);  
  const goals = useSelector(selectGoals);
  const notify = useSnackbar();

  useEffect(() => {
    if(goalId){
      const item = goals.find(g => g.id === goalId) || null;
      setGoal(item);
      if(item && item.definition) setDefinition(item.definition);
    }
    else if(goal){
      setGoal(null);
    }
  }, [goals, goalId]);

  const onAddingGoal = (item: IGoalDefinition) => {
    setGoal(null);
    setDefinition(item);
  }

  const onRemoveGoal = () => {
    // const updated = definition.filter(a => a.id !== id);
    setDefinition(null);
    setGoal(null);
    if(goalId) history.replace("/goals/add"); 
  }

  const onSaveGoal = async (model: IGoal) => {
    const toSave = removeProps<IGoal>(model, ["definition", "data"]);

    if(model.id){
      await dispatch(updateGoal(model.id, toSave));
    }
    else{
      await dispatch(createGoal(toSave));
    }

    notify("Your goal has been saved!", "success");
    onRemoveGoal(); //remove the item from the adding list
    history.replace("/goals");  //switch them to the goals view
  }
  
  return (
    <Grid container className={classes.root} justify="center">
      <Grid container spacing={2} justify="center" className={classes.section}>
        {definition && 
          <Grid item md={6} sm={12}>
            <SetGoal definition={definition} goal={goal} onCancel={onRemoveGoal} onSave={onSaveGoal}/>
          </Grid>
        }
      </Grid>
      {definition && <Divider className={classes.divider}/>}
      <Grid container spacing={2}>
        <AvailableGoals onAdd={onAddingGoal} adding={definition ? [definition] : []} existing={goals}/>
      </Grid>
    </Grid>
  );
}

export default SetGoalsView;
