import { matchPath } from "react-router-dom";
import { URLSearchParams } from "url";

export const routes = {
  dashboard: "/",
  analytics: "/analytics",
  goals: "/goals",
  goalsAdd: "/goals/add",
  goalsEdit: "/goals/:goalId/edit", //`/goals/edit/${goal.id}`
  metrics: "/metrics",
  profile: "/profile",
  profileSettings: "/profile/settings",
  projects: "/projects",
  project: "/projects/:projectId/view",
  projectEdit: "/projects/:projectId/edit",
  clients: "/clients",
  client: "/clients/:clientId/view",
  clientEdit: "/clients/:clientId/edit",
  timesheet: "/timesheet",
  invoices: "/invoices",
  invoiceNew: "/invoices/new",
  invoiceEdit: "/invoices/:invoiceId/edit",
  invoice: "/invoices/:invoiceId/view",
  notes: "/notes",
  tasks: "/tasks",
  reports: "/reports",
  logout: "/logout",
  samples: "/samples",

  integrations: "/integrations",
  integrationsRedirect: "/integrations/oauth_redirect",
};

export const sidebarConfig = [
  {
    id: 2000,
    type: "group",
    items: [
      {
        id: 20,
        ordinal: 10,
        labelId: "home",
        label: "Home",
        icon: "dashboard",
        path: routes.dashboard,
      },
      
      {
        id: 25,
        ordinal: 10,
        labelId: "projects",
        label: "Projects",
        icon: "assignment",
        path: routes.projects,
      },
      {
        id: 30,
        ordinal: 20,
        labelId: "clients",
        label: "Clients",
        icon: "business",
        path: routes.clients,
      },
    ]
  },
  {
    id: 2001,
    type: "divider",
  },  
  {
    id: 3000,
    type: "group",
    items: [
      {
        id: 40,
        ordinal: 30,
        labelId: "timesheet",
        label: "Timesheet",
        icon: "timer",
        path: routes.timesheet,
      },
      {
        id: 80,
        ordinal: 40,
        labelId: "invoices",
        label: "Invoices",
        icon: "receipt",
        path: routes.invoices,
      }, 
      //TODO: For now, disable the integrations menu item 
      // {
      //   id: 65,
      //   ordinal: 14,
      //   labelId: "integrations",
      //   label: "Integrations",
      //   icon: "device_hub",
      //   path: routes.integrations,
      // },
    ]
  },
  {
    id: 2501,
    type: "divider",
  },
  {
    id: 4000,
    type: "group",
    items: [
      {
        id: 70,
        ordinal: 0,
        labelId: "notes",
        label: "Notes",
        icon: "description",
        path: routes.notes,
      },
      {
        id: 50,
        ordinal: 0,
        labelId: "tasks",
        label: "Tasks",
        icon: "playlist_add_check",
        path: routes.tasks,
      },
      // {
      //   id: 60,
      //   ordinal: 10,
      //   labelId: "reports",
      //   label: "Reports",
      //   icon: "bar_chart",
      //   path: routes.reports,
      // },
    ],
  },
  {
    id: 1001,
    type: "divider",
  },  
  {
    id: 1000,
    type: "group",
    items: [
      {
        id: 10,
        ordinal: 0,
        labelId: "analytics",
        label: "Analytics",
        icon: "timeline_outlined",
        path: routes.analytics,
      },
      {
        id: 12,
        ordinal: 12,
        labelId: "goals",
        label: "Goals",
        icon: "track_changes",
        path: routes.goals,
      },
      {
        id: 13,
        ordinal: 13,
        labelId: "metrics",
        label: "Metrics",
        icon: "speed",
        path: routes.metrics,
      },  
                
    ],
  },
  
];


const idMatch = /(:[a-z])\w+/g;

// export const buildQueryString = (queryParams?: Record<string, unknown>) => {
//   if(!queryParams) return "";
//   const usp = new URLSearchParams(queryParams);
//   return usp.toString();
// }

//---
// Helper method for constructing routes.
export const getRoute = (pathName: keyof typeof routes, id?: string, queryString?: string) => {
  const route = routes[pathName];
  //Check for an id property
  const match = route.match(idMatch); 
  if(id && match){
      //Replace the value
      return route.replace(idMatch, id) + (queryString ? `?${queryString}` : "");
  }
  else if(match){
    //needs an id, but doesn't have one...
    throw new Error(`Route ${pathName} requires an id property.`);
  }
  
  return route + (queryString ? `?${queryString}` : "");
}

//--
// Gets the path for tracking purposes (strips out the id of the item, if present)
export const trackingPath = (pathname: string) => {
  
  let pathMatch = matchPath(pathname, {path: routes.project, exact: true});
  if(pathMatch) return "projects/view";
  
  pathMatch = matchPath(pathname, {path: routes.projectEdit, exact: true});
  if(pathMatch) return "projects/edit";

  pathMatch = matchPath(pathname, {path: routes.client, exact: true});
  if(pathMatch) return "clients/view";
  
  pathMatch = matchPath(pathname, {path: routes.clientEdit, exact: true});
  if(pathMatch) return "clients/edit";

  pathMatch = matchPath(pathname, {path: routes.invoice, exact: true});
  if(pathMatch) return "invoices/view";

  pathMatch = matchPath(pathname, {path: routes.invoiceEdit, exact: true});
  if(pathMatch) return "invoices/edit";
  
  return pathname;
}

export const getQueryParameters = (search: string, props: string[], history?: any, route?: string): Record<string, unknown> => {
    if(search){
      const ups = new URLSearchParams(search);
      const queryObject = props.reduce((obj, prop) => {
        const val = ups.get(prop);
        return {...obj, [prop]: val };
      }, {});
      
      if(history && route){
        history.replace(route);   //remove the query string    
      }

      return queryObject;
    }

    return {};
}