import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { WaitOverlay } from "components";
import AtkTable, { HeadCell } from "components/atk-table";
import HoursRow, { rowHeader } from "./hours-list-row";
import TimerGroupRow, { getSortValue, groupRowHeader } from "./hours-group-row";
import { useDialogs, useLocalization } from "utils/hooks";
import { updateTimesheetSettings } from "../infra/timesheet-slice";
import { ITimer, TimesheetSettings } from "../infra/timesheet-types";
import { ISort, RootState } from "types";
import { groupHours } from "../infra/timesheet-helpers";
import { isThisYear } from "utils/date-helpers";

interface HoursListProps{
  hours: ITimer[] | null;
  useLocalSettings?: boolean;
  isWorking?: boolean;
  tableClasses?: any;
  dense?: boolean;
  settingsKey?: string;
  header?: HeadCell<ITimer>[];
  rowsPerPageOptions?: number[];
  grouping?: string;
}

const HoursList = (props: HoursListProps) => {
  const {hours, useLocalSettings, isWorking, tableClasses, dense, settingsKey, header, rowsPerPageOptions, grouping} = props;
  const dispatch = useDispatch();
  const {LSwap} = useLocalization();
  const settings = useSelector<RootState, TimesheetSettings>(state => state.timesheet.settings);
  const [localSettings, setLocalSettings] = useState<TimesheetSettings>({sort: "startTime", sortDir: "desc"});
  const {openDialog} = useDialogs();
  const tableHeader = header ?? rowHeader();
  const onlyCurrentYear = useMemo(() => !(hours?.find(h => !isThisYear(h.startTime))) , [hours]);

  const groups = useMemo(() => {
    if(hours && grouping){
      const items = groupHours(grouping, hours);
      return items;
    }
    else return hours;
  }, [hours, grouping]);

  const onTableSorted = (order: ISort) => {
    //Need to deal with the fact that there's no actual date field in the data
    const current = useLocalSettings ? localSettings : settings;
    const actual = order;
    if(order.sort === "date"){
      actual.sort = "startTime";
      if(current.sort === "startTime"){
        actual.sortDir = (current.sortDir === "asc" ? "desc" : "asc");
      }
    }
    
    if(useLocalSettings === true){
      setLocalSettings({...localSettings, ...actual});
    }
    else{
      dispatch(updateTimesheetSettings({ ...settings, ...actual }));
    }
  }

  useEffect(() => {
    if(grouping) setLocalSettings({sort: "date", sortDir: "desc"});
  }, [grouping]);

  return (
    <Grid container>
      <WaitOverlay isWaiting={isWorking} message="Working..." color="primary">
        {grouping && 
        <AtkTable
          rowId="id"
          header={groupRowHeader()}
          rows={groups || []}
          RowComponent={TimerGroupRow}
          dense={(dense !== false)}
          order={{ sort: localSettings.sort, sortDir: localSettings.sortDir }}
          getSortValue={getSortValue}
          emptyMessage={LSwap("No hours to display", "hours")}
          settingKey={settingsKey ?? "hours-group-list"}
          classes={tableClasses}
          rowsPerPageOptions={rowsPerPageOptions}
          extra={{
            onlyCurrentYear,
          }} />
        }
        {!grouping && 
          <AtkTable
            rowId="id"
            header={tableHeader}
            rows={hours || []}
            RowComponent={HoursRow}
            dense={(dense !== false)}
            order={{ sort: settings.sort, sortDir: settings.sortDir }}
            onSort={onTableSorted}
            emptyMessage={LSwap("No hours to display", "hours")}
            settingKey={settingsKey ?? "hours-list"}
            classes={tableClasses}
            rowsPerPageOptions={rowsPerPageOptions}
            extra={{
              LSwap: LSwap,
              onEdit: openDialog,
              onlyCurrentYear
            }} />
        }
      </WaitOverlay> 
    </Grid>
  );
}

export default HoursList;
