import _ from 'lodash';

const SETTINGS_KEY = "atk-finlancer-settings";

function getValueFromStorage(key: string) {
  const strValue = localStorage.getItem(key);
  let readVal: any = strValue;
  if (strValue) {
    try {
      const objVal = JSON.parse(strValue);
      readVal = objVal;
    }
    catch (ex) { } //ignore it if it's not an object
  }
  return readVal;
}

export function writeItem(key: string, value: any) {
  const storeVal = (_.isObject(value) || _.isArray(value)) ? JSON.stringify(value) : value;
  localStorage.setItem(key, storeVal);
}

export function readItem(key: string, defaultVal: any = null) {
  const value = getValueFromStorage(key);
  return value || defaultVal;
}

export function saveSetting(key: string, value: any) {
  const existing = getValueFromStorage(SETTINGS_KEY);
  let updated = { [key]: value };

  if (existing) {
    updated = {
      ...existing,
      ...updated,
    };
  }

  writeItem(SETTINGS_KEY, updated);
}

export function readSetting(key: string, defaultValue: any = null) {
  const existing = getValueFromStorage(SETTINGS_KEY);
  return existing && (key in existing) ? existing[key] : defaultValue;
}

