import React from "react";
import clsx from "clsx";
import { useParams, Link, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';
import { DateOrAddButton } from "components";
import { fontStyles, generalStyles, iconStyles } from "utils/styles";
import { useDialogs, useProject, useLocalization } from "utils/hooks";
import { Icons, IconNames } from "utils/common-classes";
import { getRoute } from "features/app";
import ProjectTimeline from "./project-timeline";
import ProjectTimerButton from "./project-timer-button";

const buildStyles   = makeStyles(theme => ({
  ...generalStyles(theme),
  ...fontStyles(theme),
  ...iconStyles(theme),
  root  : {
    padding: theme.spacing(2),
    // height: "100%",
  },
  headerRow: {
    height: 43,
  },
  actionsRow: {
    background: theme.palette.grey[50],
    borderTop: `1px solid ${theme.palette.grey[300]}`, //`
    borderBottom: `1px solid ${theme.palette.grey[300]}`, //`
    padding: `${theme.spacing(0.5)}px ${theme.spacing(0)}px`, //`
    marginLeft: theme.spacing(-2),
    width: `calc(100% + ${theme.spacing(4)}px)`,
  }
}));

const ClientSidebar = () => {
  const classes = buildStyles();
  const history = useHistory();
  const { projectId } = useParams<{ projectId: string }>();
  const project = useProject(projectId);
  const { L } = useLocalization();
  const { openDialog } = useDialogs();
  
  const onEditDate = (whichDate: string, isExpanded = true) => () => {
    if(!project) return;

    if(whichDate === "paidDate" && project.hasInvoices){
      if(project.lastInvoice){
        openDialog("invoice", project.lastInvoice.id);
      }
    }
    else{
      openDialog("project", project.id, {isExpanded, focusField: whichDate})
    }
  }

  if(!project) return null;
  
  return (
    <Grid id="client-sidebar" container direction="column" className={classes.root}>
      
      <Grid container wrap="nowrap" className={clsx(classes.mbl, classes.headerRow)}>
        <Tooltip title={L("project", true)}>
          <span className={clsx(classes.iconLeft, classes.grey)}>{Icons.project()}</span>
        </Tooltip>
        <Typography className={classes.title}>{project.name}</Typography>
      </Grid>

      <Grid container justify="center" className={clsx(classes.actionsRow, classes.mbl)}>
        <Tooltip title="Go back">
          <IconButton onClick={() => history.goBack()}><Icon>arrow_back</Icon></IconButton>
        </Tooltip>
        <Tooltip title="Edit">
          <IconButton component={Link} to={getRoute("projectEdit", project.id)}>{Icons.edit()}</IconButton>
        </Tooltip>
        <Tooltip title="Create an Invoice">
          <IconButton component={Link} to={getRoute("invoiceNew", "-1", `projectId=${project.id}`)}>{Icons.invoice()}</IconButton>
        </Tooltip> 
        <ProjectTimerButton project={project} size="medium" />       
      </Grid>

      <Grid container direction="column" wrap="nowrap" className={classes.mbl}>
        <Grid container className={classes.mb} wrap="nowrap">
          <Icon fontSize="small" className={clsx(classes.lightGrey, classes.iconLeft)}>business</Icon>
          <Typography component={Link} to={getRoute("client", project.client.id)} className={clsx(classes.bodyTextSmall, classes.grey, classes.link)}>{project.client.name}</Typography>
        </Grid>
        <Grid container className={classes.mb} wrap="nowrap">
          <Icon fontSize="small" className={clsx(classes.lightGrey, classes.iconLeft)}>bookmark_border_outlined</Icon>
          <Typography className={clsx(classes.bodyTextSmall, classes.grey)}>{project.category}</Typography>
        </Grid>
        <Grid container className={classes.mb} wrap="nowrap">
          <Icon fontSize="small" className={clsx(classes.lightGrey, classes.iconLeft)}>person_outlined</Icon>
          <Typography className={clsx(classes.bodyTextSmall, classes.grey)}>{project.client.contactName}</Typography>
        </Grid>
        <Grid container className={classes.mb} wrap="nowrap">
          <Icon fontSize="small" className={clsx(classes.lightGrey, classes.iconLeft)}>mail_outlined</Icon>
          <a href={`mailto:${project.client.contactEmail}`} className={classes.link}>
            <Typography className={clsx(classes.bodyTextSmall, classes.grey)}>{project.client.contactEmail}</Typography>
          </a>
        </Grid>
        <Grid container className={classes.mb} wrap="nowrap">
          <Icon fontSize="small" className={clsx(classes.lightGrey, classes.iconLeft)}>phone_outlined</Icon>
          <Typography className={clsx(classes.bodyTextSmall, classes.grey)}>{project.client.contactPhone}</Typography>
        </Grid>       
      </Grid>

      {/* <ProjectTimeline project={project} /> */}

      <Grid container direction="column" className={classes.mbl} wrap="nowrap">
        {/* <Grid item container alignItems="center">
          <Grid item sm={4} xs={6}>
            <Typography className={classes.caption}>Start</Typography>
          </Grid>
          <Grid item sm={8} xs={6}>
            <DateOrAddButton value={project.startDate} onEdit={onEditDate("startDate", false)}/>
          </Grid>
        </Grid> */}
        <Grid item container alignItems="center">
          <Grid item sm={4} xs={6}>
            <Typography className={classes.caption}>Due</Typography>
          </Grid>
          <Grid item sm={8} xs={6}>
            <DateOrAddButton value={project.dueDate} onEdit={onEditDate("dueDate", false)}/>
          </Grid>
        </Grid>
        <Grid item container alignItems="center">
          <Grid item sm={4} xs={6}>
            <Typography className={classes.caption}>Delivered</Typography>
          </Grid>
          <Grid item sm={8} xs={6}>
            <DateOrAddButton value={project.deliveredDate} onEdit={onEditDate("deliveredDate")}/>
          </Grid>
        </Grid>
        <Grid item container alignItems="center">
          <Grid item sm={4} xs={6}>
            <Typography className={classes.caption}>Invoiced</Typography>
          </Grid>
          <Grid item sm={8} xs={6}>
            <DateOrAddButton value={project.invoicedDate || project.lastInvoiceDate} onEdit={onEditDate("invoicedDate")}/>
          </Grid>
        </Grid>
        <Grid item container alignItems="center">
          <Grid item sm={4} xs={6}>
            <Typography className={classes.caption}>Paid</Typography>
          </Grid>
          <Grid item sm={8} xs={6}>
            <DateOrAddButton value={project.paidDate || project.lastPaymentDate} onEdit={onEditDate("paidDate")}/>
          </Grid>
        </Grid>
        {project.estimatedHours && 
          <Grid item container alignItems="center">
            <Grid item sm={4} xs={6}>
              <Typography className={classes.caption}>Estimated Hours</Typography>
            </Grid>
            <Grid item sm={8} xs={6}>
              <Typography className={classes.bodyText}>{project.estimatedHours}</Typography>
            </Grid>
          </Grid>
        }
      </Grid>

    </Grid>
  );
}

export default ClientSidebar;
