import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';
import { fontStyles, generalStyles, statusStyles } from "utils/styles";
import { differenceInDays, formatDistanceToNowStrict, isBefore, startOfDay } from "date-fns";
import { DISPLAY_SHORT_FORMAT, formatDate } from "utils/date-helpers";
import { ProjectWithInvoices } from "types";
import { IconNames } from "utils/common-classes";
import { orderBy, sumBy } from "lodash";
import { formatCurrency } from "utils/number-helpers";

const buildStyles   = makeStyles(theme => ({
  ...fontStyles(theme),
  ...generalStyles(theme),
  ...statusStyles(theme),
  dateBox: {
    // border: `0.5px solid ${theme.palette.grey[300]}`, //`
    borderRadius: 5,
    padding: `${theme.spacing(0.5)}px ${theme.spacing(0.5)}px`, //`
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    "& .MuiIcon-root": {
      margin: `${theme.spacing(0)}px ${theme.spacing(0.5)}px`, //`
      fontSize: "1.25rem", //"0.75rem",
    },
  },
  allGood: {
    color: theme.palette.grey[500],
  }
}));

const dateLabel = (today: Date, toCompare: Date) => {
  const daysToDue = differenceInDays(today, toCompare);
  switch(daysToDue){
    case 0: return "Today";
    case -1: return "Tomorrow";
    default:
      if(daysToDue > 0){
        //Overdue
        return formatDate(toCompare, DISPLAY_SHORT_FORMAT);
      }
      else if(daysToDue >= -5){
        return formatDistanceToNowStrict(toCompare, {unit: "day"});
      }
  }

  return formatDate(toCompare, DISPLAY_SHORT_FORMAT);
}

interface BoxProps{
  project: ProjectWithInvoices;
  dateType: "delivered" | "paid";
  className?: string;
}

interface DateProps{
  label: string;
  tip?: string;
  value: Date;
  status: "info" | "success" | "warning" | "danger";
  icon: string;
}

const calcDeliveredDate = (project: ProjectWithInvoices, today: Date): DateProps => {
  let nextDate: any = {};
  
  if(project.deliveredDate){
    nextDate = {label: "Delivered", tip: `Delivered on ${formatDate(project.deliveredDate, DISPLAY_SHORT_FORMAT)}`, value: project.deliveredDate as Date, status: "success", icon: IconNames.delivered };
  }
  else if(project.dueDate){
    const due = project.dueDate as Date;
    const daysToDue = differenceInDays(today, due);
    const status = (daysToDue > 0 ? "danger" : (daysToDue >= -5 ? "warning" : "info"));
    const tip = daysToDue === 0 ? "Due today" : (daysToDue > 0 ? `Due ${daysToDue} day(s) ago` : `Due in ${Math.abs(daysToDue)} days`);
    nextDate = {label: "Due", tip, value: due, status, icon: IconNames.due};
  }
  else{
    //no due date, so show start date
    const start = project.startDate as Date;
    const label = isBefore(start, today) ? "Started" : "Starts";
    nextDate = {label, tip: `Started on ${formatDate(start, DISPLAY_SHORT_FORMAT)}, no due date`, value: project.startDate as Date, status: "info", icon: IconNames.started};
  }

  return nextDate;
};

const calcPaidDate = (project: ProjectWithInvoices, today: Date): DateProps | null => {
  let info: DateProps | null = null;
  
  if(project.invoices){
    // if(project.uninvoiced) return info; //if there is uninvoiced 
    const unpaid = orderBy(project.invoices.filter(inv => !inv.paidDate), [p => p.invoiceDate], ["asc"]);
    if(unpaid && unpaid.length){
      const invDate = project.lastInvoice?.invoiceDate as Date;
      const invAmount = formatCurrency(project.lastInvoice?.amount);
      const daysToDue = differenceInDays(today, invDate);
      const status = (daysToDue > 30 ? "danger" : (daysToDue >= 27 ? "warning" : "info"));
      const totalDue = sumBy(unpaid, u => u.amount);
      const tip = unpaid.length === 1 ? `Last invoiced ${invAmount} on ${formatDate(invDate, DISPLAY_SHORT_FORMAT)}` : `There are ${unpaid.length} open invoices totaling ${formatCurrency(totalDue)}`;
      info = {label: "Invoiced", tip, value: invDate, status, icon: IconNames.invoice};
    }
    else{
      const paidDate = project.invoices[0].paidDate;  //invoices are ordered latest first
      info = {label: "Paid", tip: `Last paid on ${formatDate(paidDate, DISPLAY_SHORT_FORMAT)}`, value: paidDate as Date, status: "success", icon: IconNames.revenue };
    }
  }
  else if(project.paidDate){
    info = {label: "Paid", tip: `Paid on ${formatDate(project.paidDate, DISPLAY_SHORT_FORMAT)}`, value: project.paidDate as Date, status: "success", icon: IconNames.revenue };
  }
  else if(project.invoicedDate || project.lastInvoiceDate){
    const invDate = (project.invoicedDate || project.lastInvoiceDate) as Date;
    const daysToDue = differenceInDays(today, invDate);
    const status = (daysToDue > 30 ? "danger" : (daysToDue >= 27 ? "warning" : "info"));
    info = {label: "Invoiced", tip: `Invoice sent on ${formatDate(invDate, DISPLAY_SHORT_FORMAT)}`, value: invDate, status, icon: IconNames.invoice};
  }

  return info;  //not delivered yet
}

const calc = {
  delivered: calcDeliveredDate,
  paid: calcPaidDate
};

const ProjectDateBox = ({project, dateType, className}: BoxProps) => {
  const classes   = buildStyles();
  const today = startOfDay(new Date());
  const date = calc[dateType](project, today);
  if(!date) return null;

  const dateText = dateLabel(today, date.value);
  const statusClass = date.status === "success" ? classes.allGood : (date.status === "info" ? classes.allGood : (date.status === "warning" ? classes.warning : classes.danger));

  return (
    <Tooltip title={date.tip || date.label}>
      <Grid item className={clsx([classes.dateBox, statusClass, className])}>
        <Icon fontSize="small">{date.icon}</Icon>
        {/* <Typography className={clsx([classes.infoText, classes.marginRight])}>{date.label}</Typography> */}
        <Typography className={clsx([classes.bodyTextSmall, classes.marginRightDense, statusClass])}>{dateText}</Typography>
      </Grid>
    </Tooltip>
  ) ;
}

export default React.memo(ProjectDateBox);
