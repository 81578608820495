import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useInputs, useSnackbar, useTrackChanges } from "utils/hooks";
import { usePasswordChange } from "utils/firebase/firebase-provider";
import { formStyles, generalStyles, fontStyles } from "utils/styles";

const buildStyles   = makeStyles(theme => ({
  ...formStyles(theme),
  ...generalStyles(theme),
  ...fontStyles(theme),
  alertsGrid: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(-2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  sectionGrid: {
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    // borderBottom: `1px solid ${theme.palette.grey[300]}`, //`
  },
  sectionHeader: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
    color: theme.palette.secondary.main,
    fontSize: 16,
    fontWeight: 400,
  }, 
}));

interface IPasswordFields{
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const passwordFields: IPasswordFields = {
  currentPassword: "",
  newPassword: "",
  confirmPassword: "",
};

const validateChange = (currentPassword: string, newPassword: string, confirm: string) => {
  const cur = currentPassword.trim().toLowerCase();
  const newP = newPassword.trim().toLowerCase();
  const cnfP = confirm.trim().toLowerCase();
  if(cur.length === 0 || newP.length === 0 || cnfP.length === 0){
    return "All fields are required";
  }
  else if(newP !== cnfP){
    return "Confirm password doesn't match new password";
  }
  else if(cur === newP){
    return "New password is unchanged";
  }
  // else{
    //TODO: validate the complexity...
  // }

  return null;
}

const PasswordChangeForm = () => {
  const classes   = buildStyles();
  const [values, display, errors, binding, setValues] = useInputs<IPasswordFields>(passwordFields);
  const [isPwChanged, resetPw] = useTrackChanges(passwordFields, values);
  const { canChange, changePassword, providerName } = usePasswordChange();
  const notify = useSnackbar();

  async function onChangePassword(){
    //Validate the password change
    const error = validateChange(values.currentPassword, values.newPassword, values.confirmPassword);
    if(error){
      notify(error, "error");
    }
    else{
      const result = await changePassword(values.currentPassword, values.newPassword);
      if(result === true){
        notify("Password successfully changed", "success");
        setValues(passwordFields);
        resetPw(passwordFields);
      }
    }
  }

  return (
    <Grid container className={classes.sectionGrid} spacing={2}>
      <Grid container>
        <Typography className={classes.sectionHeader}>Change Your Password</Typography>
      </Grid>
      { !canChange && <Typography className={clsx([classes.infoText, classes.marginLeft])}>GigOps doesn&apos;t manage your password, you can change it at: {providerName}</Typography> }
      {canChange && 
        <>
          <Grid item sm={7} xs={12} className={classes.inputGridDense} container>
            <TextField id="currentPassword" type="password" fullWidth label="Current Password" variant="outlined" margin="dense" value={display?.currentPassword} {...binding.input} {...errors["currentPassword"]} autoComplete="off" />
          </Grid>
          <Grid item sm={7} xs={12} className={classes.inputGridDense} container>
            <TextField id="newPassword" type="password" fullWidth label="New Password" variant="outlined" margin="dense" value={display?.newPassword} {...binding.input} {...errors["newPassword"]} autoComplete="off" />
          </Grid>
          <Grid item sm={7} xs={12} className={classes.inputGridDense} container>
            <TextField id="confirmPassword" type="password" fullWidth label="Confirm New Password" variant="outlined" margin="dense" value={display?.confirmPassword} {...binding.input} {...errors["confirmPassword"]} autoComplete="off" />
          </Grid>
          <Grid item sm={5} xs={12} className={classes.inputGridDense} container justify="flex-end" alignItems="flex-end">
            <Button color="secondary" variant={isPwChanged ? "contained" : undefined} size="small" disabled={!isPwChanged} onClick={onChangePassword} className={classes.actionButton}>Change</Button>
          </Grid>
        </>
      }
    </Grid>
  );
}

export default PasswordChangeForm;
