import _ from "lodash";
import { AppThunk, initAction } from "store/root-reducer";
import { Invoice, QueryFilter } from "types";
import { useFirestore, STATUSES } from "utils/firebase";
import { datesToStrings, stringsToDates, getChanges, removeProps } from "utils/general-helpers";
import { REDUX_DATE_TIME_FORMAT } from "utils/date-helpers";
import { statusChanged, itemCreated, itemsLoaded, itemQueryLoaded, itemDeleted, itemUpdated } from "./invoices-slice";
import { itemsUpdated as hoursUpdated } from "features/timesheet/infra/timesheet-slice";
import { updateProject } from "features/projects";
import { BAD_PROPS } from "./invoices-api";

//-- load invoices
export const loadInvoices = (start: Date | null = null, end: Date | null = null, filter: QueryFilter | QueryFilter[] | null = null, queryKey: string | null = null, queryProps: any = null): AppThunk => async (dispatch, getState) => {
  const [api, uid] = useFirestore();
  const orgId = initAction(uid, getState);

  dispatch(statusChanged({ isWorking: true, error: null }));

  const result = await api.getInvoices(orgId, start, end, filter);
  if (result.statusCode === STATUSES.ok || result.statusCode === STATUSES.empty) {
    // need to deal with dates...
    const items = result.items?.map(i => {
      return {
        ...datesToStrings(i, REDUX_DATE_TIME_FORMAT)
      };
    });

    if(queryKey){
      await dispatch(itemQueryLoaded({key: queryKey, items: items || [], props: queryProps}));
    }
    else{
      await dispatch(itemsLoaded(items || []));
    }
  }
}

export const createInvoice = (model: Invoice): AppThunk => async (dispatch, getState) => {
  const [api, uid] = useFirestore();
  const orgId = initAction(uid, getState);

  await dispatch(statusChanged({isWorking: true, error: null}));

  const prepared = { ...model, userId: uid } as Invoice;  //Add the User Id to the invoice
  //create the invoice
  const result = await api.createInvoice(orgId, prepared); //
  if (result.ok) {

    //Update the local store
    const invoice = {id: result.key, ...result.data} as Invoice;
    const toStore = datesToStrings(invoice); //{id: result.key, ...datesToStrings(result.data.invoice)};
    await dispatch(itemCreated(toStore));

    //Update the project(s) to say it has invoices
    const prjIds = (invoice.projectId.indexOf(",") < 0) ? [invoice.projectId] : invoice.projectId.split(",");
    for(let i = 0; i < prjIds.length; i++){
      await updateProject(prjIds[i], {hasInvoices: true});
    }

    //Update the affected hours with the invoice info
    //TODO: make this an action in the timesheet actions, so it can be handled internally
    const hrsResult = await api.assignInvoiceToHours(orgId, invoice.id, invoice);
    if(hrsResult.ok){
      const hoursToUpdate = hrsResult.data.map((h: any) => datesToStrings(h));
      await dispatch(hoursUpdated(hoursToUpdate)); 
    }

    await dispatch(statusChanged({isWorking: false}));
  }
  else{
    await dispatch(statusChanged({isWorking: false, error: result.error}));
  }

  return result;
}

export const updateInvoice = (invoiceId: string, changes: Partial<Invoice>): AppThunk => async (dispatch, getState) => {
  const [api, uid] = useFirestore();
  const orgId = initAction(uid, getState);
  const app = getState().app;

  await dispatch(statusChanged({isWorking: true, error: null}));

  if (app.isInitialized) {
    //get the actual changes
    const invoice = getState().invoices.items?.find(prj => prj.id === invoiceId);
    if (invoice) {      
      const originalHours = [...invoice.hours || []];
      const cleanInvoice = stringsToDates(removeProps<Partial<Invoice>>(invoice, BAD_PROPS));
      const cleanChanges = stringsToDates(removeProps<Partial<Invoice>>(changes, BAD_PROPS));
      const actualChanges = getChanges(cleanInvoice as any, cleanChanges as any) as Partial<Invoice>;
      if (actualChanges !== null) {
        // dispatch(statusChanged({isWorking: true}));

        const prepared = stringsToDates(actualChanges);
        const result = await api.updateInvoice(app.org.id, invoiceId, prepared);
        if (result.statusCode === STATUSES.ok) {
          //Update the local store
          dispatch(itemUpdated({ id: invoiceId, changes: datesToStrings(prepared) }));

          //If one of the dates changed (paidDate or invoiceDate) need to change the invoiced hours
          const invoiceAfter = {...invoice, ...actualChanges};
          const hasDateChange = (!!actualChanges.invoiceDate || !!actualChanges.paidDate);
          if(hasDateChange){
            const addResult = await api.assignInvoiceToHours(orgId, invoiceId, invoiceAfter);
            if(addResult.ok){
              const hoursToUpdate = addResult.data.map((h: any) => datesToStrings(h));
              await dispatch(hoursUpdated(hoursToUpdate)); 
            }
          }
          
          //Now, need to check for changes to the hours, and adjust them accordingly
          if(!!actualChanges.hours){
            const removed = _.difference(originalHours, actualChanges.hours);
            if(removed && removed.length > 0){
              const rmResult = await api.removeInvoiceFromHours(orgId, removed);
              if(rmResult.ok){
                const hoursToUpdate = rmResult.data.map((h: any) => datesToStrings(h));
                await dispatch(hoursUpdated(hoursToUpdate)); 
              }
            }
            
            //If the dates changed, we've already done this, so don't re-do it...
            if(!hasDateChange){
              const added = _.difference(actualChanges.hours, originalHours);            
              if(added && added.length > 0){
                const addResult = await api.assignInvoiceToHours(orgId, invoiceId, invoiceAfter);
                if(addResult.ok){
                  const hoursToUpdate = addResult.data.map((h: any) => datesToStrings(h));
                  await dispatch(hoursUpdated(hoursToUpdate)); 
                }
              }
            }
          }

          dispatch(statusChanged({isWorking: false}));
          return invoiceId;
        }

        //TODO: deal with a failed update?
        dispatch(statusChanged({isWorking: false, error: result.error}));
        return result;
      }
    }
  }

  return null;
}

export const deleteInvoice = (invoiceId: string): AppThunk => async (dispatch, getState) => {
  const [api, uid] = useFirestore();
  if (!uid) throw new Error("User is not authorized.");

  const app = getState().app;
  if(!app.isInitialized)  throw new Error("Action attempted before app initialized.");
  const orgId = app.org.id;

  dispatch(statusChanged({isWorking: true}));
  //get the invoice so I can update the necessary hours...
  const getResult = await api.getInvoice(orgId, invoiceId);

  if(getResult.ok && !!getResult.data){
    const invoice = getResult.data;

    //Remove the invoice from any hours that are associated with it
    if(invoice.hours && invoice.hours.length){
      const hrsResult = await api.removeInvoiceFromHours(orgId, invoice.hours);
      if(hrsResult.ok){
        const hoursToUpdate = hrsResult.data.map((h: any) => datesToStrings(h));
        await dispatch(hoursUpdated(hoursToUpdate)); 
      }
    }

    //delete the invoice
    const result = await api.deleteInvoice(orgId, invoiceId); //
    if (result.ok) {
      await dispatch(itemDeleted({id: invoiceId}));
      dispatch(statusChanged({isWorking: false}));
      return true;
    }
  }
  else{
    //TODO: the invoice wasn't found.
    console.log("attempt to delete an invoice that doesn't exist");
    dispatch(statusChanged({isWorking: false}));
    return false;
  }
}