import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from "@material-ui/core/Typography";

const buildStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    height: "100%",
  },
  title: {
    fontSize: 26,
    fontWeight: 400,
    marginBottom: theme.spacing(3),
  },
  bar: {
    minWidth: "125px",
    width: "33%",
  },
}));

const AppInitializing = () => {
  const classes = buildStyles();

  return (
    <Grid id="app-initializing" container direction="column" alignContent="flex-start" className={classes.root}>
      <Typography variant="h5" color="primary" className={classes.title}>Initializing GigOps...</Typography>
      <LinearProgress className={classes.bar} />
    </Grid>
  );
}

export default AppInitializing;
