import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IStatus } from "types";
import { replaceItem } from "utils/general-helpers";
import { Integration } from "./integration-types";

interface IntegrationSettings {
  key: string; //"harvest",
  host: string; //"https://id.getharvest.com/oauth2/authorize",
  clientId: string; //"XCd5LxM8rDRfijXNY67REtGd",
  state: string; //"harvest",
}

interface InitialState {
  isInitialized: boolean,
  items: Array<Integration>;
  status: IStatus;
  settings: Array<IntegrationSettings>,
}

// const baseIntegration: Partial<Integration> = {
  // status: "not-set",
  // isConnected: false,  
// }

export const initialState: InitialState = {
  isInitialized: false,
  items: [],
  status: {
    isWorking: false,
    error: null,
  },
  settings: [
    {
      key: "harvest",
      host: "https://id.getharvest.com/oauth2/authorize",
      clientId: "XCd5LxM8rDRfijXNY67REtGd",
      state: "harvest",
    }
  ]
};

export const slice = createSlice({
  name: "integrations",
  initialState,
  reducers: {
    statusChanged: (state, action) => {
      state.status = {
        ...state.status,
        ...action.payload,
      };
    },
    itemsLoaded: (state, action: PayloadAction<Integration[]>) => {
      state.items = action.payload;
      state.isInitialized = true; //Boolean(!action.payload || action.payload.length === 0);
      state.status = {isWorking: false, error: null};
    },

    itemUpdated: (state, action: PayloadAction<{id: string, changes: Partial<Integration>}>) => {
      const id = action.payload.id;
      const changes = action.payload.changes;
      const existing = state.items.find(i => i.id === id);
      let updates = [];
      if(existing){
        const newVal = {...existing, ...changes, data: {...existing.data, ...changes.data}};
        updates = replaceItem(state.items, existing, newVal);
      }
      else{
        const newVal = {id, ...changes};
        updates = [...state.items, newVal];
      }

      state.items = updates as Integration[];
      state.status = {
        ...state.status,
        isWorking: false,
      };
    },

    // itemCreated: (state, action: PayloadAction<Integration>) => {
    //   if(state.isInitialized && state.items !== null){
    //     state.items.unshift(action.payload);
    //   }      
    //   state.status = { isWorking: false, error: null };
    // },
    
  }
});

export const { itemsLoaded, statusChanged, itemUpdated } = slice.actions;
export default slice.reducer;
