import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { selectHomeStats } from "../../projects/infra/projects-selectors";
import { StatChartCard } from "components";
import { selectMonthHours } from "features/timesheet";
import { loadProjectHours } from "features/projects";

const buildStyles = makeStyles(theme => ({
  root: {
  },
  barContent: {
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`, //`
  }, 
  itemGrid: {
    position: "relative",
  },
  divider: {
    height: "33%",
    top: "46%",
    right: 0,
    position: "absolute",
  } 
}));

interface StatBarProps{
  onClick: (action: string) => void;
}

const ProjectDashboardStats = ({onClick}: StatBarProps) => {
  const classes = buildStyles();
  const dispatch = useDispatch();
  const stats = useSelector(selectHomeStats);
  const timeStats = useSelector(selectMonthHours);

  React.useEffect(() => {
    if(timeStats === null){
      dispatch(loadProjectHours());
    }
  }, []);

  if(!stats && !timeStats) return null;

  return (
    <Grid id="project-stats-bar" container className={classes.root} spacing={2}>
      <Grid item md={4} sm={6} xs={12} className={classes.itemGrid}>
        <StatChartCard stat={stats.revenue} onClick={onClick} variant="transparent"/>
      </Grid>

      <Grid item md={4} sm={6} xs={12} className={classes.itemGrid}>
        <StatChartCard stat={stats.assigned} onClick={onClick} variant="transparent"/>
      </Grid>

      {timeStats && 
        <Grid item md={4} sm={6} xs={12} className={classes.itemGrid}>
          <StatChartCard stat={timeStats.month} onClick={onClick} variant="transparent" format="float"/>
        </Grid>
      }

      {/* <Grid item md={4} sm={6} xs={12} className={classes.itemGrid} container justify="space-evenly">
        <Grid item md={5}>
          <StatChartCard stat={stats.uninvoiced} onClick={onClick} variant="transparent"/>
        </Grid>
        <Grid item md={5}>
          <StatChartCard stat={stats.unpaid} onClick={onClick} variant="transparent"/>
        </Grid>
      </Grid> */}

    </Grid>
  );
}

export default memo(ProjectDashboardStats)
ProjectDashboardStats.displayName = "ProjectDashboardStats";