import React, { useMemo } from "react";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import ChartPanel from "features/dashboard/chart-panel";
import { IClient, RootState } from "types";
import { useSelector } from "react-redux";
import { selectClientScore } from "../infra/client-selectors";
import { useLocalization } from "utils/hooks";

const buildStyles   = makeStyles(theme => ({
  root  : {
    padding     : theme.spacing(1),
  },
  title   : {
    fontSize    : 22,
    fontWeight  : 500,
    textAlign   : "center",
    marginBottom: theme.spacing(1),
  },
  subTitle  : {
    fontSize  : 17,
    fontWeight: 500,
    textAlign : "center",
    marginBottom  : theme.spacing(3),
    color     : theme.palette.grey[800],
  },
}));

type chartSize = "s" | "m" | "l";

const scoreDonutOptions = (startColor: string, endColor: string, bgColor: string, labelColor: string, props: any, score: number | null) =>  ({
  chart: {
    type: "radialBar",    
  },
  stroke: {
    lineCap: "round",
  },
  labels: ["Progress"],
  colors: [startColor],
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: "60%",
        background: bgColor,
      },
      track: {
        dropShadow: {
          enabled: true,
          top: 2,
          left: 0,
          blur: 2,
          opacity: 0.15
        }
      },
      dataLabels: {
        name: {
          offsetY: score === null ? -2 : -7,
          show: true,
        },
        value: {
          color: labelColor, //"#fff",
          offsetY: 0, //props.offsetY, //10,
          fontSize: "22px", //props.fontSize, //"30px",
          show: true,          
        },
        total: {
          color: `${labelColor}77`, //"#fff",
          fontSize: "13px", //"30px",
          show: true,
          label: score === null ? ["No","Rating"] : 'Score',
          formatter: (val: any) => { 
            return score === null ? "" : val.config.series[0]; 
          },
        }
      },    
    },  
  },
  grid: {
    padding: {
      top: props.padding, //-20,
      bottom: props.padding, //-20,
      left: props.padding, //-20,
      right: props.padding, //-20,
    },
  },
});

const chooseColors = (value: number | null, theme: Theme) => {
  if(value === null){
    return {
      startColor: theme.palette.grey[500], 
      endColor: theme.palette.grey[400], 
      bgColor: `${theme.palette.grey[400]}44`, //`
      labelColor: theme.palette.grey[800]
    };
  }
  else if(value < 25){
    return {
      startColor: theme.palette.error.main, 
      endColor: theme.palette.error.light, 
      bgColor: `${theme.palette.error.main}44`, //`
      labelColor: theme.palette.error.contrastText
    };
  }
  else if(value < 50){
    return {
      startColor: theme.palette.warning.dark, 
      endColor: theme.palette.warning.light, 
      bgColor: `${theme.palette.warning.main}44`, //`
      labelColor: theme.palette.warning.contrastText,
    };
  }
  else if(value < 75){
    return {
      startColor: theme.palette.info.dark, 
      endColor: theme.palette.info.light, 
      bgColor: `${theme.palette.info.main}44`, //`
      labelColor: theme.palette.info.contrastText
    };
  }
  else{
    return {
      startColor: theme.palette.success.main, 
      endColor: theme.palette.success.light, 
      bgColor: `${theme.palette.success.main}44`, //`
      labelColor: theme.palette.success.contrastText,
    };
  }
}

interface ControlProps {
  client: IClient;
  // onNav: (view: string) => void;
}

const ClientScore = ({client}: ControlProps) => {
  const theme = useTheme();
  // const { LSwap } = useLocalization();
  const score = useSelector((state: RootState) => selectClientScore(state, client.id));
  
  // if(score === null){
  //   return (
  //     <Grid container justify="center" alignItems="center">
  //       <Button size="small">{LSwap("Rate Client", "client")}</Button>
  //     </Grid>
  //   )
  // }
  
  const chartProps = {fontSize: "22px", chartHeight: 100, padding: -7, offsetY: 10,}; //getChartProps("m");
  const chartData = score === null ? [0] : [score];
  const colors = chooseColors(score, theme);
  const chartOptions = scoreDonutOptions(colors.startColor, colors.endColor, colors.bgColor, colors.labelColor, chartProps, score);

  return (
    <Grid container justify="center" alignItems="center">
      <ChartPanel options={chartOptions} series={chartData} type="radialBar" height={chartProps.chartHeight} width="100%"/>      
    </Grid>
  );
}

export default ClientScore;
