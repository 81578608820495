import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import Grid from "@material-ui/core/Grid";
import Chip from '@material-ui/core/Chip';
import { selectHeroMetrics, selectHeroAreas, loadProjectHours } from "features/projects";
import { heroAreaOptions, miniBarWithOptions } from "./dashboard-helpers";
import { formatCurrency, formatHours } from "utils/number-helpers";
import ChartPanel from "./chart-panel";
import { getSeries } from "utils/chart-helpers";
import HeroMetric from "./ui/hero-metric";
import { trackClick } from "utils/mixpanel";

const buildStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    background: theme.palette.common.white,
  },
  statGrid: {
    margin: `${theme.spacing(2)}px ${theme.spacing(0)}px`, //`
  },
  statInnerGrid: {

  },
  arrow: {
    zIndex: 11, //tooltip on the chart is 12, so need to be below that, but above the chart itself due to the overlap
  },
  statTitle: {
    fontSize: 13,
    color: theme.palette.grey[600],
    fontWeight: 300,
    marginBottom: theme.spacing(0.5),
  },
  statValue: {
    fontSize: 30,
    color: theme.palette.success.main,
    fontWeight: 300,
    // marginBottom: theme.spacing(0.5),
  },
  statChart: {
    marginTop: theme.spacing(-4),
  },
  title: {
    fontSize: 22,
    fontWeight: 500,
    textAlign: "center",
    marginBottom: theme.spacing(1),
  },
  subTitle: {
    fontSize: 17,
    fontWeight: 500,
    textAlign: "center",
    marginBottom: theme.spacing(3),
    color: theme.palette.grey[800],
  },
  monthsRow: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  chip: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  areaContainer: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    }
  }
}));

const HeroWidget = () => {
  const classes = buildStyles();
  const dispatch = useDispatch();
  const metrics = useSelector(selectHeroMetrics);
  const areas = useSelector(selectHeroAreas);
  const [months, setMonths] = React.useState(12);

  React.useEffect(() => {
    dispatch(loadProjectHours()); //won't re-load if they've already been loaded
  }, []);

  const preparedMetrics = React.useMemo<any>(() => {
    if(!metrics) return {};
    const items = _.mapValues(metrics, (metric: any) => {
      return {
        ...metric,
        chartData: metric.months ? [getSeries(metric.id, "bar", metric.months.slice(metric.months.length - months), metric.id)] : [],
      }
    });

    return items;
  }, [metrics, months]);

  const preparedAreas = React.useMemo<any>(() => {
    if(!areas) return null;
    const items = areas.map(area => 
      getSeries(area.id, "area", area.months?.slice(area.months?.length - months), area.id)
    );
    return items;
  }, [areas, months]);

  const preparedOptions = React.useMemo(() => { 
    const keys = areas.map(a => a.id);
    const options = heroAreaOptions(keys);
    return options;
  }, [areas]);

  const isReady = React.useMemo(() => {return !!metrics && !!areas}, [metrics, areas]);

  const metricWidth = React.useMemo<3 | 4 | 6 | 12>(() => {
    switch(_.keys(metrics).length){
      case 1: return 12;
      case 2: return 6;
      case 3: return 4;
      case 4: return 3;
      default: return 3;
    }
  }, [metrics]);

  const toggleMonths = (val: number) => {
    trackClick("hero-months", val.toString());
    setMonths(val);
  }

  return (
    <Grid id="work-chart" container className={classes.root}>
      <Grid item xs={12} container>
        {(isReady) && 
          _.keys(preparedMetrics).map((pm: string) => {
            const metric = preparedMetrics[pm];
            const formatter = (pm === "hours") ? formatHours : formatCurrency;
            return (
            <HeroMetric key={pm} metricKey={pm} width={metricWidth} label={metric.label || pm} chartOptions={miniBarWithOptions(metric.formatter, pm)} formatter={formatter} metricData={metric} />
            );
          })
        }
      </Grid>
      {(isReady) && 
        <Grid container className={classes.areaContainer}>
          <Grid container>
            <ChartPanel options={preparedOptions} series={preparedAreas} type="area" height={300} minWidth={300} />
          </Grid>
          <Grid item xs={12} container justify="center" className={classes.monthsRow}>
            <Chip variant={(months === 3) ? "default" : "outlined"} color="primary" label="3 months" className={classes.chip} clickable onClick={() => toggleMonths(3)} />
            <Chip variant={(months === 6) ? "default" : "outlined"} color="primary" label="6 months" className={classes.chip} clickable onClick={() => toggleMonths(6)} />
            <Chip variant={(months === 12) ? "default" : "outlined"} color="primary" label="1 year" className={classes.chip} clickable onClick={() => toggleMonths(12)} />
            <Chip variant={(months === 24) ? "default" : "outlined"} color="primary" label="2 years" className={classes.chip} clickable onClick={() => toggleMonths(24)} />
          </Grid>
        </Grid>
      }
    </Grid>
  );
}

export default HeroWidget;
