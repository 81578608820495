import React, { useMemo } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Drawer from "@material-ui/core/Drawer";
import Button from '@material-ui/core/Button';
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import SettingsIcon from '@material-ui/icons/Settings';
import SidebarItem from "./sidebar-item";
import { LabelType } from "types";
import { getRoute, sidebarConfig } from "./app-routes";
import { useLocalization, useWindowSize } from "utils/hooks";
import { useMediaQuery } from "@material-ui/core";

interface IProps {
  isOpen: boolean;
  toggle: () => void;
}

const drawerWidth = 190;

const buildStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(8) + 1,
    },
  },
  drawerRoot: {
    paddingTop: 65,
    height: "100%",
    overflow: "hidden",
    flexWrap: "nowrap",
  },
  drawerMenu: {

  },
  drawerFooter: {
    borderTop: `1px solid ${theme.palette.grey[300]}`, //`
    padding: theme.spacing(1),
  },
  settingsButton: {
    color: theme.palette.grey[600],
    fontWeight: 400,
    fontSize: '1rem',
    textTransform: "none",
    "& svg": {
      marginRight: theme.spacing(1),
    }
  },
  hidden: {
    transition: theme.transitions.create("opacity", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    opacity: 0,
    display: "none",
  }
}));

const AppSidebar = (props: IProps) => {
  const classes = buildStyles();
  const {L, LSwap} = useLocalization();
  const { height } = useWindowSize();
  const isShort = useMediaQuery('(max-height:720px)');
  
  const localizedSidebar = useMemo(() => {
    return sidebarConfig.map(group => {
      if (!group.items) return group;

      const items = group.items?.map(item => {
        return {
          ...item,
          label: L(item.labelId as (keyof LabelType), true),
        };
      });

      return { ...group, items: items };
    });
  }, [LSwap]);

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: props.isOpen,
        [classes.drawerClose]: !props.isOpen,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: props.isOpen,
          [classes.drawerClose]: !props.isOpen,
        }),
      }}
    >
      <Grid container direction="column" justify="space-between" className={classes.drawerRoot}>
        <Grid item container direction="column" className={classes.drawerMenu}>
          {localizedSidebar.map((item: any) => (
            <SidebarItem key={item.id} section={item} isOpen={props.isOpen} size={isShort ? "sm" : "md"}/>
          ))}
        </Grid>
        <Grid item container className={classes.drawerFooter} justify="space-between">
          <Button size="small" component={Link} to={getRoute("profileSettings")} className={clsx({[classes.settingsButton]: true, [classes.hidden]: !props.isOpen})}>
            <Grid container alignItems="center">
              <SettingsIcon/>
              Settings
            </Grid>
          </Button>
          <IconButton component={Link} to={getRoute("profileSettings")} size={isShort ? "small" : "medium"} className={clsx({[classes.hidden]: props.isOpen})} title="Application Settings">
            <SettingsIcon/>
          </IconButton>
          <IconButton onClick={props.toggle} size={isShort ? "small" : "medium"}  title={props.isOpen ? "Collapse Sidebar" : "Expand Sidebar"}>
            {props.isOpen && <ChevronLeftIcon />}
            {!props.isOpen && <ChevronRightIcon />}
          </IconButton>
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default React.memo(AppSidebar);
