import React, { useEffect } from 'react';
import clsx from "clsx";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import { HeadCell } from "../atk-table/table-header";
import { ISort, Order } from 'types';
import { stableSort, getComparator } from "../atk-table/table-helpers";
import { useLocalSetting } from "utils/hooks";
import AtkListTable from "./list-table";
import AtkListCards from "./list-cards";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listRoot: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
      border: `1px solid ${theme.palette.grey[300]}`, //`
    }, 
    transparentPaper: {
      background: "none",
      borderWidth: 0,
    }       
  }),
);

export type ListDisplayType = "table" | "cards" | "board";

interface IListProps<T> {
  displayType?: ListDisplayType;
  items: any[];
  tableColumns?: HeadCell<T>[];
  rowId?: string;
  tableRowComponent?: any;
  cardComponent?: any;
  selectable?: boolean;
  dense?: boolean;
  onSelected?: (selectedItems: string[]) => void;
  extra?: any;
  order?: ISort;
  onSort?: (order: ISort) => void;
  emptyMessage?: string;
  settingKey?: string;
  noPaging?: boolean;
  noHeader?: boolean;
  classes?: any;
  selected?: string[];
  isWorking?: boolean;
  caption?: string;
  rowsPerPageOptions?: number[];
  getSortValue?: (row: T, col: string) => any;
}

export interface RowProps<T> {
  rowIndex: number;
  item: T;
  cols: HeadCell<T>[];
  isSelected: boolean;
  onSelected: (event: React.MouseEvent<unknown>) => void;
  CheckboxCell: any;
  labelId: string;
  isWorking?: boolean;
  extra?: any;
}

export default function AtkList<T>(listProps: IListProps<T>) {
  const classes = useStyles();
  const { displayType, items, tableColumns, classes: classOverrides, isWorking } = listProps;
  const [settings, saveSettings] = useLocalSetting(listProps.settingKey || "atk-list", { rowCount: 10 });
  const defSortCol = tableColumns ? (tableColumns.find(h => !!h.defaultSortDir) || tableColumns[0]) : null;
  const [sortDir, setOrder] = React.useState<Order>(defSortCol?.defaultSortDir || 'asc');
  const [sort, setOrderBy] = React.useState<keyof T | undefined>(defSortCol?.id);
  const [page, setPage] = React.useState(0);
  const rowsPerPageOptions = listProps.rowsPerPageOptions ?? [5, 10, 25, 50];
  const [rowsPerPage, setRowsPerPage] = React.useState(settings.rowCount || 10);
  const isTable = Boolean(!displayType || displayType === "table");
  const paginationLabel = isTable ? undefined : "Items per page";

  //Perform some checks to make sure we have the right props.
  if(isTable && (!tableColumns || !listProps.tableRowComponent)){
    throw new Error("List with display type 'table' must have 'tableHeaderCells' and 'tableRowComponent' property values.");
  }
  else if(!isTable && !listProps.cardComponent){
    throw new Error("List with display type 'cards' must have 'cardComponent' property value.");
  }

  //Called when a user clicks something to initiate a sort
  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof T) => {
    if (listProps.onSort && listProps.order) {
      const isAsc = listProps.order.sort === property && listProps.order.sortDir === 'asc';
      const sortDir = isAsc ? 'desc' : 'asc';
      listProps.onSort({ sort: property.toString(), sortDir: sortDir });
    }
    else {
      const isAsc = sort === property && sortDir === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    }
  };

  //Effect to deal with changes to the data, and reset the page to 0
  useEffect(() => { setPage(0); }, [items]);

  //Effect to sync the sort from the props to the table sort
  useEffect(() => {
    //TODO: Deal with sorting better when not a table
    if(tableColumns){
      setOrder(listProps.order?.sortDir || "asc");
      const sortCol = tableColumns.find(h => h.id === listProps.order?.sort);
      setOrderBy(sortCol?.id || defSortCol?.id);
    }
  }, [listProps.order]);

  const handleChangePage = (event: unknown, newPage: number) => {
    if(!!isWorking) return;
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(!!isWorking) return;
    const newVal = parseInt(event.target.value, 10);
    setRowsPerPage(newVal);
    setPage(0);
    if (listProps.settingKey) saveSettings({ rowCount: newVal });
  };

  //Function to check if a row is selected or not
  const isEmpty = items.length === 0;
  
  //Creates the list of rows that are visible based on sort, page and page size
  const listItems = React.useMemo(() => {
    const sortedItems = (sort && sortDir) ? stableSort(items, getComparator(sortDir, sort, listProps.getSortValue)) : items;
    const visibleItems = sortedItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    return visibleItems;
  }, [items, sort, sortDir, page, rowsPerPage]);

  return (
    <div className={classes.listRoot}>
      <Paper className={clsx(classes.paper, (!isTable && classes.transparentPaper), classOverrides?.paper)} elevation={0}>

        {(displayType === "cards" && !! listProps.cardComponent) &&
          <AtkListCards items={listItems} Card={listProps.cardComponent} />
        }

        {(displayType === "table" && !!tableColumns) && 
          <AtkListTable 
            columns={tableColumns} 
            rows={listItems} 
            rowId={listProps.rowId}   //TODO: add an isId prop to the HeadCell<T> type instead of this?
            RowComponent={listProps.tableRowComponent}
            order={{sort: sort as string, sortDir}}
            onRequestSort={handleRequestSort}
            onSort={listProps.onSort}
            selectable={listProps.selectable}
            selected={listProps.selected}
            onSelected={listProps.onSelected}
            classes={listProps.classes}
            noHeader={listProps.noHeader}
            dense={listProps.dense}
            emptyMessage={listProps.emptyMessage}
            isWorking={listProps.isWorking}
            caption={listProps.caption}
            extra={listProps.extra}            
          />
        }

        {(!listProps.noPaging && !isEmpty) &&
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={items.length}
            rowsPerPage={rowsPerPage}
            page={(items.length < rowsPerPage) ? 0 : page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage={paginationLabel}         
          />
        }
      </Paper>
    </div>
  );
}
