import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';
import DoneIcon from '@material-ui/icons/Done';
import { RowProps, HeadCell } from "components/atk-table";
import { Invoice, LFunc, IClient } from "types";
import { formatCurrency } from "utils/number-helpers";
import { formatDate } from "utils/date-helpers";
import { WaitButton } from "components";
import { useHover } from "utils/hooks";
import { getRoute } from "features/app";

export interface InvoiceRow extends Invoice {
  client?: IClient;
  status?: any;
  alerts?: any;
}

export const rowHeader = (L: LFunc, withProject = false, withClient = false): HeadCell<InvoiceRow>[] => {
  let items: HeadCell<InvoiceRow>[] = [
    { id: "invoiceDate", label: "Date", },
    { id: 'number', label: 'Number' },
    { id: 'amount', align: "right", label: 'Amount' },
    { id: "status", align: "center", label: "Status", },
    { id: "actions", label: "", noSort: true, width: 5},
  ];

  if(withClient){
    items = [...items.slice(0, 2), {id: "client", label: "Client"}, ...items.slice(2)];
  }
  if(withProject){  //add the project field if we need to
    items = [...items.slice(0, 2), {id: "project", label: "Project"}, ...items.slice(2)];
  }
  
  return items;
}

const doneIcon = (date: Date | string | null | undefined, showDate = false) => {
  if (!date) return null;
  else if (showDate === true) return date;
  return (
    <Tooltip title={`paid on ${formatDate(date)}`}>
      <DoneIcon color="primary" fontSize="small" />
    </Tooltip>
  );
}

const invoiceStatus = (item: Invoice) => {
  if (!!item.paidDate) {
    return doneIcon(item.paidDate); //"paid";
  }
  else if (!!item.dueDate) {
    return `due ${formatDate(item.dueDate)}`;   //`
  }
  else {
    return `invoiced ${formatDate(item.invoiceDate)}`;
  }
}

const InvoiceListRow: FunctionComponent<RowProps<InvoiceRow>> = ({ item, cols, labelId, isWorking, extra }) => {
  const classes = extra.classes; // buildStyles();
  const [isDeleting, setDeleting] = React.useState(false);
  const status = React.useMemo(() => invoiceStatus(item), [item]);
  const url = getRoute("invoice", item.id); //`
  const projectUrl = getRoute("project", item.projectId);
  const clientUrl = getRoute("client", item.clientId);
  const hasProject = !!cols.find(col => col.id === "project");
  const hasClient = !!cols.find(col => col.id === "client");
  const [hoverRef, isHovered] = useHover();

  function onDelete(){
    if(!extra.onDelete) return;

    if(isDeleting){
      extra.onDelete(item);
      // setDeleting(false);
    }
    else setDeleting(true);
  }

  if(isDeleting){
    return (
      <TableRow className={classes.deleteRow}>
        <TableCell colSpan={3}>
          <Typography>Are you sure you wish to delete invoice {item.number}?</Typography>
        </TableCell>
        <TableCell colSpan={3 + (hasProject ? 1 : 0) + (hasClient ? 1 : 0)}>
          <Grid container justify="flex-end">
            <WaitButton isWaiting={isWorking} size="small" color="default" onClick={() => setDeleting(false)}>No</WaitButton>
            <WaitButton isWaiting={isWorking} size="small" color="secondary" onClick={onDelete}>Yes</WaitButton>
          </Grid>
        </TableCell>
      </TableRow>
    );
  }

  const rowActions = [
    {id: 0, icon: "edit_outlined", title: "Edit this invoice", onClick: extra.onEdit(item.id), isAvailable: !!extra.onEdit },
    {id: 1, icon: "delete_outlined", title: "Delete this invoice", onClick: onDelete, isAvailable: !!extra.onDelete },
  ]

  return (
    <TableRow hover tabIndex={-1} ref={hoverRef}>
      <TableCell component="th" id={labelId} scope="row" padding="default"> {formatDate(item.invoiceDate)} </TableCell>
      <TableCell> <Link to={url}>{item.number || "- no number -"}</Link> </TableCell>
      {hasProject && 
        <TableCell>
          {item.projectId === "multiple" ? <Typography>- multiple -</Typography> : <Link to={projectUrl}>{item.project?.name}</Link>}
        </TableCell>
      }
      {hasClient && 
        <TableCell>
          <Link to={clientUrl}>{item.client?.name}</Link>
        </TableCell>
      }      
      <TableCell align="right"> <Typography>{formatCurrency(item.amount, false)}</Typography> </TableCell>
      <TableCell align="center">{status}</TableCell>
      <TableCell align="right">
        <div style={{position: "relative"}}>
          <Grid container style={{position: "absolute", display: isHovered ? "block" : "none", marginTop: -12, right: 0, width: 60}}>
          {
              rowActions.map(action => {
                if(action.isAvailable){
                  return(
                    <IconButton key={action.id} title={action.title} size="small" onClick={action.onClick}>
                      <Icon fontSize="small">{action.icon}</Icon>
                    </IconButton>
                  )
                }
                return null;
              })
            }
          </Grid>
        </div>
      </TableCell>
    </TableRow>
  );
}

export default InvoiceListRow;
