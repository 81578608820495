import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from "@material-ui/core/Typography";
import { FallbackProps } from "react-error-boundary";
import { cardStyles, fontStyles, generalStyles } from "utils/styles";
import { useHistory } from "react-router-dom";
import { routes } from "features/app";

const buildStyles   = makeStyles(theme => ({
  ...generalStyles(theme),
  ...fontStyles(theme),
  ...cardStyles(theme),
  root  : {
    padding     : theme.spacing(1),
    marginTop: theme.spacing(8),
    height: "100%",
  },
  errorRow: {
    marginTop: "15%",
  },
  errorCard: {
    width: "40%",
    background: `${theme.palette.error.light}33`, //`
    borderColor: theme.palette.error.main,
  },
  errorHeader: {
    backgroundColor: `${theme.palette.error.light}55`, //`
    borderBottomColor: theme.palette.error.main,
    color: theme.palette.error.dark,
  },
  errorText: {
    textAlign: "center",
  },
  giphyGrid: {
    width: "67%",
  },
  attribution: {
    fontSize: "0.6rem",
    textDecoration: "none",
    width: "100%",
    textAlign: "right",
  }
}));

const ErrorContainer = ({error, resetErrorBoundary}: FallbackProps) => {
  const classes   = buildStyles();
  const history = useHistory();

  const goHome = () => {
    history.replace(routes.dashboard);
    resetErrorBoundary();
  }

  return (
    <Grid id="error-view" container justify="center" className={classes.root}>
      <Grid item container direction="column" alignItems="center" className={classes.errorRow}>
        <Paper variant="outlined" className={clsx(classes.card, classes.errorCard)}>
          <Grid container direction="column" alignItems="center" className={clsx(classes.cardHeader, classes.errorHeader)}>
            <Typography className={classes.title}>Error</Typography>
           <Typography className={classes.title}>{"Uhm... that wasn't supposed to happen"}</Typography>
          </Grid>
          <Grid container direction="column" alignItems="center" className={classes.cardBody}>

            <Grid container direction="column" className={classes.giphyGrid}>
              <iframe src="https://giphy.com/embed/3NgcLVc9B2tEPUUCMz" width="100%" frameBorder="0" className="giphy-embed" allowFullScreen></iframe>
              <a className={classes.attribution} href="https://giphy.com/gifs/3NgcLVc9B2tEPUUCMz">via GIPHY</a>
            </Grid>

            <Grid container justify="center" className={classes.mt}>
              <Typography className={clsx(classes.bodyText, classes.errorText)}>{"But seriously, we apologize for the inconvenience, and we'll get on it right away."}</Typography>
              <Button onClick={goHome} variant="outlined" className={classes.mtl}>Get me outta here</Button>            
            </Grid>
          </Grid>
          
        </Paper>
      </Grid>
    </Grid>
  );
}

export default ErrorContainer;
