import { getDaysInMonth, isSameDay, isSameMonth, isWithinInterval, startOfDay, startOfMonth } from "date-fns";
import { orderBy, sumBy } from "lodash";
import { DateRange, IProjectSummary } from "types";
import { monthShortLabels } from "utils/date-helpers";
import { getActiveProjects } from "./metric-calcs-common";
import { metricChartOptions, metricHeatmapOptions } from "./metric-chart-helpers";

interface IAreaSeries{
  name: string;
  type: string;
  data: [Date | string, number][];
}

export const TimeMetrics = [
  // {
  //   id: "time-total-alltime",
  //   category: "time",
  //   period: ["always"],
  //   label: "All Time",
  //   description: "The total number of hours you've worked",
  //   why: "",
  // },
  {
    id: "time-total",
    category: "time",
    period: ["year", "quarter", "month", "week"],
    label: "Total Hours",
    description: "The total number of hours you've worked ~period~",
    why: "",
  },
  {
    id: "time-monthly-average",
    category: "time",
    period: ["month", "week"],
    label: "Per Month",
    description: "The average number of monthly hours you worked ~period~",
    why: "",
  },
  {
    id: "time-workdays",
    category: "time",
    period: ["year", "quarter", "month"],
    label: "Workdays",
    description: "The number of days you worked ~period~",
    why: "",
  }
];

const flattenHours = (range: DateRange, projects: IProjectSummary[]) => {
  const flattened = projects.reduce((map: {date: Date; minutes: number}[], prj) => {
    const prjDays = prj.days.filter(d => isWithinInterval(d.date, range));
    prjDays.forEach(day => {
      const index = map.findIndex(m => isSameDay(m.date, day.date));
      if(index >= 0){
        map[index].minutes += day.minutes;
      }
      else{
        map.push({date: day.date, minutes: day.minutes});
      }      
    });

    return map;
  }, []);

  const ordered = orderBy(flattened, ["date"], ["asc"]);
  return ordered;
}

//"time-total"
export const calcTimeTotal = (projects: IProjectSummary[], monthEnds: Date[]) => {
  const total = sumBy(projects, p => p.minutes) / 60;

  const chartData = monthEnds.map(endDate => {
    //For each day, see if it's in this month, and if so, total it up...
    const monthTotal = projects.reduce((sum, prj) => {
      const monthDays = prj.days.filter(d => isSameMonth(endDate, d.date));
      const monthDaysSum = sumBy(monthDays, md => md.minutes);
      return sum + monthDaysSum;
    }, 0);
    return [startOfDay(endDate), monthTotal / 60];
  });

  return {
    value: total,
    displayValue: `${(parseFloat(total.toFixed(0))).toLocaleString()} hours`,
    chartData: [{
      name: "Total Hours",
      type: "bar",
      data: chartData,
    }],
    chartOptions: metricChartOptions("count", "hours"),
    chartType: "bar",
  };
}

//"time-monthly-average"
export const calcTimePerMonth = (range: DateRange, projects: IProjectSummary[], monthEnds: Date[]) => {
  const matches = getActiveProjects<IProjectSummary>(range, projects);
  const total = sumBy(matches, m => m.minutes || 0) / 60;
  const now = new Date();
  
  //TODO: Deal with pervious years (just use the full 12 months)
  let month = now.getMonth();
  const day = now.getDate();
  const daysInMonth = getDaysInMonth(now);
  if(day != daysInMonth){
    const frac = day / getDaysInMonth(now);
    month += (1 - frac);
  }

  // const doy = getDayOfYear(now);
  // const weeks = doy / 7;

  const flatHours = flattenHours(range, projects);
  const chartData = monthEnds.reduce((accum: IAreaSeries[], endDate) => {
    const start = startOfMonth(endDate);
    const monthHours = flatHours.filter(f => isWithinInterval(f.date, {start: start, end: endDate}));
    const monthTotal = sumBy(monthHours, h => h.minutes);
    const numDays = getDaysInMonth(start);
    const dayAvg = parseFloat(((monthTotal / numDays)/60).toFixed(2));
    const weekAvg = parseFloat(((monthTotal / (numDays / 7))/60).toFixed(1));
    accum[0].data.push([start, weekAvg]);
    accum[1].data.push([start, dayAvg]);
    return accum;
  }, [{name: "Hours / Week", type: "area", data: []}, {name: "Hours / Day", type: "area", data: []}]);


  const value = total / month;
  // const weekly = total / weeks;
  // const daily = total / doy;

  return {
    value,
    displayValue: `${value.toFixed(0)} hours`, 
    // stats: [
    //   {id: "weekly", label: "Per Week", value: weekly, displayValue: `${weekly.toFixed(0)} hrs` },
    //   {id: "daily", label: "Per Day", value: daily, displayValue: `${daily.toFixed(1)} hrs` },      
    // ]   
    chartData,
    chartOptions: metricChartOptions("count", ["hours", "blue"]),
    chartType: "area",
  };
}

interface HeatMapCell{ x: number; y: number}
//series: {name: Jan, data: [{x:day#, y:minutes}]}
//"time-workdays"
export const calcWorkdays = (range: DateRange, projects: IProjectSummary[]) => {
  let dayCount = 0;
  
  const basis = monthShortLabels.map((l: string, index: number) => {
    const dayCount = getDaysInMonth(index);
    const days = [];
    for(let i = 0; i < dayCount; i++){
      days.push({x: i + 1, y: 0} as HeatMapCell);
    }
    return {name: l, data: days}
  });

  const flattened = projects.reduce((map, prj) => {
    const prjDays = prj.days.filter(d => isWithinInterval(d.date, range));
    prjDays.forEach(day => {
      const m = day.date.getMonth();
      const d = day.date.getDate() - 1;
      const cell = map[m].data[d];
      if(cell.y === 0) dayCount++;
      cell.y += parseFloat((day.minutes / 60).toFixed(2));
    });

    return map;
  }, basis);

  const ordered = flattened.reverse();  //the puts the first series on the bottom

  return {
    value: dayCount,
    displayValue: dayCount.toLocaleString(),
    chartData: ordered,
    chartOptions: metricHeatmapOptions("hours"),
    chartType: "heatmap",
  }
}