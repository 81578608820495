import React, { useContext, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sumBy } from "lodash";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from '@material-ui/core/Paper';
import Typography from "@material-ui/core/Typography";
import { useLocalization, useOrg, useProfile } from "utils/hooks";
import { generalStyles, fontStyles, cardStyles } from "utils/styles";
import { InvoiceContext } from "./invoice-container";
import { formatDate } from "utils/date-helpers";
import { Divider } from "@material-ui/core";
import { formatCurrency } from "utils/number-helpers";
import { getDurationFromMinutes, loadHours, selectHoursQuery } from "features/timesheet";
import { ProjectType, QueryFilter, RootState, ITimer } from "types";
import { maxTime, subYears } from "date-fns";
import AtkTable from "components/atk-table";
import InvoicedRow, { rowHeader } from "./invoiced-hours-row";
import InvoiceStatus from "./invoice-status";

const buildStyles   = makeStyles(theme => ({
  ...generalStyles(theme),
  ...fontStyles(theme),
  ...cardStyles(theme),  
  invoiceBody: {
    padding: `${theme.spacing(4)}px ${theme.spacing(4)}px`, //`
  },
  fixedWidth: {
    display: "inline-block",
    width: theme.spacing(10),
    textAlign: "right",
  },
  fixedWidthLg: {
    display: "inline-block",
    width: theme.spacing(20),
    textAlign: "right",
  },
  tablePaper: {
    border: "none !important",
    background: "transparent",
  },  
  totalRowStyle: {
    borderTopStyle: "double",
    height: theme.spacing(5),
    "& th, td": {
      fontWeight: 600,
    }
  },
}));

const queryKey = "view-invoice";

const InvoiceViewCard = () => {
  const classes   = buildStyles();
  const dispatch = useDispatch();
  const { LSwap } = useLocalization();
  const { invoice, projectId } = useContext(InvoiceContext);
  const org = useOrg();
  const profile = useProfile();
  const hours = useSelector((state: RootState) => selectHoursQuery(state, queryKey));
  const isMultiple = useMemo(() => Boolean(projectId === "multiple"), [projectId]);

  useEffect(() => {
    //Nothing to do here if this invoice doesn't have any hours
    if(!invoice.hours || invoice.hours.length === 0) return;
    if(hours.length && hours[0].invoiceId === invoice.id) return;  //already have the hours for this invoice

    //Load the hours
    const startDate = subYears(invoice.invoiceDate as Date, 1); //go back a year from the invoice date
    const filter = [{field: "invoiceId", operator: "==", value: invoice.id} as QueryFilter];  //filter on items for this invoice
    dispatch(loadHours(startDate, null, filter, queryKey));
  }, [invoice.id]);
  
  //--Makes sure we're showing hours from the right invoice, not just what's left in the reducer
  const myHours = useMemo(() => {
    if(hours && hours.length > 0){
      if(hours[0].invoiceId === invoice.id){ 
        let totalMinutes = 0;
        let totalAmount = 0;

        const prepared = hours.map(item => {
          const rate = (item.project?.type === ProjectType.perHour && item.project?.fee) ? item.project?.fee : 0; //TODO:
          const hours = ((item.minutes || 0) / 60);
          const duration = item.minutes ? getDurationFromMinutes(item.minutes) : {hours: 0, minutes: 0};
          const amount = rate && hours ? rate * hours : 0;
          totalMinutes += item.minutes || 0;
          totalAmount += amount;
          return {
            ...item,
            rate,
            amount,
            duration
          };
        });
        
        const totalRow = {id: "total", startTime: new Date(maxTime), minutes: totalMinutes, amount: totalAmount};
        return [...prepared, totalRow];
      }
    }
    return [];
  }, [hours]);

  const totals = useMemo(() => {
    const total = sumBy(myHours, h => h.id === "total" ? 0 : h.amount);
    const diff = invoice.amount - total;
    return {
      hours: total,
      other: diff,
    };
  }, [myHours]);

  return (
    <Grid id="invoice-container" container className={classes.pad}>
      <Paper className={classes.card} variant="outlined">
        <Grid container direction="column" className={clsx(classes.cardBody, classes.invoiceBody)}>

          <Grid container alignItems="flex-start">
            <Grid item md={4} container direction="column">
              <Typography className={clsx(classes.title, classes.upcase)}>{org.name || profile?.companyName || profile?.displayName || profile?.email}</Typography>
              {/* <Typography className={clsx(classes.infoText, classes.preWrap)}>{org.address}</Typography> */}
              <Typography className={classes.infoText}>{profile?.email}</Typography>
              <Typography className={classes.infoText}>{org.website}</Typography>
              <Typography className={classes.infoText}>{org.phone}</Typography>
            </Grid>
            <Grid item md={4} container direction="column" alignItems="center" justify="flex-start">
              <InvoiceStatus invoice={invoice} />              
            </Grid>
            <Grid item md={4} container direction="column" justify="flex-start" alignItems="flex-end">
              <Typography className={classes.subTitle} component="div">Invoice: <div className={classes.fixedWidth}>{invoice.number}</div></Typography>
              <Typography className={classes.infoText} component="div">Date: <div className={classes.fixedWidth}>{formatDate(invoice.invoiceDate)}</div></Typography>
              <Typography className={classes.bodyText} component="div">Due Date: <div className={classes.fixedWidth}>{formatDate(invoice.dueDate)}</div></Typography>
              <Typography className={classes.bodyText} component="div">Amount Due: <div className={classes.fixedWidth}>{formatCurrency(invoice.amount, false)}</div></Typography>
            </Grid>
          </Grid>

          <Divider className={classes.marginYXl} />

          <Grid container alignItems="flex-start">
            <Grid item md={6} container direction="column">
              <Typography className={clsx(classes.subTitle, classes.bold)}>Billed To:</Typography>
              <Typography className={classes.bodyText}>{invoice.client?.name}</Typography>
              <Typography className={clsx(classes.bodyTextSmall, classes.preWrap)} component="div">{invoice.client?.address}</Typography>
            </Grid>

            <Grid item md={6} container direction="column" justify="flex-start" alignItems="flex-end">
              <Typography className={clsx(classes.subTitle, classes.bold)}>Send Payments To:</Typography>
              <Typography className={clsx(classes.bodyTextSmall, classes.preWrap)}>{org.name}</Typography>
              <Typography className={clsx(classes.bodyTextSmall, classes.preWrap)}>{org.address}</Typography>
            </Grid>

          </Grid>

          <Grid container direction="column" className={classes.mtxl}>
            <Typography className={clsx(classes.subTitle, classes.bold, classes.mb)}>Activity</Typography>
            <AtkTable
              rowId="id"
              header={rowHeader(isMultiple)}
              rows={myHours}
              RowComponent={InvoicedRow}
              dense={true}
              order={{sort: "startTime", sortDir: "asc"}}
              emptyMessage={LSwap("There are no line items for this invoice", "invoice")}
              settingKey="invoicable-list"
              extra={{isMultiple, totalRowStyle: classes.totalRowStyle}}
              noPaging={true}
              classes={{ paper: classes.tablePaper }}
              noHeader={true}
            />            

            {totals.other !== 0 &&
              <>
                <Grid container>                  
                  <Grid container>
                    <Grid item md={6}>
                      <Typography className={clsx(classes.bodyText, classes.boldish)}>Other</Typography>
                    </Grid>
                    <Grid item md={6} container justify="flex-end">
                      <Typography className={clsx(classes.bodyText, classes.boldish)}>{formatCurrency(totals.other, "force")}</Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Divider className={classes.marginYLg} />
              </>
            }
            
            
            <Grid container>
              <Grid item md={6}>
                {invoice.notes && 
                  <>
                    <Typography className={clsx(classes.bodyTextSmall, classes.bold)}>Notes:</Typography>
                    <Typography className={classes.bodyTextSmall}>{invoice.notes}</Typography>  
                  </>
                }                
              </Grid>
              <Grid item md={6} container justify="flex-end">
                <Typography className={clsx(classes.subTitle, classes.bold)} component="div">Balance Due: <div className={classes.fixedWidthLg}>{formatCurrency(invoice.amount, "force")}</div></Typography>
              </Grid>
              
            </Grid>
          </Grid>

        </Grid>
      </Paper>
    </Grid>
  );
}

export default InvoiceViewCard;
