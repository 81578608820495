import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { Invoice, IStatus, Indexable, InvoicesSettings } from "types";

interface InitialState {
  current: Partial<Invoice> | null;
  items: Array<Invoice> | null;
  isEmpty: boolean | null;
  isInitialized: boolean;
  settings: InvoicesSettings;
  status: IStatus;
  itemQueries: Indexable;  
}

export const initialState: InitialState = {
  current: null,
  items: null,
  isEmpty: null,
  isInitialized: false,
  settings: {
    filter: "unpaid",
    sort: "invoiceDate",
    sortDir: "desc",
    search: null,
    withVirtual: false,
  },
  status: {
    isWorking: false,
    error: null,
  },
  itemQueries: {},
};

export const slice = createSlice({
  name: "invoices",
  initialState,
  reducers: {
    statusChanged: (state, action) => {
      state.status = {
        ...state.status,
        ...action.payload,
      };
    },
    updateInvoiceSettings: (state, action) => {
      const updates = { ...state.settings, ...action.payload };
      state.settings = updates;
    },
    clearStore: (state) => {
      state.current = initialState.current;
      state.items = initialState.items;
      state.isEmpty = initialState.isEmpty;
      state.isInitialized = initialState.isInitialized;
      state.settings = {...initialState.settings};
      state.status = {...initialState.status};
      state.itemQueries = initialState.itemQueries;
    },
    itemsLoaded: (state, action) => {
      state.items = action.payload;
      state.isEmpty = Boolean(!action.payload || action.payload.length === 0);
      state.isInitialized = true;
      state.status = { isWorking: false, error: null };
    },
    itemDeleted: (state, action) => {
      const deletedId = action.payload.id;
      const existing = state.items?.find(item => item.id === deletedId);
      if (!!existing && state.items) {
        state.items = state.items?.filter(i => i.id !== existing.id);
        state.isEmpty = (state.items.length === 0);
      }
      _.forIn(state.itemQueries, (query) => {
        const found = query.items?.find((item: any) => item.id === deletedId);
        if (!!found && query.items) {
          query.items = state.items?.filter(i => i.id !== found.id);
        }
      });
    },
    itemQueryLoaded: (state, action) => {
      const { key, items, props } = action.payload;
      state.itemQueries[key] = {
        items: items,
        props: props,
      };
      state.status = { isWorking: false, error: null };
    },
    clearQuery: (state, action) => {
      state.itemQueries[action.payload] = null;
    },    
    itemCreated: (state, action) => {
      state.current = action.payload;
      if(state.isInitialized && state.items !== null){
        state.items.push(action.payload);
      }      
    },
    itemsCreated: (state, action: PayloadAction<Invoice[]>) => {
      if(state.isInitialized){
        if(!state.items) state.items = [];
        state.items.push(...action.payload);
        state.status.isWorking = false;
        state.status.error = null;
      }
    },
    itemUpdated: (state, action) => {
      const existing = state.items?.find(inv => inv.id === action.payload.id) as any;
      if (!!existing) {
        _.keys(action.payload.changes).forEach(key => {
          existing[key] = action.payload.changes[key];
        });
      }
      state.status = {isWorking: false, error: null};
    }, 
    clearItems: (state) => {
      state.current = initialState.current;
      state.items = initialState.items;
      state.isEmpty = initialState.isEmpty;
      state.itemQueries = initialState.itemQueries;
    },       
  }
});

export const {
  statusChanged,
  updateInvoiceSettings,
  clearStore,
  clearItems,
  itemsLoaded,
  itemQueryLoaded,
  clearQuery,
  itemCreated,
  itemsCreated,
  itemDeleted,
  itemUpdated,
} = slice.actions;

export default slice.reducer;