import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Zoom from '@material-ui/core/Zoom';
import Fade from '@material-ui/core/Fade';
import EditIcon from '@material-ui/icons/Edit';
// import BarChartIcon from '@material-ui/icons/BarChart';
import TimelineIcon from '@material-ui/icons/Timeline';
import { ITimer, RootState } from "types";
import { useReadyState, useProjects, useInterval, useDialogs } from "utils/hooks";
import { updateTimesheetSettings } from "../infra/timesheet-slice";
import { getTimer, startTimer, stopTimer, cancelTimer } from "../infra/timesheet-actions";
import { calcMinutes, roundMinutes } from "../infra/timesheet-helpers";
import { selectCurrentTimer } from "../infra/timesheet-selectors";
import CreateTimer from "./create-timer";
import ExistingTimer from "./existing-timer";
import TimerEditor from "./timer-editor";
import SummaryCard from "./hours-summary-annual";
// import ProjectSummary from "./hours-summary-by-project";
import { generalStyles } from "utils/styles";
import { LoadingIndicator } from "components";
import { loadProjectHours } from "features/projects";
import { trackDisplayType, trackEvent } from "utils/mixpanel";

const buildStyles = makeStyles(theme => ({
  ...generalStyles(theme),
  root: {
    // padding: theme.spacing(1),
  },
  card: {
    background: theme.palette.common.white,
    width: "100%",
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.grey[300]}`, //`
  },
  tabbedCard: {
    background: theme.palette.common.white,
    width: "100%",
    border: `1px solid ${theme.palette.grey[300]}`, //`
  },
  inputGrid: {
    marginBottom: theme.spacing(0.5),
    // marginRight: theme.spacing(1),
  },
  textRight: {
    textAlign: "right",
    "& input": {
      textAlign: "right",
    }
  },
  textCenter: {
    textAlign: "center",
    "& input": {
      textAlign: "center",
    }
  },
  noTopMargin: {
    marginTop: 0,
  },
  dateGrid: {
    marginBottom: theme.spacing(0),
  },
  dateLabel: {
    color: theme.palette.grey[700],
    fontSize: 14,
    fontWeight: 300,
    marginLeft: theme.spacing(0.5),
  },
  activeGrid: {
    background: theme.palette.primary.dark,
    borderRadius: 3,
  },
  createGrid: {
  },
  tabsRoot: {
    minHeight: 32,
  },
  tabRoot: {
    minHeight: 32,
    minWidth: 100,
    "&.Mui-selected": {
      background: `linear-gradient(${theme.palette.secondary.light}11, ${theme.palette.secondary.main}22)`,
    }
  },
  title: {
    fontSize: 22,
    fontWeight: 500,
    textAlign: "center",
    marginBottom: theme.spacing(1),
    color: theme.palette.secondary.main,
  },
  subTitle: {
    fontSize: 18,
    fontWeight: 400,
    textAlign: "center",
    // color: theme.palette.secondary.main,
  },
}));


const TimesheetHeader = () => {
  const classes = buildStyles();
  const dispatch = useDispatch();
  const projects = useProjects({sort: "name", sortDir: "asc"});
  const existing = useSelector(selectCurrentTimer);
  const view = useSelector((state: RootState) => state.timesheet.settings.tab);
  const isReady = useReadyState();
  const lastCheck = useSelector((state: RootState) => state.timesheet.lastCheckForTimer);
  const pHours = useSelector((state: RootState) => state.projects.trackedHours);
  const isFirstTime = pHours && pHours.length === 0;
  const {onOpenDialog} = useDialogs();
  
  const refresh = React.useCallback(() => {
    dispatch(getTimer());
  }, []);

  useEffect(() => {
    dispatch(loadProjectHours()); //won't re-load if they've already been loaded
  }, []);

  //Set an interval to check on a running timer every 2 minutes
  useInterval(refresh, 30000, true);

  const currentProject = React.useMemo(() => {
    if (projects && existing && existing.projectId) {
      const project = projects.find(prj => prj.id === existing.projectId);
      return project;
    }
  }, [projects, existing]);

  //--
  // Timer started, save the hours
  const onStart = async (values: Partial<ITimer>) => {
    if (!values.projectId) {
      //TODO: errors...
      return;
    }

    const project = projects.find(prj => prj.id === values.projectId);
    const client = project?.client;
    if (!project || !client) {
      //TODO: something went wrong
      return;
    }

    const model: Partial<ITimer> = {
      ...values,
      clientId: client.id,
      rate: values.rate || client.defaultRate,
    };

    await dispatch(startTimer(model));
    trackEvent("timer", "start");
    if(view !== "edit") await dispatch(updateTimesheetSettings({tab: "edit"}));
  }

  //--
  // Timer stopped, save the hours
  const onStop = async (stopTime: Date) => {
    if (!existing) throw new Error("Timer is not running!");
    if (!existing.startTime) throw new Error("Start time is not set.");

    const totalMinutes = calcMinutes(existing.startTime, stopTime);
    const roundedMinutes = roundMinutes(totalMinutes, existing.roundType);

    const model = {
      ...existing,
      stopTime: stopTime,
      minutes: roundedMinutes,
    };

    if(view === "edit") await dispatch(updateTimesheetSettings({tab: "summary"}));
    dispatch(stopTimer(model));
    trackEvent("timer", "stop");
  }
  
  const onEdit = () => {
    if(view !== "edit"){
      dispatch(updateTimesheetSettings({tab: "edit"}));
      trackEvent("timer", "edit");
    }
  }

  const onCancel = async () => {
    if (existing && !!existing.id) {
      if(view === "edit") await dispatch(updateTimesheetSettings({tab: "summary"}));
      dispatch(cancelTimer(existing.id));
      trackEvent("timer", "cancel");
    }
  }

  const onTabChange = (event: React.ChangeEvent<unknown>, newValue: string) => {
    if(newValue !== view){
      dispatch(updateTimesheetSettings({tab: newValue}));
      trackDisplayType("timesheet", newValue);
    }
  }

  return (
    <Grid id="timer-card" container className={classes.root} spacing={2}>
      <Grid item lg={3} md={4} sm={6} xs={12}>
        <Paper className={classes.card} elevation={0}>
          {(!isReady || !lastCheck) && <LoadingIndicator isVisible={true} message="Loading..."/> }
          {(isReady && !!lastCheck) && 
            <>
            <Fade in={!existing} style={{ transitionDelay: "0ms" }}>
              <Grid className={classes.createGrid}>
                {!existing &&
                  <CreateTimer projects={projects} timer={existing} onStart={onStart} />
                }
              </Grid>
            </Fade>
            <Zoom in={!!existing} style={{ transitionDelay: "0ms" }}>
              <Grid className={classes.activeGrid}>
                {(existing && !!existing.startTime && currentProject) &&
                  <ExistingTimer project={currentProject} startTime={existing.startTime} onStop={onStop} onEdit={onEdit} onCancel={onCancel} />
                }
              </Grid>
            </Zoom>
          </>
        }
        </Paper>
      </Grid>
      <Grid item lg={9} md={8} sm={12} xs={12} container>
        {isFirstTime && 
          <Grid container direction="column" alignItems="flex-start" className={classes.pad}>
            <Typography className={classes.title}>It looks like this may be your first time here.</Typography>
            <Typography className={classes.subTitle}>You can start a timer with the card to the left,</Typography>
            <Typography className={classes.subTitle}>or <Button size="small" color="secondary" onClick={onOpenDialog("import", "hours")}>Import Hours</Button> from another system</Typography>
          </Grid>
        }
        {!isFirstTime && 
          <Paper className={classes.tabbedCard} elevation={0}>
            <Grid item xs={12} container>
              <Tabs value={view} onChange={onTabChange} classes={{root: classes.tabsRoot}}>
                {!!existing && <Tab value="edit" icon={<EditIcon fontSize="small"/>} classes={{root: classes.tabRoot}} title="Edit the current timer"/>}
                <Tab value="summary" icon={<TimelineIcon fontSize="small" />} classes={{root: classes.tabRoot}} title="Timesheet summary"/>
                {/* <Tab value="project" icon={<BarChartIcon fontSize="small" />} classes={{root: classes.tabRoot}} title="Project summary"/> */}
              </Tabs>
            </Grid>
            <Grid item xs={12}>
              {(!!existing && view === "edit") && <TimerEditor />}
              {view === "summary" && <SummaryCard />}
              {/* {view === "project" && <ProjectSummary onStart={onStart} />} */}
            </Grid>
          </Paper>
        }
      </Grid>      
    </Grid>
  );
}

export default TimesheetHeader;