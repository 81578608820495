import React from "react";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Icon from '@material-ui/core/Icon';
import Divider from '@material-ui/core/Divider';
import CheckIcon from '@material-ui/icons/Check';
import { useAnchor } from "utils/hooks";
import { IMenuItem } from "types";

const buildStyles = makeStyles(theme => ({
  rightMargin: {
    marginRight: theme.spacing(1),
  },
  leftMargin: {
    marginLeft: theme.spacing(1),
  },
  menuItem: {
    minWidth: 250,
  },  
}));

interface IconMenuProps {
  icon?: any;
  items: IMenuItem[];
  isSelectable?: boolean;
  onClick?: (item: IMenuItem) => void;
  isSelected?: (item: IMenuItem) => boolean;
  title?: string;
  color?: "inherit" | "primary" | "secondary" | "default" | undefined;
  size?: "small" | "medium" | undefined;
  disabled?: boolean | undefined;
  classes?: Record<string, string>;
}

const IconMenu = ({ icon, items, isSelected, onClick, title, color, size, classes, disabled }: IconMenuProps) => {
  const myClasses = buildStyles();
  const [anchor, isOpen, onOpen, onClose, position] = useAnchor();
  const hasIcons = React.useMemo(() => !!items.find(i => !!i.icon), [items]);
  icon = icon || <Icon fontSize="small" color="disabled">more_vert</Icon>;

  const handleAction = (e: any, item: IMenuItem) => {
    if (item.action && _.isFunction(item.action)) item.action();
    else if (onClick) onClick(item);
    onClose(e);
  }

  return (
    <>
      {/* <Grid container justify="flex-end" alignItems="center"> */}
        <Tooltip title={title || ""}>
          <IconButton color={color} onClick={onOpen} disabled={disabled || items.length === 0} size={size || "medium"} className={classes?.button}>{icon}</IconButton>
        </Tooltip>
        <Menu
          // id="menu-project"
          anchorEl={anchor}
          keepMounted
          open={isOpen}
          onClose={onClose}
          {...position}
        >
          {items.map(item => {
            const labelProps = item.icon ? { className: myClasses.leftMargin } : null;
            return item.isDivider ? <Divider key={item.id} /> :
              <MenuItem key={item.id} onClick={(e) => handleAction(e, item)} className={myClasses.menuItem}>
                <Grid container>
                  {isSelected && <Grid item xs={2}><SelectedIcon isSelected={isSelected(item)} classes={myClasses} /></Grid>}
                  {hasIcons && <Grid item xs={3}>{item.icon}</Grid>}
                  <Grid item xs={7} container justify="flex-start"><Typography {...labelProps}>{item.label}</Typography></Grid>
                </Grid>
              </MenuItem>
          })}
        </Menu>
      {/* </Grid> */}
    </>
  )
}

export default IconMenu;


interface SelectedIconProps {
  isSelected: boolean;
  classes: ReturnType<typeof buildStyles>;
}

const SelectedIcon = ({ isSelected, classes }: SelectedIconProps) => {
  if (!isSelected) return null;
  else {
    return <CheckIcon className={classes.rightMargin} />
  }
}