import React, { memo } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import { fontStyles, generalStyles } from "utils/styles";
import { SimpleStat } from "types";
import { formatCurrency } from "utils/number-helpers";

const useStyles = makeStyles(theme => ({
  ...generalStyles(theme),
  ...fontStyles(theme),
  root: {
  },
  statCard: {
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1.5)}px`, //`
    
  },
  statGrid: {
    height: "100%",
  },
  dangerColor: {
    color: theme.palette.error.main,
  },
  // clickable: {
  //   cursor: "pointer",
  // },
  sm: {
    height: theme.spacing(4.5),
  },
  md: {
    height: theme.spacing(10),
  },
  lg: {
    height: theme.spacing(15),
  },
  transparentCard: {
    background: "transparent",
    borderColor: "transparent",
  },  
}));


interface SimpleStatProps {
  stat: SimpleStat | null;
  onClick?: (action: string) => void;
  height?: "sm" | "md" | "lg";
  variant?: "default" | "transparent";
}

const SimpleStatCard = (props: SimpleStatProps) => {
  const { stat, height, variant } = props;
  const classes = useStyles();
  if (!stat) return null;
  const colorClass = stat.color === "danger" ? classes.dangerColor : null;
  const pointerClass = stat.action ? classes.clickable : null;
  const sizeClass = classes[height || "sm"];

  const statClick = () => {
    if(props.onClick && stat.action) props.onClick(stat.action);
  }

  return (
    <Tooltip title={stat.toolTip || stat.label}>
      <Paper  className={clsx(classes.statCard, pointerClass, sizeClass, {[classes.transparentCard]: (variant === "transparent")})} variant="outlined" onClick={statClick}>
        <Grid container justify="space-between" alignItems="center" className={classes.statGrid}>
          <Typography className={classes.caption}>{stat.label}</Typography>
          <Typography className={clsx(classes.subTitle, classes.semiBold, colorClass)}>{formatCurrency(stat.amount)}</Typography>
        </Grid>
      </Paper>
    </Tooltip>
  )
};

export default memo(SimpleStatCard);
SimpleStatCard.displayName = "SimpleStatCard";