import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const buildStyles   = makeStyles(theme => ({
  root  : {
    // padding     : theme.spacing(1),
  },
}));

interface ControlProps{
  items: any[];
  Card: any;
}

const AtkListCards = ({items, Card}: ControlProps) => {
  const classes   = buildStyles();

  return (
    <Grid id="atk-list-cards" container spacing={2} className={classes.root}>
      {items?.map(item => {
        return (
          <Card key={item.id} item={item} />
        );
      })}
    </Grid>
  );
}

export default AtkListCards;
