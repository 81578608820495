import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import DoneIcon from '@material-ui/icons/Done';
import { RowProps, HeadCell } from "components/atk-table";
import { IProject, IAlert, IClient, LSwapFunc } from "types";
import { getProjectAlerts, hasProjectAlert, formatFee } from "features/projects/infra/project-helper";
import { getRoute } from "features/app";
import { formatDate } from "utils/date-helpers";
import { isAfter } from "date-fns";

export interface IProjectRow extends IProject {
  client?: IClient;
  status?: any;
  alerts?: any;
}

export const rowHeader = (): HeadCell<IProjectRow>[] => [
  { id: 'name', label: 'Name' },
  { id: 'fee', align: "right", label: 'Fee/Rate' },
  { id: "status", label: "Status", defaultSortDir: "desc" },
  { id: "alerts", label: "Alerts", },
  // { id: "startDate", label: "Start", isHidden: true },
  // { id: "dueDate", label: L("projectDue", true) },
];

export const getSortValue = (row: IProjectRow, col: string) => {
  switch(col){
    case "status": return row.dueDate;
    case "alerts": return row.startDate;
  }
}

const getAlertIcon = (item: IProject, alerts: IAlert[], condition: (prj: IProject, alerts: IAlert[]) => boolean, color: "primary" | "secondary" | "error", tooltip: string) => {
  if (condition(item, alerts)) {
    return (
      <Tooltip title={tooltip}>
        <InfoIcon fontSize="small" color={color} />
      </Tooltip>
    );
  }

  return null;
}

const getAlertIcon2 = (alerts: IAlert[], toMatch: string, color: "primary" | "secondary" | "error") => {
  const alert = alerts.find(al => al.id === toMatch);
  if (alert) {
    return (
      <Tooltip title={alert.message}>
        <InfoIcon fontSize="small" color={color} />
      </Tooltip>
    );
  }

  return null;
}

const doneIcon = (date: Date | string | null | undefined, showDate = false) => {
  if (!date) return null;
  else if (showDate === true) return date;
  return (
    <Tooltip title={`paid on ${formatDate(date)}`}>
      <DoneIcon color="primary" fontSize="small" />
    </Tooltip>
  );
}

const projectStatus = (item: IProject, LSwap: LSwapFunc) => {
  if (item.paidDate) {
    return doneIcon(item.paidDate); //"paid";
  }
  else if(item.lastPaymentDate && item.deliveredDate){
    if(item.uninvoiced > 0){
      return "delivered";
    }
    else if(item.unpaid > 0){
      return "invoiced";
    }
    else
      return doneIcon(item.lastPaymentDate);
  }
  else if (item.invoicedDate) {
    return `invoiced ${formatDate(item.invoicedDate)}`;
  }
  else if(item.lastInvoiceDate && item.deliveredDate){
    return `invoiced ${formatDate(item.lastInvoiceDate)}`;
  }
  else if (item.deliveredDate) {
    return "delivered";
  }
  else if (item.dueDate) {
    return `due ${formatDate(item.dueDate)}`;   //`
  }
  else {
    return LSwap(`started ${formatDate(item.startDate)}`, "projectStart");   //`
  }
}

const ClientProjectRow: FunctionComponent<RowProps<IProjectRow>> = ({ item, isSelected, onSelected, CheckboxCell, labelId, extra }) => {
  const url = React.useMemo(() => getRoute("project", item.id), [item]); //`
  const alerts = getProjectAlerts(item);

  // const needsDue = "<empty>";
  const LSwap = extra.LSwap;
  const needsDue = getAlertIcon(item, alerts, p => !p.dueDate, "secondary", "This project doesn't have a due date");
  const dueSoon = getAlertIcon(item, alerts, (p, alts) => hasProjectAlert(alts, "due-soon"), "secondary", alerts.find(a => a.id === "due-soon")?.message || "This project is due soon");
  const overdue = getAlertIcon(item, alerts, (p, alts) => hasProjectAlert(alts, "overdue"), "error", "This project is overdue");
  const needsInvoice = getAlertIcon(item, alerts, (p, alts) => hasProjectAlert(alts, "to-invoice"), "secondary", "This project has not been invoiced");
  // // const needsPayment = getAlertIcon(item, alerts, (p, alts) => hasProjectAlert(alts, "not-paid"), "error", extra.openPaid("project", item.id), "The payment for this project is overdue");
  const needsPayment = getAlertIcon2(alerts, "not-paid", "error");

  // const statusIcon = item.isArchived ? <Tooltip title="Archived"><ArchiveIcon fontSize="small" /></Tooltip> : null;
  const fee = formatFee(item);

  return (
    <TableRow hover role="checkbox" aria-checked={isSelected} tabIndex={-1} selected={isSelected} onClick={onSelected}>
      {CheckboxCell}
      <TableCell component="th" id={labelId} scope="row" padding="default">
        <Link to={url}>{item.name}</Link>
      </TableCell>
      <TableCell align="right"><Typography>{fee}</Typography></TableCell>
      <TableCell>{projectStatus(item, LSwap)}</TableCell>
      <TableCell align="center">{needsDue || dueSoon || overdue || needsInvoice || needsPayment}</TableCell>
    </TableRow>
  );
}

export default ClientProjectRow;
