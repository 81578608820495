import React, { useMemo, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import Grid from "@material-ui/core/Grid";
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { Invoice } from "../infra/invoice-types";
import { useBoolState, useInputs, useLocalization, useSnackbar } from "utils/hooks";
import { differenceInDays } from "date-fns";
import { formatDate } from "utils/date-helpers";
import { cardStyles, generalStyles } from "utils/styles";
import { DateInput, LoadingIndicator } from "components";
import { invoiceParsers } from "../infra/invoice-helpers";
import { updateInvoice } from "../infra/invoice-actions";
import { useDispatch } from "react-redux";

const buildStyles   = makeStyles(theme => ({
  ...generalStyles(theme),
  ...cardStyles(theme),
  myCardBody: {
    padding: `${theme.spacing(0.25)}px ${theme.spacing(0.5)}px`, //`
    background: `${theme.palette.info.main}33`, //`
  },
  paidChip: {
    backgroundColor: theme.palette.success.light,
    fontSize: "1rem",
    padding: `${theme.spacing(1.5)}px ${theme.spacing(1)}px`, //`
  },
  paidChipIcon: {
    color: theme.palette.success.main,
  },
  overdueChip: {
    backgroundColor: theme.palette.error.light,
    fontSize: "1rem",
    padding: `${theme.spacing(1.5)}px ${theme.spacing(1)}px`, //`
  },
  overdueChipIcon: {
    color: theme.palette.error.main,
  },
}));

interface ControlProps{
  invoice: Invoice;
}

const InvoiceStatus = ({invoice}: ControlProps) => {
  const classes   = buildStyles();
  const dispatch = useDispatch();
  const { LSwap } = useLocalization();
  const notify = useSnackbar();
  const isOverdue = useMemo(() => Boolean(invoice.dueDate && !invoice.paidDate && differenceInDays(invoice.dueDate as Date, new Date()) < 0), [invoice.dueDate]);
  const [isPaying, toggleIsPaying] = useBoolState(false);
  const [values, , errors, binding, setValues] = useInputs<Invoice>(invoice, invoiceParsers);
  const today = React.useMemo(() => new Date(), []);
  const canSave = useMemo(() => Boolean(values.paidDate && !errors.paidDate), [values, errors]);
  const [isWorking, setWorking] = useState(false);
  
  const togglePaid = () => {
    if(!values.paidDate) setValues({...invoice, paidDate: new Date()});
    toggleIsPaying();
  }

  const onSave = async () => {
    console.log("save paid date...", values.paidDate, errors);
    if(values.paidDate && !errors.paidDate){
      setWorking(true);
      const updateResult = await dispatch(updateInvoice(invoice.id, {paidDate: values.paidDate})) as any;
      if(!!updateResult){
        notify("Invoice successfully updated", "success");
        toggleIsPaying();
      }
      setWorking(false);
    }
  }
  
  return (
    <Grid container justify="center" direction="column" alignItems="center">
      {!isWorking && invoice.paidDate &&
        <Chip icon={<CheckCircleIcon className={classes.paidChipIcon}/>} label={`Paid on ${formatDate(invoice.paidDate)}`} className={clsx(classes.paidChip, classes.mb)}/>
      }
      {!isWorking && !invoice.paidDate && !isPaying && 
        <Button onClick={togglePaid} variant="outlined" color="secondary" size="small" className={classes.mb}>Mark Paid</Button>
      }
      {isOverdue && !isWorking &&  !isPaying &&
        <Chip icon={<WarningIcon className={classes.overdueChipIcon}/>} label={LSwap("This invoice is overdue", "invoice")} className={clsx(classes.overdueChip)}/>
      }
      {!invoice.paidDate && !isWorking &&  isPaying && 
        <Paper className={classes.card} variant="outlined">
          <Grid container className={classes.myCardBody} alignItems="center">
            <Grid item md={8} className={classes.mb}>
              <DateInput id="paidDate" label="Paid Date" value={values.paidDate} {...binding.dateInput("paidDate")} fullWidth minDate={values.invoiceDate || today} maxDate={today} disabled={isWorking}/>
            </Grid>
            <Grid item md={4} container justify="space-evenly">
              <Tooltip title="Save the paid date">
                <IconButton onClick={onSave} disabled={!canSave || isWorking}><CheckCircleIcon fontSize="small"/></IconButton>
              </Tooltip>
              <Tooltip title="Cancel">
                <IconButton onClick={togglePaid} disabled={isWorking}><CancelOutlinedIcon fontSize="small"/></IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Paper>
      }
      {isWorking  &&
        <LoadingIndicator isVisible={true} message="Saving changes..." />
      }
    </Grid>
  );
}

export default InvoiceStatus;
