import React from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Grid from "@material-ui/core/Grid";
import { useClient } from "utils/hooks";
import ClientSidebar from "./client-sidebar";
import ClientContent from "./client-content";
import { PageContent, PageLayout, PageSidebar } from "components/layout/page-layout";

const ClientViewVert = () => {
  const { clientId } = useParams<{ clientId: string }>();
  const client = useClient(clientId);
  
  return (
    <Grid id="client-view-vert" container>
      <Helmet>
        <title>Gigops - {client?.name}</title>
      </Helmet>

      <PageLayout>
        <PageSidebar>
          <ClientSidebar/>
        </PageSidebar>
        <PageContent minWidth={575}>
          <ClientContent />
        </PageContent>
      </PageLayout>
      
    </Grid>
  );
}

export default ClientViewVert;
