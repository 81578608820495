import React, { useEffect, useMemo } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { DateInput } from "components";
import { feeAdornStyle, formStyles, generalStyles, iconStyles } from "utils/styles";
import { useClients, useInputs } from "utils/hooks";
import { IProject, ProjectTypeItems, RootState } from "types";
import { newProject, projectParsers, projectTypeLabel } from "../infra/project-helper";
import { capitalize } from "lodash";
import { selectCustomizations } from "features/app";
import { useSelector } from "react-redux";
import { Icons } from "utils/common-classes";

const buildStyles   = makeStyles(theme => ({
  ...formStyles(theme),
  ...generalStyles(theme),
  ...feeAdornStyle(theme),
  ...iconStyles(theme),
  root  : {
    padding     : theme.spacing(1),
  },
  valid: {
    background: theme.palette.success.light,
    borderRadius: 3,
  }  
}));

interface ControlProps {
  onChange: (values: Partial<IProject>) => void;
  onRemove: () => void;
  noRemove?: boolean;
}

const NewProjectRow = ({onChange, onRemove, noRemove}: ControlProps) => {
  const classes   = buildStyles();
  const today = useMemo(() => new Date(), []);
  const clients = useClients("name");
  const defaults = useSelector((state: RootState) => selectCustomizations(state, ["defaultRounding", "defaultProjectType", "defaultHourlyRate", "projectCategory", "wordCount"]));
  const [values, display, errors, binding, setValues] = useInputs<IProject>(newProject, projectParsers);
  const canSave = useMemo(() => Boolean(values.name && values.name.trim().length > 0 && values.clientId && values.startDate), [values.name, values.clientId, values.startdate]);
  
  useEffect(() => {
    const cli = clients?.find(c => c.id === values.clientId);
    if (cli && (cli.defaultRate || cli?.defaultProjectType)) {
      setValues(
        { 
          ...values, 
          fee: cli.defaultRate, 
          type: (cli.defaultProjectType || values.type),
          roundType: defaults.defaultRounding,
        }
      );
    }
  }, [values.clientId]);

  useEffect(() => {
    onChange(values);
  }, [values]);

  return (
    <Grid id="data-view" container justify="center" className={clsx(classes.root, {[classes.valid]: canSave})} spacing={1}>
      <Grid item md={3} className={classes.inputGrid} container alignItems="flex-end">
          <TextField 
            id="name" 
            label="Project Name*" 
            autoFocus={Boolean(noRemove)} 
            fullWidth value={display?.name} 
            {...binding.input} 
            {...errors["name"]} 
            autoComplete="off" />
        </Grid>

        <Grid item md={3} container className={classes.inputGrid} alignItems="flex-end">
          <FormControl className={classes.formControl} fullWidth {...binding.selectContainer("clientId", clients)}>
            <InputLabel id="client-label" className={classes.selectLabel}>Client*</InputLabel>
            <Select id="clientId" labelId="client-label" label="Client" value={values?.clientId} {...binding.select("clientId")} className={classes.selectContainer} fullWidth classes={{ select: classes.select }}>
              <MenuItem value="-1" disabled className={classes.option}>Select Client</MenuItem>
              {clients?.map(option => <MenuItem key={option.id} value={option.id} className={classes.option}>{option.name}</MenuItem>)}
            </Select>
          </FormControl>
        </Grid>

        <Grid item md={1} container alignItems="flex-start"  className={classes.inputGrid}>
          <DateInput id="startDate" label="Assigned*" value={values.startDate} {...binding.dateInput("startDate")}  fullWidth/>
        </Grid>
        <Grid item md={1} container alignItems="flex-start" className={classes.inputGrid}>
          <DateInput id="dueDate" label="Due" value={values.dueDate} {...binding.dateInput("dueDate")} fullWidth minDate={values.startDate || today}/>
        </Grid>
        <Grid item md={2} className={classes.inputGrid} container alignItems="flex-end">
          <TextField id="fee" label="Fee / Rate" className={classes.rateInput} value={display?.fee} {...binding.input} {...errors["fee"]} fullWidth autoComplete="off"
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <Grid container className={classes.adornGrid}>
                  <Typography className={classes.adorn}>/</Typography>
                  <TextField select value={display?.type} {...binding.select("type")} className={classes.adornSelect} fullWidth>
                    <MenuItem value={-1} disabled>[choose]</MenuItem>
                    {ProjectTypeItems.map(opt => <MenuItem key={opt.id} value={opt.id} className={classes.option}>{opt.unitLabel}</MenuItem>)}
                  </TextField>
                </Grid>
              </InputAdornment>
            }}
          />
        </Grid>
        
        <Grid item md={1} container className={classes.inputGrid} alignItems="flex-end">
          {(values.type !== "fixed" && values.type !== "perHour") && 
            <TextField id="units" label={`${capitalize(projectTypeLabel(values.type))}s`} fullWidth value={display?.units} {...binding.input} {...errors["units"]} autoComplete="off" className={classes.textCenter} />
          }
        </Grid>
        <Grid item md={1} className={classes.inputGrid} container justify="flex-end" alignContent="flex-end">
          {!noRemove && <IconButton onClick={onRemove} size="small" style={{marginTop: "-4px"}}>{Icons.delete("small")}</IconButton>}
          {canSave && <Tooltip title="This project is valid and will be saved">{Icons.check("small", "disabled")}</Tooltip>}
        </Grid>
    </Grid>
  );
}

export default NewProjectRow;
