import React from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import Grid from "@material-ui/core/Grid";
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from "@material-ui/core/Typography";
// import IconButton from '@material-ui/core/IconButton';
// import Icon from '@material-ui/core/Icon';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import { IChange } from "types";
import { useLocalization } from "utils/hooks";
import { formatCurrency, formatPercent } from "utils/number-helpers";
import { selectTopClientsByInvoiced } from "features/projects";
import { ChangeArrow } from "components";
import AtkTable, { HeadCell, RowProps } from "components/atk-table";
import { getRoute } from "features/app";

const buildStyles = makeStyles(theme => ({
  root: {
    padding: `${theme.spacing(2)}px ${theme.spacing(0)}px`, //`,
    borderRadius: 5,
  },
  titleRow: {
    padding: `${theme.spacing(0)}px ${theme.spacing(2)}px`, //`
    marginBottom: theme.spacing(1),
  },
  title: {
    fontSize: 18,
    fontWeight: 400,
    color: theme.palette.grey[500],
    marginBottom: theme.spacing(1),
    textTransform: "uppercase",
    cursor: "pointer",
  },
  navButton: {
    padding: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
  },
  tableGrid: {
    margin: 0, //`${theme.spacing(0)}px ${theme.spacing(-2)}px`, //`,
  },
  tableRow: {
    "& td, th": {
      border: "none",
    }
  },
  tablePaper: {
    border: "none !important",
    background: "transparent",
  },
  filterChip: {
    marginLeft: theme.spacing(1),
    "& span": {
      fontWeight: 700,
      fontSize: 12,
      // color: theme.palette.grey[600],
    }
  },
  transparent: {
    background: "transparent",
    borderColor: "transparent",
  }
}));

const rowHeader: HeadCell<any>[] = [
  { id: "clientName", label: 'Name' },
  { id: "amount", label: "Amount", },
];

const ClientRow = (props: RowProps<any>) => {
  const { item, labelId, extra } = props;
  const url = getRoute("client", item.clientId);

  const value = item[extra.period];
  const change = item[`${extra.period}Change`] as IChange;
  const changeTitle = (change.dir === "flat") ? undefined : `{change} ${formatPercent(Math.abs(change.pct), 0)} this ${extra.period}`;

  return (
    <TableRow hover className={extra.classes.tableRow}>
      <TableCell component="th" id={labelId} scope="row" padding="default">
        <Link to={url}>{item.clientName}</Link>
      </TableCell>
      <TableCell align="right">{formatCurrency(value, true)} <ChangeArrow change={change} variant="trending" fontSize="small" title={changeTitle} /></TableCell>
    </TableRow>
  )
};

interface WidgetProps {
  variant?: "elevation" | "outlined" | "transparent";
}

const TopClientsWidget = ({variant}: WidgetProps) => {
  const classes = buildStyles();
  const history = useHistory();
  const [period, setPeriod] = React.useState("year");
  const {LSwap} = useLocalization();
  const data = useSelector(selectTopClientsByInvoiced);
  const paperClass = (variant === "transparent") ? classes.transparent : undefined;
  const vnt = variant === "transparent" ? "outlined" : variant;

  const rows = React.useMemo(() => {
    if (!data) return [];
    const items = _.orderBy(data, [period], ["desc"]).slice(0, 5);
    return items;
  }, [data, period]);

  const navTo = (path: string, filter: string | null = null) => () => {
    console.log("TODO: filter not applied", filter);
    history.push(path);   //right now, only using path here...
  }

  return (
    <Paper className={paperClass} variant={vnt}>
      <Grid id="invoice-widget" container justify="center" className={classes.root}>
        <Grid container className={classes.titleRow} alignItems="flex-start">
          <Grid item xs={9} container alignItems="flex-start">
            <Typography className={classes.title} onClick={navTo("/clients", "top-revenue",)}>{LSwap("top clients", "clients")}</Typography>
            {/* <IconButton onClick={navTo("/clients", "top-revenue",)} className={classes.navButton}><Icon color="disabled" fontSize="small">arrow_forward</Icon></IconButton> */}
          </Grid>
          <Grid item xs={3} container justify="flex-end" wrap="nowrap">
            <Tooltip title="last 30-days, by invoiced revenue">
              <Chip label="M" clickable onClick={() => setPeriod("month")} size="small" className={classes.filterChip} color="primary" variant={period === "month" ? "default" : "outlined"} />
            </Tooltip>
            <Tooltip title="this year, by invoiced revenue">
              <Chip label="Y" clickable onClick={() => setPeriod("year")} size="small" className={classes.filterChip} color="primary" variant={period === "year" ? "default" : "outlined"} />
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} container className={classes.tableGrid}>
            <AtkTable
              rowId="clientId"
              header={rowHeader}
              rows={rows}
              RowComponent={ClientRow}
              dense={true}
              order={{ sort: "amount", sortDir: "desc" }}
              settingKey="top-cli-list"
              noPaging={true}
              noHeader={true}
              classes={{ paper: classes.tablePaper }}
              extra={{
                classes: classes,
                period: period,
              }} />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default TopClientsWidget;
