import React from "react";
import _ from "lodash";
import moment from "moment";
import TextField from '@material-ui/core/TextField';
import { stringToDate } from "utils/general-helpers";

const DATE_PICKER_FORMAT = "M/D/YY";

type DatePickerProps = {
  id?: string;
  label?: string;
  fullWidth?: boolean;
  // variant?: "filled" | "outlined" | "standard";
  // margin?: "dense" | "normal" | "none";
  // size?: "small" | "medium" | "large";
  onChange: (value: any) => void;
  minDate?: Date;
  maxDate?: Date;
  value: string | Date | null;
}

function parseDate(val: string | Date | null) {
  if (!val) return null;
  else if (_.isString(val)) {
    return moment(stringToDate(val));
  }
  else if (_.isDate(val)) {
    return moment(val);
  }
  else return null;
}

function validateDate(val: string | null) {
  if (!val || val === "") return true;
  const parsed = parseDate(val);
  if (parsed === null) return true;
  return parsed.isValid();
}

function getValue(val: string | Date | null) {
  if (!val) return "";
  else {
    const tmp = parseDate(val);
    if (!!tmp) return tmp.format(DATE_PICKER_FORMAT);
    else return tmp;
  }

}

interface IState {
  value: string | null;
  error: string | null;
}

const DatePicker = (props: DatePickerProps) => {
  const [state, setState] = React.useState<IState>({ value: getValue(props.value), error: null });
  const passedProps = React.useMemo(() => { return _.pick(props, ["id", "label", "fullWidth", "variant", "margin", "size"]); }, [props]);

  //Debounce the validation of the timer so we're not doing it on every keystroke
  React.useEffect(() => {
    const tid = setTimeout(() => {
      const error = validateDate(state.value) ? null : "invalid format";
      setState({ ...state, error: error });
    }, 400);
    return () => clearTimeout(tid); //this will stop the timer if the value changes before the timer is triggered.
  }, [state.value]);

  const onChange = (e: React.ChangeEvent<any>) => {
    const newVal = e.currentTarget.value;
    setState({ value: newVal, error: null });
    props.onChange(newVal);
  }

  const onBlur = () => {
    if (state.value !== "" && state.value !== null) {
      const parsed = getValue(state.value);
      setState({ value: parsed, error: state.error });
    }
  }

  return (
    <>
      <TextField value={state} onChange={onChange} onBlur={onBlur} {...passedProps} error={!!state.error} helperText={state.error} />
    </>
  );
}

export default DatePicker;
