import firebase from "firebase/app";
import "firebase/firestore";
import { COLS, STATUSES, IFirestoreResult, collectionRef, arrayResponse, inflateCollection } from "utils/firebase";
import { IGoal } from "types";
import { prepareForDb } from "utils/general-helpers";

export const goalCollections = {
  GOALS: "goals",
}

const BAD_FIELDS = ["id", "uid", "client", "project", "definition"];

export const goalsApi = (db: firebase.firestore.Firestore) => ({

  //---
  // Creates a new goal
  createGoal: async (orgId: string, goal: IGoal): Promise<IFirestoreResult> => {
    const id = goal.id;
    const prepared = prepareForDb(goal, BAD_FIELDS);
    const coll = collectionRef(db, orgId, COLS.GOALS);
    let goalId = "";
    
    if(id){
      const doc = coll.doc(id);
      await doc.set(prepared);
      goalId = id;
    }
    else{
      const result = await coll.add(prepared);
      goalId = result.id;
    }

    return {
      ok: true,
      statusCode: STATUSES.ok,
      key: goalId
    };
  },

  //---
  // Gets the list of goals for this org
  getGoals: async (orgId: string): Promise<IFirestoreResult> => {
    const qs = await collectionRef(db, orgId, COLS.GOALS).get();
    const items = inflateCollection<IGoal>(qs);
    return arrayResponse(items, orgId);     
  },

  //---
  // Updates an existing goal
  updateGoal: async (orgId: string, goalId: string, changes: Partial<IGoal>): Promise<IFirestoreResult> => {
    const prepared = prepareForDb(changes, BAD_FIELDS);
    const goal = collectionRef(db, orgId, COLS.GOALS).doc(goalId);
    await goal.update(prepared);

    return {
      ok: true,
      statusCode: STATUSES.ok,
      key: goalId
    };
  },

  //---
  // Deletes a goal
  deleteGoal: async (orgId: string, goalId: string): Promise<IFirestoreResult> => {
    const goal = collectionRef(db, orgId, COLS.GOALS).doc(goalId);
    await goal.delete();
    return {
      ok: true,
      statusCode: STATUSES.ok,
      key: goalId
    };
  }
  
});