import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import Typography from "@material-ui/core/Typography";
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import { cardStyles, fontStyles, generalStyles } from "utils/styles";
import { INote } from "./infra/note-types";
import { formatDate } from "utils/date-helpers";
import { SeparatorDot } from "components";
import { IconNames, Icons } from "utils/common-classes";
import { useBoolState, useHover, useSnackbar } from "utils/hooks";
import { useDispatch } from "react-redux";
import { deleteNote, updateNote } from "./infra/note-actions";
import { toggleEditNote } from "./infra/notes-slice";

const buildStyles   = makeStyles(theme => ({
  ...generalStyles(theme),
  ...cardStyles(theme),
  ...fontStyles(theme),
  note: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`, //`
    width: "100%",
    //TODO: Should we have the notes start collapsed and expandable so one tall note doesn't take up the whole view?
    // maxHeight: 150,
    // overflowY: "hidden",
    // textOverflow: "ellipsis",    
  },
  noteCard: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`, //`
  },
  contentCol: {
    marginLeft: theme.spacing(-2),
  },
  primaryRow: {
    height: 45,
  },
  propsRow: {
    marginLeft: theme.spacing(4),
  },
  deleteStyle: {
    background: theme.palette.error.light,
    borderColor: theme.palette.error.main,
  },
  deleteConfirm: {
    // background: theme.palette.error.main,
    color: theme.palette.error.dark,
    borderRadius: 4,
  },  
  hoverStyle: {
    background: `${theme.palette.secondary.light}11`, //`
  },
}));

interface ControlProps {
  note: INote;
}

const NoteViewCard = ({note}: ControlProps) => {
  const classes   = buildStyles();
  const dispatch = useDispatch();
  const notify = useSnackbar();
  const [hoverRef, isHovered] = useHover();
  const [isDeleting, toggleDelete] = useBoolState(false);

  const onDelete = async () => {
    await dispatch(deleteNote(note.id));
    notify("Note successfully deleted", "info");
  }

  const onEdit = async () => {
    await dispatch(toggleEditNote(note.id));
  }

  const onFavorite = async () => {
    await dispatch(updateNote(note.id, {isFavorite: !Boolean(note.isFavorite)}));
  }

  return (
    <Grid ref={hoverRef} container>
        <Grid className={clsx(classes.note, {[classes.deleteStyle]: isDeleting, [classes.hoverStyle]: (isHovered && !isDeleting)})}>
          <Grid container justify="flex-start" className={classes.noteCard}>
            
            <Grid item md={isDeleting ? 8 : 9} container alignItems="flex-start" justify="flex-start" wrap="nowrap" className={classes.contentCol}>
              <IconButton onClick={onFavorite} color={note.isFavorite ? "secondary" : undefined} size="small" title="Mark as Favorite" className={classes.marginRight}>{note.isFavorite ? Icons.star("small") : Icons.starOutline("small")}</IconButton>
              <Typography className={clsx(classes.bodyText, classes.preWrap)}>{note.content}</Typography>
            </Grid>

            {!isDeleting &&
              <Grid item md={3} className={classes.primaryRow} container justify="flex-end" alignItems="center">
                {isHovered &&
                  <>
                    <IconButton onClick={onEdit} title="Edit Note">{Icons.edit()}</IconButton>
                    <IconButton onClick={toggleDelete} title="Delete Note">{Icons.delete()}</IconButton>
                  </>
                }
              </Grid>
            }
            {isDeleting && 
              <Grid item md={4} className={classes.primaryRow}>
                <Grid container alignItems="center" justify="flex-end" spacing={1} className={classes.deleteConfirm}>
                  <Typography className={clsx(classes.bodyTextSmall, classes.bold)}>Delete this note?</Typography>
                  <Button onClick={onDelete} size="small" className={classes.marginLeft}>Yes</Button>
                  <Button onClick={toggleDelete} size="small" className={classes.marginLeft}>No</Button>
                </Grid>
              </Grid>
              }

            <Grid item md xs={12} container alignItems="center" justify="space-between" className={classes.propsRow}>
              <Grid item md container alignItems="center" className={classes.contentCol}>
                <Typography className={classes.caption}>{formatDate(note.noteDate)}</Typography>
                {note.clientId && <SeparatorDot />}
                <Typography className={classes.caption}>
                  {note.clientId && <Tooltip title="Client"><Icon fontSize="small" className={classes.marginRightDense}>{IconNames.client}</Icon></Tooltip>}{note.client?.name}
                </Typography>
                {note.projectId && <SeparatorDot />}
                <Typography className={classes.caption}>
                  {note.projectId && <Tooltip title="Project"><Icon fontSize="small" className={classes.marginRightDense}>{IconNames.project}</Icon></Tooltip>}{note.project?.name}
                </Typography>
              </Grid>

              
              
            </Grid>
            
          </Grid>
        </Grid>
      </Grid>
  );
}

export default NoteViewCard;
