import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from "@material-ui/lab/Autocomplete";
import Alert from '@material-ui/lab/Alert';
import { FocusWithin } from "react-focus-within";
import { cardStyles, fontStyles, generalStyles } from "utils/styles";
import { useInputs, useProjects, useSnackbar } from "utils/hooks";
import { orderBy } from "lodash";
import { selectClientsWithProjects } from "features/clients/infra/client-selectors";
import { hasActiveProject } from "features/clients/client-helpers";
import { DateInput } from "components";
import { IacClient, IacProject, INote, RootState } from "types";
import { endOfDay } from "date-fns";
import { createNote, updateNote } from "./infra/note-actions";
import { toggleEditNote } from "./infra/notes-slice";

const buildStyles   = makeStyles(theme => ({
  ...generalStyles(theme),
  ...fontStyles(theme),
  ...cardStyles(theme),
  editCard: {
    borderRadius: 0,
  },
  noteField: {
    "& textarea": {
      fontSize: "0.8rem",
    }
  },
  autoSelect: {
    "& .MuiFormControl-root": {
      marginBottom: 0,
    }
  },
  dateField: {
    marginBottom: 0,
  },
  disabled: {
    background: theme.palette.grey[50],
  }
}));

const emptyNote : Partial<INote> = {
  noteDate: new Date(),
  clientId: "",
  projectId: "",
  content: "",
};

interface ControlProps {
  note?: INote | null;
  clientId?: string;
  projectId?: string;
  classes?: Record<string, string>;
}

const NoteEditCard = ({note, clientId, projectId, ...props}: ControlProps) => {
  const classes   = buildStyles();
  const otherClasses = props.classes || {};
  const dispatch = useDispatch();
  const { status, editingId } = useSelector((state: RootState) => state.notes);
  const clients = useSelector(selectClientsWithProjects);
  const projects = useProjects("name");
  const defaultNote = useMemo(() => ({...emptyNote, ...note}), [note]);
  const [values, display, errors, binding, setValues ] = useInputs<Partial<INote>>(defaultNote, undefined, {updateOn: "onChange"});
  const canSave = useMemo(() => values.content && Object.keys(errors).length === 0, [values, errors]);
  const today = useMemo(() => endOfDay(new Date()), []);
  const notify = useSnackbar();

  const projectList = useMemo(() => {
    const available = values.clientId ? projects.filter(p => p.clientId === values.clientId) : projects;
    const prjs = available.map(p => ({id: p.id, name: p.name, clientId: p.clientId, status: p.deliveredDate ? "Delivered" : "Active"}  as IacProject));
    const ordered = orderBy(prjs, ["status"]);
    return ordered;
  }, [projects, values.clientId]);

  const clientList = useMemo(() => {
    const clis = clients.map(c => ({id: c.id, name: c.name, status: hasActiveProject(c.projects) ? "Active" : "Not Active"} as IacClient));
    const ordered = orderBy(clis, ["status", "name"]);
    return ordered;
  }, [clients]);

  const theProjectId = useMemo(() => projectId || values.projectId || null, [projectId, values.projectId]);
  const theClientId = useMemo(() => {
    if(projectId){
      const proj = projects.find(p => p.id === theProjectId);
      return proj?.clientId || null;
    }
    else return clientId || values.clientId || null;
  }, [clientId, projectId, values.clientId]);
  const cli = useMemo<IacClient | null>(() => {return theClientId ? clientList?.find(c => c.id === theClientId) || null : null}, [theClientId]);
  const prj = useMemo<IacProject | null>(() => {return theProjectId ? projectList?.find(p => p.id === theProjectId) || null : null}, [theProjectId]);
  const isEditingOther = useMemo(() => {return editingId && editingId !== note?.id; }, [editingId, note?.id]);

  async function onProjectChange(e: any, item: IacProject | null){
    let changes = {projectId: item?.id || null} as any;
    if(item && (!values.clientId || item.clientId !== values.clientId)){
      changes = {...changes, clientId: item.clientId};
    }
    setValues({...values, ...changes});
  }

  async function onClientChange(e: any, item: IacClient | null){
    let changes = {};
    if(item && values.projectId){
      const p = projectList.find(pj => pj.id === values.projectId);
      if(p && p.clientId !== item.id){
        changes = {projectId: null};
      }
    }
    changes = {...changes, clientId: item?.id || null};
    setValues({...values, ...changes});
  }

  //-- Saves the new note
  async function onSave(){
    const model: Partial<INote> = {
      ...values,
      clientId: cli?.id || "",
      projectId: prj?.id || "",
    };

    const result = await dispatch(createNote(model));
    if(result !== null){
      notify("Note successfully created", "success");
      setValues(emptyNote);
    }
  }

  async function onUpdate(){
    if(!note) return;

    const model: Partial<INote> = {
      ...values,
      clientId: cli?.id || "",
      projectId: prj?.id || "",
    };

    const result = await dispatch(updateNote(note.id, model));
    if(result !== null){
      notify("Note successfully updated", "success");
      // setValues(emptyNote);
    }
  }

  async function onCancel(){
    if(!note) setValues({...values, ...emptyNote});
    else{
      dispatch(toggleEditNote(note.id));
    }
  }

  return (
    <Paper className={clsx(classes.card, classes.editCard, otherClasses.paper, {[classes.disabled]: isEditingOther})} variant="outlined">
        <FocusWithin>
          {({ focusProps, isFocused}) => (
            <Grid container className={classes.cardBody} spacing={1} {...focusProps}>
              <Grid item container>
                <TextField id="content" label={note?.content ? "Note" : "New note"} value={display?.content} {...binding.inputWithKey("content")} disabled={isEditingOther} multiline fullWidth rows={(isFocused || note || values.content) ? 4 : 1} rowsMax={10} variant="outlined" className={classes.noteField} />
              </Grid>
              {(isFocused || note || values.content) && 
                <>
                  <Grid item md={2} sm={2} xs={4} container alignItems="center">
                    <DateInput id="noteDate" label="Date" value={values.noteDate} {...binding.dateInput("noteDate")} variant="outlined" margin="dense" className={classes.dateField} required maxDate={today} disabled={status.isWorking}/>
                  </Grid>
                  <Grid item md sm={4} container alignItems="center">
                    <Autocomplete 
                      autoComplete autoHighlight autoSelect
                      id="client-filter" 
                      options={clientList}
                      value={cli}
                      onChange={onClientChange} 
                      getOptionLabel={o => o.name} 
                      groupBy={o => o.status}
                      className={classes.autoSelect} 
                      fullWidth
                      renderInput={(p) => <TextField {...p} placeholder="Client (optional)" margin="dense" variant="outlined" fullWidth/>}
                      disabled={status.isWorking || Boolean(clientId || projectId)}  
                    />
                  </Grid>
                  <Grid item md sm={4} container alignItems="center">
                    <Autocomplete 
                      autoComplete autoHighlight autoSelect
                      id="project-filter" 
                      options={projectList}
                      value={prj}
                      onChange={onProjectChange} 
                      getOptionLabel={o => o.name} 
                      groupBy={o => o.status}
                      className={classes.autoSelect} 
                      fullWidth
                      renderInput={(p) => <TextField {...p} placeholder="Project (optional)" margin="dense" variant="outlined" fullWidth/>}
                      disabled={status.isWorking || Boolean(projectId)}  
                    />
                  </Grid>
                  <Grid item md sm={2} container alignItems="center" justify="flex-end">
                    <Button onClick={onCancel} disabled={status.isWorking} variant="outlined" size="small" className={clsx(classes.mt, classes.marginRight)}>{note ? "Cancel" : "Clear"}</Button>
                    <Button onClick={note ? onUpdate : onSave} disabled={!canSave || status.isWorking} variant="outlined" color="primary" size="small" className={classes.mt}>Save</Button>
                  </Grid>
                </>
              }
            </Grid>
          )}
        </FocusWithin>
    </Paper>
  );
}

export default NoteEditCard;
