import React from "react";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Fab from '@material-ui/core/Fab';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';
import { DialogKey, useAnchor, useDialogs, useLocalization, useProjects } from "utils/hooks";
import { generalStyles } from "utils/styles";
import { IconNames } from "utils/common-classes";
import { useHistory } from "react-router-dom";
import { getRoute, routes } from "./app-routes";

const useStyles   = makeStyles(theme => ({
  ...generalStyles(theme),
  fab: {
    minHeight: 32,
    height: 32,
    width: 32,
    marginLeft: theme.spacing(1),
  },
  menuItem: {
    minWidth: 250,
  }
}));

const AppFab = () => {
  const classes   = useStyles();
  const history = useHistory();
  const [anchor, isOpen, onOpen, onClose, position] = useAnchor("header-fab");
  const {openDialog} = useDialogs();
  const {LSwap} = useLocalization();
  const projects = useProjects();
  
  const openItem = (key: DialogKey) => () => {
    onClose();  //close the menu
    if(key !== "invoice"){
      openDialog(key, "-1");  //open the dialog
    }
    else{
      history.push(routes.invoiceNew);
    }
  }

  return (
    <>
      <Tooltip title="New...">
        <Fab size="small" color="secondary" aria-label="add" className={classes.fab} onClick={onOpen}>
          <Icon fontSize="small">add</Icon>
        </Fab>
      </Tooltip>
      <Menu anchorEl={anchor} keepMounted open={isOpen} onClose={onClose} {...position}>
        <MenuItem onClick={openItem("client")} className={classes.menuItem}>
          <Grid container>
            <Grid item xs={3}><Icon fontSize="small" className={classes.marginRight}>{IconNames.client}</Icon></Grid>
            <Grid item xs={7} container justify="flex-start">
              <Typography>{LSwap("New Client", "client")}</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem onClick={openItem("project")} className={classes.menuItem}>
          <Grid container>
            <Grid item xs={3}><Icon fontSize="small" className={classes.marginRight}>{IconNames.project}</Icon></Grid>
            <Grid item xs={7} container justify="flex-start">
              <Typography>{LSwap("New Project", "project")}</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem onClick={openItem("timer")} className={classes.menuItem} disabled={projects.length === 0}>
          <Grid container>
            <Grid item xs={3}><Icon fontSize="small" className={classes.marginRight}>{IconNames.timer}</Icon></Grid>
            <Grid item xs={7} container justify="flex-start">
              <Typography>{LSwap("New Timer", "timer")}</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem onClick={openItem("invoice")} className={classes.menuItem} disabled={projects.length === 0}>
          <Grid container>
            <Grid item xs={3}><Icon fontSize="small" className={classes.marginRight}>{IconNames.invoice}</Icon></Grid>
            <Grid item xs={7} container justify="flex-start">
              <Typography>{LSwap("New Invoice", "invoice")}</Typography>
            </Grid>
          </Grid>
        </MenuItem>
      </Menu>      
    </>
  );
}

export default AppFab;
