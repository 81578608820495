import { AppThunk, INote } from "types";
import { useFirestore, STATUSES } from "utils/firebase";
import { datesToStrings, getChanges, removeUndefined, stringsToDates } from "utils/general-helpers";
import { itemsLoaded, itemCreated, itemUpdated, itemDeleted, statusChanged } from "./notes-slice";

//-- load notes
export const loadNotes = (): AppThunk => async (dispatch, getState) => {
  const [api, uid] = useFirestore();

  if (!uid) throw new Error("Must be logged in to load notes.");
  const app = getState().app;

  if (app.isInitialized) {

    //Check to see if we've already loaded them... since we get all of them at once
    const noteState = getState().notes;
    if(noteState.isInitialized) return;

    dispatch(statusChanged({ isWorking: true, error: null }));
    const result = await api.getNotes(app.org.id, null, null, null);
    if (result.statusCode === STATUSES.ok || result.statusCode === STATUSES.empty) {
      const list = result.items?.map(i => datesToStrings(i)) as INote[];
      dispatch(itemsLoaded(list || []));
    }
    else{
      dispatch(statusChanged({ isWorking: false, error: result.error }));
    }
  }
}

//-- create a note
export const createNote = (item: Partial<INote>): AppThunk => async (dispatch, getState) => {
  const [api, uid] = useFirestore();

  if (!uid) throw new Error("Not logged in.");
  const app = getState().app;

  if (app.isInitialized) {
    await dispatch(statusChanged({ isWorking: true, error: null }));
    const prepared = removeUndefined({ ...item, uid, createdDate: new Date() });
    const result = await api.createNote(app.org.id, prepared);
    if (result.statusCode === STATUSES.ok) {
      //Update the local store
      const toStore = {
        ...datesToStrings(prepared),
        id: result.key
      } as INote;

      await dispatch(itemCreated(toStore));

      return toStore;
    }
    else{
      await dispatch(statusChanged({ isWorking: false, error: result.error }));
      return null;
    }
  }
}

//-- update a note
export const updateNote = (noteId: string, changes: Partial<INote>): AppThunk => async (dispatch, getState) => {
  const [api, uid] = useFirestore();

  if (!uid) throw new Error("Not logged in.");
  const app = getState().app;

  if (app.isInitialized) {
    //get the actual changes
    const note = getState().notes.items?.find(nte => nte.id === noteId);
    if (note) {
      const actualChanges = getChanges(note as any, changes as any);

      if (actualChanges !== null) {
        dispatch(statusChanged({ isWorking: true, error: null }));
        const prepared = {...stringsToDates(actualChanges), lastUpdatedDate: new Date()};
        const result = await api.updateNote(app.org.id, noteId, prepared);

        if (result.statusCode === STATUSES.ok) {
          //Update the local store
          const toStore = datesToStrings(prepared);
          dispatch(itemUpdated({ id: noteId, changes: toStore }));
          return noteId;
        }
        else{
          dispatch(statusChanged({ isWorking: false, error: result.error }));
        }
      }
    }
  }

  return null;
}

//-- delete a note
export const deleteNote = (noteId: string): AppThunk => async (dispatch, getState) => {
  const [api, uid] = useFirestore();

  if (!uid) throw new Error("Not logged in.");
  const app = getState().app;

  if (app.isInitialized) {
    //get the actual changes
    const note = getState().notes.items?.find(nte => nte.id === noteId);
    if (note) {
      dispatch(statusChanged({ isWorking: true, error: null }));
      const result = await api.deleteNote(app.org.id, noteId);

      if (result.statusCode === STATUSES.ok) {
        //Update the local store
        dispatch(itemDeleted({id: noteId}));
        return noteId;
      }
      else{
        dispatch(statusChanged({ isWorking: false, error: result.error }));
      }
    }
  }

  return null;
}