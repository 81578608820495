import React, { useMemo } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { RootState } from "types";
import { formStyles, generalStyles } from "utils/styles";
import { useDispatch, useSelector } from "react-redux";
import { LoadingIndicator } from "components";
import { EVENTS, trackEvent } from "utils/mixpanel";
import { purgeSampleData, resetStore } from "./infra/onboarding-actions";
import { useHistory } from "react-router-dom";
import { routes } from "features/app";

const buildStyles   = makeStyles(theme => ({
  ...generalStyles(theme),
  ...formStyles(theme),
  root  : {
    padding     : theme.spacing(3),
  },
  title   : {
    fontSize    : 22,
    fontWeight  : 500,
    textAlign   : "center",
    marginBottom: theme.spacing(2),
  },
  subTitle  : {
    fontSize: 18,
    fontWeight: 400,
    textAlign: "center",
    color: theme.palette.secondary.main,
    marginBottom: theme.spacing(1),
  },
  emphasis: {
    fontSize: 18,
    fontWeight: 500,
    textAlign: "center",
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(1),
  },
  body: {
    fontSize: 16,
    fontWeight: 400,
    textAlign: "center",
  },
  contentGrid: {
    height: 150,
    width: "100%",
  }
}));

const OnboardingRemove = () => {
  const classes   = buildStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const isOnboarding = useSelector((state: RootState) => state.app.isOnboarding);
  const onboarding = useSelector((state: RootState) => state.app.org.onboarding);
  const isRemoved = useMemo(() => Boolean(onboarding && onboarding.sampleEndDate), [onboarding]);
  const message = "Removing sample data...";

  const onCancel = () => {
    history.goBack();
  }

  const onRemove = async () => {
    trackEvent(EVENTS.sample, "remove-data");    //track that they want to remove the sample data
    const result = await dispatch(purgeSampleData());
    
    console.log(result);
    await dispatch(resetStore());
  }

  const onRefresh = () => {
    history.replace(routes.dashboard);
  }
  
  return (
    <Grid item container direction="column" justify="center" alignItems="center" className={classes.root}>
      <Typography variant="h3" className={classes.title}>{"Let's get started"}</Typography>
      <Typography variant="h3" className={clsx(classes.subTitle, classes.mbl)}>{"Press the button below to remove the sample data."}</Typography>
      <Typography variant="h3" className={clsx(classes.subTitle, classes.mbl)}>{"Once you've removed the samples, you can start adding your own data."}</Typography>
      
      <Divider variant="middle" className={classes.divider}/>

      <Grid container direction="column" alignItems="center" justify="center" spacing={2} className={classes.contentGrid}>
        {!isOnboarding && !isRemoved &&
          <>
            <Grid item>
              <Button onClick={onRemove} disabled={isOnboarding} variant="outlined" color="primary" className={clsx([classes.actionButton, classes.marginX])}>Remove Sample Data</Button>
            </Grid>
            <Grid item>
              <Button onClick={onCancel} disabled={isOnboarding} className={clsx([classes.actionButton, classes.marginX])}>Nevermind, take me back</Button>
            </Grid>
          </>
        }
        {isOnboarding && !isRemoved &&
          <LoadingIndicator isVisible={true} message={message} />
        }
        {isRemoved &&
          <>
            <Typography variant="h3" className={classes.emphasis}>All Set!</Typography>
            <Typography variant="h3" className={clsx(classes.body, classes.mbl)}>{"Click below to refresh the browser, then you will be able to add your own data."}</Typography>
            <Button onClick={onRefresh} variant="outlined" color="primary" className={clsx([classes.actionButton, classes.marginX])}>Get Started</Button>
          </>
        }
      </Grid>
    </Grid>
  );
}

export default OnboardingRemove;
