import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import { IFilter } from "types";

const useStyles = makeStyles({
  chip: {
    height: 28,
    minWidth: 50,
  },
  noWrap: {
    flexWrap: "nowrap",
  }
});

interface FilterListProps {
  filters: IFilter[];
  currentFilter: string | null | undefined;
  onClick: (item: IFilter) => void;
  color?: "primary" | "secondary" | "default" | undefined;
  classes?: Record<string, string>;
  noWrap?: boolean;
}

const FilterList = (props: FilterListProps) => {
  const myClasses = useStyles();
  const gridClass = props.noWrap ? myClasses.noWrap : undefined;

  function isSelected(item: any) {
    return item.filter === props.currentFilter; //listProps.filter; // && item.sort === props.listProps.sort;
  }

  const onChipClick = (item: any) => {
    props.onClick(item);
  }

  return (
    <div className={props.classes?.container}>
      <Grid id="list-filter" container justify="flex-start" spacing={1} className={gridClass}>
        {
          props.filters.map(item => {
            return (
              <Grid key={item.id} item>
                <Tooltip title={item.title || ""}>
                  <Chip variant={isSelected(item) ? "default" : "outlined"} className={myClasses.chip} color={isSelected(item) ? props.color : "default"} label={item.label} clickable onClick={() => onChipClick(item)} />
                </Tooltip>
              </Grid>
            )
          })
        }
      </Grid>
    </div>
  );
}

export default FilterList;
