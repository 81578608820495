import { IChange } from "types";

export const currencyRegex = /^[\$]?(0|[1-9][0-9]{0,2})(,\d{3})*(\.\d{1,2})?$/g;
const invChars = /[,$%]/g;

export const atkParseFloat = (val: string, len?: number): number => {
  const sVal = val.replace(invChars, "");

  if (sVal.endsWith(".")) return NaN;
  let fVal = parseFloat(sVal);

  if (len) {
    //truncate it if necessary
    fVal = parseFloat(fVal.toFixed(len));
  }

  return fVal;
}

export const atkParseInt = (val: string): number => {
  if (typeof (val) === "number") val = (val as number).toString();

  const sVal = val.replace(invChars, "");

  if (sVal.endsWith(".")) return NaN;
  const iVal = parseInt(sVal);

  return iVal;
}

export const atkParsePercent = (val: string, len: number | undefined = 2): number => {
  const sVal = val.replace(invChars, "");

  if (sVal.endsWith(".")) return NaN;
  let fVal = parseFloat(sVal) / 100;

  if (len) {
    //truncate it if necessary
    fVal = parseFloat(fVal.toFixed(len + 2));  //since we're dividing by 100, need to increase the decimal places
  }

  return fVal;
}

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  // the default value for minimumFractionDigits depends on the currency
  // and is usually already 2
});

const wholeCurrencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  // the default value for minimumFractionDigits depends on the currency
  // and is usually already 2
});

export const calcChange = (previous: number, current: number): IChange => {
  const abs = current - previous;
  const pct = previous === 0 ? 1 : abs / previous;
  const dir = abs > 0 ? "up" : abs === 0 ? "flat" : "down";

  return {
    pct: pct,
    diff: abs,
    dir: dir,
  };
}

export const formatCurrency = (val: number | undefined, whole: boolean | "force" = true, zeroFallback = "-", emptyfallback = "-"): string => {
  if (val === undefined || val === null) return emptyfallback;
  const digits = (whole === true) ? 0 : 2;
  const tVal: number = parseFloat(val.toFixed(digits));
  if (tVal === 0 && zeroFallback) return zeroFallback;
  const formatter = (tVal % 1 > 0 || whole === "force") ? currencyFormatter : wholeCurrencyFormatter;

  const fVal = formatter.format(tVal); //'`$${tVal.toLocaleString()}`  //`
  return fVal;
}

export const formatCurrencyK = (val: number | undefined, zeroFallback = "-", emptyfallback = "-"): string => {
  if (val === undefined || val === null) return emptyfallback;
  // const digits = (whole === true) ? 0 : 2;
  const tVal: number = parseFloat(val.toFixed(2)) / 1000;
  if (tVal === 0 && zeroFallback) return zeroFallback;
  // const formatter = (tVal % 1 > 0 || whole === "force") ? currencyFormatter : wholeCurrencyFormatter;

  const fVal = wholeCurrencyFormatter.format(tVal); //'`$${tVal.toLocaleString()}`  //`
  return `${fVal}K`;
}

export const formatPercent = (val: number, digits = 0, zeroFallback = "-") => {
  if (val === 0) return zeroFallback;
  const tVal: number = parseFloat((val * 100).toFixed(digits));
  const dVal = `${tVal.toLocaleString()}%`  //`
  return dVal;
}

export const formatHours = (val: number | undefined, digits = 2, zeroFallback = "-", emptyfallback = "-") => {
  if (val === undefined) return emptyfallback;
  const tVal: number = parseFloat(val.toFixed(digits));
  if (tVal === 0 && zeroFallback) return zeroFallback;
  return tVal.toString();
  // const formatter = tVal % 1 > 0 ? currencyFormatter : wholeCurrencyFormatter;

  // const fVal = formatter.format(tVal); //'`$${tVal.toLocaleString()}`  //`
  // return fVal;
}

