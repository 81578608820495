import { addDays, isBefore, isSameYear, startOfDay, subYears } from "date-fns";
import { Invoice, InvoiceFilter } from "./invoice-types";
import { IParserCollection, Parsers } from "utils/hooks";
import { parseDate } from "utils/date-helpers";

export const newInvoice: Invoice = {
  id: "-1",
  number: "",
  invoiceDate: startOfDay(new Date()),
  paidDate: null,
  dueDate: startOfDay(addDays(new Date(), 15)),
  projectId: "",
  clientId: "",
  amount: 0,
  notes: "",
  hours: []
};

export const invoiceParsers: IParserCollection = {
  invoiceDate: Parsers.dateString,
  amount: Parsers.currency,
};

export const filterInvoices = (invoices: Invoice[], filter: InvoiceFilter) => {
  switch(filter){
    case "all": return invoices;
    case "unpaid": return invoices.filter(inv => !inv.paidDate);
    case "paid": return invoices.filter(inv => !!inv.paidDate);
    case "overdue":
    case "due-soon":
      {
        const threshold = (filter === "overdue") ? new Date() : addDays(new Date(), 7);
        const items = invoices.filter(inv => {
          if(inv.dueDate && !inv.paidDate){
            const dd = parseDate(inv.dueDate);
            return !!dd && isBefore(dd, threshold)
          }
          return false;
        });
        return items;
      }
    case "this-year":{
      const now = new Date();
      const filtered = invoices.filter(inv => isSameYear(inv.invoiceDate as Date, now));
      return filtered;
    }
    case "paid-this-year": {
      const now = new Date();
      const filtered = invoices.filter(inv => !!inv.paidDate && isSameYear(inv.paidDate as Date, now));
      return filtered;
    }
    case "last-year":{
      const yearAgo = subYears(new Date(), 1);
      const filtered = invoices.filter(inv => isSameYear(inv.invoiceDate as Date, yearAgo));
      return filtered;
    }
    case "paid-last-year": {
      const yearAgo = subYears(new Date(), 1);
      const filtered = invoices.filter(inv => !!inv.paidDate && isSameYear(inv.paidDate as Date, yearAgo));
      return filtered;
    }
  }

}