import React, { useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import AtkApp from "./app-context";
import AppHeader from "./app-header";
import AppSidebar from "./app-sidebar";
import AppContent from "./app-content";
import UITester from "../testing/ui-tester";
import { useAuth, useIsMobile, useLocalSetting } from "utils/hooks";
import { updateAppSettings } from "./app-slice";
import { RootState } from "store/root-reducer";
import { trackError, trackNav } from "utils/mixpanel";
import { selectIsOnboarded } from "./infra/common-selectors";
import ErrorContainer from "components/error-container";

const buildStyles = makeStyles({
    appContainer: {
      flexWrap: "nowrap",
    },
  });

export default function AppContainer() {
  const classes = buildStyles();
  const dispatch = useDispatch();
  //TODO: can all this move to the sidebar itself, and it determine whether it should be open or not??
  const [config, saveConfig] = useLocalSetting("app", { isSidebarOpen: true });
  const isSidebarOpen = useSelector((state: RootState) => state.app.settings.isSidebarExpanded);
  const auth = useAuth();
  const isOnboarded = useSelector(selectIsOnboarded);
  
  //Effect to keep the store in sync with the localsettings
  useEffect(() => {
    if (config.isSidebarOpen !== isSidebarOpen) {
      dispatch(updateAppSettings({ isSidebarExpanded: config.isSidebarOpen }));
    }
  }, [config, isSidebarOpen]);

  const isMobile = useIsMobile();

  function toggleSidebar() {
    const newVal = !config.isSidebarOpen;
    saveConfig({ isSidebarOpen: newVal });
  }

  const onError = (error: Error, info: {componentStack: string}) => {
    const errString = error.toString();
    console.log("caught an error", errString, info, error);
    trackError(errString, info);
  };  

  return (
    <AtkApp>
      <Route path="/" render={({location}) => {
        trackNav(location);
        return null;
      }} />
      <Switch>
        <Route path="/testing" component={UITester}/>
        <Route>
          <Grid container className={classes.appContainer}>
            <AppHeader isOpen={config.isSidebarOpen} toggle={toggleSidebar} />
            {!isMobile && auth.isAuthenticated && isOnboarded && <AppSidebar isOpen={config.isSidebarOpen} toggle={toggleSidebar} />}
            <ErrorBoundary FallbackComponent={ErrorContainer} onError={onError}>
              <AppContent />
            </ErrorBoundary>
          </Grid>
        </Route>
      </Switch>      
    </AtkApp>
  );
}
