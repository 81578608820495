import React from "react";
import Grid from "@material-ui/core/Grid";
import Icon from '@material-ui/core/Icon';
import Alert from '@material-ui/lab/Alert';

type StepsProps = {
  steps: number;
  current: number;  
  classes: any;
}

const Steps = (props: StepsProps) => {
  // const classes   = buildStyles();
  if(props.steps <= 0){
    return null;
  }
  else if(props.current > props.steps){
    return (<Alert color="error">Current Step is invalid</Alert>)
  }

  //generate an array with props.steps items in it
  let index = 0;
  const items = Array(props.steps).fill(0);
  items[props.current] = 1;
  // const items = Array.apply(null, Array(props.steps)).map(function (x, i) { return i === props.current; })

  return (
    <Grid container justify="center" alignItems="center" className={props.classes}>
      {items.map(item => <Icon key={index++} fontSize="small">{item === 1 ? "radio_button_checked" : "radio_button_unchecked"}</Icon>  )}
      {/* )}
      <Icon fontSize="small">radio_button_checked</Icon>
      <Icon fontSize="small">radio_button_unchecked</Icon>
      <Icon fontSize="small">radio_button_unchecked</Icon> */}
    </Grid>
  );
}

export default Steps;
