import React from "react";
import _, { isString } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Icon from '@material-ui/core/Icon';
import Divider from '@material-ui/core/Divider';
import CheckIcon from '@material-ui/icons/Check';
import { useAnchor } from "utils/hooks";
import { IMenuItem } from "types";
import { AnchorPosition, AnchorPositions } from "utils/hooks/layout-hooks";

const useStyles = makeStyles(theme => ({
  rightMargin: {
    marginRight: theme.spacing(1),
  },
  leftMargin: {
    marginLeft: theme.spacing(1),
  },
  menuItem: {
    minWidth: 250,
  },
  chip: {
    height: 28,
  }
}));

interface ChipMenuProps {
  icon?: any;
  label: string;
  items: IMenuItem[];
  isSelectable?: boolean;
  onClick?: (item: IMenuItem) => void;
  isSelected?: (item: IMenuItem) => boolean;
  title?: string;
  color?: "default" | "primary" | "secondary" | undefined;
  size?: "default" | "inherit" | "small" | "large" | undefined;
  disabled?: boolean | undefined;
  variant?: "default" | "outlined";
  classes?: Record<string, string>;
  menuPosition?: AnchorPosition;
}

const ChipMenu = ({ icon, label, items, isSelected, onClick, title, color, size, disabled, variant, classes, menuPosition }: ChipMenuProps) => {
  const myClasses = useStyles();
  const anchorPos = menuPosition ? AnchorPositions[menuPosition] : undefined;
  const [anchor, isOpen, onOpen, onClose, position] = useAnchor(null, anchorPos);
  const hasIcons = React.useMemo(() => !!items.find(i => !!i.icon), [items]);
  icon = icon || <Icon fontSize={size ?? "small"} color={color ?? "disabled"}>filter_list</Icon>;

  const handleAction = (e: any, item: IMenuItem) => {
    if (item.action && _.isFunction(item.action)) item.action();
    else if (onClick) onClick(item);
    onClose(e);
  }

  return (
    <div className={classes?.container}>
      <Grid container justify="flex-end" alignItems="center">
        <Tooltip title={title || ""}>
          <Chip deleteIcon={icon} onDelete={onOpen} clickable label={label} color={color ?? "default"} onClick={onOpen} disabled={disabled || items.length === 0} variant={variant} className={myClasses.chip}/>
        </Tooltip>
        <Menu
          anchorEl={anchor}
          keepMounted
          open={isOpen}
          onClose={onClose}
          {...position}
        >
          {items.map(item => {
            const labelProps = item.icon ? { className: myClasses.leftMargin } : null;
            const icon = hasIcons ? (isString(item.icon) ? <Icon>{item.icon}</Icon> : item.icon) : null;
            return item.isDivider ? <Divider key={item.id} /> :
              <MenuItem key={item.id} onClick={(e) => handleAction(e, item)} className={myClasses.menuItem}>
                <Grid container>
                  {isSelected && <Grid item xs={2}><SelectedIcon isSelected={isSelected(item)} classes={myClasses} /></Grid>}
                  {hasIcons && <Grid item xs={3}>{icon}</Grid>}
                  <Grid item xs={7} container justify="flex-start"><Typography {...labelProps}>{item.label}</Typography></Grid>
                </Grid>
              </MenuItem>
          })}
        </Menu>
      </Grid>
    </div>
  )
}

export default ChipMenu;


interface SelectedIconProps {
  isSelected: boolean;
  classes: ReturnType<typeof useStyles>;
}

const SelectedIcon = ({ isSelected, classes }: SelectedIconProps) => {
  if (!isSelected) return null;
  else {
    return <CheckIcon className={classes.rightMargin} />
  }
}