import React, { useContext, useMemo, useState } from "react";
import { compact, min, orderBy, uniq } from "lodash";
import Grid from "@material-ui/core/Grid";
import AtkTable from "components/atk-table";
import Alert from "@material-ui/lab/Alert";
import InvoicableRow, { rowHeader } from "../ui/invoicable-hours-row";
import { InvoiceContext } from "../ui/invoice-container";
import { ISort, ITimer, ProjectType, RootState } from "types";
import { areArraysEqual } from "utils/general-helpers";
import { useDispatch, useSelector } from "react-redux";
import { clearQuery, loadHours, selectHoursQuery } from "features/timesheet";
import { useLocalization, useProjects } from "utils/hooks";
import { formatHours, formatCurrency } from "utils/number-helpers";

const queryKey = "invoicable";

interface ControlProps{
  isWorking: boolean;
}

const InvoiceEditHours = ({isWorking}: ControlProps) => {
  const dispatch = useDispatch();
  const { LSwap } = useLocalization();
  const { invoice, clientId, projectId, setIncludedHours, hoursCount, hoursTotal } = useContext(InvoiceContext);
  const allHours = useSelector((state: RootState) => selectHoursQuery(state, queryKey));
  const tsStatus = useSelector((state: RootState) => state.timesheet.status);
  const allProjects = useProjects();
  const [selectedHours, setSelectedHours] = useState<string[]>([]);
  const [gridSettings, setGridSettings] = useState<ISort>({ sort: "startTime", sortDir: "asc" });
  const [hourlies, setHourlies] = useState<Record<string, any>>({});
  const isMultiple = useMemo(() => Boolean(projectId === "multiple"), [projectId]);

  //-- Effect to load the un-invoiced hours for this project, if necessary
  React.useEffect(() => {
    if(projectId){
      if(isMultiple && clientId){
        const clientProjects = allProjects.filter(p => p.clientId === clientId);
        const trackedProjects = clientProjects.filter(p => !!p.trackedMinutes).slice(0, 10);  //get a list of projects with tracked minutes ()
        if(trackedProjects.length){
          const minDate = min(trackedProjects.map(p => p.startDate as Date));
          const ids = trackedProjects.map(t => t.id);
          dispatch(loadHours(minDate as Date, null, [{field: "projectId", operator: "in", value: ids}], queryKey));
          
          const hourlyProjects = trackedProjects.filter(tp => tp.type === ProjectType.perHour);
          const hourlyRates = hourlyProjects.reduce((rates, prj) => ({...rates, [prj.id]: prj.fee || 0}), {});
          setHourlies({...hourlyRates, count: hourlyProjects.length});
          return;
        }
      }
      else if(projectId && projectId.length){
        const project = allProjects.find(p => p.id === projectId);
        if(project && project.trackedMinutes){
          dispatch(loadHours(project.startDate as Date, null, [{field: "projectId", operator: "==", value: projectId}], queryKey));
          if(project.type === ProjectType.perHour){
            setHourlies({[project.id]: project.fee || 0, count: 1});
          }
          return;    
        }
      }
    }

    //If we made it here, there are no hours to show...
    dispatch(clearQuery(queryKey));

}, [clientId, projectId]);

  //-- Update the selected hours locally and in the context, along with the related info to sync across parts of the invoice
  const updateContext = (ids: string[]) => {
    setSelectedHours(ids);  //update local list...

    const items = compact(ids.map(id => allHours.find((ah: any) => ah.id === id)));
    const totals = items.reduce((total, item) => {
      const mins = (item.minutes || 0) - (item.creditMinutes || 0);
      const itemCount = ((mins >= 0 ? mins : 0) / 60);
      const itemFee = itemCount * (hourlies[item.projectId] || 0);
      const result = {
        sum: total.sum + itemFee, 
        count: total.count + itemCount, 
        prjs: [...total.prjs, item.projectId] as string[]
      };
      return result;
    }, {sum: 0, count: 0, prjs: [] as string[]});

    const prjs = uniq(totals.prjs); //items.map(i => i.projectId));
    
    //Pass the updated hours to the context
    setIncludedHours(ids, totals.count, totals.sum, prjs);   //update the context 
  }

  //-- Effect to initialize the selected hours collection if there's an invoice
  React.useEffect(() => {
    if(!allHours || !allHours.length || !hourlies.count || tsStatus.isWorking) return; //wait until we have the hours (and the correct ones for this invoice)
    if(invoice && invoice.hours && invoice.hours.length > 0){
      if(!areArraysEqual(selectedHours, invoice.hours)){
        setSelectedHours(invoice.hours);
        updateContext(invoice.hours);
      }
    }
    else if(selectedHours.length > 0){
      setSelectedHours([]); //reset to nothing selected if the invoice doesn't have any
      updateContext([]);
    }
  }, [invoice, allHours, hourlies]);

  //List of available hours (uninvoiced)
  const availableHours = useMemo(() => {
    if(allHours && allHours.length && (isMultiple || allHours[0].projectId === projectId)){
      let included: ITimer[] = [];
      
      if(invoice && invoice.hours && invoice.hours.length > 0){
        const invoicedHours = invoice.hours || [];
        included = allHours.filter((h: ITimer) => invoicedHours.indexOf(h.id) >= 0);
      }

      const filtered = allHours.filter((h: ITimer) => !h.invoiceId && !h.invoiceDate && !h.paymentDate);
      const available = [...included, ...filtered];
      const ordered = orderBy(available, "startTime", "asc");
      return ordered;
    }
    return [];
  }, [invoice, hourlies, allHours]);

  //Caption for the list, showing the total # and amount of hours selected
  const caption = useMemo(() => {
    if(hoursCount || hoursTotal){
      const count = selectedHours.length ? `Select Rows: ${selectedHours.length}` : "";
      const hrs = hoursCount ? `Selected Hours: ${formatHours(hoursCount)}` : "";
      const amt = hoursTotal ? `Selected Amount: ${formatCurrency(hoursTotal, false)}` : "";
      const result = [count, hrs, amt].join(", ");
      return result;
    }
    return "";
  }, [hoursCount, hoursTotal])
  
  //-- Sorting of the table
  const onTableSorted = (order: ISort) => {
    setGridSettings({ sort: order.sort, sortDir: order.sortDir });
  }

  //-- Timer rows selected in the table
  const onTimerSelected = (ids: string[]) => {
    //NOTE: this collection may include items from previuos projects, if rows were selected when they switched projects.
    // so don't rely on ids collection, rely on items collection
    if(areArraysEqual(selectedHours, ids)) return;  //make sure something has changed before we do anything
    
    const items = compact(ids.map(id => allHours.find((ah: any) => ah.id === id)));
    const itemIds = items.map(i => i.id);
    setSelectedHours(itemIds);

    updateContext(ids);   
  }

  return (
    <Grid item xs={12} container>
      {!projectId && 
        <Alert severity="info">Choose a project to select available timers</Alert>
      }
      {(projectId && availableHours) && 
        <AtkTable
          rowId="id"
          header={rowHeader(isMultiple)}
          rows={availableHours}
          RowComponent={InvoicableRow}
          dense={true}
          order={gridSettings}
          onSort={onTableSorted}
          emptyMessage={LSwap("This project does not have any available hours", "hours")}
          settingKey="invoicable-list"
          selectable={true}
          onSelected={onTimerSelected}
          selected={selectedHours}
          isWorking={isWorking}
          extra={{isMultiple}}
          caption={caption}
        />
      }
    </Grid>
  );
}

export default InvoiceEditHours;
