import React from "react";
import { Route, useParams } from "react-router-dom";
import { routes } from "features/app";
import InvoiceContainer from "./invoice-container";
import { useSelector } from "react-redux";
import { RootState } from "types";

const InvoiceView = () => {
  const { invoiceId } = useParams<{ invoiceId: string }>();
  const isWorking = useSelector((state: RootState) => state.invoices.status.isWorking);
  
  return (
    <>
      <Route path={routes.invoiceNew} exact>
        <InvoiceContainer invoiceId="-1" isWorking={isWorking}/>
      </Route>
      <Route path={routes.invoiceEdit} exact>
        <InvoiceContainer invoiceId={invoiceId} isWorking={isWorking}/>
      </Route>
      <Route path={routes.invoice} exact>
        <InvoiceContainer invoiceId={invoiceId} isWorking={isWorking}/>
      </Route>
    </>
  );
}

export default InvoiceView;
