import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import Grid from "@material-ui/core/Grid";
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from "@material-ui/core/Typography";
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import { useLocalization } from "utils/hooks";
import { selectProjectWidgetData } from "features/projects";
import AtkTable, { HeadCell, RowProps } from "components/atk-table";
import { formatFee } from "features/projects/infra/project-helper";
import { formatDate } from "utils/date-helpers";
import { getRoute } from "features/app";

const buildStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(2),
    background: theme.palette.common.white,
    borderRadius: 5,
  },
  titleRow: {
    padding: `${theme.spacing(0)}px ${theme.spacing(2)}px`, //`
    marginBottom: theme.spacing(1),
  },
  title: {
    fontSize: 18,
    fontWeight: 400,
    color: theme.palette.grey[500],
    marginBottom: theme.spacing(1),
    textTransform: "uppercase",
  },
  navButton: {
    padding: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
  },
  tableGrid: {
    margin: 0, //`${theme.spacing(0)}px ${theme.spacing(-2)}px`, //`,
    maxHeight: 200,
    overflowY: "auto",
  },
  tableRow: {
    "& td, th": {
      border: "none",
    }
  },
  tablePaper: {
    border: "none !important",
  },
  filterChip: {
    marginLeft: theme.spacing(1),
    "& span": {
      fontWeight: 700,
      fontSize: 12,
      // color: theme.palette.grey[600],
    }
  }
}));

const rowHeader: HeadCell<any>[] = [
  { id: "projectName", label: 'Name' },
  { id: "dueDate", label: "Due", },
  { id: "amount", label: "Amount", },
];

const ProjectRow = (props: RowProps<any>) => {
  const { item, labelId, extra } = props;
  const url = getRoute("project", item.id);
  const [alt, setAlt] = useState(false);

  const value = formatFee(item, alt); //[extra.period];
  // const change = item[`${extra.period}Change`] as IChange;
  // const changeTitle = (change.dir === "flat") ? undefined : `{change} ${formatPercent(Math.abs(change.pct), 0)} this ${extra.period}`;

  return (
    <TableRow hover className={extra.classes.tableRow}>
      <TableCell component="th" id={labelId} scope="row" padding="default">
        <Link to={url}>{item.name}</Link>
      </TableCell>
      <TableCell title="due date">{formatDate(item.dueDate)}</TableCell>
      <TableCell align="right" onClick={() => setAlt(!alt)}>{value}</TableCell>
    </TableRow>
  )
}

const ProjectsWidget = () => {
  const classes = buildStyles();
  const history = useHistory();
  // const [period, setPeriod] = React.useState("year");
  const {L} = useLocalization();
  const data = useSelector(selectProjectWidgetData);

  // const rows = React.useMemo(() => {
  //   if (!data) return [];
  //   const items = _.orderBy(data, [period], ["desc"]).slice(0, 5);
  //   return items;
  // }, [data, period]);

  const navTo = (path: string, filter: string | null = null) => () => {
    console.log("TODO: filter not applied", filter);
    history.push(path);   //right now, only using path here...
  }

  return (
    <Paper>
      <Grid id="projects-widget" container justify="center" className={classes.root}>
        <Grid container className={classes.titleRow} alignItems="flex-start">
          <Grid item xs={9} container alignItems="flex-start">
            <Typography className={classes.title}>{L("projects")}</Typography>
            <IconButton onClick={navTo("/projects")} className={classes.navButton}><Icon color="disabled" fontSize="small">arrow_forward</Icon></IconButton>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} container className={classes.tableGrid}>
            <AtkTable
              rowId="id"
              header={rowHeader}
              rows={data || []}
              RowComponent={ProjectRow}
              dense={true}
              order={{ sort: "dueDate", sortDir: "asc" }}
              settingKey="prj-widget-list"
              noPaging={true}
              noHeader={true}
              classes={{ paper: classes.tablePaper }}
              extra={{
                classes: classes,
                // period: period,
              }} />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default ProjectsWidget;
