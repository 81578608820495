import React, { FunctionComponent, useMemo } from "react";
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { RowProps, HeadCell } from "components/atk-table";
import { ITimer, IProject, IClient, ProjectType } from "types";
import { formatDate } from "utils/date-helpers";
import { getDuration, getDurationFromMinutes, formatDuration } from "features/timesheet";
import { formatCurrency } from "utils/number-helpers";


export interface InvoicedRow extends ITimer {
  client?: IClient;
  project?: IProject;
  startMoment?: string;
  stopMoment?: string;
  duration?: string;
}

export const rowHeader = (isMultiple: boolean): HeadCell<InvoicedRow>[] => {
  const cols: HeadCell<InvoicedRow>[] = [
    { id: "id", label: "Id", isHidden: true },
    { id: "startTime", label: 'Start', padding: "default", width: 140, },
  ];
  
  if(isMultiple){
    cols.push({ id: "project", label: "Project", padding: "default" },)
  }

  cols.push({ id: "category", label: "Category", padding: "default" });
  cols.push({ id: "notes", label: "Notes", padding: "default" });
  cols.push({ id: "minutes", label: "Duration", align: "right", padding: "default" });
  cols.push({ id: "rate", label: "Rate", padding: "default", noSort: true });
  cols.push({ id: "amount", label: "Amount", padding: "default", align: "right", noSort: true });

  return cols;
};

const InvoicedRow: FunctionComponent<RowProps<ITimer>> = ({ item, labelId, extra }) => {
  const myExtra = extra || {};
  const rate = (item.project?.type === ProjectType.perHour && item.project?.fee) ? item.project?.fee : 0; //TODO:
  const duration = useMemo(() => {
    let dur = null;
    if (item.minutes) {
      dur = getDurationFromMinutes(item.minutes);
    }
    else {
      dur = getDuration(item.startTime as Date, item.stopTime as Date);
    }
    return dur;
  }, [item.startTime, item.stopTime, item.minutes, myExtra.isTimeFractional]);

  const amount = useMemo(() => rate && duration ? rate * (duration.hours + (duration.minutes / 60)) : 0, [rate, duration]);

  if(item.id === "total"){
    const totalAmount = item.amount;
    return (
      <TableRow tabIndex={-1} className={extra.totalRowStyle}>
        <TableCell component="th" id={labelId} scope="row" padding="default" colSpan={extra.isMultiple ? 4 : 3}>Total</TableCell>
        <TableCell align="right" padding="default">{formatDuration(duration, myExtra.isTimeFractional)}</TableCell>
        <TableCell padding="default"></TableCell>      
        <TableCell padding="default" align="right">{formatCurrency(totalAmount, "force", " ")}</TableCell>      
      </TableRow>
    );
  }
  else{
    return (
      <TableRow tabIndex={-1}>
        <TableCell component="th" id={labelId} scope="row" padding="default">{formatDate(item.startTime)}</TableCell>
        {extra.isMultiple && <TableCell padding="default">{item.project?.name || item.projectId}</TableCell>}
        <TableCell padding="default">{item.category}</TableCell>
        <TableCell padding="default">{item.notes}</TableCell>      
        <TableCell align="right" padding="default">{formatDuration(duration, myExtra.isTimeFractional)}</TableCell>
        <TableCell padding="default">{rate ? formatCurrency(rate, false) : ""}</TableCell>      
        <TableCell padding="default" align="right">{formatCurrency(amount, "force", " ")}</TableCell>      
      </TableRow>
    );
  }
}

export default InvoicedRow;
