/* eslint-disable react/display-name */
import React from "react";
import { Theme } from "@material-ui/core";
import { Indexable } from "types";
import Icon from '@material-ui/core/Icon';
import BusinessIcon from '@material-ui/icons/Business';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AlarmIcon from '@material-ui/icons/Alarm';
import ReceiptIcon from '@material-ui/icons/Receipt';
import DashboardIcon from '@material-ui/icons/Dashboard';
import SettingsIcon from '@material-ui/icons/Settings';
import GoalsIcon from '@material-ui/icons/TrackChanges';
import RevenueIcon from '@material-ui/icons/MonetizationOnOutlined';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/HighlightOffOutlined';
import ImportIcon from '@material-ui/icons/CloudUploadOutlined';
import ExportIcon from '@material-ui/icons/CloudDownloadOutlined';
import ToggleIcon from '@material-ui/icons/ToggleOnOutlined';
import CheckedIcon from '@material-ui/icons/Check';
import StartedIcon from '@material-ui/icons/PlayCircleOutline';
import DueIcon from '@material-ui/icons/Event';
import DeliveredIcon from '@material-ui/icons/EventAvailable';
import StarOutlineIcon from '@material-ui/icons/StarOutline';
import StarIcon from '@material-ui/icons/Star';
import WarnIcon from '@material-ui/icons/ReportProblemOutlined';
import { isString } from "lodash";

export const IconNames = {
  client: "business",
  project: "assignment",
  timer: "alarm",
  invoice: "receipt",
  dashboard: "dashboard",
  settings: "settings",
  notes: "description",
  tasks: "playlist_add_check",

  revenue: "monetization_on_outlined",
  favorites: "star_border",

  cardView: "view_agenda",
  tableView: "table_chart",
  columnView: "view_column",

  started: "play_circle_outlined",
  due: "event",
  delivered: "event_available",
}

type IconSize = "default" | "small" | "large";
type IconColor = "action" | "disabled" | "error" | "inherit" | "primary" | "secondary";

export const Icons = {
  client: (size: IconSize = "default", color: IconColor = "inherit") => { return <BusinessIcon fontSize={size}color={color}/>; },
  project: (size: IconSize = "default", color: IconColor = "inherit") => { return <AssignmentIcon fontSize={size}color={color}/>; },
  timer: (size: IconSize = "default", color: IconColor = "inherit") => { return <AlarmIcon fontSize={size}color={color}/>; },
  invoice: (size: IconSize = "default", color: IconColor = "inherit") => { return <ReceiptIcon fontSize={size}color={color}/>; },
  dashboard: (size: IconSize = "default", color: IconColor = "inherit") => { return <DashboardIcon fontSize={size}color={color}/>; },
  settings: (size: IconSize = "default", color: IconColor = "inherit") => { return <SettingsIcon fontSize={size}color={color}/>; },
  goal: (size: IconSize = "default", color: IconColor = "inherit") => { return <GoalsIcon fontSize={size}color={color}/>; },
  revenue: (size: IconSize = "default", color: IconColor = "inherit") => { return <RevenueIcon fontSize={size} color={color}/>; },

  add: (size: IconSize = "default", color: IconColor = "inherit") => { return <AddIcon fontSize={size} color={color}/>; },
  edit: (size: IconSize = "default", color: IconColor = "inherit") => { return <EditIcon fontSize={size} color={color}/>; },
  delete: (size: IconSize = "default", color: IconColor = "inherit") => { return <DeleteIcon fontSize={size} color={color}/>; },
  toggle: (size: IconSize = "default", color: IconColor = "inherit") => { return <ToggleIcon fontSize={size} color={color}/>; },
  warn: (size: IconSize = "default", color: IconColor = "inherit") => { return <WarnIcon fontSize={size} color={color}/>; },
  
  import: (size: IconSize = "default", color: IconColor = "inherit") => { return <ImportIcon fontSize={size} color={color}/>; },
  export: (size: IconSize = "default", color: IconColor = "inherit") => { return <ExportIcon fontSize={size} color={color}/>; },
  check:  (size: IconSize = "default", color: IconColor = "inherit") => { return <CheckedIcon fontSize={size} color={color}/>; },
  starOutline:  (size: IconSize = "default", color: IconColor = "inherit") => { return <StarOutlineIcon fontSize={size} color={color}/>; },
  star:  (size: IconSize = "default", color: IconColor = "inherit") => { return <StarIcon fontSize={size} color={color}/>; },
  
  started:  (size: IconSize = "default", color: IconColor = "inherit") => { return <StartedIcon fontSize={size} color={color}/>; },
  due:  (size: IconSize = "default", color: IconColor = "inherit") => { return <DueIcon fontSize={size} color={color}/>; },
  delivered:  (size: IconSize = "default", color: IconColor = "inherit") => { return <DeliveredIcon fontSize={size} color={color}/>; },

}

export const statusIcons = {
  done: "done",
}

//pink: #E900FB
//orange: #FB6C00
// teal: #26a69a
// green: #66bb6a
// very green: #00e396
// blue: #008ffb
export const featureColors: Indexable = {
  paid: "#00e396",   //green
  invoiced: "#feb019",   //gold
  hours: "#775dd0",      //purple
  assigned: "#546e7a",  //grey
  delivered: "#008ffb",   //blue
  earned:  "#775dd0",   //pink
  default: "#008ffb",   //default to the blue
  white: "#fff",
  black: "#000", 
  
  blue: "#2196f3",
  indigo: "#3f51b5",
  danger: "#ef5350",
}

export const formClasses = (theme: Theme) => ({
  formGrid: {
    paddingRight: theme.spacing(4),
  },
  formRow: {
    marginBottom: theme.spacing(2),
  },
  field: {
    minWidth: 150,
  },
  formSelectRow: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(2),
  },
  formCompactRow: {
    marginBottom: theme.spacing(1.5),
  }
});

export const dialogClasses = (theme: Theme) => ({
  dialogTitle: {
    background: theme.palette.primary.dark,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  titleText: {
    fontSize: 22,
    fontWeight: 500,
    color: theme.palette.primary.contrastText,
  },
  titleIcon: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(-0.5),
  },
  closeIcon: {
    marginLeft: theme.spacing(2),
    color: theme.palette.primary.contrastText,
    cursor: "pointer",
  },
  content: {
    marginBottom: theme.spacing(2),
  },
});

export const getIcon = (icon: any, size: IconSize = "default", color: IconColor = "inherit") => {
  if(isString(icon)) return <Icon fontSize={size} color={color}>{icon}</Icon>;
  else return icon;
}