import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { fontStyles, generalStyles } from "utils/styles";

const buildStyles   = makeStyles(theme => ({
  ...generalStyles(theme),
  ...fontStyles(theme),
  root  : {
    padding     : theme.spacing(1),
    background: theme.palette.common.white,
    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap !important",
    }
  },
  menuRow: {
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
      marginBottom: theme.spacing(1),
    }
  },
  statCell: {
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(1),
    }
  },
  emphasisDiv: {
    width: "15%",
    height: 3,
  },
  ltvColor: {
    background: theme.palette.success.main,
  },
  yearColor: {
    background: theme.palette.secondary.main,
  },
  monthColor: {
    background: theme.palette.info.main,
  },
  rateColor: {
    background: theme.palette.primary.main,
  },
  chipIcon: {
    height: 28,
    width: 28,
    border: "1px solid rgba(0,0,0,0.23)",
    "& .MuiIcon": {
      fontSize: 22,
    }
  },
}));

const ProjectStats = () => {
  const classes   = buildStyles();

  return (
    <Grid id="project-stats" container wrap="nowrap" alignItems="center" className={classes.root}>
      <Grid item xs={12}>
        
      </Grid>
    </Grid>
  );
}

export default ProjectStats;
