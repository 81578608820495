import React from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
// import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { usePageLayoutStyles, useProject } from "utils/hooks";
import ProjectSidebar from "./project-sidebar";
import ProjectContent from "./project-content";
// import { layoutStyles } from "utils/styles";
import { PageContent, PageLayout, PageSidebar } from "components/layout/page-layout";

// const buildStyles   = makeStyles(theme => ({
//   // ...layoutStyles(theme),
//   root  : {
//     padding     : theme.spacing(1),
//   },  
// }));

const ProjectViewVert = () => {
  // const classes   = buildStyles();
  // const layout = usePageLayoutStyles();
  const { projectId } = useParams<{ projectId: string }>();
  const project = useProject(projectId);
    
  return (
    <Grid id="client-view-vert" container>
      <Helmet>
        <title>Gigops - {project?.name}</title>
      </Helmet>

      <PageLayout>
        <PageSidebar>
          <ProjectSidebar/>
        </PageSidebar>
        <PageContent>
          <ProjectContent />
        </PageContent>
      </PageLayout>

      {/* <Grid id="project-left-col" className={layout.sidebarColumn}>
        <ProjectSidebar />
      </Grid>
      
      <Grid id="project-right-col" className={layout.contentColumn}>
        <ProjectContent />
      </Grid> */}
      
    </Grid>
  );
}

export default ProjectViewVert;
