import { endOfMonth, isAfter, isBefore } from "date-fns";
import { calcFee } from "features/projects";
import { compact } from "lodash";
import { DateRange, IClient, IProject, IProjectSummary } from "types";

//Interface to define an object with start and delivered dates.
interface IDeliverable{ startDate: Date | string; deliveredDate?: Date | string | null }

export const UNCATEGORIZED = "Uncategorized";
export const defaultRateMonth = (date: Date) => ({date: endOfMonth(date), minutes: 0, amount: 0});

export const getActiveProjects = <T extends IDeliverable>(range: DateRange, projects: IDeliverable[]) => {
  const matches = projects.filter(p => isBefore(p.startDate as Date, range.end) && (!p.deliveredDate || isAfter(p.deliveredDate as Date, range.start)));
  return matches as T[];
}

export const getActiveClients = (range: DateRange, clients: IClient[], projects: IProject[]) => {
  const matches = compact(clients.map(client => {
    const clientProjects = projects.filter(p => p.clientId === client.id);
    const prjMatches = getActiveProjects(range, clientProjects);
    return prjMatches.length > 0 ? client : null;
  }));
  return matches;
}

export const getProjectsWithHourlyRate = (projects: IProject[], hours: IProjectSummary[]): [string, number][] => {

  const rates = compact(projects.map(prj => {
    const minutes = hours.find(h => h.id === prj.id)?.minutes || 0;
    if(!minutes) return [prj.id, null];
    const fee = calcFee(prj);
    const rate = parseFloat((fee / (minutes / 60)).toFixed(2));
    return [prj.id, rate];
  }));

  const filtered = rates.filter(r => r[1] !== null) as [string, number][];  //filter out any projects that don't have hours
  return filtered;
}
