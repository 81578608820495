import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Padding } from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles(theme => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  denseCheckBox: {
    padding: `${theme.spacing(0)}px ${theme.spacing(1.5)}px !important`, //`
  },
  denseCell: {
    padding: `${theme.spacing(0.75)}px ${theme.spacing(1.5)}px`, //`
    paddingRight: theme.spacing(2),
  }
}));

export type Order = "asc" | "desc";

export interface HeadCell<T> {
  id: keyof T;
  label: string;
  defaultSortDir?: Order;
  padding?: Padding; //"none" | "default";
  align?: "inherit" | "left" | "center" | "right" | "justify";
  noSort?: boolean;
  isHidden?: boolean;
  width?: number;
}

export interface TableHeaderProps<T> {
  // classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof T) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  headCells: HeadCell<T>[];
  selectable?: boolean;
  dense?: boolean;
  isWorking?: boolean;
}

function TableHeader<T>(props: TableHeaderProps<T>) {
  const classes = useStyles();
  const { headCells, selectable, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, isWorking } = props;
  const dense = React.useMemo(() => Boolean(props.dense), [props.dense]);

  const createSortHandler = (property: keyof T) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {selectable &&
          <TableCell padding="checkbox" className={dense ? classes.denseCheckBox : undefined}>
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all' }}
              disabled={isWorking}
            />
          </TableCell>
        }
        {headCells.map((headCell) => {
          if (headCell.isHidden === true) return null;
          const align = headCell.align || "left";
          const pad = headCell.padding || "default";

          return (
            <TableCell
              key={headCell.id.toString()}
              align={align}
              padding={pad}
              sortDirection={orderBy === headCell.id ? order : false}
              className={dense ? classes.denseCell : undefined}
              width={headCell.width ?? undefined}
            >
              <TableSortLabel
                disabled={headCell.noSort}
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

export default TableHeader;