import _ from "lodash";
import { formatCurrency, formatHours } from "utils/number-helpers";
import { featureColors } from "utils/common-classes";
import { Indexable } from "types";

//Formatters:
export const currencyLabelFormatter = (v: any) => v ? formatCurrency(v) : "0";
export const hoursLabelFormatter = (v: any) => v ? formatHours(v): "";
export const countLabelFormatter = (v: any) => v ? v.toLocaleString() : "0";
export const rateLabelFormatter = (v: any) => v ? `${formatCurrency(v)} / hr` : "-";
export const wordRateLabelFormatter = (v: any) => v ? `${formatCurrency(v, false)} / word` : "-";

export const tipFormatters: Indexable = {
  currency: currencyLabelFormatter,
  hours: hoursLabelFormatter,
  count: countLabelFormatter,  
  rate: rateLabelFormatter,
  wordRate: wordRateLabelFormatter,
};

export type TipFormats = "currency" | "hours" | "count" | "rate" | "wordRate" | null;

//Options 
export const gradientBarFill = {
  type: "gradient",
  gradient: {
    type: "vertical",
    enabled: true,
    opacityFrom: 0.05,
    opacityTo: 0.50
  },
};

// export const gradientPieFill = {
//   type: "gradient",
//   gradient: {
//     type: "vertical",
//     enabled: true,
//     opacityFrom: 0.05,
//     opacityTo: 0.50
//   },
// };

export const gradientStackedBarFill = {
  type: "gradient",
  gradient: {
    type: "vertical",
    enabled: true,
    opacityFrom: 0.5,
    opacityTo: 0.75
  },
};

export const seriesColor = (color: string | string[]) => {
  if(!color) return {
    fill: { 
      ...gradientBarFill,
    },
  };

  const colorProp = !color ? null : {
    colors: _.isArray(color) ? color : [color],
    fill: { 
      ...gradientBarFill,
    },
    stroke: { 
      show: true, 
      width: 1, 
      colors: _.isArray(color) ? color : [color] 
    }
  };

  return colorProp;
}

export const seriesColorByKey = (colorKey: string | string[] | null) => {
  if(!colorKey) return {
    fill: { 
      ...gradientBarFill,
    },
  };

  const colorProp = !colorKey ? null : {
    colors: _.isArray(colorKey) ? colorKey.map(k => featureColors[k]) : [featureColors[colorKey] || featureColors.default],
    fill: { 
      ...gradientBarFill,
    },
    stroke: { 
      show: true, 
      width: 1, 
      colors: _.isArray(colorKey) ? colorKey.map(k => featureColors[k]) : [featureColors[colorKey] || featureColors.default],
    }
  };

  return colorProp;
}

//Helper Functions

export function getSeries(label: string, type: string, data: any, key: string) {
  return {
    name: label,
    type: type,
    data: data.map((a: any) => { return [a.date.toString(), a[key]] }),
  };
}