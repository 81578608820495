import React, { useMemo } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { fontStyles, formStyles, generalStyles } from "utils/styles";
import { TextField } from "@material-ui/core";
import { useInputs, useLocalization, useSnackbar } from "utils/hooks";
import { IClient, IClientRatings, IClientGoals } from "types";
import { ClientRatingsParsers, ClientGoalsParsers } from "../client-helpers";
import { shallowEqual, useDispatch } from "react-redux";
import { updateClient } from "../clients-slice";
import ClientRating from "./client-rating";

const buildStyles   = makeStyles(theme => ({
  ...generalStyles(theme),
  ...formStyles(theme),
  ...fontStyles(theme),
  root  : {
    padding     : theme.spacing(2),
  },
}));

interface ControlProps {
  client: IClient;
  isWorking: boolean;
}

const defaultRatings: IClientRatings = {
  joy: 5,
  communication: 5,
  network: 5,
  growth: 5,
  quality: 5,
};
const defaultGoals: IClientGoals = {
  monthlyRevenue: 0,
  annualRevenue: 0,
}

const ClientGoalsView = ({ client, isWorking }: ControlProps) => {
  const classes = buildStyles();
  const dispatch = useDispatch();
  const defaults = useMemo(() => { 
    const ratings = client && client.ratings ? {...defaultRatings, ...client.ratings} : defaultRatings;
    const goals =  client && client.ratings ? {...defaultGoals, ...client.goals} : defaultGoals;
    return {ratings, goals};
  }, [client]);

  const [rateValues, , , rateBinding] = useInputs<IClientRatings>(defaults.ratings, ClientRatingsParsers);
  const [goalValues, goalDisplay, , goalBinding] = useInputs<IClientGoals>(defaults.goals, ClientGoalsParsers);
  const { LSwap } = useLocalization();
  const notify = useSnackbar();

  //== Save the changes to the view
  const doSave = async () => {
    console.log("form values", rateValues, goalValues);
    const isRateChanged = !shallowEqual(defaults.ratings, rateValues);
    const isGoalsChanged = !shallowEqual(defaults.goals, goalValues);

    if(isRateChanged || isGoalsChanged){
      const changes: Partial<IClient> = {};
      if(isRateChanged) changes.ratings = rateValues;
      if(isGoalsChanged) changes.goals = goalValues;

      await dispatch(updateClient(client.id, changes));
      notify(LSwap("Client successfully updated", "client"));
    }
    else{
      notify("There are no changes to save", "info");
    }    
  }

  return (
    <Grid id="client-settings-view" container className={classes.root}>

      <Grid container id="client-goals-section" className={classes.mbl}>
        <Grid container className={clsx(classes.mtl, classes.mb)}>
          <Typography color="primary" className={classes.title}>{LSwap("Client Goals", "client")}</Typography>
        </Grid>
        
        <Grid container className={classes.mb}>
          <Grid item sm={3} container alignItems="center" justify="space-between">        
            <Typography className={classes.bodyText}>Monthly Revenue</Typography>
            <Tooltip title="Set a goal for how much revenue you'd like to earn from this client each month.">
              <InfoIcon fontSize="small" className={clsx(classes.marginLeft, classes.marginRight)}/>
            </Tooltip>
          </Grid>
          <Grid item md={2} sm={3} container alignItems="center">
            <TextField id="monthlyRevenue" value={goalDisplay?.monthlyRevenue} {...goalBinding.input} className={classes.textRight} fullWidth />
          </Grid>
        </Grid>
        <Grid container className={classes.mb}>
          <Grid item sm={3} container alignItems="center" justify="space-between">        
            <Typography className={classes.bodyText}>Annual Revenue</Typography>
            <Tooltip title="Set a goal for how much revenue you'd like to earn from this client each year.">
              <InfoIcon fontSize="small" className={clsx(classes.marginLeft, classes.marginRight)}/>
            </Tooltip>
          </Grid>
          <Grid item md={2} sm={3} container alignItems="center">
            <TextField id="annualRevenue" value={goalDisplay?.annualRevenue} {...goalBinding.input} className={classes.textRight} fullWidth  />
          </Grid>
        </Grid>
      </Grid>

      <Grid container id="client-ratings-section" className={classes.mbl}>
        <Grid container className={classes.mb}>
          <Typography color="primary" className={classes.title}>{LSwap("Client Rating", "client")}</Typography>
        </Grid>
        <ClientRating id="joy" label="Enjoyment" tip={LSwap("How much do you enjoy working with this client?", "client")} value={rateValues.joy} binding={rateBinding} />
        <ClientRating id="communication" label="Communication" tip={LSwap("How well does this client communicate?", "client")} value={rateValues.communication} binding={rateBinding} />
        <ClientRating id="network" label="Network Effect" tip={LSwap("How much potential for new or other work does this client offer?", "client")} value={rateValues.network} binding={rateBinding} />
        <ClientRating id="growth" label="Growth Potential" tip={LSwap("How much opportunity to grow, personally or professionally, does this client offer?", "client")} value={rateValues.growth} binding={rateBinding} />
        <ClientRating id="quality" label="Quality of Work" tip={LSwap("How much do you enjoy the work you do for this client?", "client")} value={rateValues.quality} binding={rateBinding} />
      </Grid>

      <Grid container>
        <Button onClick={doSave} variant="outlined" color="primary">Save</Button>
      </Grid>
      
    </Grid>
  );
}

export default ClientGoalsView;
