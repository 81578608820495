import React, { FunctionComponent, useMemo } from "react";
import { Link } from "react-router-dom";
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';
import { RowProps, HeadCell } from "components/atk-table";
import { IProject, IClient, ITimerGroup } from "types";
import { formatDate, DISPLAY_MONTH_FORMAT, DISPLAY_DATE_FORMAT } from "utils/date-helpers";
import { getDurationFromMinutes, formatDuration, parseGroupKey } from "../infra/timesheet-helpers";
import { getRoute } from "features/app";

export const getSortValue = (row: ITimerGroup, col: string) => {
  switch(col){
    case "date": return row.items[0].startTime;
    case "minutes": return row.minutes;
    case "clientId": return row.items[0].clientName;
    case "projectId": return row.items[0].projectName;
    case "category": return row.items[0].category;
  }
}

export interface ITimerGroupRow extends ITimerGroup {
  date?: Date;
  client?: IClient;
  project?: IProject;
  duration?: string;
  category?: string;
  notes?: string;
  clientId?: string;
  projectId?: string;
}

export const groupRowHeader = (): HeadCell<ITimerGroupRow>[] => [
  { id: "id", label: "Id", isHidden: true },
  { id: 'date', label: 'Date' },
  { id: "minutes", label: "Time", align: "right" },
  { id: "notes", label: "", noSort: true },
  { id: "clientId", label: "Client",},
  { id: "projectId", label: "Project", },
  { id: "category", label: "Category", },
];

function DurationCell({minutes}: {minutes: number}){
  const [isAlt, setAlt] = React.useState(false);

  const duration = React.useMemo(() => {
    const dur = getDurationFromMinutes(minutes);
    return formatDuration(dur, isAlt);
  }, [minutes, isAlt]);

  return (
    <Tooltip title="Click to toggle between standard / fractional display">
      <TableCell onClick={() => setAlt(!isAlt)} style={{cursor: "pointer"}} align="right">
        {duration}
      </TableCell>
    </Tooltip>
  )
}

const HoursGroupRow: FunctionComponent<RowProps<ITimerGroup>> = ({ item, labelId, extra }) => {
  const rowKey = parseGroupKey(item.id);
  const prjUrl = useMemo(() => { return getRoute("project", rowKey.projectId) }, [item]);
  const cliUrl = useMemo(() => { return getRoute("client", item.items[0].clientId) }, [item]);
  // const [hoverRef, isHovered] = useHover();

  const projectName = useMemo(() => {
    const first = item.items.find(i => i.projectName);
    return first ? first.projectName : null;
  }, [item.items]);

  const clientName = useMemo(() => {
    const first = item.items.find(i => i.clientName);
    return first ? first.clientName : null;
  }, [item.items]);

  return (
    <TableRow hover tabIndex={-1}>
      <TableCell component="th" id={labelId} scope="row" padding="default">
        {formatDate(rowKey.date, extra.onlyCurrentYear ? DISPLAY_MONTH_FORMAT : DISPLAY_DATE_FORMAT)}
      </TableCell>
      <DurationCell minutes={item.minutes}/>
      <TableCell>
        {item.notes &&
          <Tooltip title={item.notes}>
            <Icon color="disabled" fontSize="small">comment</Icon>
          </Tooltip>
        }
      </TableCell>
      <TableCell><Link to={cliUrl}>{clientName}</Link></TableCell>      
      <TableCell><Link to={prjUrl}>{projectName || rowKey.projectId}</Link></TableCell>      
      <TableCell>{rowKey.category}</TableCell>      
    </TableRow>
  );
}

export default HoursGroupRow;