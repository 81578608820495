import React, { createContext, useEffect, useMemo, useState } from "react";
import Grid from "@material-ui/core/Grid";
import InvoiceHeader from "../editor/invoice-header";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { areArraysEqual, prepareForInputs, roundTo } from "utils/general-helpers";
import { createInvoice, updateInvoice } from "../infra/invoice-actions";
import { useInvoice, useProject, useQueryParameters, useSnackbar } from "utils/hooks";
import { Invoice } from "../infra/invoice-types";
import InvoiceEditCard from "../editor/invoice-edit-card";
import InvoiceEditHours from "../editor/invoice-edit-hours";
import { newInvoice } from "../infra/invoice-helpers";
import { getRoute, routes } from "features/app";
import { isUndefined, pick } from "lodash";
import InvoiceViewCard from "./invoice-view-card";
import { RootState } from "types";

export interface IInvoiceContext{
  isNew: boolean;
  invoiceId: string;
  invoice: Invoice;
  clientId: string;
  projectId: string;
  isChanged: boolean;
  setCurrentValues: (values: Partial<Invoice>) => void;
  setIncludedHours: (items: string[], count?: number, sum?: number, projectIds?: string[]) => void;
  hoursTotal: number;
  hoursCount: number;
  isReadOnly: boolean;
}

export const InvoiceContext = createContext<IInvoiceContext>(null as any);

interface ControlProps{
  invoiceId: string;
  hideHeader?: boolean;
  onChange?: (values: Partial<Invoice>, hours: string[]) => void;
  isWorking?: boolean;
}

const PICK_LIST = ["amount", "clientId", "dueDate", "invoiceDate", "notes", "number", "paidDate", "projectId"];

const InvoiceContainer = ({invoiceId, hideHeader, onChange, isWorking}: ControlProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isReadOnly = useRouteMatch(routes.invoice);
  const rawInvoice = useInvoice(invoiceId) as Invoice;
  const invoice = useMemo(() => prepareForInputs(rawInvoice || newInvoice, ["notes"]), [rawInvoice]);
  const initProps = useQueryParameters(["clientId", "projectId"], routes.invoiceNew);   //Check for props passed in via the query string
  const [keys, setKeys] = useState({clientId: invoice.clientId || "", projectId: invoice.projectId || ""});
  const [currentValues, setCurrentValues] = useState<Partial<Invoice>>(rawInvoice);
  const [selectedHours, setSelectedHours] = useState<string[]>([]);
  const [hoursTotal, setHoursTotal] = useState(invoice.amount || 0);
  const [hoursCount, setHoursCount] = useState(0);
  const [prjList, setPrjList] = useState<string[]>(rawInvoice?.projectList || (invoice.ProjectId ? [invoice.projectId] : []));
  // const [isWorking, setWorking] = useState<boolean>(isBusy || false);
  const project = useProject(keys.projectId);
  const notify = useSnackbar();

  useEffect(() => {
    if(invoiceId === "-1" && initProps){
      const updates: any = {};
      if(initProps.projectId) updates.projectId = initProps.projectId as string;
      else if(initProps.clientId) updates.clientId = initProps.clientId as string;
      setKeys({...keys, ...updates});
    }
  }, [invoiceId, initProps]);

  const isChanged = useMemo(() => {
    if(!invoice || !currentValues) return false;
    
    const inv = pick(invoice, PICK_LIST);
    const vals = pick(currentValues, PICK_LIST);
    if(!shallowEqual(inv, vals)){
      console.log("values not equal", inv, vals);
      return true;
    }

    if(!areArraysEqual(selectedHours, invoice.hours))  {
      console.log("hours not equal", selectedHours, invoice.hours);
      return true;
    }

    return false;
  }, [currentValues, selectedHours]);
  
  useEffect(() => {
    if(isChanged && onChange){
      onChange(currentValues, selectedHours);
    }
  }, [isChanged]);

  //-- Hours are being modified in the InvoiceEditHours control
  const updateHours = (items: string[], count?: number, sum?: number, projects?: string[]) => {
    setSelectedHours(items);
    if(!isUndefined(count)) setHoursCount(count);
    if(!isUndefined(sum)) setHoursTotal(sum);
    if(!isUndefined(projects)) setPrjList(projects);
  } 

  //-- Properties are being modified in the InvoiceEditCard control
  const updateValues = (values: Partial<Invoice>) => {
    const prjChanged = Boolean(values.projectId && values.projectId !== keys.projectId);
    const cliChanged = Boolean(values.clientId && values.clientId !== keys.clientId);
    setCurrentValues(values);
    const updates: any = {};
    if(cliChanged){
      if(!project || (project && project.clientId !== values.clientId)) updates.projectId = "";
      updates.clientId = values.clientId;
    }
    else if(prjChanged){
      updates.projectId = values.projectId;
    } 

    setKeys({...keys, ...updates});    
  }

  //-- Invoice is being saved
  const onSave = (andClose?: boolean) => async () => {
    let result = null;
    // setWorking(true);
    //TODO: Validate we have enough information
    if(invoiceId && invoiceId !== "-1" && invoiceId !== "add"){
      //this is an update
      //check to see if there are any changes to the hours...
      const dollarAmount = roundTo(currentValues.amount ?? 0, 2); //don't send through the amount with a large fraction
      const model = {...currentValues, amount: dollarAmount};
      if(!areArraysEqual(rawInvoice.hours, selectedHours)){
        model.hours = selectedHours;
      }

      if(model.projectId === "multiple" && prjList.length){
        //Swap it out for the necessary projects        
        // model.projectId = prjList.join(",");
        model.projectList = prjList;
      }
      else{
        model.projectList = [model.projectId as string];
      }

      result = await dispatch(updateInvoice(rawInvoice.id, model)) as any;
      if(result && result.ok){
        notify("Invoice successfully updated", "success");
      }      
    }
    else{
      const dollarAmount = roundTo(currentValues.amount ?? 0, 2); //don't send through the amount with a large fraction      
      const model = {
        ...currentValues,
        amount: dollarAmount,
        hours: selectedHours, //.map(h => h.id),
      } as Invoice;

      if(model.projectId === "multiple" && prjList.length){
        //Swap it out for the necessary projects        
        // model.projectId = prjList.join(",");
        model.projectList = prjList;
      }
      else{
        model.projectList = [model.projectId as string];
      }

      result = await dispatch(createInvoice(model)) as any;
      if(result){
        notify("Invoice successfully created", "success");
        if(!andClose){
          const invUrl = getRoute("invoiceEdit", result.key);
          history.replace(invUrl);  //redirect to the edit invoice url
        }
      }
    }
    
    // setWorking(false);
    if(andClose) history.goBack();       
  }

  const contextValue = {
    isNew: (invoiceId === "-1"),
    invoiceId,
    invoice,
    clientId: keys.clientId,
    projectId: keys.projectId,
    isChanged,
    hoursTotal,
    hoursCount,
    setCurrentValues: updateValues,
    setIncludedHours: updateHours,  
    isReadOnly: Boolean(isReadOnly),  
  };

  return (
    <InvoiceContext.Provider value={contextValue}>
      <Grid container direction="column" wrap="nowrap">
        {!hideHeader && <InvoiceHeader onSave={onSave} isWorking={Boolean(isWorking)} /> }
        {!isReadOnly && 
          <>
            <InvoiceEditCard isWorking={Boolean(isWorking)}/>
            <InvoiceEditHours isWorking={Boolean(isWorking)} />
          </>
        }
        {isReadOnly &&
          <InvoiceViewCard />
        }
      </Grid>
    </InvoiceContext.Provider>
  );
}

export default InvoiceContainer;
