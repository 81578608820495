import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ClientEditCard from "./ui/client-edit-card";
import { useClient } from "utils/hooks";
import { LoadingIndicator } from "components";
import { Helmet } from "react-helmet-async";
import { fontStyles, generalStyles } from "utils/styles";

const buildStyles = makeStyles(theme => ({
  ...generalStyles(theme),
  ...fontStyles(theme),
  root: {
    // padding: theme.spacing(1),
  },
}));

const ClientEditView = () => {
  const classes = buildStyles();
  const history = useHistory();
  const { clientId } = useParams<{ clientId: string }>();
  const client = useClient(clientId);

  return (
    <Grid id="client-view" container justify="center" alignItems="center" className={classes.root}>
      <Helmet>
        <title>Gigops - {client?.name}</title>
      </Helmet>
      
      <Grid container justify="space-between" alignItems="center" className={classes.mbl}>
        <Grid item xs={6} container alignItems="center">
          <Icon color="primary" className={classes.marginRight}>business</Icon>
          <Typography variant="h5" color="primary" className={classes.title}>{client?.name}</Typography>
        </Grid>
        <Button onClick={() => history.goBack()} color="secondary" size="small"><ArrowBackIcon color="secondary" fontSize="small" />Go Back</Button>
      </Grid>
      
      <Grid container>
        <LoadingIndicator isVisible={!client} />
        {client && <ClientEditCard client={client} isWorking={false} />}
      </Grid>
    </Grid>
  );
}

export default ClientEditView;