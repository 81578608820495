import { ProjectMetrics } from "./metrics-project";
import { ClientMetrics } from "./metrics-client";
import { RevenueMetrics } from "./metrics-revenue";
import { TimeMetrics } from "./metrics-time";

export const Metrics = [
  ...RevenueMetrics,
  ...ClientMetrics,
  ...ProjectMetrics,  
  ...TimeMetrics,
]