import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { RootState, ITimer, IStatus, QueryFilter, IRange, IMenuItem } from "types";
import { IconMenu } from "components";
import { updateTimesheetSettings, } from "../infra/timesheet-slice";
import { loadHours, recalibrateProjectTime } from "../infra/timesheet-actions";
import { selectHours } from "../infra/timesheet-selectors";
import { getDurationFromMinutes, formatDuration, Ranges, groupHours } from "../infra/timesheet-helpers";
import { IconNames } from "utils/common-classes";
import { useLocalization, useProjects, useSnackbar, useBoolState, useDialogs } from "utils/hooks";
import { exportHoursGroupsToCsv, exportHoursToCsv } from "utils/export-helper";
import HoursFilterBar from "./hours-filter-bar";
import HoursList from "./hours-list";
import { generalStyles, cardStyles, fontStyles } from "utils/styles";
import { orderBy } from "lodash";
import { trackDisplayType, trackListFilter } from "utils/mixpanel";
import { useHistory } from "react-router-dom";
import { routes } from "features/app";

interface IPrj{
  id: string;
  name: string;
  status: "Active" | "Delivered";
}

const buildStyles = makeStyles(theme => ({
  ...generalStyles(theme),
  ...cardStyles(theme),
  ...fontStyles(theme),
  root: {
    marginTop: theme.spacing(2),
  },
  tableGrid: {
    margin: 0, 
    "& .MuiTableCell-sizeSmall": {
      padding: `6px ${theme.spacing(1)}px`, //`
    },
  },
  tablePaper: {
    border: "none !important",
  },
  textRight: {
    textAlign: "right",
  },
  projectFilter: {
    marginRight: theme.spacing(2),
    minWidth: 250,
    "& .MuiFormControl-root": {
      marginBottom: 0,
    }
  }
}));

const HoursListView = () => {
  const classes = buildStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { L } = useLocalization();
  const projects = useProjects("name");
  const hours = useSelector<RootState, ITimer[] | null>(selectHours);
  const status = useSelector<RootState, IStatus>(state => state.timesheet.status);
  const settings = useSelector<RootState, any>(state => state.timesheet.settings);
  const [range, setRange] = React.useState<IRange>(Ranges[2]);
  const [prj, setPrj] = React.useState<string | null>(null);
  const { openDialog } = useDialogs();
  const [isFiltering, toggleFilter] = useBoolState(false);
  const [isGrouping, toggleGroup] = useBoolState(false, (val: boolean) => trackDisplayType("hours", val ? "grouped" : "default") );
  const [filter, setFilter] = React.useState<QueryFilter[] | null>(null);
  const notify = useSnackbar();

  const projectsForFilter = useMemo(() => {
    const prjs = projects.map(p => ({id: p.id, name: p.name, status: p.deliveredDate ? "Delivered" : "Active"}  as IPrj));
    const ordered = orderBy(prjs, ["status"]);
    return ordered;
  }, [projects]);

  //--
  // Refreshes the data based on the selected range.
  React.useEffect(() => {
    async function doRefresh(isFiltering: boolean, range: IRange, filter: QueryFilter[] | null, prj: string | null){
      if(isFiltering){
        if(filter === null && range.id !== settings.range){
          dispatch(loadHours(range.start, range.end));
          dispatch(updateTimesheetSettings({ ...settings, range: range.id, filter: null, }));
        }
        else if(filter !== null && JSON.stringify(filter) != settings.filter){
          const result = await dispatch(loadHours(null, null, filter));
          const aResult = result as any;
          if(aResult.ok === false){
            notify("The filter failed: " + aResult.message, "error");
          }
          dispatch(updateTimesheetSettings({ ...settings, range: null, filter: JSON.stringify(filter) }));
        }
      }    
      else if (range.id !== settings.range || prj !== settings.filter) {
        const prjFilter = prj ? {field: "projectId", operator: "==", value: prj } as QueryFilter : null;        
        dispatch(loadHours(range.start, range.end, prjFilter));
        if(!!filter) setFilter(null);  //clear out the filter
        dispatch(updateTimesheetSettings({ ...settings, range: range.id, filter: prjFilter ? JSON.stringify(prjFilter) : null }));
      }
    }
    
    doRefresh(isFiltering, range, filter, prj);

  }, [isFiltering, range, prj, filter]);

  //--
  // Calculates the total hours worked during the selected time range
  const totalHours = React.useMemo(() => {
    if(!hours) return 0;
    const mins = hours.reduce((total: number, item: ITimer) => {return total + (item.minutes || 0)}, 0);
    const duration = formatDuration(getDurationFromMinutes(mins));
    return duration;
  }, [hours]);

  async function onRangeChange(e: any) {
    const key = e.target.value;
    const item = Ranges.find(r => r.id === key);
    if (item) {
      setRange(item);
      trackListFilter("hours", item.label);
    }
  }

  async function onProjectChange(e: any, item: IPrj | null){
    setPrj(item ? item.id : null);
    trackListFilter("hours", "project");
  }

  const exportHours = (items: ITimer[] | null, exportGrouping: boolean) => {
    if(!items || items.length === 0){
      notify("There are no items to export. Use the filter to choose what to export.", "warning");
    }
    else{
      let result: boolean;
      if(exportGrouping){
        const groups = groupHours("day", items);
        result = exportHoursGroupsToCsv(groups);
      }
      else result = exportHoursToCsv(items);

      if(!!result){
        notify("Export complete", "success");
      }
      else{
        notify("Export failed", "error");
      }
    }
  }

  const onFilter = (filter: QueryFilter[] | null) => {
    setFilter(filter);
    trackListFilter("hours", "custom");
  }

  const menuClicked = React.useCallback((menuItem: IMenuItem) => {
    if(!menuItem.action) return;
    switch(menuItem.action.toLowerCase()){
      case "invoice": history.push(routes.invoiceNew); break;
      case "recalibrate": dispatch(recalibrateProjectTime()); break;
      case "export": exportHours(hours, isGrouping); break;
      case "import": openDialog("import", "hours"); break;
    }
  }, [hours, isGrouping]);

  const menuItems = React.useMemo(() => {
    const items = [
      { id: 10, label: "New Invoice", icon: <Icon style={{marginLeft: 8}}>{IconNames.invoice}</Icon>, action: "invoice" },
      { id: 98, isDivider: true },
      { id: 20, label: "Export", icon: <Icon style={{ marginLeft: 8 }}>cloud_download</Icon>, action: "export" },
      { id: 30, label: "Import", icon: <Icon style={{ marginLeft: 8 }}>cloud_upload</Icon>, action: "import" },
      { id: 99, isDivider: true },
      { id: 40, label: "Recalibrate", icon: <Icon style={{ marginLeft: 8 }}>update</Icon>, action: "recalibrate" },
    ];

    return items;
  }, [hours, isGrouping]);

  return (
    <Grid id="hours-list" container justify="center" className={classes.root}>
      
      <Paper className={classes.card}>
        <Grid container>
          <Grid container className={classes.cardHeader} justify="space-between" alignItems="center">
            <Grid item md={4} sm={6} xs={12} container justify="flex-start" alignItems="center">
              <Typography className={classes.cardHeaderText}>{L("hours", true)}</Typography>   
              
              <Tooltip title="Toggle filters">
                <IconButton size="small" onClick={toggleFilter}>
                  <Icon fontSize="small">filter_list</Icon>
                </IconButton>
              </Tooltip>

              <Tooltip title="Group Items by Day">
                <IconButton size="small" onClick={toggleGroup} color={isGrouping ? "secondary" : "inherit"}>
                  <Icon fontSize="small">{isGrouping ? "group_work_outlined" : "group_work"}</Icon>
                </IconButton>
              </Tooltip>

            </Grid>
            <Grid item md={8} sm={6} xs={12} container alignItems="flex-end" justify="flex-end" spacing={2}>
                <Grid item sm={11} xs={10} container justify="flex-end" alignItems="flex-end">
                  {!isFiltering && 
                    <>
                      <Autocomplete 
                        autoComplete autoHighlight autoSelect
                        id="project-filter" 
                        options={projectsForFilter}
                        onChange={onProjectChange} 
                        getOptionLabel={o => o.name} 
                        groupBy={o => o.status}
                        className={classes.projectFilter} 
                        renderInput={(p) => <TextField {...p} placeholder="All Projects" margin="dense"/>}
                        disabled={status.isWorking}  
                      />
                      <Select id="timeframe" label="Timeframe" value={range.id} onChange={onRangeChange} disabled={status.isWorking} className={classes.marginRight}>
                        {Ranges.map(option => <MenuItem key={option.id} value={option.id}>{option.label}</MenuItem>)}
                      </Select>        
                    </>
                  }      
                  <Typography className={classes.infoText}>{totalHours} {L("hours")}</Typography>
                </Grid>
              <Grid item sm={1} xs={2} container alignItems="flex-end" justify="center"> 
                <IconMenu size="small" items={menuItems} disabled={status.isWorking} onClick={menuClicked}/>
              </Grid>
            </Grid>
          </Grid>

          <Grid container className={classes.cardBody} spacing={1}>
            {isFiltering && 
              <HoursFilterBar onFilterChange={onFilter}/>
            }
            <HoursList hours={hours} grouping={isGrouping ? "day" : undefined} isWorking={status.isWorking} tableClasses={{paper: classes.tablePaper}}/>
          </Grid> 

        </Grid>
      </Paper>

    </Grid>
  );
}

export default HoursListView;
