import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { ClientSettings, ISort } from "types";
import AtkTable from "components/atk-table";
import { useSliceSettings } from 'utils/hooks';
import ClientListRow, { headerCells } from "./client-list-row";
import ClientListHeader from "./ui/client-list-header";
import { selectFilteredClients } from "./infra/client-selectors";


const buildStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
  },
  tableGrid: {
    margin: `${theme.spacing(3)}px ${theme.spacing(0)}px`, //`
  },
}));

const ClientsList = () => {
  const classes = buildStyles();
  const items = useSelector(selectFilteredClients);
  const [settings, changeSettings] = useSliceSettings<ClientSettings>("clients");

  const onTableSorted = (order: ISort) => {
    changeSettings({ sort: order.sort, sortDir: order.sortDir });
  }

  return (
    <Grid id="clients-list-view" container className={classes.root}>
      <ClientListHeader />
      
      <Grid item xs={12} container className={classes.tableGrid}>
        <AtkTable
          rowId="id"
          settingKey="cli-list"
          header={headerCells}
          rows={items}
          RowComponent={ClientListRow}
          order={{ sort: settings.sort, sortDir: settings.sortDir }}
          onSort={onTableSorted}
          dense={true}
        />
      </Grid>
    </Grid>

  );
}

export default ClientsList;

