import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { IProject, ITimer } from "types";
import { parseTime, validateTime, DISPLAY_TIME_FORMAT } from "utils/date-helpers";
import { useInputs, useBoolState, useDialogs } from "utils/hooks";
import { filterProjects } from "features/projects";
import { timerParsers, defaultTimer } from "../infra/timesheet-config";
import { formStyles } from "utils/styles";

const buildStyles = makeStyles(theme => ({
  ...formStyles(theme),
  rootGrid: {
    // padding: theme.spacing(2),
  },
  noTopMargin: {
    marginTop: 0,
  },  
  dateGrid: {
    marginBottom: theme.spacing(0),
  },
  dateLabel: {
    color: theme.palette.grey[700],
    fontSize: 14,
    fontWeight: 300,
    marginLeft: theme.spacing(0.5),
  },
  iconButton: {
    marginRight: theme.spacing(-0.5),
  }
}));

type TimerCreateProps = {
  projects: IProject[];
  timer: Partial<ITimer> | null;
  onStart: (values: Partial<ITimer>) => void;
  noFocus?: boolean;
}

const CreateTimer = ({ projects, timer, onStart, noFocus }: TimerCreateProps) => {
  const classes = buildStyles();
  const [startTime, setStartTime] = React.useState<{ value: string; error: string | null }>({ value: "now", error: null });
  const [values, display, errors, binding, setValues] = useInputs<Partial<ITimer>>((timer || defaultTimer), timerParsers);
  const isReady = React.useMemo(() => { return !startTime.error && !!values.projectId }, [values, startTime]);
  const [isAllProjects, toggleAllProjects] = useBoolState(false);
  const availableProjects = React.useMemo(() => { return isAllProjects ? projects : filterProjects(projects, "undelivered"); }, [projects, isAllProjects]);
  const {onOpenDialog} = useDialogs();
  
  React.useEffect(() => {
    const prj = projects.find(p => p.id === values.projectId);
    if(prj?.roundType){
      setValues({...values, roundType: prj.roundType});
    }
  }, [values.projectId]);

  //If the value is saved, reset things...
  React.useEffect(() => {
    setValues(timer || defaultTimer);
  }, [timer]);

  //Debounce the validation of the timer so we're not doing it on every keystroke
  React.useEffect(() => {
    const tid = setTimeout(() => {
      const error = validateTime(startTime.value) ? null : "invalid format";
      setStartTime({ ...startTime, error: error });
    }, 400);
    return () => clearTimeout(tid); //this will stop the timer if the value changes before the timer is triggered.
  }, [startTime.value]);


  function onStartTimeChange(e: React.ChangeEvent<any>) {
    const val = e.target.value;
    setStartTime({ value: val, error: null });
  }

  function onTimeBlur() {
    //On blur, update the field with the parsed time value
    if (startTime.value !== "now" && startTime.value !== "") {
      const parsed = parseTime(startTime.value).format(DISPLAY_TIME_FORMAT);
      setStartTime({ value: parsed, error: startTime.error });
    }
  }

  function onFocus(e: any){
    e.target.select();
  }

  function startTimer() {
    if (!startTime.error) {
      const mVal = parseTime(startTime.value);
      const data = {
        ...values,
        startTime: mVal.toDate(),
      };

      onStart(data);
    }
  }


  return (
    <Grid container spacing={1} alignItems="flex-start" className={classes.rootGrid}>

      <Grid item xs={12} container className={classes.inputGridDense}  alignItems="center" alignContent="center">
        <Grid item xs={6}>
          <TextField label="Start Time" autoFocus={!noFocus} fullWidth variant="outlined" margin="dense" className={classes.textCenter} value={startTime.value} onChange={onStartTimeChange} error={!!startTime.error} helperText={startTime.error} onBlur={onTimeBlur} onFocus={onFocus}/>
        </Grid>
        <Grid item xs={6} container justify="flex-end">
          <Tooltip title="Manually add hours">
            <IconButton id="addManual" size="small" className={classes.iconButton} color="default" onClick={onOpenDialog("timer", null)} tabIndex={-1}><Icon fontSize="small" color="disabled">add_circle_outlined</Icon></IconButton>
          </Tooltip>
        </Grid>
      </Grid>

      <Grid item xs={12} container className={classes.inputGridDense} alignItems="center" alignContent="center">
        <Grid item md={11} sm={10} xs={10}>
          <Autocomplete 
            autoComplete autoHighlight autoSelect
            id="projectId" 
            options={availableProjects} 
            getOptionLabel={(opt: IProject) => `${opt.name} (${opt.client?.name})`}
            {...binding.autoComplete("projectId")}
            renderInput={(params) => <TextField {...params} label="Project" variant="outlined" fullWidth margin="dense" className={classes.noTopMargin} />} 
          />
        </Grid>
        <Grid item md={1} sm={2} xs={2} container justify="flex-end">
          <Tooltip title={isAllProjects ? "Click to show only Active projects" : "Click to show all projects"}>
            <IconButton id="toggleAll" size="small" className={classes.iconButton} color={isAllProjects ? "default" : "primary"} onClick={toggleAllProjects} tabIndex={-1}><Icon fontSize="small" color={isAllProjects ? "disabled" : "primary"}>filter_list</Icon></IconButton>
          </Tooltip>
        </Grid>
      </Grid>

      <Grid item xs={12} container className={classes.inputGridDense}>
        <TextField id="category" label="Category (optional)" fullWidth variant="outlined" margin="dense" className={classes.noTopMargin} value={display?.category} {...binding.input} {...errors["category"]} />
      </Grid>

      <Grid item xs={12} container alignItems="center">
        <Button color="primary" variant="contained" fullWidth onClick={startTimer} size="medium" disabled={!isReady}>Start</Button>
      </Grid>
    </Grid>
  )
}

export default CreateTimer;
