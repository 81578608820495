import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store/root-reducer";
import { Invoice, ProjectWithClient, ProjectWithInvoices } from "types";
import { stringsToDates, groupBy } from "utils/general-helpers";
import { calcFee, projectToInvoice } from "features/projects";
import { getMonths, getMonthStarts, formatDate, getMonthEndDates, parseDate, getMonthStartDates } from "utils/date-helpers";
import { filter, orderBy, sumBy } from "lodash";

const _getAppInitialized = (state: RootState) => state.app.isInitialized;
const _getIsOnboarding = (state: RootState) => state.app.isOnboarding;
// const _getOrg = (state: RootState) => state.app.org;
const _getClientsInitialized = (s: RootState) => s.clients.isInitialized;
const _getProjectsInitialized = (s: RootState) => s.projects.isInitialized;
const _getInvoices = (state: RootState) => state.invoices.items;  //note, this should only be used by selectAllInvoices.  Others should use selectAllInvoices instead of this
const _getClients = (state: RootState) => state.clients.items;
const _getProjects = (state: RootState) => state.projects.items;

export const selectAllClients = createSelector(
  _getClients,
  (clients) => {
    if(!clients) return [];
    return clients.map(c => stringsToDates(c));
  }
);

export const selectAllProjects = createSelector(
  _getProjects, _getClients,
  (projects, clients) => {
    if(!projects) return [];
    const withClient = projects.map(project => {
      const client = clients?.find(c => c.id === project.clientId) || null;
      return {
        ...stringsToDates(project),
        clientName: client?.name || "loading...",
        client: client,
      } as ProjectWithClient;
    });

    return withClient;
  }
);

export const selectActualInvoices = createSelector(
  _getInvoices, selectAllProjects,
  (invoices, projects) => {
    if(!invoices) return [];
    if(!projects) return invoices;

    const prepared = invoices.map(inv => {
      const project = projects ? projects.find(p => p.id === inv.projectId) : null;
      return {
        ...stringsToDates(inv),
        client: project?.client,
        project: project,
      };
    });

    return prepared;
  }
);

//-- Selects all invoices, both actual and inferred
export const selectAllInvoices = createSelector(
  selectAllProjects, selectActualInvoices,
  (allProjects, allInvoices) => {
    if(!allProjects || !allInvoices) return [];

    const invoices = allProjects.flatMap(prj => {
      
      const projectInvoices = allInvoices
        .filter(inv => inv.projectId === prj.id)
        .map(inv => { 
          return {
            ...inv,
            isProject: false,
            projectList: [inv.projectId], //in case it's not there
          } as Invoice; 
        });
      
        if(projectInvoices && projectInvoices.length > 0) return projectInvoices;
        else if(!!prj.invoicedDate){
          const daysToPay = prj.client?.daysToPay ?? 30;
          const virtualInvoice = projectToInvoice(prj, daysToPay);
          return virtualInvoice ? [virtualInvoice] : [];
        }
        else return [];
    });

    const multi = allInvoices
      .filter(inv => inv.projectId === "multiple")
      .map(inv => {
        return {
          ...inv,
          isProject: false,
        } as Invoice;
      });

    return [...invoices, ...multi];
  }
);

const calcUninvoiced = (project: ProjectWithClient, invoices: Invoice[] | null, totalInvoiced: number, totalEarned: number) => {
  if(project.deliveredDate){
    if(invoices && invoices.length > 0){
      return (totalEarned > totalInvoiced) ? totalEarned - totalInvoiced : 0
    }
    else if(!project.invoicedDate){
      return totalEarned;
    }
  }

  return 0;
}

const calcUnpaid = (project: ProjectWithClient, invoices: Invoice[] | null, totalInvoiced: number, totalPaid: number, totalEarned: number) => {
  if(invoices && invoices.length > 0){
    return (totalInvoiced > totalPaid) ? totalInvoiced - totalPaid : 0;
  }
  else if(project.invoicedDate && !project.paidDate){
    return totalEarned;
  }

  return 0;
}

//-- Select the projects and adds invoice information from the actual invoices
// so that we can get a full view of invoice and payment activity on a project
export const selectProjectsWithActualInvoices = createSelector(
  selectAllProjects, selectActualInvoices,
  (projects, invoices) => {
    if(!projects) return [];
    if(!invoices) return []; //projects;

    //TODO: this doesn't deal with invoices that are multiple projects...
    const invoicesByProject = groupBy<Invoice>(invoices, "projectId");
    const projectsWithInvoices = projects.map(prj => {
      const prjInvoices = invoicesByProject.find(ibp => ibp.key === prj.id);
      const orderedInvoices = prjInvoices ? orderBy(prjInvoices.items, ["invoiceDate"], ["desc"]) : null;  //most recent invoice first
      const hasInvoices = Boolean(orderedInvoices && orderedInvoices.length > 0);
      
      const lastPayment = orderedInvoices ? orderedInvoices.find(i => i.paidDate) : null;
      const totalInvoiced = sumBy(orderedInvoices, inv => inv.amount);
      const totalPaid = sumBy(filter(orderedInvoices, inv => !!inv.paidDate), inv => inv.amount);
      const totalEarned = calcFee(prj);
      const uninvoiced = calcUninvoiced(prj, orderedInvoices, totalInvoiced, totalEarned);
      const unpaid = calcUnpaid(prj, orderedInvoices, totalInvoiced, totalPaid, totalEarned); //(totalInvoiced > totalPaid) ? totalInvoiced - totalPaid : 0;
      
      const result: ProjectWithInvoices = {
        ...prj,
        hasInvoices,
        invoices: orderedInvoices,
        lastInvoice: orderedInvoices ? orderedInvoices[0] : null,
        lastInvoiceDate: orderedInvoices ? parseDate(orderedInvoices[0].invoiceDate) : null,
        lastPaymentDate: lastPayment?.paidDate ? parseDate(lastPayment.paidDate) : null,
        totalEarned,
        totalInvoiced,
        totalPaid,
        uninvoiced,
        unpaid
      };

      return result;
    });

    return projectsWithInvoices;

  }
);

//-- Select the projects and adds invoice information from all the invoices (including the project, as an invoice)
// so that we can get a full view of invoice and payment activity on a project
export const selectProjectsWithAllInvoices = createSelector(
  selectAllProjects, selectAllInvoices,
  (projects, invoices) => {
    if(!projects) return [];
    if(!invoices) return []; //projects;

    //TODO: this doesn't deal with invoices that are multiple projects...
    const invoicesByProject = groupBy<Invoice>(invoices, "projectId");
    const projectsWithInvoices = projects.map(prj => {
      const prjInvoices = invoicesByProject.find(ibp => ibp.key === prj.id);
      const orderedInvoices = prjInvoices ? orderBy(prjInvoices.items, ["invoiceDate"], ["desc"]) : null;  //most recent invoice first
      const lastPayment = orderedInvoices ? orderedInvoices.find(i => i.paidDate) : null;
      const totalInvoiced = sumBy(orderedInvoices, inv => inv.amount);
      const totalPaid = sumBy(filter(orderedInvoices, inv => !!inv.paidDate), inv => inv.amount);
      const totalEarned = calcFee(prj);
      // const uninvoiced = (prj.deliveredDate && totalEarned > totalInvoiced) ? totalEarned - totalInvoiced : 0;
      // const unpaid = (totalInvoiced > totalPaid) ? totalInvoiced - totalPaid : 0;
      const uninvoiced = calcUninvoiced(prj, orderedInvoices, totalInvoiced, totalEarned);
      const unpaid = calcUnpaid(prj, orderedInvoices, totalInvoiced, totalPaid, totalEarned); //(totalInvoiced > totalPaid) ? totalInvoiced - totalPaid : 0;
      const hasInvoices = Boolean(totalInvoiced > 0);

      const result: ProjectWithInvoices = {
        ...prj,
        hasInvoices,
        invoices: orderedInvoices,
        lastInvoice: orderedInvoices ? orderedInvoices[0] : null,
        lastInvoiceDate: orderedInvoices ? parseDate(orderedInvoices[0].invoiceDate) : null,
        lastPaymentDate: lastPayment?.paidDate ? parseDate(lastPayment.paidDate) : null,
        totalEarned,
        totalInvoiced,
        totalPaid,
        uninvoiced,
        unpaid,
      };

      return result;
    });

    return projectsWithInvoices;

  }
);

export const selectMonthEnds = createSelector(
  _getAppInitialized, (isInitialized) => {
    if(!isInitialized) return [];
    const monthCount = 24;
    const months = getMonths(monthCount);
    return months;
  }
);

export const selectMonthEndDates = createSelector(
  _getAppInitialized, (isInitialized) => {
    if(!isInitialized) return [];
    const monthCount = 24;
    const months = getMonthEndDates(monthCount);
    return months;
  }
);

export const selectMonthStartDates = createSelector(
  _getAppInitialized, (isInitialized) => {
    if(!isInitialized) return [];
    const monthCount = 24;
    const months = getMonthStartDates(monthCount);
    return months;
  }
);

export const selectMonthStarts = createSelector(
  _getAppInitialized, (isInitialized) => {
    if(!isInitialized) return [];
    const monthCount = 24;
    const months = getMonthStarts(monthCount);
    return months;
  }
);

export const selectMonthEndKeys = createSelector(
  selectMonthEnds, (months) => {
    if(!months) return [];
    const monthKeys = months.map(m => formatDate(m));
    return monthKeys;
  }
);

export const selectMonthStartKeys = createSelector(
  selectMonthStarts, (months) => {
    if(!months) return [];
    const monthKeys = months.map(m => formatDate(m));
    return monthKeys;
  }
);

export const selectIsOnboarded = createSelector(
  _getIsOnboarding, _getClientsInitialized, _getProjectsInitialized, selectAllClients, selectAllProjects,
  (isOnboarding, isClientInitialized, isProjectInitialized, clients, projects) => {
    const needsOnboarding = isOnboarding || ( (isClientInitialized && isProjectInitialized) && Boolean(clients?.length === 0 || projects?.length === 0) );
    return !needsOnboarding;
  }
);

// export const selectHasSample = createSelector(
//   _getOrg, selectAllClients, selectAllProjects,
//   (org, clients, projects) => {
//     return Boolean(clients.find(cli => cli.id.indexOf("SAMPLE") >= 0) || projects.find(prj => prj.id.indexOf("SAMPLE") >= 0));
//   }
// )