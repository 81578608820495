import _, { isEqual } from "lodash";
import moment from "moment";
import firebase from "firebase/app";
import { addDays, addMonths, endOfMonth, endOfQuarter, endOfWeek, endOfYear, isAfter, isBefore, startOfDay, subDays, subMonths } from "date-fns";
import { DateRange } from "types";
import { endOfDay, startOfMonth, startOfQuarter, startOfWeek, startOfYear } from "date-fns/esm";
// import { format, parse, parseISO } from "date-fns";

//NOTE: the below needs to be in this format because the apex charts don't like the YYYY-MM-DD format
export const REDUX_DATE_FORMAT = "MM/DD/YYYY"; //"YYYY-MM-DD";
export const REDUX_DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ssZ";
export const DISPLAY_DATE_FORMAT = "M/D/YY";
export const DISPLAY_TIME_FORMAT = "h:mm a";
export const DISPLAY_MONTH_FORMAT = "M/D";
export const DISPLAY_SHORT_FORMAT = "MMM D";

// const FNS_DATE_FORMAT = "MM/dd/yyyy"; //"YYYY-MM-DD";
// const FNS_DATE_TIME_FORMAT = "yyyy-MM-ddTHH:mm:ssZ";
// const FNS_DISPLAY_DATE_FORMAT = "M/d/yy";
// const FNS_DISPLAY_TIME_FORMAT = "h:mm a";
// const FNS_DISPLAY_MONTH_FORMAT = "M/d";

export const dayLabels = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
// const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
export const monthLabels = ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"];
export const monthShortLabels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
export const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];


export const DateConstants: {[key: string]: any} = {
  today: () => startOfDay(new Date()), //moment().startOf("day").toDate(),
  now: () => new Date(),
  sod: () => startOfDay(new Date()),
  eod: () => endOfDay(new Date()),
  yesterday: () => startOfDay(subDays(new Date(), 1)), // moment().subtract(1, "day").startOf("day").toDate(),
  tomorrow: () => startOfDay(addDays(new Date(), 1)), // moment().add(1, "day").startOf("day").toDate(),
  sow: () => startOfWeek(new Date()), // moment().startOf("week").toDate(),
  som: () => startOfMonth(new Date()), // moment().startOf("month").toDate(),
  soq: () => startOfQuarter(new Date()), // moment().startOf("quarter").toDate(),
  soy: () => startOfYear(new Date()), // moment().startOf("year").toDate(),
  eow: () => endOfWeek(new Date()), //moment().endOf("week").toDate(),
  eom: () => endOfMonth(new Date()), //moment().endOf("month").toDate(),
  eoq: () => endOfQuarter(new Date()), //moment().endOf("quarter").toDate(),
  eoy: () => endOfYear(new Date()), //moment().endOf("year").toDate(),
}

//===
//Gets the applicable date constant, if there is one
export function getDateConstant(value: any): (() => Date) | null {
  if(!value || !_.isString(value)) return null;
  const dc = DateConstants[value.toLowerCase()];
  return dc ? dc : null;
}

function noYear(val: string){
  return _.isString(val) ? val.split("/").length < 3 && val.split(".").length < 3 && val.split("-").length : false;
}

//--
//Parses a date string and returns a Date
export function parseDate(val: Date | string | null | undefined, fallback: Date | null = null): Date | null {
  if (!val || val === "") return fallback;
  if(_.isDate(val)) return val;
  //Check to see if it's a known constant
  const dateConstant = getDateConstant(val);
  if(!!dateConstant) return dateConstant();
  //Otherwise, just parse it
  const parsed = moment(new Date(val));
  if (parsed === null) return fallback;
  if(noYear(val)) parsed.set("year", moment().year()); //set it to the current year if it's 
  return parsed.toDate();
}

export function parseMoment(val: moment.Moment | Date | string | null, fallback: moment.Moment | null = null): moment.Moment | null {
  if (!val || val === "") return fallback;
  if(moment.isMoment(val)) return val;
  if(_.isDate(val)) return moment(val);

  //Check to see if it's a known constant
  const dateConstant = getDateConstant(val);
  if(!!dateConstant) return moment(dateConstant());

  //Otherwise, just parse it
  const parsed = moment(new Date(val));
  if (parsed === null || !parsed.isValid()) return fallback;
  if(noYear(val)) parsed.set("year", moment().year()); //set it to the current year if it's missing the year

  return parsed;
}

//===
// Gets a formatted date for display
export function formatDate(value: Date | string | null | undefined | unknown, formatString: string | undefined = undefined, fallback = "", hideCurrentYear = false): string {
  if(!value) return fallback;
  formatString = formatString || DISPLAY_DATE_FORMAT; //FNS_DISPLAY_DATE_FORMAT;
  
  if(moment.isMoment(value)){
    if(hideCurrentYear && (value as moment.Moment).isSame(moment(), "year")) formatString = DISPLAY_MONTH_FORMAT;
    return value.format(formatString);
  } 
  
  const dateConstant = getDateConstant(value);
  if(!!dateConstant) value = dateConstant();

  if(value instanceof firebase.firestore.Timestamp) value = (value as firebase.firestore.Timestamp).toDate();

  if(!_.isString(value) && !_.isDate(value)) return fallback;
  const mmt = moment(value);
  
  if(hideCurrentYear && mmt.isSame(moment(), "year")) formatString = DISPLAY_MONTH_FORMAT;
  return mmt.format(formatString);
  
  //TODO: Consider date-fns further
  //convert from string, if necessary
  // const dValue: Date = _.isString(value) ? parse(value, FNS_DATE_FORMAT, new Date()) : value;
  // return format(dValue, formatString);
}

export function formatTime(value: Date | string | null | undefined, formatString: string | undefined = undefined, fallback = ""): string {
  if(!value) return fallback;
  formatString = formatString || DISPLAY_TIME_FORMAT; //FNS_DISPLAY_TIME_FORMAT;
  const mmt = moment(value);
  return mmt.format(formatString);
}

//--
//Validates that a date is valid
export function validateDate(val: string | null | undefined, isEmptyValid = false) {
  if (!val || val === "") return isEmptyValid;
  //Check to see if it's a known constant
  const dateConstant = getDateConstant(val);
  if(!!dateConstant) return true;
  //Otherwise, see if I can turn it into a date
  const parsed = parseMoment(val);
  if (parsed === null) return isEmptyValid;
  return parsed.isValid();
}

const validFormats = [
  "h:mm a",
  "hh:mm a",
  "h a",
  "hmm a",
  "hmm",
  "h:mm",
  // "hh:mm",
];

const fullFormats = [
  "h:mm a",
  "hh:mm a",
  "hmm a",
];

//--
//Validates a time string and returns a bool with whether or not it's valid
export function validateTime(value: string): boolean {
  if (value === "[now]" || value === "now" || !value) return true;
  else if (value !== "") {
    //try to parse the time:
    const parsed = validFormats.find(fmt => { const candidate = moment(value, fmt); return candidate.isValid(); });

    if (parsed === "h:mm" || parsed === "hmm") {
      //figure out the current am/pm status
      const ampm = (moment().hour() >= 12) ? "PM" : "AM";
      const tVal = `${value} ${ampm}`;
      const parsed = fullFormats.find(fmt => { const candidate = moment(tVal, fmt); return candidate.isValid(); });
      return !!parsed;
    }
    // else if(value)

    return !!parsed;
  }

  return false;
}

//--
//Parses a time string and returns a Moment
export function parseTime(value: string, date: string | Date | null | undefined = undefined): moment.Moment {
  if(!value) return moment();
  if (value === "[now]" || value === "now"){
    const now = moment();
    if (!!date) {
      const theDate = _.isDate(date) ? date : new Date(date);
      now.set({ 'year': theDate.getFullYear(), 'month': theDate.getMonth(), 'date': theDate.getDate() });
    }
    return now;
  } 
  else if (value !== "") {

    //If it's the full, iso date, then just create the moment with it.
    if (value.indexOf("T") > 0) {
      const result = moment(value);
      return result;
    }

    //try to parse the time:
    const parsed = validFormats.find(fmt => { const candidate = moment(value, fmt); return candidate.isValid(); });

    //deal with values like 130 or 230... so they parse as H:mm, not hh:m
    if (value.indexOf(":") < 0 && value.length <= 5 && parseInt(value[0]) <= 2) {
      if (value.length === 3 && (value.endsWith("a") || value.endsWith("p"))) {
        //something like 10p or 11a
        value = `${value.slice(0, 2)}:${value.slice(2)}`;   //`
      }
      //if 4/5 digits, we only want to change if there's an a/p or am/pm involved,
      else if (value.length === 3 || value.indexOf("a") > 0 || value.indexOf("p") > 0 || value.indexOf("am") > 0 || value.indexOf("pm") > 0) {
        value = `${value.slice(0, 1)}:${value.slice(1)}`;   //`
      }

    }

    if (!!parsed) {
      if (parsed === "h:mm" || parsed === "hmm") {
        //figure out the current am/pm status
        const nowHour = moment().hour();
        const originalHour = moment(value, parsed).hour();
        const ampm = (nowHour >= 12 || originalHour === 12) ? "PM" : "AM";

        const tVal = `${value} ${ampm}`;
        const fullParsed = fullFormats.find(fmt => { const candidate = moment(tVal, fmt); return candidate.isValid(); });

        const result = moment(tVal, fullParsed);
        if (!!date) {
          const theDate = _.isDate(date) ? date : new Date(date);
          result.set({ 'year': theDate.getFullYear(), 'month': theDate.getMonth(), 'date': theDate.getDate() });
        }
        return result;
      }

      const result = moment(value, parsed);
      if (!!date) {
        const theDate = _.isDate(date) ? date : new Date(date);
        result.set({ 'year': theDate.getFullYear(), 'month': theDate.getMonth(), 'date': theDate.getDate() });
      }
      return result;
    }
  }
  return moment.invalid();
}

//===
// Gets an array of month end dates, going back from the current month, formatted as requested
export function getMonthsFormatted(numMonths: number, format: string | undefined = undefined): string[] {

  const thisMonth = moment().endOf("month");
  const startMonth = thisMonth.subtract(numMonths, "months");
  const months = [];
  for (let i = 0; i < numMonths; i++) {
    const toAdd = startMonth.add(1, "months").endOf("month").clone();
    months.push(toAdd.format(format));
  }
  return months;
}

//===
// Gets an array of month start dates, going back from the current month, formatted as requested
export function getMonthStartsFormatted(numMonths: number, format: string | undefined = undefined): string[] {
  const thisMonth = moment().startOf("month");
  const startMonth = thisMonth.subtract(numMonths, "months");
  const months = [];
  for (let i = 0; i < numMonths; i++) {
    const toAdd = startMonth.add(1, "months").startOf("month").clone();
    months.push(toAdd.format(format));
  }
  return months;
}

//===
//Gets a value as a Moment, recognizing undefined
export function getAsMoment(value: moment.Moment | Date | string | null | undefined){
  if(!value) return undefined;
  else if(value instanceof moment) return value as moment.Moment;
  else return moment(value);
}

//===
//Checks to see if a value is within a given date range
export function isDateInRange(value: string | Date | moment.Moment, min: moment.Moment | undefined, max: moment.Moment | undefined){
  if(!min && !max) return true; //no range, then we're all good
  
  //Check to see if it's a date constant
  const dateConstant = getDateConstant(value);
  if(!!dateConstant) value = dateConstant();

  const mmt = (value instanceof moment) ? value as moment.Moment : moment(value);
  if(!!min){
    if(!mmt.isSameOrAfter(min)) return false;
  }
  if(!!max){
    if(!mmt.isSameOrBefore(max)) return false;
  }
  return true;
}

//===
//Gets the months of the provided time period in an array of moments
export function getMonths(numMonths: number, thisMonth: moment.Moment | null = null): moment.Moment[] {

  thisMonth = thisMonth ?? moment().endOf("month");
  const startMonth = thisMonth.subtract(numMonths, "months");
  const months = [];
  for (let i = 0; i < numMonths; i++) {
    const toAdd = startMonth.add(1, "months").endOf("month").clone();
    months.push(toAdd);
  }
  return months;
}

//===
//Gets the months of the provided time period in an array of Dates
export function getMonthEndDates(numMonths: number, thisMonth: Date | null = null): Date[] {
  thisMonth = thisMonth ?? endOfMonth(new Date());
  const startMonth = subMonths(thisMonth, numMonths - 1); // thisMonth.subtract(numMonths, "months");
  const months = [];
  for (let i = 0; i < numMonths; i++) {
    const toAdd = endOfMonth(addMonths(startMonth, i)); // startMonth.add(1, "months").endOf("month").clone();
    months.push(toAdd);
  }
  return months;
}

//===
//Gets the months of the provided time period in an array of Dates
export function getMonthStartDates(numMonths: number, thisMonth: Date | null = null): Date[] {
  thisMonth = thisMonth ?? endOfMonth(new Date());
  const startMonth = subMonths(thisMonth, numMonths - 1); // thisMonth.subtract(numMonths, "months");
  const months = [];
  for (let i = 0; i < numMonths; i++) {
    const toAdd = startOfMonth(addMonths(startMonth, i)); // startMonth.add(1, "months").endOf("month").clone();
    months.push(toAdd);
  }
  return months;
}

//===
//Gets the months of the provided time period in an array of moments
export function getMonthStarts(numMonths: number, thisMonth: moment.Moment | null = null): moment.Moment[] {

  thisMonth = thisMonth ?? moment().startOf("month");
  const startMonth = thisMonth.subtract(numMonths, "months");
  const months = [];
  for (let i = 0; i < numMonths; i++) {
    const toAdd = startMonth.add(1, "months").startOf("month").clone();
    months.push(toAdd);
  }
  return months;
}

//===
//Gets whether or not the provided date is in the current year
const now = new Date();
export const isThisYear = (value: string | Date | undefined | null) => {
  const parsed = parseDate(value);
  return parsed && parsed.getFullYear() === now.getFullYear();
}

export const isThisMonth = (value: string | Date | undefined | null) => {
  const parsed = parseDate(value);
  return parsed && parsed.getFullYear() === now.getFullYear() && parsed.getMonth() === now.getMonth();
}

export const isInRange = (toTest: Date | string | null | undefined, range: DateRange, includeStart = true, includeEnd = true) => {
  const value = parseDate(toTest);
  if(!value) return false;
  
  const isBottom = (includeStart && isEqual(range.start, value) || isAfter(value, range.start));
  const isTop = (isBefore(value, range.end) || (includeEnd && isEqual(value, range.end)));
  return ( isBottom &&  isTop);
}