import React, { useEffect } from 'react';
import clsx from "clsx";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableHeader, { HeadCell } from "../atk-table/table-header";
import { ISort } from 'types';
import { areArraysEqual } from "../atk-table/table-helpers";
import TableCheckCell from '../atk-table/table-check-cell';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listRoot: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
      border: `1px solid ${theme.palette.grey[300]}`, //`
    },
    table: {
      // minWidth: 750,
    },
    emptyTableRow: {
      "& td": {
        textAlign: "center",
        padding: `${theme.spacing(3)}px ${theme.spacing(0)}px`, //`
        borderBottomWidth: 0,
        // fontStyle: "italic",
        fontSize: 16,
        color: theme.palette.grey[500],
      }
    },
    bodyStyle: {

    },
    denseBodyStyle: {
      "& .MuiTableCell-body, .MuiTableCell-head" : {
        padding: `${theme.spacing(0.75)}px ${theme.spacing(1.5)}px`, //`
      },
      "& th.MuiTableCell-paddingCheckbox" : {
        padding: `${theme.spacing(0)}px ${theme.spacing(1)}px !important`, //`
      },
    }
  }),
);

interface ITableProps<T> {
  columns: HeadCell<T>[];
  rows: any[];
  rowId?: string;
  RowComponent: any;
  order: ISort;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof T) => void;
  selectable?: boolean;
  dense?: boolean;
  onSelected?: (selectedItems: string[]) => void;
  extra?: any;
  onSort?: (order: ISort) => void;
  emptyMessage?: string;
  noHeader?: boolean;
  classes?: any;
  selected?: string[];
  isWorking?: boolean;
  caption?: string;
}

export interface RowProps<T> {
  rowIndex: number;
  item: T;
  cols: HeadCell<T>[];
  isSelected: boolean;
  onSelected: (event: React.MouseEvent<unknown>) => void;
  CheckboxCell: any;
  labelId: string;
  isWorking?: boolean;
  extra?: any;
}

export default function AtkListTable<T>(tableProps: ITableProps<T>) {
  const classes = useStyles();
  const { columns, rows, order, onRequestSort, classes: classOverrides, isWorking, dense } = tableProps;
  const [mySelected, setSelected] = React.useState<string[]>([]);

  //Effect to sync the selected items that are provided by the consumer
  useEffect(() => {
    if(!tableProps.selectable) return;  // table doesn't support selection
    if(areArraysEqual(tableProps.selected, mySelected)) return; //arrays are equal

    if(tableProps.selected && tableProps.selected.length > 0){
      setSelected(tableProps.selected);    
      if (tableProps.onSelected) tableProps.onSelected(tableProps.selected);
    }
    else if(mySelected && mySelected.length > 0){
      setSelected([]);
      if (tableProps.onSelected) tableProps.onSelected([]);
    }
  }, [tableProps.selected]);

  //Handles the click of the select all option with the selectable table
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(!!isWorking) return;

    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      if (tableProps.selectable && tableProps.onSelected) tableProps.onSelected(newSelecteds);
      return;
    }

    setSelected([]);
    if (tableProps.selectable && tableProps.onSelected) tableProps.onSelected([]);
  };

  //Handles the click on a row, deals with selectability
  const handleClick = (event: React.MouseEvent<unknown>, name: keyof T) => {
    if(!!isWorking) return;    
    if (!tableProps.selectable) return;

    const selectedIndex = mySelected.indexOf(name.toString());
    let newSelected: string[] = [];

    if (selectedIndex === -1) { newSelected = newSelected.concat(mySelected, name.toString()); }
    else if (selectedIndex === 0) { newSelected = newSelected.concat(mySelected.slice(1)); }
    else if (selectedIndex === mySelected.length - 1) { newSelected = newSelected.concat(mySelected.slice(0, -1)); }
    else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        mySelected.slice(0, selectedIndex),
        mySelected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
    if (tableProps.selectable && tableProps.onSelected) tableProps.onSelected(newSelected);
  };

  //Function to check if a row is selected or not
  const isSelected = (name: string) => mySelected.indexOf(name) !== -1;
  const isEmpty = rows.length === 0;
  const RowComponent = tableProps.RowComponent;
  const rowKeyProp = tableProps.rowId || "id";

  //TODO: Look into stickyHeader property

  return (
    <TableContainer>
      
      <Table className={clsx(classes.table, classOverrides?.table)} aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'} aria-label="enhanced table">
        {tableProps.caption && <caption>{tableProps.caption}</caption>}
        {!tableProps.noHeader &&
          <TableHeader
            numSelected={mySelected.length}
            order={order.sortDir}
            orderBy={order.sort.toString()}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={onRequestSort}
            rowCount={rows.length}
            headCells={columns}
            selectable={tableProps.selectable}
            dense={dense}
            isWorking={isWorking}
          />
        }
        <TableBody className={dense ? classes.denseBodyStyle : classes.bodyStyle}>
          {!isEmpty && rows.map((row, index) => {
            const rowKey = (row as any)[rowKeyProp];
            const isItemSelected = isSelected(rowKey.toString());
            const labelId = `row-checkbox-${index}`;  //`

            const checkboxCell = tableProps.selectable ? <TableCheckCell isSelected={isItemSelected} labelId={labelId} disabled={!!isWorking} /> : null;

            return <RowComponent
              key={rowKey}
              rowIndex={index}
              item={row}
              cols={columns}
              isSelected={isItemSelected}
              onSelected={(event: React.MouseEvent<unknown>) => handleClick(event, rowKey)}
              CheckboxCell={checkboxCell}
              labelId={labelId}
              className={classOverrides?.row}
              isWorking={isWorking}
              extra={tableProps.extra}
            />;

          })}
          {isEmpty &&
            <TableRow className={classes.emptyTableRow}>
              <TableCell colSpan={columns.length}>
                {tableProps.emptyMessage || "There are no items"}
              </TableCell>
            </TableRow>
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}
