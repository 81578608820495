import firebase from "firebase/app";
import "firebase/firestore";
import { IFirestoreService } from "./firebase-types";
import { appApi, appCollections } from "features/app/infra/app-api";
import { clientsApi, clientCollections } from "features/clients/infra/clients-api";
import { projectsApi, projectCollections } from "features/projects/infra/projects-api";
import { timesheetApi, timesheetCollections } from "features/timesheet/infra/timesheets-api";
import { invoicesApi, invoiceCollections } from "features/invoices/infra/invoices-api";
import { goalsApi, goalCollections } from "features/goals/infra/goals-api";
import { notesApi, noteCollections } from "features/notes/infra/notes-api";
import { tasksApi, taskCollections } from "features/tasks/infra/tasks-api";
import { integrationsApi, integrationCollections } from "features/integrations/integrations-api";

export const COLS = {
  ...appCollections,
  ...clientCollections,
  ...projectCollections,
  ...timesheetCollections,
  ...invoiceCollections,
  ...goalCollections,
  ...noteCollections,
  ...taskCollections,
  ...integrationCollections,
};

export const STATUSES = {
  ok: 200,
  empty: 202,
}

export function getFirestoreDate(value: any){
  if(value instanceof firebase.firestore.Timestamp) return value.toDate();
  else return value;
}

export function getTimestamp() {
  return ((new Date()).getTime() / 1000).toFixed(0);
}

export function getTimestampNum(): number{
  return parseInt(((new Date()).getTime() / 1000).toFixed(0));
}

export async function asyncForEach(array: any[], callback: (item: any, index?: number, array?: any[]) => void) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

export function getSnapshotDoc<T>(doc: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>): T {
  const item = {
    id: doc.id,
    ...doc.data(),
  } as unknown;

  return item as T;
}

//===
// Gets a FS document, and assigns the Id of the docRef to the document.
export async function getDoc<T>(docRef: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>, noId = false): Promise<T> {  
  const doc = await docRef.get();  
  const item = {
    ...doc.data(),
  } as any;

  if(!noId) item.id = doc.id;

  return item as T;
}

//===
// Gets a FS document, with a fallback that will be used if the doc is empty
export async function getDocF<T>(docRef: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>, fallback: T | null = null, noId = false): Promise<T | null> {  
  const doc = await docRef.get();
  if(!doc.exists) return fallback;
  const item = {
    ...doc.data(),
  } as any;
  if(!noId) item.id = doc.id;
  return item as T;
}

export function collectionRef(db: firebase.firestore.Firestore, orgId: string, collectionKey: string){
  return db.collection(COLS.ORG).doc(orgId).collection(collectionKey);
}

//===
// Converts a snapshot into a collection of the actual documents with an id field attached.
export function inflateCollection<T>(snapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>): T[]{
  const items: T[] = [];
  if (!snapshot.empty) {
    snapshot.forEach(doc => {

      const item = ({
        id: doc.id,
        ...doc.data(),
      } as unknown) as T;

      items.push(item);
    });
    return items;
  }
  else{
    return items;
  }
}

export function arrayResponse(items: any[], key: string){
  if (items.length > 0) {
    return {
      ok: true,
      statusCode: STATUSES.ok,
      key: key,
      items: items,
    };
  }
  else {
    return {
      ok: true,
      statusCode: STATUSES.empty,
      key: key,
    };
  }
}

//NOTE: Also need to update IFirestoreService in firebase-types
const FirestoreService = (db: firebase.firestore.Firestore): IFirestoreService => ({
  ...appApi(db),
  ...clientsApi(db),
  ...projectsApi(db),
  ...timesheetApi(db),
  ...invoicesApi(db),
  ...goalsApi(db),
  ...notesApi(db),
  ...tasksApi(db),
  ...integrationsApi(db),
});

export default FirestoreService;
