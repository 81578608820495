import { atkParseInt, atkParseFloat, atkParsePercent } from 'utils/number-helpers'
import { parseDate, dateToString } from "utils/general-helpers";

export const Parsers = {
  ignore: {
    name: "ignore",
    parse: (v: any) => v,
    format: (v: any) => v,
  },
  string: {
    name: "string",
    parse: (v: string) => v,
    format: (v: string) => v,
  },
  boolean: {
    name: "boolean",
    errorMessage: "Must be either true or false",
    parse: (v: boolean) => v,
    format: (v: boolean) => v,
  },
  int: {
    name: "int",
    errorMessage: "Not a valid integer value",
    parse: (v: string) => atkParseInt(v),
    format: (v: number) => v?.toLocaleString(),
  },
  intOptional: {
    name: "intOptional",
    errorMessage: "Not a valid integer value",
    parse: (v: string | null | undefined) => (!v || v.length === 0) ? undefined : atkParseInt(v),
    format: (v: number | null) => v?.toLocaleString() || "",
  },
  float2: {
    name: "float2",
    errorMessage: "Not a valid numeric value",
    parse: (v: string) => atkParseFloat(v, 2),
    format: (v: number) => parseFloat(v.toFixed(2)).toLocaleString(),
  },
  float: (length: number | undefined = 2) => ({
    name: "float",
    errorMessage: "Not a valid numeric value",
    parse: (v: string) => atkParseFloat(v),
    format: (v: number) => parseFloat(v.toFixed(length)).toLocaleString(),
  }),
  percent: {
    name: "percent",
    errorMessage: "Not a valid percent (x.xx%)",
    parse: (v: string) => {
      // const dVal : string  = v.replace(pctXp,"");
      // if(dVal.endsWith(".")) return NaN; //let them keep typing...
      // const tVal : number  = (parseFloat(dVal) / 100);
      // return parseFloat(tVal.toFixed(4));
      return atkParsePercent(v);
    },
    format: (v: number) => {
      const tVal: number = parseFloat((v * 100).toFixed(2));
      const dVal = `${tVal.toLocaleString()}%`  //`
      return dVal;
    }
  },
  currencyInt: {
    name: "currencyInt",
    errorMessage: "Not a valid amount ($x,xxx.xx)",
    parse: (v: string) => atkParseFloat(v, 2),
    format: (v: number) => {
      const tVal: number = parseFloat(v.toFixed(2));
      const dVal = `$${tVal.toLocaleString()}`  //`
      return dVal;
    }
  },
  currency: {
    name: "currency",
    errorMessage: "Not a valid amount ($x,xxx.xx)",
    parse: (v: string) => atkParseFloat(v, 2),
    format: (v: number) => {
      const tVal: number = parseFloat(v.toFixed(2)); //make sure we can parse it...
      const dVal = tVal.toLocaleString(undefined, { style: "currency", currency: "USD" });
      return dVal;
    }
  },
  date: {
    name: "date",
    errorMessage: "Not a valid date",
    parse: (v: string) => parseDate(v),
    format: (v: Date) => {
      return dateToString(v) as string;
    }
  },
  dateString: {
    name: "date",
    errorMessage: "Not a valid date",
    parse: (v: string) => dateToString(v),
    format: (v: Date) => {
      return dateToString(v) as string;
    }
  },
  dateFormat: (format: string) => ({
    name: "date",
    errorMessage: "Not a valid date",
    parse: (v: string) => parseDate(v),
    format: (v: Date) => {
      return dateToString(v, format) as string;
    }
  }),

}
