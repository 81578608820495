import { AppThunk, ITask } from "types";
import { useFirestore, STATUSES } from "utils/firebase";
import { datesToStrings, getChanges, removeUndefined, stringsToDates } from "utils/general-helpers";
import { itemsLoaded, itemCreated, itemUpdated, itemDeleted, statusChanged } from "./tasks-slice";

//-- load tasks
export const loadTasks = (): AppThunk => async (dispatch, getState) => {
  const [api, uid] = useFirestore();

  if (!uid) throw new Error("Must be logged in to load tasks.");
  const app = getState().app;

  if (app.isInitialized) {

    //Check to see if we've already loaded them... since we get all of them at once
    const taskState = getState().tasks;
    if(taskState.isInitialized) return;

    dispatch(statusChanged({ isWorking: true, error: null }));
    const result = await api.getTasks(app.org.id, null, null, null);
    if (result.statusCode === STATUSES.ok || result.statusCode === STATUSES.empty) {
      const list = result.items?.map(i => datesToStrings(i)) as ITask[];
      dispatch(itemsLoaded(list || []));
    }
    else{
      dispatch(statusChanged({ isWorking: false, error: result.error }));
    }
  }
}

//-- create a task
export const createTask = (item: Partial<ITask>): AppThunk => async (dispatch, getState) => {
  const [api, uid] = useFirestore();

  if (!uid) throw new Error("Not logged in.");
  const app = getState().app;

  if (app.isInitialized) {
    await dispatch(statusChanged({ isWorking: true, error: null }));
    const prepared = removeUndefined({ ...item, uid, createdDate: new Date() });
    const result = await api.createTask(app.org.id, prepared);
    if (result.statusCode === STATUSES.ok) {
      //Update the local store
      const toStore = {
        ...datesToStrings(prepared),
        id: result.key
      } as ITask;

      await dispatch(itemCreated(toStore));

      return toStore;
    }
    else{
      await dispatch(statusChanged({ isWorking: false, error: result.error }));
      return null;
    }
  }
}

//-- update a task
export const updateTask = (taskId: string, changes: Partial<ITask>): AppThunk => async (dispatch, getState) => {
  const [api, uid] = useFirestore();

  if (!uid) throw new Error("Not logged in.");
  const app = getState().app;

  if (app.isInitialized) {
    //get the actual changes
    const task = getState().tasks.items?.find(tsk => tsk.id === taskId);
    if (task) {
      const actualChanges = getChanges(task as any, changes as any);

      if (actualChanges !== null) {
        dispatch(statusChanged({ isWorking: true, error: null }));
        const prepared = {...stringsToDates(actualChanges), lastUpdatedDate: new Date()};
        const result = await api.updateTask(app.org.id, taskId, prepared);

        if (result.statusCode === STATUSES.ok) {
          //Update the local store
          const toStore = datesToStrings(prepared);
          dispatch(itemUpdated({ id: taskId, changes: toStore }));
          return taskId;
        }
        else{
          dispatch(statusChanged({ isWorking: false, error: result.error }));
        }
      }
    }
  }

  return null;
}

//-- delete a task
export const deleteTask = (taskId: string): AppThunk => async (dispatch, getState) => {
  const [api, uid] = useFirestore();

  if (!uid) throw new Error("Not logged in.");
  const app = getState().app;

  if (app.isInitialized) {
    //get the actual changes
    const task = getState().tasks.items?.find(nte => nte.id === taskId);
    if (task) {
      dispatch(statusChanged({ isWorking: true, error: null }));
      const result = await api.deleteTask(app.org.id, taskId);

      if (result.statusCode === STATUSES.ok) {
        //Update the local store
        dispatch(itemDeleted({id: taskId}));
        return taskId;
      }
      else{
        dispatch(statusChanged({ isWorking: false, error: result.error }));
      }
    }
  }

  return null;
}