import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CommentIcon from '@material-ui/icons/CommentOutlined';
import { IClient } from "types";
import { formatCurrency } from "utils/number-helpers";
import { HeadCell, RowProps } from "components/atk-table";
import { projectTypeLabel } from "features/projects";
import { useHover } from "utils/hooks";
import { Icons } from "utils/common-classes";
import { getRoute } from "features/app";

export const headerCells: HeadCell<IClient>[] = [
  { id: 'name', label: 'Name' },
  { id: 'contactName', label: 'Contact' },
  { id: "contactEmail", label: "Email" },
  { id: "contactPhone", label: "Phone" },
  { id: 'defaultRate', label: 'Rate' },
  { id: "notes", label: "" },
  { id: "actions", label: "", noSort: true },
];

function getRate(client: IClient) {
  if (client.defaultRate) {
    if (client.defaultProjectType && client.defaultProjectType !== "fixed") {
      return `${formatCurrency(client.defaultRate, false)} / ${projectTypeLabel(client.defaultProjectType)}`;
    }
    else return formatCurrency(client.defaultRate, false);
  }
  return "";
}

const ClientListRow: FunctionComponent<RowProps<IClient>> = ({ item, isSelected, onSelected, CheckboxCell, labelId }) => {
  const rateField = getRate(item);
  const url = getRoute("client", item.id);
  const editUrl = getRoute("clientEdit", item.id);
  const emailUrl = item.contactEmail ? `mailto:${item.contactEmail}` : "";
  const [hoverRef, isHovered] = useHover();
  
  return (
    <TableRow hover ref={hoverRef} role="checkbox" aria-checked={isSelected} tabIndex={-1} selected={isSelected} onClick={onSelected} style={{minHeight: 39, height: 39}}>
      {CheckboxCell}
      <TableCell component="th" id={labelId} scope="row" padding="default">
        <Link to={url}>{item.name}</Link>
      </TableCell>
      <TableCell>{item.contactName}</TableCell>
      <TableCell>{item.contactEmail && <a href={emailUrl} target="_blank" rel="noopener noreferrer">{item.contactEmail}</a>}</TableCell>
      <TableCell>{item.contactPhone}</TableCell>
      <TableCell align="right">{rateField}</TableCell>
      <TableCell align="center" valign="middle">
        {item.notes &&
          <Tooltip title={item.notes}>
            <CommentIcon fontSize="small" color="disabled" />
          </Tooltip>
        }
      </TableCell>
      <TableCell align="center" valign="middle">
        {isHovered && <IconButton size="small" component={Link} to={editUrl}>{Icons.edit("small")}</IconButton>}  
      </TableCell>
    </TableRow>
  );
}

export default ClientListRow;
