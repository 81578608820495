import React from "react";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { IMenuItem, IProfileInfo, IOrg } from "types";
import { useSnackbar, useInputs, useTrackChanges } from "utils/hooks";
import { IconMenu } from "components";
import { updateProfile, updateOrg } from "features/app/app-slice";
import { formStyles, cardStyles, generalStyles } from "utils/styles";
import PasswordChangeForm from "./password-change-form";
import { updateTrackedUser } from "utils/mixpanel";

const buildStyles = makeStyles(theme => ({
  ...cardStyles(theme),
  ...formStyles(theme),
  ...generalStyles(theme),
  alertsGrid: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(-2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  sectionGrid: {
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    // borderBottom: `1px solid ${theme.palette.grey[300]}`, //`
  },
  sectionHeader: {
    marginBottom: theme.spacing(2),
    color: theme.palette.secondary.main,
    fontSize: 16,
    fontWeight: 400,
  }, 
}));

interface CardProps {
  profile: IProfileInfo;
  org: IOrg;
}

const ProfileAbout = (props: CardProps) => {
  const classes = buildStyles();
  // const {L, LSwap} = useLocalization();
  const dispatch = useDispatch();
  const notify = useSnackbar();
  const menuItems: IMenuItem[] = [];
  const [orgValues, orgDisplay, orgErrors, orgBinding] = useInputs<IOrg>(props.org);
  const [values, display, errors, binding] = useInputs<IProfileInfo>(props.profile);
  const [isProfileChanged, resetProfile] = useTrackChanges(props.profile, values);
  const [isOrgChanged, resetOrg] = useTrackChanges(props.org, orgValues);
  const isChanged = React.useMemo(() => isProfileChanged || isOrgChanged, [isProfileChanged, isOrgChanged]);

  async function saveChanges() {
    let wasChanged = false;
    if (isProfileChanged) {
      await dispatch(updateProfile(values));
      resetProfile(values);
      wasChanged = true;
    }
    if (isOrgChanged) {
      await dispatch(updateOrg(orgValues));
      resetOrg(orgValues);
      wasChanged = true;
    }

    
    if(!wasChanged) {
      notify("There are no changes to save", "info");
    }
    else{
      updateTrackedUser({userName: values.displayName, orgName: orgValues.name}); //update the analytics tracking
      notify("Profile saved successfully", "success");
    }
  }

  return (
    <Grid container>
      <Paper className={clsx([classes.card, classes.lgCard])}>
        <Grid container>

          <Grid container className={classes.cardHeader} justify="space-between" alignItems="center">
            <Grid item xs={6} container justify="flex-start" alignItems="center">
              <Typography className={classes.cardHeaderText}>Profile</Typography>
            </Grid>
            <Grid item xs={6} container justify="flex-end" alignItems="center">
              <Button color="primary" variant={isChanged ? "contained" : undefined} size="small" disabled={!isChanged} onClick={saveChanges} className={classes.actionButton}>Save</Button>
              <IconMenu icon={<MoreVertIcon />} items={menuItems} />
            </Grid>
          </Grid>

          <Grid container className={classes.cardBody}>

            <Grid container className={classes.sectionGrid} spacing={2}>
              <Grid item xs={12} sm={5} className={classes.inputGrid} container>
                <TextField id="displayName" label="Your Name" variant="outlined" fullWidth margin="dense" value={display?.displayName} {...binding.input} {...errors["displayName"]} autoComplete="off" />
              </Grid>
              <Grid item xs={12} sm={7} className={classes.inputGrid} container>
                <TextField id="email" disabled={true} label="Email" variant="outlined" fullWidth margin="dense" value={display?.email} {...binding.input} {...errors["email"]} autoComplete="off" />
              </Grid>
              <Grid item xs={12} sm={5} className={classes.inputGrid} container>
                <TextField id="name" label="Company Name" variant="outlined" fullWidth margin="dense" value={orgDisplay?.name} {...orgBinding.input} {...orgErrors["name"]} autoComplete="off" />
              </Grid>
              <Grid item xs={12} sm={7} className={classes.inputGrid} container>
                <TextField id="website" label="Website" variant="outlined" fullWidth margin="dense" value={orgDisplay?.website} {...orgBinding.input} {...orgErrors["website"]} autoComplete="off" />
              </Grid>

              <Grid item xs={12} sm={6} className={classes.inputGrid} container>
                <TextField id="address" label="Address" variant="outlined" fullWidth margin="dense" value={orgDisplay?.address} {...orgBinding.input} {...orgErrors["address"]} multiline rows={4} rowsMax={6} autoComplete="off" />
              </Grid>
            </Grid>

            <PasswordChangeForm />

          </Grid>

        </Grid>

      </Paper>

    </Grid>
  );
}

export default ProfileAbout;
