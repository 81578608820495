import { trackingPath } from "features/app/app-routes";
import mixpanel from "mixpanel-browser";
// import { match } from "react-router-dom";
import { IOrg, IProfileInfo } from "types";
import { stringToDate } from "./general-helpers";

const MIXPANEL_ID_DEV = "1f5d7c197ee8eaa5f947e031c6768d95";

let _isInitialized = false;

export const EVENTS = {
  pageView: "pageview",
  login: "login",
  click: "click",
  displayType: "display-type",
  listFilter: "list-filter",
  dialogOpen: "dialog-open",
  created: "item-created",
  edited: "item-edited", 
  sample: "sample-generated",
  error: "error",
}

//Will get the tracker component, using a dummy if we're at localhost
let _tracker: any = null;
const getTracker = (): any => {
  if(window.location.hostname === "localhost"){
    return {
      init: (trackingId: string, props: any) => console.debug("Tracking Initialized", props),
      reset: () => console.debug("Tracking reset"),
      identify: (uid: string) => console.debug("Tracking user identified: ", uid),
      people: {
        set_once: (values: any) => console.debug("Tracking person values set once", values),
        set: (values: any) => console.debug("Tracking person values set", values),
      },
      track: (event: string, {...props}) => console.debug("Tracking Event: ", event, props),
    };
  }
  else{
    return mixpanel;
  }
}

export const initializeTracking = () => {
  if(!_isInitialized){
    if(!_tracker) _tracker = getTracker();
    //TODO: look at host to see what environment we're in, and handle appropriately
    _tracker.init(MIXPANEL_ID_DEV, {autotrack: true, track_pageview: true});
    _isInitialized = true;
  }
}

export const startTracking = (uid: string) => {
  _tracker.identify(uid);
}

export const identifyUser = (profile: IProfileInfo, org: IOrg | null) => {
  if(!profile.uid) return null;   //cannot track if we don't have a uid

  const values: any = {
    $email: profile.email,
    $name: profile.displayName,
    authProvider: profile.providerData ? profile.providerData[0].providerId : "unknown",
    orgId: org?.id,
    orgName: org?.name,
  };

  if(profile.createdDate){
    const created = (stringToDate(profile.createdDate || "") as Date).toISOString();
    values.$created = created;
  }

  _tracker.people.set_once(values);
}

//-- Updates the current MixPanel user, for example, after display name or og name is changed
export const updateTrackedUser = (updates: Record<string, unknown>) => {
  if(updates.userName || updates.orgName){
    const changes = {
      $name: updates.userName, //the display name field is the name of the user
      orgName: updates.orgName,    //the name field here represents the org name
    };

    _tracker.people.set(changes);
  }  
}

export const trackEvent = (event: string, action?: string, props?: Record<string, unknown>) => {
  if(_isInitialized){
    const myProps = action || props ? { action, ...props } : {action: "none"};
    _tracker.track(event, myProps);
  }
}

export const trackNav = (location: any) => {
  if(_isInitialized){
    //Don't need to track specific IDs of items...
    const props: any = {href: trackingPath(location.pathname) };
    if(location.search) props.search = location.search;
    _tracker.track(EVENTS.pageView, props);
  }
}

export const trackClick = (action: string, value: string | null = null, other?: Record<string, unknown>) => {
  if(_isInitialized){
    _tracker.track(EVENTS.click, {action, value, ...other});
  }
}

//To track how items are displayed.  For example table vs cards vs board view for Project
export const trackDisplayType = (view: string, displayType: string | null = null, other?: Record<string, unknown>) => {
  if(_isInitialized){
    _tracker.track(EVENTS.displayType, {action: view, displayType, ...other});
  }
}

//To track when / how users filter the data in list views
export const trackListFilter = (view: string, filter: string | null = null, other?: Record<string, unknown>) => {
  if(_isInitialized){
    _tracker.track(EVENTS.displayType, {action: view, filter: filter || "default", ...other});
  }
}

//Tracks when a dialog has been opened
export const trackDialog = (dialog: string, value: any, props: any) => {
  if(_isInitialized){
    _tracker.track(EVENTS.dialogOpen, {action: dialog, value, ...props});
  }
}

//Tracks when an item is created
export const trackCreate = (itemType: string) => {
  if(_isInitialized){
    _tracker.track(EVENTS.created, {action: itemType});
  }
}

//Tracks when an item is edited
export const trackEdit = (itemType: string) => {
  if(_isInitialized){
    _tracker.track(EVENTS.edited, {action: itemType});
  }
}

export const trackError = (error: string, data: any) => {
  if(_isInitialized){
    _tracker.track(EVENTS.error, {
      location: window.location.href,
      search: window.location.search,
      errorMessage: error,
      stackTrace: data,
     });
  }
}

export const stopTracking = () => {
  _tracker.reset();
}