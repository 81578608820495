import React, { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import { IClient, emptyClient } from 'types';
import { RootState } from "store/root-reducer";
import { useLocalization, useSnackbar } from 'utils/hooks';
import { AtkDialog } from 'components';
import { selectCustomizations } from "features/app";
import { uploadClients } from '../clients-slice';
import { trackCreate } from 'utils/mixpanel';
import NewClientRow from "./new-client-row";
import { removeItem, replaceItem } from 'utils/general-helpers';

interface IProps {
  isOpen: any;
  onClose: (val?: number | boolean) => void;
}

const ClientDialogMulti = (props: IProps) => {
  const dispatch = useDispatch();
  const { L,LSwap } = useLocalization();
  
  const defaults = useSelector((state: RootState) => selectCustomizations(state, ["defaultProjectType", "defaultHourlyRate"]));  
  const newItem = useMemo(() => ({
    ...emptyClient, 
    defaultRate: defaults.defaultHourlyRate, 
    defaultProjectType: defaults.defaultProjectType
  }), [defaults]);

  const [rows, setRows] = useState<Partial<IClient>[]>([{...newItem}, {...newItem}, {...newItem}]);

  const notify = useSnackbar();
  
  async function onSave() {
    const items = rows.filter(r => Boolean(r.name && r.name.trim().length > 0));
    if(items.length > 0){
      await dispatch(uploadClients(items as IClient[]));
    }
    
    notify(`${items.length} ${L("clients", true)} created successfully`);

    trackCreate("clients");
    props.onClose(true);
  }

  const dialogActions = [
    {
      label: "Cancel",
      onClick: () => { props.onClose(); },
      color: "default",
    },
    {
      label: "Save",
      onClick: onSave,
      color: "primary",
    },
  ];

  const onRowChange = (index: number) => (values: Partial<IClient>) => {
    console.log("row values changed", index, values);
    const newRows = replaceItem(rows, rows[index], values);
    setRows(newRows);
  }

  const onAddRow = () => {
    const newRows = [...rows, {...newItem}];
    setRows(newRows);
  }

  const onRemoveRow = (index: number) => () => {
    const toRemove = rows[index];
    let newRows = removeItem(rows, toRemove);
    setRows(newRows); 
    if(newRows.length === 0) {
      newRows = [{...newItem}];
      setRows(newRows); 
    }    
  }

  return (
    <AtkDialog isOpen={Boolean(props.isOpen)} maxWidth="lg" onClose={() => props.onClose()} title={LSwap("Add multiple clients", "clients")} icon="business" actions={dialogActions}>
      {rows.map((r, index) => <NewClientRow key={index} onChange={onRowChange(index)} onRemove={onRemoveRow(index)} noRemove={index === 0}/>)}  
      <Button size="small" onClick={onAddRow}>Add Row</Button>    
    </AtkDialog>
  );
}

export default ClientDialogMulti;