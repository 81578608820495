import React, { useMemo } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import FocusWithin from "react-focus-within";
import Grid from "@material-ui/core/Grid";
import { fontStyles, generalStyles } from "utils/styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "types";
import { updateTasksSettings } from "./infra/tasks-slice";
import { SearchInput } from "components";

const buildStyles   = makeStyles(theme => ({
  ...generalStyles(theme),
  ...fontStyles(theme),
  headerRow: {
    height: theme.spacing(8),
    borderBottom: `1px solid ${theme.palette.grey[300]}`, //`
    padding: theme.spacing(1),
  },
  searchContainer: {
    marginBottom: theme.spacing(-1),
    width: "100%",
  },
  noFocus: {
    background: theme.palette.grey[50],
  }
}));

const TasksListHeader = () => {
  const classes   = buildStyles();
  const dispatch = useDispatch();
  const {status, settings, isInitialized, isEmpty } = useSelector((state: RootState) => state.tasks);
  const isDisabled = useMemo(() => Boolean(!isInitialized || status.isWorking || isEmpty), [isInitialized, status.isWorking, isEmpty])

  const doSearch = (searchVal: string) => {
    dispatch(updateTasksSettings({...settings, search: searchVal}));
  }

  return (
    <FocusWithin>
    {({ focusProps, isFocused}) => (
      <Grid id="tasks-list-header" container alignItems="center" className={classes.headerRow} {...focusProps} >
          <SearchInput disabled={isDisabled} placeholder="Search" canClear onSearch={doSearch} margin="dense" classes={{container: clsx(classes.searchContainer, {[classes.noFocus]: !isFocused})}} fullWidth/>
      </Grid>
    )}
    </FocusWithin>
  );
}

export default TasksListHeader;
