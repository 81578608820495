import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Helmet } from "react-helmet-async";
import Grid from "@material-ui/core/Grid";
import { useLocalization } from "utils/hooks";
import { fontStyles, generalStyles } from "utils/styles";
import TasksContent from "./tasks-content";
import TaskSidebar from "./task-sidebar";

const buildStyles   = makeStyles(theme => ({
  ...generalStyles(theme),
  ...fontStyles(theme),
  root  : {
    height: `calc(100vh - 92px)`,
    marginLeft: theme.spacing(-3),
    marginTop: theme.spacing(-2.5),
    width: `calc(100% + ${theme.spacing(5)}px)`,
  },
  sidebarCol: {
    // padding     : theme.spacing(1),
    borderRight: `1px solid ${theme.palette.grey[300]}`, //`
  },

}));

const TasksView = () => {
  const classes   = buildStyles();
  const {LSwap} = useLocalization();
  
  return (
    <Grid id="tasks-view" container className={classes.root}>
      <Helmet>
        <title>{LSwap("Gigops - Tasks", "tasks")}</title>
      </Helmet>

      <Grid item md={3} sm={4} container className={classes.sidebarCol}>
        <TaskSidebar />
      </Grid>
      <Grid item md={9} sm={8} container>
        <TasksContent />
      </Grid>

    </Grid>
  );
}

export default TasksView;
