import React from "react";
import { Provider } from "react-redux";
import { Helmet, HelmetProvider } from "react-helmet-async";
import store from "store/app-store";
import { BrowserRouter } from "react-router-dom";
import { MuiThemeProvider } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createTheme } from "./features/app/app-theme";
import AppContainer from "./features/app/app-container";
import { initializeFirebase, firebaseContext, useFirebaseWatcher } from "utils/firebase/firebase-provider";
import DateFnsUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { initializeTracking } from "utils/mixpanel";

interface IAppProps {
  baseUrl: string | null;
}

const theme = createTheme();

const App = (props: IAppProps) => {
  initializeFirebase(); //initialize firebase once at the top level
  initializeTracking(); //initialize analytics tracking with MixPanel

  return (
    <Provider store={store}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <FirebaseApp baseUrl={props.baseUrl} />
      </MuiPickersUtilsProvider>
    </Provider>
  );
};

export default App;

const FirebaseApp = (props: IAppProps) => {
  const authContext = useFirebaseWatcher();

  return (
    <firebaseContext.Provider value={authContext}>
      <BrowserRouter basename={props.baseUrl || "."}>
        <MuiThemeProvider theme={theme}>
          <HelmetProvider>
            <Helmet>            
              <title>Gigops - Freelancer Control Center</title>
            </Helmet>
            <CssBaseline />
            <AppContainer />
          </HelmetProvider>
        </MuiThemeProvider>
      </BrowserRouter>
    </firebaseContext.Provider>
  );
}
