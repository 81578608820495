import React from "react";
import clsx from 'clsx';
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from '@material-ui/core/Paper';
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import { useDialogs } from "utils/hooks";
import { cardStyles } from "utils/styles";
import InvoiceList from "./invoice-list";

const buildStyles = makeStyles(theme => ({
  ...cardStyles(theme),
  root: {
    marginTop: 0,
  },
  tableGrid: {
    margin: 0,
  },
  deleteRow: {
    background: `${theme.palette.error.main}33`, //`
  }
}));

type Props = {
  queryKey: string;
  clientId?: string;
  projectId?: string;
  isWorking?: boolean;  
  canDelete?: boolean;
  classes?: Record<string, string>;
}

const InvoicesListCard = (props: Props) => {
  const classes = buildStyles();
  const otherClasses = props.classes || {};
  const { clientId, projectId } = props;
  const {onOpenDialog} = useDialogs();
  
  return (
    <Grid id="invoices-list-card" container justify="center" className={classes.root}>
      <Paper className={clsx([classes.card, classes.root, otherClasses.paper])} variant="outlined">
        <Grid container>

          <Grid container className={clsx(classes.cardHeader, otherClasses.toolbar)} justify="space-between" alignItems="center">
            <Grid item xs={8} container justify="flex-start" alignItems="center">
              <Typography className={clsx(classes.cardHeaderText, otherClasses.title)}>Invoices</Typography>
            </Grid>
            <Grid item xs={4} container justify="flex-end" alignItems="center">
              <Button size="small" color="secondary" onClick={onOpenDialog("invoice", null, {clientId, projectId})}>New</Button>
            </Grid>
          </Grid>

          <Grid container className={clsx(classes.cardBody, otherClasses.body)} spacing={1}>
            <InvoiceList withVirtual={true} projectId={projectId} clientId={clientId} rowsPerPageOptions={[10]}/>
          </Grid>

        </Grid>
      </Paper>      
    </Grid>
  );
}

export default InvoicesListCard;
