import _, { compact, pick, sumBy, values } from "lodash";
import moment from "moment";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store/root-reducer";
import { Indexable, IProject, ProjectType, ProjectWithDates, ProjectFilter, Invoice, ProjectGroups, ProjectDateGroup, heroMetrics, heroAreas, ProjectTracking, ProjectWithInvoices, IAlert } from "types";
import { groupBy, getMonth } from "utils/general-helpers";
import { formatCurrency, calcChange } from "utils/number-helpers";
import { formatDate, getMonths, REDUX_DATE_FORMAT } from "utils/date-helpers";
import { filterProjects, filterProjectsByDate, sumProjects, calcProjectDates, calcFee, projectToInvoice, sumProjectsForMonth } from "./project-helper";
import { selectAllClients, selectAllProjects, selectAllInvoices, selectMonthStarts, selectProjectsWithActualInvoices, selectProjectsWithAllInvoices, selectMonthStartDates } from "features/app/infra/common-selectors";
import { addDays, endOfDay, isAfter, isBefore, isSameYear, subYears } from "date-fns";
import { differenceInDays, isSameMonth, subMonths } from "date-fns/esm";
import { filterInvoices } from "features/invoices/infra/invoice-helpers";
import { routes } from "features/app";

//-- re-export this so it's accessed through the project feature
export { selectAllProjects } from "features/app/infra/common-selectors";

const _getState = (state: RootState) => state;
const _getProjects = (state: RootState) => state.projects.items;
const _getFilter = (state: RootState) => state.projects.settings.filter;
const _getSearch = (state: RootState) => state.projects.settings.search;
const _getInput = (state: RootState, input: any) => input;
const _getCustomizations = (state: RootState) => state.app.org?.customizations;
const _getProjectHours = (state: RootState) => state.projects.trackedHours;

//NOTE: SelectAllProject is moved to app/infra/common-selectors so that it can be shared among all the selectors without conflict

//---
// Calculates whether a value is up or down over a previous value, but can recognize the date to determine
// how the value relates to the previous value at this point in the month
function calcUpOrDown(currValue: number, prevValue: number, date: moment.Moment | Date | null): boolean | null {
  //If no date, just compare the values
  if(!date) return (currValue === prevValue ? null : Boolean(currValue > prevValue));
  //Otherwise, compare it based on the position of the month.
  const mmt = moment.isMoment(date) ? date : moment(date);
  const pct = mmt.date() / mmt.daysInMonth();
  const prevCompare = parseInt((prevValue * pct).toFixed(0));
  const currCompare = parseInt(currValue.toString());
  return currCompare === prevCompare ? null : Boolean(currCompare > prevCompare);
}

//==
// Selects each project with a string representation, used for searching
const selectProjectStrings = createSelector(
  selectAllProjects,
  (projects) => {
    if(!projects) return [];
    const searchStrings = projects.map(prj => {
      const prjString = values(pick(prj, ["name", "category", "notes", "clientName"])).join(" ");
      return [prj.id, prjString.toLowerCase()];      
    });

    return searchStrings as [string, string][];
  }
);

//==
// Selects the projects, recognizing the current filter / search in the project slice settings
export const selectFilteredProjects = createSelector(
  selectProjectsWithActualInvoices, selectProjectStrings, _getFilter, _getSearch,
  (projects, projectStrings, filter, search) => {
    if (!projects) return [];
    if(search){
      const searchTerm = search.toLowerCase();
      const matches = projectStrings.filter(cs => cs[1].indexOf(searchTerm) >= 0);
      const searched = compact(matches.map(m => projects.find(p => p.id === m[0]))); // clients.filter(p => p.name.toLowerCase().indexOf(searchTerm) >= 0 || p.clientName?.toLowerCase().indexOf(searchTerm) >= 0 || (p.notes ?? "").toLowerCase().indexOf(searchTerm) >= 0);
      return searched.filter(s => !s.isArchived);
    }

    const filtered = filterProjects(projects, filter as ProjectFilter) as ProjectWithInvoices[];
    return filtered;
  }
);

const doSelectInvoiceTotals = (invoices: Invoice[]) => {
  const now = new Date();

  const monthPaid = invoices.filter(inv => inv.paidDate && isSameMonth(inv.paidDate as Date, now) )
  const lastMonthPaid = invoices.filter(inv => inv.paidDate && isSameMonth(inv.paidDate as Date, subMonths(now, 1)));
  const yearPaid = invoices.filter(inv => inv.paidDate && isSameYear(inv.paidDate as Date, now));
  const lastYearPaid = invoices.filter(inv => inv.paidDate && isSameYear(inv.paidDate as Date, subYears(now, 1)));

  const month = _.sumBy(monthPaid, p => p.amount);
  const lastMonth = _.sumBy(lastMonthPaid, p => p.amount);
  const year = _.sumBy(yearPaid, p => p.amount); //sumProjects(paid) as number;
  const lastYear = _.sumBy(lastYearPaid, p => p.amount); //sumProjects(paidLast) as number;

  const due = invoices.filter(inv => !inv.paidDate && isSameYear((inv.dueDate ? inv.dueDate : inv.invoiceDate) as Date, now));
  const dueTotal = _.sumBy(due, d => d.amount);

  return {
    month,
    lastMonth,
    monthChange: calcChange(lastMonth, month),
    year,
    lastYear,
    yearChange: calcChange(lastYear, year),
    paidCount: yearPaid.length,
    due: dueTotal,
    dueCount: due.length,
  }
}

export const selectAnnualTotals = createSelector(
  _getProjects, selectAllInvoices,
  (projects, invoices) => {
    if (!projects) return null;
    const thisYear = filterProjectsByDate(projects, p => p.dueDate, "year");
    const invTotals = doSelectInvoiceTotals(invoices);
    const projected = (invTotals.year + invTotals.due) / (moment().dayOfYear() / 365);
    
    return {
      count: thisYear.length,
      dueAmount: formatCurrency(invTotals.due),
      paidAmount: formatCurrency(invTotals.year),
      paidCount: invTotals.paidCount,
      paidChange: invTotals.yearChange,
      projected: formatCurrency(projected),
    };
  }
);

export const selectProjectsByClient = createSelector(
  selectAllClients, selectProjectsWithAllInvoices,
  (clients, projects) => {
    if (!!clients && !!projects) {

      const groups = clients.map(cli => {
        return {
          clientId: cli.id,
          client: cli,
          projects: projects.filter(prj => prj.clientId === cli.id),
        }
      });

      return groups;
    }
    else {
      return [];
    }
  }
);

export const selectClientProjects = createSelector(
  selectProjectsByClient, _getInput,
  (projects, clientId) => {
    if (!projects || !clientId) return [];
    const item = projects.find(group => group.clientId === clientId);
    return item ? item.projects : [];
  }
);

export const selectClientRevenue = createSelector(
  _getInput, selectProjectsByClient, selectAllInvoices,
  (clientId, projects, invoices) => {
    if (!projects || !clientId || !invoices) return null;
    
    const clientInvoices = invoices.filter(inv => inv.clientId === clientId);
    const item = projects.find(group => group.clientId === clientId);
    const invTotals = doSelectInvoiceTotals(clientInvoices);
    
    return {
      month: formatCurrency(invTotals.month, true, "0"),
      monthChange: invTotals.monthChange, //month === 0 ? 0 : monthChange,
      year: formatCurrency(invTotals.year, true, "0"), // formatCurrency(year, true, "0"),
      yearChange: invTotals.yearChange, //year === 0 ? 0 : yearChange,
      lastYear: formatCurrency(invTotals.lastYear),
      allTime: sumProjects(item ? item.projects : [], true),
    }
  }
);

export const selectTopClientsByInvoiced = createSelector(
  selectAllClients, selectAllInvoices,
  (clients, invoices) => {
    if(!clients || !invoices) return [];
    const currYear = moment().year();
    const sixtyAgo = moment().subtract(60, "days").startOf("day");
    const thirtyAgo = moment().subtract(30, "days").startOf("day");

    const clientResults = clients.map(client => {
      const clientInvoices = invoices.filter(prj => prj.clientId === client.id);
      const allTime = _.sumBy(clientInvoices, inv => inv.amount);
      const thisYear = _.sumBy((clientInvoices.filter(inv => moment(inv.invoiceDate).year() === currYear)), inv => inv.amount);
      const lastYear = _.sumBy((clientInvoices.filter(inv => moment(inv.invoiceDate).year() === (currYear - 1))), inv => inv.amount);
      const last30 = _.sumBy((clientInvoices.filter(inv => moment(inv.invoiceDate).isSameOrAfter(thirtyAgo))), inv => inv.amount);
      const last60 = _.sumBy((clientInvoices.filter(inv => moment(inv.invoiceDate).isBetween(sixtyAgo, thirtyAgo))), inv => inv.amount);
      
      return {
        client: client,
        clientId: client.id,
        clientName: client.name,
        allTime,
        year: thisYear,
        yearChange: calcChange(lastYear, thisYear),
        month: last30,
        monthChange: calcChange(last60, last30),
      };
    });

    return clientResults;
  }
);

//TODO: can This Month and Last Month be derived from selectProjectGroups?
export const selectThisMonthTotals = createSelector(
  _getProjects,
  (projects) => {
    if (!!projects) {
      const deliveredProjects = filterProjectsByDate(projects, p => p.deliveredDate, "month");
      const paidProjects = filterProjectsByDate(projects, p => p.paidDate, "month");
      const assigned = filterProjectsByDate(projects, p => p.startDate, "month");
      const due = filterProjectsByDate(projects, p => p.dueDate, "month");

      const earned = filterProjects(deliveredProjects, "earned");
      const inProgress = filterProjects(projects, "inprogress");
      const invoiced = filterProjects(projects, "invoiced");
      const paid = filterProjects(paidProjects, "paid");

      const vbooked = sumProjects(assigned, false) as number;
      const vdue = sumProjects(due, false) as number;
      const vearned = sumProjects(earned, false) as number;
      const vinProgress = sumProjects(inProgress, false) as number;
      const vinvoiced = sumProjects(invoiced, false) as number;
      const vpaid = sumProjects(paid, false) as number;

      return {
        booked: vbooked,
        bookedCount: assigned.length,
        due: vdue,
        dueCount: due.length,
        earned: vearned,
        earnedCount: earned.length,
        inProgress: vinProgress,
        inProgressCount: inProgress.length,
        invoiced: vinvoiced,
        invoicedCount: invoiced.length,
        paid: vpaid,
        paidCount: paid.length,
      };
    }
    else {
      return {};
    }
  }
);

export const selectProjectsWithDates = createSelector(
  _getProjects,
  (projects) => {
    if (!projects) return [];
    const items: ProjectWithDates[] = projects.map(prj => {
      return {
        ...prj,
        dates: calcProjectDates(prj),
      };
    });

    return items;
  }
);

export const selectProjectGroups = createSelector(
  selectProjectsWithDates,
  (projects) => {
    const byStart: ProjectDateGroup[] = groupBy<ProjectWithDates>(projects, (d: ProjectWithDates) => getMonth(d.dates.start));
    const byDue: ProjectDateGroup[] = groupBy<ProjectWithDates>(projects, (d: ProjectWithDates) => getMonth(d.dates.due));
    const byDelivered: ProjectDateGroup[] = groupBy<ProjectWithDates>(projects, (d: ProjectWithDates) => getMonth(d.dates.delivered));
    const byInvoiced: ProjectDateGroup[] = groupBy<ProjectWithDates>(projects, (d: ProjectWithDates) => getMonth(d.dates.invoiced));
    const byPaid: ProjectDateGroup[] = groupBy<ProjectWithDates>(projects, (d: ProjectWithDates) => getMonth(d.dates.paid));

    const result: ProjectGroups = {
      all: projects,
      byStart: byStart,
      byDue: byDue,
      byDelivered: byDelivered,
      byInvoiced: byInvoiced,
      byPaid: byPaid,
    };

    return result;
  }
);

function calcAmount(project: IProject, totalMinutes: number){
  if(project.type === ProjectType.perHour){
    return (totalMinutes/60) * (project.fee || 0)
  }
  else if(project.trackedMinutes && project.estimatedHours){
    const percentComplete = project.trackedMinutes / (project.estimatedHours * 60);
    const rate = ((project.fee  || 0) * percentComplete) / (project.trackedMinutes / 60);
    const amount = rate * (totalMinutes / 60);
    return amount;
  }
  else if(project.trackedMinutes){
    const rate = (project.fee || 0) / (project.trackedMinutes / 60);
    const amount = rate * (totalMinutes / 60);
    return amount;
  }
  else if(project.estimatedHours){
    const percentComplete = totalMinutes / (project.estimatedHours * 60);
    const amount = (project.fee || 0) * percentComplete;
    return amount;
  }
  else{
    return project.fee || 0;
  }
}

function doSelectTrackedHours(projects: IProject[] | null, tracking: ProjectTracking[] | null, onlyBillable = false){
  if(!projects || !tracking) return {};

  const trackedProjects = projects.filter(prj => !!prj.trackedMinutes && (!onlyBillable || prj.type === ProjectType.perHour));
  const trackedPids = trackedProjects.map(p => p.id);
  const projectTracking = tracking.filter(t => trackedPids.indexOf(t.projectId) >= 0);
  const monthCount = 24;
  const months = getMonths(monthCount);

  const items = months.map(month => {
    const eom = month.format(REDUX_DATE_FORMAT);
    let monthlyMinutes = 0;
    const sum = _.sumBy(projectTracking, (trk: ProjectTracking) => {
      const prj = trackedProjects.find(p => p.id === trk.projectId) as IProject;
      const totalMinutes = _.reduce(trk.days, (accum, value, key) => {
        if(moment(key).endOf("month").format(REDUX_DATE_FORMAT) === eom){
          accum += value;
        }
        return accum;
      }, 0);
      const projectAmount = calcAmount(prj, totalMinutes);
      monthlyMinutes += totalMinutes;
      return projectAmount;
    });

    return {
      label: eom, //month.format(REDUX_DATE_FORMAT),
      date: month.clone().startOf("month"),
      earned: sum,  
      hours: (monthlyMinutes / 60),      
    };
  });

  const thisMonth = items[monthCount - 1].earned;
  const lastMonth = items[monthCount - 2].earned;
  const thisMonthHours = items[monthCount - 1].hours;
  const lastMonthHours = items[monthCount - 2].hours;
  const today = moment();

  return {
    id: "earned",
    months: items,
    thisMonth: thisMonth,
    thisMonthHours: thisMonthHours,
    thisMonthIsUp: calcUpOrDown(thisMonth, lastMonth, today),
    thisMonthHoursIsUp: calcUpOrDown(thisMonthHours, lastMonthHours, today),
  };
}

function hoursByWeek(project: IProject | null, tracking: ProjectTracking[] | null){
  if(!project || !tracking) return [];

  const projectTracking = tracking.find(t => t.projectId === project.id);
  if(!projectTracking) return [];

  const start = moment(project.startDate).endOf("week");
  const end = project.deliveredDate ? moment(project.deliveredDate).endOf("week") : moment().endOf("week");
  const weeks = [];
  
  while(start <= end){
    const weeklyMinutes = _.reduce(projectTracking.days, (accum, value, key) => {
      if(moment(key).isSame(start, "week")){
        accum += value;
      }
      return accum;      
    }, 0);
    const thisWeek = {
      label: start.format(REDUX_DATE_FORMAT),
      date: start.clone(),
      earned: calcAmount(project, weeklyMinutes),  
      hours: (weeklyMinutes / 60),      
    };
    weeks.push(thisWeek);
    start.add(1, "week").endOf("week");
  }

  return weeks;  
}

export const selectInvoiceTotalsByMonth = createSelector(
  selectAllInvoices, selectMonthStarts,
  (invoices, monthStarts) => {
    
    //add string dates to the invoices so we can compare more easily
    const invoicesWithKeys = invoices.map(inv => { return { ...inv, invoiceDateKey: formatDate(moment(inv.invoiceDate).startOf("month")), paidDateKey: formatDate(moment(inv.paidDate).startOf("month"))}});

    const byMonth = monthStarts.map(month => {
      const som = formatDate(month);
      const invoiced = invoicesWithKeys.filter(inv => inv.invoiceDateKey === som);
      const paid = invoicesWithKeys.filter(inv => inv.paidDateKey === som);
      const invoicedTotal = _.sumBy(invoiced, inv => inv.amount);
      const paidTotal = _.sumBy(paid, p => p.amount);

      return {
        label: som,
        date: month,
        invoiced: invoicedTotal,
        paid: paidTotal,
      };
    });

    return {
      months: byMonth,
      thisMonth: byMonth[monthStarts.length - 1],
      lastMonth: byMonth[monthStarts.length - 2],
    };
  }
);

export const selectProjectTotalsByMonth = createSelector(
  _getInput, selectProjectsWithAllInvoices, _getProjectHours, selectMonthStarts,
  (projectId, projects, projectHours) => {
    if(!projects) return [];
    const project = projects.find(prj => prj.id === projectId);
    if(!project) return [];

    let invoices = project.invoices || [];
    if(project.invoiceDate){
      const virtualInvoice = projectToInvoice(project);
      invoices = virtualInvoice ? [virtualInvoice] : [];
    } 

    if(invoices.length === 0 && !project.trackedMinutes || project.trackedMinutes === 0){
      return [];
    }

    const invoicesWithKeys = invoices.map(inv => { return { ...inv, invoiceDateKey: formatDate(moment(inv.invoiceDate).endOf("week")), paidDateKey: formatDate(moment(inv.paidDate).endOf("week"))}});
    const weeklyHours = hoursByWeek(project, projectHours)
    const start = moment(project.startDate).endOf("week");
    const maxDate = project.lastPaymentDate ?? project.lastInvoiceDate ?? project.paidDate ?? project.invoicedDate ?? project.deliveredDate ?? moment().endOf("week");
    const end = moment(maxDate).endOf("week"); //project.deliveredDate ? moment(project.deliveredDate).endOf("week") : moment().endOf("week");
    const byWeek = [];
    let invTotal = 0;
    let paidTotal = 0;

    while(start <= end){
      const eow = formatDate(start);
      const invoiced = invoicesWithKeys.filter(inv => inv.invoiceDateKey === eow);
      const paid = invoicesWithKeys.filter(inv => inv.paidDateKey === eow);
      invTotal += _.sumBy(invoiced, inv => inv.amount);
      paidTotal += _.sumBy(paid, p => p.amount);

      const hourly = weeklyHours ? weeklyHours.find(wk => wk.date.isSame(eow, "week")) : null;

      const week = {
        label: eow,
        date: start.clone(),
        invoiced: invTotal,
        paid: paidTotal,
        earned: hourly ? hourly.earned : 0, //hoursTotal,
        worked: hourly ? hourly.hours : 0,  //earnedTotal,
      };
      byWeek.push(week);
      start.add(1, "weeks").endOf("week");
    }
    
    return byWeek;

  }
);

function calcDateGroupAmount(group: ProjectDateGroup | undefined, useEstimatedHours = false) {
  const items = group?.items;
  if(!items) return 0;
  const total = items.reduce((amount: number, prj: ProjectWithDates) => {
    const projectFee = calcFee(prj, useEstimatedHours);
    return amount + projectFee;
  }, 0);

  return total;
  // return group?.items?.reduce((amt: number, s: ProjectWithDates) => amt + calcFee(s), 0) || 0
}

const groupKeys: Indexable = {
  delivered: "byDelivered",
  assigned: "byStart",
  invoiced: "byInvoiced",
  paid: "byPaid",
  due: "byDue",
  hours: "byInvoiced",  //TODO: support hours
}

const selectPaid = createSelector(
  selectAllInvoices, selectMonthStartDates,
  (invoices, monthStarts) => {
    const monthPaid = invoices.filter(inv => inv.paidDate && isSameMonth(inv.paidDate as Date, new Date()));

    let annualRevenue = 0;
    const months = monthStarts.slice(11); //take the last 12 months
    const byMonth = months.map(month => {
      const invs = invoices.filter(inv => inv.paidDate && isSameMonth(inv.paidDate as Date, month));
      const sum = sumBy(invs, inv => inv.amount); // sumProjectsForMonth(invs, month) as number;
      annualRevenue += sum;
      return [month, sum];
    });

    return {
      thisMonth: sumBy(monthPaid, inv => inv.amount), //sumProjectsForMonth(monthPaid, new Date()),
      thisYear: annualRevenue,
      chartData: [{name: "Paid", chartType: "area", data: byMonth}],
      chartProps: { chartType: "area" },
    };
  }
);

const selectAssigned = createSelector(
  selectProjectsWithAllInvoices, selectMonthStartDates,
  (projects, monthStarts) => {
    const today = new Date();
    const monthAssigned = projects.filter(p => isSameMonth(p.startDate as Date, today));
    
    let annualAssigned = 0;
    const months = monthStarts.slice(11); //take the last 12 months
    const byMonth = months.map(month => {
      const prjs = projects.filter(p => isSameMonth(p.startDate as Date, month));
      const sum = sumProjects(prjs, false, true) as number;
      annualAssigned += sum;
      return [month, sum];
    });

    return {
      thisMonth: sumProjects(monthAssigned, false, true),
      thisYear: annualAssigned,
      chartData: [{name: "Assigned", chartType: "area", data: byMonth}],
      chartProps: { chartType: "area" },
    };
  }
);

const selectDelivered = createSelector(
  selectProjectsWithAllInvoices, selectMonthStartDates,
  (projects, monthStarts) => {
    const today = new Date();
    const monthDelivered = projects.filter(p => p.deliveredDate && isSameMonth(p.deliveredDate as Date, today));
    
    let annualDelivered = 0;
    const months = monthStarts.slice(11); //take the last 12 months
    const byMonth = months.map(month => {
      const prjs = projects.filter(p => p.deliveredDate && isSameMonth(p.deliveredDate as Date, month));
      const sum = sumProjects(prjs) as number;
      annualDelivered += sum;
      return [month, sum];
    });

    return {
      thisMonth: sumProjects(monthDelivered),
      thisYear: annualDelivered,
      chartData: [{name: "Delivered", chartType: "area", data: byMonth}],
      chartProps: { chartType: "area" },
    };
  }
);

const selectUninvoiced = createSelector(
  selectProjectsWithAllInvoices,
  (projects) => {
    const uninvoiced = projects.filter(p => p.uninvoiced > 0);
    const total = sumBy(uninvoiced, p => p.uninvoiced);
    
    const groups = [
      {id: 0, label: "0 - 30 Days", total: 0, projects: [] as ProjectWithInvoices[]},
      {id: 10, label: "31 - 75 Days", total: 0, projects: [] as ProjectWithInvoices[]},
      {id: 20, label: "76+ Days", total: 0, projects: [] as ProjectWithInvoices[]}
    ];

    const today = new Date();
    const aging = uninvoiced.reduce((ages, project) => {
      const age = differenceInDays(project.deliveredDate as Date, today);
      const ageGroup = (age <= 30) ? groups[0] : (age <= 75) ? groups[1] : groups[2];
      ageGroup.total += calcFee(project);
      ageGroup.projects.push(project);
      return groups;
    }, groups);
    
    return {
      total: total,
      count: uninvoiced.length,
      aging,
      chartData: [{name: "Uninvoiced", chartType: "bar", data: aging.map(g => g.total)}],
      chartProps: { chartType: "bar", categories: aging.map(g => g.label) },
    };
  }
);

const selectUnpaid = createSelector(
  selectAllInvoices,
  (invoices) => {
    const unpaid = invoices.filter(inv => !inv.paidDate);
    const total = sumBy(unpaid, p => p.amount);
    
    const groups = [
      {id: 0, label: "0 - 30 Days", total: 0, invoices: [] as Invoice[]},
      {id: 10, label: "31 - 75 Days", total: 0, invoices: [] as Invoice[]},
      {id: 20, label: "76+ Days", total: 0, invoices: [] as Invoice[]}
    ];

    const today = new Date();
    const aging = unpaid.reduce((ages, invoice) => {
      const age = differenceInDays(invoice.deliveredDate as Date, today);
      const ageGroup = (age <= 30) ? ages[0] : (age <= 75) ? ages[1] : ages[2];
      ageGroup.total += invoice.amount;
      ageGroup.invoices.push(invoice);
      return groups;
    }, groups);
    
    return {
      total: total,
      aging,
      chartData: [{name: "Unpaid", type: "bar", data: aging.map(g => g.total) }],
      chartProps: { chartType: "bar", categories: aging.map(g => g.label) }
    };
  }
);


export const selectHomeStats = createSelector(
  selectPaid, selectAssigned, selectUninvoiced, selectUnpaid,
  (revenue, assigned, uninvoiced, unpaid) => {
    return {
      revenue: {label: "Revenue", amount: revenue.thisMonth as number, toolTip: "Revenue this month", chartData: revenue.chartData, chartProps: revenue.chartProps, color: "paid", action: "paid"},
      assigned: {label: "Assigned", amount: assigned.thisMonth as number, toolTip: "Assigned this month", chartData: assigned.chartData, chartProps: assigned.chartProps, color: "assigned", action: "active"},
      uninvoiced: {label: "Uninvoiced", amount: uninvoiced.total as number, toolTip: "Amount delivered, but not invoiced", color: "danger", action: "uninvoiced"},
      unpaid: {label: "Unpaid", amount: unpaid.total as number, toolTip: "Amount invoiced, but not paid", action: "unpaid"},
    };
  }
);

export const selectListStats = createSelector(
  selectPaid, selectAssigned, selectDelivered, selectUninvoiced, selectUnpaid,
  (paid, assigned, delivered, uninvoiced, unpaid) => {

    return {
      assigned: {label: "Assigned", amount: assigned.thisMonth as number, toolTip: "Assigned this month", chartData: assigned.chartData, chartProps: assigned.chartProps, color: "assigned", action: "active"},
      delivered: {label: "Delivered", amount: delivered.thisMonth as number, toolTip: "Delivered this month", chartData: delivered.chartData, chartProps: delivered.chartProps, color: "delivered", action: "delivered"},
      paid: {label: "Paid", amount: paid.thisMonth as number, toolTip: "Paid this month", chartData: paid.chartData, chartProps: paid.chartProps, color: "paid", action: "paid"},
      uninvoiced: {label: "Uninvoiced", amount: uninvoiced.total as number, toolTip: "Amount delivered, but not invoiced", color: "danger", action: "uninvoiced"},
      unpaid: {label: "Unpaid", amount: unpaid.total as number, toolTip: "Amount invoiced, but not paid", action: "unpaid"},
    };
  }
);

export const selectHeroMetrics = createSelector(
  selectProjectGroups, _getCustomizations, _getProjects, _getProjectHours, _getState, selectMonthStarts,
  (projectGroups, customizations, projects, projectHours, rootState, monthStarts) => {
    if(!projectGroups) return {};
    
    const today = moment();
    const metricKeys = customizations?.dashboardConfig?.metrics || heroMetrics.filter(hm => hm.isDisplayed).map(hm => hm.id);
    const monthCount = 24;
    let invoicesByMonth: any = null;
    let hourlyTotals: any = null;
    let perHourTotals: any = null;

    const metrics = metricKeys.reduce<any>((result: any, metricKey: string) => {
      const metricItem = heroMetrics.find(hm => hm.id === metricKey);
      if(metricKey === "earned"){
        if(!perHourTotals) perHourTotals = doSelectTrackedHours(projects, projectHours, true);
        return {
          ...result, 
          [metricKey]: {
            id: metricKey,
            label: metricItem?.label,
            months: perHourTotals.months,
            thisMonth: perHourTotals.thisMonth,
            thisMonthIsUp: perHourTotals.thisMonthIsUp,            
            formatter: "currency",
          }
        };
      }
      else if(metricKey === "invoiced"){
        if(!invoicesByMonth) invoicesByMonth = selectInvoiceTotalsByMonth(rootState);
        return {
          ...result,
          [metricKey]: {
            id: metricKey,
            label: metricItem?.label,
            months: invoicesByMonth.months,
            thisMonth: invoicesByMonth.thisMonth.invoiced,
            thisMonthIsUp: calcUpOrDown(invoicesByMonth.thisMonth.invoiced, invoicesByMonth.lastMonth.invoiced, today),
            formatter: "currency",
          }
        };
      }
      else if(metricKey === "paid"){
        if(!invoicesByMonth) invoicesByMonth = selectInvoiceTotalsByMonth(rootState);
        return {
          ...result,
          [metricKey]: {
            id: metricKey,
            label: metricItem?.label,
            months: invoicesByMonth.months,
            thisMonth: invoicesByMonth.thisMonth.paid,
            thisMonthIsUp: calcUpOrDown(invoicesByMonth.thisMonth.paid, invoicesByMonth.lastMonth.paid, today),
            formatter: "currency",
          }
        }
      }
      else if(metricKey === "hours"){
        if(!hourlyTotals) hourlyTotals = doSelectTrackedHours(projects, projectHours)
        return {
          ...result, 
          [metricKey]: {
            id: metricKey,
            label: metricItem?.label,
            months: hourlyTotals.months,
            thisMonth: hourlyTotals.thisMonthHours,
            thisMonthIsUp: hourlyTotals.thisMonthHoursIsUp, 
            formatter: "hours",           
          }
        };
      }
      else{
        const group = projectGroups[groupKeys[metricKey]] as ProjectDateGroup[];
        
        const groupMonths = monthStarts.map((month: any) => {
          const monthKey = month.format(REDUX_DATE_FORMAT);
          const item = group.find(g => g.key === monthKey);
          return {
            label: metricKey,
            date: month,
            [metricKey]:  calcDateGroupAmount(item, Boolean(metricKey === "assigned")),
          };
        });

        const thisMonth = groupMonths[monthCount - 1][metricKey];
        const lastMonth = groupMonths[monthCount - 2][metricKey];

        const totals = {
          id: metricKey,
          label: metricItem?.label || metricKey,
          months: groupMonths,
          thisMonth: thisMonth,
          thisMonthIsUp: calcUpOrDown(thisMonth, lastMonth, today),
          formatter: "currency",
        }

        return {...result, [metricKey]: totals };
      }

   }, {});

  return metrics;
  }
);

export const selectHeroAreas = createSelector(
  selectProjectGroups, _getCustomizations, _getProjects, _getProjectHours, selectMonthStarts, _getState,
  (projectGroups, customizations, projects, projectHours, months, rootState) => {
    if(!projectGroups) return [];

    const metricKeys = customizations?.dashboardConfig?.areas || heroAreas.filter(hm => hm.isDisplayed).map(hm => hm.id);
    let invoicesByMonth: any = null; //selectInvoiceTotalsByMonth(rootState);
    // const monthCount = 24;
    // const months = getMonthStarts(monthCount);

    const metrics = metricKeys.map((metricKey: string) => {
      if(metricKey === "earned"){
        const hourlyTotals = doSelectTrackedHours(projects, projectHours, true); //doSelectTrackedTotals(projectGroups.all);
        return {id: "earned", months: hourlyTotals.months || [] };     
      }
      else if(metricKey === "invoiced"){
        if(!invoicesByMonth) invoicesByMonth = selectInvoiceTotalsByMonth(rootState);
        return {
          id: "invoiced",
          months: invoicesByMonth.months,
        };
      }
      else if(metricKey === "paid"){
        if(!invoicesByMonth) invoicesByMonth = selectInvoiceTotalsByMonth(rootState);
        return {
          id: metricKey,
          months: invoicesByMonth.months,
        }
      }
      else{
        const group = projectGroups[groupKeys[metricKey]] as ProjectDateGroup[];
        
        const groupMonths = months.map(month => {
          const monthKey = month.format(REDUX_DATE_FORMAT);
          const item = group.find(g => g.key === monthKey);
          return {
            label: metricKey,
            date: month.clone().startOf("month"),
            [metricKey]:  calcDateGroupAmount(item, Boolean(metricKey === "assigned")),
          };
        });
        return {id: metricKey, months: groupMonths};        
      }
    });

    return metrics;
  });

export const selectProjectWidgetData = createSelector(
  selectAllProjects,
  (projects) => {
    if(!projects) return null;
    const now = new Date();
    const active = projects.filter(prj => {
      return (isBefore(addDays(prj.startDate as Date, 1), now) && (!prj.dueDate || isAfter(prj.dueDate as Date, now))) || (prj.dueDate && isSameMonth(prj.dueDate as Date, now));
    });
    const notDelivered = active.filter(p => !p.deliveredDate);
    return notDelivered;
  }
);

//Gets the list of project categories, derived from the clients.
export const selectProjectCategories = createSelector(
  selectAllProjects,
  (projects) => {
    if(!projects) return [];
    const categories = projects.map(c => c.category);
    const unique = _.uniq(categories);
    const sorted = _.compact(unique).sort();
    return sorted.length > 0 ? sorted.map(s => {return {id: s, label: s}; }) : [];
  }
);

export const selectProjectAlerts = createSelector(
  selectProjectsWithAllInvoices, selectAllInvoices, selectUninvoiced,
  (projects, invoices, uninvoiced) => {
    const today = new Date();
    const alerts = [] as IAlert[];

    const overdueProjects = projects.filter(p => p.dueDate && !p.deliveredDate && differenceInDays(endOfDay(p.dueDate as Date), endOfDay(today)) < 0);
    if(overdueProjects.length){
      const count = overdueProjects.length;
      alerts.push({
        id: "projects-overdue",
        label: "Overdue Projects",
        message: `You have ${count} overdue project${count > 1 ? "s" : ""}`,
        severity: "error",
        icon: "warning",
        path: `${routes.projects}?filter=overdue&sort=dueDate&sortDir=asc`,
        other: { count: count, items: overdueProjects },        
      });
    }

    const overdueInvoices = filterInvoices(invoices, "overdue");
    if(overdueInvoices.length){
      const count = overdueInvoices.length;
      const overdueAmount = _.sumBy(overdueInvoices, inv => inv.amount);
      alerts.push({
        id: "invoices-overdue",
        label: "Overdue Invoices",
        message: `You have ${count} overdue invoice${count > 1 ? "s" : ""} totaling ${formatCurrency(overdueAmount)}`,
        severity: "error",
        icon: "warning",
        path: `${routes.invoices}?filter=overdue&sort=dueDate&sortDir=asc`,
        other: { count: count, items: overdueProjects, amount: overdueAmount },        
      });
    }

    // const uninvoiced = projects.filter(p => p.uninvoiced && p.uninvoiced > 0);
    if(uninvoiced.total){
      const count = uninvoiced.count;
      alerts.push({
        id: "projects-uninvoiced",
        label: "Uninvoiced Projects",
        message: `You have ${count} project${count > 1 ? "s" : ""} that need to be invoiced totaling ${formatCurrency(uninvoiced.total)}`,
        icon: "info_outline",
        path: `${routes.projects}?filter=uninvoiced&sort=deliveredDate&sortDir=asc`,
        // severity: "info",
        other: { count: uninvoiced.count, items: uninvoiced, total: uninvoiced.total, },              
      });
    }
    
    return alerts;
  }
)