import firebase from "firebase/app";
import "firebase/firestore";
import _ from "lodash";
import { Integration, ExternalItemType } from "types";
import { COLS, STATUSES, IFirestoreResult, collectionRef, arrayResponse, inflateCollection } from "utils/firebase";
import { prepareForDb } from "utils/general-helpers";
import { doFetch } from "utils/fetch-helper";

export const integrationCollections = {
  INTEGRATIONS: "integrations",
};

const INTEGRATION_WHITELIST = ["token", "tokenType", "expiration", "scope", "accountId"];

export const integrationsApi = (db: firebase.firestore.Firestore) => ({

  getIntegrations: async (orgId: string): Promise<IFirestoreResult> => {
    const coll = collectionRef(db, orgId, COLS.INTEGRATIONS);
    const snapshot = await coll.get();
    const items = inflateCollection<Integration>(snapshot);

    return arrayResponse(items, orgId);
  },

  createIntegration: async (orgId: string, model: Integration): Promise<IFirestoreResult> => {
    const id = model.id;
    const safe = {...prepareForDb(model, null, null, INTEGRATION_WHITELIST), lastUpdatedDate: new Date()};
    const coll = collectionRef(db, orgId, COLS.INTEGRATIONS);

    const doc = coll.doc(id);
    await doc.set(safe);

    return {
      ok: true,
      statusCode: STATUSES.ok,
      key: id,
    };
  },

  updateIntegration: async (orgId: string, id: string, model: Partial<Integration>): Promise<IFirestoreResult> => {
    const safe = {...prepareForDb(model, null, null, INTEGRATION_WHITELIST), updatedDate: new Date()};
    const existing = collectionRef(db, orgId, COLS.NOTES).doc(id);
    await existing.update({...safe, lastUpdatedDate: new Date()});

    return {
      ok: true,
      statusCode: STATUSES.ok,
      key: id,
    };
  },

  //#region Harvest API
  fetchExternalData: async (props: Integration, itemType: ExternalItemType, itemId: string | null = null): Promise<IFirestoreResult> => {
    if(props.id === "harvest" && props.token && props.accountId){
      const headers: Record<string, string> = {
        Authorization: `Bearer ${props.token}`,
        ["Harvest-Account-Id"]: props.accountId,
        ["User-Agent"]: "Gigops (info@anythinksolutions.com)",
      };

      const url = `https://api.harvestapp.com/v2/${itemType}${itemId ? `/${itemId}` : ""}`;
      // const url = "https://api.harvestapp.com/v2/clients";
      const result = await doFetch(url, "GET", headers);

      if(result.isError){
        return {
          ok: false,
          statusCode: STATUSES.ok,
          key: props.id,
          error: result.error,
        };
      }
      else{
        const data = result;
        return {
          ok: true,
          statusCode: STATUSES.ok,
          key: props.id,
          data,
        };
      }
    }

    return {
      ok: false,
      statusCode: STATUSES.ok,
      key: props.id,
      error: "required information missing",
    };
  }

  //#endregion
})