import { Action, combineReducers } from "@reduxjs/toolkit";
import { ThunkAction } from "redux-thunk";
import appSlice from "features/app/app-slice";
import clientsSlice from "../features/clients/clients-slice";
import projectsSlice from "features/projects/infra/projects-slice";
import timesheetSlice from "features/timesheet/infra/timesheet-slice";
import invoicesSlice from "features/invoices/infra/invoices-slice";
import goalsSlice from "features/goals/infra/goals-slice";
import notesSlice from "features/notes/infra/notes-slice";
import tasksSlice from "features/tasks/infra/tasks-slice";
import integrationsSlice from "features/integrations/integrations-slice";

export const rootReducer = combineReducers({
  app: appSlice,
  clients: clientsSlice,
  projects: projectsSlice,
  timesheet: timesheetSlice,
  invoices: invoicesSlice,
  goals: goalsSlice,
  notes: notesSlice,
  tasks: tasksSlice,
  integrations: integrationsSlice,
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

//Function to streamline initialization of an action
export function initAction(uid: string | null, getState: () => RootState){
  if (!uid) throw new Error("User is not authorized.");
  
  const app = getState().app;
  if(!app.isInitialized)  throw new Error("Action attempted before app initialized.");
  
  return app.org.id;
}

export default rootReducer;
