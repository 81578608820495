import React from "react";
import { shallowEqual } from "react-redux";
import _ from "lodash";
import moment from "moment";
import TextField, { StandardTextFieldProps } from '@material-ui/core/TextField';
import { InputField } from "types";
import { isDateInRange, getAsMoment, formatDate, parseDate, validateDate } from "utils/date-helpers";

type Dateish = string | Date | null | undefined;

export function handleDateValue(
  newVal: string | Date | null | undefined,
  isRequired = false,
  outOfRangeMessage: string | undefined = undefined, 
  minDate: moment.Moment | undefined = undefined, 
  maxDate: moment.Moment | undefined = undefined
): Partial<InputField<Date>>{
  const isBlank = newVal === null || newVal === "";
  const parsed = isBlank ? null : (_.isDate(newVal) ? newVal : parseDate(newVal));
  const isValid = _.isDate(newVal) ? (!isBlank || !isRequired) : (isBlank ? !isRequired  : validateDate(newVal));
  const isInRange = !newVal || isDateInRange(parsed ?? newVal, minDate, maxDate);
  // const formatted = isValid && !isBlank ? formatDate(newVal) : _.isDate(newVal) ? newVal.toString() : newVal;
  // const formatted = _.isDate(newVal) ? formatDate(newVal) : (newVal === null ? "" : newVal);
  const formatted = (isValid && !isBlank) ? formatDate(parsed) : (newVal === null ? "" : newVal as string);

  if(isValid && isInRange){
      
    return {
      value: parsed,
      display: isBlank ? "" : formatted,
      isEmpty: isBlank,
      bindings: {
        error: false,
        helperText: null,          
      }
    }
  }
  else{
    if(!isValid){
      if(isBlank && isRequired){
        return {
          display: formatted,
          isEmpty: isBlank,
          bindings: {
            error: true,
            helperText: "value is required",
          }
        }
      }
      else{
        return {
          display: formatted,
          isEmpty: isBlank,
          bindings: {
            error: true,
            helperText: "invalid date format",
          }
        }
      }
    }
    else{ //not in range
      return {
        display: formatted, //dConstant ? formatDate(dConstant()) : newVal,
        isEmpty: isBlank,
        bindings: {
          error: true,
          helperText: outOfRangeMessage || "not in range",
        }
      }
    }
  }
}

interface DateInputProps extends StandardTextFieldProps {
  onDateChange?: (value: Date | null, isError: boolean, props: Partial<InputField<Date>>) => void;
  // onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onTextChange?: (value: string) => void;
  maxDate?: moment.Moment | Date | string;
  minDate?: moment.Moment | Date | string;
  outOfRangeMessage?: string;
  required?: boolean;
  value?: string | Date | null;
}

const DateInput = React.forwardRef((props: DateInputProps, ref: React.Ref<HTMLInputElement>) => {
  const { value, label, onDateChange, onTextChange, required, minDate, maxDate, outOfRangeMessage, ...otherProps } = props;
  const initValue = (value || "") as Dateish;
  const initial = React.useMemo(() => handleDateValue(initValue, !!required), [value, required]);
  const minMmt = React.useMemo(() => { return getAsMoment(minDate); }, [minDate]);
  const maxMmt = React.useMemo(() => { return getAsMoment(maxDate); }, [maxDate]);
  const [dateValue, setDateValue] = React.useState<Partial<InputField<Date>>>(initial);
  const [lastValue, setLastValue] = React.useState<Partial<InputField<Date>> | null>(null);

  //-- monitor the required, min, max props and adjust if they change
  React.useEffect(() => {
    const updates = handleDateValue(dateValue.display || "", !!required, outOfRangeMessage, minMmt, maxMmt);
    if(!shallowEqual(updates, dateValue)){
      setDateValue({...dateValue, ...updates});
    }
  }, [required, minMmt, maxMmt]);

  //-- monitor the dateValue property, and raise the change event when the value changes
  React.useEffect(() => {
    if(!_.isUndefined(dateValue.value) && !shallowEqual(dateValue, lastValue)){
      setLastValue(dateValue);    //track what I last reported, so we don't re-report the same thing
      if(onDateChange) onDateChange(dateValue.value, !!dateValue.bindings?.error, dateValue);
      // if(onChange) onChange({target: { value: dateValue.display as string }} as any);
    }
  }, [dateValue]);

  const _change = (e: React.ChangeEvent<any>) => {
    setDateValue({...dateValue, display: e.target.value});
    if(onTextChange) onTextChange(e.target.value);
    // if(onChange) onChange(e);
  }

  const onDateBlur = () => {
    const updates = handleDateValue(dateValue.display || "", !!required, outOfRangeMessage, minMmt, maxMmt);
    setDateValue({...dateValue, ...updates});
  }

  return (
    <TextField inputRef={ref} label={label} value={dateValue.display} onChange={_change} onBlur={onDateBlur} autoComplete="off" {...dateValue.bindings} {...otherProps} />
  );
});

DateInput.displayName = "DateInput";
export default DateInput;
