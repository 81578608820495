import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Icon from '@material-ui/core/Icon';
import CloseIcon from '@material-ui/icons/Close';
import WaitButton from './wait-button';
import { dialogClasses } from 'utils/common-classes';

export interface IDialogAction {
  label: string;
  onClick: React.MouseEventHandler;
  isDisabled?: boolean;
  isWorking?: boolean;
  color?: string;
  align?: "left" | "right";
  leftIcon?: any;
  rightIcon?: any;
}

interface IProps {
  isOpen: boolean;
  onClose: (id?: number, wasSaved?: boolean) => void;
  title?: string;
  icon?: string;
  children: any;
  actions: Array<IDialogAction>;
  maxWidth?: false | "sm" | "md" | "lg" | "xl" | "xs" | undefined;
  isWorking?: boolean;
  canSave?: boolean;
  dismissDisabled?: boolean;
}

export const cancelAction = (onClose: () => void, label = "Cancel") => ({
  label: label,
  onClick: onClose,
  color: "default",
  });

export const saveAction = (onSave: any, isDisabled = false, label = "Save") => ({
  label: label,
  onClick: onSave,
  color: "primary",
  isDisabled: isDisabled,
});

export const saveCancelDialogActions = (onSave: any, onClose: any) => {
  return [cancelAction(onClose), saveAction(onSave)];
};

const buildStyles = makeStyles(theme => ({
  ...dialogClasses(theme),
}));

export const AtkDialog = (props: IProps) => {
  const classes = buildStyles() as any;
  let iAction = 0;
  const leftActions = props.actions.filter(a => a.align === "left");
  const rightActions = props.actions.filter(a => a.align === undefined || a.align === "right");
  const width = props.maxWidth === undefined ? "sm" : props.maxWidth;
  // const isSaveDisabled = Boolean(props.canSave === false);

  return (
    <Dialog open={props.isOpen} onClose={() => props.onClose()} maxWidth={width} className={classes.dialog} disableBackdropClick={props.isWorking || props.dismissDisabled} disableEscapeKeyDown={props.isWorking || props.dismissDisabled}>

      {(props.title || props.icon) &&
        <DialogTitle className={classes.dialogTitle}>
          <Grid container justify="space-between">
            <Typography className={classes.titleText}>
              <Icon fontSize="small" className={classes.titleIcon}>{props.icon}</Icon>
              {props.title}
            </Typography>
            <CloseIcon fontSize="small" onClick={() => props.onClose()} className={classes.closeIcon} />
          </Grid>
        </DialogTitle>
      }
      <DialogContent>
        <Grid container>
          {props.children}
        </Grid>


      </DialogContent>

      <DialogActions>
        <Grid container>
          <Grid item xs={4} container>
            {
              leftActions.map((action: IDialogAction) =>
                <WaitButton key={iAction++} isWaiting={action.isWorking || props.isWorking} disabled={action.isDisabled || props.isWorking} onClick={action.onClick} color={action.color || "default"} size="small">
                  <Grid container spacing={1} alignItems="center" justify="center">
                    {action.leftIcon}
                    <Grid item>{action.label}</Grid>
                    {action.rightIcon}
                  </Grid>
                </WaitButton>
              )
            }
          </Grid>
          <Grid item xs={8} container justify="flex-end">
            {
              rightActions.map((action: IDialogAction) =>
                <WaitButton key={iAction++} isWaiting={action.isWorking || props.isWorking} disabled={action.isDisabled || props.isWorking} onClick={action.onClick} color={action.color || "default"} size="small">
                  <Grid container spacing={1} alignItems="center" justify="center">
                    <Grid item>{action.leftIcon}</Grid>
                    <Grid item>{action.label}</Grid>
                    <Grid item>{action.rightIcon}</Grid>
                  </Grid>
                </WaitButton>
              )
            }
          </Grid>
        </Grid>
      </DialogActions>

    </Dialog>
  );
}

// export default AtkDialog;