import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import { IClient, emptyClient, ProjectTypeItems } from 'types';
import { RootState } from "store/root-reducer";
import { Parsers, IParserCollection, useInputs, useLocalization, useSnackbar } from 'utils/hooks';
import { AtkDialog, SeparatorLine } from 'components';
import { selectCustomizations } from "features/app";
import { createClient } from './clients-slice';
import { feeAdornStyle, generalStyles } from "utils/styles";
import { trackCreate } from 'utils/mixpanel';

const parsers: IParserCollection = {
  // id: Parsers.int,
  // name: Parsers.string,
  defaultRate: Parsers.currency,
  // contactName: Parsers.string,
};

interface IProps {
  isOpen: any;
  id: number | string | boolean| undefined;
  onClose: (val?: number | boolean) => void;
}

const buildStyles = makeStyles(theme => ({
  ...generalStyles(theme),
  inputGrid: {
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  ...feeAdornStyle(theme),
}));

const ClientDialog = (props: IProps) => {
  const classes = buildStyles() as any;
  const dispatch = useDispatch();
  const { L } = useLocalization();
  const defaults = useSelector((state: RootState) => selectCustomizations(state, ["defaultProjectType", "defaultHourlyRate"]));
  const [values, display, errors, binding, setValues] = useInputs<IClient>(emptyClient, parsers);
  const isNew = React.useMemo(() => { return (props.id === -1 || props.id === "-1" || props.id === "" || props.id === true); }, [values.id])
  const notify = useSnackbar();
  
  React.useEffect(() => {
    if(isNew){
      const withDefaults = {
        ...values,
        defaultRate: defaults.defaultHourlyRate,
        defaultProjectType: defaults.defaultProjectType,
      };

      return setValues(withDefaults);
    }
  }, [props.id]);

  async function onSave() {
    if (props.id === -1 || props.id === "") {
      await dispatch(createClient(values)) as any;
    }
    
    notify(`${L("client", true)} created successfully`);

    setValues({ ...emptyClient });    //reset the values
    trackCreate("client");
    props.onClose(true);
  }

  const dialogActions = [
    {
      label: "Cancel",
      onClick: () => { props.onClose(); },
      color: "default",
    },
    {
      label: "Save",
      onClick: onSave,
      color: "primary",
    },
  ];

  return (
    <AtkDialog isOpen={Boolean(props.isOpen)} onClose={() => props.onClose()} title={L("client", true)} icon="business" actions={dialogActions}>
      <Grid container>
        <Grid item xs={12} className={classes.inputGrid}>
          <TextField id="name" label="Name*" fullWidth value={display?.name} {...binding.input} {...errors["name"]} autoComplete="off" autoFocus={true} />
        </Grid>

        <SeparatorLine label="Details" className={classes.mtl} />

        <Grid item xs={12} className={classes.inputGrid}>
          <TextField id="contactName" label="Contact" fullWidth value={display?.contactName} {...binding.input} {...errors["contactName"]} autoComplete="off" />
        </Grid>
        <Grid item xs={12} className={classes.inputGrid}>
          <TextField id="contactEmail" label="Contact Email" fullWidth value={display?.contactEmail} {...binding.input} {...errors["contactEmail"]} autoComplete="off" />
        </Grid>
        <Grid item xs={5} className={classes.inputGrid}>
          <TextField id="contactPhone" label="Contact Phone" fullWidth value={display?.contactPhone} {...binding.input} {...errors["contactPhone"]} autoComplete="off" />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} className={classes.inputGrid} container>
          <TextField id="defaultRate" label="Default Fee / Rate" className={classes.rateInput} value={display?.defaultRate} {...binding.input} {...errors["defaultRate"]} autoComplete="off"
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <Grid container className={classes.adornGrid}>
                  <Typography className={classes.adorn}>/</Typography>
                  <TextField select value={display?.defaultProjectType} {...binding.select("defaultProjectType")} className={classes.adornSelect}>
                    <MenuItem value={-1} disabled>[choose]</MenuItem>
                    {ProjectTypeItems.map(opt => <MenuItem key={opt.id} value={opt.id} className={classes.option}>{opt.unitLabel}</MenuItem>)}
                  </TextField>
                </Grid>
              </InputAdornment>

            }}
          />
        </Grid>
        <Grid item xs={12} className={classes.inputGrid}>
          <TextField id="notes" label="Notes" fullWidth value={display?.notes} {...binding.input} multiline rowsMax={3} {...errors["notes"]} />
        </Grid>
      </Grid>
    </AtkDialog>
  );
}

export default ClientDialog;