import React from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
import ProjectAlertItem from "./project-alert-item";
import { cardStyles, fontStyles, generalStyles } from "utils/styles";
import { selectProjectAlerts } from "features/projects";

const buildStyles   = makeStyles(theme => ({
  ...generalStyles(theme),
  ...fontStyles(theme),
  ...cardStyles(theme),
  myCardHeader: {
    borderBottom: "none",
  },
  myCardBody: {
    paddingTop: theme.spacing(0.5),
    maxHeight: theme.spacing(21),
    overflowY: "auto",
    flexWrap: "nowrap",
    [theme.breakpoints.down("sm")]: {
      maxHeight: theme.spacing(42),
    }
  },
  cardTitle: {
    color: theme.palette.grey[500],
  }
}));

const ProjectDashAlerts = () => {
  const classes   = buildStyles();
  const alerts = useSelector(selectProjectAlerts);

  return (
    <Paper variant="outlined" className={classes.card}>
      <Grid container className={clsx(classes.cardHeader, classes.myCardHeader)}>
        <Typography className={clsx(classes.cardHeaderText, classes.cardTitle, classes.upcase)}>Notifications</Typography>
      </Grid>
      <Grid id="data-view" container direction="column" justify="flex-start" className={clsx(classes.cardBody, classes.myCardBody)}>
          {alerts.map(alert => {
            return (
              <Grid key={alert.id} container className={clsx(classes.mb, classes.fullWidth)}>
                <ProjectAlertItem alert={alert} />
              </Grid>
            )        
          })}
      </Grid>
    </Paper>
  );
}

export default ProjectDashAlerts;
