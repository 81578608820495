import React, { useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { ISort, ProjectFilter, ProjectSettings } from "types";
import { selectFilteredProjects } from "../infra/projects-selectors";
import ProjectRow, { projectHeader } from "./project-list-row";
import ProjectsStatBar from "./projects-stat-bar";
import { useReadyState, useSliceSettings, useLocalization, useDialogs, DialogKey, useLocalSetting } from 'utils/hooks';
import { layoutStyles, fontStyles, generalStyles, tabStyles } from "utils/styles";
import ProjectListHeader from "./project-list-header";
import AtkList, { ListDisplayType } from "components/atk-list/atk-list";
import ProjectCard from "./card/project-card";
import ProjectListMenu from "./project-list-menu";

const buildStyles = makeStyles(theme => ({
  ...generalStyles(theme),
  ...fontStyles(theme),
  ...tabStyles(theme),
  ...layoutStyles(theme),
  root: {
    padding: theme.spacing(1),
  },
  tabRow: {
    margin: `${theme.spacing(1)}px ${theme.spacing(0)}px`, //`
  },
  tableGrid: {
    margin: `${theme.spacing(2)}px ${theme.spacing(0)}px`, //`
  },
  statsBar: {
    margin: `${theme.spacing(1)}px ${theme.spacing(0)}px`, //`
    marginBottom: theme.spacing(2),
  }
}));

const LIST_SETTINGS_KEY = "prj-list";

const ProjectList = () => {
  const classes = buildStyles();
  const {L, LSwap} = useLocalization();
  const isReady = useReadyState();
  const itemRows = useSelector(selectFilteredProjects);
  const [listSettings ] = useLocalSetting(LIST_SETTINGS_KEY, {displayType: "table"});
  const [settings, changeSettings] = useSliceSettings<ProjectSettings>("projects");
  const { openDialog, onOpenDialog } = useDialogs();
  const emptyMessage = `No ${L("projects")} match the current filter`;
  const [displayType, setDisplayType] = useState<ListDisplayType>(listSettings.displayType || "table"); //tabItems[0].action;
  const [order, setOrder] = useState<ISort>({sort: settings.sort, sortDir: settings.sortDir});

  const openDialogWithFocus = (focusField: string | null) => {
    return (dialogName: string, id: number | string) => {
      return () => {
        const props = focusField ? {isExpanded: true, focusField } : null;
        openDialog(dialogName as DialogKey, id, props);
      }
    }
  }

  const onTableSorted = (order: ISort) => {
    changeSettings({ sort: order.sort, sortDir: order.sortDir });
    setOrder(order);
  }

  const onViewChange = (view: string) => {
    setDisplayType(view as ListDisplayType);
  }

  const onSortChange = (sortProps: ISort) => {
    if(sortProps.sort === order.sort){
      const newDir = sortProps.sortDir || (order.sortDir === "asc") ? "desc" : "asc";
      setOrder({...order, sortDir: newDir});
    }
    else{
      setOrder({sort: sortProps.sort, sortDir: sortProps.sortDir || "asc"});
    }
  }

  const onStatClick = (action: string) => {
    changeSettings({ filter: action as ProjectFilter });
  }

  if (!isReady) return null;

  return (
    <Grid id="projects-list-view" container className={classes.root}>
      <Grid container justify="space-between">
        <Grid item sm={6} xs={12}>
          <Typography variant="h5" color="primary" className={classes.pageHead}>{L("projects", true)}</Typography>
        </Grid>
        <Grid item sm={6} xs={12} container justify="flex-end" alignItems="center" className={classes.centerXs}>
          <Button size="small" color="primary" onClick={onOpenDialog("project", "-1")}>{LSwap("New Project", "project")}</Button>
          <ProjectListMenu />
        </Grid>
      </Grid>

      {/* NOTE: this is here to test out the error boundary... */}
      {/* <span>{(isReady as any).toUpperCase()}</span> */}

      <Grid container className={classes.statsBar}>
        <ProjectsStatBar onClick={onStatClick}/>
      </Grid>
      <ProjectListHeader onDisplayTypeChange={onViewChange} onSortChange={onSortChange}/>
      
      <Grid item xs={12} container className={classes.tableGrid}>
        <AtkList
          displayType={displayType}
          items={itemRows}
          tableColumns={projectHeader(L)}
          tableRowComponent={ProjectRow}
          cardComponent={ProjectCard}
          dense={true}
          order={order}
          onSort={onTableSorted}
          emptyMessage={emptyMessage}
          settingKey="prj-list"
          extra={{
            classes: classes,
            openDue: openDialogWithFocus("dueDate"),
            openDelivered: openDialogWithFocus("deliveredDate"),
            openInvoiced: openDialogWithFocus("invoicedDate"),
            openPaid: openDialogWithFocus("paidDate"),
            showDates: settings.showDates,
          }} />
      </Grid>
    </Grid>

  );
}

export default ProjectList;

