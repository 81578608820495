import { endOfYear, startOfYear, subYears } from "date-fns";
import { Invoice, IProject, IClient, DateRangeFunc, IProjectSummary } from "types";
import * as RevenueCalculations from "./metrics-revenue";
import * as ClientCalculations from "./metrics-client";
import * as ProjectCalculations from "./metrics-project";
import * as TimeMetrics from "./metrics-time";

//=== Metric Functions ===
const now = new Date();
const periodMap: Record<string, DateRangeFunc> = {
  ["this-year"]: () => ({start: startOfYear(now), end: endOfYear(now)}),
  ["last-year"]: () => ({start: startOfYear(subYears(now, 1)), end: endOfYear(subYears(now, 1))}),
}

export const getMetricData = (period: string, metric: string, projects: IProject[], clients: IClient[], invoices: Invoice[], projectHours: IProjectSummary[],  monthEnds: Date[]) => {
  const range = periodMap[period]();

  switch(metric){
    case "revenue-booked": return RevenueCalculations.calcRevenueBooked(range, projects, monthEnds);
    case "revenue-earned": return RevenueCalculations.calcRevenueEarned(range, projects, monthEnds);
    case "revenue-invoiced": return RevenueCalculations.calcRevenueInvoiced(range, invoices, monthEnds);
    case "revenue-paid": return RevenueCalculations.calcRevenuePaid(range, invoices, monthEnds);
    case "revenue-rate": return RevenueCalculations.calcOverallRate(range, projects, monthEnds);
    case "revenue-wait": return RevenueCalculations.calcRevenueWait(range, invoices);
    case "revenue-perword": return RevenueCalculations.calcWordRate(range, projects);
    case "revenue-bycategory-client": return RevenueCalculations.calcRevenueByClientCategory(range, clients, projects);
    case "revenue-bycategory-project": return RevenueCalculations.calcRevenueByProjectCategory(range, projects);
    case "revenue-bywordcount-rate": return RevenueCalculations.calcWordCountRate(range, projects, projectHours);

    case "clients-count": return ClientCalculations.calcClientCount(clients, monthEnds);
    case "clients-active": return ClientCalculations.calcActiveClients(range, clients, projects, monthEnds);
    case "clients-avg-projects": return ClientCalculations.calcProjectsPerClient(range, clients, projects);
    case "clients-avg-invoiced": return ClientCalculations.calcClientAvgRevenue(range, clients, invoices);
    case "clients-category-count": return ClientCalculations.calcClientCategories(range, clients, projects);
    case "clients-bycategory-rate": return ClientCalculations.calcClientRateByCategory(range, clients, projects, projectHours);

    case "projects-count": return ProjectCalculations.calcProjectCount(projects, monthEnds);
    case "projects-active": return ProjectCalculations.calcActiveProjects(range, projects, monthEnds);
    case "projects-avg-length": return ProjectCalculations.calcProjectLength(range, projects);
    case "projects-avg-revenue": return ProjectCalculations.calcProjectRevenue(range, projects);
    case "projects-avg-rate": return ProjectCalculations.calcProjectAvgRate(range, projects, monthEnds);
    case "projects-mean-rate": return ProjectCalculations.calcProjectMeanRate(range, projects);
    case "projects-category-count": return ProjectCalculations.calcProjectCategories(range, projects);
    case "projects-word-count": return ProjectCalculations.calcTotalWords(range, projects, monthEnds);
    case "projects-bycategory-rate": return ProjectCalculations.calcRateByProjectCategory(range, projects, projectHours);

    case "time-total": return TimeMetrics.calcTimeTotal(projectHours, monthEnds);
    // case "time-total-hours": return TimeMetrics.calcTimePeriodTotal(range, projectHours, monthEnds);
    case "time-monthly-average": return TimeMetrics.calcTimePerMonth(range, projectHours, monthEnds);
    case "time-workdays": return TimeMetrics.calcWorkdays(range, projectHours);
  }

  return {
    value: 0,
    displayValue: "[TBD]"
  };
}