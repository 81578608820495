import React from "react";
import { Route } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Fade from '@material-ui/core/Fade';
import { useLocalization, useReadyState } from 'utils/hooks';
import ClientList from "./client-list";
import ClientViewVert from "./ui/client-view-vert";
import ClientEditView from "./client-edit-view";
import { routes } from "features/app/app-routes";
import { LoadingIndicator } from "components";
import { Helmet } from "react-helmet-async";

const ClientsView = () => {
  const isReady = useReadyState();
  const {LSwap} = useLocalization();

  return (
    <>
      {!isReady &&
        <LoadingIndicator isVisible={true} message="Loading..." />
      }
      {isReady &&
        <Fade in={true} timeout={250}>
          <Grid>
            <Helmet>
              <title>{LSwap("Gigops - Clients", "clients")}</title>
            </Helmet>
            <Route path={routes.clients} exact component={ClientList} />
            <Route path={routes.client} exact component={ClientViewVert} />
            <Route path={routes.clientEdit} exact component={ClientEditView} />
          </Grid>
        </Fade>
      }
    </>
  );
};

export default ClientsView;
