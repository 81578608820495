import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Alert from '@material-ui/lab/Alert';
import { useSelector } from "react-redux";
import { ITask, RootState } from "types";
import { cardStyles, fontStyles, generalStyles } from "utils/styles";
import TaskViewCard from "./task-view-card";
import { LoadingIndicator } from "components";
import TaskEditCard from "./task-edit-card";

const buildStyles   = makeStyles(theme => ({
  ...fontStyles(theme),
  ...generalStyles(theme),
  ...cardStyles(theme),
  root  : {
  },  
}));

interface ListProps {
  items: ITask[] | null;
}

const TasksList = ({ items }: ListProps) => {
  const classes   = buildStyles();
  const { status, settings, isInitialized, isEmpty, editingId } = useSelector((state: RootState) => state.tasks);

  const message = useMemo(() => {
    if(isEmpty) return "Add your first task above";
    else if(items && items.length === 0){
      if(settings.filter?.status !== "finished"){
        return "Yay!  Looks like it's time for a margarita."
      }
      else return "Didn't find any tasks matching your filter."
    }
    return null;    
  }, [isEmpty, items?.length, settings.filter, settings.search]);

  if(!isInitialized){
    return (<LoadingIndicator isVisible={!isInitialized || status.isWorking} message="Loading..." />);
  }

  return (
    <Grid container direction="column" className={classes.root}>
      {message && <Alert severity="info" style={{width: "100%"}}>{message}</Alert> }      
      
      {!isEmpty && 
        <Grid container>
          {items?.map(task => 
            <Grid key={task.id} item xs={12}>
              {task.id === editingId ? <TaskEditCard task={task}/> : <TaskViewCard task={task} />}
            </Grid>
          )}
        </Grid>
      }
      
    </Grid>
  );
}

export default TasksList;
