import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Tooltip from '@material-ui/core/Tooltip';
import { selectNotesFor } from "features/notes/infra/note-selectors";
import { INote, ITask, RootState } from "types";
import { loadNotes } from "features/notes/infra/note-actions";
import { selectTasksFor } from "features/tasks/infra/task-selectors";
import { loadTasks } from "features/tasks/infra/task-actions";
import { cardStyles, fontStyles, generalStyles } from "utils/styles";
import TaskEditCard from "features/tasks/task-edit-card";
import TaskViewCard from "features/tasks/task-view-card";
import NoteViewCard from "features/notes/note-view-card";
import NoteEditCard from "features/notes/note-edit-card";

const buildStyles   = makeStyles(theme => ({
  ...fontStyles(theme),
  ...generalStyles(theme),
  ...cardStyles(theme),   
  addRow: {
    marginTop: theme.spacing(-1),
    marginBottom: theme.spacing(1),
  },
  childPaper: {
    border: "unset",
    borderBottom: `1px solid ${theme.palette.grey[300]}`, //`
  },
  btnGroup: {
    "& button": {
      border: `1px solid ${theme.palette.grey[300]}`, //`
      boxShadow: "none !important",
      padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`, //`
      "& span": {
        fontSize: "0.8rem",
      }
    }
  }
}));

interface ControlProps {
  itemType: "client" | "project";
  itemId: string;
  isWorking: boolean;
  classes?: Record<string, string>;
}

const ItemNotesList = (props: ControlProps) => {
  const classes   = buildStyles();
  const otherClasses = props.classes || {};
  const { itemType, itemId, isWorking } = props;
  const dispatch = useDispatch();
  const [{isNotes, isTasks}, setProps] = useState({isNotes: true, isTasks: true});
  const notes = useSelector((state: RootState) => selectNotesFor(state, itemType, itemId));
  const tasks = useSelector((state: RootState) => selectTasksFor(state, itemType, itemId));
  const taskId = useSelector((state: RootState) => state.tasks.editingId);
  const noteId = useSelector((state: RootState) => state.notes.editingId);
  const [adding, setAdding] = useState<"note" | "task" | null>(null);

  const both = useMemo(() => {
    const combined: any[] = [];
    if(tasks && isTasks) combined.push(...tasks);
    if(notes && isNotes) combined.push(...notes);
    return combined;
  }, [notes, tasks, isNotes, isTasks]);

  useEffect(() => {
    if(notes === null ){
      dispatch(loadNotes());
    }
    if(tasks === null ){
      dispatch(loadTasks());
    }
  }, []);

  return (
    <Grid container>
      
      <Grid container className={clsx(classes.cardHeader, otherClasses.toolbar)} justify="space-between" alignItems="center">
        <Grid item xs={4} container justify="flex-start" alignItems="center">
          <Typography className={clsx(classes.cardHeaderText, otherClasses.title)}>{"Notes & Tasks"}</Typography>
        </Grid>
        <Grid item xs={8} container justify="flex-end" alignItems="center">
          {adding && <Button onClick={() => setAdding(null)} size="small" className={classes.marginRight}>Close New</Button>}
          {adding !== "task" && <Button onClick={() => setAdding("task")} size="small" color="secondary" className={classes.marginRight}>New Task</Button>}
          {adding !== "note" && <Button onClick={() => setAdding("note")} size="small" color="secondary" className={classes.marginRight}>New Note</Button>}

          <ButtonGroup size="small" color="primary" aria-label="small outlined button group" className={classes.btnGroup}>
            <Tooltip title="Show / Hide tasks">
              <Button size="small" variant={isTasks ? "contained" : undefined} onClick={() => {setProps({isTasks: !isTasks, isNotes})}}>Tasks</Button>
            </Tooltip>
            <Tooltip title="Show / Hide notes">
              <Button size="small" variant={isNotes ? "contained" : undefined} onClick={() => {setProps({isNotes: !isNotes, isTasks})}}>Notes</Button>
            </Tooltip>
          </ButtonGroup>
        </Grid>
      </Grid>

      {adding && 
        <Grid container className={classes.addRow}>
          {adding === "task" && <TaskEditCard clientId={itemType === "client" ? itemId : undefined} projectId={itemType === "project" ? itemId : undefined} classes={{paper: classes.childPaper}}/> }
          {adding === "note" && <NoteEditCard  clientId={itemType === "client" ? itemId : undefined} projectId={itemType === "project" ? itemId : undefined} classes={{paper: classes.childPaper}}/> }
        </Grid>
      }

      <Grid container>
        {both?.map((item: any) => 
          <Grid key={item.id} item xs={12}>
            {item.dueDate ? 
              (taskId ? <TaskEditCard task={item as ITask}/> : <TaskViewCard task={item as ITask} />) : 
              (noteId ? <NoteEditCard note={item as INote}/> : <NoteViewCard note={item as INote} />) }
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default ItemNotesList;
