import React, { useMemo, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import Typography from "@material-ui/core/Typography";
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { fontStyles, generalStyles } from "utils/styles";
import { useLocalization, useProjects } from "utils/hooks";
import { useDispatch, useSelector } from "react-redux";
import { selectClientsWithProjects } from "features/clients/infra/client-selectors";
import { IacClient, IacProject, RootState } from "types";
import { orderBy } from "lodash";
import { hasActiveProject } from "features/clients/client-helpers";
import { updateNotesSettings } from "./infra/notes-slice";

const buildStyles   = makeStyles(theme => ({
  ...generalStyles(theme),
  ...fontStyles(theme),
  root  : {
    // padding     : theme.spacing(1),
    height: "100%",
  },
  headerRow: {
    height: theme.spacing(8),
    borderBottom: `1px solid ${theme.palette.grey[300]}`, //`
    padding: theme.spacing(1),
    background: theme.palette.common.white,
    "& h5": {
      marginBottom: 0,
    }
  },
  bodyRow: {
    padding: theme.spacing(1),
  },
  autoSelect: {
    "& .MuiFormControl-root": {
      marginBottom: 0,
    }
  }, 
  filterButton: {
    textTransform: "none",
    justifyContent: "left",
    fontWeight: 500,
    // border: `1px solid transparent`,
    padding: `3px 9px`, //`
    borderWidth: 1,
    marginBottom: theme.spacing(0.5),
  },
}));

const NotesSidebar = () => {
  const classes   = buildStyles();
  const { L } = useLocalization();
  const dispatch = useDispatch();
  const clients = useSelector(selectClientsWithProjects);
  const projects = useProjects("name");
  const {status, settings, isInitialized, isEmpty } = useSelector((state: RootState) => state.notes);
  const [myFilter, setMyFilter] = useState<{client: IacClient | null, project: IacProject | null}>({client: null, project: null});
  const isDisabled = useMemo(() => Boolean(!isInitialized || status.isWorking || isEmpty), [isInitialized, status.isWorking, isEmpty])

  const projectList = useMemo(() => {
    const available = myFilter.client ? projects.filter(p => p.clientId === myFilter.client?.id) : projects;
    const prjs = available.map(p => ({id: p.id, name: p.name, clientId: p.clientId, status: p.deliveredDate ? "Delivered" : "Active"}  as IacProject));    
    const ordered = orderBy(prjs, ["status"]);
    ordered.unshift({id: "none", name: "- No Project -", clientId: "", status: "Active"});
    return ordered;
  }, [myFilter]);

  const clientList = useMemo(() => {
    const clis = clients.map(c => ({id: c.id, name: c.name, status: hasActiveProject(c.projects) ? "Active" : "Not Active"} as IacClient));
    const ordered = orderBy(clis, ["status", "name"]);
    ordered.unshift({id: "none", name: "- No Client -", status: "Active"});
    return ordered;
  }, [clients]);

  async function onProjectChange(e: any, item: IacProject | null){
    let client = null;
    if(item && (!myFilter.client || item.clientId !== myFilter.client.id)) {
      client = clientList.find(c => c.id === item.clientId) || null;
    }
    setMyFilter({client, project: item});
    dispatch(updateNotesSettings({filter: {clientId: client?.id, projectId: item?.id}}))
  }

  async function onClientChange(e: any, item: IacClient | null){
    let project = myFilter.project;
    if(item && myFilter.project && myFilter.project.clientId !== item.id){
      project = null;
    }
    setMyFilter({client: item, project});
    dispatch(updateNotesSettings({filter: {clientId: item?.id, projectId: project?.id}}))
  }

  const onFilter = (status: null | "favorite") => async () => {
    dispatch(updateNotesSettings({filter: {...settings.filter, status: status}}))
  }
  
  return (
    <Grid id="task-sidebar" container alignContent="flex-start" className={classes.root}>
      
      <Grid container alignItems="center" justify="center" className={classes.headerRow}>
        <Typography variant="h5" color="primary" className={clsx(classes.pageHead, classes.primary)}>{L("notes", true)}</Typography>
      </Grid>

      <Grid container direction="column" justify="flex-start" className={classes.bodyRow}>

          <Button onClick={onFilter(null)} size="small" variant={!settings.filter?.status  ? "outlined" : undefined} color={!settings.filter?.status ? "secondary" : undefined} className={classes.filterButton}>All</Button>
          <Button onClick={onFilter("favorite")} size="small" variant={settings.filter?.status === "favorite" ? "outlined" : undefined} color={settings.filter?.status === "favorite" ? "secondary" : undefined} className={classes.filterButton}>Favorites</Button>
          {/* <Button onClick={onFilter("finished")} size="small" variant={settings.filter?.status === "finished" ? "outlined" : undefined} color={settings.filter?.status === "finished" ? "secondary" : undefined} className={classes.filterButton}>Finished</Button> */}

          <Divider variant="middle" className={classes.dividerTall} />

          <Autocomplete 
            autoComplete autoHighlight autoSelect
            id="client-list-filter" 
            options={clientList}
            value={myFilter.client}
            onChange={onClientChange} 
            getOptionLabel={o => o.name} 
            groupBy={o => o.status}
            className={classes.autoSelect} 
            fullWidth
            renderInput={(p) => <TextField {...p} placeholder="All Clients" margin="dense" variant="outlined" fullWidth/>}
            disabled={isDisabled}  
          />
        
          <Autocomplete 
            autoComplete autoHighlight autoSelect
            id="project-list-filter" 
            options={projectList}
            value={myFilter.project}
            onChange={onProjectChange} 
            getOptionLabel={o => o.name} 
            groupBy={o => o.status}
            className={classes.autoSelect} 
            fullWidth
            renderInput={(p) => <TextField {...p} placeholder="All Project" margin="dense" variant="outlined" fullWidth/>}
            disabled={isDisabled}  
          />

      </Grid>
    </Grid>
  );
}

export default NotesSidebar;
