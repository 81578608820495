import React from "react";
import { Route } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import Fade from '@material-ui/core/Fade';
import ProjectList from "./project-list";
import ProjectEditView from "./project-edit-view";
import ProjectViewVert from "./project-view-vert";
import { useClients, useLocalization, useProjects } from 'utils/hooks';
import { routes } from "features/app/app-routes";
import { LoadingIndicator } from "components";
import { Helmet } from "react-helmet-async";

const ProjectsView = () => {
  const projects = useProjects();
  const clients = useClients();
  const {LSwap} = useLocalization();
  const isReady = React.useMemo(() => { return Boolean(!!clients && !!projects); }, [projects, clients]);

  return (
    <>      
      {!isReady &&
        <LoadingIndicator isVisible={true} message="Loading..." />
      }
      {isReady &&
        <Fade in={isReady} timeout={250}>
          <Grid>
            <Helmet>
              <title>{LSwap("Gigops - Projects", "projects")}</title>
            </Helmet>
            <Route path={routes.projectEdit} exact component={ProjectEditView} />
            <Route path={routes.project} exact component={ProjectViewVert} />
            <Route path={routes.projects} exact component={ProjectList} />
          </Grid>
        </Fade>
      }
    </>
  );
};

export default ProjectsView;
