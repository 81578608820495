import firebase from "firebase/app";
import "firebase/firestore";
import _ from "lodash";
import { ITask, QueryFilter } from "types";
import { COLS, STATUSES, IFirestoreResult, collectionRef, arrayResponse, inflateCollection } from "utils/firebase";
import { prepareForDb } from "utils/general-helpers";

export const taskCollections = {
  TASKS: "tasks",
};

const WHITELIST_PROPS = ["createdDate", "content", "clientId", "projectId", "uid", "dueDate", "isFinished", "lastUpdatedDate"];

export const tasksApi = (db: firebase.firestore.Firestore) => ({

  // Gets the list of tasks for the specified user
  getTasks: async (orgId: string, startDate: Date | null = null, endDate: Date | null = null, filter: QueryFilter | QueryFilter[] | null = null): Promise<IFirestoreResult> => {
    const coll =  collectionRef(db, orgId, COLS.TASKS);
    let snapshot: firebase.firestore.QuerySnapshot | null = null;

    //add the start and end dates to the filter collection if necessary
    let allFilters = filter ? (_.isArray(filter) ? filter : [filter]) : [];
    if(startDate || endDate){
      if(endDate)  allFilters = [{field: "dueDate", operator: "<", value: endDate}, ...allFilters];
      if(startDate) allFilters = [{field: "dueDate", operator: ">=", value: startDate}, ...allFilters];
    }

    if(allFilters.length){
      const f1 = allFilters[0];
      let query = coll.where(f1.field, f1.operator, f1.value)
      if(allFilters.length > 1){
        for(let i = 1; i < allFilters.length; i++){
          const f = allFilters[i];
          query = coll.where(f.field, f.operator, f.value);
        }
      }
      snapshot = await query.get();
    }
    else{
      snapshot = await coll.get();
    }    

    //Convert the snapshot into the collection of items
    const items = inflateCollection<ITask>(snapshot);
    
    return arrayResponse(items, orgId);
  },

  //---
  // Creates a new task
  createTask: async (orgId: string, task: Partial<ITask>): Promise<IFirestoreResult> => {
    const id = task.id;
    const safeTask = {...prepareForDb(task, null, null, WHITELIST_PROPS), createdDate: new Date()};
    const coll = collectionRef(db, orgId, COLS.TASKS);
    let taskId = "";
    
    if(id){
      const doc = coll.doc(id);
      await doc.set(safeTask);
      taskId = id;
    }
    else{
      const result = await coll.add(safeTask);
      taskId = result.id;
    }

    return {
      ok: true,
      statusCode: STATUSES.ok,
      key: taskId
    };
  },

  //---
  // Updates an existing task
  updateTask: async (orgId: string, taskId: string, changes: Partial<ITask>): Promise<IFirestoreResult> => {
    const safeChanges = prepareForDb(changes, null, null, WHITELIST_PROPS);
    const existing = collectionRef(db, orgId, COLS.TASKS).doc(taskId);
    await existing.update({...safeChanges, lastUpdatedDate: new Date()});

    return {
      ok: true,
      statusCode: STATUSES.ok,
      key: taskId
    };
  },

  //---
  // Deletes a task
  deleteTask: async (orgId: string, taskId: string): Promise<IFirestoreResult> => {
    const task = collectionRef(db, orgId, COLS.TASKS).doc(taskId);
    await task.delete();
    return {
      ok: true,
      statusCode: STATUSES.ok,
      key: taskId
    };
  }
})