import _ from "lodash";
import moment from "moment";
import { addMonths, isSameMonth, isSameYear, isThisYear } from "date-fns";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store/root-reducer";
import { Invoice, InvoiceWithProject, } from "types";
import { filterProjects, sumProjects } from "features/projects";
import { formatCurrency } from "utils/number-helpers";
import { formatDate, getMonthEndDates, getMonthStarts } from "utils/date-helpers";
import { filterInvoices } from "./invoice-helpers";
import { selectActualInvoices, selectAllClients, selectAllInvoices, selectAllProjects, selectProjectsWithActualInvoices } from "features/app/infra/common-selectors";

export { selectActualInvoices, selectAllInvoices } from "features/app/infra/common-selectors";
const _getClients = (state: RootState) => state.clients.items;
const _getProjects = (state: RootState) => state.projects.items;
const _getInput = (state: RootState, input: any) => input;
const _getItemQuery = (state: RootState, key: string) => state.invoices.itemQueries[key];
const _getFilter = (state: RootState) => state.invoices.settings.filter;
const _getSearch = (state: RootState) => state.invoices.settings.search;

export const selectInvoicesWithProjects = createSelector(
  selectAllProjects, selectAllInvoices, selectAllClients,
  (projects, invoices, clients) => {
    if(!invoices || !projects) return [] as InvoiceWithProject[];
    const withProjects = invoices.map(inv => {
      const prj = projects.find(p => p.id === inv.projectId);
      const cli = clients.find(c => c.id === inv.clientId);
      
      return {
        ...inv,
        project: prj,
        client: cli,
      } as InvoiceWithProject;
    });
    return withProjects;
  }
)

export const selectFilteredInvoices = createSelector(
  selectInvoicesWithProjects, _getFilter, _getSearch,
  (invoices, filter, search) => {
    if(!invoices) return [];
    if(search){
      const searched = invoices.filter(inv => inv.number && inv.number.toLowerCase().indexOf(search.toLowerCase()) >= 0);
      return searched;
    }
    else if(filter){
      const filtered = filterInvoices(invoices, filter) as InvoiceWithProject[];
      return filtered;
    }
    else return invoices;
  }
);

export const selectInvoice = createSelector(
  _getInput, selectActualInvoices,
  (invoiceId, invoices) => {
    if(!invoiceId || !invoices) return null;
    const item = invoices.find(inv => inv.id === invoiceId);
    return item || null;
  }
);

export const selectInvoicesQuery = createSelector(
  _getItemQuery, _getProjects,
  (query, projects) => {
    if (!query) return null;
    if(!projects) return query.items;

    const invoices: Invoice[] = query.items || [];
    const prepared = invoices.map(inv => {
      const project = projects.find(p => p.id === inv.projectId);
      return {
        ...inv,
        project: project,
      };
    });

    return {
      items: prepared,
      props: query.props,
    };
  }
);

export const selectUnpaidInvoices = createSelector(
  selectAllInvoices,
  (invoices) => {
    if(!invoices) return null;
    const pendingInvoices = invoices.filter(inv => !inv.paidDate).map(inv => { return {invoiced: inv.invoiceDate, due: inv.dueDate, clientId: inv.clientId, projectId: inv.projectId, amount: inv.amount }; });
    return pendingInvoices;
  }
);

export const selectProjectedPayments = createSelector(
  selectUnpaidInvoices, _getClients,
  (pending, clients) => {
    if(!pending) return null;
    const overdue: any[] = [];
    const months = getMonthStarts(4, moment().add(3, "months"));
    const baseList = months.map(m => { return {month: formatDate(m), amount: 0}; });

    const projections = pending.reduce((list: {month: string; amount: number}[], item) => {
      
      const cli = clients ? clients.find(c => c.id === item.clientId) : null;
      const expectedDays = cli?.daysToPay || 30;
      let expectedDate = moment(item.invoiced).add(expectedDays, "days");
      if(expectedDate.isBefore(moment())){
        expectedDate = moment();
        overdue.push(item.amount);
      }

      const som = formatDate(expectedDate.startOf("month"));
      const month = list.find((a: any) => a.month === som);
      if(!month){
        list.push({month: som, amount: item.amount });
      }
      else{
        month.amount += item.amount;
      }

      return list;
    }, baseList);

    return {
      projections: projections,
      overdueCount: overdue.length,
      overdueAmount: _.sum(overdue),
    };
  }
);

export const selectWidgetData = createSelector(
  selectUnpaidInvoices, _getProjects, _getClients, selectProjectedPayments,
  (pending, projects, clients, projected) => {
    if(!pending || !clients) return null;

    const unpaidCount = pending.length;
    const unpaidAmount = _.sumBy(pending, pnd => pnd.amount);
    const chartData = projected ? projected.projections.map(p => { return [p.month, p.amount]; }) : [];

    const uninvoiced = projects ? filterProjects(projects, "uninvoiced") : [];
    const uninvAmount = sumProjects(uninvoiced, true);

    return {
      total: formatCurrency(unpaidAmount),
      count: unpaidCount,
      overdueTotal: formatCurrency(projected?.overdueAmount) || "",
      overdueCount: projected?.overdueCount || 0,
      uninvoicedTotal: uninvAmount,
      uninvoicedCount: uninvoiced.length,
      chartSeries: [{
        name: "projected",
        type: "bar",
        data: chartData,
      }]
    }
  }
);

interface StackedBar {
  name: string;
  data: [string, number][];
}

export const selectAllWidgetData = createSelector(
  selectAllInvoices, selectProjectsWithActualInvoices,
  (invoices, projects) => {
    if(!invoices) return null;
    const unpaid = invoices.filter(inv => !inv.paidDate);
    const thisYear = invoices.filter(inv => isThisYear(inv.invoiceDate as Date) || (inv.dueDate && isThisYear(inv.dueDate as Date)) || (inv.paidDate && isThisYear(inv.paidDate as Date)));
    const paidThisYear = invoices.filter(inv => inv.paidDate && isThisYear(inv.paidDate as Date));

    const uninvoiced = projects?.filter(prj => prj.deliveredDate && !(prj.invoicedDate || prj.lastInvoiceDate));

    const unpaidAmount = _.sumBy(unpaid, i => i.amount);
    const yearRevenue = _.sumBy(paidThisYear, p => p.amount);

    const months = getMonthEndDates(12, addMonths(new Date(), 1));
    const chartData = months.reduce<StackedBar[]>((result, month) => {
      const monthInv = thisYear.filter(inv => isSameMonth(month, inv.invoiceDate as Date));
      const monthInvoiced = _.sumBy(monthInv, i => i.amount);
      const monthPaid = _.sumBy(monthInv, i => i.paidDate ? i.amount : 0);
      const monthDue = monthInvoiced - monthPaid;
      result[0].data.push([month.toDateString(), monthPaid]);
      result[1].data.push([month.toDateString(), monthDue]);
      return result;
    }, [{name: "paid", data: []}, {name: "due", data: []}]);

    return {
      totalRevenue: yearRevenue,
      totalUnpaid: unpaidAmount,
      countUnpaid: unpaid.length,
      chartSeries: chartData,
      countUninvoiced: uninvoiced?.length || 0,
    };
  }
);

export const selectInvoiceStats = createSelector(
  selectAllInvoices,
  (invoices) => {
    //NOTE: invoices have actual Dates due to selectAllInvoices selector
    const today = new Date();
    const invoicedAllTime = _.sumBy(invoices, inv => inv.amount);
    const paidAllTime = _.sumBy(invoices.filter(inv => !!inv.paidDate), inv => inv.amount);
    const invoicedThisYear = _.sumBy(invoices.filter(inv => inv.invoiceDate && isSameYear(inv.invoiceDate as Date, today)), inv => inv.amount);
    const paidThisYear = _.sumBy(invoices.filter(inv => inv.paidDate && isSameYear(inv.paidDate as Date, today)), inv => inv.amount);
    const invoicedThisMonth = _.sumBy(invoices.filter(inv => inv.invoiceDate && isSameMonth(inv.invoiceDate as Date, today) && isSameYear(inv.invoiceDate as Date, today)), inv => inv.amount);
    const paidThisMonth = _.sumBy(invoices.filter(inv => inv.paidDate && isSameMonth(inv.paidDate as Date, today) && isSameYear(inv.paidDate as Date, today)), inv => inv.amount);
    const overdue = filterInvoices(invoices, "overdue"); // invoices.filter(inv => inv.invoicedDate && !inv.paidDate && isBefore(inv.dueDate as Date, today));
    const overdueAmount = _.sumBy(overdue, inv => inv.amount);
    // const threshold = addDays(today, 7);
    const dueSoon = filterInvoices(invoices, "due-soon"); // invoices.filter(inv => inv.invoicedDate && !inv.paidDate && isBefore(inv.dueDate as Date, threshold));
    const dueSoonAmount = _.sumBy(dueSoon, inv => inv.amount);

    return {
      invoicedAllTime,
      paidAllTime,
      invoicedThisYear,
      paidThisYear,
      invoicedThisMonth,
      paidThisMonth,
      overdueAmount,
      dueSoonAmount,
    };
  }
);
