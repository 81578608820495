import { tryParseInt, isStringNumeric } from "../../general-helpers";

//The supported dialogs
export type DialogKey = "client" | "project" | "invoice" | "timer" | "import" | "dashSettings" | "clientMulti" | "projectMulti";

//Converts the store string to an object
export function parseDialogStoreString(value: string | null): Record<DialogKey, unknown> {
  if (!value) return {} as Record<DialogKey, unknown>;

  const arr = value.split(",");
  const items = arr.reduce((result, item) => {
    const parts = item.split(":");
    let val: any = true;
    if (parts.length > 0) {
      val = parts[1];
      if (isStringNumeric(val)) {
        val = tryParseInt(parts[1], parts[1] || true);
      }
    }
    if (val === "true" || val === "false") val = Boolean(val);
    return {
      ...result,
      [parts[0]]: val,
    };
  }, {} as Record<DialogKey, unknown>);

  return items;
}

//converts the dialog string to an object
export function parseDialogString(value: string) {
  if (!value) return null;

  const arr = value.split(",");
  const items = arr.reduce((result, item) => {
    const parts = item.split(":");
    const val = parts.length > 0 ? tryParseInt(parts[1], parts[1]) : true;
    return {
      ...result,
      [parts[0]]: val,
    };
  }, {});

  return items;
}