import { IFirebaseConfig } from "./firebase-types";
import firebase from "firebase/app";

export const firebaseConfig: IFirebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  // measurementId       : process.env.REACT_APP_MEASURE_ID,
};

export const PROFILE_PROPS = ["uid", "displayName", "email", "photoURL"];

export const PROVIDERS = {
  google: () => { return new firebase.auth.GoogleAuthProvider(); },
  facebook: () => { return new firebase.auth.FacebookAuthProvider(); },
  twitter: () => { return new firebase.auth.TwitterAuthProvider(); },
  microsoft: () => { return new firebase.auth.OAuthProvider("microsoft.com"); },
};

export function mapLoginFailure(result: any) {
  if (!result) return null;
  switch (result.code) {
    case "auth/invalid-email": return "Please enter a valid email address";
    case "auth/user-not-found":
    default: return "Invalid username or password.";
  }
}