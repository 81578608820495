import React, { useMemo } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Icon from '@material-ui/core/Icon';
import { IconMenu } from "components";
import { IMenuItem } from "types";
import { useDialogs, useLocalization } from "utils/hooks";
import { generalStyles } from "utils/styles";
import { exportClientsToCsv } from "utils/export-helper";
import { selectFilteredClients } from "../infra/client-selectors";
import { useSelector } from "react-redux";

const buildStyles   = makeStyles(theme => ({
  ...generalStyles(theme),
  chipIcon: {
    height: 28,
    width: 28,
    border: "1px solid rgba(0,0,0,0.23)",
    "& .MuiIcon": {
      fontSize: 22,
    }
  },
}));

const ClientListMenu = () => {
  const classes   = buildStyles();
  const { LSwap } = useLocalization();
  const {openDialog} = useDialogs();
  const items = useSelector(selectFilteredClients);
  
  const configMenu: IMenuItem[] = useMemo(() => [
    { id: 10, label: "New Invoice", action: "invoice" },
    { id: 15, label: "New Clients (muti)", action: "multi-add" },
    { id: 99, isDivider: true },
    { id: 20, label: LSwap("Import Clients", "clients"), action: "import" },
    { id: 30, label: LSwap("Export Clients", "clients"), action: "export" },
  ], []);
  
  const onMenuItemClicked = (menuItem: IMenuItem) => {
    switch (menuItem.action) {
      case "multi-add": openDialog("clientMulti"); break;
      case "import": openDialog("import", "clients"); break;
      case "invoice": openDialog("invoice"); break;
      case "export": exportClientsToCsv(items); break;
    }
  }

  return (
    <>
      <IconMenu icon={<Icon>more_vert</Icon>} items={configMenu} title="More actions" onClick={onMenuItemClicked} classes={{button: clsx(classes.chipIcon, classes.marginLeft)}} />
    </>
  );
}

export default ClientListMenu;
