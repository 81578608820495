import _ from "lodash";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IProfileInfo, IOrg, defaultProfile, defaultOrg, PROFILE_WHITELIST, AppCustomizations, defaultAppCustomizations, ORG_WHITELIST } from "types";
import { useFirestore, STATUSES } from "utils/firebase";
import { AppThunk } from "store/root-reducer";
import { getChanges, datesToStrings, stringsToDates } from "utils/general-helpers";
import { IParserCollection, Parsers } from "utils/hooks";
import { IStatus } from "types/common-types";
import { loadClients } from "features/clients/clients-slice";
import { loadProjects } from "features/projects";
import { loadInvoices } from "features/invoices";
// import { IFirebaseProfile } from "utils/firebase/firebase-types";

type AppSettings = {
  isSidebarExpanded: boolean;
}

export const customizationParsers: IParserCollection = {
  defaultHourlyRate: Parsers.currency,
}

interface AppState {
  profile: IProfileInfo | null;
  org: IOrg;
  isInitialized: boolean;
  isAuthenticated: boolean;
  settings: AppSettings;
  status: IStatus;
  isOnboarding: boolean;
}

export const initialState: AppState = {
  profile: null,
  org: defaultOrg,
  isInitialized: false,
  isAuthenticated: false,
  settings: {
    isSidebarExpanded: true,    
  },
  status: {
    isWorking: false,
    error: null,
  },
  isOnboarding: false,
};

export const slice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setOnboarding: (state, action: PayloadAction<boolean>) => {
      state.isOnboarding = action.payload;
    },
    statusChanged: (state, action) => {
      state.status = {...state.status, ...action.payload};
    },
    authChanged: (state, action) => {
      const profile = action.payload?.profile ? { ...defaultProfile, ...action.payload?.profile } : null;
      state.profile = profile;
      state.org = action.payload?.org;
      state.isInitialized = true;
      state.isAuthenticated = !!profile;
    },
    updateAppSettings: (state, action) => {
      const updates = { ...state.settings, ...action.payload };
      state.settings = updates;
    },
    profileUpdated: (state, action) => {
      const existing = state.profile as any;
      _.keys(action.payload.changes).forEach(key => {
        existing[key] = action.payload.changes[key];
      });
    },
    orgUpdated: (state, action) => {
      const existing = state.org as any;
      _.keys(action.payload.changes).forEach(key => {
        existing[key] = action.payload.changes[key];
      });
    },
    customizationsUpdated: (state, action) => {
      if (state.org) state.org.customizations = action.payload.changes;
    },
  },
});

export const { setOnboarding, statusChanged, authChanged, updateAppSettings, profileUpdated, orgUpdated, customizationsUpdated } = slice.actions;
export default slice.reducer;

//-- Initializes the primary collections in the app
export const initializeApp = (): AppThunk => async (dispatch, getState) => {
  const [api, uid] = useFirestore();
  if (!uid || !api) throw new Error("User is not authorized.");

  const allState = getState();
  if(!allState.clients.isInitialized){
    dispatch(loadClients());
  }
  if(!allState.projects.isInitialized){
    dispatch(loadProjects());
  }
  if(!allState.invoices.isInitialized){
    dispatch(loadInvoices());
  }
}

//TODO: convert these to async thunks??
//-- update the user's profile
export const updateProfile = (changes: Partial<IProfileInfo>): AppThunk => async (dispatch, getState) => {
  const [api, uid] = useFirestore();

  if (!uid) throw new Error("User is not authorized.");
  const safeChanges = _.pick(changes, PROFILE_WHITELIST);

  //get the actual changes
  const profile = getState().app.profile;
  const actualChanges = getChanges(profile as any, safeChanges as any);

  if (actualChanges !== null) {
    const prepared = stringsToDates(actualChanges);
    const result = await api.updateProfile(uid, prepared);
    if (result.statusCode === STATUSES.ok) {
      dispatch(profileUpdated({ changes: datesToStrings(prepared) }));
      return uid;
    }
  }

  return null;
}

//-- update the user's profile
export const updateOrg = (changes: Partial<IOrg>): AppThunk => async (dispatch, getState) => {
  const [api, uid] = useFirestore();

  if (!uid) throw new Error("User is not authorized.");
  const safeChanges = _.pick(changes, ORG_WHITELIST);

  //get the actual changes
  const org = getState().app.org;
  const actualChanges = getChanges(org as any, safeChanges as any);

  if (!!org && actualChanges !== null) {
    // const prepared = datesToStrings(actualChanges);
    const result = await api.updateOrg(org.id, actualChanges);
    if (result.statusCode === STATUSES.ok) {
      const toStore = datesToStrings(actualChanges);
      dispatch(orgUpdated({ changes: toStore }));
      return uid;
    }
  }
  else{
    console.warn("no savable changes to the org.  Check the safeOrgFields if you believe changes should have been saved.");
  }

  return null;
}

//-- update the user's profile
export const updateCustomizations = (changes: Partial<AppCustomizations>): AppThunk => async (dispatch, getState) => {
  const [api, uid] = useFirestore();

  if (!uid) throw new Error("User is not authorized.");
  // const safeChanges = _.pick(changes, SafeProfileUpdateFields);

  //get the actual changes
  const org = getState().app.org;
  if (!!org) {
    const existing = org?.customizations;
    const union = { ...existing, ...changes };   //get the union
    const actual = getChanges(defaultAppCustomizations, union);
    // const prepared = actual ? actual : actual;

    const result = await api.updateOrg(org.id, { customizations: actual });
    if (result.statusCode === STATUSES.ok) {
      dispatch(customizationsUpdated({ changes: actual }));
      return uid;
    }
  }
  else {
    console.error("Org doesn't exist in store");
  }

  return null;
}