import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import GoalCard, { getGoalCardSize } from "./goal-card";
import { useSelector } from "react-redux";
import GoalsWelcome from "./goals-welcome";
import { RootState } from "types";
import { useGoals } from "utils/hooks";

const buildStyles   = makeStyles(theme => ({
  root  : {
    // padding     : theme.spacing(1),
  },
  title   : {
    fontSize    : 22,
    fontWeight  : 500,
    textAlign   : "center",
    marginBottom: theme.spacing(1),
  },
  subTitle  : {
    fontSize  : 17,
    fontWeight: 500,
    textAlign : "center",
    marginBottom  : theme.spacing(3),
    color     : theme.palette.grey[800],
  },
  section: {
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    width: "25%",
    marginBottom: theme.spacing(3),
  }
}));

interface ControlProps{
  onAdd: () => void;
}

const CurrentGoalsView = ({onAdd}: ControlProps) => {
  const classes   = buildStyles();
  const isInitialized = useSelector((state: RootState) => state.goals.isInitialized);
  const goals = useGoals();
  
  const buttonMessage = (goals.length > 0 ? "Set more goals" : "Start");

  return (
    <Grid container className={classes.root} justify="center">
      <Grid container spacing={2} className={classes.section}>
        {
          goals?.map(g => {
            const size = getGoalCardSize(g.definition?.size);
            return (
              <Grid key={g.id} item lg={size.lg} md={size.md} sm={size.sm} xs={size.xs}>
                <GoalCard goal={g} />
              </Grid>
            );
          })
        }
      </Grid>
      {goals.length > 0 && <Divider className={classes.divider}/>}
      <Grid container justify="center">
        <GoalsWelcome />
        {isInitialized && <Button onClick={onAdd} color="primary">{buttonMessage}</Button>}
      </Grid>
    </Grid>
  );
}

export default CurrentGoalsView;
