import { isSameMonth, startOfDay, startOfMonth } from "date-fns";
import { metricChartOptions, metricDonutOptions } from "./metric-chart-helpers";
import { compact, orderBy, sumBy, uniq } from "lodash";
import { Invoice, IProject, IClient, DateRange, IProjectSummary } from "types";
import { isInRange } from "utils/date-helpers";
import { formatCurrency } from "utils/number-helpers";
import { getActiveClients, getActiveProjects, getProjectsWithHourlyRate, UNCATEGORIZED } from "./metric-calcs-common";

export const ClientMetrics = [
  {
    id: "clients-count",
    category: "clients",
    period: ["year"],
    label: "Total",
    description: "The total number of clients you've worked with",
    why: "",
  },
  {
    id: "clients-active",
    category: "clients",
    period: ["year", "month"],
    label: "Active",
    description: "The total number of clients you've worked with ~period~",
    why: "",
  },
  {
    id: "clients-avg-projects",
    category: "clients",
    period: ["year"],
    label: "Average Projects",
    description: "The average number of projects you have worked on per client ~period~",
    why: "",
  },
  {
    id: "clients-avg-invoiced",
    category: "clients",
    period: ["year"],
    label: "Average client revenue",
    description: "The average amount you've invoiced each client ~period~",
    why: "",
  },
  {
    id: "clients-bycategory-rate",
    category: "clients",
    period: ["year"],
    label: "Rate by Category",
    description: "Your hourly rate by client category ~period~",
    why: "",
  },
  {
    id: "clients-category-count",
    category: "clients",
    period: ["year"],
    label: "Categories",
    description: "The number of different client categories you have worked for ~period~",
    why: "",
  },
];

export const calcClientCount = (clients: IClient[], monthEnds: Date[]) => {
  const value = clients.length;

  let total = 0//clients.filter(c => !c.createdDate).length;  //start with all clients that don't have a created date
  const chartData = monthEnds.map(endDate => {
    const monthClients = clients.filter(c => !!c.createdDate && isSameMonth(c.createdDate as Date, endDate));
    total += monthClients.length;
    return [startOfDay(endDate), total];
  });

  return {
    value,
    displayValue: value.toString(),
    chartData: [{
      name: "Total Clients",
      type: "area",
      data: chartData,
    }],
    chartOptions: metricChartOptions("count", "default"),
    chartType: "area",
  }
};

export const calcActiveClients = (range: DateRange, clients: IClient[], projects: IProject[], monthEnds: Date[]) => {
  const matches = getActiveClients(range, clients, projects);
  const value = matches.length;

  const chartData = monthEnds.map(endDate => {
    const start = startOfMonth(endDate);
    const prjMatches = getActiveProjects<IProject>({start: start, end: endDate}, projects);
    const uniqClients = uniq(prjMatches.map(p => p.clientId));
    return [startOfDay(endDate), uniqClients.length];
  });

  return {
    value,
    displayValue: value.toString(),
    chartData: [{
      name: "Active Clients",
      type: "bar",
      data: chartData,
    }],
    chartOptions: metricChartOptions("count", "default"),
    chartType: "bar",
  }
};

export const calcProjectsPerClient = (range: DateRange, clients: IClient[], projects: IProject[]) => {
  const activeProjects = getActiveProjects<IProject>(range, projects);
  const matches = compact(clients.map(client => {
    const prjMatches = activeProjects.filter(p => p.clientId === client.id);
    return prjMatches.length;
  }));

  const value = sumBy(matches, m => m) / (matches.length || 1);
  return {
    value,
    displayValue: value.toFixed(1),
  }
};

//"clients-category-count"
export const calcClientCategories = (range: DateRange, clients: IClient[], projects: IProject[]) => {
  const activeClients = getActiveClients(range, clients, projects);
  let uncategorized = activeClients.length;
  const matches = uniq(compact(activeClients.map(c => c.category?.toLowerCase())));
  const chartData = matches.map(category => {
    const cliMatches = activeClients.filter(c => c.category?.toLowerCase() === category);
    const count = cliMatches.length;
    uncategorized -= count;
    return count;
  });
  matches.push("Uncategorized");
  chartData.push(uncategorized);

  const value = matches.length;
  const result = {
    value,
    displayValue: value.toString(),
    chartData: chartData,
    chartOptions: metricDonutOptions(matches),
    chartType: "donut",
  };

  return result;
};

export const calcClientAvgRevenue = (range: DateRange, clients: IClient[], invoices: Invoice[]) => {
  const matches = compact(clients.map(client => {
    const invMatches = invoices.filter(p => p.clientId === client.id && (isInRange(p.invoiceDate, range)));
    const total = sumBy(invMatches, inv => inv.amount);
    return total;
  }));

  const value = sumBy(matches, m => m) / (matches.length || 1);
  return {
    value,
    displayValue: formatCurrency(value),
  }
}

//"clients-bycategory-rate"
export const calcClientRateByCategory = (range: DateRange, clients: IClient[], projects: IProject[], hours: IProjectSummary[]) => {
  if(!hours || hours.length === 0) return { value: -999, displayValue: "n/a" };

  const hourlyProjects = projects.filter(prj => prj.trackedMinutes && prj.deliveredDate);  
  const activeProjects = getActiveProjects<IProject>(range, hourlyProjects);
  if(!activeProjects || activeProjects.length === 0){
    return {
      value: -998,
      displayValue: "no projects to evaluate",
    };
  }
  
  const clientIds = uniq(activeProjects.map(m => m.clientId));
  const matches = compact(clientIds.map(id => clients.find(c => c.id === id)));

  const categories = uniq(matches.map(m => m.category || UNCATEGORIZED));
  const catRates = orderBy(categories.map(category => {
    const catClients = clients.filter(c => (c.category ?? UNCATEGORIZED) === category);
    const catProjects = activeProjects.filter(p => catClients.findIndex(cc => cc.id === p.clientId) >= 0);
    //get hourly rates, filtering out any with no hourly...
    const rates = getProjectsWithHourlyRate(catProjects, hours);
    const avg = rates.length ? sumBy(rates, r => r[1]) / (rates.length) : 0;
    return [category, avg] as [string, number];
  }), [cr => cr[1]], ["desc"]); //.filter(cr => cr[1] !== 0);

  const value = catRates[0][1];
  const sValue = catRates[0][0];

  const cats = catRates.map(cr => cr[0]);
  const rates = catRates.map(cr => cr[1]);

  const result = {
    value,
    displayValue: `${formatCurrency(value)} / hr`,
    subValue: sValue,
    displaySubValue: sValue,
    chartData: [{
      name: "Rate",
      type: "bar",
      data: rates,
    }],
    chartOptions: metricChartOptions("currency", "default", "bar", undefined, cats),
    chartType: "bar",
  };
  
  return result;
}