import React from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { INotify, LabelType, LFunc, LSwapFunc } from "types";
import { defaultLabels, LFactory, LSwapFactory } from "utils/localization";
import { selectAllCustomizations } from "./infra/app-selectors";
import { getSeverityBorder } from "utils/general-helpers";
import AtkDialogProvider from "components/dialogs/dialog-provider";

export type SnackSeverity = "error" | "info" | "success" | "warning" | undefined;
export type CreateNotification = (notification: INotify | string, severity?: SnackSeverity) => void;

export interface IAppContext {
  notify: CreateNotification;
  notification: INotify | null;
  L: LFunc;
  LSwap: LSwapFunc;
}

export const AppContext = React.createContext<IAppContext>({
  notify: () => { console.error("notify doesn't have a context.") },
  notification: null,
  L: (key: keyof LabelType, isCapitalized?: boolean) => { return `<context missing>, ${key} ${isCapitalized}` },
  LSwap: (str: string, keys?: (keyof LabelType) | (keyof LabelType)[]) => { return `<context missing>, ${str} ${keys}` },
});

const useStyles = makeStyles(theme => ({
  alert: (props: any) => ({
    width: "100%",
    border: "1px solid",
    borderColor: getSeverityBorder(props.severity, theme.palette),
  }),
}));

//====
// The Context HOC for the app.
const AtkApp = ({ children }: any) => {
  const [notification, setNotification] = React.useState<INotify | null>(null);
  const classes = useStyles({ severity: notification?.severity });
  const customizations = useSelector(selectAllCustomizations); //(state: RootState) => state.app.customizations);
  const labels = React.useMemo<LabelType>(() => {
    return { ...defaultLabels, ...customizations };
  }, [customizations]);

  const L = React.useMemo<LFunc>(() => {
    return LFactory(labels)
  }, [labels]);
  const LSwap = React.useMemo<LSwapFunc>(() => {
    return LSwapFactory(labels)
  }, [labels]);
  const onCloseNotification = () => {
    setNotification(null);
  }

  const onNotify = (notification: INotify | string, severity?: SnackSeverity) => {
    if (notification) onCloseNotification();
    if (_.isString(notification)) {
      setNotification({ message: notification, severity: severity });
    }
    else {
      setNotification(notification);
    }
  }

  const contextValue = {
    notify: onNotify,
    notification: notification,
    L: L,
    LSwap: LSwap,
  };

  return (
    <AppContext.Provider value={contextValue}>
      <AtkDialogProvider>
        <Grid id="app-context-grid" container>

          {children}

          <Snackbar open={!!notification} autoHideDuration={3500} onClose={onCloseNotification}>
            <Alert severity={notification?.severity} onClose={onCloseNotification} className={classes.alert}>
              {notification?.message}
            </Alert>
          </Snackbar>
        </Grid>
      </AtkDialogProvider>
    </AppContext.Provider>
  )

};

export default AtkApp;