import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import IconButton from '@material-ui/core/IconButton';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import ArchiveIcon from '@material-ui/icons/ArchiveOutlined';
import Icon from '@material-ui/core/Icon';
import { RowProps, HeadCell } from "components/atk-table";
import { IProject, IAlert, LFunc, IClient } from "types";
import { formatDate } from "utils/date-helpers";
import { getProjectAlerts, hasProjectAlert, formatFee } from "../infra/project-helper";
import { Icons } from "utils/common-classes";
import { useHover } from "utils/hooks";
import { getRoute } from "features/app";

export interface IProjectRow extends IProject {
  clientName?: string;
  status?: any;
  client?: IClient;
}

export const projectHeader = (L: LFunc): HeadCell<IProjectRow>[] => [
  { id: 'name', label: 'Name' },
  { id: "status", label: "", },
  { id: 'clientName', label: L("client", true) },
  { id: 'fee', align: "right", label: 'Fee/Rate' },
  { id: "startDate", label: L("projectStart", true) },
  { id: "dueDate", label: L("projectDue", true) },
  { id: "deliveredDate", label: L("projectDelivered", true), },
  { id: "invoicedDate", label: L("projectInvoiced", true), },
  { id: "paidDate", label: L("projectPaid", true), },
  { id: "notes", label: "", noSort: true, padding: "none"},
  { id: "actions", label: "", noSort: true },
];

const getAlertIcon = (item: IProject, alerts: IAlert[], condition: (prj: IProject, alerts: IAlert[]) => boolean, color: "primary" | "secondary" | "error", action: any, tooltip: string) => {
  if (condition(item, alerts)) {
    return (
      <Tooltip title={tooltip}>
        <IconButton size="small" onClick={action}>
          <InfoIcon fontSize="small" color={color} />
        </IconButton>
      </Tooltip>
    );
  }

  return null;
}

const getAlertIcon2 = (item: IProject, alerts: IAlert[], toMatch: string, color: "primary" | "secondary" | "error", action: any) => {
  const alert = alerts.find(al => al.id === toMatch);
  if (alert) {
    return (
      <Tooltip title={alert.message}>
        <IconButton size="small" onClick={action}>
          <InfoIcon fontSize="small" color={color} />
        </IconButton>
      </Tooltip>
    );
  }

  return null;
}

const doneIcon = (date: Date | string | null | undefined, showDate = false, altIcon: string | null = null, altTip: string | null = null) => {
  if (!date) return null;
  else if (showDate === true) {
    return formatDate(date);
  }

  const tip = altTip || formatDate(date);

  return (
    <Tooltip title={tip}>
      <Icon color="primary" fontSize="small">{altIcon || "done"}</Icon>
    </Tooltip>
  );
}

function FeeCell({project}: {project: IProjectRow}){
  const [isAlt, setAlt] = React.useState(false);
  const fee = formatFee(project, isAlt);
  return (<TableCell align="right" onClick={() => setAlt(!isAlt)} style={{cursor: "pointer"}}>{fee}</TableCell>);
}

function PaidCell({project, needsPayment, showDates}: {project: IProjectRow; needsPayment: any; showDates: boolean}){
  //either there is a paidDate, or there's a lastInvoicedDate & lastPaymentDate and the lastPaymentDate is more recent
  // const isPaid = !!project.paidDate || (!!project.lastPaymentDate && !!project.lastInvoiceDate && project.lastPaymentDate > project.lastInvoiceDate);
  const paidDate = project.paidDate ?? project.lastPaymentDate;
  
  let icon = null;
  let tip = null;
  if(!!project.paidDate){
    icon = "done";
  }
  else if(!!project.lastPaymentDate && !!project.lastInvoiceDate && project.lastPaymentDate < project.lastInvoiceDate){
    icon = "outlined_flag";
    tip = `Last payment: ${formatDate(project.lastPaymentDate)}, however there is an open invoice.`;
  }

  return (
    <TableCell>
      {doneIcon(paidDate, showDates, icon, tip) || needsPayment}
    </TableCell>
  );
}

const ProjectRow: FunctionComponent<RowProps<IProjectRow>> = ({ item, isSelected, onSelected, CheckboxCell, labelId, extra }) => {
  const url = React.useMemo(() => getRoute("project", item.id), [item]); //`
  const cliUrl = React.useMemo(() => item.clientId === "" ? null : getRoute("client", item.clientId), [item]); //`
  const alerts = getProjectAlerts(item);
  const [hoverRef, isHovered] = useHover();

  const needsDue = getAlertIcon(item, alerts, p => !p.dueDate, "secondary", extra.openDue("project", item.id), "This project doesn't have a due date");
  const dueSoon = getAlertIcon(item, alerts, (p, alts) => hasProjectAlert(alts, "due-soon"), "secondary", extra.openDelivered("project", item.id), "This project is due soon");
  const overdue = getAlertIcon(item, alerts, (p, alts) => hasProjectAlert(alts, "overdue"), "error", extra.openDelivered("project", item.id), "This project is overdue");
  const needsInvoice = getAlertIcon(item, alerts, (p, alts) => hasProjectAlert(alts, "to-invoice"), "secondary", extra.openInvoiced("project", item.id), "This project has not been invoiced");
  const needsPayment = getAlertIcon2(item, alerts, "not-paid", "error", extra.openPaid("project", item.id));

  const statusIcon = item.isArchived ? <Tooltip title="Archived"><ArchiveIcon fontSize="small" /></Tooltip> : null;
    
  return (
    <TableRow ref={hoverRef} hover role="checkbox" aria-checked={isSelected} tabIndex={-1} selected={isSelected} onClick={onSelected}>
      {CheckboxCell}
      <TableCell component="th" id={labelId} scope="row" padding="default">
        <Link to={url}>{item.name}</Link>
      </TableCell>
      <TableCell>{statusIcon}</TableCell>
      <TableCell>
        {cliUrl && <Link to={cliUrl}>{item.clientName}</Link>}
        {!cliUrl && item.clientName}
      </TableCell>
      <FeeCell project={item} />
      <TableCell>
        {formatDate(item.startDate)}
      </TableCell>
      <TableCell>
        {item.dueDate ? formatDate(item.dueDate) : needsDue}
      </TableCell>
      <TableCell>
        {doneIcon(item.deliveredDate, extra.showDates) || (overdue || dueSoon)}
      </TableCell>
      <TableCell>
        {doneIcon((item.invoicedDate || item.lastInvoiceDate), extra.showDates) || needsInvoice}
      </TableCell>
      <PaidCell project={item} needsPayment={needsPayment} showDates={extra.showDates}/>
      <TableCell align="center" valign="middle">
        {item.notes &&
          <Tooltip title={item.notes}>
            <Icon fontSize="small" color="disabled">comment</Icon>
          </Tooltip>
        }
      </TableCell>
      <TableCell align="center" valign="middle">
        {isHovered && <IconButton size="small" component={Link} to={getRoute("projectEdit", item.id)}>{Icons.edit("small")}</IconButton>}
      </TableCell>
    </TableRow>
  );
}

export default ProjectRow;
