import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import { RootState } from "types";
import { fontStyles, formStyles, generalStyles } from "utils/styles";
import { useDispatch, useSelector } from "react-redux";
import { LoadingIndicator } from "components";
import { EVENTS, trackEvent } from "utils/mixpanel";
import { createSampleData } from "./infra/onboarding-actions";
import { IStepProps } from "./onboarding-view";

const buildStyles   = makeStyles(theme => ({
  ...generalStyles(theme),
  ...formStyles(theme),
  ...fontStyles(theme),
  root  : {
    // padding     : theme.spacing(1),
    marginBottom: theme.spacing(2),
    height: "100%",
  },
  emphasis: {
    fontSize: 18,
    fontWeight: 500,
    textAlign: "center",
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(1),
  },
  body: {
    fontSize: 16,
    fontWeight: 400,
    textAlign: "center",
  },
  cardBody: {
    paddingTop: theme.spacing(2),
    // height: `calc(100% - ${theme.spacing(13)}px)`,
    // [theme.breakpoints.down("xs")]: {
    //   height: "auto",
    // }
  },
  centerCol: {
    margin: `${theme.spacing(2)}px ${theme.spacing(0)}px`, //`
  },
  content: {
    padding: `${theme.spacing(0)}px ${theme.spacing(4)}px`, //`
  },
}));


const OnboardingGenerate = ({onNavStateChange}: IStepProps) => {
  const classes   = buildStyles();
  const dispatch = useDispatch();
  const isOnboarding = useSelector((state: RootState) => state.app.isOnboarding);
  const [message, setMessage] = useState("Generating data...");
  const [isFinished, setIsFinished] = useState(false);

  useEffect(() => {
    onNavStateChange({canPrevious: true, canNext: false, hideNext: true, hidePrevious: false, previousTitle: "Previous", nextTitle: "Next", nextOverride: null});
  }, []);

  const onGenerate = async () => {
    trackEvent(EVENTS.sample, "generate-data");    //track that they want to generate sample data
    onNavStateChange({canPrevious: false, canNext: false, hideNext: true, hidePrevious: true, nextOverride: null});

    await dispatch(createSampleData(setMessage));
    setMessage("Finishing up...");
    setIsFinished(true);
  }

  const onRefresh = () => {
    window.location.reload();
  }

  return (
    <Grid item container direction="column" className={classes.root}>
      <Grid container direction="column" alignItems="center" className={clsx(classes.mbl, classes.mtl)}>
        <Typography color="primary" className={classes.pageHead}>Generate Sample Data</Typography>
        <Typography color="secondary" className={classes.subTitle}>Try GigOps out with one click</Typography>
      </Grid>
      <Grid container direction="column" alignItems="center" className={classes.cardBody}>
        {!isOnboarding && !isFinished &&
          <Grid item container className={classes.centerCol}>
            <Grid container direction="column" justify="space-between" alignItems="center" className={classes.content}>
              <Typography className={clsx(classes.body, classes.mtl, classes.mbd, classes.marginX)}>{"Clicking the button below will generate sample clients and projects for you to experiment with."}</Typography>
              <Typography className={clsx(classes.body, classes.mtd, classes.mbl, classes.marginX)}>{"Once you're ready, one click will bring you back here to remove the sample data and begin with your real data."}</Typography>
              <Button onClick={onGenerate} disabled={isOnboarding} variant="outlined" color="primary" className={clsx([classes.actionButton, classes.mtxl, classes.marginX])}>Generate Sample Data</Button>
            </Grid>
          </Grid>
        }
        {isOnboarding && !isFinished &&
          <LoadingIndicator isVisible={true} message={message} />
        }
        {isOnboarding && isFinished &&
          <>
            <Typography variant="h3" className={classes.emphasis}>All Set!</Typography>
            <Typography variant="h3" className={clsx(classes.body, classes.mbl)}>{"Click the button below, or press your browser's refresh button to get started."}</Typography>
            <Button onClick={onRefresh} variant="outlined" color="primary" className={clsx([classes.actionButton, classes.marginX, classes.mtxl])}>Get Started</Button>
          </>
        }
      </Grid>
    </Grid>
  );
}

export default OnboardingGenerate;
