import React from "react";
import clsx from "clsx";
import { useParams, Link, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';
import { fontStyles, generalStyles, iconStyles } from "utils/styles";
import { useClient, useIsSidebarExpanded, useLocalization } from "utils/hooks";
import { Icons, IconNames } from "utils/common-classes";
import { getRoute } from "features/app";
import ClientScore from "./client-score";
import ClientGoals from "./client-goals";

const buildStyles   = makeStyles(theme => ({
  ...generalStyles(theme),
  ...fontStyles(theme),
  ...iconStyles(theme),
  root  : {
    padding: theme.spacing(2),
  },
  headerRow: {
    height: theme.spacing(7),
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    width: `calc(100% + ${theme.spacing(4)}px)`,
    padding: theme.spacing(1),
    paddingTop: 0,
    borderBottom: `1px solid ${theme.palette.grey[300]}`, //`
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    }
  },
  actionsRow: {
    background: theme.palette.grey[50],
    borderBottom: `1px solid ${theme.palette.grey[300]}`, //`
    padding: `${theme.spacing(0.5)}px ${theme.spacing(0)}px`, //`
    marginLeft: theme.spacing(-2),
    width: `calc(100% + ${theme.spacing(4)}px)`,
  },
  itemsGrid: {
    overflowX: "hidden",
  },
  goalsRow: {
    marginTop: theme.spacing(-3),
  }
}));

// interface ControlProps {
//   onNav: (view: string) => void;
// }

//=== This is the client sidebar for the standard view (not displayed in mobile view)
const ClientSidebar = () => {
  const isSidebarExpanded = useIsSidebarExpanded();
  const classes = buildStyles({isSidebarExpanded});
  const history = useHistory();
  const { clientId } = useParams<{ clientId: string }>();
  const client = useClient(clientId);
  const { L } = useLocalization();

  if(!client) return null;
  
  return (
    // <Drawer variant="permanent" classes={{paper: classes.clientDrawer}}>
      <Grid id="client-sidebar" container direction="column" className={classes.root}>
        
        <Grid container wrap="nowrap" className={clsx(classes.headerRow)}>
          <Tooltip title={L("client", true)}>
            <span className={clsx(classes.iconLeft, classes.grey)}>{Icons.client()}</span>
          </Tooltip>
          <Typography className={classes.title}>{client?.name}</Typography>
        </Grid>

        <Grid container justify="center" className={clsx(classes.actionsRow, classes.mbl)}>
          <Tooltip title="Go back">
            <IconButton onClick={() => history.goBack()}><Icon>arrow_back</Icon></IconButton>
          </Tooltip>
          <Tooltip title="Edit">
            <IconButton component={Link} to={getRoute("clientEdit", client.id)}>{Icons.edit()}</IconButton>
          </Tooltip>
          <Tooltip title="Create an Invoice">
            <IconButton component={Link} to={getRoute("invoiceNew", "-1", `clientId=${client.id}`)}>{Icons.invoice()}</IconButton>
          </Tooltip>        
        </Grid>

        <Grid container justify="center" className={classes.mbl}>
          <ClientScore client={client}/>
          <Grid container className={classes.goalsRow}>
            <ClientGoals client={client} />
          </Grid>
        </Grid>

        <Grid container direction="column" wrap="nowrap" className={clsx(classes.mt, classes.mbl, classes.itemsGrid)}>
          {client.category &&
            <Grid container className={classes.mb} wrap="nowrap">
              <Icon fontSize="small" className={clsx(classes.lightGrey, classes.iconLeft)}>bookmark_border_outlined</Icon>
              <Typography className={clsx(classes.bodyTextSmall, classes.grey)}>{client.category}</Typography>
            </Grid>
          }
          {client.contactName && 
            <Grid container className={classes.mb} wrap="nowrap">
              <Icon fontSize="small" className={clsx(classes.lightGrey, classes.iconLeft)}>person_outlined</Icon>
              <Typography className={clsx(classes.bodyTextSmall, classes.grey)}>{client.contactName}</Typography>
            </Grid>
          }
          {client.contactEmail &&
            <Grid container className={classes.mb} wrap="nowrap">
                <Icon fontSize="small" className={clsx(classes.lightGrey, classes.iconLeft)}>mail_outlined</Icon>
                <a href={`mailto:${client.contactEmail}`} className={classes.link}>
                  <Typography className={clsx(classes.bodyTextSmall, classes.grey)}>{client.contactEmail}</Typography>
                </a>
            </Grid>
          }
          {client.contactPhone && 
            <Grid container className={classes.mb} wrap="nowrap">
              <Icon fontSize="small" className={clsx(classes.lightGrey, classes.iconLeft)}>phone_outlined</Icon>
              <Typography className={clsx(classes.bodyTextSmall, classes.grey)}>{client.contactPhone}</Typography>
            </Grid>
          }
          {client.address && 
            <Grid container className={classes.mb} wrap="nowrap">
              <Icon fontSize="small" className={clsx(classes.lightGrey, classes.iconLeft)}>business</Icon>
              <Typography className={clsx(classes.bodyTextSmall, classes.grey, classes.preWrap)}>{client.address}</Typography>
            </Grid>        
          }
        </Grid>

        {client.notes && 
          <Grid container direction="column" className={classes.mbl} wrap="nowrap">
            <Grid container className={classes.mbd}>
              <Icon fontSize="small" className={clsx(classes.lightGrey, classes.iconLeft)}>{IconNames.notes}</Icon>
              <Typography className={classes.infoText}>Notes</Typography>
            </Grid>
            <Typography className={clsx(classes.bodyTextSmall, classes.preWrap)}>{client.notes}</Typography>
          </Grid>
        }
      </Grid>
    // </Drawer>
  );
}

export default ClientSidebar;
