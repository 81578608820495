import * as yup from "yup";
import { IHierarchicalEntity, ISort } from "./common-types";
import { IProject, ProjectType } from "./project-types"

export type ClientFilter = "active" | "all" | "archived" | "unarchived" | null;

export interface ClientSettings extends ISort {
  filter?: ClientFilter | null;
  categoryFilter?: string | null;
  search?: string | null;
}

export interface IClientRatings extends Record<string, unknown> {
  joy: number;
  communication: number;
  network: number;
  growth: number;
  quality: number;
}

export interface IClientGoals extends Record<string, number> {
  monthlyRevenue: number;
  annualRevenue: number;
}

export interface IClient extends IHierarchicalEntity {
  name: string;
  contactName?: string;
  contactEmail?: string;
  contactPhone?: string;
  defaultRate?: number;
  notes?: string;
  isArchived?: boolean;
  defaultProjectType?: ProjectType;
  createdDate?: Date | string;
  daysToPay?: number | undefined;   //the number of days it typically takes for this client to pay invoices
  category?: string;
  address?: string;
  ratings?: IClientRatings;
  goals?: IClientGoals;
}

export interface IClientWithProjects extends IClient {
  projects: IProject[];
}

export const emptyClient: IClient = {
  id: "",
  name: "",
  contactName: "",
  contactEmail: "",
  contactPhone: "",
  defaultRate: 0,
  notes: "",
  defaultProjectType: ProjectType.fixed,
  daysToPay: undefined,
  category: "",
  address: "",
}

// const currency = () =>
//   yup.number().transform(function(value, originalValue){
//     if(this.isType(value)) return value;
//     const stripped = value.replace("$", ",", "-");
//     if(this.isType(stripped)) return parseFloat(stripped)
//     return NaN;
//   });

// const clientSettingsSchema = yup.object().shape({
//   // joy: yup.number().positive().integer(),
//   // communication: yup.number().positive().integer(),
//   monthlyRevenueGoal: yup.number().positive().integer(),
//   annualRevenueGoal: yup.number().positive().integer(),
// });