import React, { useState, useMemo, useEffect } from "react";
import { useDispatch, shallowEqual } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Paper from '@material-ui/core/Paper';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import InputAdornment from '@material-ui/core/InputAdornment';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ArchiveIcon from '@material-ui/icons/Archive';
import { IProject, IAlert,  ProjectTypeItems, roundingTypeItems, ProjectType } from "types";
import { prepareForInputs, removeItem, toHours } from "utils/general-helpers";
import { useLocalization, useClients, useInputs, useSnackbar, useProjectSafe, useDialogs } from "utils/hooks";
import { IconNames } from "utils/common-classes";
import { AtkAlert, IconMenu, DateInput } from "components";
import { projectParsers, getProjectAlerts, projectTypeLabel, formatFee } from "../infra/project-helper";
import { updateProject } from "../infra/project-actions";
import { formatDate } from "utils/date-helpers";
import { formStyles, generalStyles, cardStyles, feeAdornStyle } from "utils/styles";
import { capitalize } from "lodash";

const buildStyles = makeStyles(theme => ({
  ...formStyles(theme),
  ...generalStyles(theme),
  ...cardStyles(theme),
  ...feeAdornStyle(theme),
  alertsGrid: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  dateGrid: {
    marginBottom: theme.spacing(0),
    paddingLeft: theme.spacing(1.5),
  },
  denseAvatar: {
    height: "1.5rem",
    width: "1.5rem",
    backgroundColor: theme.palette.info.main,
    "& svg": {
      fontSize: "1rem",
    }
  }
}));

const ProjectEditCard = () => {
  const classes = buildStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const {LSwap} = useLocalization();
  const { projectId } = useParams<{ projectId: string }>();
  const project = useProjectSafe(projectId);
  const clients = useClients("name");
  const preparedProject = useMemo(() => prepareForInputs(project, ["category", "wordCount"]), [project]);
  const [values, display, errors, binding] = useInputs<IProject>(preparedProject, projectParsers, { updateOn: "onChange" });
  const [alerts, setAlerts] = useState<IAlert[]>([]);
  const notify = useSnackbar();
  const { openDialog, onOpenDialog } = useDialogs();
  const today = useMemo(() => new Date(), []);
  const hasInvoices = useMemo(() => { return Boolean(project.invoices && project.invoices.length > 0); }, [project]);

  const isChanged = useMemo(() => {
    return !shallowEqual(project, values);
  }, [project, values]);

  useEffect(() => {
    const items: IAlert[] = getProjectAlerts(project);
    setAlerts(items);
  }, [project]);

  async function saveChanges(andClose = false) {
    if (isChanged) {
      await dispatch(updateProject(project.id, values));
      notify(LSwap("Project saved successfully", "project"), "success");

      if (andClose) {
        history.push("/projects");
      }
    }
  }

  const menuClicked = (action: string) => async () => {
    if (action === "archive") {
      const msg = project.isArchived ? "unarchived" : "archived";
      const changes = { ...values, isArchived: !project.isArchived };
      await dispatch(updateProject(project.id, changes));
      notify(LSwap(`Project has been ${msg}`, "project"), "success");
    }
    else if(action === "invoice"){
      openDialog("invoice", null, {projectId: project.id});
    }
  }

  const menuItems = React.useMemo(() => {
    const items = [];
    const archiveProps = !project.isArchived ? {label: "Archive project", icon: "archive" } : {label: "Unarchive project", icon: "unarchive" };
    items.push({ id: 0, label: archiveProps.label, icon: <Icon style={{marginLeft: 8}}>{archiveProps.icon}</Icon>, action: menuClicked("archive") });
    items.push({id: 10, isDivider: true});
    items.push({id: 20, label: "New Invoice", icon: <Icon style={{marginLeft: 8}}>{IconNames.invoice}</Icon>, action: menuClicked("invoice")});

    return items;
  }, [project.isArchived]);

  function closeAlert(id: string) {
    const item = alerts.find(a => a.id === id);
    if (item) {
      const newAlerts = removeItem(alerts, item);
      setAlerts(newAlerts);
    }
  }

  return (
    <Grid container>
      {alerts.length > 0 &&
        <Grid container className={classes.alertsGrid}>
          {alerts.map(alert => <AtkAlert key={alert.id} {...alert} onClose={() => closeAlert(alert.id)} />)}
        </Grid>
      }

      <Paper className={classes.card} variant="outlined">
        <Grid container>

          <Grid container className={classes.cardHeader} justify="space-between" alignItems="center">

            <Grid item md={8} sm={6} container justify="flex-start" alignItems="center">
              <TextField id="name" label="Project Name" margin="dense" variant="outlined" fullWidth autoFocus value={display?.name} {...binding.input} {...errors["name"]} autoComplete="off" />
            </Grid>
            <Grid item md={4} sm={6} container justify="flex-end" alignItems="center">
              {project.isArchived && 
                <Tooltip title="This project is archived">
                  <Avatar className={clsx([classes.denseAvatar, classes.marginLeft])} sizes="small">
                    <ArchiveIcon fontSize="small" />
                  </Avatar>
                </Tooltip>
              }
              <Button color="primary" variant={isChanged ? "contained" : undefined} size="small" disabled={!isChanged} onClick={() => saveChanges(false)} className={clsx([classes.actionButton, classes.marginRight])}>Save</Button>
              <Button color="primary" variant={isChanged ? "contained" : undefined} size="small" disabled={!isChanged} onClick={() => saveChanges(true)} className={clsx([classes.actionButton, classes.marginRight])}>Save &amp; Close</Button>
              <IconMenu icon={<MoreVertIcon />} items={menuItems} size="small" />
            </Grid>
          </Grid>

          <Grid container className={classes.cardBody} spacing={1}>

            <Grid item md={5} sm={12} container className={classes.inputGridDense}>
              <FormControl className={classes.formControl} fullWidth variant="outlined" {...binding.selectContainer("clientId", clients)} margin="dense">
                <InputLabel id="client-label" htmlFor="clientId" className={classes.selectLabel}>Client</InputLabel>
                <Select id="clientId" labelId="client-label" label="Client" value={values?.clientId} {...binding.select("clientId")} fullWidth className={classes.selectContainer} classes={{ select: classes.select }}>
                  <MenuItem value="-1" disabled className={classes.option}><em>Select Client</em></MenuItem>
                  {clients?.map(option => <MenuItem key={option.id} value={option.id} className={classes.option}>{option.name}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>

            <Grid item md={3} sm={4} container className={classes.inputGridDense} >
              <TextField id="fee" label="Fee / Rate" variant="outlined" className={classes.rateInput} value={display?.fee} {...binding.input} {...errors["fee"]} autoComplete="off" margin="dense"
                InputProps={{
                  endAdornment: <InputAdornment position="end">
                    <Grid container className={classes.adornGrid} alignItems="center">
                      <Typography className={classes.adorn}>/</Typography>
                      <TextField select value={display?.type} {...binding.select("type")} className={classes.adornSelect}>
                        <MenuItem value={-1} disabled>[choose]</MenuItem>
                        {ProjectTypeItems.map(opt => <MenuItem key={opt.id} value={opt.id}>{opt.unitLabel}</MenuItem>)}
                      </TextField>
                    </Grid>
                  </InputAdornment>

                }}
              />
            </Grid>

            {(values.type !== "fixed" && values.type !== "perHour") && 
              <Grid item md={2} sm={3} className={classes.inputGridDense}>
                  <TextField id="units" label={`${capitalize(projectTypeLabel(values.type))}s`} variant="outlined" fullWidth value={display?.units} {...binding.input} {...errors["units"]} autoComplete="off" margin="dense" className={classes.textCenter} />
              </Grid>
            }
            {(values.type !== "perHour" && values.type !== "perWord") && 
              <Grid item md={2} sm={3} className={classes.inputGridDense}>
                  <TextField id="wordCount" label={`Total words`} variant="outlined" fullWidth value={display?.wordCount} {...binding.input} {...errors["wordCount"]} autoComplete="off" margin="dense" className={classes.textCenter} />
              </Grid>
            }

            {values.type === "perHour" && 
              <Grid item md={3} sm={4} container className={classes.inputGridDense}>
                <FormControl fullWidth variant="outlined" margin="dense">
                  <InputLabel id="rounding-label" htmlFor="roundType">Rounding</InputLabel>
                  <Select id="roundType" labelId="rounding-label" label="Rounding" value={values?.roundType || "none"} {...binding.select("roundType")} variant="outlined" fullWidth>
                    {roundingTypeItems?.map((option: any) => <MenuItem key={option.id} value={option.id}>{option.label}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>
            }
            
            <Grid container spacing={1}>
              <Grid item sm={4} xs={12} className={classes.inputGridDense}>
                <TextField id="projectCategory" label="Project Category" variant="outlined" fullWidth value={display?.category || ""} {...binding.inputWithKey("category")} {...errors["category"]} margin="dense" autoComplete="on" />              
              </Grid>

              <Grid item md={2} sm={3} className={classes.inputGridDense}>
                <TextField id="estimatedHours" label="Est Hours" variant="outlined" value={display?.estimatedHours ?? ""} {...binding.input} {...errors["estimatedHours"]} autoComplete="off" fullWidth margin="dense" className={classes.textRight} />
              </Grid>
              <Grid item md={2} sm={3} className={classes.inputGridDense}>
                <Tooltip title={project.trackedMinutes ? "The total time you've tracked against this project" : "Start tracking your time to see how much time you've spent on this project"}>
                  <TextField id="trackedHours" value={project.trackedMinutes ? toHours(project.trackedMinutes) : ""} disabled label="Tracked Hours" variant="outlined" fullWidth margin="dense" className={classes.textRight} />
                </Tooltip>
              </Grid>
              <Grid item md={2} sm={3} className={classes.inputGridDense}>
                <Tooltip title={project.trackedMinutes ? "The rate or fee calculated based on your time tracking" : "Start tracking your time to calculate your actual rate or fee"}>
                  <TextField id="calcRate" value={project.trackedMinutes ? formatFee(project, true) : ""} disabled label={`Calculated ${project.type === ProjectType.perHour ? "Fee" : "Rate"}`} variant="outlined" fullWidth margin="dense" className={classes.textRight} />
                </Tooltip>
              </Grid>
            </Grid>

            <Grid item md={8} sm={12} container>
              <Grid item xs={12} className={classes.inputGridDense}>
                <TextField id="notes" label="Notes" fullWidth variant="outlined" value={display?.notes} {...binding.input} {...errors["notes"]} multiline rows={12} margin="dense"/>
              </Grid>
            </Grid>

            <Grid item md={4} sm={12} container>
              <Grid item xs={12} className={classes.dateGrid} container alignItems="center" justify="space-between">
                <Grid item xs={4}><InputLabel className={classes.inputLabel}>Start</InputLabel></Grid>
                <Grid item xs={7}>
                  <DateInput id="startDate" variant="outlined" value={values.startDate} {...binding.dateInput("startDate")} fullWidth margin="dense"/>
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.dateGrid} container alignItems="center" justify="space-between">
                <Grid item xs={4}><InputLabel className={classes.inputLabel}>Due</InputLabel></Grid>
                <Grid item xs={7}>
                  <DateInput id="dueDate" variant="outlined" value={values.dueDate} {...binding.dateInput("dueDate")} fullWidth margin="dense" minDate={values.startDate || today} />
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.dateGrid} container alignItems="center" justify="space-between">
                <Grid item xs={4}><InputLabel className={classes.inputLabel}>Delivered</InputLabel></Grid>
                <Grid item xs={7}>
                  <DateInput id="deliveredDate" variant="outlined" value={values.deliveredDate} {...binding.dateInput("deliveredDate")} fullWidth margin="dense" minDate={values.startDate || today} />
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.dateGrid} container alignItems="center" justify="space-between">
                <Grid item xs={4}><InputLabel className={classes.inputLabel}>{hasInvoices ? "Last " : ""}Invoiced</InputLabel></Grid>
                <Grid item xs={7} container alignItems="center" justify="space-between">
                  <Grid item xs={10}>
                    {hasInvoices && 
                      <Tooltip title="Use the the button to the right to add invoices to this project.">
                        <TextField id="lastInvoicedDate" disabled variant="outlined" value={formatDate(project.lastInvoiceDate)} fullWidth margin="dense"/>
                      </Tooltip>
                    }
                    {!hasInvoices && 
                      <DateInput id="invoicedDate" variant="outlined" value={values.invoicedDate} {...binding.dateInput("invoicedDate")} fullWidth margin="dense" minDate={values.startDate || today} />
                    }
                  </Grid>
                  <Grid item xs={2} container justify="flex-end">
                    <Tooltip title="Add a new invoice">
                      <IconButton onClick={onOpenDialog("invoice", null, {clientId: project.clientId, projectId: project.id})} size="small"><Icon fontSize="small">add_circle_outlined</Icon></IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>                
              </Grid>
              <Grid item xs={12} className={classes.dateGrid} container alignItems="center" justify="space-between">
                <Grid item xs={4}><InputLabel className={classes.inputLabel}>{hasInvoices ? "Last " : ""}Paid</InputLabel></Grid>
                <Grid item xs={7}>
                  {hasInvoices && 
                    <Tooltip title="To change the paid date, visit the invoices for this project.">
                      <TextField id="lastPaymentDate" disabled variant="outlined" value={formatDate(project.lastPaymentDate)} fullWidth margin="dense"/>
                    </Tooltip>
                  }
                  {!hasInvoices && 
                    <DateInput id="paidDate" variant="outlined" value={values.paidDate} {...binding.dateInput("paidDate")} fullWidth margin="dense" minDate={values.startDate || today} />
                  }
                </Grid>
              </Grid>

            </Grid>

          </Grid>

        </Grid>        
      </Paper>
    </Grid>
  )
}

export default ProjectEditCard;