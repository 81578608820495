import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Link, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { RootState } from "types";
import { loadGoals } from "./infra/goal-actions";
import CurrentGoalsView from "./ui/current-goals-view";
import { Icons } from "utils/common-classes";
import { generalStyles, tabStyles } from "utils/styles";
import SetGoalsView from "./ui/set-goals-view";
import { getRoute, routes } from "features/app";

const buildStyles   = makeStyles(theme => ({
  ...generalStyles(theme),
  ...tabStyles(theme),
  root  : {
    padding     : theme.spacing(1),
  },
  title   : {
    fontSize    : 22,
    fontWeight  : 500,
    marginBottom: theme.spacing(2),
  },
  section: {
    marginBottom: theme.spacing(2),
  },
  // tabs: {
  //   marginBottom: theme.spacing(2),
  // },
}));

const GoalsView = () => {
  const classes   = buildStyles();
  const dispatch = useDispatch();
  const goalState = useSelector((state: RootState) => state.goals);  
  const loc = useLocation();
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    if(goalState && !goalState.isInitialized){
      dispatch(loadGoals());
    }
  }, []);

  useEffect(() => {
    const path = loc.pathname;
    if(path === routes.goals && tabIndex !== 0) setTabIndex(0);
    else if(path !== routes.goals && tabIndex === 0) setTabIndex(1);
  }, [loc.pathname, tabIndex]);

  const onTabChange = (e: unknown, value: number) => {
    setTabIndex(value);
  }

  return (
    <Grid id="goals-view" container className={classes.root}>
      <Grid container justify="center" className={classes.mbl}> 
        <Tabs value={tabIndex} onChange={onTabChange} indicatorColor="secondary" textColor="secondary" className={classes.tabs}>
          <Tab icon={Icons.goal()} label="Your Goals" className={classes.tab} component={Link} to={getRoute("goals")}/>
          <Tab icon={Icons.add()} label="Set a Goal" className={classes.tab}  component={Link} to={getRoute("goalsAdd")}/>
        </Tabs>
      </Grid>
      <Route exact path={routes.goals}>
        <CurrentGoalsView onAdd={() => onTabChange(null, 1)}/>
      </Route>
      <Route path={routes.goalsAdd}>
        <SetGoalsView />
      </Route>
      <Route path={routes.goalsEdit}>
        <SetGoalsView />
      </Route>
    </Grid>
  );
}

export default GoalsView;
