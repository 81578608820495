import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import InvoiceList from "./invoice-list";
import { StatCard } from "components";
import InvoiceListFilter from "./invoice-list-header";
import { selectInvoiceStats } from "../infra/invoice-selectors";
import { useSelector } from "react-redux";
import { formatCurrency } from "utils/number-helpers";
import lightBlue from "@material-ui/core/colors/lightBlue";
import cyan from "@material-ui/core/colors/cyan";

const buildStyles   = makeStyles(theme => ({
  root  : {
    padding     : theme.spacing(1),
  },
  statRow: {
    marginTop: theme.spacing(2),
  },
  tableGrid: {
    margin: `${theme.spacing(3)}px ${theme.spacing(0)}px`, //`
  },
  
}));

const InvoiceListView = () => {
  const classes   = buildStyles();
  const stats = useSelector(selectInvoiceStats);
  const theme = useTheme();

  return (
    <Grid id="data-view" container justify="center" className={classes.root}>
      <InvoiceListFilter />
      <Grid container item xs={12} spacing={2} className={classes.statRow}>
        <Grid item xs={12} sm={stats?.overdueAmount ? 6 : 4} md={3}>
          <StatCard label="Upcoming" value={formatCurrency(stats?.dueSoonAmount)} icon="event_available" backgroundColor={theme.palette.success.main} foregroundColor={theme.palette.success.light} variant="outlined" toolTip="Amount of invoices due within the next week"/>
        </Grid>
        {Boolean(stats?.overdueAmount)  && 
          <Grid item xs={12} sm={stats?.overdueAmount ? 6 : 4} md={3}>
            <StatCard label="Overdue" value={formatCurrency(stats?.overdueAmount)} icon="report_problem_outlined" backgroundColor={theme.palette.error.main} foregroundColor={theme.palette.error.light} variant="outlined" toolTip="Amount of overdue invoices"/>
          </Grid>
        }
        <Grid item xs={12} sm={stats?.overdueAmount ? 6 : 4} md={3}>
          <StatCard label="Month" value={formatCurrency(stats?.invoicedThisMonth)} icon="event" backgroundColor={lightBlue[300]} foregroundColor="white" variant="outlined" toolTip="Amount you've invoiced this month"/>
        </Grid>
        <Grid item xs={12} sm={stats?.overdueAmount ? 6 : 4} md={3}>
          <StatCard label="Year" value={formatCurrency(stats?.invoicedThisYear)} icon="calendar_today" backgroundColor={cyan[300]} foregroundColor="white" variant="outlined" toolTip="Amount you've invoiced this year"/>
        </Grid>
      </Grid>
      <Grid container item xs={12} className={classes.tableGrid}>
        <InvoiceList />
      </Grid>      
    </Grid>
  );
}

export default InvoiceListView;
