import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from "@material-ui/core/Typography";
import EventIcon from '@material-ui/icons/Event';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import { Icons } from "utils/common-classes";
import { ProjectWithInvoices } from "types";
import { fontStyles, generalStyles } from "utils/styles";
import { useDialogs } from "utils/hooks";

const buildStyles   = makeStyles(theme => ({
  ...fontStyles(theme),
  ...generalStyles(theme),
  dateButton: {
    // border: `1px solid ${theme.palette.grey[300]}`, //`
    // borderRadius: 5,
    background: theme.palette.grey[100],
    border: `1px solid ${theme.palette.grey[200]}`, //`
    padding: `${theme.spacing(0.5)}px ${theme.spacing(0.5)}px`, //`
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    "& svg": {
      margin: `${theme.spacing(0)}px ${theme.spacing(0.5)}px`, //`
      fontSize: "1.25rem", //"0.75rem",
    },
    "& p": {
      textTransform: "none",
    },
    "&:hover": {
      // backgroundColor: "unset",
      background: theme.palette.primary.main,
      "& p, svg": {
        color: theme.palette.primary.contrastText,
      }
    }
  },
}));

interface ControlProps{
  project: ProjectWithInvoices;
}

const ProjectNextAction = ({project}: ControlProps) => {
  const classes   = buildStyles();
  const {openDialog} = useDialogs();

  let btn: any = null;
  if(project.invoices){
    //Check to see if any invoices are unpaid...
    // const unpaid = project.invoices.filter(inv => !inv.paidDate);
    if(project.unpaid){ //(unpaid && unpaid.length){
      btn = {tip: "Receive payment for this project", focus: "paidDate", icon: <EventIcon fontSize="small" color="disabled" />, text: "Payment" };
    }
    else if(project.uninvoiced){
      btn = {tip: "This project can be invoiced", focus: "invoicedDate", icon: Icons.invoice("small", "disabled"), text: "Invoice" };
    }
    else{
      return null;
    }    
  }
  else if(!project.dueDate){
    btn = {tip: "Add a due date", focus: "dueDate", icon: <EventIcon fontSize="small" color="disabled" />, text: "Due" };
  }
  else if(project.dueDate && !project.deliveredDate){
    btn = {tip: "Mark as delivered", focus: "deliveredDate", icon: <EventAvailableIcon fontSize="small" color="disabled" />, text: "Deliver" };
  }
  else if(project.deliveredDate && !project.invoicedDate){
    btn = {tip: "This project can be invoiced", focus: "invoicedDate", icon: Icons.invoice("small", "disabled"), text: "Invoice" };
  }
  else if(project.invoicedDate && !project.paidDate){
    btn = {tip: "Receive payment for this project", focus: "paidDate", icon: Icons.revenue("small", "disabled"), text: "Payment" };
  }

  if(!btn) return null;

  const performAction = () => {
    if(project.hasInvoices && (btn.focus === "paidDate" || btn.focus === "invoicedDate")){
      //Need to open the invoice dialog, not the project dialog
      if(btn.focus === "invoicedDate"){
        openDialog("invoice", null, {clientId: project.clientId, projectId: project.id});
      }
      else{
        openDialog("invoice", project.lastInvoice?.id, {clientId: project.clientId, projectId: project.id});
      }
    }
    else{
      openDialog("project", project.id, {isExpanded: true, focusField: btn.focus});
    }
  }

  return (
    <>
      <Tooltip title={btn.tip}>
        <Button size="small" className={classes.dateButton} onClick={performAction}>
          {btn.icon}
          <Typography className={clsx([classes.infoText, classes.marginRight])}>{btn.text}</Typography>
        </Button>
      </Tooltip>       
    </>
  );
}

export default React.memo(ProjectNextAction);
ProjectNextAction.displayName = "ProjectNextAction";
