import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Exportable, IClient, Invoice, IProject, ITimer } from "types";
import { AtkDialog, IDialogAction } from "components";
import { ParsedCsv } from "utils/import-helper";
import { useLocalization, useSnackbar } from "utils/hooks";
import ImportForm from "./import-form";
import ImportInstructions from './import-instructions';
import { importClients, importInvoices, importProjects } from "utils/import-actions";
import { IconNames } from "utils/common-classes";
import { uploadHours } from "features/timesheet";
import { trackEvent } from "utils/mixpanel";

const buildStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
  },
  dialogContent: {
    minHeight: '12em',
  },
  instructions: {
    fontSize: 18,
    fontWeight: 400,
    marginBottom: theme.spacing(2),
  },
  alertsGrid: {
    margin: theme.spacing(2),
  },
}));

interface ImportDialogProps {
  isOpen?: boolean;
  itemType?: Exportable;
  onClose: (isSaved?: boolean) => void;
  onValidate?: (text: string) => Promise<[number, string]>;
  onImported?: (count: number, error?: string) => void;
}

const ImportDialog = (props: ImportDialogProps) => {
  const classes = buildStyles();
  const dispatch = useDispatch();
  const { L } = useLocalization();
  const notify = useSnackbar();
  const [isWorking, setWorking] = React.useState<boolean>(false);
  
  // const [alerts, setAlerts] = React.useState<IAlert[]>([]);

  const dialogActions: IDialogAction[] = [
    {
      label: "Cancel",
      onClick: () => { props.onClose(); },
      color: "default",
    },
  ];

  if (!props.isOpen) return null;

  const icon = useMemo(() => {
    switch(props.itemType){
      case "clients": return IconNames.client;
      case "projects": return IconNames.project;
      case "hours": return IconNames.timer;
      case "invoices": return IconNames.invoice;
      default: return undefined;
    }
  }, [props.itemType]);

  const handleImport = async (parsed: ParsedCsv) => {
    const count = parsed.items.length;
    if(count === 0) return;

    setWorking(true);
    
    switch(props.itemType){
      case "clients":
        const clients = parsed.items as IClient[];
        await dispatch(importClients(clients));
        break;
      case "projects":
        const projects = parsed.items as IProject[];
        await dispatch(importProjects(projects));
        break;
      case "invoices":
        const invoices = parsed.items as Invoice[];
        await dispatch(importInvoices(invoices));
        break;
      case "hours":
        const hours = parsed.items as ITimer[];
        await dispatch(uploadHours(hours));
        break;
      default: break;
    }

    if(props.onImported) props.onImported(parsed.items.length);
    setWorking(false);
    if(props.itemType) notify(`${L(props.itemType, true)}(s) imported successfully`, "success");
    
    trackEvent("imported", props.itemType);
    
    props.onClose();
  }

  return (
    <AtkDialog isOpen={Boolean(props.isOpen)} onClose={() => props.onClose()} maxWidth="md" title={`Import ${L(props.itemType as Exportable, true)}`} icon={icon} actions={dialogActions} isWorking={isWorking}>
      <Grid container className={classes.dialogContent}>
        {/* {alerts.length > 0 &&
          <Grid container className={classes.alertsGrid}>
              <>{alerts.map(alert => <AtkAlert key={alert.id} {...alert} />)}</>
          </Grid>
        } */}
        <Grid container>
          <Grid item xs={5}>
            <ImportInstructions itemType={props.itemType as Exportable} isWorking={isWorking}/>
          </Grid>
          <Grid item xs={7}>
            <ImportForm itemType={props.itemType as Exportable} onImport={handleImport} isWorking={isWorking}/>
          </Grid>
        </Grid>
      </Grid>
    </AtkDialog>
  );
}

export default ImportDialog;
