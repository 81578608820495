import React from "react";
import clsx from "clsx";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import Icon from "@material-ui/core/Icon";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

const buildStyles = makeStyles((theme) => ({
  section: (props: any) => ({
    paddingTop: props.size === "sm" ? theme.spacing(0.25) : theme.spacing(1),
    paddingBottom: props.size === "sm" ? theme.spacing(0.25) : theme.spacing(1),
  }),
  item: (props: any) => ({
    minHeight: props.size === "sm" ? theme.spacing(3) : theme.spacing(6), 
    paddingTop: props.size === "sm" ? theme.spacing(0.25) : theme.spacing(1),   
    paddingBottom: props.size === "sm" ? theme.spacing(0.25) : theme.spacing(1),   
  }),
  selectedItem: {
    background: `${theme.palette.secondary.light}33`, //`,
    color: theme.palette.secondary.main,
    "& .MuiIcon-root": {
      color: theme.palette.secondary.main,
    },
  },
  hidden: {
    transition: theme.transitions.create("opacity", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    opacity: 0,    
  },
  icon: {
    minWidth: 40,
  }
}));

const SidebarSection = (props: any) => {
  const classes = buildStyles({size: props.size});
  const location = useLocation();
  const history = useHistory();

  const onClick = (path: string) => () => {
    // trackNav(path);
    history.push(path);
  };

  if (props.section.type === "divider") return <Divider />;
  else {
    return (
      <List className={classes.section}>
        {props.section.items.map((item: any) => (
          <SidebarItem
            key={item.id}
            item={item}
            location={location}
            onClick={onClick}
            classes={classes}
            isOpen={props.isOpen}
            size={props.size}
          />
        ))}
      </List>
    );
  }
};

export default SidebarSection;

export const SidebarItem = (props: any) => {
  const { item, classes } = props;
  const match =
    (props.item?.path === "/" && props.location.pathname === "/") ||
    (props.item?.path !== "/" &&
      props.location.pathname.startsWith(props.item?.path)); //useRouteMatch();

  return (
    <ListItem
      button
      onClick={props.onClick(item.path)}
      className={clsx({ [props.classes.item]: true, [props.classes.selectedItem]: !!match })}
      title={item.label}
    >
      <ListItemIcon className={classes.icon}>
        <Icon fontSize={props.size === "sm" ? "small" : "default"}>{item.icon}</Icon>
      </ListItemIcon>
      <ListItemText primary={item.label} className={clsx({[props.classes.hidden]: !props.isOpen})}/>
    </ListItem>
  );
};
