import React from "react";
import _ from "lodash";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { loadProjectHours } from "features/projects";
import { selectHoursSummary, selectCurrentTimer } from "../infra/timesheet-selectors";
import { summaryBarOptions } from "../infra/timesheet-helpers";
import { months } from "utils/date-helpers";
import ChartPanel from "features/dashboard/chart-panel";

const buildStyles   = makeStyles(theme => ({
  rootGrid: {
    padding: theme.spacing(2),
    paddingBottom: 0,
    height: "100%",
  },
  statRow: {
    // marginBottom: theme.spacing(2),
  },
  sumCol: {
    borderRight: `1px solid ${theme.palette.grey[300]}`, //`
    [theme.breakpoints.down("xs")]: {
      borderRight: "none",
      borderBottom: `1px solid ${theme.palette.grey[300]}`, //`
    }

  },
  sumColLast: {
    // borderRight: `1px solid ${theme.palette.grey[300]}`, //`
  },
  sumTitle: {
    fontSize: 14,
    color: theme.palette.grey[600],
    fontWeight: 300,
    marginBottom: theme.spacing(0.5),
  },
  sumValue: {
    fontSize: 18,
    color: theme.palette.success.main,
    fontWeight: 400,
  },
}));

const HoursSummaryAnnual = () => {
  const classes   = buildStyles();
  const dispatch = useDispatch();
  const runningTimer = useSelector(selectCurrentTimer);
  const data = useSelector(selectHoursSummary);
  const chartDimesions = React.useMemo(() => { return {width: 100, height: !!runningTimer ? 200 : 145}; }, [runningTimer]);

  React.useEffect(() => {
    if(data === null){
      dispatch(loadProjectHours());
    }
  }, []);

  const dayLabels = React.useMemo(() => {
    if(!data || !data.weekApexChart || !data.weekApexChart[0]) return [];
    const mydata = data.weekApexChart[0].data;
    const labels: string[] = [];
    const dte = moment();
    for(let i = 0; i < mydata.length; i++){
      labels.push(dte.format("ddd"));
      dte.subtract(1, "day");
    }
    return _.reverse(labels);

  }, [data]);

  return (
    <Grid id="timer-summary" container justify="center" className={classes.rootGrid}>
      <Grid container className={classes.statRow}>
        <Grid item md={4} sm={6}  xs={12} container direction="column" justify="center" alignItems="center" className={classes.sumCol}>
          <Typography className={classes.sumTitle}>Week</Typography>
          <Typography className={classes.sumValue}>{data?.weekHours} <span className={classes.sumTitle}>h</span></Typography>

          <Grid container>
            {data?.weekApexChart && 
              <ChartPanel options={summaryBarOptions("weeklyBar", "days", dayLabels, "hours")} series={data.weekApexChart} type="bar" minWidth={chartDimesions.width} height={chartDimesions.height} />
            }
          </Grid>
        </Grid>
        <Grid item md={4} sm={6}  xs={12} container direction="column" justify="center" alignItems="center" className={classes.sumCol}>
          <Typography className={classes.sumTitle}>Month</Typography>
          <Typography className={classes.sumValue}>{data?.monthHours} <span className={classes.sumTitle}>h</span></Typography>

          <Grid container>
            {data?.monthApexChart && 
              <ChartPanel options={summaryBarOptions("monthlyBar", "weeks", data.weekEnds, "hours")} series={data.monthApexChart} type="bar" minWidth={chartDimesions.width} height={chartDimesions.height}/>
            }
          </Grid>
        </Grid>
        <Grid item md={4} sm={6} xs={12} container direction="column" justify="center" alignItems="center" className={classes.sumColLast}>
          <Typography className={classes.sumTitle}>Year</Typography>
          <Typography className={classes.sumValue}>{data?.yearHours} <span className={classes.sumTitle}>h</span></Typography>
          <Grid container>
            {data?.yearApexChart && 
              <ChartPanel options={summaryBarOptions("yearlyBar", "months", months.slice(0, data.yearApexChart[0].data.length), "hours")} series={data.yearApexChart} type="bar" minWidth={chartDimesions.width} height={chartDimesions.height} />
            }
          </Grid>
        </Grid>
      </Grid>

    </Grid>
  );
}

export default HoursSummaryAnnual;