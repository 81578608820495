import React from "react";
import { useHistory } from "react-router-dom";
import Tab from '@material-ui/core/Tab';

interface LinkTabProps {
  label?: string;
  href?: string;
  className?: any;
  value: string;
}

const LinkTab = (props: LinkTabProps) => {
  const history = useHistory();

  function tabClicked(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    e.preventDefault();
    if (props.href) history.replace(props.href);
  }

  return (
    <Tab component="a" onClick={tabClicked} {...props} />
  );
}

export default LinkTab;
