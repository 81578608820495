import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import { IAuthInfo } from "types";
import { AtkErrorDisplay, AtkAlert, WaitButton } from "components";
import { useInputs, useAuthWithActions, useBoolState } from "utils/hooks";
import { mapLoginFailure } from "utils/firebase";
import { forgotPassword } from "utils/firebase/firebase-provider";

const buildStyles = makeStyles(theme => ({
  card: {
    marginTop: theme.spacing(5),
    width: "100%",
    // marginLeft: "10%",
    // marginRight: "auto",
    // width: "80%",
  },
  section: {
    marginBottom: theme.spacing(2),
  },
  root: {
    padding: theme.spacing(1),
  },
  manualGrid: {
    marginTop: theme.spacing(3),
    padding: `${theme.spacing(0)}px ${theme.spacing(1)}px`, //`,
  },
  title: {
    fontSize: 22,
    fontWeight: 500,
    textAlign: "center",
    marginBottom: theme.spacing(1),
  },
  subTitle: {
    fontSize: 16,
    fontWeight: 400,
    textAlign: "center",
    marginBottom: theme.spacing(4),
  },
  inputGrid: {
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  forgotButton: {
    textTransform: "none",
    fontWeight: 300,
    marginLeft: theme.spacing(-1),
  },
  info: {
    fontSize: 16,
    fontWeight: 400,
    verticalAlign: "center",
  },
  infoButton: {
    textTransform: "none",
  },
  signUpGrid: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(1),
  }
}));

const authInfo: IAuthInfo = {
  username: "",
  password: "",
  passwordConfirm: ""
}

const AppLogin = () => {
  const classes = buildStyles();
  const auth = useAuthWithActions();
  const [error, setError] = useState<string | null>(null);
  const [info, setInfo] = useState<string | null>(null);
  const [isSignup, toggleSignup] = useBoolState(false);
  const [isForgot, toggleForgot] = useBoolState(false);
  const [values, display, errors, binding] = useInputs<IAuthInfo>(authInfo, undefined, { updateOn: "onChange" });
  const subTitle = React.useMemo(() => isSignup ? "Create an account below" : "Please sign in using the options below", [isSignup]);
  const actionName = React.useMemo(() => isSignup ? "up" : "in", [isSignup]);
  const [isWorking, setIsWorking] = React.useState(false);

  async function onLogin() {
    auth.login(null);
  }

  async function onLoginManual() {
    setError(null); 
    setInfo(null);
    let error = null;
    if (values.username.indexOf("@") <= 0 || values.username.indexOf(".") <= 3) {
      error = "Please enter a valid email address";
    }
    else if (values.password.length === 0) {
      error = "Please enter your password";
    }
    else if (isSignup && values.password !== values.passwordConfirm) {
      error = "Passwords do not match";
    }
    else if (isSignup && values.password.length < 6) {
      error = "Password must be at least 6 characters long";
    }

    if (error) {
      setError(error);
      return;
    }

    setIsWorking(true);

    if (isSignup) {
      const result = await auth.createAccount(values.username, values.password);
      if (result.message) {
        const message = mapLoginFailure(result);
        setError(message);
      }
    }
    else {
      const result = await auth.loginWithEmail(values.username, values.password);
      if (result.message) {
        const message = mapLoginFailure(result);
        setError(message);
      }
    }

    setIsWorking(false);
  }

  function handleKeyPress(e: any) {
    if (e.key === "Enter" || e.keyCode === 13) {
      if (values.username.length > 0 && values.password.length > 0) {
        onLoginManual();
      }
    }
  }

  const onForgotPassword = async () => {
    setError(null);
    setInfo(null);
    
    if(values.username && values.username.indexOf("@") > 0){
      setIsWorking(true);
      const result = await forgotPassword(values.username);
      if(result === true){
        setInfo("Check your inbox for an email with instructions for changing your password.");    
        toggleForgot(null);
      }
      else{
        setError(result);
      }
      setIsWorking(false);
    }
    else{
      setError("Email address is missing or invalid.");
    }
  }

  return (
    <Grid container>
      <Grid item xs={1} sm={2} md={3} lg={4}></Grid>
      <Grid item xs={10} sm={8} md={6} lg={4} container>      
        <Card className={classes.card}>
          <div className={classes.section}>
            <Grid id="data-view" container justify="center" className={classes.root}>
              <Grid item xs={12}>
                <Typography variant="h5" color="primary" className={classes.title}>Welcome to GigOps</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h3" className={classes.subTitle}>{subTitle}</Typography>
              </Grid>
              <Grid item xs={12} container justify="center" alignItems="center">
                <Button variant="outlined" color="secondary" onClick={onLogin}>Sign {actionName} with Google</Button>
              </Grid>
            </Grid>
          </div>
          <Divider variant="middle" />
          <div className={classes.section}>
            <Grid container className={classes.manualGrid}>
              <Grid item xs={12}>
                <AtkErrorDisplay message={error} />
                {info && <AtkAlert id="info-alert" severity="info" message={info} onClose={() => setInfo(null)}/>}
              </Grid>
              <Grid item xs={2} />
              <Grid item xs={8} container>
                {isForgot && <Typography variant="h3" className={classes.subTitle}>Enter the email you used to sign up, press submit and then check your email</Typography> }
                <Grid item xs={12} className={classes.inputGrid}>
                  <TextField id="username" fullWidth label="Email address" value={display?.username} {...binding.input} {...errors["username"]} autoFocus={true} disabled={isWorking} />
                </Grid>
                {!isForgot && 
                  <Grid item xs={12} className={classes.inputGrid}>
                    <TextField id="password" type="password" fullWidth label="Password" value={display?.password} {...binding.input} {...errors["password"]} onKeyPress={handleKeyPress} disabled={isWorking} />
                  </Grid>
                }
                {isSignup &&
                  <Grid item xs={12} className={classes.inputGrid}>
                    <TextField id="passwordConfirm" type="password" fullWidth label="Confirm password" value={display?.passwordConfirm} {...binding.input} {...errors["passwordConfirm"]} disabled={isWorking} />
                  </Grid>
                }
                <Grid item xs={12} container justify={isSignup ? "flex-end" : "space-between"} className={classes.inputGrid}>
                  {!isSignup && <Button className={classes.forgotButton} color="secondary" disabled={isWorking} onClick={toggleForgot}>{isForgot ? "Cancel" : "Forgot Password?"}</Button>}
                  {!isForgot && <WaitButton variant="outlined" color="secondary" onClick={onLoginManual} disabled={isWorking} isWaiting={isWorking}>{`Sign ${actionName}`}</WaitButton> }
                  {isForgot && <WaitButton variant="outlined" color="secondary" onClick={onForgotPassword} disabled={isWorking || !values.username} isWaiting={isWorking}>Submit</WaitButton> }
                </Grid>
              </Grid>
              <Grid item xs={2} />
              {!isSignup &&
                <Grid item xs={12} container justify="center" alignItems="center" className={classes.signUpGrid}>
                  <Typography className={classes.info}>First time here?</Typography>
                  <WaitButton className={classes.infoButton} color="secondary" onClick={toggleSignup} disabled={isWorking} isWaiting={isWorking}>Create an Account</WaitButton>
                </Grid>
              }
              {isSignup &&
                <Grid item xs={12} container justify="center" alignItems="center" className={classes.signUpGrid}>
                  <Button className={classes.infoButton} color="secondary" onClick={toggleSignup} disabled={isWorking}>Cancel</Button>
                </Grid>
              }
            </Grid>
          </div>
        </Card>
        </Grid>
      <Grid item xs={1} sm={2} md={3} lg={4}></Grid>
    </Grid>
  );
}

export default AppLogin;
