import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Icon from '@material-ui/core/Icon';
import MenuIcon from '@material-ui/icons/Menu';
import { IconMenu } from "components";
import { useLocalization } from "utils/hooks";
import { IMenuItem, LabelType } from "types";
import { sidebarConfig } from "./app-routes";
import { useHistory } from "react-router-dom";

const buildStyles   = makeStyles(theme => ({
  navButton: {
    marginLeft: theme.spacing(-1),
    marginRight: theme.spacing(1),
    "& span": {
      color: theme.palette.primary.contrastText,
    }
  }
}));

// const navItems: IMenuItem[] = [
//   { id: 0, label: "Home", path: routes.dashboard },
//   { id: 0, label: "Projects", path: routes.projects },
//   { id: 0, label: "Clients", path: routes.clients },
// ];

const AppNavButton = () => {
  const classes   = buildStyles();
  const {L, LSwap} = useLocalization();
  const history = useHistory();

  const menuItems = useMemo(() => {
    let id = 0;

    return sidebarConfig.flatMap(group => {
      if (!group.items) return {id: id++, isDivider: true} as IMenuItem;

      const items = group.items?.map(item => {

        return {
          ...item,
          label: L(item.labelId as (keyof LabelType), true),
          icon: <Icon fontSize="small">{item.icon}</Icon>
        } as IMenuItem;
      });

      return items;
    });
  }, [LSwap]);

  const onClick = (item: IMenuItem) => {
    if(item.path) history.push(item.path);
  }
  
  return (
    <IconMenu items={menuItems} onClick={onClick} icon={<MenuIcon />} classes={{button: classes.navButton}} />
  );
}

export default AppNavButton;
