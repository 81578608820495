import firebase from "firebase/app";
import "firebase/firestore";
import { COLS, STATUSES, IFirestoreResult, collectionRef, asyncForEach } from "utils/firebase";
import { IClient } from "types";
import { prepareForDb } from "utils/general-helpers";

export const clientCollections = {
  CLIENT: "clients",
}

//Properties that may get attached to the client for convenience, but shouldn't get saved with the client
const BAD_PROPS = ["id", "uid", "projects"];

export const clientsApi = (db: firebase.firestore.Firestore) => ({
  //---
  // Creates a new client for the specified user
  createClient: async (orgId: string, client: IClient): Promise<IFirestoreResult> => {
    const id = client.id;
    const prepared = prepareForDb(client, BAD_PROPS);
    const coll = collectionRef(db, orgId, COLS.CLIENT);
    let clientId = "";
    
    if(id){
      const doc = coll.doc(id);
      await doc.set(prepared);
      clientId = id;
    }
    else{
      const result = await coll.add(prepared);
      clientId = result.id;
    }

    return {
      ok: true,
      statusCode: STATUSES.ok,
      key: clientId
    };
  },

  createClients: async (orgId: string, clients: IClient[]): Promise<IFirestoreResult> => {

    const items = clients.map(cli => ({id: cli.id, client: prepareForDb(cli, BAD_PROPS)}));
    const batch = db.batch();
    const coll = collectionRef(db, orgId, COLS.CLIENT);
    const keys = [] as string[];

    await asyncForEach(items, async item => {
      if(item.id){
        const doc = coll.doc(item.id);
        await doc.set(item.client);
        keys.push(item.id);
      }
      else{
        const result = await coll.add(item.client);
        keys.push(result.id);
      }
    });

    await batch.commit();

    return {
      ok: true,
      statusCode: STATUSES.ok,
      key: keys.join("~"),
    };
  },

  //---
  // Gets the list of clients for the specified user
  getClients: async (orgId: string): Promise<IFirestoreResult> => {
    const items: IClient[] = [];

    //TODO: convert to async foreach
    await db.collection(COLS.ORG)
      .doc(orgId).collection(COLS.CLIENT).get()
      .then(qs => {
        if (!qs.empty) {
          qs.forEach(doc => {

            const item = {
              id: doc.id,
              ...doc.data(),
            } as IClient;

            items.push(item);
          });
        }
      });

    if (items.length > 0) {
      return {
        ok: true,
        statusCode: STATUSES.ok,
        key: orgId,
        items: items,
      };
    }
    else {
      return {
        ok: true,
        statusCode: STATUSES.empty,
        key: orgId,
      };
    }
  },

  //---
  // Updates an existing client
  updateClient: async (orgId: string, clientId: string, changes: Partial<IClient>): Promise<IFirestoreResult> => {
    const prepared = prepareForDb(changes, BAD_PROPS);
    const client = db.collection(COLS.ORG).doc(orgId).collection(COLS.CLIENT).doc(clientId);
    await client.update(prepared);

    return {
      ok: true,
      statusCode: STATUSES.ok,
      key: clientId
    };
  },
})