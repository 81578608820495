import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import Typography from "@material-ui/core/Typography";
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import { getRoute } from "features/app";
import { formStyles, generalStyles } from "utils/styles";
import { IconNames } from "utils/common-classes";
import { useClient } from "utils/hooks";
import { InvoiceContext } from "../ui/invoice-container";
import { WaitButton } from "components";

const buildStyles   = makeStyles(theme => ({
  ...formStyles(theme),
  ...generalStyles(theme),
  title: {
    fontSize: 22,
    fontWeight: 500,
    textAlign: "center",
    marginRight: theme.spacing(1),
  },
  titleIcon: {
    marginRight: theme.spacing(1),
  },
  backIcon: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0.5),
  },  
  clientTitle: {
    fontSize: 20,
    fontWeight: 400,
    textAlign: "center",
  },  
  clientLink: {
    textDecoration: "none",
    color: theme.palette.action.disabled
  },
  headerRow: {
    marginBottom: theme.spacing(2),
  }
}));

interface HeaderProps{
  onSave: (andClose?: boolean) => () => Promise<void>;
  isWorking: boolean;
}

//TODO: Have the Print option create a true, formatted print view of the invoice

const InvoiceHeader = ({isWorking, onSave}: HeaderProps) => {
  const classes   = buildStyles();
  const history = useHistory();
  const { invoice, clientId, isNew, isChanged, isReadOnly } = useContext(InvoiceContext)
  const client = useClient(clientId);

  return (
    <Grid container justify="space-between" alignItems="center" className={classes.headerRow}>
      <Grid item md={8} xs={12} container alignItems="center">
        <Tooltip title="Go to Invoices List">
          <Link to="/invoices" replace={true}><Icon className={classes.backIcon} color="primary">keyboard_backspace</Icon></Link>
        </Tooltip>
        {client &&
          <>
            <Tooltip title="Go to this client">
              <Link to={getRoute("client", clientId)} className={classes.clientLink}>
                <Grid container>
                  <Icon color="disabled" className={classes.titleIcon}>{IconNames.client}</Icon>
                  <Typography color="textSecondary" className={classes.clientTitle}>{client?.name}</Typography>
                </Grid>
              </Link>
            </Tooltip>        
            <Icon color="disabled" className={classes.titleIcon}>chevron_right</Icon>
          </>
        }
        {invoice?.id && !isNew && 
          <>
            <Icon color="primary" className={classes.titleIcon}>{IconNames.invoice}</Icon>
            <Typography variant="h5" color="primary" className={classes.title}>Invoice {invoice?.number}</Typography>
          </>
        }
        {isNew &&
          <>
            <Icon color="primary" className={classes.titleIcon}>{IconNames.invoice}</Icon>
            <Typography variant="h5" color="primary" className={classes.title}>New Invoice</Typography>
          </>
        }
      </Grid>
      <Grid item md={4} xs={12} container justify="flex-end">
        <Button onClick={() => history.goBack()} disabled={isWorking} color="default" size="small" className={classes.marginRight}>Cancel</Button>
        {isReadOnly && 
          <>
           <Button color="primary" size="small" className={classes.marginRight} onClick={() => window.print()} disabled={isWorking}>Print</Button>
           <Button color="primary" size="small" className={classes.marginRight} component={Link} to={getRoute("invoiceEdit", invoice.id)} disabled={isWorking}>Edit</Button>
          </>
        }
        {!isReadOnly &&
          <>
            <WaitButton color="primary" variant={isChanged ? "contained" : undefined} size="small" isWaiting={isWorking} disabled={!isChanged} className={classes.marginRight} onClick={onSave(false)}>Save</WaitButton>
            <WaitButton color="primary" variant={isChanged ? "contained" : undefined} size="small" isWaiting={isWorking} disabled={!isChanged} onClick={onSave(true)}>Save &amp; Close</WaitButton>
          </>
        }        
      </Grid>
    </Grid>
  );
}

export default InvoiceHeader;
