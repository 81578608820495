import _, { isUndefined } from "lodash";
import { parseMoment } from "utils/date-helpers";
import { Order } from "./table-header";

// const isPath = (value: string) => value.indexOf(".") >= 0 || value.indexOf("[") >= 0;

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T, getSortValue?: (row: T, col: string) => any) {
  let bVal: any = getSortValue ? getSortValue(b, orderBy as string) || b[orderBy] : b[orderBy];
  let aVal: any = getSortValue ? getSortValue(a, orderBy as string)|| a[orderBy] : a[orderBy];
  
  if(isUndefined(bVal) || isUndefined(aVal)){
    return isUndefined(aVal) && isUndefined(bVal) ? 0 : (isUndefined(bVal) ? -1 : 1);
  }

  const mA = parseMoment(aVal);
  const mB = parseMoment(bVal);
  if (mA || mB) {
    bVal = mB; aVal = mA;
    if (bVal && !aVal) return 1;
    else if (aVal && !bVal) return -1;
  }

  if (bVal < aVal) {
    return -1;
  }
  if (bVal > aVal) {
    return 1;
  }
  return 0;
}

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
  getSortValue?: (row: any, col: string) => any
): (a: { [key in Key]: number | string | Date }, b: { [key in Key]: number | string | Date }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy, getSortValue)
    : (a, b) => -descendingComparator(a, b, orderBy, getSortValue);
}

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// type ArrayCompareFunc = (v1: any, v2: any) => number;
export function areArraysEqual(value1: any[] | null | undefined, value2: any[] | null | undefined){
  if(!value1 || !value2) return false;  //if either OR BOTH are not arrays, return false
  if(value1.length != value2.length) return false;  //need to be the same size
  
  // const sorter = (a: any, b: any) => { return a - b};
  return _.isEqual(value1.slice().sort(), value2.slice().sort());
}