import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';
import { ChangeDirection, IChange } from "types";

const buildStyles = makeStyles(theme => ({
  icon: {
    marginBottom: theme.spacing(-0.5),
  },
  up: {
    color: theme.palette.success.main,
    // marginBottom: theme.spacing(-0.5),
  },
  down: {
    color: theme.palette.error.main,
    // marginBottom: theme.spacing(-0.5),
  },
  flat: {
    color: theme.palette.grey[600],
    // marginBottom: theme.spacing(-0.5),
  },
  na: {
    color: theme.palette.grey[600],
    // marginBottom: theme.spacing(-0.5),
  }
}));

type ChangeArrowProps = {
  isUp?: boolean | null;
  className?: any;
  title?: string;
  variant?: "chevron" | "trending" | undefined;
  change?: IChange;
  fontSize?: "default" | "inherit" | "large" | "small";
}

const matcher = /\{[\w:]+\}/;

const formatTitle = (source: string | undefined, direction: ChangeDirection) => {
  if (source) {
    //is there something to replace?
    const match = matcher.exec(source);
    if (!match) return source;

    //swap the value
    const matchText = match[0];
    switch (matchText.toLowerCase()) {
      case "{change}":
        return source.replace("{change}", direction);
      default: return source;   //Don't know the value to replace
    }
  }
  else {
    return direction === "flat" ? "no change" : direction;
  }
}

const iconMap: { [variant: string]: { [direction: string]: string | null } } = {
  chevron: {
    up: "arrow_drop_up",
    down: "arrow_drop_down",
    flat: "remove",
    na: null,
  },
  trending: {
    up: "trending_up",
    down: "trending_down",
    flat: "trending_flat",
    na: null,
  },
  default: {
    up: "arrow_upward",
    down: "arrow_downward",
    flat: "remove",
    na: null,
  }
}

const getIcon = (variant: string | undefined, direction: ChangeDirection) => {
  const v = variant || "default";
  const icon = iconMap[v][direction];
  return icon;
}

const getDirection = (changes: IChange | undefined, isUp: boolean | null | undefined): ChangeDirection => {
  if (changes) return changes.dir;
  else {
    return isUp === undefined ? "na" : (isUp === null ? "flat" : (isUp ? "up" : "down"));
  }
}

const ChangeArrow = ({ isUp, title, variant, className, change, fontSize }: ChangeArrowProps) => {
  const classes = buildStyles();
  const direction = getDirection(change, isUp);
  const myTitle = React.useMemo(() => formatTitle(title, direction), [title, isUp, change]);
  const myIcon = React.useMemo(() => getIcon(variant, direction), [variant, isUp, change]);

  return (
    <Tooltip title={myTitle}>
      <Icon className={clsx(classes.icon, classes[direction], className)} fontSize={fontSize || "default"}>{myIcon}</Icon>
    </Tooltip>
  );
}

export default ChangeArrow;
