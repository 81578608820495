import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Button from '@material-ui/core/Button';
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from '@material-ui/core/Tooltip';
import { useAnchor, useAuthWithActions, useProfile, useOrg, useIsMobile } from "utils/hooks";
import Logo from "./logo-192.png";
import AppHeaderTimer from "./app-header-timer";
import AppFab from "./app-fab";
import AppNavButton from "./app-nav-button";
import { useDispatch, useSelector } from "react-redux";
import { selectIsOnboarded } from "./infra/common-selectors";
import { trackClick } from "utils/mixpanel";
import { purgeSampleData } from "features/onboarding/infra/onboarding-actions";
import { RootState } from "types";
import { routes } from "./app-routes";
interface IProps {
  isOpen: boolean;
  toggle: () => void;
}

const buildStyles = makeStyles((theme) => ({
  appBar: {
    borderTop: `4px solid ${theme.palette.primary.dark}`, //`
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    borderTop: `4px solid ${theme.palette.primary.dark}`, //`
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36, //theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  avatar: {
    borderRadius: "50%",
    height: "30px",
    width: "30px",
    lineHeight: "30px",
  },
  logo: {
    height: 30,
    width: 30,
    marginRight: theme.spacing(2),
  },
  headerButton: {
    margin: `${theme.spacing(0)}px ${theme.spacing(1)}px`, //`
    [theme.breakpoints.down("xs")]: {
      padding: 0,
      // margin: `0 0 0 ${theme.spacing(0.5)}px`,
    }
  },
  companyName : {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    }
  },
  btn: {
    [theme.breakpoints.down("xs")]: {
      "& span": {
        fontSize: "0.8rem",
      }
    }
  }
}));

const AppHeader = (props: IProps) => {
  const classes = buildStyles();
  // const dispatch = useDispatch();
  const history = useHistory();
  const auth = useAuthWithActions();
  const profile = useProfile();
  const org = useOrg();
  const [anchor, isOpen, onOpen, onClose, position] = useAnchor("profile-menu");
  const hasPhoto = useMemo(() => !!profile?.photoURL, [auth]);
  const isMobile = useIsMobile();
  const isOnboarding = useSelector((state: RootState) => state.app.isOnboarding);
  const isOnboarded = useSelector(selectIsOnboarded);
  const onboarding = useSelector((state: RootState) => state.app.org?.onboarding);
  const hasSample = onboarding && onboarding.sampleStartDate && !onboarding.sampleEndDate;

  async function onLogin(e: any) {
    auth.login(null);
    onClose(e);
  }

  async function onLogout(e: any) {
    history.push(routes.logout);
    onClose(e);
  }

  async function onProfile(e: any) {
    history.push(routes.profile);
    onClose(e);
  }

  async function onRemoveSamples(){
    trackClick("remove-samples");
    history.push(routes.samples);
  }

  const menuItems = [
    { id: 0, label: "Login", isAuthenticated: false, action: onLogin },
    { id: 10, label: "Logout", isAuthenticated: true, action: onLogout },
    { id: 20, label: "Profile", isAuthenticated: true, action: onProfile },
  ];

  const availableMenuItems = menuItems.filter(itm => itm.isAuthenticated === auth.isAuthenticated);

  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: props.isOpen,
      })}
    >
      <Toolbar>
        <Grid container justify="space-between" wrap="nowrap">
          <Grid item md={5} xs={4} container justify="flex-start" alignItems="center">
            {isMobile && isOnboarded && <AppNavButton /> }
            <img src={Logo} className={classes.logo} />
            <Typography variant="h6" noWrap className={classes.companyName}>
              {org?.name || "GigOps"}
            </Typography>
          </Grid>

          <Grid item md={3} xs={4} container alignItems="center" justify="center">
            {hasSample && !isOnboarding &&
              <Tooltip title="Click this when you've seen enough, and are ready to get started">
                <Button onClick={onRemoveSamples} variant="contained" color="secondary" className={classes.btn}>REMOVE SAMPLES</Button>
              </Tooltip>
            }
          </Grid>

          <Grid item md={4} xs={5} container justify="flex-end" alignItems="center" wrap="nowrap">
            {auth.isAuthenticated &&
              <Grid container justify="flex-end" alignItems="center">

                {isOnboarded && 
                  <>
                    <AppHeaderTimer />
                    <AppFab />
                  </>
                }

                <IconButton color="inherit" onClick={onOpen} className={classes.headerButton}>
                  {hasPhoto && <img className={classes.avatar} src={profile?.photoURL || ""} alt="avatar" />}
                  {!hasPhoto && <AccountCircleIcon />}
                </IconButton>
              </Grid>
            }
            <Menu
              id="menu-appbar"
              anchorEl={anchor}
              keepMounted
              open={isOpen}
              onClose={onClose}
              {...position}
            >
              {availableMenuItems.map(item => <MenuItem key={item.id} onClick={item.action}>{item.label}</MenuItem>)}
            </Menu>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default AppHeader;
