import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MuiAlert from '@material-ui/lab/Alert';
import { IAlert } from "types";
import { getSeverityBorder } from "utils/general-helpers";

//"success" | "info" | "warning" | "error"
const buildStyles = makeStyles(theme => ({
  alert: (props: any) => ({
    width: "100%",
    border: "1px solid",
    borderColor: getSeverityBorder(props.severity, theme.palette),
    marginBottom: theme.spacing(1),
  }),
}));

const AtkAlert = (props: IAlert) => {
  const classes = buildStyles({ severity: props.severity });

  return (
    <MuiAlert onClose={props.onClose} severity={props.severity} className={classes.alert}>
      {props.message}
    </MuiAlert>
  );
}

export default AtkAlert;
