import React from "react";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from '@material-ui/core/Tooltip';
import Chart from "react-apexcharts";
import { ChangeArrow } from "components";
import { featureColors } from "utils/common-classes";

const buildStyles   = makeStyles(theme => ({
  statGrid: {
    margin: `${theme.spacing(2)}px ${theme.spacing(0)}px`, //`
  },
  statInnerGrid: {

  },
  arrow: {
    zIndex: 11, //tooltip on the chart is 12, so need to be below that, but above the chart itself due to the overlap
  },
  statTitle: {
    fontSize: 13,
    color: theme.palette.grey[600],
    fontWeight: 300,
    marginBottom: theme.spacing(0.5),
  },
  statValue: {
    fontSize: 30,
    color: theme.palette.success.main,
    fontWeight: 300,
    // marginBottom: theme.spacing(0.5),
  },
  statChart: {
    marginTop: theme.spacing(-4),
  },
}));

interface IMetricData {
  thisMonthIsUp: boolean;
  thisMonth: number;
  chartData: any;
}

type MetricProps = {
  metricKey: string;
  width: 3 | 4 | 6 | 12;
  label: string;
  formatter: (val: any) => string;
  chartOptions: any;
  metricData: IMetricData;
}

const HeroMetric = (props: MetricProps) => {
  const {width, label, formatter, chartOptions, metricData} = props;
  const classes = buildStyles();
  const tooltip = React.useMemo(() => `${_.capitalize(label)} this month`, [label]);
  const style = {color: featureColors[props.metricKey] || featureColors.default};

  return (
    <Tooltip title={tooltip}>
      <Grid item md={width} sm={6} xs={12} container className={classes.statGrid} direction="column" alignItems="center">
        <Typography color="primary" className={classes.statTitle}>{label}</Typography>
        <Grid container justify="center" alignItems="center" className={classes.statInnerGrid}>
          {/* don't show the arrow if it's flat, and the current value is 0*/}
          {(metricData.thisMonthIsUp !== null || metricData.thisMonth !== 0) && <ChangeArrow isUp={metricData.thisMonthIsUp} title="{change} from last month" className={classes.arrow} />}
          <Typography className={classes.statValue} style={style}>{formatter(metricData.thisMonth)}</Typography>
        </Grid>
        <Grid className={classes.statChart}>
          {metricData && metricData.chartData && <Chart options={chartOptions} series={metricData.chartData} type="bar" width={200} height={75} />}
        </Grid>
      </Grid>
    </Tooltip>
  );
}

export default HeroMetric;
