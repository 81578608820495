import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { FocusWithin } from "react-focus-within";
import { cardStyles, fontStyles, generalStyles } from "utils/styles";
import { useInputs, useProjects, useSnackbar } from "utils/hooks";
import { orderBy } from "lodash";
import { selectClientsWithProjects } from "features/clients/infra/client-selectors";
import { hasActiveProject } from "features/clients/client-helpers";
import { DateInput } from "components";
import { IacClient, IacProject, ITask, RootState } from "types";
import { createTask, updateTask } from "./infra/task-actions";
import { toggleEditTask } from "./infra/tasks-slice";
import { addDays } from "date-fns/esm";

const buildStyles   = makeStyles(theme => ({
  ...generalStyles(theme),
  ...fontStyles(theme),
  ...cardStyles(theme),
  editCard: {
    borderRadius: 0,
  },
  taskField: {
    "& textarea": {
      fontSize: "0.8rem",
    }
  },
  autoSelect: {
    "& .MuiFormControl-root": {
      marginBottom: 0,
    }
  },
  dateField: {
    marginBottom: 0,
  },
  disabled: {
    background: theme.palette.grey[50],
  }
}));

const emptyTask : Partial<ITask> = {
  clientId: "",
  projectId: "",
  content: "",
  dueDate: addDays(new Date(), 2),
  isFinished: false,
}

interface ControlProps {
  task?: ITask | null;
  clientId?: string;
  projectId?: string;
  classes?: Record<string, string>;
}

const TaskEditCard = ({task, clientId, projectId, ...props}: ControlProps) => {
  const classes   = buildStyles();
  const otherClasses = props.classes || {};
  const dispatch = useDispatch();
  const { status, editingId } = useSelector((state: RootState) => state.tasks);
  const clients = useSelector(selectClientsWithProjects);
  const projects = useProjects("name");
  const defaultTask = useMemo(() => ({...emptyTask, ...task}), [task]);
  const [values, display, errors, binding, setValues ] = useInputs<Partial<ITask>>(defaultTask, undefined, {updateOn: "onChange"});
  const canSave = useMemo(() => values.content && Object.keys(errors).length === 0, [values, errors]);
  const notify = useSnackbar();

  const projectList = useMemo(() => {
    const available = values.clientId ? projects.filter(p => p.clientId === values.clientId) : projects;
    const prjs = available.map(p => ({id: p.id, name: p.name, clientId: p.clientId, status: p.deliveredDate ? "Delivered" : "Active"}  as IacProject));
    const ordered = orderBy(prjs, ["status"]);
    return ordered;
  }, [projects, values.clientId]);

  const clientList = useMemo(() => {
    const clis = clients.map(c => ({id: c.id, name: c.name, status: hasActiveProject(c.projects) ? "Active" : "Not Active"} as IacClient));
    const ordered = orderBy(clis, ["status", "name"]);
    return ordered;
  }, [clients]);

  const theProjectId = useMemo(() => projectId || values.projectId || null, [projectId, values.projectId]);
  const theClientId = useMemo(() => {
    if(projectId){
      const proj = projects.find(p => p.id === theProjectId);
      return proj?.clientId || null;
    }
    else return clientId || values.clientId || null;
  }, [clientId, projectId, values.clientId]);
  const cli = useMemo<IacClient | null>(() => {return theClientId ? clientList?.find(c => c.id === theClientId) || null : null}, [theClientId]);
  const prj = useMemo<IacProject | null>(() => {return theProjectId ? projectList?.find(p => p.id === theProjectId) || null : null}, [theProjectId]);

  const isEditingOther = useMemo(() => {return editingId && editingId !== task?.id; }, [editingId, task?.id]);
  
  async function onProjectChange(e: any, item: IacProject | null){
    let changes = {projectId: item?.id || null} as any;
    if(item && (!values.clientId || item.clientId !== values.clientId)){
      changes = {...changes, clientId: item.clientId};
    }
    setValues({...values, ...changes});
  }

  async function onClientChange(e: any, item: IacClient | null){
    let changes = {};
    if(item && values.projectId){
      const p = projectList.find(pj => pj.id === values.projectId);
      if(p && p.clientId !== item.id){
        changes = {projectId: null};
      }
    }
    changes = {...changes, clientId: item?.id || null};
    setValues({...values, ...changes});
  }

  //-- Saves the new task
  async function onSave(){
    const model: Partial<ITask> = {
      ...values,
      clientId: cli?.id || "",
      projectId: prj?.id || "",
    };

    const result = await dispatch(createTask(model));
    if(result !== null){
      notify("Task successfully created", "success");
      setValues(emptyTask);
    }
  }

  async function onUpdate(){
    if(!task) return;

    const model: Partial<ITask> = {
      ...values,
      clientId: cli?.id || "",
      projectId: prj?.id || "",
    };

    const result = await dispatch(updateTask(task.id, model));
    if(result !== null){
      notify("Task successfully updated", "success");
    }
  }

  async function onCancel(){
    if(!task){
      setValues({...values, ...emptyTask});
    } 
    else{
      dispatch(toggleEditTask(task.id));
    }
  }

  return (
    <Paper className={clsx(classes.card, classes.editCard, otherClasses.paper, {[classes.disabled]: isEditingOther})} variant="outlined">
      <FocusWithin>
        {({ focusProps, isFocused}) => (
          <Grid container className={classes.cardBody} spacing={1} {...focusProps}>
            <Grid item container>
              <TextField id="content" label={task?.content ? "Task" : "New task"} value={display?.content} {...binding.inputWithKey("content")} disabled={isEditingOther} multiline fullWidth rows={1} rowsMax={10} variant="outlined" className={classes.taskField} />
            </Grid>
            {(isFocused || task || values.content) && 
              <>
                <Grid item md={2} sm={2} xs={4} container alignItems="center">
                  <DateInput id="dueDate" label="Due" value={values.dueDate} {...binding.dateInput("dueDate")} variant="outlined" margin="dense" className={classes.dateField} required disabled={status.isWorking}/>
                </Grid>
                <Grid item md sm={4} container alignItems="center">
                  <Autocomplete 
                    autoComplete autoHighlight autoSelect
                    id="client-filter" 
                    options={clientList}
                    value={cli}
                    onChange={onClientChange} 
                    getOptionLabel={o => o.name} 
                    groupBy={o => o.status}
                    className={classes.autoSelect} 
                    fullWidth
                    renderInput={(p) => <TextField {...p} placeholder="Client (optional)" margin="dense" variant="outlined" fullWidth/>}
                    disabled={status.isWorking || Boolean(clientId || projectId)}  
                  />
                </Grid>
                <Grid item md sm={4} container alignItems="center">
                  <Autocomplete 
                    autoComplete autoHighlight autoSelect
                    id="project-filter" 
                    options={projectList}
                    value={prj}
                    onChange={onProjectChange} 
                    getOptionLabel={o => o.name} 
                    groupBy={o => o.status}
                    className={classes.autoSelect} 
                    fullWidth
                    renderInput={(p) => <TextField {...p} placeholder="Project (optional)" margin="dense" variant="outlined" fullWidth/>}
                    disabled={status.isWorking || Boolean(projectId)}  
                  />
                </Grid>
                <Grid item md sm={2} container alignItems="center" justify="flex-end">
                  <Button onClick={onCancel} disabled={status.isWorking} variant="outlined" size="small" className={clsx(classes.mt, classes.marginRight)}>{task ? "Cancel" : "Clear"}</Button>
                  <Button onClick={task ? onUpdate : onSave} disabled={!canSave || status.isWorking} variant="outlined" color="primary" size="small" className={classes.mt}>Save</Button>
                </Grid>
              </>
            }
          </Grid>
        )}
      </FocusWithin>
    </Paper>
  );
}

export default TaskEditCard;
