import React, { useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { Route, useHistory, Link, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import FavoritesIcon from "@material-ui/icons/StarBorder";
import { IMenuItem } from "types";
import { tabStyles, generalStyles, fontStyles, layoutStyles } from "utils/styles/common-styles";
import { Icons, IconNames } from "utils/common-classes";
import MetricsList from "./metrics-list";
import { ChipMenu } from "components";
import { useDispatch } from "react-redux";
import { loadProjectHours } from "features/projects";

const buildStyles   = makeStyles(theme => ({
  ...tabStyles(theme),
  ...generalStyles(theme),
  ...fontStyles(theme),
  ...layoutStyles(theme),
  root  : {
    padding     : theme.spacing(1),
  },
  // tabMenu: {
  //   display: "none",
  // }
}));

const tabMap = ["/metrics", "/metrics/revenue", "/metrics/clients", "/metrics/projects", "/metrics/time"];
const tabMenuItems: IMenuItem[] = [
  {id: 0, label: "Favorites", action: tabMap[0], icon: IconNames.favorites, props: "tab", },
  {id: 1, label: "Revenue", action: tabMap[1], icon: IconNames.revenue, props: "tab", },
  {id: 2, label: "Clients", action: tabMap[2], icon: IconNames.client, props: "tab", },
  {id: 3, label: "Projects", action: tabMap[3], icon: IconNames.project, props: "tab", },
  {id: 4, label: "Time", action: tabMap[4], icon: IconNames.timer, props: "tab", }
];

const MetricsView = () => {
  const classes   = buildStyles();
  const dispatch = useDispatch();
  const loc = useLocation();
  const history = useHistory();
  const [tabIndex, setTabIndex] = useState(0);
  const [period, setPeriod] = useState({id: "this-year", label: "This year"});
  const [tabItem, setTabItem] = useState(tabMenuItems[0]);

  useEffect(() => {
    dispatch(loadProjectHours()); //won't re-load if they've already been loaded
  }, []);

  useEffect(() => {
    const path = loc.pathname;
    const index = tabMap.findIndex(t => t === path);
    if(tabIndex !== index) setTabIndex(index);
    if(tabItem.id !== index) setTabItem(tabMenuItems[index]);
    // trackNav(path);
  }, [loc.pathname, tabIndex]);

  const dateMenuItems = useMemo(() => {
    const items: IMenuItem[] = [
      {id: 0, label: "This year", action: "this-year", isSelected: (period.id === "this-year"), props: "period", }, 
      {id: 1, label: "Last year", action: "last-year", isSelected: (period.id === "last-year"), props: "period", }, 
    ];
    return items;
  }, [period]);

  const onTabChange = (e: unknown, value: number) => {
    setTabIndex(value);    
  }

  const onMenuClick = (item: IMenuItem) => {
    if(item.props === "period"){
      setPeriod({id: item.action, label: item.label as string});
    }
    else if(item.props === "tab"){
      setTabItem(item);
      setTabIndex(item.id);
      history.replace(item.action);
    }
  }

  return (
    <Grid id="metrics-view" container className={classes.root}>
      <Grid container alignItems="center" spacing={2} className={classes.mbl}>
        <Grid item md={3} sm={12} container alignItems="center">
          <Typography className={clsx([classes.title, classes.marginRightLg])} color="primary">Metrics</Typography>
          <ChipMenu label={tabItem.label as string} title="Metrics Category" variant="outlined" color="secondary" items={tabMenuItems} onClick={onMenuClick} classes={{container: clsx([classes.marginRight, classes.showXs])}}/>
          <ChipMenu label={period.label} title="Choose the date range" items={dateMenuItems} onClick={onMenuClick}/>
        </Grid>
        <Grid item md={9} sm={12} container className={classes.hideXs} alignItems="center"> 
          <Tabs value={tabIndex} onChange={onTabChange} indicatorColor="secondary" textColor="secondary" className={classes.tabs} variant="scrollable" scrollButtons="auto">
            <Tab icon={<FavoritesIcon />} label="Favorites" className={classes.tab} component={Link} to="/metrics"/>
            <Tab icon={Icons.revenue()} label="Revenue" className={classes.tab}  component={Link} to="/metrics/revenue"/>
            <Tab icon={Icons.client()} label="Clients" className={classes.tab}  component={Link} to="/metrics/clients"/>
            <Tab icon={Icons.project()} label="Projects" className={classes.tab}  component={Link} to="/metrics/projects"/>
            <Tab icon={Icons.timer()} label="Time" className={classes.tab}  component={Link} to="/metrics/time"/>
          </Tabs>
        </Grid>
      </Grid>
      <Grid container>
        <Route exact path="/metrics">
          <MetricsList range={period.id} period={period.label}/>
        </Route>
        <Route path="/metrics/:category">
          <MetricsList range={period.id} period={period.label}/>
        </Route>
      </Grid>
    </Grid>
  );
}

export default MetricsView;
