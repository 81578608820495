import firebase from "firebase/app";
import "firebase/functions";
import { AppThunk } from "types";
import { useFirestore } from "utils/firebase";
import { setOnboarding, updateOrg } from "features/app/app-slice";
import { generateSampleData } from "utils/sample-data/sample-writer";
import { stringsToDates } from "utils/general-helpers";
import { uploadClients, clearItems as clearClientItems } from "features/clients/clients-slice";
import { uploadProjects, clearItems as clearProjectItems } from "features/projects";
import { clearItems as clearInvoiceItems } from "features/invoices";
import { uploadHours, clearItems as clearTimesheetItems } from "features/timesheet";

export const createSampleData = (statusCallback?: (message: string) => void): AppThunk => async (dispatch, getState) => {

    await dispatch(setOnboarding(true));

    const data = generateSampleData(5);
    // console.log(data);
    if(statusCallback) statusCallback("Uploading sample clients...");
    await dispatch(uploadClients(data.clients));
    
    if(statusCallback) statusCallback("Uploading sample projects...");
    await dispatch(uploadProjects(data.projects));
    
    if(statusCallback) statusCallback("Uploading sample hours (this may take a minute)...");
    await dispatch(uploadHours(data.hours, true));

    // update the org to indicate there is sample data...
    const onboarding = {
      sampleStartDate: new Date(),
      sampleEndDate: null,
    }
    await dispatch(updateOrg({onboarding}));
}

//-- Initializes the primary collections in the app
export const purgeSampleData = (): AppThunk => async (dispatch, getState) => {
  const [api, uid] = useFirestore();
  if (!uid || !api) throw new Error("User is not authorized.");

  await dispatch(setOnboarding(true));

  const appState = getState().app;
  const orgId = appState.org.id;
  
  try{
    const removeFunc = firebase.functions().httpsCallable("purgeOrg");
    const result = await removeFunc({orgId: orgId});
    console.log("Organization data has been purged", result);

    if(result.data.status === "ok"){
      
      //update the org to indicate there was sample data...
      const existing = appState.org.onboarding ? stringsToDates(appState.org.onboarding) : undefined;
      const status = {
        ...existing,
        sampleEndDate: new Date(),
      };

      await dispatch(updateOrg({onboarding: status}));
    }

    await dispatch(setOnboarding(false));
    return result.data;
  }
  catch(ex){
    console.error("failed to purge organization.", ex);
    await dispatch(setOnboarding(false));
  }
}

export const resetStore = (): AppThunk => async (dispatch) => {
  await dispatch(clearClientItems());
  await dispatch(clearProjectItems());
  await dispatch(clearInvoiceItems());
  await dispatch(clearTimesheetItems());
}