import firebase from "firebase";
import { LabelType } from "../utils/localization/localization-types";
import { ProjectType } from "./project-types";

export type CellSize = boolean | "auto" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined;

export interface Indexable{
  [key: string]: any;
}
export interface IEntity extends Indexable {
  id: string;
  // [key: string]: any;   //indexer...
}

export interface IHierarchicalEntity extends IEntity {
  parentId?: string;
}

export interface IAuthInfo {
  username: string;
  password: string;
  passwordConfirm?: string;
}



//TODO: Move localization labels into a sub-object to group them
export interface AppCustomizations extends LabelType, Indexable {
  defaultProjectType?: ProjectType;
  defaultHourlyRate?: number;
  defaultRounding?: string;
  dashboardConfig?: {
    metrics?: string[];
    areas?: string[];
    widgets?: string[];
  };
  favoriteMetrics?: string[];
}

//NOTE: see defaultLabels in common-types.tsx for the defaults shown in the profile settings view.
export const defaultAppCustomizations: AppCustomizations = {
  dashboard: "dashboard",
  client: "client",
  clients: "clients",
  project: "project",
  projects: "projects",
  projectStart: "started",
  projectDue: "due",
  projectDelivered: "delivered",
  projectInvoiced: "invoiced",
  projectPaid: "paid",
  timesheet: "timesheet",
  hours: "hours",
  notes: "notes",
  note: "note",
  tasks: "tasks",
  task: "task",
  report: "report",
  reports: "reports",
  defaultProjectType: ProjectType.fixed,
  defaultHourlyRate: 0,
  defaultRounding: "none",
  dashboardConfig: {
    metrics: ["delivered", "paid", "assigned"],
    areas: ["delivered", "assigned"],
    widgets: ["invoices", "revenue", "top-clients"],
  },
  favoriteMetrics: ["revenue-paid", "clients-active", "projects-active", "projects-mean-rate"],
}

export interface IProfileInfo {
  uid?: string;
  displayName?: string | null;
  email?: string | null;
  companyName?: string;
  companyType?: string;
  photoURL?: string | null;
  customizations?: AppCustomizations | null;
  createdDate?: Date | string | null;
  providerData?: any[];
}

// export const OrgTypes = {
//   writer: {id: "writer", name: "Writer & Editor", description: "Content producer who performs writing and editing work for publications (digital or print) as well as for brands", },
//   UiUx = "designer",
//   Developer = "developer",
// }

export interface IOnboarding{
  sampleStartDate?: Date;
  sampleEndDate?: Date | null;
}
export interface IOrg {
  id: string;
  name?: string | null;
  logoURL?: string | null;
  website?: string | null;
  email?: string | null;
  phone?: string | null;
  admin: string;
  customizations?: AppCustomizations | null;
  onboarding?: IOnboarding;
  address?: string;
}

//Fields that are safe for the profile update operation (to make sure bad things can't be changed)
export const PROFILE_WHITELIST = ["displayName", "email", "companyName", "companyType", "photoType", "customizations"];
export const ORG_WHITELIST = ["name", "logoURL", "website", "email", "phone", "customizations", "onboarding", "address"];

export const defaultProfile: IProfileInfo = {
  displayName: "",
  email: "",
  companyName: "",
  companyType: "",
  photoURL: "",
  customizations: {}
}

export const defaultOrg: IOrg = {
  id: "",
  name: "",
  logoURL: "",
  website: "",
  phone: "",
  admin: "",
  address: "",
  customizations: null,
}

export type Severity = "success" | "info" | "warning" | "error" | undefined;

export interface IAlert {
  id: string;
  severity?: Severity;
  label?: string;
  message: string;
  icon?: any;
  onClose?: () => void;
  other?: any;
  tip?: string;
  path?: string;
  onClick?: (alert: IAlert) => void;
}

export interface INotify {
  severity?: "error" | "info" | "success" | "warning" | undefined;
  message: string;
}

export type Order = "asc" | "desc";

export interface ISort {
  sort: string;
  sortDir: Order;
}

export interface IFilter extends ISort {
  id: number;
  filter: string;
  label: string;
  title?: string;
  action?: () => void;
  isSelected?: boolean;
}

export interface IMenuItem {
  id: number;
  label?: string;
  icon?: any;
  action?: any; //string | {} | (() => void);
  props?: any;
  isSelected?: boolean;
  isDivider?: boolean;
  path?: string;
}

export type ChangeDirection = "up" | "down" | "flat" | "na";
export interface IChange {
  pct: number;
  diff: number;
  dir: ChangeDirection;
}

export interface IStatus {
  isWorking: boolean;
  error: any;
  wantsRefresh?: boolean;
}

export type RoundingTypes = "none" | "round15" | "round30" | "round60" | "push15" | "push30" | "push60";

export interface IDialogProps {
  isOpen: any;
  onClose: (value?: any) => void;
}

//Represents an item to be updated where the id is separate from the updates to the item
export type ItemUpdate<T> = {
  id: string; 
  updates: Partial<T>;
};

export interface QueryFilter {
  field: string;
  operator: firebase.firestore.WhereFilterOp; //"==" | "!=" | "<" | ">" | "<=" | ">=";
  value: any;  
}

// export interface ItemQuery<T>{
//   key: string;
//   props: any;
//   items: T[];
// }

// export type SliceKeys = "app" | "client" | "project" | "timesheet" | "invoices";

export interface InputField<T> extends Indexable {
  value: T | null;
  display: string;
  isEmpty: boolean;
  bindings: {
    error: boolean;
    helperText: string | null;
    errorType?: string;
  };
}

//Types that can be imported or exported from the application
export type Exportable = "clients" | "projects" | "invoices" | "hours";

export type DateRange = {start: Date; end: Date};
export type DateRangeFunc = (key?: string) => DateRange;

export interface SimpleStat{
  label: string; 
  amount: number; 
  toolTip?: string, 
  color?: string
  action?: string;
}

export type ChartFormatter = "currency" | "hours" | "count";

export interface StatWithChart extends SimpleStat{
  chartData?: any, 
  chartProps?: any;
  chartFormatter?: ChartFormatter; //"currency" | "hours" | "count";
  chartType?: "bar" | "area";
}

export interface IacClient{
  id: string;
  name: string;
  status: "Active" | "Not Active";
}
export interface IacProject{
  id: string;
  name: string;
  clientId: string;
  status: "Active" | "Delivered";
}
