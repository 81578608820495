import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "types";
import { loadTasks } from "./infra/task-actions";
import { cardStyles, fontStyles, generalStyles } from "utils/styles";
import { selectFilteredTasks } from "./infra/task-selectors";
import TasksListHeader from "./tasks-list-header";
import { LoadingIndicator } from "components";
import TaskEditCard from "./task-edit-card";
import TasksList from "./tasks-list";

const buildStyles   = makeStyles(theme => ({
  ...fontStyles(theme),
  ...generalStyles(theme),
  ...cardStyles(theme),
  root  : {
  },  
}));

const TasksContent = () => {
  const classes   = buildStyles();
  const dispatch = useDispatch();
  const tasks = useSelector(selectFilteredTasks);
  const { status, isInitialized } = useSelector((state: RootState) => state.tasks);

  useEffect(() => {
    if(tasks === null ){
      dispatch(loadTasks());
    }
  }, []);

  if(!isInitialized){
    return (<LoadingIndicator isVisible={!isInitialized || status.isWorking} message="Loading..." />);
  }

  return (
    <Grid container direction="column" className={classes.root}>
      <TasksListHeader />
      <TaskEditCard />
      <TasksList items={tasks} />      
    </Grid>
  );
}

export default TasksContent;
