import React from "react";
import { useSelector } from "react-redux";
import { Route, useRouteMatch } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tabs from '@material-ui/core/Tabs';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { RootState } from "types";
import { prepareForInputs } from "utils/general-helpers";
import { getRoute, routes } from "features/app/app-routes";
import { useAuth, useOrg } from "utils/hooks";
import { LinkTab } from "components";
import ProfileAbout from "./profile-about";
import ProfileSettings from "./profile-settings";

const buildStyles = makeStyles(theme => ({
  root: {
    // padding: theme.spacing(1),
    margin: theme.spacing(-3),
    width: "calc(100% + 48px)",
  },
  headerGrid: {
    background: theme.palette.primary.dark,
    padding: theme.spacing(3),
    height: 235,
    [theme.breakpoints.down("xs")]: {
      height: 315,
    }
  },
  tabContainer: {
    marginBottom: theme.spacing(6),
  },
  tabs: {
    marginLeft: theme.spacing(4),
    color: theme.palette.common.white,
    "& .MuiTabs-indicator": {
      background: theme.palette.common.white,
    }
  },
  tab: {

  },
  bodyGrid: {
    marginTop: theme.spacing(-8),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),    
  },
  title: {
    fontSize: 24,
    fontWeight: 300,
    textAlign: "center",
    // marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    color: theme.palette.common.white,
  },
  subTitle: {
    fontSize: 17,
    fontWeight: 300,
    textAlign: "center",
    marginBottom: theme.spacing(3),
    // color: theme.palette.grey[800],
    color: theme.palette.common.white,
  },
  avatar: {
    borderRadius: "50%",
    height: 90,
    width: 90,
    lineHeight: 120,
    marginRight: theme.spacing(2),
    background: theme.palette.grey[200],
    // marginBottom: theme.spacing(1),
  },
}));

const ProfileView = () => {
  const classes = buildStyles();
  const auth = useAuth();
  const rMatch = useRouteMatch(routes.profileSettings);
  const profile = useSelector((s: RootState) => s.app.profile);
  const org = useOrg(true);
  const prepedProfile = React.useMemo(() => profile ? prepareForInputs(profile) : null, [profile]);
  const hasPhoto = React.useMemo(() => !!profile?.photoURL, [profile]);
  const [tab, setTab] = React.useState(rMatch ? "settings" : "about");

  if (!auth || !auth.isAuthenticated) return null;

  return (
    <Grid id="profile-view" container className={classes.root}>
      <Grid item xs={12} container className={classes.headerGrid} alignItems="flex-start">
        <Grid container>
          <Grid item>
            {hasPhoto && <img className={classes.avatar} src={profile?.photoURL || ""} alt="avatar" />}
            {!hasPhoto && <AccountCircleIcon className={classes.avatar} />}
          </Grid>
          <Grid item>
            <Grid container justify="flex-start">
              <Grid item xs={12} container>
                <Typography className={classes.title}>{profile?.displayName || profile?.email}</Typography>
              </Grid>
              <Grid item xs={12} container>
                <Typography className={classes.subTitle}>{org.name}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.tabContainer}>
          <Tabs value={tab} onChange={(e, v) => setTab(v)} className={classes.tabs}>
            <LinkTab href={getRoute("profile")} value="about" label="About" className={classes.tab} />
            <LinkTab href={getRoute("profileSettings")} value="settings" label="Settings" className={classes.tab} />
          </Tabs>
        </Grid>
      </Grid>

      {prepedProfile &&
        <Grid item container className={classes.bodyGrid}>
          <Route exact path={routes.profile}>
            <ProfileAbout profile={prepedProfile} org={org} />
          </Route>
          <Route exact path={routes.profileSettings}>
            <ProfileSettings />
          </Route>
        </Grid>
      }
    </Grid>
  );
}

export default ProfileView;
