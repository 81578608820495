import { calcFee } from "features/projects";
import { uniq } from "lodash";
import { IClient, IProject } from "types";
import { isThisMonth, isThisYear } from "utils/date-helpers";
import { formatCurrency, formatPercent } from "utils/number-helpers";
import { IGoal, IGoalData, IGoalDefinition, IGoalVariable } from "./goal-types";
import { goalTokens } from "./goals-config";

export const getGoalDescription = (definition: IGoalDefinition, display: any, filterDisplay?: string) => {
  if(!definition.description) return "";

  const defaultDescription = goalTokens.reduce((result, token) => {
    if(result.indexOf(token[0]) >= 0){
      const defaultValue = definition.variables?.find((v: IGoalVariable) => v.id === token[1])?.defaultValue;
      const filterDisp = (token[1] === "filter" && filterDisplay) ? filterDisplay : null;
      const replaceValue = filterDisp ||  (display ? (display[token[1]] || defaultValue || token[2]) : defaultValue ||token[2]);
      return result.replaceAll(token[0], replaceValue);
    }
    return result;
  }, definition.description);

  return defaultDescription;
}

export const getGoalDescriptionTokenized = (definition: IGoalDefinition, display: any, filterDisplay?: string): [string, string[]] => {
  if(!definition.description) return ["", []];

  const swaps: string[] = [];
  const defaultDescription = goalTokens.reduce((result, token) => {
    if(result.indexOf(token[0]) >= 0){
      const defaultValue = definition.variables?.find((v: IGoalVariable) => v.id === token[1])?.defaultValue;
      const filterDisp = (token[1] === "filter" && filterDisplay) ? filterDisplay : null;
      const replaceValue = filterDisp ||  (display ? (display[token[1]] || defaultValue || token[2]) : defaultValue ||token[2]);
      swaps.push(replaceValue);
      return result.replaceAll(token[0], `|~~${swaps.length - 1}|`);
    }
    return result;
  }, definition.description);    

  return [defaultDescription, swaps];
}

//=== GOAL CALCULATIONS ===

//#region Revenue Goals

//Goal: revenue-annual
export const calcAnnualRevenue = (goal: IGoal, projects: IProject[]) => {
  const matches = projects.filter(p => isThisYear(p.deliveredDate));
  const revenue = matches.reduce((total, prj) => { return total + calcFee(prj); }, 0);
  const value = revenue;
  const displayValue = formatCurrency(revenue);
  const target = goal.amount;
  const displayTarget = formatCurrency(goal.amount);
  const percent = parseFloat((value / target).toFixed(2));
  const displayPercent = formatPercent(percent);

  return {
    value,
    displayValue,
    target,
    displayTarget,
    percent,
    displayPercent,
  }
}

//Goal: revenue-monthly
export const calcMonthlyRevenue = (goal: IGoal, projects: IProject[]) => {
  const matches = projects.filter(p => isThisMonth(p.deliveredDate));
  const revenue = matches.reduce((total, prj) => { return total + calcFee(prj); }, 0);
  const value = revenue;
  const displayValue = formatCurrency(revenue);
  const target = goal.amount;
  const displayTarget = formatCurrency(goal.amount);
  const percent = parseFloat((value / target).toFixed(2));
  const displayPercent = formatPercent(percent);

  return {
    value,
    displayValue,
    target,
    displayTarget,
    percent,
    displayPercent,
  };
}

export const calcRevenueBySize = (goal: IGoal, projects: IProject[]) => {
  const matches = projects.filter(c => isThisYear(c.startDate) && calcFee(c, true) >= goal.amount);
  const value = matches.length;
  const displayValue = matches.length.toString();
  const target = goal.count;
  const displayTarget = goal.count.toString();
  const percent = parseFloat((value / target).toFixed(2));
  const displayPercent = formatPercent(percent);

  return {
    value,
    displayValue,
    target,
    displayTarget,
    percent,
    displayPercent,
  };
}

//#endregion

//#region Project Goals

//Goal: projects-new
export const calcNewProjects = (goal: IGoal, projects: IProject[]) => {
  const matches = projects.filter(p => isThisYear(p.startDate));
  const value = matches.length;
  const displayValue = matches.length.toString();
  const target = goal.count;
  const displayTarget = goal.count.toString();
  const percent = parseFloat((value / target).toFixed(2));
  const displayPercent = formatPercent(percent);

  return {
    value,
    displayValue,
    target,
    displayTarget,
    percent,
    displayPercent,
  }
}

//Goal: projects-type
export const calcProjectTypes = (goal: IGoal, projects: IProject[]) => {
  const matches = projects.filter(p => isThisYear(p.startDate) && p.category === goal.filter);
  const value = matches.length;
  const displayValue = matches.length.toString();
  const target = goal.count;
  const displayTarget = goal.count.toString();
  const percent = parseFloat((value / target).toFixed(2));
  const displayPercent = formatPercent(percent);

  return {
    value,
    displayValue,
    target,
    displayTarget,
    percent,
    displayPercent,
  };
}

//Goal: projects-expand
// export const calcProjectExpansion = (goal: IGoal, projects: IProject[]) => {
//   //TODO: Don't have a way of calculating team you're working on...
//   return {};
// }

//#endregion

//#region Client Goals

//clients-new
export const calcNewClients = (goal: IGoal, clients: IClient[]) => {
  const matches = clients.filter(c => isThisYear(c.createdDate));
  const value = matches.length;
  const displayValue = matches.length.toString();
  const target = goal.count;
  const displayTarget = goal.count.toString();
  const percent = parseFloat((value / target).toFixed(2));
  const displayPercent = formatPercent(percent);

  return {
    value,
    displayValue,
    target,
    displayTarget,
    percent,
    displayPercent,
  };
}

//clients-diversity
export const calcClientDiversity = (goal: IGoal, clients: IClient[], projects: IProject[]) => {
  //clients with a category
  const clientsWithCategory = clients.filter(c => !!c.category);
  
  //Create a list of client categories from clients who have one or more projects (from this year)
  const categories = clientsWithCategory.reduce((result: string[], c: IClient) => {
    if(!c.category) return result;  //to make typescript happy
    //client projects that started this year
    const cat = c.category;
    const index = result.findIndex(r => r === cat);
    if(index < 0) {
      //don't have this category yet, so see if this client meets the requirements
      const cliProjects = projects.filter(p => p.clientId === c.id && isThisYear(p.startDate));
      if(cliProjects.length > 0){
        return [...result, cat];
      }
    }
    return result;
  }, []);

  const matches = uniq(categories);
  const value = matches.length;
  const displayValue = matches.length.toString();
  const target = goal.count;
  const displayTarget = goal.count.toString();
  const percent = parseFloat((value / target).toFixed(2));
  const displayPercent = formatPercent(percent);

  return {
    value,
    displayValue,
    target,
    displayTarget,
    percent,
    displayPercent,
  };
}

//clients-type
export const calcClientTypes = (goal: IGoal, clients: IClient[], projects: IProject[]) => {
  const myClients = clients.filter(c => c.category === goal.filter);
  const withProjects = myClients.map(c => {
    const cliProjects = projects.filter(p => p.clientId === c.id && isThisYear(p.startDate));
    return { id: c.id, count: cliProjects.length };
  });
  const matches = withProjects.filter(wp => wp.count > 0);
  const value = matches.length;
  const displayValue = matches.length.toString();
  const target = goal.count;
  const displayTarget = goal.count.toString();
  const percent = parseFloat((value / target).toFixed(2));
  const displayPercent = formatPercent(percent);

  return {
    value,
    displayValue,
    target,
    displayTarget,
    percent,
    displayPercent,
  };
}

//#endregion

//--Gets the data for a goal
export const getGoalData = (goal: IGoal, clients: IClient[], projects: IProject[]) => {
  if(!goal) return null;
  const now = new Date();

  const baseData: IGoalData = {
    year: now.getFullYear(),
    month: now.getMonth(),     
    value: 0,
    displayValue: "",
    target: 0,
    displayTarget: "",
    percent: 0,
    displayPercent: "",
    description: "",
  }

  let values: Record<string, any> = {};

  switch(goal.definitionId){
    case "revenue-annual":
      values = calcAnnualRevenue(goal, projects);
      break;
    
    case "revenue-monthly":
      values = calcMonthlyRevenue(goal, projects);
      break;

    case "revenue-size":
      values = calcRevenueBySize(goal, projects);
      break;

    case "clients-new":   //TODO: Should I only include clients with a project this year?
      values = calcNewClients(goal, clients);
      break;

    case "clients-diversity":
      values = calcClientDiversity(goal, clients, projects);
      break;

    case "client-type":
      values = calcClientTypes(goal, clients, projects);
      break;

    case "projects-new":
      values = calcNewProjects(goal, projects);
      break;

    case "projects-type":
      values = calcProjectTypes(goal, projects);
      break;

  }

  return {...baseData, ...values};
}

