import React, { useMemo } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Invoice, ISort, RootState } from "types";
import { useLocalization, useSnackbar, useDialogs } from "utils/hooks";
import AtkTable from "components/atk-table";
import InvoicesListRow, { rowHeader } from "./invoices-list-row";
import { deleteInvoice } from "../infra/invoice-actions";
import { cardStyles } from "utils/styles";
import { selectFilteredInvoices, selectInvoicesWithProjects } from "../infra/invoice-selectors";
import { formatCurrency } from "utils/number-helpers";

const buildStyles = makeStyles(theme => ({
  ...cardStyles(theme),
  deleteRow: {
    background: `${theme.palette.error.main}33`, //`
  }
}));

type Props = {
  queryKey?: string;
  clientId?: string;
  projectId?: string;
  isWorking?: boolean;  
  canDelete?: boolean;
  withVirtual?: boolean;
  rowsPerPageOptions?: number[];
}

const InvoiceList = (props: Props) => {
  const classes = buildStyles();
  const dispatch = useDispatch();
  const {L, LSwap} = useLocalization();
  const { clientId, projectId, withVirtual, rowsPerPageOptions } = props;
  const status = useSelector((state: RootState) => state.invoices.status);
  const allInvoices = useSelector(selectInvoicesWithProjects);
  const filteredInvoices = useSelector(selectFilteredInvoices);
  const [settings, setSettings] = React.useState<ISort>({ sort: "invoiceDate", sortDir: "desc" });
  const { openDialog } = useDialogs();
  const notify = useSnackbar();

  //the rows for the table
  const rows = React.useMemo(() => {
    //If no client or project, then dealing with the slice filters
    if(!clientId && !projectId) return filteredInvoices;
    //Otherwise, filter based on the provided props
    let working = withVirtual === true ? allInvoices : allInvoices.filter(inv => !inv.isProject);
    working = (!clientId && !projectId) ? working : working.filter(inv => projectId && (inv.projectList || []).indexOf(projectId) >= 0 || clientId && inv.clientId === clientId);
    return working;
  }, [withVirtual, filteredInvoices, allInvoices, clientId, projectId]);
  
  const viewAmount = useMemo(() => {
    return _.sumBy(rows, r => r.amount);
  }, [rows]);
  
  const caption = useMemo(() => {
    return `List Total: ${formatCurrency(viewAmount)}`;
  }, [viewAmount]);
  
  const onTableSorted = (order: ISort) => {
    const sort = (order.sort === "status" || order.sort === "alerts") ? "startDate" : order.sort;
    setSettings({ sort: sort, sortDir: order.sortDir });
  }

  const onEditInvoice = (invoiceId: string) => () => {
    //Different actions, depending on whether it's a real or virtual invoice
    const inv = allInvoices.find(inv => inv.id === invoiceId);
    if(!inv) return;
    if(inv.isProject){
      openDialog("project", inv.projectId, {isExpanded: true, focusField: "invoicedDate"});
    }
    else{
      openDialog("invoice", invoiceId);
    }
  }

  const onInvoiceDeleted = async (invoice: Invoice) => {
    console.log("delete invoice ", invoice.id);
    const result = await dispatch(deleteInvoice(invoice.id));
    if(!!result){
      notify("invoice deleted successfully.");
    }
  }

  return (
    <Grid id="invoice-list" container>
      <Grid container>
        <AtkTable
          rowId="id"
          header={rowHeader(L, !projectId, (!clientId && !projectId))} //add project and client columns
          rows={rows}
          RowComponent={InvoicesListRow}
          dense={true}
          order={{ sort: settings.sort, sortDir: settings.sortDir }}
          onSort={onTableSorted}
          emptyMessage={LSwap("This client has no invoices", "client")}
          settingKey="cli-inv-list"
          caption={caption}
          rowsPerPageOptions={rowsPerPageOptions}
          isWorking={status.isWorking}
          extra={{
            classes: classes,
            onEdit: onEditInvoice,
            onDelete: onInvoiceDeleted,
            LSwap: LSwap,
          }} />
      </Grid>
    </Grid>
  );
}

export default InvoiceList;
