import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import { widgetStyles, generalStyles } from "utils/styles";
import { GoalContent } from "features/goals/ui/goal-card";
import { useGoals } from "utils/hooks";
import { CellSize } from "types";

const buildStyles   = makeStyles(theme => ({
  ...widgetStyles(theme),
  ...generalStyles(theme),
  upcase: {
    textTransform: "uppercase",
  }
}));

const getClosesSize = (size: number, min?: CellSize, max?: CellSize): CellSize => {
  const whole = Math.ceil(size);
  if(whole > 12) return max || 12;
  else{
    if(min && whole < min) return min as CellSize;
    return whole as CellSize;
  } 
}

const GoalsWidget = () => {
  const classes   = buildStyles();
  const goals = useGoals();
  const dashGoals = useMemo(() => goals.filter(g => g.showOnDash === true), [goals]);
  
  const goalCount = dashGoals.length;
  if(goalCount === 0) return null;

  const itemSize = getClosesSize(12 / goalCount, 2);
  const widgetSize = getClosesSize(goalCount * 2);

  return (
    <Grid item md={widgetSize} sm={8} xs={12}>
      <Paper className={classes.widget}>
        <Grid container className={classes.widgetHeader} alignItems="center">
          <Typography className={clsx([classes.widgetHeaderText, classes.upcase])}>Goals</Typography>
          <IconButton component={Link} to="/goals" className={classes.headerNavButton}>
            <Icon color="disabled" fontSize="small">arrow_forward</Icon>
          </IconButton>
        </Grid>
        <Grid container className={classes.widgetBody} spacing={2}>
          {
            dashGoals?.map(g => {
              return (
                <Grid key={g.id} item md={itemSize} sm={3} xs={6}>
                  <GoalContent goal={g} size="s" showTitle={true}/>
                </Grid>
              );
            })
          }
        </Grid>
      </Paper>
    </Grid>
  );
}

export default GoalsWidget;
