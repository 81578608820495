import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Paper from '@material-ui/core/Paper';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import { selectAllWidgetData, updateInvoiceSettings } from "features/invoices";
import { allRevenueOptions } from "./dashboard-helpers";
import { updateProjectSettings } from "features/projects";
import { ProjectFilter } from "types";
import { formatCurrency } from "utils/number-helpers";
import ChartPanel from "./chart-panel";

const useStyles   = makeStyles(theme => ({
  root  : {
    padding     : theme.spacing(2),
    background: theme.palette.common.white,
    borderRadius: 5,
  },
  titleRow: {
    marginBottom: theme.spacing(1),
  },
  title: {
    fontSize: 18,
    fontWeight: 400,
    color: theme.palette.grey[500],
    marginBottom: theme.spacing(1),
  },
  subScript: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.grey[500],
  },
  total: {
    fontSize: 30,
    fontWeight: 400,
    color: theme.palette.primary.main,
  },
  subTotal: {
    fontSize: 24,
    fontWeight: 300,
    color: theme.palette.secondary.main,
  },
  label: {
    fontSize: 18,
    fontWeight: 300,
    "& svg, .MuiIcon-root": {
      marginLeft: theme.spacing(1),
    }
  },
  navButton: {
    padding: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
  },
  navButtonRight: {
    padding: theme.spacing(0.5),
    // marginRight: theme.spacing(1),
  },
  chartStyle: {
    marginTop: -100,
  }
}));

const AllRevenueWidget = () => {
  const classes   = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const widgetData = useSelector(selectAllWidgetData);
  const myOptions = React.useMemo(() => allRevenueOptions(), []);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const chartStyle = isSmall ? {marginTop: -50, marginLeft: -25} : {marginTop: -75, marginLeft: -10};
  const chartWidth = isSmall ? "100%" : "110%";

  const navTo = (path: string, filter?: ProjectFilter) => () => {
    if (filter) {
      let changes = {};
      switch (filter) {
        case "unpaid": {
          changes = { filter: "unpaid", sort: "invoiceDate", sortDir: "asc" }; 
          dispatch(updateInvoiceSettings(changes));
          break;
        }
        case "uninvoiced": {
          changes = { filter: "uninvoiced", sort: "deliveredDate", sortDir: "asc" }; 
          dispatch(updateProjectSettings(changes));
          break;
        }
      }
    }

    history.push(path);
  }

  return (
    <Paper>
      <Grid id="revenue-widget" container justify="center" alignItems="flex-start" className={classes.root}>
        <Grid container className={classes.titleRow} alignItems="flex-start" style={{zIndex: 3}}>
          <Grid item xs={8} container alignItems="flex-start">
            <Typography className={classes.title}>REVENUE</Typography>
            <IconButton onClick={navTo("/invoices", "unpaid",)} className={classes.navButton}><Icon color="disabled" fontSize="small">arrow_forward</Icon></IconButton>
          </Grid>        
          {widgetData?.countUninvoiced && 
            <Grid item xs={4} container justify="flex-end" alignItems="flex-start">
              <Tooltip title={`You have ${widgetData?.countUninvoiced} projects that are delivered but not invoiced`}>
                <IconButton onClick={navTo("/projects", "uninvoiced",)} className={classes.navButtonRight}><Icon color="error" fontSize="small">warning</Icon></IconButton>
              </Tooltip>
            </Grid>
          }
          
        </Grid>

        <Grid container alignItems="flex-start">
          <Grid item md={6} sm={12} container direction="column">
            <Tooltip title="YTD Revenue">
              <Typography className={classes.total}>{formatCurrency(widgetData?.totalRevenue)}</Typography>
            </Tooltip>
            <Tooltip title={`${widgetData?.countUnpaid} items due`}>
              <Typography className={classes.subTotal}>{formatCurrency(widgetData?.totalUnpaid)} <span className={classes.subScript}>DUE</span></Typography>
            </Tooltip>
          </Grid>
          <Grid item md={6} sm={12} container>
            {widgetData && widgetData.chartSeries && <ChartPanel options={myOptions} series={widgetData.chartSeries} type="bar" minWidth={100} width={chartWidth} maxWidth={"200%"} height={175} style={chartStyle} />}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default AllRevenueWidget;
