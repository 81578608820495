import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import FilledIcon from '@material-ui/icons/CheckCircle';
import { useClients, useDialogs, useLocalization } from "utils/hooks";
import { fontStyles, formStyles, generalStyles } from "utils/styles";
import { IStepProps, OnboardingScreens } from "./onboarding-view";

const buildStyles   = makeStyles(theme => ({
  ...generalStyles(theme),
  ...formStyles(theme),
  ...fontStyles(theme),
  root  : {
    marginBottom: theme.spacing(3),
    // padding: theme.spacing(2),
  },  
  actionButton: {
    minWidth: 250,
    margin: `${theme.spacing(1)}px ${theme.spacing(0)}px`, //`
  },
  success: {
    color: theme.palette.success.main,
  },
  body: {
    fontSize: 16,
    fontWeight: 400,
    textAlign: "center",
  },
  cardBody: {
    // height: `calc(100% - ${theme.spacing(13)}px)`,
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    }
  },
  leftCol: {
    margin: `${theme.spacing(2)}px ${theme.spacing(0)}px`, //`
    // height: `calc(100% - ${theme.spacing(4)}px)`,
    borderRight: `1px solid ${theme.palette.grey[300]}`, //`
    [theme.breakpoints.down("xs")]: {
      height: "auto",
      borderRight: "none",
      borderBottom: `1px solid ${theme.palette.grey[300]}`, //`
    }
  },
  rightCol: {
    margin: `${theme.spacing(2)}px ${theme.spacing(0)}px`, //`
    // height: `calc(100% - ${theme.spacing(4)}px)`,
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    }
  },
  content: {
    padding: `${theme.spacing(3)}px ${theme.spacing(1)}px`, //`
  },  
}));

const OnboardingClients = ({onNavStateChange}: IStepProps) => {
  const classes   = buildStyles();
  const clients = useClients();
  const {L, LSwap} = useLocalization();
  const { onOpenDialog } = useDialogs();
  
  useEffect(() => {
    onNavStateChange({prevOverride: OnboardingScreens.choice});
  }, []);

  useEffect(() => {
    const hasClients = Boolean(clients && clients.length > 0);
    onNavStateChange({canPrevious: !hasClients, canNext: hasClients, prevOverride: OnboardingScreens.choice});
  }, [clients]);

  return (
    <Grid item container justify="center" alignContent="flex-start" className={classes.root}>
      <Grid item xs={12} container justify="center" direction="column" className={clsx(classes.mb, classes.mtl, classes.marginX)}>
        {clients.length <= 0 && 
          <>
            <Typography color="primary" className={clsx(classes.pageHead, classes.textCenter)}>{`${L("clients", true)}`}</Typography>
            <Typography color="secondary" className={clsx(classes.subTitle, classes.textCenter)}>{LSwap("You will need some clients to get started.", "clients")}</Typography>
          </>
        }
        {clients.length > 0 && 
          <>
            <Typography color="primary" className={clsx(classes.pageHead, classes.textCenter)}>{LSwap(`You have ${clients.length} client(s)`, "clients")}</Typography>
            <Typography color="secondary" className={clsx(classes.subTitle, classes.textCenter)}>{"That's all you need to get started"}</Typography>
          </>
        }
      </Grid>
      <Grid item container direction="column" wrap="nowrap" alignItems="center" justify="flex-start" className={clsx(classes.mbl, classes.mtl)}>
        {clients.length > 0 && 
          <>
            <Tooltip title="All set!"><FilledIcon className={clsx(classes.success, classes.mtxl)} fontSize="large"/></Tooltip>
            <Typography className={clsx(classes.success, classes.subTitle, classes.textCenter, classes.mt)}>All Set!</Typography>
            <Typography className={clsx(classes.success, classes.subTitle, classes.textCenter, classes.mt)}>(You can add more later)</Typography>
          </>
        }
        {clients.length <= 0 && 
          <Grid container className={classes.cardBody}>        
            <Grid item sm={6} xs={12} container className={classes.leftCol}>
              <Grid item container direction="column" justify="space-between" alignItems="center" className={classes.content}>
                <Typography variant="h3" className={clsx(classes.body, classes.mtl, classes.mbl, classes.marginX)}>{"Create your clients manually"}</Typography>
                <Button variant="outlined" color="primary" className={clsx([classes.actionButton, classes.marginX])} onClick={onOpenDialog("clientMulti", -1)}>{LSwap("Create clients", "clients")}</Button>
              </Grid>
            </Grid>
            
            <Grid item sm={6} xs={12} container className={classes.rightCol}>
              <Grid item container direction="column" justify="space-between" alignItems="center" className={classes.content}>
                <Typography variant="h3" className={clsx(classes.body, classes.mtl, classes.mbl, classes.marginX)}>{"Import your clients from a spreadsheet or .csv file"}</Typography>
                <Button onClick={onOpenDialog("import", "clients")} variant="outlined" color="primary" className={clsx([classes.actionButton, classes.marginX])}>{LSwap("Import clients", "clients")}</Button>
              </Grid>
            </Grid>
          </Grid>
        }
      </Grid>
    </Grid>
  );
}

export default OnboardingClients;
