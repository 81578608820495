import _ from "lodash";
import { Exportable, IClient, Invoice, IProject, ITimer, ITimerGroup } from "types";
import { formatDate, formatTime} from "utils/date-helpers";
import { formatMinutesDuration } from "features/timesheet/infra/timesheet-helpers";

const LINE_BREAK = '\n';

//----------------------
// Converts the data into a blob, and triggers a download
//----------------------
export const doDownload = (data: any, format: "csv" | "json", fileName: string) => {
  const actualFileName = fileName || `gigops-download.${format}`; //`
  let blob = null;
  if(format === "json"){
    //Create a blob with the json data
    const stringData = JSON.stringify(data, null, 2);
    blob = new Blob([stringData], {type : 'application/json'});
  }
  else if(format === "csv" && _.isString(data)){
    blob = new Blob([data])
  }

  const linkElement = document.createElement('a');
  try {
      //To get the file to download, need to create a virtual link pointed to the file url and "click" it.
      const url = window.URL.createObjectURL(blob);
      linkElement.setAttribute('href', url);
      linkElement.setAttribute("download", actualFileName);

      const clickEvent = new MouseEvent("click", {
          "view": window,
          "bubbles": true,
          "cancelable": false
      });
      linkElement.dispatchEvent(clickEvent);

      return true;    //indicate it worked

  } catch (ex) {
      console.log(ex);
      return false;
  }
}

//---
// Will wrap a string with double-quotes (\"{string}\") if it has commas in it, otherwise just returns it
function prepareString(str: string | null | undefined, fallback = ""){
  if(!str) return fallback;
  const noCommas = (str.indexOf(",") >= 0) ? `\"${str.replaceAll('\"', '\'')}\"` : str;
  const noLinefeeds = (noCommas.indexOf('\n') >= 0) ? noCommas.replaceAll('\n', "<lf>") : noCommas;
  return noLinefeeds;
}

//---
// Removes a comma from the end of the string, if present
function csvLinebreak(str: string){
  if(str.endsWith(",")) str = str.slice(0, str.length - 1);
  return `${str}${LINE_BREAK}`
}
interface ExportField {
  label: string;
  isRequired?: boolean;
  tooltip?: string;
  excludeFromTemplate?: boolean;
}

interface ExportMap<T> extends ExportField {
  // label: string;
  format: (item: T) => string;
  // isRequired?: boolean;
  // tooltip?: string;
  // excludeFromTemplate?: boolean;
}

function doExport<T>(items: T[], map: ExportMap<T>[], fileName: string){
  const headers = csvLinebreak(map.reduce((accum, map) => { return `${accum}${map.label},`; }, ""));
  const text = items.reduce((accum, item) => {
    let row = map.reduce((rowAccum, map) => {
      return `${rowAccum}${map.format(item)},`;
    }, "");
    row = csvLinebreak(row);
    return `${accum}${row}`;
  }, headers);
  
  const result = doDownload(text, "csv", fileName);
  return result;
}

const timerMap: ExportMap<ITimer>[] = [
  { label: "Date", format: t => formatDate(t.startTime), isRequired: true,}, 
  { label: "Start Time", format: t => formatTime(t.startTime), isRequired: true },
  { label: "Stop Time", format: t => formatTime(t.stopTime), isRequired: true },
  { label: "Client Name", format: t => prepareString(t.clientName) },
  { label: "Project Name", format: t => prepareString(t.projectName) },
  { label: "Time", format: t => t.minutes ? formatMinutesDuration((t.minutes || 0) - (t.creditMinutes || 0), true) : "0", excludeFromTemplate: true},
  { label: "Category", format: t => prepareString(t.category) },
  { label: "Invoice Date", format: t => formatDate(t.invoiceDate) },
  { label: "Payment Date", format: t => formatDate(t.paymentDate) },
  { label: "Notes", format: t => prepareString(t.notes) },
  { label: "Minutes", format: t => Math.round(t.minutes || 0).toString() || "", excludeFromTemplate: true, },
  { label: "CreditMinutes", format: t => t.creditMinutes ? Math.round(t.creditMinutes).toString() : "", excludeFromTemplate: true },
  { label: "Rounding", format: t => (!t.roundType || t.roundType === "none") ? "" : t.roundType },
  { label: "Client Id", format: t => t.clientId },
  { label: "Project Id", format: t => t.projectId, isRequired: true },
];

const timerGroupMap: ExportMap<ITimerGroup>[] = [
  { label: "Date", format: t => formatDate(t.items[0].startTime), isRequired: true,}, 
  { label: "Client Name", format: t => prepareString(t.items[0].clientName) },
  { label: "Project Name", format: t => prepareString(t.items[0].projectName) },
  { label: "Time", format: t => t.minutes ? formatMinutesDuration((t.minutes || 0) - (t.creditMinutes || 0), true) : "0", excludeFromTemplate: true},
  { label: "Category", format: t => prepareString(t.items[0].category) },
  { label: "Notes", format: t => prepareString(t.notes) },
  { label: "Minutes", format: t => Math.round(t.minutes || 0).toString() || "", excludeFromTemplate: true, },
  { label: "CreditMinutes", format: t => t.creditMinutes ? Math.round(t.creditMinutes).toString() : "", excludeFromTemplate: true },
  { label: "Client Id", format: t => t.items[0].clientId },
  { label: "Project Id", format: t => t.items[0].projectId, isRequired: true },
]

//----
// Exports the provided timers to a csv file
export const exportHoursToCsv = (timers: ITimer[], fileName = "gigop-hours.csv") => {
  const result = doExport(timers, timerMap, fileName);
  return result;  
}

export const exportHoursGroupsToCsv = (groups: ITimerGroup[], fileName = "gigop-hours-grouped.csv") => {
  const result = doExport(groups, timerGroupMap, fileName);
  return result;  
}

const clientMap: ExportMap<IClient>[] = [
  { label: "Id", format: c => c.id, excludeFromTemplate: true },
  { label: "Name", format: c => prepareString(c.name), isRequired: true, tooltip: "Name of the client"}, 
  { label: "Contact", format: t => prepareString(t.contactName), tooltip: "Primary contact name" },
  { label: "Email", format: t => prepareString(t.contactEmail) ?? "", tooltip: "Primary contact email" },
  { label: "Phone", format: t => prepareString(t.contactPhone) ?? "", tooltip: "Primary contact phone" },
  { label: "Category", format: t => prepareString(t.category) ?? "", tooltip: "Client Category", },
  { label: "Rate", format: t => t.defaultRate ? t.defaultRate.toString() : "", tooltip: "Typical hourly rate or project fee"},
  { label: "Project Type", format: t => t.defaultProjectType ?? "", tooltip: "Typical project type (perHour, fixed, perArticle, perWord)"},
  { label: "Notes", format: t => prepareString(t.notes), tooltip: "Notes" },
  { label: "DaysToPay", format: t => t.daysToPay ? t.daysToPay.toString() : "", tooltip: "The number of days this client usually takes to pay invoices."},
]

export const exportClientsToCsv = (clients: IClient[], fileName = "gigops-clients.csv") => {
  const result = doExport(clients, clientMap, fileName);
  return result;
}


const projectMap: ExportMap<IProject>[] = [
  { label: "Id", format: p => p.id, excludeFromTemplate: true },
  { label: "Name", format: p => prepareString(p.name), isRequired: true, tooltip: "Name of the project"}, 
  { label: "Client Id", format: t => t.clientId, excludeFromTemplate: true },
  { label: "Client", format: t => prepareString(t.clientName), tooltip: "Name of the client (should match one of your clients)" },
  { label: "Category", format: t => prepareString(t.category) ?? "", tooltip: "Project Category", },
  { label: "Started", format: t => formatDate(t.startDate), isRequired: true, tooltip: "Start Date" },
  { label: "Due", format: t => formatDate(t.dueDate), tooltip: "Due date" },
  { label: "Delivered", format: t => formatDate(t.deliveredDate), tooltip: "Delivered date"},
  { label: "Invoiced", format: t => formatDate(t.invoicedDate), tooltip: "Invoiced date"},
  { label: "Paid", format: t => formatDate(t.paidDate), tooltip: "Paid date"},
  { label: "Fee", format: t => t.fee ? t.fee.toString() : "", tooltip: "The amount you are/were paid for this project" },
  { label: "Type", format: t => t.type, tooltip: "Fee Type for the project (perHour, perArticle, perWord, fixed)" },
  { label: "Units", format: t => t.units ? t.units.toString() : "", tooltip: "The number of items (e.g. words or articles)" },
  { label: "Notes", format: t => prepareString(t.notes) },
]

export const exportProjectsToCsv = (projects: IProject[], fileName = "gigops-projects.csv") => {
  const result = doExport(projects, projectMap, fileName);
  return result;  
}

const invoiceMap: ExportMap<Invoice>[] = [
  { label: "Id", format: c => c.id, excludeFromTemplate: true, },
  { label: "Number", format: c => c.number ?? "", isRequired: true,}, 
  { label: "Client Id", format: t => t.clientId },
  { label: "Project Id", format: t => t.projectId, isRequired: true },
  { label: "Date", format: t => formatDate(t.invoiceDate), isRequired: true },
  { label: "Due", format: t => formatDate(t.dueDate), isRequired: true },
  { label: "Paid", format: t => formatDate(t.paidDate) },
  { label: "Amount", format: t => t.amount.toString() },
  { label: "Notes", format: t => prepareString(t.notes) },
]

export const exportInvoicesToCsv = (invoices: Invoice[], fileName = "gigops-invoices.csv") => {
  // const filtered = invoices.filter(inv => !inv.isProject);  //don't want to export any projects as invoices
  const result = doExport(invoices, invoiceMap, fileName);
  return result;
}

export function getExportMap(itemType: Exportable): ExportField[] {
  switch(itemType){
    case "clients": return clientMap.filter(m => !m.excludeFromTemplate);
    case "projects": return projectMap.filter(m => !m.excludeFromTemplate);
    case "invoices": return invoiceMap.filter(m => !m.excludeFromTemplate);
    case "hours": return timerMap.filter(m => !m.excludeFromTemplate);
    default: return [];
  }
}

export const exportTemplate = (itemType: Exportable) => {
  let map: any = null;

  switch(itemType){
    case "clients": 
      map = clientMap.filter(m => !m.excludeFromTemplate);
      break;
    case "projects": 
      map = projectMap.filter(m => !m.excludeFromTemplate);
      break;
    case "invoices": 
      map = invoiceMap.filter(m => !m.excludeFromTemplate);
      break;
    case "hours": 
      map = timerMap.filter(m => !m.excludeFromTemplate);
      break;
  }

  const header = csvLinebreak(map.reduce((accum: string, map: {label: string}) => { return `${accum}${map.label},`; }, ""));
  const result = doDownload(header, "csv", `${itemType}-template.csv`);
  return result;
}