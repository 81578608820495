import React from "react";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import { Integration } from "./integration-types";
import { cardStyles, fontStyles, generalStyles } from "utils/styles";
import { fetchExternalData } from "./integration-actions";
import { LoadingIndicator } from "components";

const buildStyles   = makeStyles(theme => ({
  ...generalStyles(theme),
  ...fontStyles(theme),
  ...cardStyles(theme),
  root  : {
    padding     : theme.spacing(1),
  },  
}));

interface ControlProps {
  item: Integration;
  isWorking: boolean;
}

const IntegrationItem = ({item, isWorking}: ControlProps) => {
  const classes   = buildStyles();
  const dispatch = useDispatch();

  const handleFetch = (item: Integration) => async () => {
    console.log("fetch data for ", item.id);
    const clients = await dispatch(fetchExternalData(item, "clients"));
    const projects = await dispatch(fetchExternalData(item, "projects"));
    const hours = await dispatch(fetchExternalData(item, "time_entries"));
    const data = {
      clients,
      projects,
      hours
    };

    console.log("fetched external data", data);
  }

  return (
    <Grid item md={3}>
      <Paper className={classes.card} variant="outlined">
        <Grid container justify="center" className={classes.cardHeader}>
          <Typography color="primary" className={clsx(classes.upcase, classes.title, classes.centerText)}>{item.id}</Typography>
        </Grid>
        <Grid item container direction="column" alignItems="flex-start" className={classes.cardBody}>
          {isWorking && <LoadingIndicator isVisible={true} message="Fetching..." />}
          {!isWorking && 
            <Grid container direction="column">
              <Grid container justify="space-between">
                <Typography color="secondary" className={classes.subTitle}>Status:</Typography>
                <Typography className={classes.infoText}>{item.token ? " connected" : " not connected"}</Typography>
              </Grid>
              <Grid container justify="space-between">
                <Typography color="secondary" className={classes.subTitle}>Data:</Typography>
                <Typography className={classes.infoText}>{item.data ? " loaded" : " not loaded"}</Typography>
              </Grid>
              <Button color="primary" variant="outlined" onClick={handleFetch(item)} size="small" className={classes.mt}>Fetch Data</Button>
            </Grid>
          }      
        </Grid>
      </Paper>
    </Grid>
  )
}

export default IntegrationItem;
