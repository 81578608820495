import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import Grid from "@material-ui/core/Grid";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import { cardStyles, generalStyles, fontStyles } from "utils/styles";
import { useBoolState, useHover } from "utils/hooks";
import MetricContent from "./metric-content";
import { IMetric } from "../infra/metrics-types";
import MetricDivider from "./metric-divider";

const buildStyles   = makeStyles(theme => ({
  ...cardStyles(theme),
  ...generalStyles(theme),
  ...fontStyles(theme),
  rootContent: {
    paddingLeft: theme.spacing(0),
  },
  headerContent  : {
    margin: 0,
  },
  fullHeight: {
    height: `calc(100% - 20px)`,
  },
  favCol: {
    width: theme.spacing(6),
  },
  contentCol: {
    width: `calc(100% - ${theme.spacing(6)}px)`,
  },
  favoriteButton: {
    marginBottom: theme.spacing(-1),
  },  
}));

interface ControlProps{
  metric: IMetric;
  isFavorite: boolean;
  onToggleFavorite: (id: string) => void;
  category: string;
  period: string;
}

const MetricCardContent = ({metric, isFavorite, onToggleFavorite, category, period}: ControlProps) => {
  const classes   = buildStyles();
  const [isFav, toggleFav] = useBoolState(isFavorite);
  
  const onFavorite = (e: any) => {
    e.stopPropagation();
    toggleFav(null);
    onToggleFavorite(metric.id);
  }

  return (
    <Grid container>
      <Grid item container alignItems="center" className={classes.favCol}>
        <Tooltip title={isFav ? "Make this metric a Favorite" : "Un-favoriate this metric"}>
          <IconButton onClick={onFavorite} className={classes.favoriteButton}>{isFav ? <StarIcon color="secondary"/> : <StarBorderIcon />}</IconButton>
        </Tooltip>
      </Grid>
      <Grid item className={classes.contentCol}>
        <MetricContent metric={metric} category={category} period={period} />
      </Grid>
    </Grid>
  )
}

const MetricCard = ({metric, isFavorite, onToggleFavorite, category, period}: ControlProps) => {
  const classes   = buildStyles();
  const [isExpanded, toggleExpanded] = useBoolState(false);
  const [hoverRef, isHovered] = useHover();
  
  const onAccordianChange = () => {
    toggleExpanded(null);
  }

  //Should this metric be hidden?
  if(metric.data?.displayValue === "n/a" && metric.hideWhenNa === true) return null;

  if(metric.isExpandable){
    return (
      <Accordion ref={hoverRef} variant="outlined" className={clsx([classes.card, classes.mbl])} onChange={onAccordianChange}>
        <AccordionSummary classes={{root: classes.rootContent, content: classes.headerContent}}>
          <Grid container direction="column">
            <MetricCardContent  metric={metric} category={category} period={period} isFavorite={isFavorite} onToggleFavorite={onToggleFavorite} />
            <MetricDivider isHovered={isHovered} isExpanded={isExpanded} onToggle={toggleExpanded} />
          </Grid>
        </AccordionSummary>
      </Accordion>
    );
  }
  else{
    return (
      <Paper variant="outlined" className={clsx([classes.card, classes.mbl])}>
        <MetricCardContent  metric={metric} category={category} period={period} isFavorite={isFavorite} onToggleFavorite={onToggleFavorite} />        
      </Paper>
    );
  }
}

export default MetricCard;
