import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import Avatar from '@material-ui/core/Avatar';
import { useLocalization } from "utils/hooks";
import { generalStyles } from "utils/styles";
import { exportTemplate, getExportMap } from "utils/export-helper";
import { Exportable } from "types";

const buildStyles   = makeStyles(theme => ({
  ...generalStyles(theme),
  root  : {
    padding: theme.spacing(1),
    paddingRight: theme.spacing(2),
    maxHeight: 300,
    overflowY: "auto",
    // borderRight: `1px solid ${theme.palette.grey[300]}`, //`
  },
  instructions: {
    fontSize: 18,
    fontWeight: 400,
    marginBottom: theme.spacing(1),
  },
  emphasis: {
    fontSize: 18,
    fontWeight: 400,
    color: theme.palette.secondary.main,
    marginBottom: theme.spacing(1),
  },
  actionButton: {
    margin: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
  fieldChip: {
    margin: theme.spacing(0.5),
  }
}));

interface Props{
  itemType: Exportable;
  isWorking?: boolean;
}

const ImportInstructions = ({itemType, isWorking}: Props) => {
  const classes   = buildStyles();
  const { LSwap } = useLocalization();
  const fields = getExportMap(itemType);

  const onDownload = () => {
    exportTemplate(itemType);
  }

  return (
    <Grid id="import-instructions" container justify="center" className={classes.root}>
      <Typography className={classes.instructions}>
        {`You can import a .csv file with existing ${LSwap((itemType || "clients"), ["clients", "projects", "invoices", "hours"])} and the following columns:`}
      </Typography>
      
      <Grid container direction="row">
        {fields.map((field: any) => {
          return (
            <Tooltip key={field.label} title={`${field.isRequired ? "Required" : "Optional"}: ${field.tooltip || field.label}`}>
              <Chip className={classes.fieldChip} variant="outlined" color={field.isRequired ? "primary" : undefined} avatar={field.isRequired ? <Avatar>R</Avatar> : undefined} label={field.label}/>
            </Tooltip>
          )
        })}
      </Grid>
      
      <Button fullWidth size="small" color="primary" variant="outlined" className={classes.actionButton} disabled={isWorking} onClick={onDownload}>Download Template</Button>

    </Grid>
  );
}

export default ImportInstructions;
