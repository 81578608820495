/* istanbul ignore file */
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
// import * as serviceWorker from "./serviceWorker";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");

const render = () => {
  ReactDOM.render(
    <React.StrictMode>
      <App baseUrl={baseUrl} />
    </React.StrictMode>,
    rootElement
  );
};

render();

if ((module as any).hot) {
  (module as any).hot.accept("./App", () => {
    render();
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
