import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from '@material-ui/core/Tooltip';
import { ProjectWithInvoices } from "types";
import { formatFee } from "features/projects/infra/project-helper";
import { formatDuration, getDurationFromMinutes } from "features/timesheet";
import { fontStyles } from "utils/styles";

const buildStyles   = makeStyles(theme => ({
  ...fontStyles(theme),
  root  : {
    // padding     : theme.spacing(1),
  },  
}));

interface ControlProps{
  project: ProjectWithInvoices;
}

const ProjectCardStats = ({project}: ControlProps) => {
  const classes   = buildStyles();
  const fee = formatFee(project);
  const altFee = formatFee(project, true);
  const dur = project.trackedMinutes ? getDurationFromMinutes(project.trackedMinutes) : null;
  const hours = dur ? formatDuration(dur, true) : null;
  const altFeeTip = project.type === "perHour" ? "Total earned" : "Hourly Rate";

  return (
    <Grid container justify="space-evenly" className={classes.root}>
      <Grid item xs={project.trackedMinutes ? 4 : 12} container direction="column">  
        <Typography className={classes.caption}>Fee</Typography>
        <Tooltip title="Project fee">
          <Typography className={classes.bodyTextSmall}>{fee}</Typography>
        </Tooltip>
      </Grid>
      {project.trackedMinutes && 
        <>
          <Grid item xs={4} container direction="column">
            <Typography className={classes.caption}>Time</Typography>
            <Tooltip title="Tracked time">
              <Typography className={classes.bodyTextSmall}>{hours} hours</Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={4} container direction="column">
            <Typography className={classes.caption}>{altFeeTip}</Typography>
            <Tooltip title={altFeeTip}>
              <Typography className={classes.bodyTextSmall}>{altFee}</Typography>
            </Tooltip>
          </Grid>
        </>
      }
    </Grid>
  );
}

export default React.memo(ProjectCardStats);
ProjectCardStats.displayName = "ProjectCardStats";
