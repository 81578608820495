
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { useEffect, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'types';
import { useWindowSize } from '.';

// React hook for delaying calls with time
// returns callback to use for cancelling
export const useTimeout = (
  callback: () => void, // function to call. No args passed.
  // if you create a new callback each render, then previous callback will be cancelled on render.
  timeout = 0, // delay, ms (default: immediately put into JS Event Queue)
): () => void => {
  const timeoutIdRef = useRef<NodeJS.Timeout>();
  const cancel = useCallback(
    () => {
      const timeoutId = timeoutIdRef.current;
      if (timeoutId) {
        timeoutIdRef.current = undefined;
        clearTimeout(timeoutId);
      }
    },
    [timeoutIdRef],
  );

  useEffect(
    () => {
      timeoutIdRef.current = setTimeout(callback, timeout);
      return cancel;
    },
    [callback, timeout, cancel],
  );

  return cancel;
}

export function useInterval(callback: () => void, delay: number, callFirst = false) {
  const savedCallback = useRef<() => void>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
    if (callFirst && callback) callback();
  }, [callback]);


  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

// export function useIsMobile(){
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
//   return isMobile;
// }

export function useIsMobile(){
  const windowSize = useWindowSize();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  
  return isXs || Boolean(windowSize.width < 600);
}

export function useIsSidebarExpanded(){
  const isExpanded = useSelector((state: RootState) => state.app.settings.isSidebarExpanded);
  return isExpanded;  
}

export function usePageLayoutStyles(){
  const isSidebarExpanded = useIsSidebarExpanded();
  // const theme = useTheme();

  const buildStyles = makeStyles(theme => ({
    sidebarColumn: (props: any) => ({
      background: theme.palette.common.white,
      borderRight: `1px solid ${theme.palette.grey[300]}`, //`
      position: "fixed" as const,
      left: props.isSidebarExpanded ? 191 : 64,
      top: 68,
      height: "100%",
      width: 260,
      [theme.breakpoints.down("xs")]: {
        display: "none",
      }
    }),
    contentColumn: (props: any) => ({
      marginLeft: 236,
      marginTop: -20,
      width: "calc(100% - 213px)",
      [theme.breakpoints.down("xs")]: {
        marginLeft: 0,
        marginTop: 0,
      }
    }),
  }));

  return buildStyles({isSidebarExpanded});
}