import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import _ from "lodash";
import { RootState, IOrg, IClient, IProject, Invoice, LFunc, LSwapFunc, defaultOrg, ProjectFilter, ISort, IGoal, emptyClient, Order } from "types";
import { AppContext } from "features/app/app-context";
import { loadClients, updateClientSettings } from "features/clients/clients-slice";
import { selectAllClients, selectProjectsWithActualInvoices } from "features/app/infra/common-selectors";
import { loadProjects, filterProjects, selectAllProjects, updateProjectSettings } from "features/projects";
import { loadInvoices, selectAllInvoices, selectInvoicesWithProjects, updateInvoiceSettings } from "features/invoices";
import { readSetting, saveSetting } from "../localstorage-helper";
import { newProject } from "features/projects/infra/project-helper";
import { prepareForInputs } from "utils/general-helpers";
import { newInvoice } from "features/invoices/infra/invoice-helpers";
import { getTimer, selectCurrentTimer } from "features/timesheet";
import { selectGoalsWithData } from "features/goals/infra/goal-selectors";
import { loadGoals } from "features/goals/infra/goal-actions";

export function useProfile() {
  const profile = useSelector((state: RootState) => state.app.profile);
  return profile;
}

export function useOrg(forInputs = false): IOrg {
  const org = useSelector((state: RootState) => state.app.org);
  const theOrg = React.useMemo(() => {
    if (forInputs && org) {
      return prepareForInputs(org, ["address"]);
    }
    else return org || defaultOrg;
  }, [org]);

  return theOrg;
}

export function useReadyState(){
  const [readyState, setReadyState] = React.useState(false);
  const clientsInitialized = useSelector((state: RootState) => state.clients.isInitialized);
  const projectsInitialized = useSelector((state: RootState) => state.projects.isInitialized);
  const invoicesInitialized = useSelector((state: RootState) => state.invoices.isInitialized);

  React.useEffect(() => {
    setReadyState(Boolean(clientsInitialized && projectsInitialized && invoicesInitialized));
  }, [clientsInitialized, projectsInitialized, invoicesInitialized]);

  return readyState;
}

export function useClients(sort: keyof IClient | null = null, sortDir: "asc" | "desc" = "asc"): IClient[] {
  const dispatch = useDispatch();
  const isAuthed = useSelector((state: RootState) => state.app.isAuthenticated);
  const isInitialized = useSelector((state: RootState) => state.clients.isInitialized);
  const isWorking = useSelector((state: RootState) => state.clients.status.isWorking);
  const allClients = useSelector(selectAllClients);

  React.useEffect(() => {
    if (isAuthed && !isInitialized && !isWorking) {
      dispatch(loadClients());
    }
  }, [isAuthed, isInitialized, isWorking]);

  let items = isAuthed ? allClients || [] : [];
  if (sort) {
    items = _.orderBy(items, [sort], [sortDir || "asc"]);
  }

  return items;
}

export function useClient(clientId: string | null | undefined): IClient | null {
  const allClients = useSelector((state: RootState) => state.clients.items);
  const item = React.useMemo(() => { return (clientId && allClients) ? allClients.find(i => i.id === clientId) : null }, [clientId, allClients]);
  return item ?? null;
}

export function useClientSafe(clientId: string | null | undefined): IClient {
  const allClients = useSelector((state: RootState) => state.clients.items);
  const item = React.useMemo(() => { return (clientId && allClients) ? allClients.find(i => i.id === clientId) : null }, [clientId, allClients]);
  return item ?? emptyClient;
}

export function useProjects(sort: ISort | keyof IProject | undefined = undefined, sortDir: "asc" | "desc" = "asc"): IProject[] {
  const dispatch = useDispatch();
  const isAuthed = useSelector((state: RootState) => state.app.isAuthenticated);
  const isInitialized = useSelector((state: RootState) => state.projects.isInitialized);
  const isWorking = useSelector((state: RootState) => state.projects.status.isWorking);
  const allProjects = useSelector(selectAllProjects) as IProject[];
  const mySort = sort ? (sort.hasOwnProperty("sort") ? sort as ISort : {sort: sort, sortDir: sortDir}) : null;
  const sortedProjects = React.useMemo(() => { return !mySort ? allProjects : _.orderBy(allProjects, [mySort.sort], [mySort.sortDir]); }, [allProjects, sort]);

  React.useEffect(() => {
    if (isAuthed && !isInitialized && !isWorking) {
      dispatch(loadProjects());
    }
  }, [isAuthed, isInitialized, isWorking]);

  return sortedProjects;
}

export function useActiveTimer(){
  const dispatch = useDispatch();
  const isReady = useSelector((state: RootState) => state.app.isAuthenticated);
  const checked = useSelector((state: RootState) => state.timesheet.lastCheckForTimer);
  const timer = useSelector(selectCurrentTimer);

  useEffect(() => {
    if(isReady && !checked && !timer){
      dispatch(getTimer());
    }
  }, [isReady, checked, timer]);

  return timer;
}

export function useInvoices(): Invoice[] {
  const dispatch = useDispatch();
  const isAuthed = useSelector((state: RootState) => state.app.isAuthenticated);
  const isInitialized = useSelector((state: RootState) => state.invoices.isInitialized);
  const isWorking = useSelector((state: RootState) => state.invoices.status.isWorking);
  const allInvoices = useSelector(selectAllInvoices);

  React.useEffect(() => {
    if (isAuthed && !isInitialized && !isWorking) {
      dispatch(loadInvoices());
    }
  }, [isAuthed, isInitialized, isWorking]);

  return allInvoices;
}

export function useGoals(): IGoal[] {
  const dispatch = useDispatch();
  const isAuthed = useSelector((state: RootState) => state.app.isAuthenticated);
  const isInitialized = useSelector((state: RootState) => state.goals.isInitialized);
  const isWorking = useSelector((state: RootState) => state.goals.status.isWorking);
  const allGoals = useSelector(selectGoalsWithData);

  React.useEffect(() => {
    if (isAuthed && !isInitialized && !isWorking) {
      dispatch(loadGoals());
    }
  }, [isAuthed, isInitialized, isWorking]);

  return allGoals;
}

export function useInvoice(invoiceId: string | number | null | undefined): Invoice | null {
  const allInvoices = useSelector(selectInvoicesWithProjects);
  const item = React.useMemo(() => { 
    if(!invoiceId) return null;
    else if (invoiceId === -1 || invoiceId === "add") return newInvoice;  
    else return allInvoices?.find(i => i.id === invoiceId) 
  }, [invoiceId, allInvoices]);

  return item || null;
}

export function useClientInvoices(clientId: string | null): Invoice[] {
  const allInvoices = useInvoices();
  const clientInvoices = useMemo(() => { return clientId ? allInvoices.filter(inv => inv.clientId === clientId) : []; }, [allInvoices, clientId]);
  return clientInvoices;
}

export function useProjectInvoices(projectId: string | null): Invoice[] {
  const allInvoices = useInvoices();
  const projectInvoices = useMemo(() => { return projectId ? allInvoices.filter(inv => inv.projectId === projectId) : []; }, [allInvoices, projectId]);
  return projectInvoices;
}

export function useProjectsWithFilter(filter: ProjectFilter | undefined = undefined, sort: ISort | undefined = undefined): IProject[] {
  const dispatch = useDispatch();
  const isInitialized = useSelector((state: RootState) => state.projects.isInitialized);
  const allProjects = useSelector(selectAllProjects);

  const items = React.useMemo(() => {
    if(isInitialized && (!!filter || !!sort)){
      const filtered = filter ? filterProjects(allProjects, filter) : allProjects;
      const sorted = sort ? _.orderBy(filtered, [sort.sort], [sort.sortDir]) : filtered;
      return sorted;
    }
    else return allProjects;

  }, [isInitialized, filter, sort]);

  React.useEffect(() => {
    if (!isInitialized) {
      dispatch(loadProjects());
    }
  }, [isInitialized]);

  return items;
}

export function useProject(projectId: string | number | null | undefined): IProject | null {
  const allProjects = useSelector(selectProjectsWithActualInvoices);
  const item = React.useMemo(() => { 
    if (!projectId) return null;
    else if (projectId === -1) return newProject;
    else return allProjects?.find(i => i.id === projectId);
  }, [projectId, allProjects]);
  
  return item || null;
}

export function useProjectSafe(projectId: string | number | null | undefined): IProject {
  const allProjects = useSelector(selectProjectsWithActualInvoices);
  const item = React.useMemo(() => { 
    if (!projectId || projectId === -1) return newProject;
    else return allProjects?.find(i => i.id === projectId);
  }, [projectId, allProjects]);

  return item ?? newProject;
}

export function useSliceSettings<T>(slice: keyof RootState): [any, (changes: Partial<T>) => void] {
  const dispatch = useDispatch();
  const sliceState = useSelector((state: RootState) => state[slice]);
  const settings = sliceState ? sliceState["settings"] : null;

  const updateSettings = (changes: Partial<T>) => {
    switch (slice) {
      case "projects": dispatch(updateProjectSettings(changes)); break;
      case "clients": dispatch(updateClientSettings(changes)); break;
      case "invoices": dispatch(updateInvoiceSettings(changes)); break;
      //TODO: settings for the other slices
    }
  }

  return [settings, updateSettings];
}

export function useClientProjects(clientId: string | null | undefined): IProject[] | null {
  const allProjects = useSelector((state: RootState) => state.projects.items);
  const items = React.useMemo(() => { 
    if (!clientId) return null;
    else return allProjects?.filter(i => i.clientId === clientId);
  }, [clientId, allProjects]);

  return items || null;
}

export function useQueryString(): Record<string, unknown> {
  const { search } = useLocation();
  // const { params }    = useRouteMatch();

  let result = {};

  if (!_.isEmpty(search)) {
    const urlP = new URLSearchParams(search);
    const sort = urlP.get("sort");
    const filter = urlP.get("filter");
    const find = urlP.get("search");       //TODO: Need to convert this to "find" since search is a keyword that will make it tough

    result = {
      sort: sort,
      filter: filter,
      search: find,       //TODO: Need to convert this to "find" since search is a keyword that will make it tough
      hasSort: Boolean(sort),
      hasFilter: Boolean(filter) || Boolean(find),
      queryString: search,
    };
  }

  return result;
}

export function useLocalSetting(key: string, defaultValue: any): [any, (value: any) => void] {
  const [value, setValue] = React.useState(readSetting(key, defaultValue));

  const updateFunc = (value: any) => {
    saveSetting(key, value);
    setValue(value);
  };

  return [value, updateFunc];
}

type ToggleFunc = (val?: any) => void;

export function useBoolState(defaultValue: boolean, onToggle?: (value: boolean) => void): [boolean, ToggleFunc, any, any, any] {
  const [value, setValue] = React.useState<boolean>(defaultValue);

  // function toggle(): void { setValue(!value); }
  function onFalse(): void { setValue(false); if(onToggle) onToggle(false); }
  function onTrue(): void { setValue(true); if(onToggle) onToggle(true); }

  const toggle = useCallback(() => {
    const nextValue = !value;
    setValue(nextValue);  
     if(onToggle) onToggle(nextValue); 
  }, [value]);

  return [
    value,
    toggle,
    onFalse,
    onTrue,
    setValue,
  ];
}

export function useTrackChanges<T>(original: T, current: T): [boolean, (newOriginal: T) => void] {
  const [orig, setOrig] = React.useState(original);
  const [isChanged, setChanged] = React.useState(false);

  React.useEffect(() => {
    setChanged(!shallowEqual(orig, current));
  }, [orig, current]);

  const updateOriginal = (newOriginal: T) => {
    setOrig(newOriginal);
  }

  return [isChanged, updateOriginal];
}

//Debounces a stored value.  This can be used to slow side effects from happening
// immediately, such as waiting to validate something until after there's a pause
export function useDebouncedValue(value: any, wait: number) {
  const [dbVal, setDbVal] = React.useState(value);

  React.useEffect(() => {
    const tid = setTimeout(() => setDbVal(value), wait);
    return () => clearTimeout(tid);
  }, [value]);

  return dbVal;
}

export function useLocalization(): {L: LFunc; LSwap: LSwapFunc} {
  const ctx = React.useContext(AppContext);
  return {L: ctx.L, LSwap: ctx.LSwap};
}

// export function useSelectAndPrepare<T>(selector: any, requiredFields: (keyof T)[], fallback: T){
//   const [value, setValue] = useState<T | null>(null);
//   const rawValue = useSelector(selector);

//   useEffect(() => {
//     if(!!rawValue){
//       const tValue = rawValue as T;
//       const prepared = prepareForInputs<T>(tValue, requiredFields);
//       setValue(prepared);
//     }
//     else{
//       setValue(fallback)
//     }
//   }, [rawValue])

//   return value ?? fallback;
// }

//===
// Will check for a filter in the query parameters, assign the filter and remvoe it from the url
export function useSearchStringFilter(route: string, changeSettings: (changes: any) => void){
  const { search } = useLocation();
  const history = useHistory();

  useEffect(() => {
    if(search && search.indexOf("filter") >= 0){
      const ups = new URLSearchParams(search);
      const filter = ups.get("filter");
      const sort = ups.get("sort") || "dueDate";
      const sortDir = ups.get("sortDir") || "asc";
      const changes = { filter: filter as any, sort: sort, sortDir: sortDir as Order };
      changeSettings(changes);
      history.replace(route);   //remove the query string    
    }
  }, [search])
}

//===
// Will check for a filter in the query parameters, assign the filter and remvoe it from the url
export function useQueryParameters(props: string[], replaceRoute?: string): Record<string, unknown> {
  const { search } = useLocation();
  const history = useHistory();
  const [query, setQuery] = useState<Record<string, unknown>>({});

  useEffect(() => {
    if(search){
      const ups = new URLSearchParams(search);
      let hasKeys = false;
      const queryObject = props.reduce((obj, prop) => {
        const val = ups.get(prop);
        hasKeys = hasKeys || Boolean(val);
        return {...obj, [prop]: val };
      }, {});
      
      setQuery(queryObject);

      if(hasKeys && replaceRoute){
        history.replace(replaceRoute);   //remove the query string    
      }
    }
  }, [search]);

  return query;
}