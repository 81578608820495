import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { IDialogProps, IAlert, IDashboardMetric, heroMetrics, heroAreas } from "types";
import { AtkDialog, cancelAction, saveAction } from "components";
import { useSnackbar } from "utils/hooks";
import { updateCustomizations } from "features/app/app-slice";
import { selectAllCustomizations } from "features/app/infra/app-selectors";

const buildStyles   = makeStyles(theme => ({
  dialogGrid: {
    minWidth: 350,
  },
  formControl: {
    margin: theme.spacing(1),
  },
}));

function getInitial(current: string[] | undefined, defaults: IDashboardMetric[]){
  const checked = current || [];
  const items = defaults.map(itm => ({ ...itm, isDisplayed: checked.indexOf(itm.id) >= 0}) );
  return items;
}

const DashboardSettingsDialog = (props: IDialogProps) => {
  const classes = buildStyles();
  const dispatch = useDispatch();
  const customizations = useSelector(selectAllCustomizations);
  // const {L, LSwap} = useLocalization();
  const [alert, setAlert] = React.useState<IAlert | null>(null);
  const [metrics, setMetrics] = React.useState<IDashboardMetric[] | null>(null);
  const [areas, setAreas] = React.useState<IDashboardMetric[] | null>(null);
  const notify = useSnackbar();

  //Get the selected hero metrics from the customizations
  React.useEffect(() => {
    if(customizations.dashboardConfig){
      const heroChecked = getInitial(customizations.dashboardConfig.metrics, heroMetrics);
      setMetrics(heroChecked);

      const areaChecked = getInitial(customizations.dashboardConfig.areas, heroAreas);
      setAreas(areaChecked);
    }
  }, []);

  //Validate there are between 1 and 4 metrics selected
  React.useEffect(() => {
    if(!metrics) return;
    const checkedCount = metrics.filter(m => m.isDisplayed === true).length;

    if(!alert && (checkedCount > 4 || checkedCount <= 0)){
      setAlert({id: "invalid-number", severity: "error", message: "Please choose between 1 and 4 metrics"});
    }
    else if(!!alert){
      setAlert(null);
    }
  }, [metrics]);

  //Validate there are between 1 and 3 areas selected
  React.useEffect(() => {
    if(!areas) return;
    const checkedCount = areas.filter(m => m.isDisplayed === true).length;

    if(!alert && (checkedCount > 4 || checkedCount <= 0)){
      setAlert({id: "invalid-number", severity: "error", message: "Please choose between 1 and 4 areas"});
    }
    else if(!!alert){
      setAlert(null);
    }
  }, [areas]);

  const onMetricChanged = (type: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const coll = type === "metrics" ? metrics : areas;
    const update = type === "metrics" ? setMetrics : setAreas;

    if(!!coll){
      const id = e.target.name;
      const isChecked = e.target.checked;
      const index = coll.findIndex(m => m.id === id);
      if(index >= 0){
        const metric = coll[index];
        const updated = {...metric, isDisplayed: isChecked};
        const list = [...coll.slice(0, index), updated, ...coll.slice(index + 1)];
        update(list);
      }
    }
  }

  async function onSave(){
    const selectedMetrics = metrics?.filter(m => m.isDisplayed).map(m => m.id);
    const selectedAreas = areas?.filter(m => m.isDisplayed).map(m => m.id);
    const dashboardConfig = customizations.dashboardConfig;
    await dispatch(updateCustomizations({
      dashboardConfig: {
        ...dashboardConfig, 
        metrics: selectedMetrics,
        areas: selectedAreas,
      }
    }));

    notify("Dashboard Settings saved successfully");
    props.onClose();
  }

  const dialogActions = [cancelAction(props.onClose), saveAction(onSave, !!alert, "Save & Close")];
  
  return (
    <AtkDialog maxWidth="sm" isOpen={Boolean(props.isOpen)} onClose={() => props.onClose()} title="Dashboard Settings" icon="settings" actions={dialogActions}>
      <Grid container spacing={2} className={classes.dialogGrid}>

        <Grid container>
          <Grid item xs={12}>
            <Typography></Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl error={alert !== null} component="fieldset" className={classes.formControl}>
              <FormLabel>Choose between 1 and 4 Metrics for the Callouts</FormLabel>
              <FormGroup row>
                {metrics?.map(metric => 
                  <FormControlLabel key={metric.id} control={<Checkbox checked={metric.isDisplayed} onChange={onMetricChanged("metrics")} name={metric.id} />} label={metric.label} />
                )}
              </FormGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl error={alert !== null} component="fieldset" className={classes.formControl}>
              <FormLabel>Choose between 1 and 4 metrics for the Areas Chart</FormLabel>
              <FormGroup row>
                {areas?.map(metric => 
                  <FormControlLabel key={metric.id} control={<Checkbox checked={metric.isDisplayed} onChange={onMetricChanged("areas")} name={metric.id} />} label={metric.label} />
                )}
              </FormGroup>
              {/* {!!alert && <FormHelperText>{alert.message}</FormHelperText>} */}
            </FormControl>
          </Grid>
        </Grid>        

      </Grid>
    </AtkDialog>
  );
}

export default DashboardSettingsDialog;
