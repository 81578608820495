import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import DialogContainer from "components/dialog-container";
import { DialogKey, parseDialogStoreString } from "utils/hooks/dialogs"
import { isBoolean, omit } from "lodash";
import { trackDialog } from "utils/mixpanel";
import { useHistory } from "react-router-dom";
import { getRoute } from "features/app";

export interface IDialogContext{
  dialogs: Record<DialogKey, unknown>;
  setProps: (key: DialogKey, value: any) => void;
  openDialog: (key: DialogKey, id?: any, props?: any) => void;
  closeDialog: (key: DialogKey) => void;
  onOpenDialog: (key: DialogKey, id?: any, props?: any) => () => void;
  onCloseDialog: (key: DialogKey) => () => void;
}

export const DialogContext = React.createContext<IDialogContext | null>(null);

const AtkDialogProvider = ({children}: any) => {
  const history = useHistory();
  const [store, setStore] = useState<string | null>(null);
  const [dialogs, setDialogs] = useState<Record<DialogKey, unknown>>({} as Record<DialogKey, unknown>);
  const [dialogProps, setDialogProps] = useState<Record<DialogKey, unknown>>({} as Record<DialogKey, unknown>);

  function setProps(key: DialogKey, props: any) {
    if(props) setDialogProps({...dialogProps, [key]: props});
    else setDialogProps(omit(dialogProps, [key]) as Record<DialogKey, unknown>);
  }

  //Will remove the specified dialog key from the query string, effectively closing the dialog
  function closeDialog(key: DialogKey) {
    if (dialogs && dialogs[key]) {
      const items = store?.split(",") || [];
      const after = items.filter((i: string) => i.indexOf(key) !== 0);
      if (after.length === 0) {
        setStore(null);
        setProps(key, null);
        setDialogs({} as Record<DialogKey, unknown>);
      } else {
        const result = after.join(",");
        setStore(result);
        setProps(key, null);
        setDialogs(parseDialogStoreString(result));
      }
    }
  }

  //Will remove the specified dialog key from the query string, effectively closing the dialog
  function openDialog(key: DialogKey, id: any = true, props: any = undefined) {
    if(key === "invoice"){
      //don't use the dialog for this...
      let url = "";
      if(id && id !== "-1"){
        url = getRoute("invoiceEdit", id);
      }
      else{
        let qs = "";
        if(props?.clientId || props?.projectId){
          qs = props?.projectId ? `projectId=${props?.projectId}` : `clientId=${props?.clientId}`;
        }
        url = getRoute("invoiceNew", "-1", qs);
      }
      history.push(url);
    }
    else if (!dialogs || !dialogs[key]) {
      const items = (store || "").split(",");
      const clean = items.filter((i: string) => i !== "");
      const after = [...clean, `${key}:${id}`]; //`
      const result = after.join(",");
      setStore(result);
      setProps(key, props);
      setDialogs(parseDialogStoreString(result));
      const trackValue = (key === "import") ? id : (!isBoolean(id) && !!id && id != "-1" ? "edit" : "create");
      trackDialog(key, trackValue, props);
    }
  }

  const contextValue = {
    dialogs,
    setProps,
    openDialog,
    closeDialog,
    onOpenDialog: (key: DialogKey, id?: any, props?: any) => () => openDialog(key, id, props),
    onCloseDialog: (key: DialogKey) => () => closeDialog(key),  //this is also called to close a dialog
  };

  const onDialogClosed = (key: DialogKey) => (value?: any) => {
    console.log("Dialog closed, ", key, value);
    closeDialog(key); 
  }
  
  return (
    <DialogContext.Provider value={contextValue}>
      <Grid id="dialog-provider-grid">

        {children}

        <DialogContainer dialogs={dialogs} onClose={onDialogClosed} dialogProps={dialogProps}/>

      </Grid>
    </DialogContext.Provider>
  );
}

export default AtkDialogProvider;
