import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import InputBase, { InputBaseProps } from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';

const buildStyles   = makeStyles(theme => ({
  searchRoot: {
    padding: '0 4px',
    display: 'flex',
    alignItems: 'center',
  },
  searchInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

interface SearchInputProps extends InputBaseProps{
  onClose?: (val?: any) => any;
  onSearch: (value: string) => any;
  classes?: Record<string, string>;
  canClear?: boolean;  
  disabled?: boolean;
}

const SearchInput = (props: SearchInputProps) => {
  const myClasses   = buildStyles();
  const {placeholder, onClose, onSearch, canClear, disabled, classes} = props;
  const [value, setValue] = useState("");

  const doSearch = (forceVal: string | null = null) => {
    onSearch(forceVal !== null ? forceVal : value);
  }

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  }

  const onBlur = () => {
    doSearch();
  }

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if(event.key === "Enter"){
      event.preventDefault();
      doSearch();
    }
    else if(event.key === "Escape"){
      if(value === "" && onClose){
        onClose();
      }
      else{
        setValue("");
      }
    }
  }

  const onClear = () => {
    setValue("");
    doSearch("");
  }

  return (
    <Paper component="form" className={clsx([myClasses.searchRoot, classes?.container])} variant="outlined">
      <InputBase disabled={disabled} autoFocus={props.autoFocus} value={value} className={myClasses.searchInput} placeholder={placeholder} inputProps={{ 'aria-label': placeholder }} onChange={onChange} onKeyDown={onKeyDown} onBlur={onBlur}/>
      <IconButton disabled={disabled} className={myClasses.iconButton} aria-label="search" onClick={() => doSearch()}>
        <Icon fontSize="small">search</Icon>
      </IconButton>
      {(onClose || canClear) && 
        <>
          <Divider className={myClasses.divider} orientation="vertical" />
          <IconButton disabled={disabled} color="primary" className={myClasses.iconButton} aria-label="close" onClick={onClose ? onClose : onClear}>
            <Icon fontSize="small">close</Icon>
          </IconButton>
        </>
      }
    </Paper>
  );
};

export default SearchInput;
