import grey from "@material-ui/core/colors/grey";
import { tipFormatters, currencyLabelFormatter, gradientBarFill, seriesColorByKey, gradientStackedBarFill } from "utils/chart-helpers";
import { featureColors } from "utils/common-classes";

export const heroAreaOptions = (colorKeys: string[] | null = null) => ({
  chart: {
    id: 'hero-area-chart',
    type: 'area',
    toolbar: {
      show: false,
    }
  },
  grid: {
    // show: false,
    borderColor: grey[200],
  },
  stroke: {
    width: 0.5
  },
  dataLabels: {
    enabled: false
  },
  xaxis: {
    type: 'datetime',
    // tickAmount: 4,
  },
  yaxis: {
    // forceNiceScale: true,s
    labels: {
      formatter: currencyLabelFormatter, //(v: any) => v ? formatCurrency(v) : "",
    }
  },
  tooltip: {
    x: {
      format: 'MMM yyyy'
    },
    y: {
      formatter: currencyLabelFormatter, //(v: any) => v ? formatCurrency(v) : "",
    }
  },
  ...seriesColorByKey(colorKeys),
  // fill: {
  //   type: "gradient",
  //   gradient: {
  //     enabled: true,
  //     opacityFrom: 0.5,
  //     opacityTo: 0.
  //   }
  // },
  legend: {
    show: false,
  }
});

export const miniBarOptions = {
  chart: {
    id: 'mini-bar-chart',
    type: 'bar',
    toolbar: {
      show: false,
    }
  },
  grid: {
    show: false,
  },
  stroke: {
    width: 1
  },
  dataLabels: {
    enabled: false
  },
  xaxis: {
    type: "datetime",
    show: false,
    labels: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    }
  },
  yaxis: {
    show: false,
  },
  tooltip: {
    x: {
      format: 'MMM yyyy'
    },
    y: {
      formatter: currencyLabelFormatter, //(v: any) => v ? formatCurrency(v) : "",
    }
  },
  fill: {
    ...gradientBarFill,
  },
};

export const stackedBarOptions = (offsetX?: number, offsetY?: number) => ({
  chart: {
    id: 'stacked-bar-chart',
    type: 'bar',
    stacked: true,
    toolbar: {
      show: false,
    },
    offsetX: offsetX || 0,
    offsetY: offsetY || 0,
  },
  grid: {
    show: false,
  },
  stroke: {
    width: 1
  },
  dataLabels: {
    enabled: false
  },
  xaxis: {
    type: "datetime",
    show: false,
    labels: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    }
  },
  yaxis: {
    show: false,
  },
  tooltip: {
    x: {
      format: 'MMM yyyy'
    },
    y: {
      formatter: currencyLabelFormatter, //(v: any) => v ? formatCurrency(v) : "",
    }
  },
  fill: {
    ...gradientBarFill,
  },
});

export const dateAxis = (dates: Date[], options?: Record<string, any>) => {
  return {
    xaxis: {
      type: "datetime",
      categories: dates,
      ...options,
    }
  }
}

export const miniBarWithOptions = (tipFormatter: "currency" | "hours" | "count" | null = null, color: string | null | string[] = null) => {
  const formatter = tipFormatter ? tipFormatters[tipFormatter] : null;
  const formatterProp = !formatter ? null : {
    tooltip: {
      ...miniBarOptions.tooltip,
      y: {
        formatter: formatter,
      }
    }
  };

  const colorProp = seriesColorByKey(color); 

  return {
    ...miniBarOptions,
    ...colorProp,
    ...formatterProp,
  };
}

//TODO: have whatever calls this just use the miniBarOptions above...
// const invoiceColor = "#66bb6a";
export const allRevenueOptions = () => {
  const formatterProp = {
    tooltip: {
      ...miniBarOptions.tooltip,
      y: {
        formatter: tipFormatters["currency"],
      }
    }
  };

  return {
    ...stackedBarOptions(), //(0, -100),
    legend: {
      show: false,
    },
    ...formatterProp,
    colors: [featureColors.paid, featureColors.invoiced],
    // fill: { 
    //   opacity: 0.5,
    // },
    ...gradientStackedBarFill,
    stroke: { 
      show: true, 
      width: 0.5, 
      colors: [featureColors.paid, featureColors.invoiced] 
    }
  };

};