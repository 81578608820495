import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import clsx from 'clsx';
import Backdrop from '@material-ui/core/Backdrop';

interface IProps {
  isWaiting?: boolean;
  message?: string;
  variant?: string;
  color?: "primary" | "secondary";
  className?: string;
  classes?: any;
  children?: any;
}

const buildStyles = makeStyles(theme => ({
  waitContainer: {
    position: "relative",
    width: "100%",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    position: "absolute",
    color: theme.palette.common.white,
    backgroundColor: "#0001",
    alignItems: "flex-start",
    paddingTop: 60,
    paddingBottom: 30,
  },
  waitRoot: {
    textAlign: "center",
    backgroundColor: "#0008",
    borderRadius: 5,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`, //`,
    minWidth: 200,
  },
  circular: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(4),
  },
  circularProgress: {
    marginBottom: theme.spacing(1),
  },
  linear: {

  },
  linearProgress: {
    minWidth: "100px",
    marginTop: theme.spacing(1),
  },
  message: {
  }
}));

const WaitOverlay = (props: IProps) => {
  const { isWaiting, message, variant, color, className, children } = props;
  const myClasses = buildStyles();

  const progress = variant === "circular" ?
    <CircularWaiter color={color} classes={myClasses} className={className} message={message} /> :
    <LinearWaiter color={color} classes={myClasses} className={className} message={message} />;

  return (
    <div className={myClasses.waitContainer}>
      {children}
      <Backdrop className={myClasses.backdrop} open={!!isWaiting}>
        {progress}
      </Backdrop>
    </div>
  );
}

export default WaitOverlay;



const LinearWaiter = (props: IProps) => {
  const { color, classes, className, message } = props;

  return (
    <div className={clsx("waiting waiting-linear", classes.linear, classes.waitRoot, className)}>
      <em className={classes.message}>{message}</em>
      <LinearProgress color={color} className={classes.linearProgress} />
    </div>
  );
}

const CircularWaiter = (props: IProps) => {
  const { color, classes, className, message } = props;

  return (
    <div className={clsx("waiting waiting-circular", classes.circular, classes.waitRoot, className)}>
      <CircularProgress color={color} className={classes.circularProgress} />
      <em className={classes.message}>{message}</em>
    </div>
  );
}