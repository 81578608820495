import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Switch, Route } from "react-router-dom";
import AnalyticsView from "../dashboard/analytics-view";
import ProjectsView from "../projects/ui/projects-view";
import ClientsView from "../clients/clients-view";
import TimesheetView from "../timesheet";
import InvoicesView from "../invoices";
import NotesView from "../notes/notes-view";
import TasksView from "../tasks/tasks-view";
import ReportsView from "../reports/reports-view";
import GoalsView from "../goals/goals-view";
import MetricsView from "../metrics";
import AppLogin from "./app-login";
import AppLogout from "./app-logout";
import AppInitializing from "./app-initializing";
import ProfileView from "../profile/profile-view";
import ProjectDashboard from "features/dashboard/project-dashboard";
import { routes } from "./app-routes";
import { RootState } from "store/root-reducer";
import { AtkAlert } from "components";
import { initializeApp } from "features/app/app-slice";
import { useIsMobile, useReadyState } from "utils/hooks";
import { selectIsOnboarded } from "./infra/common-selectors";
import OnboardingView from "features/onboarding/onboarding-view";
import IntegrationsView from "features/integrations/integrations-view";
import OauthRedirect from "features/integrations/oauth-redirect";

const buildStyles = makeStyles((theme) => ({
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: (props: any) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    maxWidth: props.isMobile ? "100%" : `calc(100% - ${!props.isVisible ? 0 : (props.isOpen ? 189 : 64)}px)`,   //`
    // transition: "width 1s, transform 2s",
  }),
}));

const AppContent = () => {
  const dispatch = useDispatch();
  const {isAuthenticated, isInitialized, settings, status } = useSelector((state: RootState) => state.app);
  const isOnboarded = useSelector(selectIsOnboarded);
  const isMobile = useIsMobile();
  const classes = buildStyles({ isOpen: settings.isSidebarExpanded, isVisible: isAuthenticated && isOnboarded, isMobile: isMobile });
  const isDataReady = useReadyState();
  
  //Initialize the apps primary collections
  useEffect(() => {
    if(isAuthenticated){
      dispatch(initializeApp());
    }
  }, [isAuthenticated]);

  

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
        {status.wantsRefresh && <AtkAlert id="refreshAlert" message="Please refresh the application to continue" severity="info" />}
        
        {isAuthenticated && (!isInitialized || !isDataReady) && <AppInitializing />}
        {(isInitialized === true && isAuthenticated === false) && <AppLogin />}

        <Route exact path={routes.logout} component={AppLogout} />
        <Route exact path={routes.integrationsRedirect} component={OauthRedirect} />

        {(isAuthenticated && isDataReady && !isOnboarded) && <OnboardingView />}
        {(isAuthenticated && isDataReady && isOnboarded) &&
          <Switch>
            <Route exact path={routes.dashboard} component={ProjectDashboard} />
            <Route exact path={routes.analytics} component={AnalyticsView} />
            <Route path={routes.goals} component={GoalsView} />
            <Route path={routes.metrics} component={MetricsView} />
            <Route path={routes.projects} component={ProjectsView} />
            <Route path={routes.clients} component={ClientsView} />
            <Route exact path={routes.timesheet} component={TimesheetView} />
            <Route path={routes.invoices} component={InvoicesView} />
            <Route exact path={routes.notes} component={NotesView} />
            <Route exact path={routes.tasks} component={TasksView} />
            <Route exact path={routes.reports} component={ReportsView} />
            <Route path={routes.profile} component={ProfileView} />
            <Route path={routes.samples} component={OnboardingView} />
            <Route path={routes.integrations} component={IntegrationsView} />
          </Switch>
        }
    </main>
  );
};

export default AppContent;
