import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ChevronDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ChevronUpIcon from '@material-ui/icons/KeyboardArrowUp';

const buildStyles   = makeStyles(theme => ({
  divider: {
    width: "25%",
    backgroundColor: theme.palette.secondary.light,
  },
  hidden: {
    marginTop: -5,
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
}));

interface ControlProps{
  onToggle: () => void;
  isHovered?: boolean;
  isExpanded: boolean;
}

const MetricDivider = ({onToggle, isHovered, isExpanded}: ControlProps) => {
  const classes   = buildStyles();

  return (
    <Grid container alignItems="center" justify="center" className={clsx({[classes.hidden]: true, [classes.visible]: isHovered})} onClick={onToggle}>
      <Grid item md={5} container justify="flex-end">
        <Divider className={classes.divider}/>
      </Grid>
      <Grid item md={1} container justify="center">
        <Tooltip title="More about this metric">
          <IconButton size="small" color="secondary" onClick={onToggle}>{isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}</IconButton>
        </Tooltip>  
      </Grid>
      <Grid item md={5} container justify="flex-start">
        <Divider className={classes.divider}/>
      </Grid>
    </Grid>
  );
}

// export MetricDivider;
export default React.memo(MetricDivider);