import { addSeconds, getUnixTime } from "date-fns";
import { Integration } from "./integration-types";

const parseHarvest = (query: URLSearchParams): Integration => {
  const token = query.get("access_token");
  const tokenType = query.get("token_type");
  const expiresIn = query.get("expires_in");
  const scope = query.get("scope");
  const accountId = scope?.substr(scope?.indexOf(":") + 1);

  const expSeconds = expiresIn ? parseInt(expiresIn) : 0;
  const expDate = addSeconds(new Date(), expSeconds);
  // const expTs = dateToString(expDate, REDUX_DATE_TIME_FORMAT);

  const item: Integration = {
    id: "harvest",
    token,
    tokenType,
    expiration: getUnixTime(expDate),
    scope,
    accountId,
  }

  return item;
}

export const parseIntegration = (search: string) => {
  const query = new URLSearchParams(search);
  const key = query.get("state") as string;
  switch(key){
    case "harvest": return parseHarvest(query);
  }
}