import _ from "lodash";
import { createSelector } from "@reduxjs/toolkit";
import { defaultAppCustomizations } from "types";
import { RootState } from "store/root-reducer";
import { selectAllClients, selectAllProjects } from "./common-selectors";

const _getInput = (state: RootState, input: any) => input;
const _getCustomizations = (state: RootState) => state.app.org?.customizations;

//Determines if the user has been onboarded by checking for clients and projects
export const selectIsOnboarded = createSelector(
  selectAllClients, selectAllProjects,
  (clients, projects) => {
    return clients && clients.length > 0 && projects && projects.length > 0;
  }
);



//Selector to get all the customizations (including the defaults)
export const selectAllCustomizations = createSelector(
  _getCustomizations,
  (customizations) => {
    if (!!customizations) {
      const items = { ...defaultAppCustomizations, ...customizations };
      return items;
    }
    else {
      return defaultAppCustomizations;
    }
  }
);

export const selectCustomization = createSelector(
  _getInput, selectAllCustomizations,
  (customizationKey, customizations) => {
    return customizations[customizationKey];
  }
);

export const selectCustomizations = createSelector(
  _getInput, selectAllCustomizations,
  (keys, customizations) => {
    if(_.isArray(keys)){
      const output = keys.reduce((accum, key: string) => {
        accum[key] = customizations[key];
        return accum;
      }, {});

      return output;
    }
    else{
      return {
        [keys]: customizations[keys],
      };
    }
  }
);