import React, { useMemo, useState } from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/FilterList';
import { RootState, ITimer, roundingTypeItems, InputField, Times } from "types";
import { defaultTimer, timerParsers } from "../infra/timesheet-config";
import { AtkDialog, DateInput, IDialogAction } from "components";
import { useProjects, useInputs, useSnackbar, useBoolState, useLocalization } from "utils/hooks";
import { createHours, updateHours } from "../infra/timesheet-actions";
import { selectTimer } from "../infra/timesheet-selectors";
import { ensureProps, twoChars } from "utils/general-helpers";
import { DISPLAY_TIME_FORMAT, formatDate, formatTime, parseTime, parseDate, validateDate } from "utils/date-helpers";
import { calcMinutes, roundMinutes } from "../infra/timesheet-helpers";
import { filterProjects } from "features/projects";
import { findIndex } from "lodash";
// import DatePicker from "components/date-picker";

const buildStyles = makeStyles(theme => ({
  inputGrid: {
    marginBottom: theme.spacing(0.5),
    // marginRight: theme.spacing(1),
  },
  textRight: {
    textAlign: "right",
    "& input": {
      textAlign: "right",
    }
  },
  textCenter: {
    textAlign: "center",
    "& input": {
      textAlign: "center",
    }
  },
}));

interface DialogProps {
  isOpen: any;
  id: number | string | undefined;
  onClose: (id?: number | boolean) => void;
}

function validateTimer(timer: Partial<ITimer>, times: Times, date: InputField<Date>): string | null {
  if (!timer.projectId) return "Project is required";
  else if (!timer.clientId) return "Client is required (select a valid project)";
  else if(!date) return "Date is required";
  else if(date.bindings.error === true) return "Date is invalid";
  // else if (!timer.date) return "Date is required";
  else {
    return times.startTime.error || times.stopTime.error;
  }
}

function handleDateChange(newVal: string): Partial<InputField<Date>>{
    const isBlank = newVal === "";
    const isValidDate = isBlank ? false  : validateDate(newVal);
    const parsed = (!isBlank && isValidDate) ? parseDate(newVal) : null;
    const formatted = (!isBlank && isValidDate) ? formatDate(parsed) : newVal;

    if(isValidDate){
      
      return {
        value: isBlank ? null : parsed,
        display: isBlank ? "" : formatted,
        bindings: {
          error: false,
          helperText: null,          
        }
      }
    }
    else if(isBlank){
      return {
        display: newVal,
        isEmpty: isBlank,
        bindings: {
          error: true,
          helperText: "value is required",
        }
      }
    }      
    else{
      return {
        display: newVal,
        isEmpty: isBlank,
        bindings: {
          error: true,
          helperText: "invalid date format",
        }
      }
    }
}

const HoursDialog = (props: DialogProps) => {
  const classes = buildStyles();
  const dispatch = useDispatch();
  const notify = useSnackbar();
  const { LSwap } = useLocalization();
  
  const projects = useProjects({sort: "name", sortDir: "asc"});
  const [isFiltered, toggleIsFiltered] = useBoolState(true);
  const [isDelivered, setIsDelivered] = useState(false);
  const availableProjects = React.useMemo(() => { return isFiltered ? filterProjects(projects, "undelivered") : projects; }, [projects, isFiltered]);
  
  const existing = useSelector<RootState, ITimer | null>(state => selectTimer(state, props.id)) || defaultTimer;
  const prepared = useMemo(() => ensureProps<ITimer>(existing, ["creditMinutes", "category", "notes"], [0, "", ""]), [existing]);
  const isNew = useMemo(() => { return !prepared || !prepared.id; }, [prepared]);
  const [values, display, errors, binding, setValues] = useInputs<Partial<ITimer>>((prepared || defaultTimer), timerParsers);
  const [itemDate, setItemDate] = useState<Partial<InputField<Date>>>(handleDateChange(prepared?.startTime ? formatDate(prepared.startTime) : "")); 
  const [times, setTimes] = useState<Times>(
    {
      startTime: {
        value: formatTime(existing?.startTime),
        error: null,
        date: parseTime(existing?.startTime as string, existing?.date),
        isDirty: false,
      },
      stopTime: {
        value: formatTime(existing?.stopTime),
        error: null,
        date: parseTime(existing?.stopTime as string, existing?.date),
        isDirty: false,
      }
    }
  );
  
  const duration = React.useMemo(() => {
    if (values.minutes !== undefined && values.minutes !== null) {
      if(values.minutes === -9999) return "";   //means one of the items isn't valid
      const hours = Math.floor(values.minutes / 60);
      const minutes = values.minutes % 60;
      return `${hours}:${twoChars(minutes)}`;
    }
  }, [values.minutes]);

  //Update the client id if the project id is changed
  React.useEffect(() => {
    if (existing) {
      if (values.projectId !== existing.projectId) {
        const prj = projects?.find(p => p.id === values.projectId);
        setValues({ ...values, clientId: prj?.clientId, roundType: prj?.roundType || "none" });
      }
    }
  }, [values.projectId]);

  //Modify the displayed minutes if the times or rounding changes
  React.useEffect(() => {
    let displayMinutes = -9999;
    const startMmt = times.startTime.date;
    const stopMmt = times.stopTime.date;
    if(startMmt.isValid() && stopMmt.isValid()){
      const minutes = calcMinutes(times.startTime.date.toDate(), times.stopTime.date.toDate());
      displayMinutes = roundMinutes(minutes, values.roundType);
    }
    
    setValues({ ...values, minutes: displayMinutes });
  }, [times, values.roundType]);

  //If this is a new timer, initialize the start time
  React.useEffect(() => {
    if(!existing.id){
      const now = new Date();
      const date = handleDateChange(now.toLocaleDateString());
      setItemDate(date);      
    }
  }, [existing]);

  //If the current project is not in the available list, need to toggle the filter
  React.useEffect(() => {
    if(values.projectId && isFiltered){
      const index = findIndex(availableProjects, p => p.id === values.projectId);
      if(index < 0){
        setIsDelivered(true);
        toggleIsFiltered(null); //turn off the filter so the project shows in the drop down.
      }
      else setIsDelivered(false);
    }

  }, [isFiltered, isDelivered, availableProjects, values.projectId]);

  const onSave = async (andClose: any = false) => {
    const error = validateTimer(values, times, itemDate as InputField<Date>);
    if (!error) {
    
      const date = moment(itemDate.value);
      const start = times.startTime.date;
      const stop = times.stopTime.date;
      //Make sure the date of the start / stop time is the same as the date
      if(!date.isSame(start, "date") || !date.isSame(stop, "date")){
        start.set({"year": date.year(), "month": date.month(), "date": date.date()});
        stop.set({"year": date.year(), "month": date.month(), "date": date.date()});
      }
      const minutes = calcMinutes(start.toDate(), stop.toDate());
      const rounded = roundMinutes(minutes, values.roundType);

      //TODO: only send the changed properties to the action to prevent
      // unnecessary updating (with link to the project trackedHours)
      const model = {
        ...values,
        startTime: start.format(),
        stopTime: stop.format(),
        minutes: rounded,
      }

      if(isNew){
        await dispatch(createHours(model));        
      }
      else if (existing && existing.id) {
        await dispatch(updateHours(existing.id, model));
      }

      notify("Item successfully saved.", "success");
      if (andClose === true) {
        props.onClose(true);
      }
    }
    else {
      console.error("Error:", error);
    }
}

  const onTimeChange = (key: string) => (e: React.ChangeEvent<any>) => {
    const val = e.target.value as string;
    if (val !== times[key].value) {

      let error = null;
      if (!val || val.trim().length === 0) {
        const label = key === "startTime" ? "from" : "To";
        error = `${label} is required.`;  //`
        //create an invalid moment to use as the date
        setTimes({ ...times, [key]: { value: val, error: error, date: moment("x"), isDirty: true } });
      }
      else{
        const parsed = parseTime(val, itemDate.value);
        setTimes({ ...times, [key]: { value: val, error: error, date: parsed, isDirty: true } });
      }
    }
  }

  const onTimeBlur = (key: string) => () => {
    //On blur, update the field with the parsed time value
    const timer = times[key];
    if (timer.isDirty) {
      if (timer.value !== "") {
        const parsed = parseTime(timer.value, itemDate.value);
        if(!parsed.isValid()){
          const label = key === "startTime" ? "from" : "To";
          const error = `${label} is invalid.`;  //`
          setTimes({ ...times, [key]: { value: timer.value, error: error, date: parsed } });
        }
        else{
          const formatted = parsed.isValid() ? parsed.format(DISPLAY_TIME_FORMAT) : "";
          setTimes({ ...times, [key]: { value: formatted, error: timer.error, date: parsed } });
        }
      }
    }
  }

  const onDateChange = (e: React.ChangeEvent<any>) => {
    setItemDate({...itemDate, display: e.target.value});
  }

  const onDateBlur = () => {
    const updates = handleDateChange(itemDate.display || "");
    setItemDate({...itemDate, ...updates});
  }

  const dialogActions: IDialogAction[] = [
    {
      label: "Cancel",
      onClick: () => { props.onClose(); },
      color: "default",
    },
    {
      label: "Save",
      onClick: onSave,
      color: "primary",
    },
    {
      label: "Save & Close",
      onClick: () => onSave(true),
      color: "primary",
    }
  ];

  return (
    <AtkDialog isOpen={Boolean(props.isOpen)} onClose={() => props.onClose()} title={isNew ? "Add Hours" : "Edit Hours"} icon="alarm" actions={dialogActions}>
      <Grid container spacing={2}>
        <Grid item xs={3} container className={classes.inputGrid}>
          <TextField id="date" label="Date" fullWidth variant="outlined" margin="dense" value={itemDate.display} onChange={onDateChange} onBlur={onDateBlur} {...itemDate.bindings} />
        </Grid>
        <Grid item xs={3} container className={classes.inputGrid}>
          <TextField label="from" fullWidth variant="outlined" margin="dense" autoFocus className={classes.textCenter} value={times.startTime.value} onChange={onTimeChange("startTime")} error={!!times.startTime.error} helperText={times.startTime.error} onBlur={onTimeBlur("startTime")} />
        </Grid>
        <Grid item xs={3} container className={classes.inputGrid}>
          <TextField label="to" fullWidth variant="outlined" margin="dense" className={classes.textCenter} value={times.stopTime.value} onChange={onTimeChange("stopTime")} error={!!times.stopTime.error} helperText={times.stopTime.error} onBlur={onTimeBlur("stopTime")} />
        </Grid>
        <Grid item xs={3} container className={classes.inputGrid}>
          <TextField label="time" fullWidth variant="outlined" margin="dense" disabled={true} className={classes.textCenter} value={duration} />
        </Grid>

        <Grid item xs={6} container className={classes.inputGrid} alignItems="center">
          <Grid item xs={10}>
            <FormControl fullWidth variant="outlined" margin="dense">
              <InputLabel id="project-label" htmlFor="projectId">Project</InputLabel>
              <Select id="projectId" labelId="project-label" label="Project" value={values?.projectId} {...binding.select("projectId")} fullWidth>
                {availableProjects?.map(option => <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            {!isDelivered && 
              <Tooltip title={LSwap(isFiltered ? "Showing only active projects (click to show all)" : "Showing all projects (click to filter)", "projects")}>
                <IconButton size="small" color={isFiltered ? "primary" : "default"} onClick={toggleIsFiltered}><FilterListIcon /></IconButton>
              </Tooltip>
            }
          </Grid>
        </Grid>

        <Grid item xs={6} container className={classes.inputGrid} alignItems="center">
          <FormControl fullWidth variant="outlined" margin="dense">
            <InputLabel id="rounding-label" htmlFor="roundType">Rounding</InputLabel>
            <Select id="roundType" labelId="rounding-label" margin="dense" label="Rounding" value={values?.roundType || "none"} {...binding.select("roundType")} variant="outlined" fullWidth>
              {roundingTypeItems?.map((option: any) => <MenuItem key={option.id} value={option.id}>{option.label}</MenuItem>)}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} container className={classes.inputGrid}>
          <TextField id="category" label="Category (optional)" fullWidth variant="outlined" margin="dense" value={display?.category} {...binding.input} {...errors["category"]} />
        </Grid>

        <Grid item xs={12} className={classes.inputGrid}>
          <TextField id="notes" label="Notes" fullWidth variant="outlined" value={display?.notes} {...binding.input} {...errors["notes"]} multiline rows={4} />
        </Grid>

        <Grid item xs={2} container className={classes.inputGrid}>
          <TextField id="creditMinutes" label="Credit" title="Minutes to credit" value={display?.creditMinutes} {...binding.input} fullWidth variant="outlined" margin="dense" {...errors["creditMinutes"]} />
        </Grid>
        <Grid item xs={2} container className={classes.inputGrid}>
          <TextField disabled id="invoiceId" label="Invoice" value={display?.invoiceId} {...binding.input} fullWidth variant="outlined" margin="dense" {...errors["invoiceId"]} />
        </Grid>
        <Grid item xs={4} className={classes.inputGrid}>
          <DateInput id="invoiceDate" label="Invoice Date" value={values.invoiceDate} {...binding.dateInput("invoiceDate")} fullWidth variant="outlined" margin="dense" minDate={values.startTime}/>
        </Grid>
        <Grid item xs={4} className={classes.inputGrid}>
          <DateInput id="paymentDate" label="Paid Date" value={values.paidDate} {...binding.dateInput("paymentDate")} fullWidth variant="outlined" margin="dense" minDate={values.startTime}/>
        </Grid>

      </Grid>
    </AtkDialog>
  );
}

export default HoursDialog;
