import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Icon from '@material-ui/core/Icon';
import Typography from "@material-ui/core/Typography";
import Tooltip from '@material-ui/core/Tooltip';
import { SeparatorDot } from "components";
import clsx from "clsx";

const buildStyles   = makeStyles(theme => ({
  subTitle  : {
    fontSize  : "1.2em",
    fontWeight: 400,
    color     : theme.palette.grey[500],
    textDecoration: "none",
    "& a": {
      color     : theme.palette.grey[500],
      fontSize  : "1.2em",
      fontWeight: 400,
      textDecoration: "none",
    }
  },
  subTitleIcon: {
    marginRight: theme.spacing(0.5),
    color: theme.palette.grey[500]
  },
  iconLink: {
    height: 20,
  }
}));

interface SubItemProps{
  icon: string;
  label?: string | null;
  noSeparator?: boolean;
  tooltip?: string;
  link?: string;
  hideLabel?: boolean;
  spacing?: "sm" | "md" | "lg";
}

const ItemContent = React.forwardRef<HTMLSpanElement, SubItemProps>((props, ref ) => {
  const classes   = buildStyles();
  return (
    <>
      {!props.noSeparator && <SeparatorDot spacing={props.spacing}/>}
      {props.hideLabel && props.link &&
        <Tooltip title={props.tooltip || ""}>
          <a className={clsx([classes.subTitle, classes.iconLink])} href={props.link}>
            <Icon ref={ref} fontSize="small" className={classes.subTitleIcon}>{props.icon}</Icon>
          </a>
        </Tooltip>
      }
      {(!props.hideLabel || !props.link) && 
        <Tooltip title={props.tooltip || ""}>
          <Icon ref={ref} fontSize="small" className={classes.subTitleIcon}>{props.icon}</Icon>
        </Tooltip>
      }
      {(!props.link && !props.hideLabel) && <Typography className={classes.subTitle}>{props.label}</Typography>}
      {(props.link && !props.hideLabel) && <a className={classes.subTitle} href={props.link}>{props.label}</a>}
    </>
  );
});
ItemContent.displayName = "ItemContent";

//TODO: For some reason, the tooltip doesn't display over the whole thing...
const SubItem = (props: SubItemProps) => {
  if(!props.label) return null; //if there isn't a label to display, don't show this item
  else if(props.tooltip){
    return (
      <Tooltip title={props.tooltip}>
        <ItemContent {...props} />
      </Tooltip>
    );
  }
  else{
    return ( <ItemContent {...props} /> );
  }
}

export default React.memo(SubItem);