import React, { useMemo } from "react";
import { useDispatch, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Paper from '@material-ui/core/Paper';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import Chip from '@material-ui/core/Chip';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ArchiveIcon from '@material-ui/icons/Archive';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import { IClient, IAlert, ProjectTypeItems } from "types";
import { prepareForInputs, removeItem } from "utils/general-helpers";
import { useLocalization, useInputs, useSnackbar } from "utils/hooks";
import { AtkAlert, IconMenu } from "components";
import { clientParsers } from "../client-helpers";
import { updateClient } from "../clients-slice";
import { formStyles, generalStyles, cardStyles, feeAdornStyle } from "utils/styles";

const buildStyles = makeStyles(theme => ({
  ...generalStyles(theme),
  ...formStyles(theme),
  ...cardStyles(theme),  
  ...feeAdornStyle(theme),
  alertsGrid: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(-2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

interface ClientCardProps {
  client: IClient;
  isWorking: boolean;
}

const ClientCard = ({ client }: ClientCardProps) => {
  const classes = buildStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const {LSwap} = useLocalization();
  const prepedClient = useMemo(() => prepareForInputs(client, ["category", "daysToPay", "address", "notes"]), [client]);
  const [values, display, errors, binding, updateValues] = useInputs<IClient>(prepedClient, clientParsers, { updateOn: "onChange" });
  const [alerts, setAlerts] = React.useState<IAlert[]>([]);
  const notify = useSnackbar();

  const isChanged = React.useMemo(() => {
    return !shallowEqual(client, values);
  }, [client, values, display]);

  async function saveChanges(andClose = false) {
    if (isChanged) {
      await dispatch(updateClient(client.id, values));
      notify(LSwap("Client saved successfully", "client"), "success");

      if (andClose) {
        history.push("/clients");
      }
    }
  }

  async function menuClicked(action: string) {
    if (action === "archive") {
      const msg = client.isArchived ? "unarchived" : "archived";
      const changes = { ...values, isArchived: !client.isArchived };
      await dispatch(updateClient(client.id, changes));
      updateValues(changes);  //reset the values so it doesn't think they've changed
      notify(LSwap(`Client has been ${msg}`, "client"), "success");
    }
  }

  const menuItems = React.useMemo(() => {
    const items = [];
    if (!client.isArchived) {
      items.push({ id: 0, label: LSwap("Archive client", "client"), icon: <ArchiveIcon style={{ marginLeft: 8 }} />, action: () => menuClicked("archive") });
    }
    else {
      items.push({ id: 0, label: LSwap("UnArchive client", "client"), icon: <UnarchiveIcon style={{ marginLeft: 8 }} />, action: () => menuClicked("archive") })
    }

    return items;
  }, [client.isArchived]);

  function closeAlert(id: string) {
    const item = alerts.find(a => a.id === id);
    if (item) {
      const newAlerts = removeItem(alerts, item);
      setAlerts(newAlerts);
    }
  }

  return (
    <Grid container>
      {alerts.length > 0 &&
        <Grid container className={classes.alertsGrid}>
          {alerts.map(alert => <AtkAlert key={alert.id} {...alert} onClose={() => closeAlert(alert.id)} />)}
        </Grid>
      }

      <Paper className={classes.card} variant="outlined">
        <Grid container>

          <Grid container className={classes.cardHeader} justify="space-between" alignItems="center">
            <Grid item md={7} sm={12} container justify="flex-start" alignItems="center">
              <TextField id="name" label={LSwap("Client Name", "client")} value={display?.name} {...binding.input} {...errors["name"]} variant="outlined" fullWidth margin="dense" autoComplete="off" />
            </Grid>
            <Grid item md={5} sm={12} container justify="flex-end" alignItems="center">
              {client.isArchived && <Chip icon={<ArchiveIcon />} label="Archived" className={classes.marginLeftLg} />}
              <Button color="primary" variant={isChanged ? "contained" : undefined} size="small" disabled={!isChanged} onClick={() => saveChanges(false)} className={clsx([classes.actionButton, classes.marginRight])}>Save</Button>
              <Button color="primary" variant={isChanged ? "contained" : undefined} size="small" disabled={!isChanged} onClick={() => saveChanges(true)} className={clsx([classes.actionButton, classes.marginRight])}>Save &amp; Close</Button>
              <IconMenu icon={<MoreVertIcon />} items={menuItems} />
            </Grid>
          </Grid>

          <Grid container className={classes.cardBody} spacing={1}>

            <Grid item sm={3} xs={12} className={classes.inputGridDense}>
              <TextField id="contactName" label="Contact" variant="outlined" fullWidth value={display?.contactName} {...binding.input} {...errors["contactName"]} margin="dense" autoComplete="off" />
            </Grid>
            <Grid item sm={5} xs={12} className={classes.inputGridDense}>
              <TextField id="contactEmail" label="Contact Email" variant="outlined" fullWidth value={display?.contactEmail} {...binding.input} {...errors["contactEmail"]} margin="dense" autoComplete="off" />
            </Grid>
            <Grid item sm={4} xs={12} className={classes.inputGridDense}>
              <TextField id="contactPhone" label="Contact Phone" variant="outlined" fullWidth value={display?.contactPhone} {...binding.input} {...errors["contactEmail"]} margin="dense" autoComplete="off" />
            </Grid>

            <Grid item sm={4} xs={12} className={classes.inputGridDense}>
              <TextField id="clientCategory" label="Client Category" variant="outlined" fullWidth value={display?.category || ""} {...binding.inputWithKey("category")} {...errors["category"]} margin="dense" autoComplete="on" />              
            </Grid>

            <Grid item md={3} sm={4} xs={6} className={classes.inputGridDense} container>
              <TextField id="defaultRate" label="Typical Rate" variant="outlined" className={classes.rateInput} value={display?.defaultRate} {...binding.input} {...errors["defaultRate"]} autoComplete="off" margin="dense"
                InputProps={{
                  endAdornment: <InputAdornment position="end">
                    <Grid container className={classes.adornGrid}>
                      <Typography className={classes.adorn}>/</Typography>
                      <TextField select value={display?.defaultProjectType} {...binding.select("defaultProjectType")} className={classes.adornSelect}>
                        <MenuItem value={-1} disabled>[choose]</MenuItem>
                        {ProjectTypeItems.map(opt => <MenuItem key={opt.id} value={opt.id}>{opt.unitLabel}</MenuItem>)}
                      </TextField>
                    </Grid>
                  </InputAdornment>

                }}
              />
            </Grid>
            <Grid item md={2} sm={4} xs={6} className={classes.inputGridDense}>
              <TextField id="daysToPay" label="Days to Pay" variant="outlined" fullWidth value={display?.daysToPay || ""} className={classes.textRight} {...binding.input} {...errors["daysToPay"]} autoComplete="off" margin="dense"
                InputProps={{
                  endAdornment: <Tooltip title="How many days it takes for this client to pay invoices"><InfoIcon color="disabled" className={classes.marginLeft} /></Tooltip>
                }}
              />
            </Grid>
            
            <Grid item sm={6} xs={12} className={classes.inputGridDense}>
              <TextField id="clientAddress" label="Client Address" variant="outlined" fullWidth value={display?.address || ""} {...binding.inputWithKey("address")} {...errors["address"]} margin="dense" multiline rows={4} rowsMax={6} />              
            </Grid>

            <Grid item xs={12} container>
              <Grid item xs={12} className={classes.inputGridDense}>
                <TextField id="notes" label="Notes" fullWidth variant="outlined" value={display?.notes} {...binding.input} {...errors["notes"]} multiline rows={4} margin="dense" />
              </Grid>
            </Grid>

          </Grid>

        </Grid>
      </Paper>
    </Grid>
  )
}

export default ClientCard;