import { createSelector } from "@reduxjs/toolkit";
import { parseISO } from "date-fns";
import { selectAllClients, selectAllProjects, selectAllInvoices } from "features/app/infra/common-selectors";
import { compact, reduce } from "lodash";
import { RootState, IMetric, IDaySummary, IProjectSummary } from "types";
import { getMonthEndDates } from "utils/date-helpers";
import { Metrics } from "./metrics-config";
import { getMetricData } from "./metrics-helper";

// const _getCategory = (state: RootState, category: string) => category;
const _getMetricFilter = (state: RootState, category: string, range: string) => ({category: category, range: range});
const _getFavorites = (state: RootState) => state.app.org.customizations?.favoriteMetrics;
const _getProjectHours = (state: RootState) => state.projects.trackedHours;

export const selectMetricHours = createSelector(
  selectAllProjects, _getProjectHours,
  (projects, projectHours) => {
    if(!projectHours) return [];

    const projectsWithDays = reduce(projectHours, (result: IProjectSummary[], prjInfo) => {
      
      const project = projects.find(p => p.id === prjInfo.projectId);
      if(!project) return result;

      let totalMinutes = 0;
      const days = reduce(prjInfo.days, (summary: IDaySummary[], value: number, key: string) => {
        const dayItem = {
          date: parseISO(key), 
          minutes: value as number
        } as IDaySummary;

        totalMinutes += dayItem.minutes;
        summary.push(dayItem);
        return summary;
      }, []);

      const item = {
        id: prjInfo.projectId, 
        name: project.name,
        minutes: totalMinutes,
        startDate: project.startDate as Date,
        deliveredDate: project.deliveredDate as Date,
        days: days,
      };

      result.push(item);
      return result;
    }, []);

    return projectsWithDays;
  }
)

export const selectMetrics = createSelector(
  _getMetricFilter, _getFavorites, selectAllProjects, selectAllInvoices, selectAllClients, selectMetricHours,
  (filter, favorites, projects, invoices, clients, projectHours) => {
    
    const monthEnds = getMonthEndDates(12);
    let items: IMetric[] = [];
    if(filter.category === "favorites"){
      const temp = (favorites && favorites.length > 0) ? favorites?.map(f => Metrics.find(m => m.id === f)) : [];
      items = compact(temp);
    } 
    else if(filter.category !== "favorites"){
      items = Metrics.filter(m => m.category === filter.category);
    }

    const values = items.map(metric => {
      const data = getMetricData(filter.range, metric.id, projects, clients, invoices, projectHours, monthEnds);
      return {
        ...metric,
        data
      } as IMetric;
    });
    
    return values;
  }
);