import { useFirestore, STATUSES } from "utils/firebase";
import { AppThunk } from "store/root-reducer";
import { newProject } from "./project-helper";
import { IProject } from "types";
import { getChanges, datesToStrings, stringsToDates, prepareForDb} from "utils/general-helpers";
import { projectsLoaded, projectAdded, projectUpdated, projectHoursLoaded, projectsAdded } from "./projects-slice";

//-- load projects
export const loadProjects = (): AppThunk => async (dispatch, getState) => {
  const [api, uid] = useFirestore();

  if (!uid) throw new Error("User is not authorized.");
  const app = getState().app;

  if (app.isInitialized) {
    const result = await api.getProjects(app.org.id);
    if (result.statusCode === STATUSES.ok || result.statusCode === STATUSES.empty) {
      //need to deal with dates...
      const items = result.items?.map(i => {
        return {
          ...newProject,
          ...datesToStrings(i)
        };
      });

      dispatch(projectsLoaded(items || []));
    }
  }
}

//-- create a new project
export const createProject = (item: IProject): AppThunk => async (dispatch, getState) => {
  const [api, uid] = useFirestore();

  if (!uid) throw new Error("User is not authorized.");
  const app = getState().app;

  if (app.isInitialized) {
    const prepared = {...prepareForDb(item), createdDate: new Date() };
    // const withoutUndefs = removeUndefined(item);
    // const prepared = stringsToDates<IProject>({ ...withoutUndefs, createdDate: new Date() });
    const result = await api.createProject(app.org.id, prepared);
    if (result.statusCode === STATUSES.ok) {
      //Update the local store
      const toStore = {
        ...datesToStrings(prepared),
        id: result.key
      };

      dispatch(projectAdded(toStore));

      return toStore;
    }
  }
}

//-- create a new project
export const uploadProjects = (items: IProject[]): AppThunk => async (dispatch, getState) => {
  const [api, uid] = useFirestore();

  if (!uid) throw new Error("User is not authorized.");
  const app = getState().app;

  if (app.isInitialized) {
    const prepared = items.map(prj => ({...prepareForDb(prj), createdDate: new Date() }));
    const result = await api.createProjects(app.org.id, prepared);

    if (result.statusCode === STATUSES.ok) {
      const ids = (result.key as string).split("~");
      let i = 0;
      const toStore = prepared.map(prj => ({...datesToStrings(prj), id: ids[i++]} as IProject));
      dispatch(projectsAdded(toStore));
      return toStore;
    }
  }
}

//-- create a new project
export const updateProject = (projectId: string, changes: Partial<IProject>): AppThunk => async (dispatch, getState) => {
  const [api, uid] = useFirestore();

  if (!uid) throw new Error("User is not authorized.");
  const app = getState().app;

  if (app.isInitialized) {
    //get the actual changes
    const project = getState().projects.items?.find(prj => prj.id === projectId);
    if (project) {
      const actualChanges = getChanges(project as any, changes as any);

      if (actualChanges !== null) {
        const prepared = stringsToDates(actualChanges);
        const result = await api.updateProject(app.org.id, projectId, prepared);
        if (result.statusCode === STATUSES.ok) {
          //Update the local store
          // const toStore = prepareDates(actualChanges);
          dispatch(projectUpdated({ id: projectId, changes: datesToStrings(prepared) }));
          return projectId;
        }
      }
    }
  }

  return null;
}

//-- load project hours
export const loadProjectHours = (force = false): AppThunk => async (dispatch, getState) => {
  const [api, uid] = useFirestore();

  if (!uid) throw new Error("User is not authorized.");
  const app = getState().app;

  if (app.isInitialized) {
    const existing = getState().projects.trackedHours;
    if(!!existing && !force) return true;

    const result = await api.getProjectHours(app.org.id);
    if (result.statusCode === STATUSES.ok || result.statusCode === STATUSES.empty) {
      await dispatch(projectHoursLoaded(result.data || []));
      return true;
    }
    else{
      return false;
    }
  }
}