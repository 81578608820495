import firebase from "firebase/app";
import "firebase/firestore";
import _ from "lodash";
import { INote, QueryFilter } from "types";
import { COLS, STATUSES, IFirestoreResult, collectionRef, arrayResponse, inflateCollection } from "utils/firebase";
import { prepareForDb } from "utils/general-helpers";

export const noteCollections = {
  NOTES: "notes",
};

// const REQ_PROPS = ["noteDate", "content"];
const WHITELIST_PROPS = ["noteDate", "content", "clientId", "projectId", "uid", "isFavorite", "createdDate", "lastUpdatedDate"];

export const notesApi = (db: firebase.firestore.Firestore) => ({

  // Gets the list of clients for the specified user
  getNotes: async (orgId: string, startDate: Date | null = null, endDate: Date | null = null, filter: QueryFilter | QueryFilter[] | null = null): Promise<IFirestoreResult> => {
    // const items: INote[] = [];
    const coll =  collectionRef(db, orgId, COLS.NOTES);
    let snapshot: firebase.firestore.QuerySnapshot | null = null;

    //add the start and end dates to the filter collection if necessary
    let allFilters = filter ? (_.isArray(filter) ? filter : [filter]) : [];
    if(startDate || endDate){
      if(endDate)  allFilters = [{field: "invoiceDate", operator: "<", value: endDate}, ...allFilters];
      if(startDate) allFilters = [{field: "invoiceDate", operator: ">=", value: startDate}, ...allFilters];
    }

    if(allFilters.length){
      const f1 = allFilters[0];
      let query = coll.where(f1.field, f1.operator, f1.value)
      if(allFilters.length > 1){
        for(let i = 1; i < allFilters.length; i++){
          const f = allFilters[i];
          query = coll.where(f.field, f.operator, f.value);
        }
      }
      snapshot = await query.get();
    }
    else{
      snapshot = await coll.get();
    }    

    //Convert the snapshot into the collection of items
    const items = inflateCollection<INote>(snapshot);
    
    return arrayResponse(items, orgId);
  },

  //---
  // Creates a new note
  createNote: async (orgId: string, note: Partial<INote>): Promise<IFirestoreResult> => {
    const id = note.id;
    const safeNote = {...prepareForDb(note, null, null, WHITELIST_PROPS), createdDate: new Date()};
    const coll = collectionRef(db, orgId, COLS.NOTES);
    let noteId = "";
    
    if(id){
      const doc = coll.doc(id);
      await doc.set(safeNote);
      noteId = id;
    }
    else{
      const result = await coll.add(safeNote);
      noteId = result.id;
    }

    return {
      ok: true,
      statusCode: STATUSES.ok,
      key: noteId
    };
  },

  //---
  // Updates an existing note
  updateNote: async (orgId: string, noteId: string, changes: Partial<INote>): Promise<IFirestoreResult> => {
    const safeChanges = prepareForDb(changes, null, null, WHITELIST_PROPS);
    const existing = collectionRef(db, orgId, COLS.NOTES).doc(noteId);
    await existing.update({...safeChanges, lastUpdatedDate: new Date()});

    return {
      ok: true,
      statusCode: STATUSES.ok,
      key: noteId
    };
  },

  //---
  // Deletes a note
  deleteNote: async (orgId: string, noteId: string): Promise<IFirestoreResult> => {
    const note = collectionRef(db, orgId, COLS.NOTES).doc(noteId);
    await note.delete();
    return {
      ok: true,
      statusCode: STATUSES.ok,
      key: noteId
    };
  }
})