import firebase from "firebase/app";
import "firebase/firestore";
import _ from "lodash";
import { IFirestoreResult, IFirebaseProfile } from "utils/firebase/firebase-types";
import { COLS, STATUSES } from "utils/firebase";
import { IOrg, IProfileInfo, PROFILE_WHITELIST } from "types";

export const appCollections = {
  PROFILE: "profiles",
  ORG: "orgs",
  EVENTS: "activitylog",
  
}

export const appApi = (db: firebase.firestore.Firestore) => ({
  createProfile: async (profile: IFirebaseProfile): Promise<IFirestoreResult> => {

    const model: IProfileInfo = {
      ...profile,
      createdDate: new Date(),
    };

    //use the UID of the user as the key of the document
    await db.collection(COLS.PROFILE).doc(model.uid).set(model);

    return {
      ok: true,
      statusCode: STATUSES.ok,
      key: model.uid,
      data: model,
    };
  },

  //--- logs an event in the DB
  logActivity: async (uid: string, event: string, props: any = null): Promise<void> => {
    const model = {
      timestamp: new Date(),
      event: event,
      uid: uid,
      props: props,
    };

    await db.collection(COLS.EVENTS).add(model);
  },

  //--- gets a profile by the UID
  getProfile: async (uid: string): Promise<IFirestoreResult> => {

    const result = await db.collection(COLS.PROFILE).doc(uid).get();

    if (result.exists) {
      return {
        ok: true,
        statusCode: STATUSES.ok,
        key: uid,
        data: result.data(),
      };
    }
    else {
      return {
        ok: true,
        statusCode: STATUSES.empty,
        key: uid,
      };
    }
  },

  //---
  // Updates an existing project
  updateProfile: async (uid: string, changes: Partial<IProfileInfo>): Promise<IFirestoreResult> => {
    const safeChanges = _.omit(_.pick(changes, PROFILE_WHITELIST), ["uid", "id", "createdDate"]);  //remove the id property, if present
    const profile = db.collection(COLS.PROFILE).doc(uid);
    await profile.update(safeChanges);

    return {
      ok: true,
      statusCode: STATUSES.ok,
      key: uid
    };
  },

  //#region Org methods
  getOrg: async (uid: string, orgId: string | null = null): Promise<IFirestoreResult> => {
    let result = null;
    if (orgId) {
      result = await db.collection(COLS.ORG).doc(orgId).get();
      return {
        ok: true,
        statusCode: result.exists ? STATUSES.ok : STATUSES.empty,
        key: orgId,
        data: result.exists ? result.data() : null,
      };
    }
    else {
      const snapshot = await db.collection(COLS.ORG).where("admin", "==", uid).get();
      const items: any[] = [];
      snapshot.forEach(doc => {
        const item = doc.data();
        items.push({ id: doc.id, ...item });
      });

      const hasItems = Boolean(items.length > 0);

      //TODO: For now, only care about the first item...
      return {
        ok: true,
        statusCode: hasItems ? STATUSES.ok : STATUSES.empty,
        key: hasItems ? items[0].id : null,
        data: hasItems ? items[0] : null,
      };
    }
  },

  createOrg: async (uid: string, email: string): Promise<IFirestoreResult> => {

    const model = {
      name: null,
      website: null,
      phone: null,
      admin: uid,
      adminEmail: email,
    };

    const result = await db.collection(COLS.ORG).add(model);
    const org: IOrg = { id: result.id, ...model };

    return {
      ok: true,
      statusCode: STATUSES.ok,
      key: result.id,
      data: org,
    };
  },

  updateOrg: async (orgId: string, changes: Partial<IOrg>): Promise<IFirestoreResult> => {
    const safeChanges = _.omit(changes, ["uid", "id", "createdDate"]);  //remove the id property, if present
    const org = db.collection(COLS.ORG).doc(orgId);
    await org.update(safeChanges);

    return {
      ok: true,
      statusCode: STATUSES.ok,
      key: orgId
    };
  },
});