import React, { FunctionComponent, useMemo } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';
import { RowProps, HeadCell } from "components/atk-table";
import { ITimer, IProject, IClient } from "types";
import { formatDate, DISPLAY_TIME_FORMAT, DISPLAY_MONTH_FORMAT, DISPLAY_DATE_FORMAT } from "utils/date-helpers";
import { IconNames, statusIcons } from "utils/common-classes";
import { getDuration, getDurationFromMinutes, formatDuration } from "../infra/timesheet-helpers";
import { deleteHours } from "../infra/timesheet-actions";
import { useSnackbar, useHover } from "utils/hooks";
import { getRoute } from "features/app";

export interface ITimerRow extends ITimer {
  client?: IClient;
  project?: IProject;
  startMoment?: string;
  stopMoment?: string;
  duration?: string;
  status?: string;
  actions?: any;
}

export const rowHeader = (): HeadCell<ITimerRow>[] => [
  { id: "id", label: "Id", isHidden: true },
  { id: 'date', label: 'Date' },
  { id: 'startTime', label: 'Start', align: "right" },
  { id: 'stopTime', label: 'End', align: "right"},
  { id: "minutes", label: "Time", align: "right" },
  { id: "category", label: "Category", },
  { id: "notes", label: "", noSort: true },
  { id: "projectId", label: "Project", },
  { id: "status", label: "", noSort: true },
  { id: "actions", label: "", noSort: true, width: 5}
];

export const rowHeaderForProject = (): HeadCell<ITimerRow>[] => [
  { id: "id", label: "Id", isHidden: true },
  { id: 'date', label: 'Date' },
  { id: 'startTime', label: 'Start', align: "right" },
  { id: 'stopTime', label: 'End', align: "right"},
  { id: "minutes", label: "Time", align: "right" },
  { id: "category", label: "Category", },
  { id: "notes", label: "", noSort: true },
  // { id: "projectId", label: "Project", },
  { id: "status", label: "", noSort: true },
  // { id: "actions", label: "", noSort: true}
];

export const rowHeaderForClient = (): HeadCell<ITimerRow>[] => [
  { id: "id", label: "Id", isHidden: true },
  { id: 'date', label: 'Date' },
  { id: 'startTime', label: 'Start', align: "right" },
  // { id: 'stopTime', label: 'End', align: "right"},
  { id: "minutes", label: "Time", align: "right" },
  { id: "category", label: "Category", },
  { id: "notes", label: "", noSort: true },
  { id: "projectId", label: "Project", },
  { id: "status", label: "", noSort: true },
  // { id: "actions", label: "", noSort: true}
];

const buildStyles = makeStyles(theme => ({
  deleteRow: {
    background: `${theme.palette.error.main}33`, //`
  },
  deleteCell: {
    color: theme.palette.error.dark,
    
  },
  bold: {
    fontWeight: 600,
  },
  deleteButton: {

  }
}));

function DurationCell({minutes, item}: {minutes: number, item: ITimer}){
  const [isAlt, setAlt] = React.useState(false);
  const hasCredit = Boolean(item.creditMinutes && item.creditMinutes > 0);
  const tt = item.creditMinutes ? `. This item has a credit of ${item.creditMinutes} minutes`: "";

  const duration = React.useMemo(() => {
    const dur = getDurationFromMinutes(minutes);
    return formatDuration(dur, isAlt);
  }, [minutes, isAlt]);

  return (
    <Tooltip title={`Click to toggle between standard / fractional display${tt}`}>
      <TableCell onClick={() => setAlt(!isAlt)} style={{cursor: "pointer"}} align="right">
        {duration}{hasCredit && "*"}
      </TableCell>
    </Tooltip>
  )
}

const HoursRow: FunctionComponent<RowProps<ITimer>> = ({ item, cols, isSelected, onSelected, labelId, extra }) => {
  const classes = buildStyles();
  const dispatch = useDispatch();
  const notify = useSnackbar();
  const [isConfirming, setConfirming] = React.useState(false);
  const hasProject = useMemo(() => !!cols.find(c => c.id === "projectId"), [cols]);
  const hasEnd = useMemo(() => !!cols.find(c => c.id === "stopTime"), [cols]);
  const hasActions = useMemo(() => !!cols.find(c => c.id === "actions"), [cols]);
  const prjUrl = useMemo(() => { return getRoute("project", item.projectId) }, [item]);
  const [hoverRef, isHovered] = useHover();

  const startMoment = useMemo(() => { return moment(item.startTime); }, [item.startTime]);
  const endMoment = useMemo(() => { return moment(item.stopTime); }, [item.stopTime]);

  const totalMinutes = React.useMemo(() => {
    const minutes = item.minutes ? item.minutes : endMoment.diff(startMoment, "minutes");
    return minutes;
  }, [item.startTimer, item.stopTime, item.minutes]);
  
  const duration = React.useMemo(() => {
    const dur = item.minutes ? getDurationFromMinutes(item.minutes) : getDuration(startMoment, endMoment);
    return formatDuration(dur);
  }, [item.startTime, item.stopTime, item.minutes]);

  const status = React.useMemo(() => {
    if(item.paymentDate || item.paymentId){
      return (
        <Tooltip title={`paid${item.paymentDate ? ` on ${formatDate(item.paymentDate)}`: ""}`}>
          <Icon fontSize="small" color="secondary">{statusIcons.done}</Icon>
        </Tooltip>
      )
    }
    else if(item.invoiceId || item.invoiceDate){
      return (
        <Tooltip title={`invoiced${item.invoiceDate ? ` on ${formatDate(item.invoiceDate)}`: ""}`}>
          <Icon fontSize="small" color="secondary">{IconNames.invoice}</Icon>
        </Tooltip>
      )
    }
    return null;
  }, [item]);

  const onDelete = React.useCallback(async () => {
    if(isConfirming){
      await dispatch(deleteHours(item.id));
      notify("Item deleted successfully.", "success");
    }
    else{
      //TODO: should they be able to delete an invoiced item??
      setConfirming(true);  //toggle this to confirm the delete
    }
  }, [isConfirming]);

  if(isConfirming){
    return (
      <TableRow className={classes.deleteRow}>
        <TableCell padding="default" colSpan={6} className={classes.deleteCell}>
          Delete <span className={classes.bold}>{duration}</span> on <span className={classes.bold}>{startMoment.format(DISPLAY_DATE_FORMAT)}</span> for <span className={classes.bold}>{item.projectName}</span> permenantly?
        </TableCell>
        <TableCell colSpan={3}>
          <Grid container justify="flex-end">
            <Button size="small" color="secondary" onClick={() => onDelete()}>delete</Button>
            <Button size="small" onClick={() => setConfirming(false)}>cancel</Button>
          </Grid>
        </TableCell>        
      </TableRow>
    );
  }

  return (
    <TableRow hover ref={hoverRef} role="checkbox" aria-checked={isSelected} tabIndex={-1} selected={isSelected} onClick={onSelected}>
      <TableCell component="th" id={labelId} scope="row" padding="default">
        {startMoment.format(extra.onlyCurrentYear ? DISPLAY_MONTH_FORMAT : DISPLAY_DATE_FORMAT)}
      </TableCell>
      <TableCell align="right">{startMoment.format(DISPLAY_TIME_FORMAT)}</TableCell>
      {hasEnd && <TableCell align="right">{endMoment.format(DISPLAY_TIME_FORMAT)}</TableCell>}
      <DurationCell minutes={totalMinutes} item={item}/>
      <TableCell>{item.category}</TableCell>
      <TableCell>
        {item.notes &&
          <Tooltip title={item.notes}>
            <Icon color="disabled" fontSize="small">comment</Icon>
          </Tooltip>
        }
      </TableCell>
      {hasProject && <TableCell><Link to={prjUrl}>{item.projectName || item.project?.name}</Link></TableCell>}
      <TableCell>{status}</TableCell>
      {hasActions && 
        <TableCell align="right">
        <div style={{position: "relative"}}>
          <Grid container style={{position: "absolute", display: isHovered ? "block" : "none", marginTop: -12, right: 0, width: 60}}>
            <IconButton size="small" onClick={() => extra.onEdit("timer", item.id)} title="Edit row"><Icon fontSize="small" color="disabled">edit_outlined</Icon></IconButton>
            <IconButton size="small" onClick={onDelete} title="Delete row"><Icon fontSize="small" color="disabled">delete_outlined</Icon></IconButton>
          </Grid>
        </div>
      </TableCell>        
      }
    </TableRow>
  );
}

export default HoursRow;
