import React from "react";
import _ from "lodash";
import moment from "moment";
import { shallowEqual } from "react-redux";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import { IClient, IProject, QueryFilter, Indexable } from "types";
import { DateInput } from "components";
import { useProjects, useClients, useInputs } from "utils/hooks";
// import { selectHours } from "../infra/timesheet-selectors";
import { formStyles } from "utils/styles";

const buildStyles   = makeStyles(theme => ({
  filterRoot  : {
    padding     : `${theme.spacing(0.75)}px ${theme.spacing(1)}px`, //`,
    background: theme.palette.grey[100],
    marginTop: theme.spacing(-1),
    borderBottom: `1px solid ${theme.palette.grey[300]}`, //`
    flexWrap: "nowrap",
  },
  filterSection: {
    paddingRight: theme.spacing(1),
  },
  filterFormControl: {
    "& .MuiInput-formControl": {
      marginTop: 0,
      fontSize: 12,
    },
    "& .MuiInputLabel-formControl": {
      top: theme.spacing(-2),
      fontSize: 12,
    },
    "& .MuiFormControlLabel-label": {
      fontSize: 12,
    }
  },
  title   : {
    fontSize    : 22,
    fontWeight  : 500,
    textAlign   : "center",
    marginBottom: theme.spacing(1),
  },
  ...formStyles(theme),
}));

export type TimerFilter = {
  startDate: Date | null;
  endDate: Date | null;
  category: string;
  clientId: string;
  projectId: string;
  isInvoiced: boolean | null;
  isPaid: boolean | null;
}

const defaultFilter: TimerFilter = {
  startDate: moment().startOf("month").toDate(),
  endDate: null,
  category: "",
  clientId: "",
  projectId: "",
  isInvoiced: null,
  isPaid: null,
}

const fieldOps: Indexable = {
  startDate: ">=",
  endDate: "<=",  
}
const fieldKeys: Indexable = {
  startDate: "startTime",
  endDate: "endTime",  
}

type FilterBarProps = {
  onFilterChange: (filter: QueryFilter[] | null) => void;
}

// const fakeClients = [{id: "abc", name: "Client 1"}, {id: "def", name: "Client 2"}];
// const fakeProjects = [{id: "123", clientId: "abc", name: "Project 1"}, {id: "456", clientId: "def", name: "Project 2"}];

const HoursFilterBar = ({onFilterChange}: FilterBarProps) => {
  const classes   = buildStyles();
  const today = React.useMemo(() => moment().endOf("day"), []);
  const allClients: Partial<IClient>[] | null = useClients("name");
  const allProjects: Partial<IProject>[] | null = useProjects({sort: "name", sortDir: "asc"});
  const [values, display, errors, binding, setValues] = useInputs<TimerFilter>(defaultFilter, undefined, {updateOn: "onChange"});
  const [projects, setProjects] = React.useState<Partial<IProject>[]>(allProjects || []);
  const [lastFilter, setLastFilter] = React.useState<Partial<TimerFilter>>({}); //the last value that was sent (to avoid redundancy)
  const [dateHasValue, setDateHasValue] = React.useState({startDate: true, endDate: false});
  const [hasErrors, setErrors] = React.useState(false);

  //TODO: prevent applying a filter if there is an error

  //Effect to keep the Client and Project boxes in sync.  Using an effect
  // instead of a memo so the project can be cleared if the client is set to
  // an incompatible value.
  React.useEffect(() => {
    if(values.clientId === "" || (values as any).clientId === 0){
      setProjects(allProjects);
      return;
    }

    //Do I need to reset the project filter (if it's set to one that's not with the new client)?
    const curr = values.projectId ? allProjects.find(p => p.id === values.projectId) : null;
    if(curr && curr.clientId !== values.clientId) setValues({...values, projectId: ""});
    
    //Now, set the available projects accordingly
    const availProjects = allProjects.filter(p => p.clientId === values.clientId);
    setProjects(availProjects);

  }, [values.clientId]);

  //-- Applies the current filter
  function onApply(){    
    const filterObj = _.reduce(values, (accum: any, value, key) => {
      if(!!value || value === false){
        accum[key] = value;
      }
      return accum;
    }, {});

    //Make sure something changed
    if(!shallowEqual(lastFilter, filterObj)){
      //Convert to an array of query filters
      const queryFilters = _.reduce(filterObj, (accum: QueryFilter[], value, key) => {
        const op = fieldOps[key] || "==";
        const fieldKey = fieldKeys[key] || key;
        const item = {field: fieldKey, value: value, operator: op};
        accum.push(item);
        return accum;
      }, []);

      onFilterChange(queryFilters);
      setLastFilter(filterObj);
    }    
  }

  //-- Clears the current filter
  function onClear(){
    const tmpFilter = {...defaultFilter, startDate: null};
    setValues(tmpFilter);
    setValues(defaultFilter);
    //Make sure something changed
    if(!shallowEqual(lastFilter, {})){
      onFilterChange(null);
      setLastFilter({});
    }
  }

  //--- Handles a change to one of the dates
  const onDateChange = (key: string) => (newValue: Date | null, isError: boolean) => {
    setValues({...values, [key]: newValue || ""});
    setErrors(isError);
  }
  //--- Handles a text change to one of the dates (so we can hide the placeholder text)
  const onDateTextChange = (key: string) => (newText: string) => {
    setDateHasValue({...dateHasValue, [key]: !!newText});
  }

  return (
    <Grid id="hours-filter" container alignItems="flex-start" className={classes.filterRoot}>      
      <Grid item xs={3} className={classes.filterSection} container direction="row" justify="flex-start" alignItems="flex-start">
        <Grid item xs={6}>
          <FormControl className={clsx(classes.formControl, classes.filterFormControl)}>
            <InputLabel id="start-label" shrink={false} className={classes.selectLabel}>
              {dateHasValue.startDate ? "" : "Start"}
            </InputLabel>
            <DateInput id="startDate" required={true} value={values.startDate} onDateChange={onDateChange("startDate")} onTextChange={onDateTextChange("startDate")} maxDate={today} />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl className={clsx(classes.formControl, classes.filterFormControl)} style={{marginLeft: 8}}>
            <InputLabel id="end-label" shrink={false} className={classes.selectLabel}>
              {dateHasValue.endDate ? "" : "End"}
            </InputLabel>
            <DateInput id="endDate" value={values.endDate} onDateChange={onDateChange("endDate")} onTextChange={onDateTextChange("endDate")} maxDate={today} minDate={values.startDate || undefined} />
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={2} className={classes.filterSection}>
        <FormControl className={clsx(classes.formControl, classes.filterFormControl)} fullWidth>
          <InputLabel id="category-label" shrink={false} className={classes.selectLabel}>
            {values.category ? "" : "Filter Category"}
          </InputLabel>
          <TextField id="category" fullWidth value={display?.category} {...binding.input} {...errors["category"]} />
        </FormControl>
      </Grid>
      <Grid item xs={2} className={classes.filterSection}>
        <FormControl className={clsx(classes.formControl, classes.filterFormControl)} fullWidth {...binding.selectContainer("clientId", allClients)}>
          <InputLabel id="client-label" shrink={false} className={classes.selectLabel}>
            {values.clientId ? "" : "Filter Client"}
          </InputLabel>
          <Select id="clientId" labelId="client-label" label="Client" value={values?.clientId} {...binding.select("clientId")} fullWidth className={classes.selectContainer} classes={{ select: classes.select }}>
            <MenuItem value="" className={classes.option}>[all]</MenuItem>
            {allClients?.map(option => <MenuItem key={option.id} value={option.id} className={classes.option}>{option.name}</MenuItem>)}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={2} className={classes.filterSection}>
        <FormControl className={clsx(classes.formControl, classes.filterFormControl)} fullWidth {...binding.selectContainer("projectId", allProjects)}>
          <InputLabel id="project-label" shrink={false} className={classes.selectLabel}>
            {values.projectId ? "" : "Filter Project"}
          </InputLabel>
          <Select id="projectId" labelId="project-label" label="Project" value={values?.projectId} {...binding.select("projectId")} fullWidth className={classes.selectContainer} classes={{ select: classes.select }}>
            <MenuItem value="" className={classes.option}>[all]</MenuItem>
            {projects.map(option => <MenuItem key={option.id} value={option.id} className={classes.option}>{option.name}</MenuItem>)}
          </Select>
        </FormControl>
      </Grid>
      
      <Grid item xs={3} container className={classes.filterSection} alignItems="flex-start" justify="flex-end">
        <Tooltip title="Clear all filters">
          <IconButton id="clearFilterButton" size="small" onClick={onClear}><Icon fontSize="small">clear</Icon></IconButton>
        </Tooltip>
        <Tooltip title="Apply the filter">
          <IconButton id="applyFilterButton" size="small" onClick={onApply} disabled={hasErrors}><Icon fontSize="small">done_outline</Icon></IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
}

export default HoursFilterBar;
