import React, { useEffect, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { IFilter, IMenuItem, Indexable, ISort, LSwapFunc, ProjectFilter, ProjectSettings } from "types";
import { FilterList, ChipMenu, SearchInput } from "components";
import { useBoolState, useLocalization, useLocalSetting, useSearchStringFilter, useSliceSettings } from "utils/hooks";
import { layoutStyles, generalStyles } from "utils/styles";
import clsx from "clsx";
import { IconNames } from "utils/common-classes";
import { trackDisplayType } from "utils/mixpanel";
import { ListDisplayType } from "components/atk-list/atk-list";
import { routes } from "features/app";

const buildStyles   = makeStyles(theme => ({
  ...generalStyles(theme),
  ...layoutStyles(theme),
  root  : {
    minHeight: 42,    
  },
  menuBar: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: "center",
    }
  },
  firstCol: {
    padding: `${theme.spacing(0)}px ${theme.spacing(1)}px`, //`
    // borderRight: `1px solid ${theme.palette.grey[300]}`, //`
    display: "inline-flex",
    [theme.breakpoints.down('xs')]: {
      // borderRight: 'none',
      marginBottom: theme.spacing(1),
      alignItems: 'center',
    }
  },
  lastCol: {
    padding: `${theme.spacing(0)}px ${theme.spacing(1)}px`, //`
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1),
      alignItems: 'center',
    }
  },
  // chip: {
  //   height: 28,
  //   minWidth: 50,
  // },
  chipIcon: {
    height: 28,
    width: 28,
    border: "1px solid rgba(0,0,0,0.23)",
    "& .MuiIcon": {
      fontSize: 22,
    }
  },
  iconButton: {
    padding: 10,
  }, 
  searchContainer: {
    width: "65%",
    [theme.breakpoints.down('xs')]: {
      width: "100%",
    }
  } 
}));

export const filterLabels: Indexable = {
  thismonth: "This Month",
  active: "Active",
  inprogress: "Not Delivered",
  uninvoiced: "Not Invoiced",
  unpaid: "Unpaid",
  all: "All",
  paid: "Paid",
  delivered: "Delivered",
  lastmonth: "Last Month",
  thisquarter: "This Quarter",
  thisyear: "This Year",
  archived: "Archived",
}

const filterListItems = (LSwap: LSwapFunc): IFilter[] => {
  return [
    { id: 0, label: "Active", filter: "active", sort: "dueDate", sortDir: "asc", title: LSwap("Projects that are in progress this month", "projects") },
    { id: 40, label: "All", filter: "all", sort: "startDate", sortDir: "desc", title: LSwap("All projects", "projects") },
  ];
}

const filterMenuItems: IMenuItem[] = [
  { id: 300, label: "UnInvoiced", action: "uninvoiced", props: { sort: "dueDate", sortDir: "desc" } },
  { id: 100, label: "Unpaid", action: "unpaid", props: { sort: "invoicedDate", sortDir: "desc" } },
  { id: 200, label: "Paid", action: "paid", props: { sort: "paidDate", sortDir: "desc" } },
  { id: 250, label: "Delivered", action: "delivered", props: { sort: "deliveredDate", sortDir: "desc" } },
  { id: 275, label: "Overdue", action: "overdue", props: { sort: "dueDate", sortDir: "asc" } },
  { id: 990, isDivider: true },
  { id: 400, label: "This Month", action: "thismonth", props: { sort: "startDate", sortDir: "desc" } },
  { id: 500, label: "Last Month", action: "lastmonth", props: { sort: "startDate", sortDir: "desc" } },
  { id: 600, label: "This Quarter", action: "thisquarter", props: { sort: "startDate", sortDir: "desc" } },
  { id: 700, label: "This Year", action: "thisyear", props: { sort: "startDate", sortDir: "desc" } },
  { id: 991, isDivider: true },
  { id: 800, label: "Archived", action: "archived", props: { sort: "startDate", sortDir: "desc" } },
];

const listViews: IMenuItem[] = [
  {id: 0, label: "Table", action: "table", icon: IconNames.tableView, props: "tab", path: "/projects-table", },
  {id: 1, label: "Cards", action: "cards", icon: IconNames.cardView, props: "tab", path: "/projects",},
  // {id: 2, label: "Board", action: "board", icon: IconNames.columnView, props: "tab", path: "/projects-board", },
];

const LIST_SETTINGS_KEY = "prj-list";

interface IHeaderProps{
  onDisplayTypeChange: (string: ListDisplayType) => void;
  onSortChange: (order: ISort) => void;
}

const ProjectListHeader = ({onDisplayTypeChange, onSortChange}: IHeaderProps) => {
  const classes   = buildStyles();
  const {L, LSwap} = useLocalization();
  const [settings, changeSettings] = useSliceSettings<ProjectSettings>("projects");
  const [isSearch, toggleSearch] = useBoolState(false); 
  const filters = useMemo(() => filterListItems(LSwap), []);
  const menuItems = useMemo(() => filterMenuItems, []);
  const selectedItem = useMemo(() => { return filterMenuItems.find(f => f.action === settings.filter) ?? null; }, [settings.filter]);
  const [listSettings, saveListSettings] = useLocalSetting(LIST_SETTINGS_KEY, {displayType: "table"});
  const viewType = useMemo<IMenuItem>(() => listViews.find(v => v.action === listSettings.displayType) || listViews[0], [listSettings.displayType]);

  const sortItems: IMenuItem[] = [
    {id: 0, label: "Name", action: "name", props: "sort" },
    {id: 1, label: L("client", true), action: "clientName", props: "sort" },
    {id: 2, label: "Started", action: "startDate", props: "sort" },
    {id: 3, label: "Delivered", action: "deliveredDate", props: "sort" },
    {id: 4, label: "Invoiced", action: "invoicedDate", props: "sort" },
    {id: 5, label: "Paid", action: "paidDate", props: "sort" },
  ];

  //-- Clear the search term when the search closes
  useEffect(() => {
    if(!isSearch && settings.search !== null){
      changeSettings({...settings, search: null});
    }
  }, [isSearch]);

  //Check to see if there's a filter in the query paramters, and if so, deal with it
  useSearchStringFilter(routes.projects, changeSettings);

  const menuLabel = useMemo<string>(() => { 
    return selectedItem?.label ?? "Filter";
  }, [selectedItem]);

  const onFilterClicked = (filter: IFilter) => {
    changeSettings({ filter: filter.filter as ProjectFilter});
    onSortChange({sort: filter.sort, sortDir: filter.sortDir} as ISort);
  }

  const onMenuItemClicked = (menuItem: IMenuItem) => {
    changeSettings({ filter: menuItem.action as ProjectFilter, sort: menuItem.props.sort, sortDir: menuItem.props.sortDir });
  }

  const onViewChange = (view: IMenuItem) => {
    trackDisplayType("project-list", view.action);
    saveListSettings({displayType: view.action});
    onDisplayTypeChange(view.action);
  }
  
  const filterMenuComparer = (menuItem: IMenuItem) => {
    return menuItem.action === settings.filter && menuItem.props.sort === settings.sort;
  }

  const doSearch = (searchVal: string) => {
    changeSettings({...settings, search: searchVal});
  }

  const onSorted = (item: IMenuItem) => {
    const order = {sort: item.action } as ISort;
    onSortChange(order);
  }

  return (
    <Grid id="project-list-header" container className={classes.root}>
      <Grid item sm={8} xs={12} container>
        {isSearch && 
          <Grid container alignItems="center" justify="flex-start">
            <SearchInput autoFocus={true} placeholder="Search projects" onClose={toggleSearch} onSearch={doSearch} classes={{container: classes.searchContainer}}/>
          </Grid>
        }
        {!isSearch && 
          <Grid container alignItems="center" justify="flex-start" className={classes.menuBar}>
            <Grid item className={classes.firstCol}>
              <FilterList filters={filters} currentFilter={settings.filter} onClick={onFilterClicked} color="secondary" classes={{container: classes.marginRight}}/>
              <ChipMenu label={menuLabel} icon={<Icon fontSize="small">filter_list</Icon>} size="small" items={menuItems} title="More filters" color={!!selectedItem ? "secondary" : "default"} variant={!!selectedItem ? "default" : "outlined"}  isSelectable={true} onClick={onMenuItemClicked} isSelected={filterMenuComparer} />
              <Tooltip title="Search projects">
                <IconButton size="small" className={clsx(classes.chipIcon, classes.marginLeft)} onClick={toggleSearch}><Icon fontSize="small">search</Icon></IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        }
      </Grid>
      <Grid item sm={4} xs={12} container justify="flex-end" alignItems="center" className={classes.centerXs}>
        {viewType.action !== "table" && <ChipMenu label="Sort" icon={<Icon fontSize="small">sort</Icon>} size="small" items={sortItems} title="Sort items" variant="outlined"  onClick={onSorted} classes={{container: classes.marginRight}}/>}
        <ChipMenu label={viewType.action as string} icon={<Icon fontSize="small">{viewType.icon}</Icon>} size="small" items={listViews} title="Select View" variant="outlined" onClick={onViewChange} />
      </Grid>
    </Grid>
  );
}

export default ProjectListHeader;
