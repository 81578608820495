import { IParserCollection, Parsers } from "utils/hooks";
import { ITimer } from "./timesheet-types";

export const timerParsers: IParserCollection = {
  hours: Parsers.float(2),
  creditTime: Parsers.float(2),
  rate: Parsers.float(2),
}

export const defaultTimer: Partial<ITimer> = {
  startTime: "",
  clientId: "",
  projectId: "",
  notes: "",
  creditMinutes: 0,
  category: "",
  rate: 0,
  roundType: "none",
  //These properties are so they can be queried against
  // if the prop doesn't exist, firestore can't query against it
  invoiceDate: "",
  paymentDate: "",
  invoiceId: "",
  paymentId: "",
}