import React from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from '@material-ui/core/Paper';
import Zoom from '@material-ui/core/Zoom';
import Fade from '@material-ui/core/Fade';
import LinearProgress from '@material-ui/core/LinearProgress';
import { LoadingIndicator } from "components";
import CreateTimer from "./create-timer";
import ExistingTimer from "./existing-timer";
import { cardStyles } from "utils/styles";
import { useBoolState, useInterval, useProjects, useReadyState } from "utils/hooks";
import { selectCurrentTimer } from "../infra/timesheet-selectors";
import { ITimer, RootState } from "types";
import { cancelTimer, getTimer, startTimer, stopTimer } from "../infra/timesheet-actions";
import { trackEvent } from "utils/mixpanel";
import { calcMinutes, roundMinutes } from "../infra/timesheet-helpers";
import TimerEditor from "./timer-editor";

const buildStyles   = makeStyles(theme => ({
  ...cardStyles(theme),
  timerCard: {
    background: theme.palette.common.white,
    width: "100%",
    maxWidth: 450,
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.grey[300]}`, //`
  },
  activeGrid: {
    background: theme.palette.primary.dark,
    borderRadius: 3,
  },
  editGrid: {
    borderRadius: 3,
    border: `1px solid ${theme.palette.primary.dark}`, //`
    background: `${theme.palette.primary.main}22`, //`
  },
  progressGrid: {

  },
  progress: {
    width: "100%",
    height: 5,
  },
  hidden: {
    display: "none",
  }
}));

interface CardProps{
  onEdit?: () => void;
  noFocus?: boolean;
  onStarted?: (item: Partial<ITimer>) => void;
  onStopped?: (item: ITimer) => void;
}

const TimerCard = ({onEdit, noFocus, onStarted, onStopped}: CardProps) => {
  const classes   = buildStyles();
  const dispatch = useDispatch();
  const projects = useProjects("name", "asc");
  const existing = useSelector(selectCurrentTimer);
  const isReady = useReadyState();
  const lastCheck = useSelector((state: RootState) => state.timesheet.lastCheckForTimer);
  const [isEditing, toggleEditing] = useBoolState(false);

  const currentProject = React.useMemo(() => {
    if (projects && existing && existing.projectId) {
      const project = projects.find(prj => prj.id === existing.projectId);
      return project;
    }
  }, [projects, existing]);

  const refresh = React.useCallback(() => {
    dispatch(getTimer());
  }, []);
  
    //Set an interval to check on a running timer every 2 minutes
    useInterval(refresh, 30000, true);
    
  //--
  // Timer started, save the hours
  const onStart = async (values: Partial<ITimer>) => {
    if (!values.projectId) {
      //TODO: errors...
      return;
    }

    const project = projects.find(prj => prj.id === values.projectId);
    const client = project?.client;
    if (!project || !client) {
      //TODO: something went wrong
      return;
    }

    const model: Partial<ITimer> = {
      ...values,
      clientId: client.id,
      rate: values.rate || client.defaultRate,
    };

    await dispatch(startTimer(model));
    trackEvent("timer", "start");
    //TODO: raise event that timer was started
    if(onStarted) onStarted(model);
  }

  //--
  // Timer stopped, save the hours
  const onStop = async (stopTime: Date) => {
    if (!existing) throw new Error("Timer is not running!");
    if (!existing.startTime) throw new Error("Start time is not set.");

    const totalMinutes = calcMinutes(existing.startTime, stopTime);
    const roundedMinutes = roundMinutes(totalMinutes, existing.roundType);

    const model = {
      ...existing,
      stopTime: stopTime,
      minutes: roundedMinutes,
    } as ITimer;

    //TODO: raise event that timer was stopped
    await dispatch(stopTimer(model));
    trackEvent("timer", "stop");
    if(onStopped) onStopped(model);
  }

  //--
  //Finish editing, and possibly stop the timer...
  const closeEditor = async (andStop = false) => {
    if(!isEditing) return;
    toggleEditing();
    if(andStop){
      await onStop(new Date());
    }
  }

  const onLocalEdit = () => {
    if(onEdit) return onEdit();
    else{
      toggleEditing();
    }
  }

  const onCancel = async () => {
    if (existing && !!existing.id) {
      //TODO: raise event that it changed
      // if(view === "edit") await dispatch(updateTimesheetSettings({tab: "summary"}));
      dispatch(cancelTimer(existing.id));
      trackEvent("timer", "cancel");
    }
  }

  return (
    <Paper className={clsx(classes.card, classes.timerCard)} elevation={0}>
      {(!isReady || !lastCheck) && <LoadingIndicator isVisible={true} message="Loading..."/> }
      {(isReady && !!lastCheck) && 
        <>
          <Fade in={!existing} style={{ transitionDelay: "0ms" }}>
            <Grid>
              {!existing &&
                <CreateTimer projects={projects} timer={existing} onStart={onStart} noFocus={noFocus}/>
              }
            </Grid>
          </Fade>
          <Zoom in={!!existing && !isEditing} style={{ transitionDelay: "0ms" }}>
            <Grid className={classes.activeGrid}>
              {(existing && currentProject && !!existing.startTime && !isEditing) && <ExistingTimer project={currentProject} startTime={existing.startTime} onStop={onStop} onEdit={onLocalEdit} onCancel={onCancel} /> }
            </Grid>
          </Zoom>
          <Zoom in={isEditing} style={{transitionDelay: "0ms"}}>
            <Grid className={clsx(classes.editGrid, {[classes.hidden]: !isEditing})}>
              <Grid container className={classes.progressGrid}>
                <LinearProgress color="secondary" className={classes.progress} />
              </Grid>
              {isEditing && <TimerEditor size="small" onClose={closeEditor}/>}
            </Grid>
          </Zoom>
        </>
      }
    </Paper>
  );
}

export default TimerCard;
