import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { IClient, RootState } from "types";
import { fontStyles, generalStyles } from "utils/styles";
import { formatCurrency } from "utils/number-helpers";
import ClientGoal from "./client-goal";
import { useSelector } from "react-redux";
import { selectClientViewStats } from "../infra/client-selectors";

const buildStyles   = makeStyles(theme => ({
  ...generalStyles(theme),
  ...fontStyles(theme),
  root  : {
    padding     : theme.spacing(1),
  },
}));

interface ControlProps {
  client: IClient;
}

const ClientGoals = ({ client }: ControlProps) => {
  const classes   = buildStyles();
  const data = useSelector((state: RootState) => selectClientViewStats(state, client.id));

  // if(!client.goals) return <Typography className={classes.caption}>No Client Goals</Typography>

  return (
    <Grid id="client-goals" container className={classes.root}>
      <Grid item xs={6}>
        <ClientGoal label="monthly" value={data?.paid.month || 0} goal={client.goals?.monthlyRevenue || 0} period="month" />
      </Grid>
      <Grid item xs={6}>
        <ClientGoal label="yearly" value={data?.paid.year || 0} goal={client.goals?.annualRevenue || 0} period="year"/>
      </Grid>
    </Grid>
  );
}

export default ClientGoals;