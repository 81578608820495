import _ from "lodash";
import { AppThunk } from "store/root-reducer";
import { ITimer, QueryFilter } from "types";
import { useFirestore, STATUSES } from "utils/firebase";
import { statusChanged, hoursLoaded, timerStarted, timerUpdated, timerStopped, itemCreated, itemUpdated, itemDeleted, recalibrated, itemQueryLoaded, imported, checkedForTimer } from "./timesheet-slice";
import { datesToStrings, stringsToDates, ensureProps, prepareForDb} from "utils/general-helpers";
import { REDUX_DATE_TIME_FORMAT, REDUX_DATE_FORMAT } from "utils/date-helpers";
import { statusChanged as appStatusChanged } from "features/app/app-slice";
import { projectTrackedMinutesUpdated } from "features/projects";

export const TIMER_BAD_FIELDS = ["client", "clientName", "project", "projectName"];
export const TIMER_REQ_FIELDS = ["invoiceDate", "paidDate"];

//-- load hours
export const loadHours = (start: Date | null = null, end: Date | null = null, filter: QueryFilter | QueryFilter[] | null = null, queryKey: string | null = null): AppThunk => async (dispatch, getState) => {
  const [api, uid] = useFirestore();

  if (!uid) throw new Error("User is not authorized.");
  const app = getState().app;

  if (app.isInitialized) {
    dispatch(statusChanged({ isWorking: true, error: null }));

    try{
      const result = await api.getHours(app.org.id, start, end, filter);
      if (result.statusCode === STATUSES.ok || result.statusCode === STATUSES.empty) {
        // need to deal with dates...
        const items = result.items?.map(i => {
          return {
            ...datesToStrings(i, REDUX_DATE_TIME_FORMAT)
          };
        });

        if(queryKey){
          await dispatch(itemQueryLoaded({key: queryKey, items: items || []}));
        }
        else{
          await dispatch(hoursLoaded(items || []));
        }
        
        return result;
      }
    }
    catch(ex){
      console.log(ex);
      const err = { ok: false, code: ex.code, name: ex.name, message: `${ex.code}: ${ex.name}` };
      dispatch(statusChanged({ isWorking: false, error: err }));
      return err;
    }
  }
}

export const createHours = (item: Partial<ITimer>): AppThunk => async (dispatch, getState) => {
  const [api, uid] = useFirestore();

  if (!uid) throw new Error("User is not authorized.");
  const app = getState().app;

  if (app.isInitialized) {
    let prepared = prepareForDb(item, TIMER_BAD_FIELDS);
    prepared = ensureProps(prepared, TIMER_REQ_FIELDS);
    
    const hResult = await api.addHours(app.org.id, prepared);
    if (hResult.statusCode === STATUSES.ok) {
      const toStore = {
        ...datesToStrings(prepared, REDUX_DATE_TIME_FORMAT),
        id: hResult.key,
      };
      dispatch(itemCreated(toStore));
        
      //Update the tracked minutes on the project
      const trackingData = datesToStrings(hResult.data, REDUX_DATE_FORMAT);
      dispatch(projectTrackedMinutesUpdated(trackingData));

      return toStore;
    }
  }
}

export const updateHours = (id: string | null, item: Partial<ITimer>): AppThunk => async (dispatch, getState) => {
  const [api, uid] = useFirestore();

  if (!uid) throw new Error("User is not authorized.");
  const app = getState().app;

  if (app.isInitialized) {
    const ts = getState().timesheet;
    const existing = ts.items?.find(t => t.id === id);
    if (!existing || !existing.id || existing.id !== id) return;

    const union = {...existing, ...item};
    let prepared = prepareForDb(union, TIMER_BAD_FIELDS);
    prepared = ensureProps(prepared, TIMER_REQ_FIELDS);
    
    const result = await api.updateHours(app.org.id, id, prepared);
    if (result.statusCode === STATUSES.ok) {
      const forStore = datesToStrings(prepared, REDUX_DATE_TIME_FORMAT);
      dispatch(itemUpdated({ id: id, changes: forStore }));
      //Update the projects slice with the change to the minutes, if necessary.
      const trackingData = datesToStrings(result.data);
      dispatch(projectTrackedMinutesUpdated(trackingData));
    }
  }
}

export const deleteHours = (id: string): AppThunk => async (dispatch, getState) => {
  const [api, uid] = useFirestore();

  if (!uid) throw new Error("User is not authorized.");
  const app = getState().app;

  if (app.isInitialized) {
    const existing = getState().timesheet.items?.find(h => h.id === id);
    if (!existing || !existing.id || existing.id !== id) return;

    //delete it from the current timer
    const result = await api.deleteHours(app.org.id, existing.id);
    if (result.statusCode === STATUSES.ok) {
      dispatch(itemDeleted({ id: id }));
      //update the project to reflect the change
      const trackingData = datesToStrings(result.data);
      dispatch(projectTrackedMinutesUpdated(trackingData));
    }
  }
}

export const getTimer = (): AppThunk => async (dispatch, getState) => {
  const [api, uid] = useFirestore();

  if (!uid) throw new Error("User is not authorized.");
  const app = getState().app;
  if (!app.isInitialized) return;

  const result = await api.getTimers(app.org.id);
  const current = getState().timesheet.current;
  
  if (result.statusCode === STATUSES.ok && result.statusCode !== STATUSES.empty && result.items) {
    const timer = result.items[0];
    //Don't do anything unless this is different than the current state
    if(!current || timer.id !== current.id){
      const prepared = datesToStrings(timer, REDUX_DATE_TIME_FORMAT);
      dispatch(timerStarted(prepared));
    }
    else{
      //flag that we checked...
      dispatch(checkedForTimer());
    }
  }
  else{
    if(!!current){
      dispatch(timerStarted(null));
    }
    else{
      //flag that we checked
      dispatch(checkedForTimer());
    }
  }
}

//-- create a new timer
export const startTimer = (item: Partial<ITimer>): AppThunk => async (dispatch, getState) => {
  const [api, uid] = useFirestore();

  if (!uid) throw new Error("User is not authorized.");
  const app = getState().app;

  if (app.isInitialized) {
    const prepared = { ...stringsToDates(item), userId: uid };  //Add the User Id to the timer
    const result = await api.addTimer(app.org.id, prepared);
    if (result.statusCode === STATUSES.ok) {
      //Update the local store
      const toStore = {
        id: result.key,
        ...datesToStrings(prepared, REDUX_DATE_TIME_FORMAT),
      };

      dispatch(timerStarted(toStore));

      return toStore;
    }
  }
}

export const stopTimer = (item: Partial<ITimer>): AppThunk => async (dispatch, getState) => {
  const [api, uid] = useFirestore();

  if (!uid) throw new Error("User is not authorized.");
  const app = getState().app;

  if (app.isInitialized) {
    const existing = getState().timesheet.current;
    if (!existing || !existing.id) return;

    const timerId = existing.id;
    //Add it to the hours
    const filtered = _.omit(item, ["client", "clientName", "project", "projectName"]);

    let hours = { ...stringsToDates(existing), ...stringsToDates(filtered) };
    hours = ensureProps(hours, TIMER_REQ_FIELDS);
    //Validate / add these fields if necessary

    // if(!_.has(hours, "invoiceDate") || !_.has(hours, "paymentDate")){
    //   hours.invoiceDate = hours.invoiceDate || null;
    //   hours.paymentDate = hours.paymentDate || null;
    // }

    const hResult = await api.addHours(app.org.id, hours);
    if (hResult.statusCode === STATUSES.ok) {
      //delete it from the current timer
      const result = await api.deleteTimer(app.org.id, timerId);

      if (result.statusCode === STATUSES.ok) {
        //Update the local store
        const toStore = {
          ...datesToStrings(hours, REDUX_DATE_TIME_FORMAT),
          id: hResult.key,
        };
        dispatch(timerStopped(toStore));
        
        //Update the tracked minutes on the project
        const trackingData = datesToStrings(hResult.data, REDUX_DATE_FORMAT);
        dispatch(projectTrackedMinutesUpdated(trackingData));

        return toStore;
      }
    }
  }
}

export const cancelTimer = (id: string): AppThunk => async (dispatch, getState) => {
  const [api, uid] = useFirestore();

  if (!uid) throw new Error("User is not authorized.");
  const app = getState().app;

  if (app.isInitialized) {
    const existing = getState().timesheet.current;
    if (!existing || !existing.id || existing.id !== id) return;

    //delete it from the current timer
    const result = await api.deleteTimer(app.org.id, existing.id);
    if (result.statusCode === STATUSES.ok) {
      dispatch(timerStopped(null));
    }
  }
}

export const updateTimer = (id: string | null, item: Partial<ITimer>): AppThunk => async (dispatch, getState) => {
  const [api, uid] = useFirestore();

  if (!uid) throw new Error("User is not authorized.");
  const app = getState().app;

  if (app.isInitialized) {
    const ts = getState().timesheet;
    const existing = ts.current;
    if (!existing || !existing.id || existing.id !== id) return;

    //delete it from the current timer
    const filtered = _.omit(item, ["client", "clientName", "project", "projectName"]);
    const prepared = stringsToDates({ ...stringsToDates(existing), ...stringsToDates(filtered) });
    const result = await api.updateTimer(app.org.id, id, prepared);
    if (result.statusCode === STATUSES.ok) {
      const forStore = datesToStrings(prepared, REDUX_DATE_TIME_FORMAT);
      dispatch(timerUpdated(forStore));
    }
  }
}

export const recalibrateProjectTime = (): AppThunk => async (dispatch, getState) => {
  const [api, uid] = useFirestore();

  if (!uid) throw new Error("User is not authorized.");
  const app = getState().app;
  
  if(app.isInitialized){
    await dispatch(statusChanged({isWorking: true, error: null}));
    const result = await api.recalibrateHours(app.org.id);
    const trackedData = datesToStrings(result.data);
    await dispatch(recalibrated(trackedData));
    await dispatch(appStatusChanged({wantsRefresh: true}));
  }

  return true;
}

export const uploadHours = (items: Partial<ITimer>[], noWarning?: boolean): AppThunk => async (dispatch, getState) => {
  const [api, uid] = useFirestore();

  if (!uid) throw new Error("User is not authorized.");
  const app = getState().app;
  
  if(app.isInitialized){
    await dispatch(statusChanged({isWorking: true, error: null}));
    const result = await api.uploadHours(app.org.id, items);
    const trackedData = datesToStrings(result.data);
    await dispatch(imported(trackedData));
    if(!noWarning){
      await dispatch(appStatusChanged({wantsRefresh: true}));
    }
  }

  return true;
}
